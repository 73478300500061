import { useEffect, useState } from "react";
import { SelectBox } from "components/Select/Select";
import { getCurrencyDictionaryApi } from "./api";
import { InputLoad } from "components/InputLoading/inputLoading";

interface DictionaryTypeSelectProps {
  value?: any;
  mode?: string;
  resetForm?: boolean;
  name: string;
  label: string;
  baseUrl?: string;
  required?: boolean;
  transe?: boolean;
  disabled?: boolean;
  params?: any;
  recordedData?: any;
  Change?: (e) => void;
  onChange?: (e: any, obj?: any) => void;
  useTrans?: boolean;
}
export const CurrencyDictionary = ({
  recordedData,
  params,
  label,
  required = false,
  baseUrl,
  useTrans = false,
  disabled,
  value,
  name,
  mode,
  resetForm,
  onChange,
  Change,
}: DictionaryTypeSelectProps) => {
  const [CurrencyDictionary, setCurrencyDictionary] = useState<any>();
  const [defaultval, setdefault] = useState(value);
  const [errcode, setErrcode] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCurrencyDictionaryAsync = async () => {
    setLoading(false);
    try {
      const res = await getCurrencyDictionaryApi(baseUrl, params);
      // if(res?.Data){
      //     setCurrencyDictionary(res?.Data)
      //    onChange && onChange(res?.Data[0],{Id:res?.Data[0].Id , Name:res?.Data[0].Title	})
      // }else{
      //     setCurrencyDictionary(res)
      //    onChange && onChange(res[0],{Id:res[0] , Name:res[0]})

      // }
      if (res?.Data) {
        setCurrencyDictionary(res?.Data);
      } else {
        onChange && onChange(Object.values(res?.Data)[0]);
      }
    } catch (error: any) {
      setErrcode(error.response?.status);
      // setCurrencyDictionary(null)
    }
    setLoading(true);
  };

  const handleOnChange = (e) => {
    onChange && onChange(CurrencyDictionary[e.target.value]);

    Change && Change(true);
    const firstItem = Object.values(CurrencyDictionary)[0] as any;

    if (e.target && e.target.value != (value ? value : firstItem.Id)) {
      Change && Change({ [name]: e.target.value });
    } else {
      Change && Change({ [name]: null });
    }
  };

  useEffect(() => {
    if (value && CurrencyDictionary) {
      onChange && onChange(CurrencyDictionary[value]);
    }
  }, [CurrencyDictionary, value]);
  useEffect(() => {
    if (params) {
      //getCurrencyDictionaryAsync()
    }
  }, [params]);

  useEffect(() => {
    setdefault(value);
  }, [resetForm]);
  useEffect(() => {
    getCurrencyDictionaryAsync();
  }, []);

  return (
    <>
      {CurrencyDictionary && loading ? (
        <>
          <SelectBox
            onChange={(e) => {
              handleOnChange(e);
            }}
            lable={label}
            selectType={"select"}
            options={Object.values(CurrencyDictionary).map((item: any) => {
              return item.Title;
            })}
            val={Object.keys(CurrencyDictionary)}
            name={name}
            value={value}
            isDisabled={disabled}
            resetForm={resetForm}
            mode={mode}
            required={required}
            useTrans={useTrans}
            recordedData={recordedData}
          />
          <div className="text-box__msg-wrapper"></div>
        </>
      ) : (
        <InputLoad
          msg={errcode}
          required={required}
          labelText={label}
          onClick={getCurrencyDictionaryAsync}
        />
      )}
    </>
  );
};
