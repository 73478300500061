import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { deleteMethodFeed, getFeedMethodsDictionaryApi, sendFeedMethodsForm } from "./api";
import { DataGrid } from "components/DataGrid/DataGrid";

interface FeedMethodsFormPropsType {
    id?: any,
    mode?: any,
    onHide: () => void
    reset: (ans: boolean) => void
}

export const FeedMethodsForm = ({
    id,
    mode,
    onHide,
    reset,
}: FeedMethodsFormPropsType) => {

    let data, method;
    const [FeedMethodsForm, setFeedMethodsForm] = useState<any>(undefined);
    const [methodList,setMethodList]=useState([])
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [resetForm, setResetForm] = useState(false);
    const formRef = useRef<any>("")
    const buttonRef = useRef()
    const [loading , setLoading] = useState(false)  



    async function formSubmit(event) {
        event.preventDefault();
            if (id && mode !== "create") {
                data = {
                    Id: id,
                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    ClassName: formRef.current.ClassName.value,
                    MethodName: formRef.current.MethodName.value,
                };
                method = "put";
            } else {
                data = {

                    Title: formRef.current.Title.value,
                    SystemKey: formRef.current.SystemKey.value,
                    ClassName: formRef.current.ClassName.value,
                    MethodName: formRef.current.MethodName.value,
                };
                method = "post";
            }        
            try {
                await sendFeedMethodsForm(data, method);
                setResetForm(true);
                reset(true);
                onHide();

            } catch (error) {

            }
        
    
    }
    
    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const getFeedMethodDictionaryApiAsync=async()=>{
        setLoading(false)
        try{
           const res= await getFeedMethodsDictionaryApi('','')
           if(!!res.data.Data.length){
               setMethodList(res.data.Data); 
           }
        }
        catch(error){
    
        }
        setLoading(true)
      }


    function cancel() {
            onHide()
        
    }


  const handleDeleteItem=async()=>{ 
    try{
        await deleteMethodFeed(selectedObj.Id)
        getFeedMethodDictionaryApiAsync()

    }
    catch(error){}
  }



    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
          
            setResetForm(false);
        }

    }, [resetForm]);
    useEffect(()=>{
        getFeedMethodDictionaryApiAsync()
      },[])

    return (
        <form  className="" ref={formRef} >
            {loading ? 
            <div className="card">
                <div className="card-body">
                    <div className="container">
                        <div  className="row">
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="Title"
                                    defaultValue={FeedMethodsForm?.Title}
                                    resetForm={resetForm}
                                    labelText="Title"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="systemKey"
                                    textboxName="SystemKey"
                                    defaultValue={FeedMethodsForm?.SystemKey}
                                    resetForm={resetForm}
                                    labelText="SystemKey"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="MethodName"
                                    defaultValue={FeedMethodsForm?.MethodName}
                                    resetForm={resetForm}
                                    labelText="MethodName"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                            <div className="col-4">
                                <Textbox
                                    autoFocus={true}
                                    textboxType="text"
                                    textboxName="ClassName"
                                    defaultValue={FeedMethodsForm?.ClassName}
                                    resetForm={resetForm}
                                    labelText="ClassName"
                                    required={true}
                                    mode={mode}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4 pt-2">
                            {mode !== "view" ?
                                <>
                                    <Button ref={buttonRef} onClick={formSubmit}  value="Save" btnType="submit" />
                                    <Button
                                        btnType="cancel"
                                        value="Cancel"
                                        onClick={() => {cancel()}}
                                    />
                                </>
                                :
                                 <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={() =>{cancel()}}
                                  />
                            }
                        </div>
                    </div>
   <div className="mt-3">
   {
                                 !!methodList.length &&
                                 <DataGrid
                                    DoubleClick={(e) => {
                                       setSelectedObj(e);
                                   
                                    }}
                                    parentName={"FeedParameters"}
                                    startIndex={0}
                                    theadData={Object.keys(methodList[0])}
                                    tbodyData={methodList}
                                    minCellWidth={80}
                                   selectedRowObjectCallback={selectedIdCallback}
                                    // handleEdit={(e) => {
                                    //   setSelectedObj(e);
                                    //   setDetailMode('edit')
                                    // }}
                                    handleDelete={()=>{handleDeleteItem()}}
                                  />}
   </div>
                </div>
            </div>     
                :<Loading/>
            }
        </form>
    );
}