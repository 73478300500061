import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { useEffect, useState } from "react";






interface SalesInvoiceFormProps {


  selectedObj?: any;
  mode: any;
  onHide: () => void;
}

export const CustomerSalesInvoiceDetailForm = ({

  selectedObj,
  mode,
  onHide,
}: SalesInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userChanges, setUserChanges] = useState({});

  const handleSalesInvoiceFormSubmit = async (event) => {
    event.preventDefault();

  };










  const cancel = () => {
    if (sessionStorage.getItem('changingforms') === 'true') {
      Toast('modalclosemessage', 'warning', 'alertWithoutClose', () => {
        sessionStorage.setItem('changingforms', 'false');
        setUserChanges({});
        onHide();
      });
    } else {
      onHide();
    }
  };

  useEffect(() => {
    if (resetForm) setResetForm(false);
  }, [resetForm]);

  






  return (
    <div className="card " style={{ flexDirection: "row" }}>

      <div style={{flexWrap:"nowrap"}} className="card-body row">
        <div
          className={`card-body col-12`}
        >
          {loading ? (
            <div className="container-fluid">
              <form onSubmit={handleSalesInvoiceFormSubmit}>
                <div className="row">

                    <div
                      className="col-lg-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Textbox
                        required
                        textboxName={"itemCode"}
                        textboxType="text"
                        resetForm={resetForm}
                        labelText={"itemCode"}
                        mode={mode}
                        value={selectedObj?.ItemCode}
                        defaultValue={selectedObj?.ItemCode}

                      />
                    </div>
                  <div className="col-lg-4">
                    <Textbox
                      textboxName="Item"
                      textboxType="text"
                      labelText="ItemDescription"
                      value={selectedObj?.Item}
                      defaultValue={selectedObj?.Item}
                      resetForm={resetForm}
                      mode={"view"}
                       
                    />
                  </div>

                  <div className="col-lg-2">
                    <Textbox
                      textboxName="ItemTechnicalCode"
                      textboxType="text"
                      labelText="TechnicalCode"
                      value={selectedObj.ItemTechnicalCode + ""}
                      defaultValue={ selectedObj?.ItemTechnicalCode }
                      resetForm={resetForm}
                      mode="view"
                       
                    />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-3">
                    <Textbox
                      textboxName="Qty1"
                      textboxType="number"
                      labelText="Qty"
                      value={selectedObj?.Qty }
                      defaultValue={ selectedObj?.Qty}
                      resetForm={resetForm}
                      mode={mode}
                      required={true}
                    />
                  </div>
                  <div className="col-lg-3">
                    <UnitDictionary
                      value={selectedObj?.SalesUnitId}
                      label="Unit"
                      name="Unit1Id"
                      mode={"view"}
                      resetForm={resetForm}
                    />
                  </div>
                  <div className="col-lg-3 col-12">
                    <Textbox

                      textboxName={"UnitPrice"}
                      textboxType="text"
                      labelText="UnitPrice"
                      value={selectedObj?.SalesUnitPrice}
           
                      defaultValue={ selectedObj?.SalesUnitPrice }
                      resetForm={resetForm}
                      mode={"view"}
                      useGroupingPrice={true}
                       
                    />
                  </div>
               <div className="col-lg-3">
                    <Textbox
                      textboxName="SubTotalPrice"
                      textboxType="text"
                      labelText="مبلغ"
                      value={selectedObj?.SubTotalPrice}
                  
                      round={false}
                      defaultValue={  selectedObj?.SubTotalPrice??null }
                      resetForm={resetForm}
                      mode={"view"}
                      useGroupingPrice={true}
                       
                    />
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-lg-2">
                    <Textbox
                      textboxName="DiscountPercent"
                      textboxType="text"
                      labelText="DiscountPercent"
                      value={selectedObj?.DiscountPercent + ""}
                      defaultValue={ selectedObj?.DiscountPercent  }
                      resetForm={resetForm}
                      mode={"view"}
                      useGroupingPrice={true}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Textbox
                      textboxName="Discount"
                      textboxType="text"
                      labelText="Discount"
                      value={selectedObj?.Discount}
                      defaultValue={selectedObj?.Discount}
                      resetForm={resetForm}
                  
                      round={false}
                      mode={"view"}
                      useGroupingPrice={true}
                       
                    />
                  </div>
                  
                </div>
                <div className="row justify-content-end">
                <div className="col-lg-3">
                    <Textbox
                      textboxName="Price"
                      textboxType="text"
                      labelText="مبلغ  کل"
                      value={selectedObj.Price + ""}
                      defaultValue={selectedObj?.Price}
                      resetForm={resetForm}
                 
                      round={false}
                      mode={"view"}
                      useGroupingPrice={true}
                    />
                  </div></div>
            <div className="row justify-content-end">
                  <div className="col-lg-3">
                    <Textbox
                    useGroupingPrice
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Tax"
                      textboxType="text"
                      labelText="Tax"
                      
                      defaultValue={selectedObj?.Tax }
                      value={selectedObj?.Tax }
                      resetForm={resetForm}
                      mode={"view"}
                    />
                  </div>
                </div>
                <div className="row justify-content-end">
                <div className="col-lg-3">
                <Textbox
                      textboxName="GrandTotalPrice"
                      textboxType="text"
                      labelText="payableamount"
                      value={selectedObj?.Price + +selectedObj?.Tax}
                      defaultValue={selectedObj?.Price + +selectedObj?.Tax}
                      resetForm={resetForm}
                   
                      round={false}
                      mode={"view"}
                      useGroupingPrice={true}
                       
                    />
                </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="textarea"
                      textboxName="Description"
                      minHeight={1}
                      labelText="Remarks"
                      defaultValue={selectedObj?.Description  }
                      resetForm={resetForm}
                      mode={mode }
                      maxLength={150}
                    letterCounter={true}
                    />
                  </div>
                </div>
                <>
                <div className="col-lg-12 mt-1 btns_form">
                      <Button
                        btnType="primary"
                        value="close"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                </>
              </form>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};
