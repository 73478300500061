import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { useEffect, useState } from "react";
import { GUID_EMPTY, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { roundOrTruncate, trans, uuidv4 } from "utils/helpers";
import { PriceDetails } from "../components/PriceDetails";
import { getTaxList } from "./api";
import { WarehouseDictionaryWithAccess } from "../SalesInvoice/components/WarehouseDictionary/WarehouseDictionaryWithAccess";
import { WarehouseTreeForm } from "../SalesInvoice/components/WarehouseTreeForm/WarehouseTreeForm";
import { SalesInvoiceDetailType } from "./type";
import { ItemsForSell } from "../components/ItemsForSell/ItemsForSell";

interface SalesInvoiceFormProps {
  onHide: () => void;
  SalesInvoiceDetailCallBack?: (e: any) => void;
  selectedObj?: any;
  mode: any;
  priceList?: any;
  lastWarehouse?: any;
  invoiceConstraint?: any;
  setLastWarehouse: (e: any) => void;
  originalQty?:any
}

export const SalesReturnInvoiceDetailForm = ({
  lastWarehouse = null,
  invoiceConstraint,
  setLastWarehouse,
  priceList,
  selectedObj,
  mode,
  onHide,
  SalesInvoiceDetailCallBack,
  originalQty
}: SalesInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const [userChanges, setUserChanges] = useState({});
  const [ExpireDate, setExpireDate] = useState<any>();
  const [showTree, setShowTree] = useState<any>();
  const [showWarehouseTree, setShowWarehouseTree] = useState<any>();
  const [priceListDetail, setPriceListDetail] = useState<any>([]);
  const [detailPriceData, setDetailPriceData] = useState<any>({
    Price: 0,
    Discount: 0,
    SubTotalPrice: 0,
    Qty1: mode != "create" ? selectedObj?.Qty1 : 1,
    Qty2: mode != "create" ? selectedObj?.Qty2 : 1,
  });
  const [itemData, setItemData] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>(lastWarehouse);
  const [dictionaryName, setDictionaryName] = useState<any>({});
  const [save_Exit, setSave_Exit] = useState<boolean>(false);
  const [rate, setRate] = useState<any>(1);
  const [numberOfSalesUnit, setNumberOfSalesUnit] = useState<any>();
  const [newDetials, setNewDetials] = useState<any>({
    Qty1: selectedObj.Qty1,
    Qty2: selectedObj.Qty2,
    price: selectedObj.SubTotalPrice,
    discount: selectedObj.Discount,
  });
  const [showInvalidValue, setShowInvalidValue] = useState(false);
  const handleSalesInvoiceFormSubmit = async (event) => {
    event.preventDefault();
    let data: SalesInvoiceDetailType = {
      SalesInvoiceDetailId: selectedObj.SalesInvoiceDetailId,
      Id: mode != "create" ? selectedObj?.Id : uuidv4(),
      SourceId: selectedObj?.SourceId,
      SourceData: selectedObj?.SourceData,
      ItemId: selectedObj?.ItemId,
      WarehouseId: selectedObj?.WarehouseId,
      WarehouseDetailId: selectedObj?.WarehouseDetailIdl,
      Unit1Id:
        event.target.Unit1Id?.value == "" ? null : event.target.Unit1Id?.value,
      Unit2Id:
        event.target?.Unit2Id?.value == ""
          ? null
          : event.target?.Unit2Id?.value,
      ItemCode: selectedObj?.ItemCode,
      Item: selectedObj?.Item,
      Qty1: +event.target.Qty1?.value,
      Unit1: dictionaryName?.Unit1 ? dictionaryName?.Unit1 : null,
      Qty2: +event.target?.Qty2?.value,
      Unit2: dictionaryName?.Unit2 ? dictionaryName?.Unit2 : null,
      UnitPrice: selectedObj.UnitPrice,
      SubTotalPrice:
        numberOfSalesUnit == 1
          ? selectedObj.UnitPrice * newDetials.Qty1
          : selectedObj.UnitPrice * newDetials.Qty2,
      DiscountPercent: +event.target.DiscountPercent?.value.replaceAll(",", ""),
      Discount: +event.target.Discount?.value.replaceAll(",", ""),
      Price: newDetials.price
        ? newDetials.price - newDetials.discount
        : selectedObj.Price, //تخفیف کم میشود
      Tax: selectedObj.Tax,
      ItemTechnicalCode: selectedObj.ItemTechnicalCode,
      Description: event.target.Description?.value,
      Warehouse: selectedObj.Warehouse,
      WarehouseDetail: selectedObj?.WarehouseDetail,
    };
    if (!showInvalidValue) {
      SalesInvoiceDetailCallBack && SalesInvoiceDetailCallBack(data);
      setItemData(null);
      setResetForm(true);
      setLastWarehouse(warehouseData);
      setUserChanges({});
      setDetailPriceData({
        Price: 0,
        Discount: 0,
        SubTotalPrice: 0,
        Qty1: mode != "create" ? selectedObj?.Qty1 : 1,
        Qty2: mode != "create" ? selectedObj?.Qty2 : 1,
      });
      sessionStorage.setItem("changingforms", "false");
      Toast("msg_saveddatasuccessfully", "success");

      try {
      } catch (error) {}
      if (save_Exit) {
        onHide();
      }
    } else {
      Toast(trans("Msg_DeleteItemFromRowOperationsOfDataGrid"), "error");
    }
  };

  const itemCodeHandeler = (obj, bool) => {
    if (obj.IsLeaf || bool) {
      // setLoading(false)
      setItemData(obj);

      setShowTree(false);
      //  setLoading(true)
    } else {
      Toast(trans("msg_itemleafisnotselected"), "error");
    }
  };
  const WarehouseIdHandeler = (obj) => {
    setWarehouseData(obj);
    setShowWarehouseTree(false);
  };

  const TaxHandler = async (Id) => {
    //  setLoading(false)
    try {
      const res = await getTaxList(Id);
      let totalTaxRate = 0;
      res.Data.map((item) => {
        totalTaxRate += item.TaxRate;
      });

      return totalTaxRate;
    } catch (error) {}
    // setLoading(true)
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide();
      });
    } else {
      onHide();
    }
  }
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (selectedObj) {
      setItemData(selectedObj);
      setWarehouseData({
        Id: selectedObj.WarehouseDetailId
          ? selectedObj.WarehouseDetailId
          : selectedObj.Id,
        Title: selectedObj.WarehouseDetail
          ? selectedObj.Warehouse + "," + selectedObj.WarehouseDetail
          : selectedObj.Warehouse,
      });
      if (selectedObj) {
        setNumberOfSalesUnit(
          selectedObj.SubTotalPrice / selectedObj.Qty1 === selectedObj.UnitPrice
            ? 1
            : 2
        );
      }
    }
  }, []);

  useEffect(() => {
    if (newDetials.Qty1 == 0 || newDetials.Qty2 == 0) {
      setShowInvalidValue(true);
    }
  }, [newDetials.Qty1, newDetials.Qty2]);




  return (
    <div className="card " style={{ flexDirection: "row" }}>
      {showTree && (
        <ModalForm
          mode={mode}
          title="ItemTree"
          onHide={() => setShowTree(false)}
          show={showTree}
        >
          <ItemsForSell
            priceListId={priceList?.Id}
            warehouseId={warehouseData?.WarehouseId}
            warehouseDetailId={warehouseData?.WarehouseDetailId}
            onHide={() => {
              setShowTree(false);
            }}
            itemCodeHandeler={itemCodeHandeler}
          />
        </ModalForm>
      )}
      {showWarehouseTree && (
        <ModalForm
          mode={mode}
          title="warehouses"
          onHide={() => setShowWarehouseTree(false)}
          show={showWarehouseTree}
        >
          <WarehouseTreeForm
            onHide={() => {
              setShowWarehouseTree(false);
            }}
            WarehouseIdHandeler={WarehouseIdHandeler}
          />
        </ModalForm>
      )}
      <div style={{flexWrap:"nowrap"}} className="card-body row">
        <div
          className={`card-body ${
            !!priceListDetail.length
              ? "col-12 col-md-8 col-lg-9 col-xl-10"
              : "col-12"
          }`}
        >
          {loading ? (
            <div className="container-fluid">
              <form onSubmit={handleSalesInvoiceFormSubmit}>
                <div className="row">
                  <div className="col-lg-1">
                    <WarehouseDictionaryWithAccess
                      name="Warehouse"
                      label="Warehouse"
                      resetForm={resetForm}
                      value={
                        selectedObj?.WarehouseId
                          ? selectedObj?.WarehouseId
                          : undefined
                      }
                      mode={"view"}
                      required={true}
                    />
                  </div>

                  {selectedObj.WarehouseDetailId &&
                    selectedObj.WarehouseDetailId != GUID_EMPTY && (
                      <div className="col-lg-2">
                        <DictionarySelectBox
                          name="WarehouseDetail"
                          label="WarehouseDetail"
                          params={{ warehouseId: selectedObj?.WarehouseId }}
                          baseUrl={INVENTORYANDSALES_URL}
                          endPoint={
                            ENDPOINTSINVENTORYANDSALES.Inventory
                              .BaseInformationApi.WarehouseDetail.dictionary
                          }
                          value={selectedObj.WarehouseDetailId}
                          resetForm={resetForm}
                          mode={"view"}
                        />
                      </div>
                    )}

                  <div
                    className="col-md-3 col-sm-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Textbox
                      required
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName={"itemCode"}
                      textboxType="text"
                      resetForm={resetForm}
                      labelText={"itemCode"}
                      mode={"view"}
                      value={itemData?.Code}
                      defaultValue={selectedObj.ItemCode}
                      onChange={(e) => {
                        if (
                          e?.target.value === null ||
                          e?.target.value === ""
                        ) {
                          setDetailPriceData({
                            Price: 0,
                            Discount: 0,
                            SubTotalPrice: 0,
                            Qty1: mode != "create" ? selectedObj?.Qty1 : 1,
                            Qty2: mode != "create" ? selectedObj?.Qty2 : 1,
                          });
                          setItemData({});
                          setRate(null);
                        }
                      }}
                    />
                  </div>

                  <div className="col-lg-4">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="ItemDescription"
                      textboxType="text"
                      labelText="ItemReview"
                      value={itemData?.ItemDescription}
                      defaultValue={selectedObj.ItemDescription}
                      resetForm={resetForm}
                      mode={"view"}
                      required={false}
                    />
                  </div>

                  <div className="col-lg-2">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="ItemTechnicalCode"
                      textboxType="text"
                      labelText="TechnicalCode"
                      value={selectedObj.ItemTechnicalCode + ""}
                      defaultValue={selectedObj?.ItemTechnicalCode}
                      resetForm={resetForm}
                      mode="view"
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>

                <div className="row ">
                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Qty1"
                      textboxType="number"
                      labelText="Qty1"
                      readonly={
                        selectedObj?.IsAbsoluteUnitConversionRatio &&
                        numberOfSalesUnit != 1
                      }
                      //   add
                      defaultValue={
                        selectedObj?.IsAbsoluteUnitConversionRatio &&
                        numberOfSalesUnit != 1
                          ? roundOrTruncate(
                              (newDetials?.Qty2 * selectedObj?.Qty1) /
                                selectedObj.Qty2,
                              selectedObj.Unit1RoundingPlace,
                              true
                            ) + ""
                          : selectedObj.Qty1 + ""
                      }
                      value={
                        selectedObj?.IsAbsoluteUnitConversionRatio &&
                        numberOfSalesUnit != 1
                          ? roundOrTruncate(
                              (newDetials?.Qty2 * selectedObj?.Qty1) /
                                selectedObj.Qty2,
                              selectedObj.Unit1RoundingPlace,
                              true
                            ) + ""
                          : selectedObj.Qty1 + ""
                      }
                      max={originalQty.qty1}
                      min={0}
                      resetForm={resetForm}
                      mode={mode }
                      required={true}
                      onChange={(e) => {
                        if (e.target.value == 0) {
                          setShowInvalidValue(true);
                        } else {
                          setShowInvalidValue(false);
                          setNewDetials({
                            ...newDetials,
                            Qty1: +e.target.value,
                            price:
                              numberOfSalesUnit == 1
                                ? +e.target.value * selectedObj.UnitPrice
                                : selectedObj.Price,
                            discount:
                              (+e.target.value *
                                selectedObj.UnitPrice *
                                selectedObj.DiscountPercent) /
                              100,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <UnitDictionary
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      value={selectedObj?.Unit1Id}
                      label="Unit1"
                      name="Unit1Id"
                      required={false}
                      mode={"view"}
                      resetForm={resetForm}
                      setUnitDictionary={(e) => {
                        setDictionaryName((pre) => {
                          return { ...pre, Unit1: e.Value };
                        });
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-12">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName={
                        numberOfSalesUnit == 1 ? "UnitPrice" : "UnitPrice1"
                      }
                      textboxType="text"
                      labelText="UnitPrice1"
                      value={
                        numberOfSalesUnit == 1
                          ? selectedObj?.UnitPrice
                          : selectedObj.SubTotalPrice / selectedObj.Qty1
                      }
                      defaultValue={
                        numberOfSalesUnit == 1
                          ? selectedObj?.UnitPrice
                          : selectedObj.SubTotalPrice / selectedObj.Qty1
                      }
                      resetForm={resetForm}
                      mode={priceList.IsManual ? "create" : "view"}
                      useGroupingPrice={true}
                      onChange={(e) => {
                        if (numberOfSalesUnit == 1)
                          setDetailPriceData({
                            ...detailPriceData,
                            UnitPrice: +e.target.value.replaceAll(",", ""),
                            SubTotalPrice:
                              +e.target.value.replaceAll(",", "") *
                              detailPriceData.Qty1,
                          });
                      }}
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  {selectedObj?.Unit2Id ? (
                    <>
                      <div className="col-lg-3">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName="Qty2"
                          textboxType="number"
                          labelText="Qty2"
                          readonly={
                            selectedObj?.IsAbsoluteUnitConversionRatio &&
                            numberOfSalesUnit != 2
                          }
                          defaultValue={
                            selectedObj?.IsAbsoluteUnitConversionRatio &&
                            numberOfSalesUnit != 2
                              ? roundOrTruncate(
                                  (newDetials?.Qty1 * selectedObj?.Qty2) /
                                    selectedObj.Qty1,
                                  selectedObj.Unit2RoundingPlace,
                                  true
                                ) + ""
                              : selectedObj.Qty2 + ""
                          }
                          value={
                            selectedObj?.IsAbsoluteUnitConversionRatio &&
                            numberOfSalesUnit != 2
                              ? roundOrTruncate(
                                  (newDetials?.Qty1 * selectedObj?.Qty2) /
                                    selectedObj.Qty1,
                                  selectedObj.Unit2RoundingPlace,
                                  true
                                ) + ""
                              : selectedObj.Qty2 + ""
                          }
                          max={originalQty.qty2}
                          min={0}
                          resetForm={resetForm}
                          mode={mode}
                          required={false}
                          onChange={(e) => {
                            if (e.target.value == 0) {
                              setShowInvalidValue(true);
                            } else if (
                              e.target.value ==
                              roundOrTruncate(
                                e.target.value,
                                selectedObj.Unit2RoundingPlace,
                                false
                              )
                            ) {
                              setShowInvalidValue(false);

                              setNewDetials({
                                ...newDetials,
                                Qty2: +e.target.value,
                                price: +e.target.value * selectedObj.UnitPrice,
                                discount:
                                  (+e.target.value *
                                    selectedObj.UnitPrice *
                                    selectedObj.DiscountPercent) /
                                  100,
                              });
                            } else {
                              setShowInvalidValue(false);
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <UnitDictionary
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          value={selectedObj?.Unit2Id}
                          label="Unit2"
                          name="Unit2Id"
                          required={false}
                          mode={"view"}
                          resetForm={resetForm}
                          setUnitDictionary={(e) =>
                            setDictionaryName((pre) => {
                              return { ...pre, Unit2: e.Value };
                            })
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-lg-3 col-12">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName={
                        numberOfSalesUnit == 1 ? "UnitPrice" : "UnitPrice1"
                      }
                      textboxType="text"
                      labelText="UnitPrice2"
                      value={
                        numberOfSalesUnit == 2
                          ? selectedObj?.UnitPrice
                          : selectedObj.SubTotalPrice / selectedObj.Qty2
                      }
                      defaultValue={
                        numberOfSalesUnit == 2
                          ? selectedObj?.UnitPrice
                          : selectedObj.SubTotalPrice / selectedObj.Qty2
                      }
                      resetForm={resetForm}
                      mode={priceList.IsManual ? "create" : "view"}
                      useGroupingPrice={true}
                      onChange={(e) => {
                        if (numberOfSalesUnit == 1)
                          setDetailPriceData({
                            ...detailPriceData,
                            UnitPrice: +e.target.value.replaceAll(",", ""),
                            SubTotalPrice:
                              +e.target.value.replaceAll(",", "") *
                              detailPriceData.Qty1,
                          });
                      }}
                      required={false}
                    />
                  </div>

                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="SubTotalPrice"
                      textboxType="text"
                      labelText="مبلغ"
                      value={
                        newDetials.price
                          ? newDetials.price + ""
                          : selectedObj.SubTotalPrice
                      }
                      decimals={priceList?.PriceRoundingPlace}
                      round={false}
                      defaultValue={
                        newDetials.price
                          ? newDetials.price + ""
                          : selectedObj.SubTotalPrice
                      }
                      resetForm={resetForm}
                      mode={priceList.IsManual ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-2">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="DiscountPercent"
                      textboxType="text"
                      labelText="DiscountPercent"
                      value={
                        selectedObj?.DiscountPercent.toString().slice(0, 4) + ""
                      }
                      defaultValue={
                        selectedObj?.DiscountPercent.toString().slice(0, 4) + ""
                      }
                      max={100}
                      resetForm={resetForm}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      required={false}
                    />
                  </div>
                  <div className="col-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Discount"
                      textboxType="text"
                      labelText="Discount"
                      value={
                        newDetials.discount
                          ? newDetials.discount + ""
                          : selectedObj.Discount + ""
                      }
                      defaultValue={
                        newDetials.discount
                          ? newDetials.discount + ""
                          : selectedObj.Discount + ""
                      }
                      resetForm={resetForm}
                      decimals={0}
                      round={false}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>
                {/* <div className="row justify-content-end">
                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Tax"
                      textboxType="number"
                      labelText="Tax"
                      decimals={priceList?.PriceRoundingPlace}
                      defaultValue={
                        detailPriceData?.TaxRate
                          ? (+detailPriceData?.TaxRate *
                              +detailPriceData?.Price) /
                              100 +
                            ""
                          : "0"
                      }
                      resetForm={resetForm}
                      mode={"view"}
                      required={false}
                    />
                  </div>
                </div> */}
                <div className="row justify-content-end">
                  <div className="col-lg-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Price"
                      textboxType="text"
                      // labelText="Price"
                      labelText="payableamount"
                      value={
                        newDetials?.price
                          ? +newDetials?.price -
                            newDetials.discount +
                            selectedObj.Tax +
                            ""
                          : selectedObj.SubTotalPrice + ""
                      }
                      defaultValue={
                        newDetials?.price
                          ? +newDetials?.price -
                            newDetials.discount +
                            selectedObj.Tax +
                            ""
                          : selectedObj.SubTotalPrice + ""
                      }
                      round={false}
                      resetForm={resetForm}
                      mode={(priceList.IsManual&&mode!="view" ) ? "create" : "view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>

                <div className="row justify-content-end">
                  {detailPriceData?.HasProductTracking && (
                    <>
                      <div className="col-lg-2">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxType="text"
                          textboxName="PartyNo"
                          labelText="PartyNo"
                          defaultValue={
                            mode != "create" ? selectedObj?.PartyNo : null
                          }
                          resetForm={resetForm}
                          mode={mode }
                          required={false}
                        />
                      </div>
                      <div className="col-lg-2">
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName="CargoWeight"
                          textboxType="number"
                          labelText="CargoWeight"
                          defaultValue={
                            mode != "create" ? selectedObj?.CargoWeight : null
                          }
                          resetForm={resetForm}
                          mode={mode }
                          required={false}
                        />
                      </div>
                    </>
                  )}
                  {detailPriceData?.HasExpireDate && (
                    <div className="col-lg-2">
                      <Datepicker
                        mode={mode }
                        lable="ExpireDate"
                        setIsoTime={(isoTime: string) => {
                          setExpireDate(isoTime);
                        }}
                        val={mode != "create" ? selectedObj?.ExpireDate : null} //resetForm ? new Date().toISOString() :
                        errorFlag={false}
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        resetForm={resetForm}
                        required={false}
                      />
                    </div>
                  )}

                  <div className="col">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="textarea"
                      textboxName="Description"
                      minHeight={1}
                      labelText="Remarks"
                      defaultValue={selectedObj?.Description}
                      value={selectedObj?.Description}
                      resetForm={resetForm}
                      mode={mode }
                      required={false}
                      maxLength={150}
                    letterCounter={true}
                    />
                  </div>
                </div>
                <>
                  {mode != "view" ? (
                    <div className="col-lg-12 mt-1 btns_form">
                      <Button value="save" btnType="submit" />
                      <Button
                        value="save&close"
                        btnType="submit"
                        onClick={() => {
                          setSave_Exit(true);
                        }}
                      />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-12 mt-1 btns_form">
                      <Button
                        btnType="primary"
                        value="close"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  )}
                </>
              </form>
            </div>
          ) : (
            <Loading />
          )}
        </div>
        {!!priceListDetail.length && (
          <PriceDetails priceListDetail={priceListDetail} />
        )}
      </div>
    </div>
  );
};
