import { Button } from "components/Button/Button";
import { FileInput } from "components/FileInput/FileInput";
import { useEffect, useState } from "react";
import { importCustomer } from "./api";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";

interface props {
    showForm?: () => any
    reload?: () => any
   
}
export const ImportCustomersForm = ({showForm,reload }:props) => {

    const [resetForm, setResetForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>();

    const handleSelectedFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };
    async function sendDataToServer(event) {
        event.preventDefault();
        try {
            var data = new FormData();
            data.append("file", selectedFile);
            const res = await importCustomer(data,event.target.CustomerOrganizationId.value)
            reload&&reload()
        } catch (error) {
            
        }
       
    }

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);

    return (<>
        <div className="col-lg-12 mb-1">
            <div className="card ">
                <div className="card-body">
                    <form
                        className="row d-flex flex-row"
                        onSubmit={sendDataToServer}
                    >

                        <div className="col-12 col-sm-3">
                        <DictionarySelectBox
                            name='CustomerOrganizationId'
                            label='CustomerOrganization'
                            endPoint={ENDPOINTS.BaseInformation.organization.byType}
                            mode={"create"}
                        />
                        </div>
                        <div className="col-lg-4 ">
                            <FileInput
                                maxSize={25600000}
                                lable="CustomersFile"
                                name="CustomersFile"
                                onChange={handleSelectedFile}
                                hideSrc={true} />
                        </div>
                        <div className="col-lg-12 mt-1 btns_form">
                            <Button value="confirm" btnType="submit" />
                            <Button
                                btnType="cancel"
                                value="Cancel"
                                onClick={() => { setResetForm(true);showForm&& showForm() }}
                            />
                        </div>
                    </form >
                </div>
            </div>
        </div>
    </>)
}