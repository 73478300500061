import { Button } from "components/Button/Button";
import { TabBar } from "components/Tabbar/Tabbar";
import { useEffect, useRef, useState } from "react";

//import { getCustomerForm, sendCustomer } from "./api";

import { Loading } from "components/Loading/loading";
import { InvitationForm } from "./components/InvitationForm/InvitationForm";
import { MeetingForm } from "./components/MeetingForm/MeetingForm";
import { Invitaion } from "./components/Invitaion/Invitaion";
import { sendmeeting } from "./components/MeetingForm/api";
import { InvitedPeopleList } from "./components/InvitedPeopleList/InvitedPeopleList";

interface MeetingTabFormPropsType {
  onHide: () => void;
  reload:(e:boolean)=>void;
  closeForm?:()=>void;
  showForm:any;
  mode: any;
  selectedObj: any;
  jobId?:any;
  sourceId?:any;
  processId?:any;
  referenceId?:any;
  step:any;
}
export const MeetingTabForm = ({ onHide,reload,closeForm,step,selectedObj,showForm, mode,processId,sourceId,jobId ,referenceId  }: MeetingTabFormPropsType) => {
  const [curentTab, setCurentTab] = useState<any>("MeetingForm")
  const MeetingData = useRef<any>();
  const invitaionData = useRef<any>();
  const formRefs = [useRef(), useRef()];
  const [loading , setLoading] = useState(false)
  const [listPosition,setListPosition] = useState<any>({})
  const [invitaionObj, setInvitaionObj] = useState<any>(null); 
  const [acceptExpireTime, setAcceptExpireTime] = useState<any>(null); 
  const lang = localStorage.getItem("lang");
  const delay = (duration) =>
    new Promise(resolve => setTimeout(resolve, duration));
  



  let content = [

    { title: "MeetingForm",body: (<MeetingForm
       jobId={jobId}
       setInvitaionObj={setInvitaionObj}
       processId={processId}
       referenceId={referenceId}
       setAcceptExpireTime={setAcceptExpireTime}
       setListPosition={setListPosition} listPosition={listPosition} formRef={formRefs[0]} handleData={(e)=>MeetingData.current = e} showForm={showForm} step={step} mode={mode} reload={reload} onHide={()=>onHide()}/>), },
  
       { title:step>1?"invitedpeople" : "invitation",body: (
       <>{
        step>1 ?
            <InvitedPeopleList
            meetingId={sourceId}
            JobId={jobId}
            onHide={() => {}}
            mode={step > 1 ? "view" : mode}
         />
        :
        <Invitaion 
          formRef={formRefs[1]}
          intab={curentTab}
          acceptExpireTime={acceptExpireTime}
          InvitaionData={invitaionObj?.Meeting}
          defaultPeopleList={invitaionObj?.InvitedPeople ? invitaionObj?.InvitedPeople :   {"Main" : [
         {PositionId: listPosition?.RequesterPositionId?.Id , Description : listPosition?.RequesterPositionId?.Name }, 
         {PositionId: listPosition?.SecretaryPositionId?.Id, Description : listPosition?.SecretaryPositionId?.Name }, 
         {PositionId: listPosition?.ExecuterPositionId?.Id , Description : listPosition?.ExecuterPositionId?.Name }, 
         {PositionId: listPosition?.BossPositionId?.Id , Description : listPosition?.BossPositionId?.Name }, 
      ]}}
        mode={mode}
        InvitaionFormSubmit={(e)=>{invitaionData.current =e} }/>       
       }
       
       
       
       </>
), },
    ];




    async function sendDataAsync(data, method) {
      try {
   
         const res = await sendmeeting(data, method ,processId ,referenceId);
         sessionStorage.setItem("changingforms" , "false");
         closeForm &&closeForm()
         onHide&&onHide()
         showForm()
      } catch (err: any) {

      }
  }

  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 
  async function formSubmit() {
    await delay(20)
      try {
        if(mode=="create"){
          let obj = {
            Meeting: {
                ...MeetingData.current,
                AcceptExpireTime:invitaionData.current.AcceptExpireTime,
                InvitationText:invitaionData.current.InvitationText,
            } ,
            InvitedPeople:invitaionData.current.InvitedPeople
        }
             sendDataAsync(obj , "post")
        }else{
          let obj = {
            Meeting: {
                ...MeetingData.current,
                AcceptExpireTime:invitaionData.current.AcceptExpireTime,
                InvitationText:invitaionData.current.InvitationText,
            } ,
            InvitedPeople:invitaionData.current.InvitedPeople
        }
             sendDataAsync(obj , "put")

        }
       
        } catch (error) {

        }
        document.body.classList.remove("loading-indicator-"+lang);
}


   const handleSubmitAllForms = async(e) => {
  let flag=true ; 
  await Promise.all(
    formRefs.map(async (formRef:any) => {
      if(formRef.current.checkValidity()&&flag){
        formRef.current.hidebutton?.click();
      }else if(flag){
        flag = false
        setCurentTab(formRef.current.name)
        await delay(50)
        formRef.current.reportValidity()
      }
    })
  );
  await delay(50)
  if(MeetingData.current!=undefined && invitaionData.current!=undefined){
      formSubmit()
  }else{
    document.body.classList.remove("loading-indicator-"+lang);
  }  

  };


useEffect(() => {
      if (mode!="create"&& selectedObj?.Id) {

    }else{
      setLoading(true)
    }
}, []);

useEffect(() => {
    


}, [invitaionObj]);


  return (
    <>
    
      <div className="d-flex justify-content-end mt-2" style={{position:"absolute",left:"6px" , zIndex:"1"}}>
        { (mode != "view" && step<2)?
          
          <Button onClick={handleSubmitAllForms} btnType="submit" value={mode=="create"? "saveandreference" :"sendinvitationandrefer"}  />
          :
          <></>
          
        }
      </div>
      {mode=="view" ?
      <MeetingForm
      jobId={jobId}
      setAcceptExpireTime={setAcceptExpireTime}

      setInvitaionObj={setInvitaionObj}
      processId={processId}
      referenceId={referenceId}
      setListPosition={setListPosition} listPosition={listPosition} formRef={formRefs[0]} handleData={(e)=>MeetingData.current = e} showForm={showForm} step={step} mode={mode} reload={reload} onHide={()=>onHide()}/>
    :
    <TabBar  tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"MeetingForm"} />

    }
       </>

    
  )
}