import React, { useCallback, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "components/Message/Message";
import { PaginationNavbar } from "components/Pagination/Pagination";
import { Toast } from "components/ToastShow/ToastShow";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { EditableInput } from "./EditableInput";
import { GridSetting } from "./GridSetting";
import "./style.scss";
import { DateFilter } from "./DateFilter";

const createHeaders = (headers, parentName, startIndex, setRemovedColumns) => {
  let head = headers;

  let setHead: any = [];
  let widths = [];
  let hedTables = localStorage.getItem("userHeadTable");
  let locallist;

  if (hedTables == null) {
    locallist = [];
  } else {
    locallist = JSON.parse(hedTables);
  }
  locallist.forEach((item) => {
    if (item[0] == parentName) {
      if (item[1].length == head.length - startIndex) {
        let switchHed = false;
        item[1].forEach((el) => {
          if (head.indexOf(el) == -1) {
            switchHed = true;
          }
        });
        if (!switchHed) {
          head = item[1];
          widths = item[2];
          head.forEach((item, index) => {
            setHead.push(
              widths[index]
                ? {
                    text: item,
                    ref: useRef(),
                    Id: headers.indexOf(item),
                    width: widths[index],
                  }
                : { text: item, ref: useRef(), Id: headers.indexOf(item) }
            );
          });

          if (item[6] && item[6] != undefined) {
            setRemovedColumns(item[6]);
          }
        }
      }
    }
  });

  if (setHead.length == 0) {
    head.forEach((item, index) => {
      if (index >= startIndex) {
        setHead.push(
          widths[index] && widths[index] != undefined
            ? {
                text: item,
                ref: useRef(),
                Id: headers.indexOf(item),
                width: widths[index],
              }
            : { text: item, ref: useRef(), Id: headers.indexOf(item) }
        );
      }
    });
  }
  return setHead;
};
interface DataGridEditablePropsType {
  theadData: any;
  tbodyData: any;
  minCellWidth?: number;
  multiSelect?: boolean;
  editablecolumns?: any;
  multiSelecttype?: number;
  selectedRowProp?: any;
  resetMultiSelect?: boolean;
  View?: boolean;
  parentName?: any;
  customFuction?: any;
  startIndex?: number;
  selectList?: (e?: any) => void;
  selectItem?: (e) => void;
  theaxCells?: (e) => void;
  selectedRowObjectCallback?: (obj: any, index, event) => void;
  changeingDataCallback?: (data: any) => void;
  editAllGrid?: any;
  RowKey?: any;
  rowSelected?: any;
  handleEdit?: (id: string) => void;
  handleDelete?: ((id: string) => void) | null;
  handleView?: (id: string) => void;
  handleDownload?: (id: string) => void;
  handleAttachment?: (id: string) => void;
  handleCreate?: (flag: boolean) => void;
  handlefiltering?: (filterStr: string | object) => void;
  filterStr?: string;
  totalRecords?: number;
  pageNum?: number;
  pageSize?: number;
  HandlePrevPage?: () => void;
  HandleNextPage?: () => void;
  handlechangePageSize?: (e) => void;
  first?: () => void;
  end?: () => void;
  handleGoPage?: (e) => void;
  orderFormat?: (e) => void;
  orderFormatList?: any;
  multiSelectCallback?: (e: any) => void;
  checkedRowEventCallback?: (e: any, item: any) => void;
  DoubleClick?: (e: any) => void;
  //ActiveRow?: number;
  scrollHeight?: any;
  ViewParametr?: string;
  disabled?: boolean;
  mode?: any;
  showForm?: boolean;
  defaultValueOption?: any;
  columnsWithOption?: any;
  setEditAllGrid?: any;
  dependsValue?: any;
}

export const DataGridEditable: React.FC<DataGridEditablePropsType> = ({
  theadData,
  tbodyData,
  minCellWidth,
  editablecolumns,
  multiSelect,
  multiSelecttype = 1,
  selectedRowProp,
  resetMultiSelect,
  View = false,
  parentName,
  customFuction = [],
  startIndex = 1,
  selectList,
  selectItem,
  theaxCells,
  selectedRowObjectCallback,
  changeingDataCallback,
  editAllGrid,
  RowKey,
  rowSelected,
  handleEdit,
  handleDelete,
  handleView,
  handleDownload,
  handleAttachment,
  handleCreate,
  handlefiltering,
  filterStr,
  totalRecords,
  pageNum,
  pageSize,
  HandlePrevPage,
  HandleNextPage,
  handlechangePageSize,
  first,
  end,
  handleGoPage,
  orderFormat,
  orderFormatList,
  multiSelectCallback,
  checkedRowEventCallback,
  DoubleClick,
  //ActiveRow,
  scrollHeight = totalRecords === 0 && tbodyData.length === 1 ? 55 : null,
  ViewParametr = "ViewIcon",
  disabled = false,
  showForm = false,
  mode = false,
  defaultValueOption,
  columnsWithOption,
  setEditAllGrid,
  dependsValue,
}) => {
  ///const [activeRow, setActiveRow] = useState<any>( -2);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [operationSubIndex, setOperationSubIndex] = useState<any>(-2);
  const [flag, setFlag] = useState(false);
  const [inEditCol, setInEditCol] = useState<any>([]);
  //const [changedData, setChangedData] = useState<any>({});
  const changedDataRef = useRef<any>({});
  const myStateRef = useRef<any>(null);
  const SubIndexRef = useRef(operationSubIndex);
  const tbody = JSON.parse(JSON.stringify(tbodyData));
  const rtlDataGridEditableDirection =
    localStorage.getItem("lang") === "fa" ? true : false;
  const tableElement = useRef(null);
  let selectedIndex: any;
  const hasOperation =
    handleDelete ||
    handleEdit ||
    handleView ||
    handleDownload ||
    handleAttachment ||
    customFuction.length > 0
      ? true
      : false;
  const [hasmultiSelector, setHasmultiSelector] = useState(multiSelect);
  let localFlag = true;
  let pages;
  let columns = JSON.parse(JSON.stringify(theadData));
  let widthColumns = [];
  let a = false;
  let ummont = true;
  let operationList = [
    ...customFuction,
    handleDelete,
    handleEdit,
    handleView,
    handleDownload,
    handleAttachment,
  ].filter((e) => {
    if (e) {
      return e;
    }
  });

  if (multiSelect) columns.splice(startIndex, 0, "SelectAll");
  if (hasOperation) columns.splice(startIndex, 0, "Operation");

  let removed: any = [];
  let [hedcolums, setHedcolums] = useState<any>(
    createHeaders(columns, parentName, startIndex, (e) => {
      removed = e;
    })
  );

  let DefaultMinCellWidth = 30;
  let gridTemplateColumnsStyle: string[] = [];
  let tableHeight = tbody?.length * 25 + 25 + "px";

  const [activeIndex, setActiveIndex] = useState(null);
  const [removedColumns, setRemovedColumns] = useState<any>(removed);
  const activeIndexRef = useRef<any>(activeIndex);
  const [currentOperation, setCurrentOperation] = useState(-1);
  const [showOperations, setShowOperations] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const [filterList, setFilterList] = useState<any>(filterStr ? filterStr : []);
  const [filterListValuse, setFilterListValuse] = useState<any>([]);
  const [sortingFormat, setSortingFormat] = useState<any>(
    orderFormatList ? orderFormatList : []
  );
  const [filterFlag, setFilterFlag] = useState<any>(true);
  const [hoverSource, setHoverSource] = useState<any>();
  const [operationPosition, setOperationPosition] = useState<any>(0);
  theaxCells && theaxCells(hedcolums);

  async function handleRemoveFilterList() {
    let hedTables = localStorage.getItem("userHeadTable");
    let locallist;
    if (hedTables == null) {
      locallist = [];
    } else {
      locallist = JSON.parse(hedTables);
    }
    locallist.forEach((item) => {
      if (item[0] === parentName) {
        item[4] = "";
        item[5] = [];
      }
    });
    localStorage.setItem("userHeadTable", JSON.stringify(locallist));
    try {
      handlefiltering && handlefiltering({ Filterlist: "", OrderFormat: [] });
    } catch (error) {}
  }

  const kydown = (event) => {
    pages =
      totalRecords && pageSize && totalRecords > pageSize
        ? Math.ceil(totalRecords / pageSize)
        : 1;
    if (document.getElementsByClassName("fullscreen-bg-modal").length === 0) {
      if (event.ctrlKey && event.key.includes("Delete")) {
        event.preventDefault();
        handleRemoveFilterList();
        event.returnValue = false;
        return false;
      }
      if (myStateRef.current > -2) {
        if (event.key == "ArrowDown") {
          if (hasOperation && SubIndexRef.current !== -2) {
            if (operationList.length === SubIndexRef.current + 1) {
              SubIndexRef.current = 0;
              setOperationSubIndex(SubIndexRef.current);
            } else {
              SubIndexRef.current += 1;
              setOperationSubIndex(SubIndexRef.current);
            }
          } else {
            if (tbody.length === myStateRef.current + 1) {
              if (HandleNextPage && pages !== pageNum) {
                HandleNextPage();
              }
            } else if (tbody.length > myStateRef.current + 1) {
              myStateRef.current += 1;
              //PROF
              toggleActiveRow(event, myStateRef.current);
            }
          }
        } else if (event.key === "ArrowUp") {
          if (hasOperation && SubIndexRef.current !== -2) {
            if (0 === SubIndexRef.current) {
              SubIndexRef.current = operationList.length - 1;
              setOperationSubIndex(SubIndexRef.current);
            } else {
              SubIndexRef.current -= 1;
              setOperationSubIndex(SubIndexRef.current);
            }
          } else {
            if (0 >= myStateRef.current) {
              if (HandlePrevPage && pageNum !== 1) {
                HandlePrevPage();
              }
            } else if (0 < myStateRef.current) {
              myStateRef.current -= 1;
              toggleActiveRow(event, myStateRef.current);
            }
          }
        } else if (
          event.key === "Enter" &&
          document?.activeElement?.className.indexOf("ck") === -1 &&
          document?.activeElement?.tagName != "TEXTAREA" &&
          document?.activeElement?.tagName != "INPUT"
        ) {
          // document.getElementById(`trbody${index}`)?.click()
          if (
            hasOperation &&
            SubIndexRef.current !== -2 &&
            operationList[SubIndexRef.current]
          ) {
            if (typeof operationList[SubIndexRef.current] == "function") {
              operationList[SubIndexRef.current](
                tbodyData[myStateRef.current].Id
              );
            } else if (
              typeof operationList[SubIndexRef.current][0] == "function"
            ) {
              operationList[SubIndexRef.current][0](
                tbodyData[myStateRef.current].Id
              );
            }
          } else {
            //DoubleClick ? DoubleClick(tbodyData[myStateRef.current]) : (handleView &&handleView(tbodyData[myStateRef.current].Id))
          }
        } else if (event.key === "ArrowRight") {
          if (hasOperation) {
            toggleShowOperations(myStateRef.current);
            SubIndexRef.current = 0;
            setOperationSubIndex(SubIndexRef.current);
          }
        } else if (event.key === "ArrowLeft") {
          if (hasOperation) {
            toggleShowOperations(-1);
            SubIndexRef.current = -2;
            setOperationSubIndex(SubIndexRef.current);
          }
        }
        if (
          document.activeElement?.className.includes("filterInput") &&
          event.key == "Enter"
        ) {
          //// filtering()
        }
      } else if (event.key === "ArrowDown") {
        toggleActiveRow(event, 0);
        myStateRef.current = 0;
      } else if (event.key === "ArrowUp") {
        toggleActiveRow(event, pageSize && pageSize - 1);
        myStateRef.current = pageSize && pageSize - 1;
      }
    }
  };
  useEffect(() => {
    if (
      (myStateRef.current === -2 || rowSelected == null) &&
      ((totalRecords && totalRecords > 0) || totalRecords == null)
    ) {
      myStateRef.current = 0;
      // setActiveRow(0);
      // فرستادن مقدار آیدی به والد
      selectedRowObjectCallback &&
        selectedRowObjectCallback(tbodyData[0], 0, "");
    }
    document.addEventListener("keydown", kydown);

    return () => {
      document.removeEventListener("keydown", kydown);
    };
  }, [rowSelected]);
  //     useEffect(() => {
  //         if(rowSelected==null){
  //             myStateRef.current = 0
  //            //setActiveRow(0);
  // /*             // فرستادن مقدار آیدی به والد
  //             selectedRowObjectCallback && selectedRowObjectCallback(tbodyData[0], 0 ,""); */
  //         }
  //     }, [rowSelected])

  function toggleShowOperations(operationIndex) {
    setCurrentOperation(operationIndex);
    if (operationIndex === currentOperation || currentOperation === -1) {
      setShowOperations(!showOperations);
    } else setShowOperations(true);
  }

  function blurBtn(event) {
    event.currentTarget.parentElement?.parentElement?.blur();
  }

  async function toggleActiveRow(event, rowIndex) {
    event.stopPropagation();
    myStateRef.current = rowIndex;
    // setActiveRow(rowIndex);
    // فرستادن مقدار آیدی به والد
    selectedRowObjectCallback &&
      (await selectedRowObjectCallback(tbodyData[rowIndex], rowIndex, event));
  }

  function createSortingFormat(text, event) {
    let itemExists = false;
    let goPageFormat = false;

    let Sorting: any = [];
    /*         if (sortingFormat.length == 0) {
                    setSortingFormat([`${text} asc`])
                } else */
    if (event.ctrlKey) {
      let newSortingFormat: string[] = [];
      for (let i = 0; i < sortingFormat.length; i++) {
        if (sortingFormat[i].includes(text)) {
          // اگر عنوان ستون داخل یکی از رشته ها بود
          itemExists = true;
          if (sortingFormat[i].includes("asc")) {
            newSortingFormat.push(sortingFormat[i].replace("asc", "desc"));
          } else if (sortingFormat[i].includes("desc")) {
            // اگر ترتیب ستون نزولی بود کاری انجام نمی دهیم تا نام این ستون دیگر در لیست مرتب سازی قرار نگیرد
          }
          if (i == 0) {
            goPageFormat = true;
          }
        } else {
          // یعنی اگر پیمایش آرایه به آخر رسید
          newSortingFormat.push(sortingFormat[i]);
        }
      }
      if (itemExists) {
        Sorting = newSortingFormat;
      } else {
        // Sorting = JSON.parse(JSON.stringify(sortingFormat))
        newSortingFormat.push(`${text} asc`);
        if (newSortingFormat.length === 1) {
          goPageFormat = true;
        }
        Sorting = newSortingFormat;
        //  setSortingFormat((old) => { return [...old, `${text} asc`] })
      }
    } else {
      let newSortingFormat: string[] = [];

      if (sortingFormat.length > 0 && sortingFormat[0].includes(text)) {
        if (sortingFormat[0].includes("asc")) {
          newSortingFormat.push(sortingFormat[0].replace("asc", "desc"));
        }
        Sorting = newSortingFormat;
      } else {
        newSortingFormat.push(`${text} asc`);
        Sorting = newSortingFormat;
      }
    }
    // setSortingFormat(Sorting)
    let hedTables = localStorage.getItem("userHeadTable");
    let locallist;
    if (hedTables == null) {
      locallist = [];
    } else {
      locallist = JSON.parse(hedTables);
    }
    locallist.forEach((item) => {
      if (item[0] === parentName) {
        item[5] = Sorting;
      }
    });
    localStorage.setItem("userHeadTable", JSON.stringify(locallist));
    orderFormat && orderFormat(Sorting);
    if ((goPageFormat && pageNum !== 1) || event.ctrlKey) {
      handleGoPage && handleGoPage(1);
    }
  }
  function toggleSortingFormat(text) {
    for (let i = 0; i < sortingFormat.length; i++) {
      if (sortingFormat[i].split(" ")[0] == text) {
        if (sortingFormat[i].includes("asc")) return ["up", i];
        else if (sortingFormat[i].includes("desc")) return ["down", i];
      }
    }
    return "";
  }

  // useEffect(() => {
  // }, [sortingFormat])

  if (hasOperation) {
    if (
      hedcolums[2] &&
      hedcolums[2].width &&
      hedcolums[2].width !== undefined
    ) {
      if (hasmultiSelector) {
        gridTemplateColumnsStyle[0] = "24px";
        gridTemplateColumnsStyle[1] = "24px";
        for (let i = 2; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push(
                `${
                  hedcolums[i].width === undefined ? "24px" : hedcolums[i].width
                }`
              );
            } else {
              if (hedcolums[i].width === "24px") {
                gridTemplateColumnsStyle.push(hedcolums[i].width);
              } else {
                gridTemplateColumnsStyle.push(
                  `${
                    hedcolums[i].width === undefined
                      ? "120px"
                      : hedcolums[i].width
                  }`
                );
              }
            }
          }
        }
      } else {
        gridTemplateColumnsStyle[0] = "24px";

        for (let i = 1; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push(
                `${
                  hedcolums[i].width === undefined ? "24px" : hedcolums[i].width
                }`
              );
            } else {
              if (
                hedcolums[i].width === "24px" ||
                hedcolums[i].width === "24px"
              ) {
                gridTemplateColumnsStyle.push(hedcolums[i].width);
              } else {
                gridTemplateColumnsStyle.push(
                  `${
                    hedcolums[i].width === undefined
                      ? "120px"
                      : hedcolums[i].width
                  }`
                );
              }
            }
          }
        }
      }
    } else {
      if (hasmultiSelector) {
        gridTemplateColumnsStyle[0] = "24px";
        gridTemplateColumnsStyle[1] = "24px";
        for (let i = 2; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push("24px");
            } else {
              gridTemplateColumnsStyle.push("minmax(120px,auto)");
            }
          }
        }
      } else {
        gridTemplateColumnsStyle[0] = "24px";

        for (let i = 1; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push("24px");
            } else {
              gridTemplateColumnsStyle.push("minmax(120px,auto)");
            }
          }
        }
      }
    }
  } else {
    if (hedcolums[1]?.width && hedcolums[1].width !== undefined) {
      for (let i = 0; i < hedcolums.length; i++) {
        if (!removedColumns.includes(hedcolums[i].text)) {
          if (hedcolums[i].text.includes("Icon")) {
            gridTemplateColumnsStyle.push(
              `${
                hedcolums[i].width === undefined ? "24px" : hedcolums[i].width
              }`
            );
          } else {
            if (hedcolums[i].width === "24px") {
              gridTemplateColumnsStyle.push(hedcolums[i].width);
            } else {
              gridTemplateColumnsStyle.push(
                `${
                  hedcolums[i].width === undefined
                    ? "120px"
                    : hedcolums[i].width
                }`
              );
            }
          }
        }
      }
    } else {
      if (hasmultiSelector) {
        gridTemplateColumnsStyle[0] = "24px";
        for (let i = 1; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push("24px");
            } else {
              gridTemplateColumnsStyle.push("minmax(120px,auto)");
            }
          }
        }
      } else {
        for (let i = 0; i < hedcolums.length; i++) {
          if (!removedColumns.includes(hedcolums[i].text)) {
            if (hedcolums[i].text.includes("Icon")) {
              gridTemplateColumnsStyle.push("24px");
            } else {
              gridTemplateColumnsStyle.push("minmax(120px,auto)");
            }
          }
        }
      }
    }
  }

  let Rows = tbodyData.map((item, index) => {
    // محتوای جدول
    let tr: any = JSON.parse(JSON.stringify(item));

    if (hasmultiSelector) {
      tr.SelectAll = (
        <input
          tabIndex={-1}
          key={index + "checkbox"}
          type={"checkbox"}
          disabled={
            multiSelecttype !== 2 ? false : hasSelect(item) ? false : true
          }
          id={`checkbox ${item.Id}`}
          className={`${parentName} checkBox`}
          onClick={(e) => {
            multiSelector(item);
            selectorEvent(e, item);
          }}
        />
      );

      /*             tr.splice(1, 0, // در اینجا محتوای ستون عملیات قرار مقدار دهی می شود
            
                            <input type={"checkbox"} id={`checkbox ${item.Id}`} className={`${parentName} checkBox`} onClick={()=>{multiSelector(item)}} />
            
                        ); */
    }

    if (hasOperation) {
      tr.Operation = (
        <div key={index + "operations"} className="operations">
          <button
            id={"Operation" + index}
            tabIndex={-1}
            type="button"
            className="operations__trigger-btn"
            onClick={(e) => {}}
            onFocus={(e) => {
              if (
                window.innerHeight - e.target.getBoundingClientRect().top <
                (customFuction.length + 2) * 25
              ) {
                setOperationPosition((customFuction.length + 2) * 25);
              } else {
                setOperationPosition(0);
              }
              toggleShowOperations(index);
              SubIndexRef.current = 0;
            }}
            onBlur={() => {
              toggleShowOperations(index);
              setShowOperations(false);
              SubIndexRef.current = -3;
            }}
          >
            <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
            <div
              style={{ top: `${index * 25 + 40 - operationPosition}px` }}
              className={`operations__operations-icons pt-1 ${
                showOperations &&
                index === currentOperation &&
                SubIndexRef.current !== -3
                  ? ""
                  : "d-none"
              }`}
            >
              {customFuction &&
                customFuction.map((item2, index2) => {
                  if (item2[3] !== undefined && item2[3].length > 1) {
                    if (item[item2[3]] === item2[4]) {
                      return (
                        <div
                          key={index2}
                          className={`operations__operations-icon-wrapper ${
                            operationList.indexOf(item2) === operationSubIndex
                              ? "subFocos"
                              : " "
                          }`}
                          onClick={blurBtn}
                        >
                          <div
                            className="operations__operations-icon-wrapper"
                            onClick={() => item2[0](item)}
                          >
                            <FontAwesomeIcon
                              icon={[ICON_TYPE, item2[1]]}
                              className="text-primary"
                            />
                            <p>{trans(item2[2])}</p>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div
                        key={index2}
                        className={`operations__operations-icon-wrapper ${
                          operationList.indexOf(item2) === operationSubIndex
                            ? "subFocos"
                            : " "
                        }`}
                        onClick={blurBtn}
                      >
                        <div
                          className="operations__operations-icon-wrapper"
                          onClick={() => item2[0](item)}
                        >
                          <FontAwesomeIcon
                            icon={[ICON_TYPE, item2[1]]}
                            className="text-primary"
                          />
                          <p>{trans(item2[2])}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              {handleDelete && (
                <div
                  className={`operations__operations-icon-wrapper ${
                    operationList.indexOf(handleDelete) === operationSubIndex
                      ? "subFocos"
                      : " "
                  }`}
                  onClick={blurBtn}
                >
                  <div
                    className="operations__operations-icon-wrapper"
                    onClick={() => {
                      handleDelete(item["Id"]);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_TYPE, "trash-alt"]}
                      className="text-primary"
                    />
                    <p>{trans("Delete")}</p>
                  </div>
                </div>
              )}
              {handleEdit && (
                <div
                  className={`operations__operations-icon-wrapper ${
                    operationList.indexOf(handleEdit) === operationSubIndex
                      ? "subFocos"
                      : " "
                  }`}
                  onClick={blurBtn}
                >
                  <div
                    className="operations__operations-icon-wrapper"
                    onClick={() => handleEdit(item["Id"])}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_TYPE, "edit"]}
                      className="text-primary"
                    />
                    <p>{trans("Edit")}</p>
                  </div>
                </div>
              )}
              {handleView && (
                <div
                  className={`operations__operations-icon-wrapper ${
                    operationList.indexOf(handleView) === operationSubIndex
                      ? "subFocos"
                      : " "
                  }`}
                  onClick={blurBtn}
                >
                  <div
                    className="operations__operations-icon-wrapper"
                    onClick={() => handleView(item["Id"])}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_TYPE, "eye"]}
                      className="text-primary"
                    />
                    <p>{trans("View")}</p>
                  </div>
                </div>
              )}
              {handleDownload && (
                <div
                  className={`operations__operations-icon-wrapper ${
                    operationList.indexOf(handleDownload) - 1 ===
                    operationSubIndex
                      ? "subFocos"
                      : " "
                  }`}
                  onClick={blurBtn}
                >
                  <div
                    className="operations__operations-icon-wrapper"
                    onClick={() => handleDownload(item["Id"])}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_TYPE, "download"]}
                      className="text-primary"
                    />
                    <p>{trans("Download")}</p>
                  </div>
                </div>
              )}
              {handleAttachment && (
                <div
                  className={`operations__operations-icon-wrapper ${
                    operationList.indexOf(handleAttachment) ===
                    operationSubIndex
                      ? "subFocos"
                      : " "
                  }`}
                  onClick={blurBtn}
                >
                  <div
                    className="operations__operations-icon-wrapper"
                    onClick={() => handleAttachment(item["Id"])}
                  >
                    <FontAwesomeIcon
                      icon={[ICON_TYPE, "paperclip"]}
                      className="text-primary"
                    />
                    <p>{trans("Attachment")}</p>
                  </div>
                </div>
              )}
            </div>
          </button>
        </div>
      );
    }

    return tr;
  });

  function getTh(e, index) {
    e.stopPropagation();
    selectedIndex = index;
  }
  function setTh(e, index) {
    if (selectedIndex > 0) {
      let putIndex = index;
      if (hasOperation) {
        if (putIndex > 1) {
          let selectIndex: any = selectedIndex;
          let arryHed = hedcolums;
          let selectHed = arryHed[selectIndex];
          arryHed.splice(selectIndex, 1);
          arryHed.splice(putIndex, 0, selectHed);

          setHedcolums(arryHed);
          setTimeout(() => {
            setFlag(!flag);
          }, 5);
        }
      } else {
        if (putIndex > 0) {
          let selectIndex: any = selectedIndex;
          let arryHed = hedcolums;
          let selectHed = arryHed[selectIndex];
          arryHed.splice(selectIndex, 1);
          arryHed.splice(putIndex, 0, selectHed);
          setHedcolums(arryHed);

          setTimeout(() => {
            setFlag(!flag);
          }, 5);
        }
        setFlag(!flag);
      }
    }
  }

  function multiSelectAll(e) {
    let checkboxs: any = Object.values(
      document.getElementsByClassName(`${parentName} checkBox`)
    );
    if (multiSelecttype !== 2) {
      if (e.target.checked) {
        setCheckedAll(true);
        checkboxs.forEach((item) => {
          item.checked = true;
        });
        setSelectedRow([]);
        setSelectedRow(tbody);
        selectList && selectList([parentName, tbody]);
        setFlag(!flag);
      } else {
        setCheckedAll(false);
        checkboxs.forEach((item) => {
          item.checked = false;
        });
        setSelectedRow([]);
        setFlag(!flag);
        selectList && selectList([parentName, []]);
      }
      setFlag(!flag);
    } else {
      if (!e.target.checked) {
        setCheckedAll(false);
        checkboxs.forEach((item) => {
          item.checked = false;
        });
        setSelectedRow([]);
        setFlag(!flag);
        selectList && selectList([parentName, []]);
        multiSelectCallback && multiSelectCallback(false);
      } else {
        selectList && selectList([parentName, tbody]);
        multiSelectCallback && multiSelectCallback(true);
      }
    }

    // ***** for test *****
  }
  async function onContextMenu(e, index, id) {
    e.preventDefault();
    myStateRef.current = index;
    selectedRowObjectCallback &&
      (await selectedRowObjectCallback(tbodyData[index], index, e));
    document.getElementById("Operation" + index)?.focus();
    toggleShowOperations(index);
    SubIndexRef.current = 0;

    if (
      myStateRef.current === index ||
      (rowSelected && rowSelected.Id === id && myStateRef.current !== 0)
    ) {
    }
    return false;
  }

  useEffect(() => {
    setSelectedRow([]);
    setCheckedAll(false);
  }, [parentName]);

  useEffect(() => {
    if (selectedRowProp) {
      let seletedList: any = selectedRow;

      selectedRowProp.forEach((item) => {
        let flag = false;
        selectedRow.forEach((item2) => {
          if (item2.Id === item.Id) {
            flag = true;
          }
        });
        if (!flag) {
          seletedList.push(item);
        }
      });

      let checkboxs: any = Object.values(
        document.getElementsByClassName(`${parentName} checkBox`)
      );

      if (selectedRowProp[0]?.Id === "All") {
        setCheckedAll(true);
        setSelectedRow([]);
        setSelectedRow(tbody);
        if (checkboxs.length > 0) {
          checkboxs.forEach((item1) => {
            item1.checked = true;
          });
        }
      } else {
        if (checkboxs.length > 0) {
          checkboxs.forEach((item1) => {
            let flag = false;
            seletedList.forEach((item2) => {
              if (item1.id.slice(9, 45) === item2.Id) {
                flag = true;
                item1.checked = true;
                item1.disabled = false;
              }
            });
            if (!flag) {
              item1.checked = false;
              if (multiSelecttype !== 3) {
                item1.disabled = true;
              } else {
                item1.disabled = false;
              }
            }
          });
        }

        // setSelectedRow(seletedList)
        // selectList && selectList([parentName, seletedList])
      }
    }
  }, [tbodyData, selectedRowProp]);

  function selectorEvent(e, item) {
    checkedRowEventCallback && checkedRowEventCallback(e.target.checked, item);
  }

  function multiSelector(e) {
    selectItem && selectItem(e);
    if (multiSelecttype === 3) {
      let seletedList: any = [];
      if (selectedRowProp?.length > 0) {
        seletedList = selectedRowProp;
      }
      let index1 = seletedList.findIndex((item, index) => {
        if (item.Id === e.Id) {
          return true;
        }
      });
      if (index1 > -1) {
        seletedList.splice(index1, 1);
        setCheckedAll(false);
      } else {
        seletedList.splice(0, 0, e);
        setSelectedRow(seletedList);
      }
      selectList && selectList([parentName, seletedList]);
    } else {
      let seletedList: any = selectedRow;
      let index1 = selectedRow.findIndex((item, index) => {
        if (item.Id === e.Id) {
          return true;
        }
      });
      if (index1 > -1) {
        seletedList.splice(index1, 1);
        setCheckedAll(false);
      } else {
        seletedList.splice(0, 0, e);
        setSelectedRow(seletedList);
      }
      selectList && selectList([parentName, selectedRow]);
    }
  }
  function hasSelect(e) {
    let seletedList: any = selectedRow;
    let index1 = seletedList.findIndex((item, index) => {
      if (item.Id === e.Id) {
        return true;
      }
    });
    if (index1 > -1) {
      return true;
    } else {
      return false;
    }
  }

  function filterMaker(e) {
    setAutoFocus(true);
    let newList: any = [];
    let flag = false;
    filterList.forEach((item, index) => {
      if (item !== e) {
        newList.push(item);
      } else {
        flag = true;
        setFilterListValuse(filterListValuse.splice(index, 1));
      }
    });
    if (!flag) {
      newList.push(e);
      setFilterList(newList);
    }
    if (flag) {
      filtering(newList);
    }
    return flag;
  }

  function filtering(List = filterList) {
    let filterString = "";
    if (document.forms["Filtering" + parentName]["filter"]) {
      if (
        document.forms["Filtering" + parentName]["filter"].length > 1 &&
        List.length > 0
      ) {
        document.forms["Filtering" + parentName]["filter"].forEach((item) => {
          if (List.includes(item.id) && item.value.length > 0) {
            filterString += `${item.id}='${item.value}',`;
          }
        });
      } else {
        if (
          List.includes(
            document.forms["Filtering" + parentName]["filter"].id
          ) &&
          document.forms["Filtering" + parentName]["filter"].value.length > 0
        ) {
          filterString += `${
            document.forms["Filtering" + parentName]["filter"].id
          }='${document.forms["Filtering" + parentName]["filter"].value}',`;
        }
      }
      if (filterString.length > 0) {
        handlefiltering && handlefiltering(filterString);
      } else {
        if (filterFlag) {
          setFilterList(List);
        } else {
          handlefiltering && handlefiltering(filterString);
        }
      }
    } else {
      handlefiltering && handlefiltering("");
    }
    let hedTables = localStorage.getItem("userHeadTable");
    let locallist;
    if (hedTables == null) {
      locallist = [];
    } else {
      locallist = JSON.parse(hedTables);
    }
    locallist.forEach((item) => {
      if (item[0] === parentName) {
        item[4] = filterString;
      }
    });
    localStorage.setItem("userHeadTable", JSON.stringify(locallist));
  }
  function updateInEditColList(name) {
    setEditAllGrid(0);

    if (inEditCol.includes(name)) {
      /* setEditAllGrid(0);

      let flag = true;
      for (const property in changedDataRef.current) {
        if (
          changedDataRef.current[property][name] &&
          changedDataRef.current[property][name] != undefined
        ) {
          flag = false;

          break;
        }
      }
      if (!flag) {
        Toast(
          trans("modalclosemessage"),
          "warning",
          "alertWithoutClose",
          () => {
            let newList = inEditCol.filter((item) => item != name);
            let newObj = JSON.parse(JSON.stringify(changedDataRef.current));
            for (const property in newObj) {
              delete newObj[property][name];
            }
            if (newList.length == 0) {
              changedDataRef.current = {};
              changeingDataCallback &&
                changeingDataCallback(changedDataRef.current);
            } else {
              changedDataRef.current = newObj;
              changeingDataCallback &&
                changeingDataCallback(changedDataRef.current);
            }

            setInEditCol(newList);
          }
        );
      } else {
        setEditAllGrid(0);
        let newList = inEditCol.filter((item) => item != name);
        setInEditCol(newList);
      } */
        setEditAllGrid(3);
        setInEditCol([...inEditCol, name]);
    } else {
      setEditAllGrid(3);
      setInEditCol([...inEditCol, name]);
    }
  }
  function checkChangedRow(ChangedData , data){
        let change = false ; 
        Object.keys(editablecolumns).map(item =>{
          if(ChangedData[item]!= data[item]&&ChangedData[item]!=undefined){
            change=true}
        })
        return change
  }

 async function addRowChange(text, row, cell) {
    if ((text != row[cell]  ) ||(+text != row[cell] && +text?.length > 0) || typeof text == "boolean") {
      if (!!dependsValue?.length && cell === dependsValue[0]) {
        if (text && text != -1 && text != 0) {
          changedDataRef.current = {
            ...changedDataRef.current,
            [row[RowKey]]: {
              ...row,
              ...changedDataRef.current[row.Id],
              [cell]: typeof text == "boolean" ? text : +text,
              [dependsValue[1]]: true,
            },
          };
        } else {
          changedDataRef.current = {
            ...changedDataRef.current,
            [row[RowKey]]: {
              ...row,
              ...changedDataRef.current[row.Id],
              [cell]: typeof text == "boolean" ? text : +text,
              [dependsValue[1]]: false,
            },
          };
        }
        changeingDataCallback && changeingDataCallback(changedDataRef.current);
      } else {
        changedDataRef.current = {
          ...changedDataRef.current,
          [row[RowKey]]: {
            ...row,
            ...changedDataRef.current[row.Id],
            [cell]: typeof text == "boolean" ? text : +text,
          },
        };
        console.log("changedDataRef.current2222" ,text , +text)

        changeingDataCallback && changeingDataCallback(changedDataRef.current);
      }
    } else {
      let obj = {
        ...changedDataRef.current,
        [row[RowKey]]: {
          ...changedDataRef.current[row.Id],
          [cell]: text == row[cell] ? text :null ,
        },
      };
      delete obj[row[RowKey]][cell];
      if (!(Object.keys(obj[row[RowKey]]).length > 0)||checkChangedRow(row[cell] , row)   ) {
        delete obj[row[RowKey]];
      }
      let  complexData = 
      changedDataRef.current = obj;
      changeingDataCallback && changeingDataCallback(changedDataRef.current);
      //  setChangedData(obj)
    }
  }
  useEffect(() => {
    if (editAllGrid && editAllGrid !== 3) {
      setInEditCol(Object.keys(editablecolumns));
    } else if (!editAllGrid) {
      setInEditCol([]);
    }
  }, [editAllGrid]);

  useEffect(() => {
    if (filterStr && typeof filterStr == "string" && filterStr.length > 1) {
      let newJs: any = filterStr.split(",");
      let lastList: any = [];
      newJs.forEach((item) => {
        lastList.push(item.split("="));
      });
      let hedList: any = [];
      let valueList: any = [];
      lastList.forEach((item) => {
        if (item[0].length > 0) {
          hedList.push(item[0]);
          valueList.push(item[1].replace(/'/g, ""));
        }
      });
      setFilterList(hedList);
      setFilterListValuse(valueList);
      setFilterFlag(false);
    }
  }, [filterStr]);

  // const renderTooltip = (tooltipText) => (
  //     <Tooltip id={`button-tooltip-${tooltipText}`} >
  //         {trans(tooltipText)}
  //     </Tooltip>
  // );
  const mouseDown = (index) => {
    setActiveIndex(index);
    activeIndexRef.current = index;
  };
  const mouseMove = useCallback(
    (e) => {
      e.stopImmediatePropagation();
      // Return an array of px values

      let gridColumns = hedcolums.map((col, i) => {
        if (!removedColumns.includes(col.text)) {
          if (i === activeIndex) {
            // Calculate the column width
            if (tableElement.current !== null) {
              if (rtlDataGridEditableDirection) {
                // سایز سلول در حالت راست چین

                const width =
                  col.ref.current.getBoundingClientRect().right - e.clientX;
                let width2 =
                  (col.ref.current.getBoundingClientRect().width - width) / 2;
                if (width >= DefaultMinCellWidth - 5) {
                  return `${
                    col.ref.current.getBoundingClientRect().width - width2
                  }px`;
                } else {
                  mouseUp("");
                  Toast(
                    trans("msg_deletecolumnconfirm"),
                    "warning",
                    "alert",
                    () => {
                      let hedTables = localStorage.getItem("userHeadTable");
                      let locallist;
                      if (hedTables == null) {
                        locallist = [];
                      } else {
                        locallist = JSON.parse(hedTables);
                      }
                      locallist.forEach((item) => {
                        if (item[0] === parentName) {
                          item[6] = [...removedColumns, col.text];
                        }
                      });

                      setRemovedColumns([...removedColumns, col.text]);
                      localStorage.setItem(
                        "userHeadTable",
                        JSON.stringify(locallist)
                      );
                    }
                  );
                  return `${DefaultMinCellWidth + 5}px`;
                }
              } else {
                // سایز سلول در حالت چپ چین
                const width =
                  e.clientX -
                  (tableElement.current as HTMLElement)?.getBoundingClientRect()
                    .left -
                  col.ref.current.offsetLeft;
                if (width >= DefaultMinCellWidth - 5) {
                  return `${width}px`;
                } else {
                  mouseUp("");
                  Toast(
                    trans("msg_deletecolumnconfirm"),
                    "warning",
                    "alert",
                    () => {
                      let hedTables = localStorage.getItem("userHeadTable");
                      let locallist;
                      if (hedTables == null) {
                        locallist = [];
                      } else {
                        locallist = JSON.parse(hedTables);
                      }
                      locallist.forEach((item) => {
                        if (item[0] === parentName) {
                          item[6] = [...removedColumns, col.text];
                        }
                      });

                      setRemovedColumns([...removedColumns, col.text]);

                      localStorage.setItem(
                        "userHeadTable",
                        JSON.stringify(locallist)
                      );
                    }
                  );
                  return `${DefaultMinCellWidth + 5}px`;
                }
              }
            }
          }
          // Otherwise return the previous width (no changes)
          return `${col.ref.current.offsetWidth}px`;
        } else {
          return null;
        }
      });
      gridColumns.filter((n) => n);
      if (tableElement.current) {
        (tableElement.current as HTMLElement).style.gridTemplateColumns =
          gridColumns.join(" ");
      }

      if (gridColumns.length > 0) {
        widthColumns = JSON.parse(JSON.stringify(gridColumns));
      }
    },
    [activeIndex, DefaultMinCellWidth]
  );
  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);
  const mouseUp = useCallback(
    (e) => {
      if (activeIndex) {
        let newhedcolums = hedcolums;
        newhedcolums[activeIndex].width = widthColumns[activeIndex];
        setTimeout(() => {
          setHedcolums(newhedcolums);
        }, 5);
      }

      removeListeners();
      setActiveIndex(null);
      activeIndexRef.current = null;
    },
    [setActiveIndex, removeListeners]
  );

  useEffect(() => {
    let checkboxs: any = Object.values(
      document.getElementsByClassName(`${parentName} checkBox`)
    );
    checkboxs.forEach((item) => {
      item.checked = false;
    });
    setSelectedRow([]);
    setFlag(!flag);
    setCheckedAll(false);
  }, [resetMultiSelect]);

  useEffect(() => {
    if (multiSelecttype !== 2) {
      let checkboxs: any = Object.values(
        document.getElementsByClassName(`${parentName} checkBox`)
      );
      checkboxs.forEach((item1) => {
        let flag = false;
        selectedRow.forEach((item2) => {
          if (item1.id.slice(9, 45) === item2.Id) {
            flag = true;
          }
        });
        if (flag) {
          item1.checked = true;
        } else {
          item1.checked = false;
        }
      });
    }
  }, [tbodyData]);

  useEffect(() => {
    let hedTables = localStorage.getItem("userHeadTable");
    let locallist;
    if (hedTables == null) {
      locallist = [];
    } else {
      locallist = JSON.parse(hedTables);
    }
    locallist.forEach((item) => {
      if (item[0] === parentName) {
        if (item[7]) {
          setHoverSource(item[7]);
        } else {
          setHoverSource("ColumnData");
        }
      }
    });

    return () => {
      if (ummont) {
        let flag2 = false;
        let hedlist = hedcolums.map((item) => {
          return item.text;
        });
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables);
        }
        if (localFlag) {
          locallist.forEach((item) => {
            if (item[0] === parentName) {
              item[1] = hedlist;
              localFlag = false;
              flag2 = true;
            }
          });
          if (!flag2) {
            locallist.push([parentName, hedlist, widthColumns]);
          }
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }
        ummont = false;
      }
    };
  }, []);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }
    a = true;
    return () => {
      if (a) {
        let hedTables = localStorage.getItem("userHeadTable");
        let locallist;
        if (hedTables == null) {
          locallist = [];
        } else {
          locallist = JSON.parse(hedTables);
        }
        if (localFlag) {
          locallist.forEach((item) => {
            if (item[0] === parentName) {
              if (ummont)
                if (widthColumns.length > 0) {
                  item[2] = widthColumns;
                }
            }
          });
          localStorage.setItem("userHeadTable", JSON.stringify(locallist));
        }
        removeListeners();
      }
    };
  }, [mouseMove, mouseUp]);

  return (
    <>
      <div className="data-grid-wrapper">
        <form
          id={"formFilter"}
          name={"Filtering" + parentName}
          onSubmit={(e) => {
            e.preventDefault();
            filtering();
          }}
        >
          <table
            tabIndex={-1}
            className={`data-grid ${
              scrollHeight ? "scrollable" : ""
            } section-to-print`}
            ref={tableElement}
            style={{
              gridTemplateColumns: gridTemplateColumnsStyle.join(" "),
              maxHeight: `${scrollHeight && scrollHeight}`,
            }}
          >
            <thead
              className={`data-grid__thead    ${
                filterList.length > 2 ? "theadTr" : ""
              }`}
            >
              <tr tabIndex={-1} className="data-grid__tr ">
                {hedcolums.map(({ ref, text }, index) => (
                  <>
                    {!removedColumns.includes(text) && (
                      <OverlayTrigger
                        key={text + index}
                        placement="top"
                        delay={{ show: 600, hide: 0 }}
                        overlay={
                          !text.includes("Icon") ? (
                            <Tooltip
                              className="tooltip"
                              id={`button-tooltip-${text}`}
                            >
                              {trans(text)}
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <th
                          tabIndex={-1}
                          ref={ref}
                          key={index + text}
                          className={`data-grid__header-cell
                             ${disabled ? "disabledGride" : "" }  
                             ${filterList.length > 0 ? "theadTr " : ""}
                            ${filterList.length > 0 ? "theadTr " : ""}
                            ${ scrollHeight ? "scrollable" : ""
                          }`}
                        >
                          <div>
                            {text === "SelectAll" ? (
                              <span
                                onClick={
                                  multiSelecttype === 2
                                    ? multiSelectAll
                                    : () => {}
                                }
                                className={`${
                                  hasOperation
                                    ? "data-grid__cell-bodytext"
                                    : "data-grid__header-cell-span"
                                }`}
                              >
                                <input
                                  tabIndex={-1}
                                  style={{
                                    marginRight: "2px",
                                    marginTop: "3px",
                                  }}
                                  type={"checkbox"}
                                  className={"checkBox"}
                                  checked={checkedAll}
                                  onChange={() => {}}
                                  onClick={multiSelectAll}
                                  id={"checkboxAll"}
                                />
                              </span>
                            ) : hasOperation && index === 0 ? (
                              <GridSetting
                                HoverSource={hoverSource}
                                setHoverSource={setHoverSource}
                                setRemovedColumns={setRemovedColumns}
                                parentName={parentName}
                                startIndex={startIndex}
                                columns={columns}
                                removedColumns={removedColumns}
                                filter={filterStr}
                                order={sortingFormat}
                                handleOrderList={(e) => {
                                  orderFormat && orderFormat(e);
                                }}
                                handleFilterList={(e) => {
                                  handlefiltering && handlefiltering(e);
                                }}
                              />
                            ) : (
                              <span
                                className="data-grid__header-cell-span"
                                onMouseDown={(e) => {
                                  getTh(e, index);
                                }}
                                onMouseUp={(e) => {
                                  setTh(e, index);
                                }}
                              >
                                {editablecolumns?.[text] && (
                                  <div
                                    style={{ marginLeft: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateInEditColList(text);
                                    }}
                                  >
                                    {
                                      <FontAwesomeIcon
                                        className="filterBox"
                                        style={{
                                          height: "18px",
                                          color: "rgb(164, 164, 164)",
                                        }}
                                        icon={[ICON_TYPE, "edit"]}
                                      />
                                    }{" "}
                                  </div>
                                )}
                                <div
                                  onClick={(e) => {
                                    createSortingFormat(text, e);
                                  }}
                                  style={{
                                    width: "100%",
                                    marginRight: disabled
                                      ? "2px"
                                      : text.includes("Icon")
                                      ? "2px"
                                      : toggleSortingFormat(text)[0]
                                      ? "28px"
                                      : "",
                                  }}
                                  className="data-grid__cell-text"
                                >
                                  {text === "Operation" ? (
                                    handleCreate && (
                                      <div
                                        style={{
                                          flexDirection: "column",
                                          display: "flex",
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                          marginRight: "4px",
                                          marginTop: "1px",
                                        }}
                                        onClick={() => handleCreate(true)}
                                      >
                                        +
                                      </div>
                                    )
                                  ) : text.includes("Icon") ? (
                                    text.replace("Icon", "").length > 0 ? (
                                      <FontAwesomeIcon
                                        icon={[
                                          ICON_TYPE,
                                          text
                                            .replace("Icon", "")
                                            .toLowerCase(),
                                        ]}
                                      />
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <>
                                      {trans(text)}
                                      {handlefiltering && (
                                        <div
                                          style={{ marginLeft: "10px" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            filterMaker(text);
                                          }}
                                        >
                                          {
                                            <FontAwesomeIcon
                                              className="filterBox"
                                              style={{
                                                height: "18px",
                                                color: "rgb(164, 164, 164)",
                                              }}
                                              icon={[
                                                ICON_TYPE,
                                                filterList.includes(text)
                                                  ? "xmark"
                                                  : "filter",
                                              ]}
                                            />
                                          }{" "}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                {(hasOperation && index === 0) ||
                                (!hasOperation && index === 0) ? null : (
                                  <>
                                    {toggleSortingFormat(text)[0] && (
                                      <div
                                        onClick={(e) => {
                                          createSortingFormat(text, e);
                                        }}
                                        className="data-grid__sorting-icon-wrapper"
                                        id={"data-grid__header-cell" + index}
                                      >
                                        {toggleSortingFormat(text)[0] ===
                                        "up" ? (
                                          <>
                                            <div className="badgeSortNum">
                                              {+toggleSortingFormat(text)[1] +
                                                1}{" "}
                                            </div>
                                            <div className="badgeGrid">
                                              <FontAwesomeIcon
                                                size="lg"
                                                style={{
                                                  marginTop: "1px",
                                                  fontSize: "12px",
                                                }}
                                                icon={[ICON_TYPE, "arrow-up"]}
                                              />{" "}
                                            </div>{" "}
                                          </>
                                        ) : toggleSortingFormat(text)[0] ===
                                          "down" ? (
                                          <>
                                            <div className="badgeSortNum">
                                              {+toggleSortingFormat(text)[1] +
                                                1}
                                            </div>
                                            <div className="badgeGrid">
                                              {" "}
                                              <FontAwesomeIcon
                                                size="lg"
                                                style={{
                                                  marginTop: "0px",
                                                  fontSize: "12px",
                                                }}
                                                icon={[ICON_TYPE, "arrow-down"]}
                                              />{" "}
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    )}
                                  </>
                                )}
                              </span>
                            )}
                            {filterList.includes(text) &&
                                                    <div style={{ display: "flex" }}>
                                                        {(text.includes("Date")||text.includes("Time"))?
                                                         <DateFilter  id={text}   defaultValue={filterListValuse[filterList.indexOf(text)]}/>
                                                        :
                                                        <input autoFocus={autoFocus} defaultValue={filterListValuse[filterList.indexOf(text)]} id={text} name={"filter"} className="text-box__box form-control filterInput" />
                                                        }
                                                        <FontAwesomeIcon size="lg" style={{ height: "20px", marginTop: "4px", marginRight: "2px", color: "#5b5b5b" }} icon={[ICON_TYPE, "filter"]} onClick={(e) => { filtering() }} />
                                                    </div>
                                                }
                          </div>
                          {index === 0 && (
                            <>
                              {hasOperation || multiSelect ? (
                                <div
                                  style={{
                                    height: tableHeight,
                                    cursor: "default",
                                  }}
                                  className={`data-grid__resize-handle resizeDisabled pl-2 `}
                                />
                              ) : (
                                <div
                                  style={{ height: tableHeight }}
                                  onMouseDown={() => mouseDown(index)}
                                  className={`data-grid__resize-handle ${
                                    activeIndex === index ? "active" : "idle"
                                  }`}
                                />
                              )}
                            </>
                          )}
                          {index > 0 ? (
                            text.includes("Icon") ? (
                              <div
                                style={{
                                  height: tableHeight,
                                  cursor: "default",
                                }}
                                className={`data-grid__resize-handle resizeDisabled`}
                              />
                            ) : text.includes("multiSelect") ? (
                              <div
                                style={{
                                  height: tableHeight,
                                  cursor: "default",
                                }}
                                className={`data-grid__resize-handle resizeDisabled`}
                              />
                            ) : (
                              <div
                                style={{ height: tableHeight }}
                                onMouseDown={() => mouseDown(index)}
                                className={`data-grid__resize-handle ${
                                  activeIndex === index ? "active" : "idle"
                                }`}
                              />
                            )
                          ) : text.includes("multiSelect") ? (
                            <div
                              style={{ height: tableHeight, cursor: "default" }}
                              className={`data-grid__resize-handle resizeDisabled`}
                            />
                          ) : (
                            <></>
                          )}
                        </th>
                      </OverlayTrigger>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            {totalRecords === 0 && tbody.length === 1 ? (
              <></>
            ) : (
              <tbody style={{ height: "25px" }} className="data-grid__tbody">
                {Rows.map((row, index1) => {
                  if (rowSelected && rowSelected.Id === row.Id) {
                    myStateRef.current = index1;
                  }
                  return (
                    <tr
                      onContextMenu={(e) => {
                        onContextMenu(e, index1, row.Id);
                      }}
                      tabIndex={-1}
                      onDoubleClick={() => {
                        DoubleClick
                          ? DoubleClick(row)
                          : handleView && handleView(row.Id);
                      }}
                      id={`trbody${index1}`}
                      key={index1}
                      className={`data-grid__tr ${
                        View ? (row[ViewParametr] ? "boldTr" : "") : " "
                      }  `}
                      onClick={(event) => {
                        toggleActiveRow(event, index1);
                      }}
                    >
                      {hedcolums.map(
                        (cell, index2) =>
                          !removedColumns.includes(cell.text) && (
                            <OverlayTrigger
                              key={cell.text + index2}
                              placement="top"
                              delay={{ show: 600, hide: 0 }}
                              overlay={
                                cell.text !== "SelectAll" &&
                                cell.text !== "Operation" ? (
                                  <Tooltip
                                    className="tooltip"
                                    id={`button-tooltip-${cell}`}
                                  >
                                    <div>
                                      {inEditCol?.includes(cell.text) &&
                                        trans("previousvalue")+" : "}
                                      {cell.text.includes("Icon")
                                        ? row[`${cell.text}Hover`]
                                          ? row[`${cell.text}Hover`]
                                          : hoverSource === "ColumnData"
                                          ? row[cell.text]
                                          : trans(cell.text)
                                        : hoverSource === "ColumnData"
                                        ? ((row[cell.text] =="-1"&&!cell.text.includes("Min")&&!cell.text.includes("Max"))? trans("CallForPrice") : row[cell.text])
                                        : trans(cell.text)}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <td
                                key={index2 + cell.text}
                                tabIndex={-1}
                                className={`${
                                  row.Reversed ? "reversedTd" : " "
                                }   data-grid__body-cell ${
                                  myStateRef.current === index1 ||
                                  (rowSelected &&
                                    rowSelected.Id === row.Id &&
                                    myStateRef.current !== 0)
                                    ? "active"
                                    : ""
                                } `}
                                id={index1}
                              >
                                <span
                                  className={`data-grid__cell-span ${
                                    index2 === 1 && hasOperation ? "p-0" : ""
                                  } `}
                                >
                                  <div className="data-grid__cell-bodytext d-flex">
                                    {(editablecolumns[cell.text] ===
                                      "Boolean" &&
                                      editAllGrid == 0) ||
                                    (typeof row[cell.text] === "boolean" &&
                                      row?.Id != rowSelected?.Id &&
                                      editAllGrid == 2) ||
                                    (typeof row[cell.text] == "boolean" &&
                                      !inEditCol?.includes(cell.text) &&
                                      editAllGrid == 3) ? (
                                      row[cell.text] ? (
                                        <div className="data-grid__check-icon">
                                          <FontAwesomeIcon
                                            icon={[ICON_TYPE, "check-square"]}
                                          />
                                        </div>
                                      ) : (
                                        <div className="data-grid__check-icon">
                                          <FontAwesomeIcon
                                            icon={["far", "square"]}
                                          />
                                        </div>
                                      )
                                    ) : cell.text.includes("Icon") ? (
                                      row[cell.text] && (
                                        <FontAwesomeIcon
                                          icon={[
                                            ICON_TYPE,
                                            row[cell.text].split(",")[0]
                                              ? row[cell.text].split(",")[0]
                                              : row[cell.text],
                                          ]}
                                          style={{
                                            color: row[cell.text].split(",")[1]
                                              ? row[cell.text].split(",")[1]
                                              : "#5b5b5b",
                                          }}
                                        />
                                      )
                                    ) : (inEditCol?.includes(cell.text) &&
                                        editAllGrid === 3) ||
                                      (editAllGrid === 1 &&
                                        inEditCol?.includes(cell.text)) ||
                                      (inEditCol?.includes(cell.text) &&
                                        editAllGrid === 2 &&
                                        row?.Id == rowSelected?.Id) ||
                                      (editAllGrid === 3 &&
                                        editablecolumns[cell.text] ===
                                          "Boolean" &&
                                        inEditCol?.includes(cell.text)) ? (
                                      <EditableInput
                                        // min={0}
                                        columnsWithOption={columnsWithOption}
                                        defaultValueOption={defaultValueOption}
                                        column={cell.text}
                                        type={editablecolumns[cell.text]}
                                        onBlurCallBack={(text) => {                                          
                                          addRowChange(text, row, cell.text);
                                        }}
                                        defaultValue={row[cell.text]}
                                      />
                                    ) : defaultValueOption ? (
                                      defaultValueOption[row[cell.text]] ? (
                                        defaultValueOption[row[cell.text]]
                                      ) : (
                                        row[cell.text]
                                      )
                                    ) : (
                                      row[cell.text]
                                    )}
                                  </div>
                                </span>
                              </td>
                            </OverlayTrigger>
                          )
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {totalRecords === 0 && tbody.length === 1 ? (
            <Message message={"NoDataForPreview"} type={"info"} />
          ) : (
            <></>
          )}
        </form>
      </div>
      {pageNum &&
        pageSize &&
        HandleNextPage &&
        HandlePrevPage &&
        handlechangePageSize &&
        first &&
        end && (
          <PaginationNavbar
            totalRecords={totalRecords}
            pageNum={pageNum}
            pageSize={pageSize}
            HandleNextPage={HandleNextPage}
            HandlePrevPage={HandlePrevPage}
            handlechangePageSize={handlechangePageSize}
            first={first}
            end={end}
            handleGoPage={handleGoPage}
          />
        )}
    </>
  );
};
