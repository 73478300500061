import { Toast } from "components/ToastShow/ToastShow";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSREPORT } from "utils/endpoints";
import { http } from "utils/http";



export const getWithFeedApi = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendWithFeedMethod = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.tree,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getWithFeedMethod = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.tree,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        Id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const sendWithFeed = (data: string, method , Id = null) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.withFeed,
    method: method,
    params:{
        Id:Id
    },
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getWithFeed = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.report,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getReportView = (id: any) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.reportView,
    method: 'get',
    headers: { "Accept-Language": "en" },
    params: {
        id: id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});



export const sendWithFeedApi = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const getFeedApis = (data: string, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.Report.feedApi,
    method: method,
    data: data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});

export const getFeedApiDictionaryApi = (str: string, id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.loadApi,
    method: "get",
    params: {
        searchString: str
    }
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});
export const getFeedMethodsDictionaryApi = (str: string, id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.loadMethod,
    method: "get",
    params: {
        searchString: str
    }
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const sendFeedApi = (data, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseApi,
    method:method,
    data:data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});


export const sendFeedMethodsForm = (data, method) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseMethod,
    method:method,
    data:data
}).then((res) => {
    return res
}).catch((err) => {
    return Promise.reject( err);
});

export const getParameterDataTypeApi = (isMethod) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameterTypes,
    method:"get",
    params:{
        isMethod
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const getParameterEnumApi = (dataType) => http({
    url:ENDPOINTS.enumeration,
        params:{
            enumerationType:dataType
    },
    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getParameterEnumMethod = (dataType) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameterEnums,
    params:{
        enumName:dataType
    },
    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const getenumApi = () => http({
    url:ENDPOINTS.enumTypes,
    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const getenumMethod = () => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.methodEnumTypes,

    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getDicMethod = () => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.GenericDictionary,

    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getParameterDictionary = (search ,typeName) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.GenericDictionaryValues,
    method:"get",
params:{
    searchString:search,
    typeName:typeName
},
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const GenerateParameterFromDTO = (serviceKey,dtoFullName) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.gnerate,
    params:{
        serviceKey ,
        dtoFullName
    },
    method:"get"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const putParameter = (data) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameters,
data,
    method:"put"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const postParameter = (data) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameters,
data,
    method:"post"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const putTemplates = (data) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.templates,
data,
    method:"put"
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const deletetemplates= (id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.templates,
    method:"delete",
    params:{
        id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const getTemplates= (reportId) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.templatesList,
    method:"get",
    params:{
        reportId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const postTemplates= (data) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.templates,
    method:"post",
    data:data
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});
export const deleteParameter= (id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.parameters,
    method:"delete",
    params:{
        id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const getParameterOperatorTypesDictionaryApi = () => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.operatorTypes,
    method:"get", 

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});


export const deleteApiFeed = (id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseApi,
    method:"delete", 
    params:{
        id
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});

export const deleteMethodFeed = (id) => http({
    baseURL:REPORT_URL ,
    url:ENDPOINTSREPORT.ReportFeed.browseMethod,
    method:"delete", 
    params:{
        id
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject( err);
});