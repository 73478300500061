import axios from "axios";
import { Toast } from "components/ToastShow/ToastShow";
import { MessageType } from "types/enum";
import { auth } from "./auth";
import { APP_URL } from "./constants";
import { trans } from "./helpers";

export let UserTabs: any = [];
const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

export const httpFile = axios.create({
    baseURL: APP_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    }
  
  });
  
  httpFile.interceptors.request.use(
   async function (config: any) {
      const lang = localStorage.getItem("lang");
      const token = localStorage.getItem("token");
      const ex = localStorage.getItem("token_expire");
      let res = true;
      if (token) {
        res =await auth.checkTokenExpire();
      } else {
        auth.attempt();
      }
      if(!localStorage.getItem("token")){
        await delay(300)
        if (localStorage.getItem("token")) {
          res =await auth.checkTokenExpire();
        } else {
          auth.attempt();
        }
      }
      if (
        config.method == "post" ||
        config.method == "put" ||
        config.method == "delete"
      ) {
        //document.body.classList.add("loading-indicator-"+lang);
      }
      if (res) {
        if (sessionStorage.getItem("accept-data")) {
          config.headers["Accept-Data"] = sessionStorage.getItem("accept-data");
        }
        if (
          config.headers["Accept-Language"] == undefined ||
          config.headers["Accept-Language"] == ""
        )
          config.headers["Accept-Language"] = lang;
        config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        return config;
      } else {
        auth.attempt();
        if (
          config.headers["Accept-Language"] == undefined ||
          config.headers["Accept-Language"] == ""
        )
          config.headers["Accept-Language"] = lang;
        config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        return config;
      }
    },
    function (error) {
  
    }
  );
  
  
  httpFile.interceptors.response.use(
    async (res) => {
      const lang = localStorage.getItem("lang");
      try {
  
        if (res.config.method == "get") {
          document.body.classList.remove("loading-indicator-"+lang);
          return res;
        } else {
          if (res.data.MessageType == MessageType["confirm"]) {
            res.config.params.Confirm = true;
            const confirm = await new Promise((resolve) => {
              let re: any;
              Toast(
                res.data.Message,
                "warning",
                "alert",
                () => {
                  re = httpFile(res.config);
                  resolve(re);
                },
                "",
                "",
                () => {
                  document.body.classList.remove("loading-indicator-"+lang);
                }
              );
            });
            document.body.classList.remove("loading-indicator-"+lang);
            return confirm;
          } else {
            if (res?.data?.Data && res.data.Data[0]?.MessageType) {
              Toast(
                res?.data.Message,
                MessageType[res.data.MessageType],
                "toast",
                () => {},
                "",
                res?.data?.Data
              );
              return res;
            } else {
              if(res.data.MessageType){
                // Toast(res.data.Message, MessageType[res.data.MessageType]);
              }
              document.body.classList.remove("loading-indicator-"+lang);
              return res;
            }
          }
        }
      } catch (error) {
      }
    },
  
    async (err) => {
  
  
      const lang = localStorage.getItem("lang");
  
      document.body.classList.remove("loading-indicator-"+lang);
      const statusCode = err?.response?.status;
  
      if (statusCode == 401) {

        try {
          await auth.checkTokenExpire();
          const token = localStorage.getItem("token");
          err.response.config.headers["Authorization"] = "Bearer " + token
          await new Promise((resolve) => {
            let re = httpFile(err?.response.config);
            resolve(re);
          });
        } catch (error) {}
      }  else if (statusCode == 403) {
        err.response.data ={
          data:null ,
          MessageType : MessageType["error"],
          Message:"noaccess"
        }
        return Promise.reject(err);
  
      }else if (!err?.message.includes("timeout")) {
        if (err?.response?.data?.MessageType == MessageType["confirm"] && false) {
          err.config.params.Confirm = true;
          document.body.classList.remove("loading-indicator-"+lang);
          document.body.classList.remove("loading-indicator-"+lang);
          const confirm = await new Promise((resolve) => {
            let re: any;
            Toast(
              err.response.data.Message,
              "warning",
              "alert",
              () => {
                re = httpFile(err.config);
                resolve(re);
              },
              "",
              "",
              () => {
                document.body.classList.remove("loading-indicator-"+lang);
              }
            );
          });
  
          return confirm;
        } else {
          if (
            err?.response?.data?.Data &&
            err?.response?.data?.Data[0]?.MessageType
          ) {
            Toast(
              err?.response?.data.Message,
              "error",
              "toast",
              () => {},
              "",
              err?.response?.data?.Data
            );
          } else if (!err?.response?.data?.Message) {
          } else {
           Toast(err.response.data.Message, "error");
           return Promise.reject(err);
  
          }
          document.body.classList.remove("loading-indicator-"+lang);
        }
      } else if (err?.message.includes("timeout ")) {
        Toast(trans("Msg_ServerTimeOutError"), "error");
      }
      return Promise.reject(err);
    }
  );
  
  