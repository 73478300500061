import ListGroup from "react-bootstrap/ListGroup";
import "./systemSettingMenu.scss";
import { useEffect, useState } from "react";
import {
  getSystemSetting,
  getSystemSettingGroupTitle,
  putSystemSetting,
} from "./api";
import { SystemSettingGroupItem } from "./SystemSettingGroupTitle";
import { Loading } from "components/Loading/loading";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { SystemSettingForm } from "./SystemSettingForm";
import { Message } from "components/Message/Message";
import { Button } from "components/Button/Button";
import { PropertyType } from "types/enum";

export function InventorySystemSetting() {
  const [groupTitle, setGroupTitle] = useState<Response | any>(null);
  const [selectedGroupTitle, setSelectedGroupTitle] = useState<any>(
    groupTitle?.length ? groupTitle[0] : ""
  );
  const [settingCards, setSettingCards] = useState<Response | any>(null);
  const [filterCards, setFilterCards] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [loadingCards, setLoadingCards] = useState(true);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [userChanges, setUserChanges] = useState({});

  //to get all systemSettingGroupItem
  const getSystemSettingGroupTitleAsync = async () => {
    setLoading(true);
    try {
      const res = await getSystemSettingGroupTitle();
      setGroupTitle(res.Data);
      setSelectedGroupTitle(res.Data[0]);
      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
    } catch (error) {
      setCatchMessage({ type: "error", msg: "wrong fetch data" });
    }
    setLoading(false);
  };

  //to get all systemSettingCards
  const getSystemSettingAsync = async () => {
    setLoadingCards(true);
    try {
      const res = await getSystemSetting(selectedGroupTitle);
      setSettingCards(res.Data);
      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
    } catch (error) {

      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
    }
    setLoadingCards(false);
  };

  //to put systemSetting
 const submitForm = (e) => {
    e.preventDefault();
    const cards = [] as any;
    const newData = {} as any;
    filterCards.map((cards) => {
      cards.map((item) => {
        const { SystemKey } = item;

        if (item.PropertyType == PropertyType.Date) {
          newData[SystemKey]= fromDate ;
        } else if (item.PropertyType == PropertyType.CheckBox) {
          newData[SystemKey]=e?.target[SystemKey]?.checked ? "true" : "false"
        } else if (item.PropertyType == PropertyType.Price) {
          newData
            [SystemKey]= e?.target[SystemKey]?.value?.replaceAll(",", "")
        } else if (item.PropertyType == PropertyType.List) {
          const findSelectListItem = item?.Values.split(",")?.findIndex(
            (option) => option == e?.target[SystemKey]?.value
          );
          newData[SystemKey]=findSelectListItem.toString()
        } else if (item.PropertyType == PropertyType.Time) {
          newData[SystemKey]= e?.target[SystemKey][0]?.value
        } else {
          newData[SystemKey]=e?.target[SystemKey]?.value? e?.target[SystemKey]?.value?.toString() : ""
        }
      });
    });

    const putSystemSettingApi = async (newDataObj) => {
      try {
        await putSystemSetting(newDataObj);
      } catch (error) {}
    };
    putSystemSettingApi(newData);
  };

  const handleClickItemGroup = (selectedItem) => {
    setSelectedGroupTitle(selectedItem);
  };

  useEffect(() => {
    getSystemSettingGroupTitleAsync();
  }, []);

  useEffect(() => {
    if (selectedGroupTitle) {
      getSystemSettingAsync();
    }
  }, [selectedGroupTitle]);

  //to filter cards
  useEffect(() => {
    const newCardObj = {};
    settingCards?.forEach((item) => {
      if (newCardObj[item.CardTitle]) {
        newCardObj[item.CardTitle].push(item);
      } else {
        newCardObj[item.CardTitle] = [];
        newCardObj[item.CardTitle].push(item);
      }
    });
    const createdCollectionCards = [] as any;
    Object.keys(newCardObj).forEach((item) => {
      createdCollectionCards.push(newCardObj[item]);
    });
    setFilterCards(createdCollectionCards);
  }, [settingCards]);

  return (
    <div className="card overflow-hidden"  style={{
      overflowY: "auto",
       maxHeight: "83vh",
      scrollbarWidth: "thin",
    }}>
      <div className="row card-body setting-menu  ">
        {/* setting groupItem*/}
        <div className="col-sm-4 col-md-3 border-start mb-3 mb-sm-0 overflow-hidden ">
          {!loading ? (
            groupTitle?.length > 0 ? (
              <div>
                {groupTitle?.map((item, index) => (
                  <SystemSettingGroupItem
                    key={index}
                    index={index}
                    item={item}
                    handleClickItemGroup={handleClickItemGroup}
                    selectedGroupTitle={selectedGroupTitle}
                  />
                ))}
              </div>
            ) : (
              <Message message={catchMessage.msg} type={catchMessage.type} />
            )
          ) : (
            <Loading />
          )}
        </div>
        {/* cards Component */}
        <div className="col-sm-8 col-md-9 bg-default mx-h-100  cards-component ">
          <form onSubmit={submitForm}>
            <div className="card-section px-2">
              {!loadingCards ? (
                filterCards?.map((cards, index) => {
                  return (
                    <FormWithTitle
                      showFristRow={false}
                      title={cards[0].CardTitle}
                      cardClass={"mb-3 mt-1 "}
                      key={index}
                    >
                      <SystemSettingForm
                        cards={cards}
                        setFromDate={setFromDate}
                        setUserChanges={setUserChanges}
                        userChanges={userChanges}
                      />
                    </FormWithTitle>
                  );
                })
              ) : (
                <Loading />
              )}
            </div>
            <div className="btn-form pt-3 ">
              <Button value="Save" btnType="submit" />
              <Button
                btnType="cancel"
                value="Cancel"
                onClick={() => {
                  setUserChanges({});
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
