import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import Datepicker from "components/DatePicker/DatePicker";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { CurrencyDictionary } from "../../PriceList/components/CurrencyDictionary/CurrencyDictionary";
import {
  deleteCurrency,
  getCurrency,
  getListCurrenceis,
  postConversionRatio,
  postCurrencies,
  putCurrencies,
} from "./api";

interface Form {}
export const Currency = ({}: Form) => {
  const [response, setResponse] = useState<Response | any>(null);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("create");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [flag, setFlag] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [editForm, setEditForm] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [orderBy, setOrderBy] = useState<any>([]);
  const [search, setSearch] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userChanges, setUserChanges] = useState({});
  const [showConversionForm, setShowConversionForm] = useState<boolean>(false);
  const [date, setDate] = useState<any>(new Date().toISOString());
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);


  const searchHandler = (str) => {
    if (str == "") {
      setSearch(null);
    } else {
      setSearch(str);
    }
  };
  const handleCreateAction = () => {
    // setSelectedObj(null);
    setEditForm(null);
    setMode("create");
    setShowForm(true);
    setShowConversionForm(false)
  };
  const handleEditRow = () => {
    setMode("edit");
  setShowForm(true)
  };
  const handleDeleteRow = async () => {
    try {
      const res = await deleteCurrency(selectedObj.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };
  const handleViewRow = () => {
    setMode("view");
    setShowConversionForm(false)
    setShowForm(true);

  };

  const selectedIdCallback = (obj, index) => {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        setSelectedObj(obj);
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
      });
    } else {
      setSelectedObj(obj);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (editForm == null) {
      postCurrencies({
        IsDefault: e.target.checkbox?.checked,
        Title: e.target.CurrencyTitle?.value,
        TitleEN: e.target.CurrencyTitleEN?.value,
        PriceRoundingPlace: +e.target.CurrencyRoundingPlace?.value,
        Sign: e.target.CurrencySign?.value,
        Description: e.target.CurrencyDescription?.value,
        DescriptionEN: e.target.CurrencyDescriptionEN?.value,
      }).then(() => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        getListCurrenceisAsync();
        cancel();
      });
    } else {
      putCurrencies({
        IsDefault: e.target.checkbox?.checked,
        Title: e.target.CurrencyTitle?.value,
        TitleEN: e.target.CurrencyTitleEN?.value,
        PriceRoundingPlace: +e.target.CurrencyRoundingPlace?.value,
        Sign: e.target.CurrencySign?.value,
        Description: e.target.CurrencyDescription?.value,
        DescriptionEN: e.target.CurrencyDescriptionEN?.value,
        Id: selectedObj.Id,
      }).then(() => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        getListCurrenceisAsync();
        cancel();
      });
    }
    setShowForm(false);
  };


  const submitConversionRatio=(e)=>{
    e.preventDefault();
    if (editForm == null) {
        postConversionRatio({
            CurrencyId:e.target.CurrencyId?.value,
            ConversionRatio:+e.target.ConversionRatio?.value,
            CurrencyConversionDate:date
      }).then(() => {
        sessionStorage.setItem("changingforms", "false");
        getListCurrenceisAsync();
        cancel();
      });
    } 

    setShowConversionForm(false);

}


  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        setEditForm(null);
        setShowForm(false);
      setDate(new Date().toISOString())

      setShowConversionForm(false)
setDate(null)
      });
    } else {
      setEditForm(null);
      setUserChanges({});
      setDate(new Date().toISOString())

      setShowForm(false);
      setShowConversionForm(false)
    }
  }
  async function getListCurrenceisAsync(pagNum = pageNum) {
    setLoading(false);
    try {
        let pageSizeChangedByUser = getPageSize("Currency");
        setPageSize(pageSizeChangedByUser);
      const res = await getListCurrenceis(
        search,
        orderBy.length == 0 ? null : orderBy.join(","),
        pagNum,
        pageSizeChangedByUser
      );
      setResponse(res);
      setCatchMessage({ type: "info", msg: "NoDataForPreview" });
      if (res.Data.Data.length > 0) {
        setResponse(res);

        setPages(
          res.Data.TotalRecords > pageSizeChangedByUser
            ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
            : 1
        );
      }
    } catch (error: any) {
      setCatchMessage({ type: "error", msg: response?.Message });
    }
    setLoading(true);
  }
  async function getCurrencyasync() {
      setFormLoading(false)
      try {
          const data = await getCurrency(selectedObj?.Id);
          setEditForm({
            IsDefault: data?.Data?.IsDefault,
            Title: data?.Data?.Title,
            TitleEN: data?.Data?.TitleEN,
            PriceRoundingPlace:data?.Data?.PriceRoundingPlace ,
            Sign:data?.Data?.Sign ,
            Description:data?.Data?.Description,
            DescriptionEN:data?.Data?.DescriptionEN ,
          });
      } catch (error) {

      }
      setFormLoading(true)
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  if (flag) {
    getListCurrenceisAsync();
    setFlag(false);
  }

  /* #endregion */

  /* #region toolbarActions */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response?.Data?.Data, 1, trans("currency"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        setShowConversionForm(false)
        setShowForm(false)

        break;
      case "create":
        handleCreateAction();
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "edit":{
        setShowConversionForm(false)

        if (selectedObj?.Id)
  {
    selectedObj.SystemReserved
    ? Toast(trans("Msg_CanNotEditSystemReservedData"), "error")
    : handleEditRow()
  }

      }
        break;
      case "help":
        alert("call api help");
        break;
      case "conversionratio":
        {
          setShowConversionForm(true);
          setShowForm(false)
        }
        break;
    }
  };
  /* #endregion */



  const handleGoPage = (value) => {
    if (value) {
      setPageNum(+value);
      getListCurrenceisAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (response?.Data.TotalRecords && response?.Data.TotalRecords / pageSize > pageNum) {
      setPageNum((pageNum) => {
        return +pageNum + 1;
      });
      getListCurrenceisAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getListCurrenceisAsync(pageNum - 1);
    }
  };

  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("Currency", e.currentTarget.value);
    getListCurrenceisAsync(1);
  };



  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    getListCurrenceisAsync();
  }, [forceUpdate, flag, search, orderBy]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(() => {
    if (showForm && selectedObj && mode != "create") {
      setFormLoading(true);
    } else {
      setFormLoading(true);
    }
  }, [selectedObj, showForm, mode]);


useEffect(() => {
    if(showForm &&selectedObj && mode != "create"){
       getCurrencyasync()
    }else{
        setFormLoading(true)
    }
   
}, [selectedObj ,showForm ,mode]);





  return (
    <>
      <div className="cartable-action">
        <Toolbar
          ConversionRatio={true}
          handleSearch={searchHandler}
          id={selectedObj?.Id}
          emptyList={response?.Data?.Data == null || response?.Deta?.Data.length == 0}
          remove={true}
          create={true}
          edit={true}
          search={true}
          handleClickToolbar={clickToolbarCallback}
        />
        <div className="card">
          <div className="">
            <>
              {showForm && (
                <>
                  <div className="card mb-4">
                    {formLoading ? (
                      <form
                        className="TypeSettingForm row card-body "
                        onSubmit={submit}
                      >
                        <div className="col-md-3 col-sm-12">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencyTitle"}
                            textboxType="text"
                            resetForm={resetForm}
                            labelText={"Title"}
                            mode={mode}
                            defaultValue={editForm?.Title || ''}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencyTitleEN"}
                            textboxType="text"
                            resetForm={resetForm}
                            labelText={"TitleEN"}
                            mode={mode}
                            defaultValue={editForm?.TitleEN || ''}
                          />
                        </div>

                        <div className="col-md-3 mt-1">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencyRoundingPlace"}
                            textboxType="text"
                            resetForm={resetForm}
                            labelText={"PriceRoundingPlace"}
                            mode={mode}
                            defaultValue={editForm?.PriceRoundingPlace?editForm?.PriceRoundingPlace+"":editForm?.PriceRoundingPlace==0?'0':""}
                          />
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencySign"}
                            textboxType="text"
                            resetForm={resetForm}
                            labelText={"abbreviation"}
                            mode={mode}
                            defaultValue={editForm?.Sign || ""}
                          />
                        </div>

                        <div className=" col-sm-12">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencyDescription"}
                            textboxType="textarea"
                            resetForm={resetForm}
                            labelText={"Description"}
                            mode={mode}
                            defaultValue={editForm?.Description || ""}
                          />
                        </div>

                        <div className=" col-sm-12">
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            required={true}
                            textboxName={"CurrencyDescriptionEN"}
                            textboxType="textarea"
                            resetForm={resetForm}
                            labelText={"DescriptionEN"}
                            mode={mode}
                            defaultValue={editForm?.DescriptionEN || ''}
                          />
                        </div>
                        <div className=" mt-4 mb-4">
                          <CheckableButton
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                               defaultChecked={editForm?.IsDefault}
                               defaultValue={editForm?.IsDefault}
                            labelText={"IsDefault"}
                            inputName={"checkbox"}
                            type="checkbox"
                            mode={mode}
                          />
                        </div>
                        {mode != "view" ? (
                          <div className="col-lg-12 mt-1 btns_form">
                            <Button value="Save" btnType="submit" />
                            <Button
                              btnType="cancel"
                              value="Cancel"
                              onClick={() => {
                                cancel();
                              }}
                            />
                          </div>
                        ) : (
                          <div className="col-lg-12 mt-1 btns_form">
                            <Button
                              btnType="primary"
                              value="close"
                              onClick={() => {
                                cancel();
                              }}
                            />
                          </div>
                        )}
                      </form>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </>
              )}

              {showConversionForm && (
                <div className="card mb-4">
                  <form
                    className="TypeSettingForm row card-body "
                    onSubmit={submitConversionRatio}
                  >
                    <div className="col-md-4">
                      <CurrencyDictionary
                        name="CurrencyId"
                        label="Currency"
                        resetForm={resetForm}
                        required={true}
                        value={selectedObj?.Id || ""}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        required={true}
                        textboxName={"ConversionRatio"}
                        textboxType="number"
                        resetForm={resetForm}
                        labelText={"currencyRate"}
                        mode={mode}
                        min={0}
                        // defaultValue={selectedObj?.Title}
                      />
                    </div>

                    <div className="col-md-4">
                    <Datepicker
                        mode={"create"}
                        resetForm={resetForm}
                        lable={"currencyRateDate"}
                        setIsoTime={(isoTime: string) => {setDate(isoTime)}}
                        val={null} //resetForm ? new Date().toISOString() : 
                        withTime={true}
                        onOpenPickNewDate={true}
                        required
                      />  
                      </div>
                    {mode != "view" ? (
                      <div className="col-lg-12 mt-1 btns_form">
                        <Button value="Save" btnType="submit" />
                        <Button
                          btnType="cancel"
                          value="Cancel"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-12 mt-1 btns_form">
                        <Button
                          btnType="primary"
                          value="close"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      </div>
                    )}
                  </form>
                </div>
              )}
              {loading ? (
                response?.Data?.Data?.length >= 0 ? (
                  <>
                    <DataGrid
                      DoubleClick={(obj) => { setSelectedObj(obj); clickToolbarCallback("view") }}
                      parentName={""}
                       ViewParametr={"NotSeen"}
                      orderFormat={setOrderBy}
                      orderFormatList={orderBy}
                      startIndex={1}
                      theadData={Object.keys(response?.Data.Data[0])}
                      tbodyData={response?.Data?.Data}
                      minCellWidth={80}
                      // scrollHeight={showForm ? "25vh" : "67vh"}
  
                      selectedRowObjectCallback={selectedIdCallback}
                      handleEdit={handleEditRow}
                      handleDelete={(e) => {
                        Toast(
                          trans("msg_deleteconfirm"),
                          "warning",
                          "alert",
                          handleDeleteRow
                        );
                      }}
                      View={true}
                      handleView={handleViewRow}
                      totalRecords={response?.Data?.TotalRecords}
                      pageNum={pageNum}
                      pageSize={pageSize}
                      HandleNextPage={handleNextPage}
                      HandlePrevPage={handlePrevPage}
                      handlechangePageSize={handleChangePageSize}
                      first={() => {
                        getListCurrenceisAsync(pages);
                        setPageNum(pages);
                      }}
                      end={() => {
                        getListCurrenceisAsync(1);
                        setPageNum(1);
                      }}
                      handleGoPage={handleGoPage}
                    />
                  </>
                ) : (
                  <Message
                    message={catchMessage.msg}
                    type={catchMessage.type}
                  />
                )
              ) : (
                <Loading />
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
