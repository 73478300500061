import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { ItemMainPropertyVariation } from "../../../components/ItemMainPropertyVariation/ItemMainPropertyVariation";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { Toast } from "components/ToastShow/ToastShow";


interface MainFormPropsType {
    id?: any,
    mode?: any,
    inTab?: any,
    formRef?: any,
    data?:any;
    checkData: any;

    submitMain?: (data) => void;

}

export const MainForm = ({
    id,
    mode,
    inTab,
    formRef,
    data,
    checkData,
    submitMain,
 
  
}: MainFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [selectedItemMainPropertyValues, setSelectedItemMainPropertyValues] = useState<any>();
    const [descriptions, setDescriptions] = useState<any>({Description: checkData.Description , DescriptionEN:checkData.DescriptionEN});
    const [editor, setEditor] = useState<any>();
    const [editorData, setEditorData] = useState<any>(data.DetailedDescription	);

     function formSubmit(event) {
        event.preventDefault();    
             let data ={
                Code: (checkData.ItemMainPropertyId? checkData?.ParentCode :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","") ,
              //  Alias : event.target.Alias.value,
                 TechnicalCode : mode=="create" ?"" :event.target.TechnicalCode.value,
                 Description : mode=="create" ? checkData.Description : event.target.Description.value ,
                 DescriptionEN : mode=="create" ? checkData.DescriptionEN :event.target.DescriptionEN.value,
                 SummaryDescription :checkData.ItemMainPropertyId?"":  event.target.SummaryDescription.value,
                 SummaryDescriptionEN : checkData.ItemMainPropertyId?"": event.target.SummaryDescriptionEN.value,
                 DetailedDescription : editorData,
                 SelectedItemMainProperty: checkData.ItemMainPropertyId,
                 SelectedItemMainPropertyValues:selectedItemMainPropertyValues? selectedItemMainPropertyValues.map(item=>(item.Id)):[]
             }
             if(checkData.ItemMainPropertyId&& (!selectedItemMainPropertyValues ||selectedItemMainPropertyValues?.length==0)){
               Toast(trans(`msg_validation_valueisrequired`) , "error")
             }else{
                 submitMain&&  submitMain(data)
             }
    }
    

    useEffect(() => {
        if ( mode === "create"){
            setResetForm(true);
        }
    }, [ mode]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);

    return (

        <form className="Main-form py-2" id={"ItemForm"} name={"main"} ref={formRef} onSubmit={formSubmit}>
            <div className="card">
            { (mode != "create" && data)||(mode == "create") ?
                <div className="card-body">
                    <div className="row">
                        {(!checkData.ItemMainPropertyId||mode=="view")?
                        <>
                        <div className="col-2">
                            <Textbox
                                textboxType="number"
                                textboxName="Code"
                                required
                                resetForm={resetForm}
                                UsepadWithZeros={true}
                                maxLength={+checkData.CodeLength}
                                fixedlabel={checkData ? checkData?.ParentCode+"":checkData?.ParentCode}
                                defaultValue={data?.Code ? data.Code.replace(checkData?.ParentCode,"") : checkData?.Code}
                                labelText={trans("itemcode") + ( mode !== "create" ? "" : `  (${"طول مجاز"} : ${checkData.CodeLength})`)}
                                mode={checkData.ItemMainPropertyId?"view" :  mode}
                                />
                        </div>
                        <div className="col-2">
                            <Textbox
                                textboxType="text"
                                textboxName="TechnicalCode"
                                resetForm={resetForm}
                                defaultValue={data?.TechnicalCode ? data.TechnicalCode : null}
                                labelText="TechnicalCode"
                                mode={mode}
                                />
                        </div>
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,summaryDescription:e })}}
                                textboxType="text"
                                textboxName="SummaryDescription"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescription ? data.SummaryDescription :  null }
                                labelText="itemSummaryDescription"
                                required={!checkData.ItemMainPropertyId }
                                mode={mode}
                                />
                        </div>
                        </> :<></>   
                    }
                    {(mode!="create")&&
                        <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,Description:e })}}
                                required={!checkData.ItemMainPropertyId }
                                textboxType="text"
                                textboxName="Description"
                                resetForm={resetForm}
                                defaultValue={(data?.Description&&mode!="create")	? data?.Description	 : ((descriptions.Description ? descriptions.Description: (descriptions.summaryDescription ? data?.Description+" "+descriptions.summaryDescription:descriptions.summaryDescription)))}
                                labelText="itemDescription"
                                mode={mode}
                            />
                        </div>}
                        {(!checkData.ItemMainPropertyId||mode=="view")&&
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,SummaryDescriptionEN:e })}}
                                textboxType="text"
                                textboxName="SummaryDescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescriptionEN ? data.SummaryDescriptionEN : null}
                                labelText="itemSummaryDescriptionEN"
                                mode={mode}
                                />
                        </div>
                            }
                        {(mode!="create")&&
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,DescriptionEN:e })}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.DescriptionEN  ? data?.DescriptionEN :  (descriptions.DescriptionEN ? descriptions.DescriptionEN: descriptions.SummaryDescriptionEN)}
                                labelText="itemDescriptionEN"
                                mode={mode}
                                />
                        </div>
                            }
  {/*                    <div className="col-3">
                            <Textbox
                                textboxType="text"
                                textboxName="Alias"
                                resetForm={resetForm}
                                defaultValue={data?.Alias ? data.Alias : null}
                                labelText="Alias"
                                mode={mode}
                                />
                        </div>  */}              
                        </div>
                <div className="row ">
         


                        <div className="col-12">
                            <span>{trans('DetailedDescription')}</span>
                            <CKEditor
                                data={editorData}
                                disabled={mode == "view" && "true"}
                                onReady={editor => {
                                    editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                    );
                                    setEditor(editor)
                                }}
                                onError={(error, { willEditorRestart }) => {
                                    if (willEditorRestart) {
                                        editor.ui.view.toolbar.element.remove();
                                    }
                                }}
                                onOk={(editor) => {
                                    editor.insertHtml(editorData)
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data)
                                }}
                                editor={DecoupledEditor}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                }}
                            />
                        </div>

                    </div>
                    {checkData.ItemMainPropertyId ?
                        <ItemMainPropertyVariation checkData={checkData} showLeafMode={true} SelectedItemMainPropertyValues={setSelectedItemMainPropertyValues} ItemMainPropertyId={checkData.ItemMainPropertyId}ItemMainPropertyDescription={checkData.ItemMainPropertyDescription}  />:<></>
                    }
                </div>
                :
                <Loading/>
                }
            </div>
          <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
          </button>
        </form>
    );
}