import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";



export const getItemPriceTree = (id: any,isWarehouseItem ,warehouseDetailId ,hasPriceUnit,priceListId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.itemsTree,
    method: "get",
    params: {
        hasPriceListUnitOnly:hasPriceUnit,
        priceListId:priceListId,
        ParentId: null ,
        warehouseId: isWarehouseItem? (id=="allWarehouses"? null: id ): null
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
export const getItemPriceList = (id: any,isWarehouseItem ,warehouseDetailId  ,pageNumber,pageSize,orderByString,searchString,filterString,hasPriceUnit,priceListId) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.itemsPaging,
    method: "get",
    params: {
        hasPriceListUnitOnly:hasPriceUnit,
        priceListId:priceListId,
        ParentId: null ,
        warehouseId: isWarehouseItem? id : null,
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});