import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";

interface ButtonToolbarCreateWarehouseIo {
  Callback: (id: any) => void;
  id?;
}
export const ButtonToolbarCreateWarehouseIo: React.FC<
  ButtonToolbarCreateWarehouseIo
> = ({ id, Callback }) => {
  let profile = { isBeginner: true }; //let profile = ButtonToolbarCreateWarehouseIo.getItem("profile"); // type --> Profile
  const buttonToolbarProcessStarting = useRef(null);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [options, setOptions] = useState<any>([
    { Id: 0, Name: `${trans("InventoryReceipt")}` },
    { Id: 1, Name: `${trans("ReturnedReceipt")}` },
    { Id: 2, Name: `${trans("TransferReceipt")}` },
    { Id: 3, Name: `${trans("InventoryRemittance")}` },
    { Id: 4, Name: `${trans("ReturnRemittance")}` },
    { Id: 5, Name: `${trans("TransferRemittance")}` },
    { Id: 6, Name: `${trans("InventoryBalancing")}` },
    { Id: 7, Name: `${trans("DirectDelivery")}` },
    { Id: 8, Name: `${trans("Conversion")}` },
    { Id: 9, Name: `${trans("PeriodInit")}` },
  ]);
  const [selectoptions, setSelectOptions] = useState<any>();
  const showSubMenuRef = useRef<any>(false);

  function showSubMenuFunction() {
    setShowSubMenu(true);
    showSubMenuRef.current = true;
  }
  function hideSubMenuFunction() {
    setShowSubMenu(false);
    showSubMenuRef.current = true;
  }
  function selectboxValSetter(event) {
    setSelectOptions(event.currentTarget.getAttribute("data-value"));
    Callback && Callback(event.currentTarget.getAttribute("data-value"));
    setShowSubMenu(false);
    if (buttonToolbarProcessStarting.current)
      (buttonToolbarProcessStarting.current as HTMLButtonElement).blur();
  }
  useEffect(() => {
    let flag = true;
    const callback = (event: KeyboardEvent) => {
      event.stopPropagation();
      if ("F4" == event.key) {
        showSubMenuRef.current = !showSubMenuRef.current;
        setShowSubMenu(showSubMenuRef.current);
      }
      flag = true;
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [id]);

  return (
    <>
      {" "}
      {options && (
        <div className="toolbar-btn-wrapper">
          <button
            ref={buttonToolbarProcessStarting}
            onClick={() => {}}
            onFocus={showSubMenuFunction}
            onBlur={hideSubMenuFunction}
            className="btn btn-outline-primary toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center"
          >
            <div
              className={`toolbar-btn__hotkey ${
                profile == undefined
                  ? ""
                  : profile.isBeginner
                  ? ""
                  : "professional-customer"
              }`}
            >
              F4
            </div>
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              width="20px"
              height="20px"
            />
            <small>{trans("create")}</small>
            <div
              className={`toolbar-btn-sub-menu mt-1 ${
                showSubMenu ? "" : "d-none"
              }`}
            >
              {options &&
                options.map((option, index) =>
                  selectoptions && option.Id == selectoptions ? (
                    <div
                      key={index}
                      className="toolbar-btn-sub-menu__option "
                      data-value={`${option.Id}`}
                      onClick={selectboxValSetter}
                    >
                      {option.Name}
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="toolbar-btn-sub-menu__option"
                      data-value={`${option.Id}`}
                      onClick={selectboxValSetter}
                    >
                      {option.Name}
                    </div>
                  )
                )}
            </div>
          </button>
        </div>
      )}
    </>
  );
};
