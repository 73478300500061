import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "components/Loading/loading";
import { Search } from "components/Search/Search";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GUID_EMPTY, ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";

import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { OrganizationType } from "types/enum";
import "../style.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface props {
  user: any;
  contactName: any;
  UserList: any;
  groupList: any;
  onlinUserList: any;
  HistoryUserList: any;
  forwardMsg: any;
  contactSelectList: any;
  mode: any;
  searchString: any;

  getContacts: () => void;
  getGroupList: () => void;
  cancelForward: () => void;
  getHistoryUserList: (str) => void;
  sendContactToShowChat: (name: any) => void;
  setmode: (name: any) => void;
  UserListSearch: (str: any) => void;
  leftFromGroup: (contact: any) => void;
  removeGroup: (contact: any) => void;
  deleteChat: (contact: any) => void;
  setContactSelectList: (list: any) => void;
  handleSubmit: (list: any) => void;
  createGroup: (list: any) => void;
  handleCreateGroup: (list: any) => void;
  handleSearch: (list: any) => void;
}

const ContactList = ({
  sendContactToShowChat,
  setmode,
  contactSelectList,
  onlinUserList,
  searchString,
  handleSearch,
  setContactSelectList,
  mode,
  handleCreateGroup,
  handleSubmit,
  createGroup,
  cancelForward,
  user,
  forwardMsg,
  UserListSearch,
  getGroupList,
  getContacts,
  getHistoryUserList,
  UserList,
  HistoryUserList,
  groupList,
  removeGroup,
  leftFromGroup,
  deleteChat,
  contactName,
}: props) => {
  const [showUserType, setShowUserType] = useState<string>("Globe");
  const [contactList, setContactList] = useState<any>([]);
  const [HistoryContactList, setHistoryContactList] =
    useState<any>(HistoryUserList);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [favoriteFilter, setfavoriteFilter] = useState<any>(false);
  const [PageSize, setPageSize] = useState<any>(8);
  const [masrjin, setmasrjin] = useState<any>(0);
  const [organizationType, setOrganizationType] = useState<any>(1);
  const [search, setSearch] = useState<boolean>(false);
  const [searchStr, setsearchStr] = useState<any>(searchString);
  const [noData, setNoData] = useState(false);

  const lastContactName = useRef<any>();
  const layoutContext = useContext(LayoutContext);
  const listInnerRef = useRef(null);
  const listContactRef = useRef(contactSelectList);
  listContactRef.current = contactSelectList;
  const [longPressTriggered, setLongPressTriggered] = useState<any>(false);
  const timeout = useRef<any>();
  const target = useRef<any>();
  let fristClick: Boolean = true;

  const [showOperations, setShowOperations] = useState(false);
  const formButton = useRef<any>();
  const showOperationsRef = useRef<any>(false);

  const onLongPress = (e, item) => {
    e.stopPropagation();
    if (forwardMsg || mode == "create") {
      setContactSelectList(item);
    }
  };

  const Press = (e, item) => {
    e.stopPropagation();
    if ((listContactRef.current.length > 0 && forwardMsg) || mode == "create") {
      onLongPress(e, item);
    } else {
      sendContactToShowChat(item);
    }
  };

  const start = useCallback(
    (event, item) => {
      if (event.target) {
        event.target.addEventListener("touchend", {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event, item);
        setLongPressTriggered(true);
      }, 1500);
    },
    [onLongPress]
  );

  const clear = useCallback(
    (event, item, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      if (shouldTriggerClick && !longPressTriggered) {
        // Press(event , item);
      }

      setLongPressTriggered(false);
      if (target.current) {
        target.current.removeEventListener("touchend", {
          passive: false,
        });
      }
    },
    [longPressTriggered]
  );

  function ownClick(e, item) {
    fristClick = true;
    setTimeout(() => {
      if (fristClick) {
        Press(e, item);
      }
    }, 250);
  }

  function DblClick(e, item) {
    fristClick = false;
    onLongPress(e, item);
  }

  const getPersonContactsAsync = async (type, str: any = null) => {
    setLoading(true);
    let favorite = localStorage.getItem("Favorite");
    let locallist;
    if (favorite == null) {
      locallist = {};
    } else {
      locallist = JSON.parse(favorite);
    }
    setLoading(false);
  };

  const ScrollData = (e) => {
    /*    
           if (listInnerRef.current) {
               const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
               if (scrollTop + clientHeight === scrollHeight) {
                   
                   if(masrjin==30){
                    setPageSize(PageSize+3)
                    setmasrjin(0)
                   }else{
                      setmasrjin(masrjin+10)
       
                   }
               }
             } */
  };
  const onClickGlobe = async () => {
    setmode(null);
    setContactSelectList(null);
    await getHistoryUserList(null);

    if (HistoryUserList[0]?.id == GUID_EMPTY) {
      setNoData(true);
    }
    setHistoryContactList(HistoryUserList);
    setShowUserType("Globe");
  };
  const onClickContact = async () => {
    try {
      const res: any = await getContacts();
      setContactSelectList(null);
      if (res != undefined && res[0].id == GUID_EMPTY) {
        setNoData(true);
      }
      setHistoryContactList(res);
      setShowUserType("Contact");
    } catch (error) {}
  };
  const onClickGroupList = async () => {
    setmode(null);

    await getGroupList();
    if (
      groupList != undefined &&
      groupList[0] != undefined &&
      groupList[0].id == GUID_EMPTY
    ) {
      setNoData(true);
    }
    setHistoryContactList(groupList);
    setShowUserType("GroupList");
  };

  const changefavoriteFilter = (e) => {
    let favorite = localStorage.getItem("Favorite");
    let locallist;
    if (favorite == null) {
      locallist = {};
    } else {
      locallist = JSON.parse(favorite);
    }

    let list = contactList.map((item) => {
      let fav = locallist[organizationType]
        ? locallist[organizationType]?.includes(
            item.latestMessageUserIdOrGroupId
          )
        : false;
      return {
        latestMessagePersonOrGroupName: item.latestMessagePersonOrGroupName,
        latestMessageUserIdOrGroupId: item.latestMessageUserIdOrGroupId,
        isFav: fav,
      };
    });
    setContactList(list);
    setfavoriteFilter(!favoriteFilter);
  };

  const addToFavorite = (e) => {
    let favorite = localStorage.getItem("Favorite");
    let locallist;
    if (favorite == null) {
      locallist = {};
    } else {
      locallist = JSON.parse(favorite);
    }
    if (locallist[organizationType] && locallist[organizationType].length > 0) {
      if (locallist[organizationType].includes(e.id)) {
        locallist[organizationType] = locallist[organizationType].replace(
          e.id,
          ""
        );
      } else {
        locallist[organizationType] += e.id + "*";
      }
    } else {
      locallist[organizationType] += e.id + "*";
    }
    localStorage.setItem("Favorite", JSON.stringify(locallist));
  };

  function multiSelector(e) {
    let seletedList: any = JSON.parse(JSON.stringify(selectedRow));
    let index1 = selectedRow.findIndex((item, index) => {
      if (item.latestMessageUserIdOrGroupId == e.id) {
        return true;
      }
    });

    if (index1 > -1) {
      seletedList.splice(index1, 1);
    } else {
      seletedList.splice(0, 0, e);
      setSelectedRow(seletedList);
    }

    sendContactToShowChat(seletedList);
  }

  const Searchhandleer = (str) => {
    if (str == "") {
      setsearchStr(null);
    } else {
      setsearchStr(str);
    }
    handleSearch(str);
    if (showUserType == "Globe") {
      getHistoryUserList(str);
    } else {
      UserListSearch(str);
    }
  };

  useEffect(() => {
    getPersonContactsAsync(organizationType);
  }, [organizationType, PageSize]);

  useEffect(() => {
    if (contactName) {
      lastContactName.current = contactName;
    }
  }, [contactName]);

  useEffect(() => {
    if (showUserType == "Globe") {
      setHistoryContactList(HistoryUserList);
    } else if (showUserType == "GroupList") {
      setHistoryContactList(groupList);
    } else {
      setHistoryContactList(UserList);
    }
  }, [UserList, HistoryUserList, showUserType, groupList]);

  function isInList(e) {
    let flag = true;
    contactSelectList.forEach((item) => {
      if (item.latestMessageUserIdOrGroupId == e) {
        flag = false;
      }
    });
    return !flag;
  }

  return (
    <div className="d-flex h-100 ">
      <div className="col-2 h-100  col-md-12 mx-auto chat__contact-container d-flex flex-column align-items-center">
        <span
          style={{ justifyContent: "space-between" }}
          className="d-flex mr-3  align-items-center m-1 w-100  rounded-top"
        >
          <div className="contact-book col-8">
            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"contact-book"}`}
                >
                  {trans("ChatHistory")}
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={["fas", "globe"]}
                  onClick={onClickGlobe}
                  style={{ height: "20px", cursor: "pointer" }}
                  className="mx-1 contact-book"
                  color="grey"
                />
              </span>
            </OverlayTrigger>
            {layoutContext.currentUserPosition.PositionId && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 600, hide: 0 }}
                overlay={
                  <Tooltip
                    className="tooltip"
                    id={`button-tooltip-${"Contacts"}`}
                  >
                    {trans("Contacts")}
                  </Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    icon={["fas", "contact-book"]}
                    onClick={onClickContact}
                    style={{
                      height: "20px",
                      paddingRight: "8px",
                      cursor: "pointer",
                    }}
                    className="mx-1 contact-book"
                    color="grey"
                  />
                </span>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"users"}`}
                >
                  {trans("Groups")}
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={["fas", "users"]}
                  onClick={onClickGroupList}
                  style={{
                    height: "20px",
                    paddingRight: "8px",
                    cursor: "pointer",
                  }}
                  className="mx-1 contact-book"
                  color="grey"
                />
              </span>
            </OverlayTrigger>
            {layoutContext.currentUserPosition.PositionId &&
              layoutContext.currentUserPosition.OrganizationType !=
                OrganizationType.Customers && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 600, hide: 0 }}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      id={`button-tooltip-${"user-plus"}`}
                    >
                      {trans("newGroup")}
                    </Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      icon={["fas", "user-plus"]}
                      onClick={(e) => {
                        onClickContact();
                        createGroup(e);
                      }}
                      style={{
                        height: "20px",
                        paddingRight: "8px",
                        cursor: "pointer",
                      }}
                      className="mx-1 contact-book"
                      color="grey"
                    />
                  </span>
                </OverlayTrigger>
              )}
          </div>
          {forwardMsg ? (
            <>
              {contactSelectList.length > 0 ? (
                <div className=" col-3">
                  {`${trans("Forwardto")} ${contactSelectList.length}...`}
                </div>
              ) : (
                <div className=" col-3">{trans("Forwardto")}...</div>
              )}

              <div
                onClick={(e) => {
                  sendContactToShowChat(lastContactName.current);
                  cancelForward();
                }}
                style={{ marginRight: "11px" }}
                className="col-1   backIcon"
              >
                <FontAwesomeIcon
                  className="chat__chatbox-reply-btn"
                  color={"grey"}
                  icon={["fas", "xmark"]}
                />
              </div>
            </>
          ) : (
            <>
              {mode == "create" && (
                <>
                  {contactSelectList.length > 0 ? (
                    <div className=" col-3">
                      <div className="position-relative">
                        {`${trans("New Group")} `}
                        <span className="groupBadge bg-warning ">
                          {contactSelectList.length}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className=" col-3">{trans("New Group")}...</div>
                  )}
                  <div
                    onClick={(e) => {
                      cancelForward();
                      setmode(null);
                    }}
                    style={{ marginRight: "11px" }}
                    className="col-1   backIcon"
                  >
                    <FontAwesomeIcon
                      className="chat__chatbox-reply-btn"
                      color={"grey"}
                      icon={["fas", "xmark"]}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </span>
        <div
          style={{ height: "94%" }}
          className="chat__contact-list w-100 card pb-1 px-0"
        >
          
          {(showUserType == "Contact" || showUserType == "Globe") && (
            <Search
              onChange={Searchhandleer}
              defultSerach={searchStr}
              autoFocus={(searchString&& !document.activeElement?.tagName.includes("TEXTAREA"))? true :false}
            />
          )}
          <ul
            onScroll={ScrollData}
            ref={listInnerRef}
            style={{ paddingBottom: masrjin + "px", height: "100%" }}
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {HistoryContactList.map((item) => (
                  <>
                    {!noData && (
                      <>
                        {isInList(item.latestMessageUserIdOrGroupId) ? (
                          <li
                            className="UserSelected"
                            style={{ paddingRight: "10px" }}
                            key={item.latestMessageUserIdOrGroupId}
                          >
                            <div
                              style={{ cursor: "pointer", width: "89%" }}
                              className="d-flex align-items-center"
                            >
                              <div
                                className=" d-flex align-items-center mt-1 mr-2 "
                                onClick={(e) => ownClick(e, item)}
                                onDoubleClick={(e) => DblClick(e, item)}
                                onTouchEnd={(e) => clear(e, item)}
                                onTouchStart={(e) => start(e, item)}
                              >
                                {item.isDirect ? (
                                  <div
                                    className={`borderUser   borderUserSelect  `}
                                  >
                                    <FontAwesomeIcon
                                      style={{ height: "16px" }}
                                      icon={["fas", "user-circle"]}
                                      color="grey"
                                      className=" chat__contact-list-avatar"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={`borderUser   borderUserSelect  `}
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "users"]}
                                      color="grey"
                                      className=" chat__contact-list-avatar"
                                    />
                                  </div>
                                )}
                                <div>
                                  <div
                                    className={` tesxtUserName  tesxtUserSelect  `}
                                  >
                                    {item.latestMessagePersonOrGroupName}
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#5e5e5e",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "130px",
                                    }}
                                  >
                                    {" "}
                                    {item.latestMessage}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <>
                              {item.haveMsg || item.unreadCount > 0 ? (
                                <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                  <span className="unreadCount bg-warning text-align-center  ">
                                    {item.unreadCount > 0
                                      ? item.unreadCount
                                      : null}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          </li>
                        ) : (
                          <li
                            style={{ marginRight: "10px" }}
                            key={item.latestMessageUserIdOrGroupId}
                          >
                            <div
                              style={{ cursor: "pointer", width: "89%" }}
                              className="d-flex align-items-center"
                            >
                              <div
                                style={{ width: "100%" }}
                                className="d-flex align-items-center mt-1 mr-2 "
                                onClick={(e) => ownClick(e, item)}
                                onDoubleClick={(e) => DblClick(e, item)}
                                onTouchEnd={(e) => clear(e, item)}
                                onTouchStart={(e) => start(e, item)}
                              >
                                {showUserType != "Contact" && (
                                  <button
                                    ref={formButton}
                                    onFocus={() => {
                                      setShowOperations(
                                        item.latestMessageUserIdOrGroupId
                                      );
                                      showOperationsRef.current =
                                        item.latestMessageUserIdOrGroupId;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "relative",
                                      width: "20px",
                                      paddingLeft: 0,
                                      marginRight: "-13px",
                                      marginBottom: "4px",
                                    }}
                                    tabIndex={-1}
                                    type="button"
                                    className="operations__trigger-btn"
                                    onBlur={() => {
                                      setShowOperations(false);
                                      showOperationsRef.current = false;
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={[ICON_TYPE, "ellipsis-v"]}
                                    />
                                    {(showOperations ==
                                      item.latestMessageUserIdOrGroupId ||
                                      showOperationsRef.current ==
                                        item.latestMessageUserIdOrGroupId) && (
                                      <div
                                        style={{
                                          minWidth: "90px",
                                          right: "30px",
                                          top: `${-9}px`,
                                          width: "120px",
                                        }}
                                        className={`operations__operations-icons pt-1 ${
                                          showOperations ==
                                            item.latestMessageUserIdOrGroupId ||
                                          showOperationsRef.current ==
                                            item.latestMessageUserIdOrGroupId
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        {!item.isDirect && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                leftFromGroup(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  transform: "rotate(180deg)",
                                                }}
                                                className="chat__chatbox-reply-btn"
                                                color={"grey"}
                                                icon={[
                                                  "fas",
                                                  "arrow-right-from-bracket",
                                                ]}
                                              />

                                              <p>{trans("LeftTheGroup")}</p>
                                            </div>
                                          </div>
                                        )}
                                        {showUserType != "GroupList" ? (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                deleteChat(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "trash"]}
                                                className="chat__chatbox-reply-btn"
                                                color={`${"grey"}`}
                                              />
                                              <p>{trans("Delete")}</p>
                                            </div>
                                          </div>
                                        ) : (
                                          item.isGroupOwner && (
                                            <div
                                              className={`operations__operations-icon-wrapper `}
                                            >
                                              <div
                                                className="operations__operations-icon-wrapper"
                                                onClick={() => {
                                                  removeGroup(item);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fas", "trash"]}
                                                  className="chat__chatbox-reply-btn"
                                                  color={`${"grey"}`}
                                                />
                                                <p>{trans("Delete")}</p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </button>
                                )}

                                {item.isDirect ? (
                                  <div
                                    style={{ position: "relative" }}
                                    className={`borderUser      `}
                                  >
                                    <FontAwesomeIcon
                                      style={{ height: "16px" }}
                                      icon={["fas", "user-circle"]}
                                      color="grey"
                                      className=" chat__contact-list-avatar"
                                    />
                                    {(item.isOnline ||
                                      onlinUserList.includes(
                                        item.latestMessageUserIdOrGroupId
                                      )) && <div className="onlineUsear"></div>}
                                  </div>
                                ) : (
                                  <div className={`borderUser `}>
                                    <FontAwesomeIcon
                                      icon={["fas", "users"]}
                                      color="grey"
                                      className=" chat__contact-list-avatar"
                                    />
                                  </div>
                                )}
                                <div style={{ width: "100%" }}>
                                  <div className="tesxtUserName">
                                    {item.latestMessagePersonOrGroupName}
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#5e5e5e",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "130px",
                                    }}
                                  >
                                    {" "}
                                    {item.latestMessage}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <>
                              {item.haveMsg || item.unreadCount > 0 ? (
                                <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                  <span className="unreadCount bg-warning text-align-center  ">
                                    {item.unreadCount > 0
                                      ? item.unreadCount
                                      : null}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          </li>
                        )}
                      </>
                    )}
                    {noData && (
                      <div
                        style={{
                          marginRight: "18%",
                          marginTop: "6px",
                          color: "#39393999",
                        }}
                      >
                        {trans("NoDataForPreview")}
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </ul>
          {contactSelectList.length > 0 && forwardMsg && (
            <button
              style={{
                height: "33px",
                borderRadius: "50%",
                backgroundColor: "#0d6efd",
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
              className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
            >
              <FontAwesomeIcon
                icon={["fas", "paper-plane"]}
                color="white"
                className="chat__input-btn-icon"
              />
            </button>
          )}
          {contactSelectList.length > 0 && mode == "create" && (
            <form
              className="w-100"
              onSubmit={(e) => {
                handleCreateGroup(e);
                setShowUserType("Globe");
              }}
            >
              <div
                style={{ height: "30px" }}
                className="w-100 d-flex align-items-center justify-content-center px-1"
              >
                <button
                  type="submit"
                  style={{
                    height: "37px",
                    borderRadius: "50%",
                    backgroundColor: "#0d6efd",
                  }}
                  className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
                >
                  <FontAwesomeIcon
                    icon={["fas", "check"]}
                    color="white"
                    className="chat__input-btn-icon"
                  />
                </button>

                <div
                  style={{ height: "80%" }}
                  className="w-100  position-relative"
                >
                  <input
                    autoFocus={true}
                    style={{ height: "100%" }}
                    name="GroupName"
                    className={`p-1 groupNameInput `}
                    placeholder={"اسم گروه را وارد نمایید"}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
        <div className="chat__contact-toggle chat-contact border border-top-0 border-bottom-1 border-start-0 border-end-0 py-2 ">
          <FontAwesomeIcon
            icon={["fas", "globe"]}
            onClick={onClickGlobe}
            className="chat__contact-icon"
          />
        </div>

        <div className="chat__contact-toggle chat-contact  border border-top-0 border-bottom-1 border-start-0 border-end-0 py-2">
          <FontAwesomeIcon
            icon={["fas", "contact-book"]}
            onClick={onClickContact}
            className="chat__contact-icon"
          />
        </div>
        <div className="chat__contact-toggle chat-contact  border border-top-0 border-bottom-1 border-start-0 border-end-0 py-2">
          <FontAwesomeIcon
            icon={["fas", "users"]}
            onClick={onClickGroupList}
            className="chat__contact-icon"
          />
        </div>
      </div>

      <div className="d-md-none border border-end-0 border-start-1"></div>

      {showUserType == "Globe" && (
        <div
          className={` ${
            contactName == null ? "col-10 " : " col-12"
          }  d-md-none`}
        >
          <div style={{ height: "88vh" }} className="  border-end-0 ">
            <div className="chat__contact-toggle-filter mt-1">
              <div className="col-7">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearch(true)}
                >
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    color="#dfdfdf"
                    className="chat__contact-toggle-filter-icon"
                  />
                </span>
                <div className={`navbar-search-block ${search ? "open" : ""}`}>
                  <form
                    className="form-inline-search"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div
                      style={{ marginTop: "8px", flexWrap: "nowrap" }}
                      className="input-group input-group-sm"
                    >
                      <input
                        name="search"
                        onChange={(e) => {
                          Searchhandleer(e.target.value);
                        }}
                        className="rounded d-md-none mb-1 chat__message-search"
                        placeholder="جستجو مخاطبین ..."
                      />
                      <button
                        className="navbar-search-btn ms-1"
                        onClick={() => setSearch(false)}
                      >
                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5  d-flex">
                {forwardMsg && (
                  <>
                    {contactSelectList.length > 0 ? (
                      <div className=" col-10">
                        {`${trans("Forwardto")}${
                          contactSelectList.length
                        } ${trans("chat")}...`}
                      </div>
                    ) : (
                      <div className=" col-10">{trans("Forwardto")}...</div>
                    )}

                    <div
                      onClick={(e) => {
                        sendContactToShowChat(lastContactName.current);
                        cancelForward();
                      }}
                      style={{ marginRight: "11px" }}
                      className="col-2   backIcon"
                    >
                      <FontAwesomeIcon
                        className="chat__chatbox-reply-btn"
                        color={"grey"}
                        icon={["fas", "xmark"]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <ul
              onScroll={ScrollData}
              ref={listInnerRef}
              style={{
                paddingBottom: masrjin + "px",
                height: "80%",
                overflowY: "auto",
              }}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  {HistoryContactList.map((item) => (
                    <>
                      {!noData && (
                        <>
                          {isInList(item.latestMessageUserIdOrGroupId) ? (
                            <li
                              className="UserSelected"
                              style={{
                                paddingRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className=" d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  <button
                                    ref={formButton}
                                    onFocus={() => {
                                      setShowOperations(
                                        item.latestMessageUserIdOrGroupId
                                      );
                                      showOperationsRef.current =
                                        item.latestMessageUserIdOrGroupId;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "relative",
                                      width: "20px",
                                      paddingLeft: 0,
                                      marginRight: "-13px",
                                      marginBottom: "4px",
                                    }}
                                    tabIndex={-1}
                                    type="button"
                                    className="operations__trigger-btn"
                                    onBlur={() => {
                                      setShowOperations(false);
                                      showOperationsRef.current = false;
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={[ICON_TYPE, "ellipsis-v"]}
                                    />
                                    {(showOperations ==
                                      item.latestMessageUserIdOrGroupId ||
                                      showOperationsRef.current ==
                                        item.latestMessageUserIdOrGroupId) && (
                                      <div
                                        style={{
                                          minWidth: "90px",
                                          right: "30px",
                                          top: `${-9}px`,
                                          width: "120px",
                                        }}
                                        className={`operations__operations-icons pt-1 ${
                                          showOperations ==
                                            item.latestMessageUserIdOrGroupId ||
                                          showOperationsRef.current ==
                                            item.latestMessageUserIdOrGroupId
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        {!item.isDirect && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                leftFromGroup(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  transform: "rotate(180deg)",
                                                }}
                                                className="chat__chatbox-reply-btn"
                                                color={"grey"}
                                                icon={[
                                                  "fas",
                                                  "arrow-right-from-bracket",
                                                ]}
                                              />

                                              <p>{trans("LeftTheGroup")}</p>
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className={`operations__operations-icon-wrapper `}
                                        >
                                          <div
                                            className="operations__operations-icon-wrapper"
                                            onClick={() => {
                                              deleteChat(item);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={["fas", "trash"]}
                                              className="chat__chatbox-reply-btn"
                                              color={`${"grey"}`}
                                            />
                                            <p>{trans("Delete")}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </button>

                                  {item.isDirect ? (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      {/* <FontAwesomeIcon style={{height : "16px"}}  icon={['fas', 'user-circle']} color='grey' className=" chat__contact-list-avatar" /> */}
                                    </div>
                                  ) : (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      className={` tesxtUserName  tesxtUserSelect  `}
                                    >
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          ) : (
                            <li
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  <button
                                    ref={formButton}
                                    onFocus={() => {
                                      setShowOperations(
                                        item.latestMessageUserIdOrGroupId
                                      );
                                      showOperationsRef.current =
                                        item.latestMessageUserIdOrGroupId;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "relative",
                                      width: "20px",
                                      paddingLeft: 0,
                                      marginRight: "-13px",
                                      marginBottom: "4px",
                                    }}
                                    tabIndex={-1}
                                    type="button"
                                    className="operations__trigger-btn"
                                    onBlur={() => {
                                      setShowOperations(false);
                                      showOperationsRef.current = false;
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={[ICON_TYPE, "ellipsis-v"]}
                                    />
                                    {(showOperations ==
                                      item.latestMessageUserIdOrGroupId ||
                                      showOperationsRef.current ==
                                        item.latestMessageUserIdOrGroupId) && (
                                      <div
                                        style={{
                                          minWidth: "90px",
                                          right: "30px",
                                          top: `${-9}px`,
                                          width: "120px",
                                        }}
                                        className={`operations__operations-icons pt-1 ${
                                          showOperations ==
                                            item.latestMessageUserIdOrGroupId ||
                                          showOperationsRef.current ==
                                            item.latestMessageUserIdOrGroupId
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        {!item.isDirect && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                leftFromGroup(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  transform: "rotate(180deg)",
                                                }}
                                                className="chat__chatbox-reply-btn"
                                                color={"grey"}
                                                icon={[
                                                  "fas",
                                                  "arrow-right-from-bracket",
                                                ]}
                                              />

                                              <p>{trans("LeftTheGroup")}</p>
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className={`operations__operations-icon-wrapper `}
                                        >
                                          <div
                                            className="operations__operations-icon-wrapper"
                                            onClick={() => {
                                              deleteChat(item);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={["fas", "trash"]}
                                              className="chat__chatbox-reply-btn"
                                              color={`${"grey"}`}
                                            />
                                            <p>{trans("Delete")}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </button>
                                  {item.isDirect ? (
                                    <div
                                      style={{ position: "relative" }}
                                      className={`borderUser      `}
                                    >
                                      <FontAwesomeIcon
                                        style={{ height: "16px" }}
                                        icon={["fas", "user-circle"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                      {(item.isOnline ||
                                        onlinUserList.includes(
                                          item.latestMessageUserIdOrGroupId
                                        )) && (
                                        <div className="onlineUsear"></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className={`borderUser      `}>
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className="tesxtUserName">
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            {contactSelectList.length > 0 && forwardMsg && (
              <button
                style={{
                  height: "33px",
                  borderRadius: "50%",
                  backgroundColor: "#0d6efd",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={["fas", "paper-plane"]}
                  color="white"
                  className="chat__input-btn-icon"
                />
              </button>
            )}
            {contactSelectList.length > 0 && mode == "create" && (
              <form
                className="w-100"
                onSubmit={(e) => {
                  handleCreateGroup(e);
                  setShowUserType("Globe");
                }}
              >
                <div
                  style={{ height: "30px" }}
                  className="w-100 d-flex align-items-center justify-content-center px-1"
                >
                  <button
                    type="submit"
                    style={{
                      height: "37px",
                      borderRadius: "50%",
                      backgroundColor: "#0d6efd",
                    }}
                    className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "check"]}
                      color="white"
                      className="chat__input-btn-icon"
                    />
                  </button>

                  <div
                    style={{ height: "80%" }}
                    className="w-100  position-relative"
                  >
                    <input
                      autoFocus={true}
                      style={{ height: "100%" }}
                      name="GroupName"
                      className={`p-1 groupNameInput `}
                      placeholder={"اسم گروه را وارد نمایید"}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      {showUserType == "GroupList" && (
        <div
          className={` ${
            contactName == null ? "col-10 " : " col-12"
          }  d-md-none`}
        >
          <div style={{ height: "88vh" }} className="  border-end-0 ">
            <div className="chat__contact-toggle-filter mt-1">
              <div className="col-5  d-flex">
                {forwardMsg && (
                  <>
                    {contactSelectList.length > 0 ? (
                      <div className=" col-10">
                        {`${trans("Forwardto")}${
                          contactSelectList.length
                        } ${trans("chat")}...`}
                      </div>
                    ) : (
                      <div className=" col-10">{trans("Forwardto")}...</div>
                    )}

                    <div
                      onClick={(e) => {
                        sendContactToShowChat(lastContactName.current);
                        cancelForward();
                      }}
                      style={{ marginRight: "11px" }}
                      className="col-2   backIcon"
                    >
                      <FontAwesomeIcon
                        className="chat__chatbox-reply-btn"
                        color={"grey"}
                        icon={["fas", "xmark"]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <ul
              onScroll={ScrollData}
              ref={listInnerRef}
              style={{
                paddingBottom: masrjin + "px",
                height: "80%",
                overflowY: "auto",
              }}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  {HistoryContactList.map((item) => (
                    <>
                      {!noData && (
                        <>
                          {isInList(item.latestMessageUserIdOrGroupId) ? (
                            <li
                              className="UserSelected"
                              style={{
                                paddingRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className=" d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  <button
                                    ref={formButton}
                                    onFocus={() => {
                                      setShowOperations(
                                        item.latestMessageUserIdOrGroupId
                                      );
                                      showOperationsRef.current =
                                        item.latestMessageUserIdOrGroupId;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "relative",
                                      width: "20px",
                                      paddingLeft: 0,
                                      marginRight: "-13px",
                                      marginBottom: "4px",
                                    }}
                                    tabIndex={-1}
                                    type="button"
                                    className="operations__trigger-btn"
                                    onBlur={() => {
                                      setShowOperations(false);
                                      showOperationsRef.current = false;
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={[ICON_TYPE, "ellipsis-v"]}
                                    />
                                    {(showOperations ==
                                      item.latestMessageUserIdOrGroupId ||
                                      showOperationsRef.current ==
                                        item.latestMessageUserIdOrGroupId) && (
                                      <div
                                        style={{
                                          minWidth: "90px",
                                          right: "30px",
                                          top: `${-9}px`,
                                          width: "120px",
                                        }}
                                        className={`operations__operations-icons pt-1 ${
                                          showOperations ==
                                            item.latestMessageUserIdOrGroupId ||
                                          showOperationsRef.current ==
                                            item.latestMessageUserIdOrGroupId
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        {!item.isDirect && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                leftFromGroup(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  transform: "rotate(180deg)",
                                                }}
                                                className="chat__chatbox-reply-btn"
                                                color={"grey"}
                                                icon={[
                                                  "fas",
                                                  "arrow-right-from-bracket",
                                                ]}
                                              />

                                              <p>{trans("LeftTheGroup")}</p>
                                            </div>
                                          </div>
                                        )}
                                        {showUserType != "GroupList" && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                deleteChat(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "trash"]}
                                                className="chat__chatbox-reply-btn"
                                                color={`${"grey"}`}
                                              />
                                              <p>{trans("Delete")}</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </button>

                                  {item.isDirect ? (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      <FontAwesomeIcon
                                        style={{ height: "16px" }}
                                        icon={["fas", "user-circle"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      className={` tesxtUserName  tesxtUserSelect  `}
                                    >
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          ) : (
                            <li
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  <button
                                    ref={formButton}
                                    onFocus={() => {
                                      setShowOperations(
                                        item.latestMessageUserIdOrGroupId
                                      );
                                      showOperationsRef.current =
                                        item.latestMessageUserIdOrGroupId;
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    style={{
                                      position: "relative",
                                      width: "20px",
                                      paddingLeft: 0,
                                      marginRight: "-13px",
                                      marginBottom: "4px",
                                    }}
                                    tabIndex={-1}
                                    type="button"
                                    className="operations__trigger-btn"
                                    onBlur={() => {
                                      setShowOperations(false);
                                      showOperationsRef.current = false;
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="grey"
                                      icon={[ICON_TYPE, "ellipsis-v"]}
                                    />
                                    {(showOperations ==
                                      item.latestMessageUserIdOrGroupId ||
                                      showOperationsRef.current ==
                                        item.latestMessageUserIdOrGroupId) && (
                                      <div
                                        style={{
                                          minWidth: "90px",
                                          right: "30px",
                                          top: `${-9}px`,
                                          width: "120px",
                                        }}
                                        className={`operations__operations-icons pt-1 ${
                                          showOperations ==
                                            item.latestMessageUserIdOrGroupId ||
                                          showOperationsRef.current ==
                                            item.latestMessageUserIdOrGroupId
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        {!item.isDirect && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                leftFromGroup(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  transform: "rotate(180deg)",
                                                }}
                                                className="chat__chatbox-reply-btn"
                                                color={"grey"}
                                                icon={[
                                                  "fas",
                                                  "arrow-right-from-bracket",
                                                ]}
                                              />

                                              <p>{trans("LeftTheGroup")}</p>
                                            </div>
                                          </div>
                                        )}
                                        {showUserType != "GroupList" && (
                                          <div
                                            className={`operations__operations-icon-wrapper `}
                                          >
                                            <div
                                              className="operations__operations-icon-wrapper"
                                              onClick={() => {
                                                deleteChat(item);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={["fas", "trash"]}
                                                className="chat__chatbox-reply-btn"
                                                color={`${"grey"}`}
                                              />
                                              <p>{trans("Delete")}</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </button>

                                  {item.isDirect ? (
                                    <div
                                      style={{ position: "relative" }}
                                      className={`borderUser      `}
                                    >
                                      <FontAwesomeIcon
                                        style={{ height: "16px" }}
                                        icon={["fas", "user-circle"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                      {(item.isOnline ||
                                        onlinUserList.includes(
                                          item.latestMessageUserIdOrGroupId
                                        )) && (
                                        <div className="onlineUsear"></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className={`borderUser      `}>
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className="tesxtUserName">
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            {contactSelectList.length > 0 && forwardMsg && (
              <button
                style={{
                  height: "33px",
                  borderRadius: "50%",
                  backgroundColor: "#0d6efd",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={["fas", "paper-plane"]}
                  color="white"
                  className="chat__input-btn-icon"
                />
              </button>
            )}
            {contactSelectList.length > 0 && mode == "create" && (
              <form
                className="w-100"
                onSubmit={(e) => {
                  handleCreateGroup(e);
                  setShowUserType("Globe");
                }}
              >
                <div
                  style={{ height: "30px" }}
                  className="w-100 d-flex align-items-center justify-content-center px-1"
                >
                  <button
                    type="submit"
                    style={{
                      height: "37px",
                      borderRadius: "50%",
                      backgroundColor: "#0d6efd",
                    }}
                    className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "check"]}
                      color="white"
                      className="chat__input-btn-icon"
                    />
                  </button>

                  <div
                    style={{ height: "80%" }}
                    className="w-100  position-relative"
                  >
                    <input
                      autoFocus={true}
                      style={{ height: "100%" }}
                      name="GroupName"
                      className={`p-1 groupNameInput `}
                      placeholder={"اسم گروه را وارد نمایید"}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      {showUserType == "Contact" && (
        <div
          className={` ${
            contactName == null ? "col-10 " : " col-12"
          }  d-md-none`}
        >
          <div style={{ height: "88vh" }} className=" border-end-0 ">
            <div className="chat__contact-toggle-filter mt-1">
              <div className="col-7">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearch(true)}
                >
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    color="#dfdfdf"
                    className="chat__contact-toggle-filter-icon"
                  />
                </span>
                <div className={`navbar-search-block ${search ? "open" : ""}`}>
                  <form
                    className="form-inline-search"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div
                      style={{ marginTop: "8px", flexWrap: "nowrap" }}
                      className="input-group input-group-sm"
                    >
                      <input
                        name="search"
                        onChange={(e) => {
                          Searchhandleer(e.target.value);
                        }}
                        className="rounded d-md-none mb-1 chat__message-search"
                        placeholder="جستجو مخاطبین ..."
                      />
                      <button
                        className="navbar-search-btn ms-1"
                        onClick={() => setSearch(false)}
                      >
                        <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-5 d-flex">
                {forwardMsg && (
                  <>
                    {contactSelectList.length > 0 ? (
                      <div className=" col-10">
                        {`${trans("Forwardto")}${
                          contactSelectList.length
                        } ${trans("chat")}...`}
                      </div>
                    ) : (
                      <div className=" col-10">{trans("Forwardto")}...</div>
                    )}

                    <div
                      onClick={(e) => {
                        sendContactToShowChat(lastContactName.current);
                        cancelForward();
                      }}
                      style={{ marginRight: "11px" }}
                      className="col-2   backIcon"
                    >
                      <FontAwesomeIcon
                        className="chat__chatbox-reply-btn"
                        color={"grey"}
                        icon={["fas", "xmark"]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <ul
              onScroll={ScrollData}
              ref={listInnerRef}
              style={{
                paddingBottom: masrjin + "px",
                height: "80%",
                overflowY: "auto",
              }}
            >
              {loading ? (
                <Loading />
              ) : (
                <>
                  {HistoryContactList.map((item) => (
                    <>
                      {!noData && (
                        <>
                          {isInList(item.latestMessageUserIdOrGroupId) ? (
                            <li
                              className="UserSelected"
                              style={{
                                paddingRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className=" d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  {showUserType != "Contact" && (
                                    <button
                                      ref={formButton}
                                      onFocus={() => {
                                        setShowOperations(
                                          item.latestMessageUserIdOrGroupId
                                        );
                                        showOperationsRef.current =
                                          item.latestMessageUserIdOrGroupId;
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{
                                        position: "relative",
                                        width: "20px",
                                        paddingLeft: 0,
                                        marginRight: "-13px",
                                        marginBottom: "4px",
                                      }}
                                      tabIndex={-1}
                                      type="button"
                                      className="operations__trigger-btn"
                                      onBlur={() => {
                                        setShowOperations(false);
                                        showOperationsRef.current = false;
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="grey"
                                        icon={[ICON_TYPE, "ellipsis-v"]}
                                      />
                                      {(showOperations ==
                                        item.latestMessageUserIdOrGroupId ||
                                        showOperationsRef.current ==
                                          item.latestMessageUserIdOrGroupId) && (
                                        <div
                                          style={{
                                            minWidth: "90px",
                                            right: "30px",
                                            top: `${-9}px`,
                                            width: "120px",
                                          }}
                                          className={`operations__operations-icons pt-1 ${
                                            showOperations ==
                                              item.latestMessageUserIdOrGroupId ||
                                            showOperationsRef.current ==
                                              item.latestMessageUserIdOrGroupId
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >
                                          {!item.isDirect && (
                                            <div
                                              className={`operations__operations-icon-wrapper `}
                                            >
                                              <div
                                                className="operations__operations-icon-wrapper"
                                                onClick={() => {
                                                  leftFromGroup(item);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  style={{
                                                    transform: "rotate(180deg)",
                                                  }}
                                                  className="chat__chatbox-reply-btn"
                                                  color={"grey"}
                                                  icon={[
                                                    "fas",
                                                    "arrow-right-from-bracket",
                                                  ]}
                                                />

                                                <p>{trans("LeftTheGroup")}</p>
                                              </div>
                                            </div>
                                          )}
                                          {showUserType != "GroupList" && (
                                            <div
                                              className={`operations__operations-icon-wrapper `}
                                            >
                                              <div
                                                className="operations__operations-icon-wrapper"
                                                onClick={() => {
                                                  deleteChat(item);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fas", "trash"]}
                                                  className="chat__chatbox-reply-btn"
                                                  color={`${"grey"}`}
                                                />
                                                <p>{trans("Delete")}</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </button>
                                  )}
                                  {item.isDirect ? (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      <FontAwesomeIcon
                                        style={{ height: "16px" }}
                                        icon={["fas", "user-circle"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className={`borderUser   borderUserSelect  `}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      className={` tesxtUserName  tesxtUserSelect  `}
                                    >
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          ) : (
                            <li
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={item.latestMessageUserIdOrGroupId}
                            >
                              <div
                                style={{ cursor: "pointer", width: "89%" }}
                                className="d-flex align-items-center"
                              >
                                <div
                                  className="d-flex align-items-center mt-1 mr-2 "
                                  onClick={(e) => ownClick(e, item)}
                                  onDoubleClick={(e) => DblClick(e, item)}
                                  onTouchEnd={(e) => clear(e, item)}
                                  onTouchStart={(e) => start(e, item)}
                                >
                                  {showUserType != "Contact" && (
                                    <button
                                      ref={formButton}
                                      onFocus={() => {
                                        setShowOperations(
                                          item.latestMessageUserIdOrGroupId
                                        );
                                        showOperationsRef.current =
                                          item.latestMessageUserIdOrGroupId;
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      style={{
                                        position: "relative",
                                        width: "20px",
                                        paddingLeft: 0,
                                        marginRight: "-13px",
                                        marginBottom: "4px",
                                      }}
                                      tabIndex={-1}
                                      type="button"
                                      className="operations__trigger-btn"
                                      onBlur={() => {
                                        setShowOperations(false);
                                        showOperationsRef.current = false;
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="grey"
                                        icon={[ICON_TYPE, "ellipsis-v"]}
                                      />
                                      {(showOperations ==
                                        item.latestMessageUserIdOrGroupId ||
                                        showOperationsRef.current ==
                                          item.latestMessageUserIdOrGroupId) && (
                                        <div
                                          style={{
                                            minWidth: "90px",
                                            right: "30px",
                                            top: `${-9}px`,
                                            width: "120px",
                                          }}
                                          className={`operations__operations-icons pt-1 ${
                                            showOperations ==
                                              item.latestMessageUserIdOrGroupId ||
                                            showOperationsRef.current ==
                                              item.latestMessageUserIdOrGroupId
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >
                                          {!item.isDirect && (
                                            <div
                                              className={`operations__operations-icon-wrapper `}
                                            >
                                              <div
                                                className="operations__operations-icon-wrapper"
                                                onClick={() => {
                                                  leftFromGroup(item);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  style={{
                                                    transform: "rotate(180deg)",
                                                  }}
                                                  className="chat__chatbox-reply-btn"
                                                  color={"grey"}
                                                  icon={[
                                                    "fas",
                                                    "arrow-right-from-bracket",
                                                  ]}
                                                />

                                                <p>{trans("LeftTheGroup")}</p>
                                              </div>
                                            </div>
                                          )}
                                          {showUserType != "GroupList" && (
                                            <div
                                              className={`operations__operations-icon-wrapper `}
                                            >
                                              <div
                                                className="operations__operations-icon-wrapper"
                                                onClick={() => {
                                                  deleteChat(item);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fas", "trash"]}
                                                  className="chat__chatbox-reply-btn"
                                                  color={`${"grey"}`}
                                                />
                                                <p>{trans("Delete")}</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </button>
                                  )}

                                  {item.isDirect ? (
                                    <div
                                      style={{ position: "relative" }}
                                      className={`borderUser      `}
                                    >
                                      <FontAwesomeIcon
                                        style={{ height: "16px" }}
                                        icon={["fas", "user-circle"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                      {(item.isOnline ||
                                        onlinUserList.includes(
                                          item.latestMessageUserIdOrGroupId
                                        )) && (
                                        <div className="onlineUsear"></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className={`borderUser      `}>
                                      <FontAwesomeIcon
                                        icon={["fas", "users"]}
                                        color="grey"
                                        className=" chat__contact-list-avatar"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className="tesxtUserName">
                                      {item.latestMessagePersonOrGroupName}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        color: "#5e5e5e",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        width: "130px",
                                      }}
                                    >
                                      {" "}
                                      {item.latestMessage}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <>
                                {item.haveMsg || item.unreadCount > 0 ? (
                                  <div className="d-flex align-items-center justify-content-center text-white rounded-3 mx-2 ">
                                    <span className="unreadCount bg-warning text-align-center  ">
                                      {item.unreadCount > 0
                                        ? item.unreadCount
                                        : null}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                              {!item.isDirect && (
                                <div
                                  style={{ width: "20px" }}
                                  className="UserLi UserLi__delete-selected-option mt-2 ml-3 pl-2"
                                  data-key={item.latestMessageUserIdOrGroupId}
                                  onClick={() => {
                                    leftFromGroup(item);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "times"]}
                                  />
                                </div>
                              )}
                            </li>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            {contactSelectList.length > 0 && forwardMsg && (
              <button
                style={{
                  height: "33px",
                  borderRadius: "50%",
                  backgroundColor: "#0d6efd",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={["fas", "paper-plane"]}
                  color="white"
                  className="chat__input-btn-icon"
                />
              </button>
            )}
            {contactSelectList.length > 0 && mode == "create" && (
              <form
                className="w-100"
                onSubmit={(e) => {
                  handleCreateGroup(e);
                  setShowUserType("Globe");
                }}
              >
                <div
                  style={{ height: "30px" }}
                  className="w-100 d-flex align-items-center justify-content-center px-1"
                >
                  <button
                    type="submit"
                    style={{
                      height: "37px",
                      borderRadius: "50%",
                      backgroundColor: "#0d6efd",
                    }}
                    className={`chat__input-btn mx-1 border border-primary d-flex align-items-center justify-content-center `}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "check"]}
                      color="white"
                      className="chat__input-btn-icon"
                    />
                  </button>

                  <div
                    style={{ height: "80%" }}
                    className="w-100  position-relative"
                  >
                    <input
                      autoFocus={true}
                      style={{ height: "100%" }}
                      name="GroupName"
                      className={`p-1 groupNameInput `}
                      placeholder={"اسم گروه را وارد نمایید"}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ContactList);
