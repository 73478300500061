import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { useEffect, useState } from "react";

import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toast } from "components/ToastShow/ToastShow";
import { trans } from "utils/helpers";
import { getAddressBox, postAddressBox } from "./api";
import { Address } from "components/Address/Address";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Textbox } from "components/Textbox/Textbox";
import { ContactType } from "types/enum";
interface Prop {
   AddressHandeler: (e) => void,
  personId:string ,
  Current?:boolean ,
  onHide: () => void,
  status:'selectMode'|'createMode'|null,

}



export const AddressBox = ({ AddressHandeler ,Current=false,onHide ,status ,personId}: Prop) => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("NoDataForPreview");
  const [noAccessError,setNoAccessError]=useState(false)

 
  const setselecetrow = (e) => {
    setSelectedObj(e)


  }
  const sendTreeCode = (e) => {
    e.preventDefault()
    e.stopPropagation()

      if(selectedObj){
        let parentObj:any=null
        if(selectedObj.RootId!=null){
            data.map(item=>{
                if(item.Id ==selectedObj.RootId ){
                    parentObj = item
                }
            })

        }
        if(parentObj){
            AddressHandeler({...selectedObj , Title: parentObj.Title +","+selectedObj.Title })
        }else{
            AddressHandeler(selectedObj)

        }
      }else{
        Toast(trans("Msg_SelectRow"), "warning")
      }


  }

  const onSubmit = async(event)=>{
    setLoading(false)
    try{
    
    let data = {
      PersonId:personId,
      ContactType: ContactType.Address,
      Value:JSON.stringify({
        address: event.target.Address.value,
        plaque: event.target.Plaque.value,
        unit: event.target.Unit.value,
        PostalCode: event.target.PostalCode.value,
      }),
      Description:event.target.Description.value,
      ExtraDescription:event.target.ExtraDescription.value,
      IsDefault:event.target.IsDefault.checked,
    }
      await postAddressBox(data ,Current)
      setLoading(true)
      onHide()
      AddressHandeler({
          address: event.target.Address.value,
          plaque: event.target.Plaque.value,
          unit: event.target.Unit.value,
          PostalCode: event.target.PostalCode.value})
      } catch (error) {
                  
      }   
  }

  async function getAddressBoxAsync() {
    setLoading(false)
    try{

      const respons = await getAddressBox(personId ,Current)
      setData(respons.Data)
      setNoAccessError(false)
    }catch(err:any){
      console.log(err);
      
      if(err?.status===403){
        setNoAccessError(true)
      }else{
      setNoAccessError(false)

      }
      setMessage(err?.data?.Message)
    }
    setLoading(true)
  }


  

  useEffect(() => {
    setData(null)
    getAddressBoxAsync()
  }, [forceUpdate]);


  useEffect(() => {
    if(idCallback){
      setIdCallback(null)
    }
  }, [idCallback]);



    return (
     <>


        <div className="card" style={{height: "40vh",scrollbarWidth: "thin"}}>
            {
              loading ? (
                <>
                {status == 'createMode' && !noAccessError &&
                  <div className="card mb-4">
                    <form className="TypeSettingForm row card-body "  onSubmit={onSubmit}>
                      <Address  withMap={false} mode={'create'}/>
                      <div className="col-6">
                        <Textbox textboxName='Description' textboxType="textarea"  labelText={"Description"} mode={'create'}   />
                    </div>  
                    <div className="col-6">
                        <Textbox  textboxName='ExtraDescription' textboxType="textarea"  labelText={"ExtraDescription"} mode={'create'}   />
                    </div>  
                      <div className="col-md-4 col-sm-12">
                        <CheckableButton labelText='IsDefault' type="checkbox" inputName="IsDefault" />
                    </div> 
                      {status == 'createMode' &&
                      <div className="col-lg-12 mt-1 btns_">
                        <Button value="Save" btnType="submit"/>
                        <Button btnType="cancel" value="Cancel"   onClick={()=>onHide()}/>
                      </div>
                      }
                    </form>
                  </div >
                }
                
                
                {
                data ?
                <div>
                <div>
                      <DataGrid
                        parentName={"AddressBox"}
                        theadData={Object.keys(data[0])}
                        tbodyData={data}
                        minCellWidth={80}
                        selectedRowObjectCallback={setselecetrow}

                    />
                </div>
                </div>: <Message message={message} type={"error"} />


                }
                
                </> ): <Loading />
            }
        </div>
        {status == 'selectMode' && !noAccessError &&
            <div className="col-lg-12 mt-1 btns_">
              <Button value="select" btnType="submit" onClick={(e)=>{sendTreeCode(e)}}/>
              <Button btnType="cancel" value="Cancel"  onClick={()=>onHide()}/>
            </div>
        }


      </>
    )
}




