import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { deleteApiFeed, getFeedApiDictionaryApi, sendFeedApi } from "./api";

interface FeedApiFormPropsType {
  id?: any;
  mode?: any;
  onHide: () => void;
  reset: (ans: boolean) => void;
}

export const FeedApiForm = ({
  id,
  mode,
  onHide,
  reset,
}: FeedApiFormPropsType) => {
  let data, method;


  const [resetForm, setResetForm] = useState(false);
  const formRef = useRef<any>("");
  const buttonRef = useRef();
  const [loading, setLoading] = useState(false);
  const [apiList,setApiList]=useState([])
  const [selectedObj, setSelectedObj] = useState< any>(null);
  const [detailMode,setDetailMode]=useState('create')



  async function formSubmit(event) {
    event.preventDefault();

    let url = formRef.current.FeedApiUrl.value.includes("api/")
      ? "#" +
        formRef.current.Service.value +
        "#/" +
        formRef.current.FeedApiUrl.value
      : "#" +
        formRef.current.Service.value +
        "#api/" +
        formRef.current.FeedApiUrl.value;
    if (id && mode !== "create") {
      data = {
        Id: id,
        Title: formRef.current.Title.value,
        SystemKey: formRef.current.SystemKey.value,
        FeedApiUrl: url,
      };
      method = "put";
    } else {
      data = {
        Title: formRef.current.Title.value,
        SystemKey: formRef.current.SystemKey.value,
        FeedApiUrl: url,
      };
      method = "post";
    }
    try {
      await sendFeedApi(data, method);
      setResetForm(true);
      reset(true);
      onHide();
    } catch (error) {}
  }


  const getFeedApiDictionaryApiAsync=async()=>{
    setLoading(false)
    try{
       const res= await getFeedApiDictionaryApi('','')
       if(!!res.data.Data.length){
           setApiList(res.data.Data); 
       }
    }
    catch(error){

    }
    setLoading(true)
  }

  const selectedIdCallback = (obj, index) => {
    setSelectedObj(obj);
}
  function cancel() {
    onHide();
  }



  const handleDeleteItem=async()=>{
    try{
        await deleteApiFeed(selectedObj.Id)
        getFeedApiDictionaryApiAsync()

    }
    catch(error){}
  }

;

  useEffect(() => {
    if (mode === "create") {
      setResetForm(true);
    }
  }, [mode]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(()=>{
    getFeedApiDictionaryApiAsync()
  },[])

  return (
    <form className="" ref={formRef}>
      {loading ? (
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <Textbox
                    autoFocus={true}
                    textboxType="text"
                    textboxName="Title"
                    defaultValue={detailMode=='edit'?selectedObj?.Title:''}
                    resetForm={resetForm}
                    labelText="Title"
                    required={true}
                    mode={mode}
                  />
                </div>
                <div className="col-4">
                  <Textbox
                    textboxType="systemKey"
                    textboxName="SystemKey"
                    defaultValue={detailMode=='edit'?selectedObj?.SystemKey:''}
                    resetForm={resetForm}
                    labelText="SystemKey"
                    required={true}
                    mode={mode}
                  />
                </div>
                <div className="col-4">
                  <DictionarySelectBox
                    mode={mode}
                    revert={true}
                    value={detailMode=='edit'?selectedObj?.FeedApiUrl.split('#')[1]:''}
                    name="Service"
                    label="Service"
                    baseUrl={REPORT_URL}
                    endPoint={ENDPOINTSREPORT.ReportFeed.service}
                  />
                </div>
                <div className="col-4">
                  <Textbox
                    textboxType="text"
                    textboxName="FeedApiUrl"
                    placeholder="api/"
                    fixedLanguage={"en"}
                    defaultValue={detailMode=='edit'?selectedObj?.FeedApiUrl:''}
                    resetForm={resetForm}
                    labelText="FeedApiUrl"
                    required={true}
                    mode={mode}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4 pt-2">
                {mode !== "view" ? (
                  <>
                    <Button
                      ref={buttonRef}
                      onClick={formSubmit}
                      value="Save"
                      btnType="submit"
                    />
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={() => {
                        cancel();
                      }}
                    />
                  </>
                ) : (
                  <Button
                    btnType="primary"
                    value="Close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="mt-3">
             {
             !!apiList.length &&
             <DataGrid
                DoubleClick={(e) => {
                   setSelectedObj(e);
               
                }}
                parentName={"FeedParameters"}
                startIndex={0}
                theadData={Object.keys(apiList[0])}
                tbodyData={apiList}
                minCellWidth={80}
               selectedRowObjectCallback={selectedIdCallback}
                // handleEdit={(e) => {
                //   setSelectedObj(e);
                //   setDetailMode('edit')
                // }}
                handleDelete={()=>{handleDeleteItem()}}
              />}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </form>
  );
};
