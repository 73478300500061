import { Loading } from "components/Loading/loading";
import { useContext, useEffect, useRef, useState } from "react";
//import { Data } from "./components/data/data";
import { useLocation, useNavigate } from "react-router-dom";
import { GUID_EMPTY } from "utils/constants";
import { trans } from "utils/helpers";
import { getItemGroupLabels, getItemStoreListApi } from "./api";
import { ItemCards } from "./components/ItemCard/ItemCards";
import { ItemCardsHeader } from "./components/ItemCardsHeader/ItemCardsHeader";
import { ItemGroupLebal } from "./components/ItemGroupMenu/ItemGroupLabel";
import { FilterBox } from "../ECommerceDEMO/components/FilterBox/FilterBox";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";

interface Form {
  Title: string;
  Status: number;
}
export const ECommercePage = ({ match }) => {
  const [forceUpdate, setForceUpdate] = useState(false);

  const [response, setResponse] = useState<any>();
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [messageType, setMessageType] = useState<any>("info");
  const [itemGroupId, setItemGroupId] = useState<any>(GUID_EMPTY);
  const [dataGroup, setDataGroup] = useState<any>([]);
  const defaultCardStyle=localStorage.getItem('cardStyle')?JSON.parse(localStorage.getItem('cardStyle') as string):true
  const [cardStyle, setCardStyle] = useState<boolean>(defaultCardStyle);
  const [groupLabels, setGroupLabels] = useState<any>({});
  const [breadCrumb, setBreadCrumb] = useState<any>([
    { id: null, name: trans("ECommerce") },
  ]);
  const itemGroupIdRef = useRef<any>(GUID_EMPTY);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const itemGroup = queryParams.get("itemGroup");
  const [stopFetching, setStopFetching] = useState<any>(false);
  const [filterObj, setfilterObj] = useState<any>("");
  const { sidebarIsOpen,emptyBasket } = useContext(LayoutContext);
  const boxRef = useRef<any>(null);

  const navigate = useNavigate();
  async function getECommerceAsync(
    e = null,
    pagenum = 1,
    minPrice = null,
    maxPrice = null,
    size = 10,

  ) {
    if (pagenum <= 1) setLoading(false);
    try {
      const res = await getItemStoreListApi(
        e == GUID_EMPTY ? null : e,
        pagenum,
        minPrice,
        maxPrice,
        boxRef?.current ?Math.floor(boxRef?.current?.offsetWidth/208)*Math.floor((((75 / 100) * window.innerHeight)/340)+1):10
      );
      if (pagenum > 1) {
      }
      if (res.Data.PageNumber * res.Data.PageSize >= res.Data.TotalRecords) {
        setStopFetching(true);
      } else {
        setStopFetching(false);
      }
      if (
        res.Data.Data.length == 1 &&
        res.Data.Data[0].Id == GUID_EMPTY &&
        pagenum == 1
      ) {
        setResponse([]);
      } else {
        if (pagenum <= 1) {
          setResponse(res.Data.Data);
        } else {
          if (res.Data.Data[0].Id != GUID_EMPTY) {
            setResponse((pre) => {
              return [...pre, ...res.Data.Data];
            });
          }
        }
      }
      setMessageType("info");
    } catch (error) {
      setMessageType("error");
    }
    if (pagenum <= 1) setLoading(true);
  }

  function clickBreadCrumb(Bread) {
    setItemGroupId(Bread.id);
    itemGroupIdRef.current = Bread.id;
    let newBread: any = [];
    let flag = false;
    breadCrumb.map((item) => {
      if (!flag) {
        newBread.push(item);
      }
      if (item.id == Bread.id) {
        flag = true;
      }
    });
    setBreadCrumb(newBread);
  }

  const getDataAsync = async (id = null) => {
    try {
      const res = await getItemGroupLabels();

      if (id == null) {
        setGroupLabels(res.data);
        let content =
          res.Data &&
          Object.keys({ [GUID_EMPTY]: trans("all"), ...res.Data }).map(
            (item, index) => (
              <ItemGroupLebal
                key={index + "contentECommerce"}
                shape="FoursquareRadio"
                IsActive={itemGroupIdRef.current == item}
                onClick={(e) => {
                  e.stopPropagation();
                  setItemGroupId(item);
                  itemGroupIdRef.current = item;
                  setBreadCrumb([
                    { id: null, name: trans("ECommerce") },
                    {
                      id: item,
                      name: { [GUID_EMPTY]: trans("all"), ...res.Data }[item],
                    },
                  ]);
                }}
                title={{ [GUID_EMPTY]: trans("all"), ...res.Data }[item]}
              />
            )
          );
        setDataGroup(content);
      } else {
        //    const res2 = {Data:{
        //        "E36E1489-83FC-EE11-AB53-00155DAA1053":{
        //            "E36E1489-83FC-EE11-AB53-001552561053": "کلاه",
        //            "E36E1489-83FC-EE11-AB53-001589631053": "کالای چرم",
        //        },
        //        "084E4FDE-6D74-ED11-AB39-00155DAA1053":{
        //            "084E4FDE-6D74-ED11-AB39-0015596A1053": "برق صنعتی",
        //            "084E4FDE-6D74-ED11-8953-00155DAA1053": " برق خانگی",
        //        }
        //
        //    }}
        //    let content =(res2.Data[id]&& Object.keys(res2.Data[id]).map((item,index) =>(<ItemGroupLebal key={index+'contentECommerce'} shape="FoursquareRadio" IsActive={itemGroupId==item} onClick={(e)=>{e.stopPropagation()  ;setItemGroupId(item);setBreadCrumb([...breadCrumb , {id: item,name:res2.Data[id][item]}])}} title={res2.Data[id][item]}/>)))
        //   setDataGroup(content)
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (itemGroup) {
      setItemGroupId(itemGroup);
      itemGroupIdRef.current = itemGroup;
      navigate("/e-commerce/Items", { replace: true });
    } else {
      getECommerceAsync(itemGroupId,1,filterObj.min,filterObj.max);
      getDataAsync(null);
    }
  }, [itemGroupId, forceUpdate]);

  useEffect(()=>{
      emptyBasket && getECommerceAsync(itemGroupId,1,filterObj.min,filterObj.max);
  },[emptyBasket])

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  useEffect(() => {
    getDataAsync();
  }, []);
  useEffect(() => {
  }, [sidebarIsOpen]);

  useEffect(() => {
    if (itemGroupId == null) {
      setfilterObj({});
    }
  }, [itemGroupId]);

  /* #endregion */

  //filter by max and min price


  return (
    <>
      <div
        className="d-flex align-items-center w-100"
        style={{ zIndex: "5", flexDirection: "column" }}
      >
        <div
          className="w-100 m-1 d-flex mb-3"
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <div className=" col-8">
            {breadCrumb.map((breadCrumbsItem, index) => (
              <span
                key={index}
                onClick={() => {
                  clickBreadCrumb(breadCrumbsItem);
                }}
                style={{ cursor: "pointer" }}
              >
                {breadCrumbsItem.name}
                {index < breadCrumb.length - 1 ? (
                  <span key={index} className="bread-crumb__separator">
                    /
                  </span>
                ) : null}
              </span>
            ))}
          </div>
          <ItemCardsHeader
            data={response}
            cardStyle={cardStyle}
            handleModeCard={() => {
              setCardStyle(!cardStyle)
              localStorage.setItem('cardStyle',JSON.stringify(!cardStyle))
              ;
            }}
          />
        </div>
      </div>
      <div className="card-group">
        {/* <ItemGroupCards /> */}

        <div className="col-lg-2 d-lg-block d-none ">
          <div style={{ width: `${sidebarIsOpen ? "13vw" : "15.8vw"}` }}>
            <FilterBox
            filterObj={filterObj}
              setfilterObj={(e) => {
                setfilterObj({ ...filterObj, ...e });
              }}
              filterData={{ type: 1, title: "بر اساس قیمت" }}
              handleFilter={()=>{
                if (filterObj.min != null || filterObj.max != null) {
                  getECommerceAsync(itemGroupId, 1, filterObj.min, filterObj.max );
                }
                
              }}
              handleRemoveFilter={()=>{
              
                  getECommerceAsync(itemGroupId, 1,null , null );
                
              }}
            />
            {itemGroupId == "E36E1489-83FC-EE11-AB53-00155DAA1053" && (
              <>
                <FilterBox
                  setfilterObj={(e) => {
                    setfilterObj({ ...filterObj, ...e });
                  }}
                  filterData={{
                    type: 2,
                    data: ["طبیعی", "مصنوعی"],
                    title: "بر اساس جنس چرم",
                  }}
                />
                <FilterBox
                  setfilterObj={(e) => {
                    if (filterObj.option2) {
                      setfilterObj({ ...filterObj, ...{ option2: null } });
                    } else {
                      setfilterObj({ ...filterObj, ...e });
                    }
                  }}
                  filterData={{
                    type: 3,
                    data: ["ورزش"],
                    title: "بر اساس کاربرد ",
                  }}
                />
              </>
            )}
            {itemGroupId == "084E4FDE-6D74-ED11-AB39-00155DAA1053" && (
              <>
                <FilterBox
                  setfilterObj={(e) => {
                    setfilterObj({ ...filterObj, ...e });
                  }}
                  filterData={{
                    type: 2,
                    data: ["100-240V AC", "24-220V DC"],
                    title: "بر اساس ولتاژ کاری",
                  }}
                />
                <FilterBox
                  setfilterObj={(e) => {
                    if (filterObj.option2) {
                      setfilterObj({ ...filterObj, ...{ option2: null } });
                    } else {
                      setfilterObj({ ...filterObj, ...e });
                    }
                  }}
                  filterData={{
                    type: 3,
                    data: ["آب بندی شده"],
                    title: "بر اساس آب بندی ",
                  }}
                />
              </>
            )}
          </div>
        </div>

        <div className="col-lg-10 col-12 item-card-container" ref={boxRef} >
        {loading ? (
            <ItemCards
              loading={loading}
              stopFetching={stopFetching}
              fetchData={(e, minPrice, maxPrice) => {
                if (e != 1 || (maxPrice != null && minPrice != null))
                  getECommerceAsync(itemGroupId, e, filterObj.min, filterObj.max );
              }}
              itemGroupId={itemGroupId}
              filterId={itemGroupId}
              cardStyle={cardStyle}
              dataGroup={dataGroup}
              data={response}
            />
          ) : (
            <Loading />
          )}
          </div>
      </div>
    </>
  );
};
