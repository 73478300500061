import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const sendWarehouseIO = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.InventoryReceipt,
    method: "post",
    headers: { "Accept-Language": "en" },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const putWarehouseIO = (data) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.browse,
    method: "put",
    headers: { "Accept-Language": "en" },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getWarehouses = (
  pageNumber: number,
  pageSize: number,
  searchString,
  orderBy,
  filterlist,
  currentWarehouse
) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.browse,
    method: "get",
    params: {
      PageNumber: pageNumber,
      PageSize: pageSize,
      SearchString: searchString,
      OrderByString: orderBy,
      FilterString: filterlist,
      warehouseId: currentWarehouse,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getWarehouseIoId = (id) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.load,
    method: "get",
    headers: { "Accept-Language": "en" },
    params: {
      id,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
export const constraintWarehouseIo = (warehouseIOType) =>
  http({
    baseURL: INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.constraint,
    method: "get",
    headers: { "Accept-Language": "en" },
    params:{
      warehouseIOType
    }
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

    export const getWarehouseIoNo = (docsClassificationId,id=null) =>
      http({
        baseURL: INVENTORYANDSALES_URL,
        url: ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis.nextNo,
        method: "get",
        headers: { "Accept-Language": "en" },
        params:{
          docsClassificationId,
          id
        }
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    