import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getFeedMethodsDictionaryApi } from "./api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { ICON_TYPE } from "utils/constants";
import { ModalForm } from "components/ModalForm/ModalForm";
import { FeedMethodsForm } from "./FeedMethodsForm";
interface FeedMethodsPropsType {

  resetForm?: boolean,
  required?: boolean,
  returnObj?: boolean,
  disable?: boolean,
  value?: string,
  mode?: string,
  FeedMethodList?: any,
  name: string,
  FeedMethodsListCallback?: (e) => void,

}

export const FeedMethods = ({  value,mode,returnObj=false,FeedMethodList,disable ,resetForm,required , name="" ,FeedMethodsListCallback}: FeedMethodsPropsType) => {
  const [FeedMethods, setFeedMethodss] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [showFeedMethodsForm , setShowFeedMethodsForm] = useState(false);

  const [rquird , setRquird] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const [disabled, setDisabled] = useState(mode == "view" ? true : false);
  const [selectedItem, setSelectedItem] = useState<any>([]);

  const countRef = useRef<any>(0)


  async function getFeedMethodsListAsync(search?, id?) {
    try {
      const res = await getFeedMethodsDictionaryApi(search, id);
      setFeedMethodss(res.data.Data)

      setShow(true)
      setFlag(!flag)
    } catch (err: any) {
      
    }
  }

  const resetData = (e) => {
    setValidat(false)
    setRquird(false)
    setShow(true)
    inputRef.current.value = null
    inputIdRef.current.value = null
    getFeedMethodsListAsync(null, "")
    setFlag(!flag)
  }
  const Auto = (e) => {
    setValidat(false)
    setRquird(false)
    setShow(true)
    getFeedMethodsListAsync(e.target.value, "")
    setFlag(!flag)
  }

  function userSelecter(){
   // setValeu(options_PostLevel_values[countRef.current],options_PostLevel_keys[countRef.current])

    setShow(false)
  }
  function addNewFeedMethods(e) {
    e.stopPropagation()
    e.preventDefault()
    setShowFeedMethodsForm(true)


}


  const kydown = (event) => {  

    if((event.key === "ArrowDown")){
      if(countRef.current < FeedMethods.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = FeedMethods.length-1;
      }
    }
    document.getElementById("FeedMethodsIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
    setCount(countRef.current)
    if(event.key === "Enter"){
      userSelecter()
    }
  }

  const CheckFeedMethods =async (e)=>{
    setTimeout(async() => {
      if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target.value==""){
        inputIdRef.current.value = null
        if(required)
        setRquird(true)
    }else{
      setRquird(false)
      try{
        const res = await getFeedMethodsDictionaryApi(e.target.value,"")
        if(Object.keys(res).length==0){
          setValidat(true)
        }
        if(Object.keys(res).length == 1){
          setValeu(Object.values(res)[0],  Object.keys(res)[0]   )
        }
      }catch(err:any){
        setValidat(true)
      }
    }
    }, 200);
    await delay(200)
    // setShow(false)
  }

  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));





  const setValeu = (item, id) => {
    if(inputIdRef != null && inputIdRef.current != null){

      setRquird(false)
      inputRef.current.value = item.ClassName
      inputIdRef.current.value = id
      setFeedMethodss(null)

 

      setFlag(!flag)
    }
  }

  
  useEffect(() => {
    if (value) {
      getFeedMethodsListAsync(null, value)
    }
  }, [value ,resetForm]);

  
  useEffect(() => {
      setDisabled(mode == "view" ? true : false)
    
  }, [mode]);
  useEffect(() => {
    if(FeedMethodList){

      setSelectedItem(FeedMethodList)
    }
    
  }, [FeedMethodList]);



  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) {
      setShow(false);
    }
  }
  async function deleteaOption(obj , index) {
      let list = selectedItem
      list.splice(index,1)
      FeedMethodsListCallback&&  FeedMethodsListCallback(list)
      setSelectedItem(list)
    
}

  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Name = inputRef.current.value 
    let Id = inputIdRef.current.value

    if(FeedMethodsListCallback){
      let selectList = JSON.parse(JSON.stringify(selectedItem))
      FeedMethodsListCallback([...selectList, {Id:Id , Name:Name}])
      setSelectedItem(selectList.concat({Id:Id , Name:Name}))
    }else{ }
}

  useEffect(() =>{
    if(show){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
    return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ])


  return (
    <>
      <ModalForm mode={mode} title="FeedMethods"  onHide={() => setShowFeedMethodsForm(false)} show={showFeedMethodsForm}>
        <FeedMethodsForm 
         mode={"create"}
         onHide={()=>{setShowFeedMethodsForm(false)}}
         reset={resetData}
        />
      </ModalForm>

      <div className="text-box">
        <label htmlFor="browser" className="text-box__label">{trans("FeedMethods")}</label>
        {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
        <input required={required} defaultValue={value}   name={name} className="d-none" ref={inputIdRef} />
        <>
        <form style={{display:"flex" , alignItems:'end' ,flexDirection:"row",width:"100%"}}  >
              <div style={{width:"100%"}} >
               <input required={required}  autoFocus={valueName} defaultValue={valueName}  onBlur={CheckFeedMethods} autoComplete="off" onMouseDown={(e) => {e.stopPropagation(); Auto(e) }} onFocus={() => {getFeedMethodsListAsync( valueName); setShow(true) }} ref={inputRef} list="FeedMethodsList" name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} onChange={Auto} disabled={disable ?disable: disabled} />
              {
                show &&
                <div className={`text-box__sub-menu mt-1 glassBox border border-1`} tabIndex={-1} >    
                  {FeedMethods&&FeedMethods.map((item, index) => {
                    return(
                      <div id={"FeedMethodsIndex"+index} className={`border-bottom  ${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={(e) => { setValeu(item, item.Id);addOption(e) }} key={item.Id} >
                        <div id={"PositionIndex"+index} className={`font-size-md `} >{item.ClassName}</div>
                        <div className="mt-1 font-size-sm text-muted">{item.MethodName}</div>
                      </div>
                    )
                  })}
                </div>
                 }                
              </div>
              {!disable&&
              <>
                <div  style={{marginTop:"4px" , width:"6%"}}>
                  <Button Icon={true}  value={<button onClick={addOption} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}    size="2x"  /></button>} btnType="submit"  />
                </div>
                <div  style={{marginTop:"4px" , width:"10%"}}>
                  <Button Icon={true}  value={<button onClick={addNewFeedMethods} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "plus"]}    size="2x"  /></button>} btnType="primary"  />
                </div>
                </>
              }
             </form>
             <div className="group-person__selected-options-wrapper" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(100px,100%))'}}>
              { selectedItem.map((item, index) => (
            <div className="group-person__selected-option form-control-custom" key={item.Id + index}>
                <div className="group-person__defult-selected-option" data-key={item.Id}>
                   
                </div>
                <div className="showDots">
                 {trans(item.Name) } 
                </div>
                
                <div className="group-person__delete-selected-option" data-key={item.Id} onClick={()=>{deleteaOption(item, index)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>      

        </>
      </div>
    </>
  )
}