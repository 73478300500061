import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { GeneralRequestDTO } from "pages/OfficeAutomation/Cartable/types";
import { useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getGeneralRequest, getGeneralRequestByReferenceId, getProcessId, postGeneralRequest, putGeneralRequest } from "./api";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";
import { MessageType } from "types/enum";

interface GeneralRequestFormPropsType {
  onHide: (usenavigate?) => void;
  handleCancelForm: (ans: boolean) => void;
  handleShowReferenceStepper?: (referenceId: string) => void;
  referenceId?: string;
  processId?: string;
  Description?: string;
  Id?: string;
  mode;
}
export const GeneralRequestForm = ({
  handleCancelForm,
  referenceId,
  processId,
  Id,
  Description,
  mode,
  onHide,
  handleShowReferenceStepper,
}: GeneralRequestFormPropsType) => {
  let GeneralRequestDTO: GeneralRequestDTO | any;

  const [showReferenceStepper, setShowReferenceStepper] = useState<boolean>(false);

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const layoutContext = useContext(LayoutContext);
  const [response, setResponse] = useState<any>();

  const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);


  async function getGeneralRequestAsync() {
    setLoading(false);
    try {
      
        if(Id){
          const res = await getGeneralRequest(Id );
          setResponse(res);
          GeneralRequestDTO = res.Data;

        }else if(referenceId){

          const res = await getGeneralRequestByReferenceId(referenceId);
          setResponse(res);
          GeneralRequestDTO = res.Data;
        }
      

      
    } catch (error){ }
    setLoading(true);
  }


  useEffect(() => {
    if(mode != "create" || (Id&&Id?.length>0)){
      if ((referenceId||Id) && !showReferenceStepper) getGeneralRequestAsync();

    }
  }, [referenceId ,Id]);

  const handleGeneralRequestFormSubmit = (event) => {
    event.preventDefault();
        if (mode == "edit") {
      let data: GeneralRequestDTO = {
        Id: response.Data.Id,
        Description: event.target.Description.value,
        RequesterPositionId: event.target.RequesterPosition.value,
        Applicant: event.target.applicant.value,
        GeneralRequestClassificationId: event.target.GeneralRequestClassification.value,
      };
      sendDataAsync(data, "put"  );
    } else {
      let data: GeneralRequestDTO = {
        Description: event.target.Description.value,
        RequesterPositionId: event.target.RequesterPosition.value,
        Applicant: event.target.applicant.value,
        GeneralRequestClassificationId: event.target.GeneralRequestClassification.value,

       
      };
      sendDataAsync(data, "post" );
    }
  };


  async function sendDataAsync(data: GeneralRequestDTO, method ) {
    try {
      let res;
      if (method === "post"){
        if(processId == null){
          const prId = await getProcessId("GeneralRequestForm")
          res = await postGeneralRequest(data, true  , prId.Data);
        }else{

          res = await postGeneralRequest(data, true  , processId);
        }


      }else res = await putGeneralRequest(data ,referenceId);
      if (showReferenceStepper) {
        handleShowReferenceStepper && handleShowReferenceStepper(!(res.MessageType ==MessageType.success) ? false: method === "post" ?  res.Data : referenceId);
      } else {
        handleCancelForm(false);
        onHide(true);
      }
    } catch (err: any) { }
  }
  if (response  ) {
      GeneralRequestDTO  = {GeneralRequest : response.Data};
  }
 

  return (

    <>

    
    
    
    <div className={``}>

      <div className="list-card card  ">
      
        <div className="list-card__body">
          {loading ? (
            <form action="" onSubmit={handleGeneralRequestFormSubmit} className={"p-2 "+mode}>
              <div className="row">
                <div className="col-12 col-sm-4  mb-sm-0">
                  {
                    <Position
                      name="RequesterPosition"
                      Change={setUserMadeChanges}
                      required={true}
                      label="asker"
                      resetForm={resetForm}
                      value={
                        GeneralRequestDTO?.GeneralRequest
                          ? GeneralRequestDTO?.GeneralRequest.RequesterPositionId
                          : layoutContext.currentUserPosition.PositionId
                      }
                      mode={"view"}

                    />
                  }
                </div>
                <div className="col-12 col-sm-4  mb-sm-0">
                  <Textbox
                    Change={setUserMadeChanges}
                    textboxType="text"
                    resetForm={resetForm}
                    textboxName="applicant"
                    labelText="applicant"
                    defaultValue={GeneralRequestDTO?.GeneralRequest?.Applicant}
                    mode={mode}
                  />
                </div>
                <div className="col-12 col-sm-4  mb-sm-0">
                  <DictionarySelectBox
                    endPoint={ENDPOINTS.cartable.GeneralRequestClassification.dictionary}
                    Change={setUserMadeChanges}
                    label="jobClassification"
                    name="GeneralRequestClassification"
                    value={GeneralRequestDTO?.GeneralRequest?.GeneralRequestClassificationId}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Textbox
                    required={true}
                    minHeight={mode == "view" ? 3 : 4}
                    Change={setUserMadeChanges}
                    textboxType="textarea"
                    labelText="Description"
                    resetForm={resetForm}
                    textboxName="Description"
                    defaultValue={Description ? Description : GeneralRequestDTO?.GeneralRequest?.Description}
                    mode={mode}
                  />
                </div>
              </div>
              {mode != "view" ? (
                <div className="d-flex justify-content-end">
                  {!Description &&  
                  <Button btnType="submit" value="save" />
                  }
                  {handleShowReferenceStepper && (
                    <Button
                      btnType="submit"
                      value="SaveAndReference"
                      onClick={() => setShowReferenceStepper(true)}
                    />
                  )}
                  <Button
                    btnType="cancel"
                    value="cancel"
                    onClick={() => {
                      userMadeChanges ? Toast(trans("modalclosemessage"), "warning", "alert", onHide)
                        : onHide()
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </form>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
    
    </>
  );
};
