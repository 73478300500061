import { SelectBox } from "components/Select/Select";
import { CheckableButtonList } from "components/CheckableButtonList/CheckableButtonList";
import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { Enumeration } from "types/types";
import { getEnumerationTypeSelectApi } from "./api"
import { InputLoad } from "components/InputLoading/inputLoading";
import { setDefaultPersonSignatureApi } from "pages/OfficeAutomation/BaseInformation/PersonSignature/api";

interface EnumerationTypeSelectProp extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  value?: any;
  mode?: any;
  enumTypeSelect?:string;
  Change?: (ans: any) => void,
  onChange?:(data:any)=>void,
  required?: boolean,
  recordedData?:any
}
export const EnumerationTypeSelect = React.memo(({enumTypeSelect, label, name="", value,onChange,Change ,mode, required ,recordedData}: EnumerationTypeSelectProp) => {
  const [optionsEnumerationTypeSelect, setEnumerationTypeSelect] = useState<Enumeration>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errcode, setErrcode] = useState<any>();



  const getEnumerationTypeSelectAsync = async ()=>{
    setLoading(false)
    try {
      const res = await getEnumerationTypeSelectApi(enumTypeSelect)
      setEnumerationTypeSelect(res?.Data)
      
      console.log(Object.keys(res?.Data)[0],'test');
      onChange&&   onChange(Object.keys(res?.Data)[0] )
    } catch (error:any) {
      setErrcode(error.response?.status)

    }
    setLoading(true)
  }

  const onChangeHandler = (e) =>{
    if(onChange){onChange(e.target.value)};

    if(e.target.value != (value?value:Object.keys(optionsEnumerationTypeSelect as Enumeration)[0]) ){
      Change&&Change({[name] :e.target?.value})
    }else{
      Change&&Change({[name] :null}) 
    }
  }
  

  useEffect(() => {
    getEnumerationTypeSelectAsync()
    return () => {
     
      setErrcode("")
    };
  }, []);





  return (
    <>
    
        {optionsEnumerationTypeSelect && loading ?
            <SelectBox  
            onChange={(e)=>{onChangeHandler(e);}}
            lable={label}
            required={required}        
            selectType="select"
            value={value?value:Object.keys(optionsEnumerationTypeSelect as Enumeration)[0]}
            options={Object.values(optionsEnumerationTypeSelect as Enumeration)}
            val={Object.keys(optionsEnumerationTypeSelect as Enumeration)}
            name={name}
            mode={mode}
            useTrans={true}
            recordedData={recordedData}
          ></SelectBox>
       :<InputLoad  msg={errcode} required={required}   labelText={label }  onClick={getEnumerationTypeSelectAsync}/> 
      }
    </>
  );
});
