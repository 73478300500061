import { Textbox } from "components/Textbox/Textbox"
import { Button } from 'components/Button/Button';
import { useEffect, useState } from "react";
import { Response } from "types/types";
import { Loading } from "components/Loading/loading";
import { ProvinceDictionary } from "../Province/components/ProvinceDictionary"
import { getCity , postCityApi, putCityApi} from "./api";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
interface Prop {
    resetForm?: boolean,
    onHide: () => void,
    selectedObj?: any,
    mode:string,
    reload:()=>void,
}
interface Form{
    Name:string,
    Code:string,
}
export const CityForm = ({ reload,onHide, mode ,selectedObj}: Prop) => {
    const [resetForm, setResetForm] = useState(false);
    const [response, setResponse] = useState<Response | any>();
    const [userChanges, setUserChanges] = useState({});
    const [loading,setLoading] = useState<any>()

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [userChanges]);

    async function getCityId() {
        setLoading(false)
        try {
            const data = await getCity(selectedObj.Id);
            setResponse(data.Data);

        } catch (error) {
            
        }
        setLoading(true)
    }

    const submit = async(e) => {
        e.preventDefault();
        try {
            if (response == null) {
               await postCityApi({IsDefault: e.target.checkbox.checked, Name: e.target.Name.value ,Code :e.target.Code.value ,ProvinceId:e.target.Province.value }).then(() => {sessionStorage.setItem("changingforms" , "false");setUserChanges({});reload();onHide() });
            } else {
               await putCityApi({IsDefault: e.target.checkbox.checked, Name: e.target.Name.value ,Code :e.target.Code.value ,ProvinceId:e.target.Province.value ,Id: selectedObj.Id }).then(() => { sessionStorage.setItem("changingforms" , "false"); setResponse(null);setUserChanges({});reload();onHide() })
            }
            
        } catch (error) {
            
        }
        
    }


    useEffect(() => {
        if(selectedObj && mode != "create"){
        getCityId()
        }else{
            setResponse(null)
            setLoading(true)
        }
    }, [selectedObj ,mode])
    
    
    useEffect(()=>{
        if(resetForm){
            setUserChanges({})
            setResponse(null)

        }
    },[resetForm])



    return (
        <>    
            <div className="card mb-4">
        {loading ?                        
                    <form className="TypeSettingForm row card-body " onSubmit={submit}>
                    <div className="col-md-4 col-sm-12">
                        <ProvinceDictionary Change={(e)=>{setUserChanges({...userChanges , ...e})}} label={"Province"} resetForm={resetForm}  name={"Province"} ProvinceName={selectedObj?.Province} mode={mode} required={true} value={response?.ProvinceId }/>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"Name"} textboxType="text" resetForm={resetForm} labelText={"Name"} mode={mode}  defaultValue={response?.Name} />
                    </div>  
                    <div className="col-md-4 col-sm-12">
                        <Textbox Change={(e)=>{setUserChanges({...userChanges , ...e})}} required={true} textboxName={"Code"} textboxType="text" resetForm={resetForm} labelText={"Code"} mode={mode}  defaultValue={response?.Code} />
                    </div> 
                    <div className="col-lg-2 mt-4 mb-4" >
                    <CheckableButton
                       Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                       defaultChecked={response?.IsDefault}
                       defaultValue={response?.IsDefault}
                       labelText={"isdefault"}
                       inputName={"checkbox"}
                       type="checkbox"
                       mode={mode}
                    />                                
                    </div>   
             
                    {  mode !="view" ?
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button btnType="cancel" value="Cancel" onClick={onHide} />
                          </div>
                          :
                          <div className="col-lg-12 mt-1 btns_form">
                          <Button btnType="primary" value="Close" onClick={onHide}/>
                          </div>
                      }                        
                </form>

                :
                <Loading/>
                }         
            </div >   
        </>
    )
}