import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getItemGroupRoot, sendItemGroupRoot } from "./api";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ItemGroupRootType {
  mode?: any;
  checkData?: any;
  showForm: (e: boolean) => void;

  selectedObj: any;
}

export const ItemGroupRoot = ({
  mode,
  selectedObj,
  checkData,
  showForm,
}: ItemGroupRootType) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [canPaste, setCanPaste] = useState(false);
  const [descriptions, setDescriptions] = useState<any>({});

  const sendItemGroupAsync = async (method, data) => {
    try {
      const res = await sendItemGroupRoot(data, method);

      showForm(true);
    } catch (error) {}
  };
  const pasteHandler = async () => {
    const text = JSON.parse(await navigator.clipboard.readText());
    setResponse(text);
    try {
    } catch (error) {}
  };

  async function formSubmit(event) {
    event.preventDefault();
    if (mode == "create") {
      let data = {
        Code: event.target?.Code?.value,
        "Description": checkData.Description ,
        "DescriptionEN":checkData.DescriptionEN,
        SummaryDescription: event.target.SummaryDescription.value,
        SummaryDescriptionEN: event.target.SummaryDescriptionEN.value,
      };
      sendItemGroupAsync("post", data);
    } else {
      let data = {
        Id: response.Id,
        Code: response.Code,
        "Description": checkData.Description ,
        "DescriptionEN":checkData.DescriptionEN,
        SummaryDescription: event.target.SummaryDescription.value,
        SummaryDescriptionEN: event.target.SummaryDescriptionEN.value,
      };
      sendItemGroupAsync("put", data);
    }
  }

  async function getDataAsync() {
    setLoading(false);
    try {
      const res = await getItemGroupRoot(selectedObj.Id);
      setResponse(res.Data);
    } catch (error) {}
    setLoading(true);
  }
  async function checkPaste() {
    try {
      let data = await navigator.clipboard.readText();
      if (data && JSON.parse(data)) {
        setCanPaste(true);
      }
    } catch (error) {
      setCanPaste(false);
    }
  }

  useEffect(() => {
    if (selectedObj?.Id && mode != "create") {
      getDataAsync();
    } else {
      setLoading(true);
    }
    if (mode != "view") checkPaste();
  }, [selectedObj]);

  return (
    <form
      className="Main-form py-2"
      id={"ItemForm"}
      name={"main"}
      onSubmit={formSubmit}
    >
      <div className="card">
        {(mode != "create" && response) || mode == "create" ? (
          <div className="card-body">
            <div className="row">
              {canPaste && mode != "view" && (
                <div
                  onClick={() => {
                    pasteHandler();
                  }}
                  className="col-2  btn btn-primary btn-sm d-flex align-items-center justify-content-between "
                  style={{
                    width: "89px",
                    maxHeight: "30px",
                    marginRight: "20px",
                    position: "absolute",
                    left: "0",
                  }}
                >
                  <span className=" font-size-md ">{trans("paste")}</span>
                  <FontAwesomeIcon
                    size="xs"
                    icon={["fas", "paste"]}
                    className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"
                    color="white"
                  />
                </div>
              )}

              <div className="col-3">
                <Textbox
                  required
                  textboxType="number"
                  textboxName="Code"
                  UsepadWithZeros={true}
                  maxLength={checkData.CodeLength}
                  defaultValue={
                    response?.Code
                      ? response?.Code.replace(checkData?.ParentCode, "")
                      : checkData?.Code
                  }
                  resetForm={resetForm}
                  labelText={
                    trans("itemcode") +
                    (mode !== "create"
                      ? ""
                      : `  (${"طول مجاز"} : ${checkData.CodeLength})`)
                  }
                  mode={mode == "edit" ? "view" : mode}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <Textbox
                  required
                  onBlurHandler={(e) => {
                    setDescriptions({ ...descriptions, summaryDescription: e });
                  }}
                  textboxType="text"
                  textboxName="SummaryDescription"
                  resetForm={resetForm}
                  defaultValue={
                    response?.SummaryDescription
                      ? response.SummaryDescription
                      : null
                  }
                  labelText="ItemGroupSummaryDescription"
                  mode={mode}
                />
              </div>
{/*               <div className="col-4">
                <Textbox
                  required
                  onBlurHandler={(e) => {
                    setDescriptions({ ...descriptions, Description: e });
                  }}
                  textboxType="text"
                  textboxName="Description"
                  resetForm={resetForm}
                  defaultValue={
                    response?.Description
                      ? response?.Description
                      : descriptions.Description
                      ? descriptions.Description
                      : descriptions.summaryDescription
                  }
                  labelText={"ItemGroupDescription"}
                  mode={mode}
                />
              </div> */}
{/*             </div>
            <div className="row"> */}
              <div className="col-3">
                <Textbox
                  onBlurHandler={(e) => {
                    setDescriptions({
                      ...descriptions,
                      SummaryDescriptionEN: e,
                    });
                  }}
                  textboxType="text"
                  textboxName="SummaryDescriptionEN"
                  resetForm={resetForm}
                  defaultValue={
                    response?.SummaryDescriptionEN
                      ? response.SummaryDescriptionEN
                      : null
                  }
                  labelText="ItemGroupSummaryDescriptionEN"
                  mode={mode}
                />
              </div>
{/*               <div className="col-4">
                <Textbox
                  onBlurHandler={(e) => {
                    setDescriptions({ ...descriptions, DescriptionEN: e });
                  }}
                  textboxType="text"
                  textboxName="DescriptionEN"
                  resetForm={resetForm}
                  defaultValue={
                    response?.DescriptionEN
                      ? response?.DescriptionEN
                      : descriptions.DescriptionEN
                      ? descriptions.DescriptionEN
                      : descriptions.SummaryDescriptionEN
                  }
                  labelText="ItemGroupDescriptionEN"
                  mode={mode}
                />
              </div> */}
            </div>

            <div className="d-flex justify-content-end mt-2">
              {mode !== "view" ? (
                <>
                  <Button value="Save" btnType="submit" />
                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      showForm(true);
                    }}
                  />
                </>
              ) : (
                <Button
                  btnType="primary"
                  value="Close"
                  onClick={() => {
                    showForm(true);
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </form>
  );
};
