import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";
import { getPageSize, trans } from "utils/helpers";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { getAllItemMainPropertyValues } from "./api";

interface Form {
    onHide: () => void;
    PropertiesListcallback: (list) => void;

    ItemMainPropertyId?:any;

}
export const ItemMainPropertyValuesList = ({ onHide ,PropertiesListcallback ,ItemMainPropertyId}: Form) => {


    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filterlist, setFilterlist] = useState<any>("");
    const [pages, setPages] = useState<number>(0);
    const [userChanges, setUserChanges] = useState({});
    const [selectList , setSelectList] = useState<any>()
    const [resetMultiSelect , setResetMultiSelect] = useState<any>(false)



    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("ItemMainPropertyValuesList" ,e.currentTarget.value )
        getAllItemMainPropertyValuesAsync(1)
      };


      function handleGoPage(value): void {
        if (value) {
            if (value < 1 || value > pages) { }
            else {setPageNum(+value);getAllItemMainPropertyValuesAsync(+value)}
        } else {
        }
    }
    
    const handleNextPage = () => {
        if(pageNum < pages){
            setPageNum(pageNum + 1);
            getAllItemMainPropertyValuesAsync(pageNum + 1);
          };
    };
    const handlePrevPage = () => {
        if(pageNum > 1){
            setPageNum((pageNum)=>{return +pageNum - 1});
            getAllItemMainPropertyValuesAsync(pageNum - 1);
          };
    };
    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj)         
       
    }

    async function getAllItemMainPropertyValuesAsync(PageNum =pageNum) {
        setLoading(false)
        let pageSizeChangedByUser = getPageSize("ItemMainPropertyValuesList" )
        setPageSize(pageSizeChangedByUser)
        try {
            const res = await getAllItemMainPropertyValues(PageNum,  pageSizeChangedByUser  ,search ,orderBy.length == 0 ?null:  orderBy.join(",") ,filterlist,ItemMainPropertyId);
            setResponse(res.Data.Data);
            setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) 
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }


    const selectListCallBack =(list)=>{
          
        setSelectList(list[1])
      }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };

      const makePropertiesList = (event) => {
          event.preventDefault();    
          PropertiesListcallback(selectList)
          onHide()
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setUserChanges({});
                onHide()
            });
        }else{
            onHide()    
        };

    }

    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){

            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {
        getAllItemMainPropertyValuesAsync();
    }, [forceUpdate ,search , orderBy , filterlist , pageNum]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
            getAllItemMainPropertyValuesAsync();
        }
    }, [resetForm]);





    return (
        <>
            <div className="cartable-action">
                <div className="card" style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}}>
                    <div className="">
                            <>
                                {loading ?
                                    response?.length > 0 ?
                                        <>
                                            <DataGrid
                                                 selectItem={(e) => {   }}
                                                parentName={"ItemMainPropertyValuesList"}
                                                handlefiltering={(str)=>{setFilterlist(str)  }}
                                                filterStr={filterlist}
                                                orderFormat={setOrderBy}
                                                orderFormatList={orderBy}
                                                multiSelect={mode!="view"}
                                                selectList={selectListCallBack}
                                                resetMultiSelect={resetMultiSelect}
                                                startIndex={1}
                                                theadData={Object.keys(response[0])}
                                                tbodyData={response}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedIdCallback}
                                                totalRecords={response.TotalRecords}
                                                pageNum={pageNum}
                                                pageSize={pageSize}
                                                HandleNextPage={handleNextPage}
                                                HandlePrevPage={handlePrevPage}
                                                // handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                                handlechangePageSize={handleChangePageSize}
                                                first={() =>{getAllItemMainPropertyValuesAsync(pages); setPageNum(pages)}}
                                                end={() =>{getAllItemMainPropertyValuesAsync(1); setPageNum(1)}}
                                                handleGoPage={handleGoPage}
                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                        :<Loading/>
                                }
                                        {  mode !="view"?
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button onClick={makePropertiesList} value="Save" btnType="submit" />
                                              <Button btnType="cancel" value="Cancel" onClick={cancel} />
                                              </div>
                                              :
                                              <div className="col-lg-12 mt-1 btns_form">
                                              <Button btnType="primary" value="close" onClick={cancel} />
                                              </div>

                                          }   
                            </>
                    </div>
                </div>
            </div>
        </>

    );
}