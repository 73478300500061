import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { deletetemplates, getTemplates, postTemplates, putTemplates } from "./api";


interface ReportTemplatesProps {
  ReportTemplatesListCallback: (e) => void;
  parentMode?: any;
  templates?: any;
  curentTab?: any;
  reportMainInfo?: any;
  handleUserChanges?:any
}
export const ReportTemplates = ({
  parentMode,
  reportMainInfo,
  curentTab,
  templates=[],
  ReportTemplatesListCallback,
  handleUserChanges
}: ReportTemplatesProps) => {
  const [response, setResponse] = useState<Response | any>(templates);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("create");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [editForm, setEditForm] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [showForm, setShowForm] = useState(false);

  const handleCreateFeedParameter = () => {
    setEditForm(null);
    setMode("create");
    setShowForm(true);
  };



  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    if(response.length > 1){
      let newData = templates.find((e) => e.Id == id);
      if(newData){
        try {
            const res = await deletetemplates(newData.Id)
            setSelectedObj(null);
            getTemplatesAsync()
            setForceUpdate(!forceUpdate);
        } catch (error) {
            
        }
      }else{
        let list = response.filter((e) => e.Id != id);
        setResponse(list);
        setSelectedObj(null);
        setForceUpdate(!forceUpdate);
        handleUserChanges()
      }
    }else{
      Toast("msg_validation_valueisrequired", "error")
    }
  };
  const getTemplatesAsync = async () => {
      try {
          const res = await getTemplates(reportMainInfo?.ReportId)
          setResponse(res.Data)
      } catch (error) {
          
      }

   
  };
  const handleViewRow = () => {
    setMode("view");
    setShowForm(true);
    setEditForm(selectedObj);
  };
  const handleEditRow = (e) => {
    setMode("edit");
    setShowForm(true);
    setEditForm(selectedObj);
  };

  const selectedIdCallback = (obj, index) => {
    if (showForm && mode != "create") {
      setEditForm(obj);
    }
    setSelectedObj(obj);
  };

  const submit = async (e) => {
    e.preventDefault();
    let data:any = {
      Id:mode == "edit" ?selectedObj.Id : uuidv4(),
      ReportId: reportMainInfo?.ReportId,
      AttachmentId:mode == "edit" ? selectedObj.AttachmentId: null,
      Title: mode == "edit" ? e.target.Title.value :reportMainInfo.ReportName+" " + e.target.Title.value,
      //TitleEN: e.target.TitleEN?.value,
      ReportTemplateFileName:mode == "edit" ? e.target.ReportTemplateFileName?.value :reportMainInfo.ReportFileName + e.target.ReportTemplateFileName?.value,
      IsDefault: e.target.IsDefault?.checked,
    };

    if (mode == "edit") {
      try {
        if(reportMainInfo?.ReportId){
          const res = await putTemplates(data)
          setResetForm(true);
          setShowForm(false);
          setEditForm(null);
          setSelectedObj(null);
          getTemplatesAsync()
        }else{
          let newResponse = response.filter((item) => item.Id !== selectedObj.Id);
          setResponse([...newResponse, { ...data, Id: selectedObj.Id }]);
          setShowForm(false);
          setEditForm(null);
          setSelectedObj(null);
          handleUserChanges()
        }
      } catch (error) {}
    } else {
      if(reportMainInfo?.ReportId){
        try {
          delete data?.Id
          const res = await postTemplates(data)
          setResetForm(true);
          setShowForm(false);
          setEditForm(null);
          setSelectedObj(null);
          getTemplatesAsync()
      } catch (error) {
          
      }
      }else{
        setResponse([...response, data]);
        setResetForm(true);
        setShowForm(false);
        setEditForm(null);
        setSelectedObj(null);
        handleUserChanges()
      }
    }
  
  };


  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setEditForm(null);
        setShowForm(false);
      });
    } else {
      setEditForm(null);
      setShowForm(false);
    }
  }

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);



useEffect(()=>{
  ReportTemplatesListCallback(response)
  
},[response])
useEffect(()=>{
  if(templates.length==0&&mode=="create"&&reportMainInfo.ReportFileName&&curentTab=="ReportTemplates"){
    let data = {
      Id: uuidv4(),
      ReportId: undefined,
      AttachmentId: null,
      Title: reportMainInfo.ReportName+" "+trans("defaulttemplate") ,
      //TitleEN: e.target.TitleEN?.value,
      ReportTemplateFileName:reportMainInfo.ReportFileName+"DefaultTemplate" ,
      IsDefault:true,
    };
    setResponse([ data]);

  }  
},[curentTab])


  /* #region toolbarFeedParameters */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response, 1, trans("FeedParameters"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateFeedParameter();
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "help":
        alert("call api help");
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(trans("msg_deleteconfirm"), "warning", "alert", () =>
            handleDeleteRow(selectedObj?.Id)
          );
        break;
      case "edit":
        if (selectedObj?.Id) {
          handleEditRow(selectedObj);
        }
        break;
    }
  };
  /* #endregion */

  return (
    <>
      <div className="cartable-FeedParameter">
        <Toolbar
          create={true}
          remove={true}
          view={false}
          id={selectedObj?.Id}
          emptyList={response == null || response?.length == 0}
          edit={false}
          refresh={false}
          search={false}
          handleClickToolbar={clickToolbarCallback}
        />
        <div className="card">
          <div className="">
            <>
              {showForm && (
                <>
                  <div className="card mb-4">
                    <form className="TypeForm row card-body " onSubmit={submit}>
                      <div className="col-md-4 col-sm-12">
                        <Textbox
                          required={true}
                          textboxName={"Title"}
                          textboxType="text"
                          resetForm={resetForm}
                          labelText={"Title"}
                          mode={mode}
                          defaultValue={editForm?.Title}
                        />
                      </div>
{/*                       <div className="col-md-4 col-sm-12">
                        <Textbox
                          required={true}
                          textboxName={"TitleEN"}
                          textboxType="text"
                          resetForm={resetForm}
                          labelText={"TitleEN"}
                          mode={mode}
                          defaultValue={editForm?.TitleEN}
                        />
                      </div> */}
                      <div className="col-md-4 col-sm-12">
                        <Textbox
                          textboxName={"ReportTemplateFileName"}
                          textboxType="text"
                          resetForm={resetForm}
                          labelText={"ReportTemplateFileName"}
                          mode={mode == "edit"?"view":mode}
                          defaultValue={editForm?.ReportTemplateFileName}
                        />
                      </div>
                      <div className=" mt-4 mb-4">
                        <CheckableButton
                          defaultChecked={editForm?.IsDefault}
                          checked={editForm?.IsDefault}
                          defaultValue={editForm?.IsDefault}
                          labelText={"IsDefault"}
                          inputName={"IsDefault"}
                          type="checkbox"
                          mode={mode}
                        />
                      </div>
                      {mode != "view" ? (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button
                            btnType="primary"
                            value="close"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      )}
                    </form>
                  </div>
                </>
              )}

              {loading ? (
                response?.length > 0 ? (
                  <>
                    <DataGrid
                      DoubleClick={handleEditRow}
                      parentName={"FeedParameters"}
                      startIndex={3}
                      theadData={Object.keys(response[0])}
                      rowSelected={selectedObj}
                      tbodyData={response}
                      scrollHeight={showForm ? "25vh" : "67vh"}
                      minCellWidth={80}
                      selectedRowObjectCallback={selectedIdCallback}
                      handleDelete={(e) => {
                        Toast(
                          trans("msg_deleteconfirm"),
                          "warning",
                          "alert",
                          () => handleDeleteRow(e)
                        );
                      }}
                      handleEdit={handleEditRow}
                    />
                  </>
                ) : (
                  <Message
                    message={catchMessage.msg}
                    type={catchMessage.type}
                  />
                )
              ) : (
                <Loading />
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
