import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getSalesOrder = (pageNumber: number, pageSize: number, searchString, orderBy, filterlist ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse,
    method: 'get',
    params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString: searchString,
        OrderByString: orderBy,
        FilterString: filterlist,
      
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesOrderById = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
       Id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSalesOrderByReferenceId = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.referenceId,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
        ReferenceId:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getTaxList = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemTax.browse,
    method: 'get',
    params: {
        id:Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPriceListDetail = (Id,priceListId ,code , warehouseId=null ,personId,WarehouseDetailId=null ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemPriceApis.ItemPrice,
    method: 'get',
    params: {
        ItemId:Id,
        ItemCode:code , 
        PriceListId:priceListId,
        WarehouseId:warehouseId,
        CustomerPersonId:personId,
       WarehouseDetailId
        
   

    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const postSalesOrder = (data ,processId=null,SendSMS=false ) => http({
    baseURL:INVENTORYANDSALES_URL,
 url: processId ? ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.addFirst :  ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse,
    method: 'post',
    data:data,
    params:{
        Confirm:false,
        PRJ_UID:processId,
        SendSMS:SendSMS
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const CalculateSalesOrder = (data ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.Calculate,
    method: 'post',
    data:data,
    headers: { "Accept-Language": "en" },
    params:{Confirm:false}
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const putSalesOrder = (data ) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse,
    method: 'put',
    data:data
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getOrderConstraint = ( ) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.constraint,
    method: 'get',

    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});

export const getDefaultPersonContact = (Id ) => http({

    url: ENDPOINTS.BaseInformation.PersonContact.default,
    method: 'get',
    params:{
        personId:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPersonCustomer = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.load,
    method: 'get',
    params:{
        id:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSources = (type ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: type==1? ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse :ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse ,
    method: 'get',


}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const GenerateFromSource = (SourceId, SourceData  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    //url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.generate,
    method: 'get',
    params:{
        SourceData ,
        SourceId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const optionalDiscountHandler = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    //url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.optionalDiscount,
    method: 'post',
    data:data

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});


export const postResendJobOrder = (Id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.resendJob,
    method: 'post',
    params:{
        Id:Id ,
    }
})
export const putSalesOrderWorkFlow = (data,referenceId) => http({
    baseURL:INVENTORYANDSALES_URL,
   url: ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.workFlow,
    method: 'put',
    data:data,
    params:{
        referenceId:referenceId
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});