import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trans } from "utils/helpers";
import { getCustomerDictionaryApi, getCustomerDictionaryListApi } from "./api";

interface CustomerPropsType {
  label?: string,
  hasBorder?: boolean,
  valueString?: string,
  disabled?:any,
  resetForm?: boolean,
  required?: boolean,
  returnObj?: boolean,
  allowType?: boolean,
  value?: string,
  CustomerName?: string,
  mode?: string,
  ShowMethod?: any,//if useAlllist is true, when input is empty we can get all list instedof relations list
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
  checkHasAccess?:any
}

export const CustomerDictionary = ({ ShowMethod=null,label,disabled=false,checkHasAccess, hasBorder, value,CustomerName ,  valueString ,allowType=false,mode,returnObj=false ,resetForm,required , name="", Change ,onChange}: CustomerPropsType) => {
  const [Customer, setCustomers] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [rquird , setRquird] = useState(false);
  const [noAccessError,setNoAccessError]=useState(false)
  const [errorMessage,setErrorMessage]=useState('')
  const [allowTyping , setAllowTyping] = useState(valueString&& allowType );
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();

  const countRef = useRef<any>(0)
  const [listBoxPosition,setListBoxPosition]=useState<any>()
  let options_Customer_keys, options_Customer_values;


  async function getCustomerListAsync(search?, id?) {
    try {
      if (id) {
        const res2 = await getCustomerDictionaryApi( id );
        if(Object.values(res2)[0] && Object.values(res2)[0] != undefined){

          setValeu( Object.values(res2)[0],  Object.keys(res2)[0])
          setValueName( Object.values(res2)[0])
          if(!returnObj)
            onChange && onChange(Object.values(res2)[0])
          else
            onChange && onChange({Id:Object.keys(res2)[0] , Name:Object.values(res2)[0]})
        }else{
          
        }
       
      } else {

        const res = await getCustomerDictionaryListApi(search , ShowMethod!=null? value:null );
        setCustomers(res)
        options_Customer_values = Object.values(res)
        options_Customer_keys = Object.keys(res)
        setShow(true)
      }
      setFlag(!flag)
      setNoAccessError(false)
    } catch (error: any) {
      if(error.response?.status===403){
        setNoAccessError(true)
        setErrorMessage(error.response.data?.Message)
        checkHasAccess()
        
      }else{
        setNoAccessError(false)
      }

    }
  }


  const Auto = (e) => {
    setValidat(false)
    setRquird(false)
    setShow(true)
    Change && Change(true)
    inputIdRef.current.value = null
    getCustomerListAsync(e.target.value, "")
    setFlag(!flag)

  }

  function userSelecter(){
    setValeu(options_Customer_values[countRef.current],options_Customer_keys[countRef.current])

  //  setValeu(options_Customer_values[countRef.current],Object.keys(options_Customer_keys)[countRef.current])
    setShow(false)
  }

  const kydown = (event) => {  

    if((event.key === "ArrowDown")){
      if(countRef.current < options_Customer_values.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = options_Customer_values.length-1;
      }
    }
    document.getElementById("CustomerIndex"+countRef.current)?.scrollIntoView({block:'end',behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }

  }


  
  const CheckCustomer =async (e)=>{
    
    setTimeout(async() => {
      
      if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target?.value==""){
        onChange && onChange({Id:null , Name:null})
        if(inputIdRef.current)
             inputIdRef.current.value = null
        if(required)
        setRquird(true)
    }else{
      setRquird(false)
      try{
        if(!inputIdRef.current.value){

          const res = await getCustomerDictionaryListApi(inputIdRef.current.value ? null :e.target.value  , ShowMethod!=null? value:null)
          if(Object.keys(res).length==0){
            setValidat(true)
          }
          if(Object.keys(res).length == 1){
            setValeu(Object.values(res)[0],  Object.keys(res)[0]   )
          }
        }
      }catch(err:any){
        setValidat(true)
      }
    }
    }, 200);
    await delay(200)
    setShow(false)
  }

  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));





  const setValeu = (value, id) => {    
    if(inputIdRef != null && inputIdRef.current != null){

      setRquird(false)
      inputRef.current.value = value
      inputIdRef.current.value = id
      setCustomers(null)

      if(!returnObj)
      onChange && onChange(value)
    else
      onChange && onChange({Id:id , Name:value})

      setFlag(!flag)
    }
  }


  
  useEffect(() => {
    if (value) {
      if((mode!="view"&&disabled==false)){
        getCustomerListAsync(null, value)
      }else{
        onChange && onChange({Id:value , Name:CustomerName	})
      }
    }else if(valueString&&allowType){
      onChange && onChange({Id:" " , Name:valueString})
    }
  }, [value ,resetForm]);

  



  if (Customer) options_Customer_values = Object.values(Customer).map(item => {
    return item
  })
  if (Customer) options_Customer_keys = Object.keys(Customer).map(item => {
    return item
  })

  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) {
      setShow(false);
    }
  }


  const handleSetListBoxPosition=()=>{
    setListBoxPosition(inputRef.current?.getBoundingClientRect())
  }


  useEffect(() =>{
    if(show){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
    return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,options_Customer_values])



  useEffect(()=>{
    if(show){
      document.addEventListener('scroll',handleSetListBoxPosition,true)
      document.addEventListener('resize',handleSetListBoxPosition,true)
    }
    if(!show){ 
      document.removeEventListener('scroll',handleSetListBoxPosition,true)
      document.removeEventListener('resize',handleSetListBoxPosition,true)
    }
    return() => {
      document.removeEventListener('scroll',handleSetListBoxPosition,true)
      document.removeEventListener('resize',handleSetListBoxPosition,true)
    }
    },[inputRef.current,document,show,window])
 

    
    useEffect(()=>{
     setListBoxPosition(inputRef.current?.getBoundingClientRect())
 
    },[show])

  

  return (
    <>
    {(mode!="view"&&disabled==false)?
      <div className="text-box">
        {label && 
      <OverlayTrigger
             key={label}
             placement="top"
             delay={{"show" : 600 , "hide":0}}
             overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                {trans(label)}
                </Tooltip>
                }>
                 <div className="d-flex align-items-center" style={{height:'22px'}}>
                   <label className="text-box__label showDots"  style={{maxWidth:'90%'}}>{trans(`${label}`)}</label>
                   {required && <span className='text-box__required-sign-for-label text-danger me-1'>*</span>}
                  {(allowType&&mode!="view")&&
                  <>
                  {
                    allowTyping ?
                    <FontAwesomeIcon onClick={()=>{setAllowTyping(false)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass"]} className="text-primary pr-2"/>
                    :
                    <FontAwesomeIcon onClick={()=>{setAllowTyping(true)}} style={{marginRight:"5px"}} icon={["fas", "magnifying-glass-minus"]} className="text-danger  pr-2"/>

                  }
                  </>
                  }
                 </div>
          </OverlayTrigger> 
        }


        <input required={required} defaultValue={value ? value:valueString}   name={name} className="d-none" ref={inputIdRef} />
        
        {(!allowTyping||!allowType)?
        <div className="position-relative">
        <input   required={required} autoFocus={(!allowTyping&&valueName)} defaultValue={valueName}  onBlur={CheckCustomer} autoComplete="off"

        onMouseDown={(e) => {e.stopPropagation(); Auto(e) }} onFocus={(e) => {getCustomerListAsync(!allowTyping ? e.target.value:null); setShow(true) }} ref={inputRef} list="CustomerList" name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} onChange={Auto} disabled={ disabled||mode=="view" || noAccessError} />
       
     {(show && !noAccessError) &&    <div className={`text-box__sub-menu mt-1 glassBox border border-1 `} tabIndex={-1} style={{position:'fixed',zIndex:'5',top:`${listBoxPosition?.top+listBoxPosition?.height}px`,left:`${listBoxPosition?.left}px`,width:`${listBoxPosition?.width+'px'}`}}>    
            {options_Customer_values && options_Customer_values.map((item, index) => {
              return <div id={"CustomerIndex"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={(e) => { setValeu(item, options_Customer_keys[index]) }} key={options_Customer_keys[index]} >{item}</div>
            })}
          </div>}
          {noAccessError &&
          <div className="text-box__msg-wrapper text-box__required-msg show-msg text-danger">{trans(errorMessage)}</div>
          }
        
        </div>
        :
        <input required={required} defaultValue={valueString}  onBlur={(e)=>{
          onChange&&onChange( {Id:" " , Name:e.target.value});inputIdRef.current.value = e.target.value }} onChange={(e)=>{setValueName(e.target.value)
          }}  ref={inputRef}  name="browser" className={"text-box__box form-control" + " browser"+name} type={'text'} id={"browser"+name} disabled={disabled} />

        }
     {!noAccessError &&  <div className="text-box__msg-wrapper">
         {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
         {required && <span className={`${rquird ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsRequired", [""])}</span>}
        </div>    }
 
      </div>      
      :  
      <Textbox
      textboxType="text"
      textboxName="CustomerName"
      labelText="CustomerName"
      defaultValue={CustomerName	 }
      resetForm={resetForm}
      mode={mode}
    />


  }

    </>
  )
}