

import { Button } from "components/Button/Button";
import { Textbox } from "components/Textbox/Textbox";
import { getItemMainPropertyValues, postItemMainPropertyValues, putItemMainPropertyValues } from "../api";
import { useEffect, useState } from "react";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";


interface  ItemMainPropertyValueFormPropsType {
    onHide?: () => void;
    reload?: () => void;
    mode:any;
    propertyId?:any;
    selectObj?:any;
    colorValue?:any;
    
}

export const   ItemMainPropertyValueForm = ({ propertyId , selectObj ,mode ,reload,onHide ,colorValue}: ItemMainPropertyValueFormPropsType) => {
    const [response,setResponse] = useState<any>()
    const sendItemMainPropertyValuesAsync =async (e) => {
        e.preventDefault()
        try {
        
        if(mode == 'create'){
                let data ={
                    "OwnerId": propertyId,
                    "Code": e.target.Code.value ,
                    "Value": e.target?.Value?.value  ? e.target.Value.value  : null ,
                    "Description": e.target.Description.value ,
                    "DescriptionEN": e.target.DescriptionEN.value ,
                   
                    }
                await postItemMainPropertyValues(data)
            }else{
                let data ={
                    "OwnerId": propertyId,
                    "Id": selectObj.Id,
                    "Code": e.target.Code.value ,
                    "Value": e.target?.Value?.value  ? e.target.Value.value  : null ,
                    "Description": e.target.Description.value ,
                    "DescriptionEN": e.target.DescriptionEN.value ,
                    'ActivityStatus' : +e.target.ActivityStatus.value,
                    }
                    await putItemMainPropertyValues(data)
            }

   
            onHide&& onHide()
            reload&& reload()
        } catch (error) {
            
        }
       
    };


    

    async function getItemMainPropertyAsync() {
        try {
            const data = await getItemMainPropertyValues(selectObj?.Id);
            setResponse(data.Data)
        } catch (error) {
            
        }
    }


    useEffect(() => {
        if(mode != 'create'){
            getItemMainPropertyAsync()
        }else{
            // setLoading()
        }
    }, [mode]);
    return (
        < >
    
        <div className="p-2">
          
        <form className="TypeSettingForm row card-body " onSubmit={sendItemMainPropertyValuesAsync}>
             <div className="card ">
              <div className="card-body row">
                 <div className="col-md-4 col-sm-12">
                     <Textbox  textboxName={"Description"} textboxType="text"   labelText={"Description"} mode={mode}  defaultValue={response?.Description}/>
                 </div>  
                <div className="col-md-4 col-sm-12">
                     <Textbox  textboxName={"DescriptionEN"} textboxType="text"   labelText={"DescriptionEN"} mode={mode}   defaultValue={response?.DescriptionEN}/>
                 </div>     
                 {mode != "create" &&          
                    <div className="col-lg-4 mt-1">
                        <EnumerationTypeSelect
                         value={selectObj?.ActivityStatus} 
                         name="ActivityStatus"  
                         mode={mode} 
                         label="Status"
                         enumTypeSelect="ActivityStatus"
                        />
                    </div>                            
                     }   
                 <div className="col-md-4 col-sm-12">
                     <Textbox   required={true} textboxName={"Code"} textboxType="number"   labelText={"Code"} mode={mode}   defaultValue={response?.Code}/>
                 </div> 

                 {colorValue&&
                 <div className="col-md-4 col-sm-12">
                     <input type="color" name="Value" value={response?.Value??'#000000'}  />
                 </div> 
                
                 }

                      
        {  mode !="view"?
              <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit"/>
              <Button btnType="cancel" value="Cancel" onClick={()=>{onHide &&onHide()}} />
              </div>
              :
              <div className="col-lg-12 mt-1 btns_form">
              <Button btnType="primary" value="close" onClick={()=>{onHide &&onHide()}} />
              </div>

            }  
                </div>
                </div>
     
            
        </form>
    </div>
        </>
    )
}