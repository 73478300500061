import { ButtonToolbar } from "components/ButtonToolbar/ButtonToolbar";
import { ButtonToolbarCreateUser } from "components/ButtonToolbarCreateUser/ButtonToolbarCreateUser";
import { ButtonToolbarHolidaysType } from "components/ButtonToolbarHolidaysType/ButtonToolbarHolidaysType";
import { ButtonToolbarMyOwnRequest } from "components/ButtonToolbarMyOwnRequest/ButtonToolbarMyOwnRequest";
import { ButtonToolbarProcessFilter } from "components/ButtonToolbarProcessFilter/ButtonToolbarProcessFilter";
import { useContext, useEffect, useState } from "react";

import { ButtonToolbarArchiveFile } from "components/ButtonToolbarArchiveFile/ButtonToolbarArchiveFile";
import { ButtonToolbarCreateArchive } from "components/ButtonToolbarCreateArchive/ButtonToolbarCreateArchive";
import { ButtonToolbarCreateitem } from "components/ButtonToolbarCreateItem/ButtonToolbarCreateItem";
import { ButtonToolbarCreateManual } from "components/ButtonToolbarCreateManual/ButtonToolbarCreateManual";
import { ButtonToolbarCreateReports } from "components/ButtonToolbarCreateReports/ButtonToolbarCreateReports";
import { ButtonToolbarCreateWarehouse } from "components/ButtonToolbarCreateWarehouse/ButtonToolbarCreateWarehouse";
import { ButtonToolbarCreateWarehouseIo } from "components/ButtonToolbarCreateWarehouseIo/ButtonToolbarCreateWarehouseIo";
import { ButtonToolbarEditSalesForms } from "components/ButtonToolbarEditSalesForms/ButtonToolbarEditSalesForms";
import { ButtonToolbarEnumerationFilter } from "components/ButtonToolbarEnumerationFilter/ButtonToolbarEnumerationFilter";
import { ButtonToolbarPersonnelCartable } from "components/ButtonToolbarPersonnelCartable/ButtonToolbarPersonnelCartable";
import { ButtonToolbarPersonType } from "components/ButtonToolbarPersonType/ButtonToolbarPersonType";
import { ButtonToolbarShowDeletedFilter } from "components/ButtonToolbarShowDeletedFilter/ButtonToolbarShowDeletedFilter";
import { ButtonToolbarStartProcess } from "components/ButtonToolbarStartProcess/ButtonToolbarStartProcess";
import { ButtonToolbarWarehouse } from "components/ButtonToolbarWarehouse/ButtonToolbarWarehouse";
import { Search } from "components/Search/Search";
import { SwitchBtn } from "components/SwitchBtn/SwitchBtn";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { ButtonPrintToolbar } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonPrintToolbar/ButtonPrintToolbar";
import { ButtonToolbarProcessStarting } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonToolbarProcessStarting/ButtonToolbarProcessStarting";
import { ButtonToolbarSecretariatFilter } from "pages/Secretariats/LettersPage/components/Toolbar/ButtonToolbarSecretariatFilter/ButtonToolbarSecretariatFilter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";

interface props {
  handleClickToolbar: (clickedButtonName: string) => void;
  handleSelectedFilterPersonType?: (clickedButtonName: any) => void;
  handleSelectedFilterProcess?: (clickedButtonName: any) => void;
  handleSelectedJobstatusFilter?: (clickedButtonName: any) => void;
  handleSelectedcreateUser?: (clickedButtonName: any) => void;
  handleSelectedProcessWithIdFilterToolbar?: (clickedButtonName: any) => void;
  handleSelectedFilterPersonalTaskStatus?: (clickedButtonName: any) => void;
  handleSelectedHolidaysType?: (clickedButtonName: any) => void;
  handleSelectedMyOwnRequestStartForm?: (clickedButtonName: any) => void;
  handleSelectedStartProcess?: (clickedButtonName: any) => void;
  handleSelectedStartingProcessToolbar?: (selectedStarting: string) => void;
  handleSelectedProcessFilterToolbar?: (selectedProcessFilter: any) => void;
  handleShowDeletedFilterToolbar?: (selectedFilter: any) => void;
  handleCreateArchive?: (selectedFilter: any) => void;
  handleCreateWarehouse?: (selectedFilter: any) => void;
  handleCreateReports?: (selectedFilter: any) => void;
  handleCreateItem?: (selectedProcessFilter: any) => void;
  handleCreateManual?: (selected: any) => void;
  handleSelectedArchive?: null | ((selectedProcessFilter: any) => void);
  handleSearch?: (str: any) => void;
  handlePrint?: (str: any) => void;
  handleChangePersonnelCartable?: (str: any) => void;
  setShowMode?: (str: any) => void;
  editSalesFormsCallBack?: (str: any) => void;
  showMode?: boolean;
  id?: string;
  emptyList?: boolean;
  reminder?: boolean;
  create?: boolean;
  view?: boolean;
  refresh?: boolean;
  edit?: boolean;
  search?: boolean;
  defultSerach?: any;
  remove?: boolean;
  attach?: boolean;
  members?: boolean;
  people?: boolean;
  download?: boolean;
  upload?: boolean;
  reference?: boolean;
  specialreference?: boolean;
  assignRule?: boolean;
  action?: boolean;
  history?: boolean;
  related?: boolean;
  Tree?: boolean;
  assignApp?: boolean;
  assignIcon?: boolean;
  report?: boolean;
  finish?: boolean;
  move?: boolean;
  OrganizationTypeFilter?: boolean;
  reply?: boolean;
  cancel?: boolean;
  current?: boolean;
  archive?: boolean;
  reverse?: boolean;
  forceStop?: boolean;
  info?: boolean;
  chat?: boolean;
  copy?: boolean;
  paste?: boolean;
  calendar?: boolean;
  transfer?: boolean;
  pattern?: boolean;
  reserve?: boolean;
  Export?: boolean;
  Print?: boolean;
  Import?: boolean;
  Importprocesses?: boolean;
  Delivery?: boolean;
  excel?: boolean;
  save?: boolean;
  editInGrid?: boolean;
  price?: boolean;
  boxes?: boolean;
  warehouse?: boolean;
  PersonnelCartable?: boolean;
  properties?: boolean;
  tax?: boolean;
  barcode?: boolean;
  user?: boolean;
  insertnote?: boolean;
  objectTag?: string;
  alarme?: boolean;
  design?: boolean;
  ConversionRatio?: boolean;
  voiding?: boolean;
  editSalesForms?: string;
  helpBtn?: boolean;
  more?: any;
  settingPattern?: boolean;
  recovery?: boolean;
  values?:boolean;
  createWarehouseIoForm?:any
  handleCreateWarehouseIo?:any
}
export const Toolbar: React.FC<props> = ({
  handleClickToolbar,
  handleSelectedFilterPersonType,
  handleSelectedFilterProcess,
  handleSelectedJobstatusFilter,
  handleSelectedcreateUser,
  handleSelectedFilterPersonalTaskStatus,
  handleSelectedHolidaysType,
  handleSelectedMyOwnRequestStartForm,
  handleSelectedStartProcess,
  handleSelectedStartingProcessToolbar,
  handleSelectedProcessFilterToolbar,
  handleShowDeletedFilterToolbar,
  handleSelectedProcessWithIdFilterToolbar,
  handleCreateArchive,
  handleCreateWarehouse,
  handleCreateReports,
  handleCreateItem,
  handleCreateManual,
  handleSearch,
  handlePrint,
  handleSelectedArchive,
  handleChangePersonnelCartable,
  setShowMode,
  editSalesFormsCallBack,
  showMode = false,
  id,
  emptyList,
  create,
  action,
  reminder,
  view = true,
  refresh = true,
  edit,
  search,
  defultSerach = null,
  remove,
  attach,
  members,
  people,
  download,
  upload,
  reference,
  specialreference,
  assignRule,
  related,
  history,
  Tree,
  assignApp,
  assignIcon,
  report,
  finish,
  move,
  OrganizationTypeFilter,
  reply,
  cancel,
  current,
  reverse,
  archive,
  forceStop,
  info,
  chat,
  copy,
  paste,
  calendar,
  transfer,
  pattern,
  reserve,
  Export,
  Print = false,
  Import,
  Importprocesses,
  Delivery,
  excel = true,
  warehouse = false,
  save,
  editInGrid,
  price,
  boxes,
  properties,
  tax,
  barcode,
  PersonnelCartable,
  insertnote,
  objectTag,
  user,
  alarme,
  design,
  ConversionRatio,
  voiding,
  editSalesForms,
  helpBtn = true,
  more = true,
  settingPattern = false,
  recovery = false,
  values=false,
  createWarehouseIoForm,
  handleCreateWarehouseIo
}) => {
  const [shortcutList, setShortcutList] = useState<any>({});

  const [openToolbar, setOpenToolbar] = useState<boolean>(false);
  const [isFocos, setIsFocos] = useState<boolean>(false);
  const [personnelCartableName, setPersonnelCartableName] =
    useState<string>("");
  const layoutContext = useContext(LayoutContext);
  const toggleToolbar = () => {
    setOpenToolbar(!openToolbar);
  };

  const handleSelectedLetterProcessStarting = (id: string) => {
    handleSelectedStartingProcessToolbar &&
      handleSelectedStartingProcessToolbar(id);
  };

  const handleSelectedLetterFilterProcess = (id: string) => {
    handleSelectedProcessFilterToolbar &&
      handleSelectedProcessFilterToolbar(id);
  };

  useEffect(() => {
    //  window.addEventListener("keydown" , keydown)
    let StorageShortcut = localStorage.getItem("Shortcut");
    let ShortcutList;
    if (StorageShortcut == null) {
      setShortcutList({});
    } else {
      ShortcutList = JSON.parse(StorageShortcut);
      setShortcutList(ShortcutList);
    }
  }, []);
  return (
    <section className="py-2 mb-2">
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-2 mt-1 mt-sm-0">
          {setShowMode && (
            <SwitchBtn
              handleModeCard={setShowMode}
              setShape="circle"
              firstMode="درختی"
              secondMode="لیستی"
              cardStyle={showMode}
            />
          )}
          {search ? (
            <Search
              defultSerach={defultSerach}
              setIsFocos={setIsFocos}
              isFocos={isFocos}
              onChange={handleSearch && handleSearch}
            />
          ) : null}
        </div>
        <div className="toolbarXscrol toolbar-btns-container col-12 col-lg-10">
          <div
            className={`toolbarXscrol toolbar-btns-container__sub-container ${
              openToolbar ? "active" : ""
            } d-flex d-md-flex justify-content-md-start justify-content-lg-end w-100 mt-2 mt-lg-0`}
          >
            {more && (
              <button
                onClick={toggleToolbar}
                className="toolbar-btns-container__toggle-btn btn btn-primary my-1"
              >
                +
              </button>
            )}

            <OverlayTrigger
              key={"PersonnelCartableBtn"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"PersonnelCartableBtn"}`}
                >
                  {personnelCartableName}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {PersonnelCartable ? (
                  <ButtonToolbarPersonnelCartable
                    handleChangePersonnelCartable={(e) => {
                      handleChangePersonnelCartable &&
                        handleChangePersonnelCartable(e);
                      setPersonnelCartableName(e.Name);
                    }}
                    onClick={() => {
                      handleClickToolbar("refresh");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"warehouseItemBtn"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"warehouseItemBtn"}`}
                >
                  {trans("currentwarehouse")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {warehouse &&
                layoutContext.currentApplication?.Name ==
                  "InventoryManagement" ? (
                  <ButtonToolbarWarehouse
                    onClick={() => {
                      handleClickToolbar("refresh");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"design"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"design"}`}>
                  {trans("design")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {design ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["design"]}
                    faIcon={[ICON_TYPE, "compass-drafting"]}
                    text="design"
                    onClick={() => {
                      handleClickToolbar("design");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"boxes"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"boxes"}`}>
                  {trans("select ItemGroup")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {boxes ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["boxes"]}
                    faIcon={[ICON_TYPE, "boxes-stacked"]}
                    text="ItemGroup"
                    onClick={() => {
                      handleClickToolbar("boxes");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            {save ?  <OverlayTrigger
              key={"save"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"save"}`}>
                  {trans("save")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                 
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["save"]}
                    faIcon={[ICON_TYPE, "save"]}
                    text="save"
                    onClick={() => {
                      handleClickToolbar("save");
                    }}
                  />
                
              </div>
            </OverlayTrigger>
            : null}
            {cancel ? (
            <OverlayTrigger
              key={"cancel"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"cancel"}`}>
                  {trans("cancel")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                  <ButtonToolbar
                    id={id}
                    key={shortcutList["Archive"]}
                    faIcon={[ICON_TYPE, "xmark"]}
                    text="cancel"
                    hotKey={shortcutList["cancel"]}
                    onClick={() => {
                      handleClickToolbar("cancel");
                    }}
                  />
              </div>
              </OverlayTrigger>
                ):<></> }
            <OverlayTrigger
              key={"Filter5"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter") + " " + trans("Process")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedProcessWithIdFilterToolbar ? (
                  <div className="py-1 py-lg-0">
                    <ButtonToolbarProcessFilter
                      key={""}
                      Callback={handleSelectedProcessWithIdFilterToolbar}
                    />
                  </div>
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Filter4"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedProcessFilterToolbar ? (
                  <div className="py-1 py-lg-0">
                    <ButtonToolbarSecretariatFilter
                      key={""}
                      Callback={handleSelectedLetterFilterProcess}
                    />
                  </div>
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Filter6"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleShowDeletedFilterToolbar ? (
                  <div className="py-1 py-lg-0">
                    <ButtonToolbarShowDeletedFilter
                      key={""}
                      Callback={handleShowDeletedFilterToolbar}
                    />
                  </div>
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"Filter7"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("PersonType")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedFilterPersonType ? (
                  <div className="py-1 py-lg-0">
                    <ButtonToolbarPersonType
                      key={""}
                      Callback={(e) => {
                        if (handleSelectedFilterPersonType) {
                          handleSelectedFilterPersonType(e);
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Filter1"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedFilterProcess ? (
                  <ButtonToolbarEnumerationFilter
                    enumerationType="OrganizationType"
                    key={""}
                    Callback={(e) => {
                      if (handleSelectedFilterProcess) {
                        handleSelectedFilterProcess(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"Filter2"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter") + " " + trans("status")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedJobstatusFilter ? (
                  <ButtonToolbarEnumerationFilter
                    enumerationType="jobstatus"
                    key={""}
                    Callback={(e) => {
                      if (handleSelectedJobstatusFilter) {
                        handleSelectedJobstatusFilter(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Filter3"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Filter"}`}>
                  {trans("Filter")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedFilterPersonalTaskStatus ? (
                  <ButtonToolbarEnumerationFilter
                    enumerationType="personalTaskStatus"
                    key={""}
                    Callback={(e) => {
                      if (handleSelectedFilterPersonalTaskStatus) {
                        handleSelectedFilterPersonalTaskStatus(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"reserve"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip "
                  id={`button-tooltip-${"reserve"}`}
                >
                  {trans("reserve")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {reserve ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["reserve"]}
                    faIcon={[ICON_TYPE, "calendar"]}
                    text="reserve"
                    onClick={() => {
                      handleClickToolbar("reserve");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"transfer"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip "
                  id={`button-tooltip-${"transfer"}`}
                >
                  {trans("transfer")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {transfer ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["transfer"]}
                    faIcon={[ICON_TYPE, "arrow-right-arrow-left"]}
                    text="transfer"
                    onClick={() => {
                      handleClickToolbar("transfer");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"paste"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"paste"}`}>
                  {trans("paste")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {paste ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["paste"]}
                    faIcon={[ICON_TYPE, "paste"]}
                    text="paste"
                    onClick={() => {
                      handleClickToolbar("paste");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"pattern"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip "
                  id={`button-tooltip-${"pattern"}`}
                >
                  {trans("pattern")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {pattern ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["pattern"]}
                    faIcon={[ICON_TYPE, "paste"]}
                    text="pattern"
                    onClick={() => {
                      handleClickToolbar("pattern");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"copy"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"copy"}`}>
                  {trans("copy")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {copy ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["copy"]}
                    faIcon={[ICON_TYPE, "copy"]}
                    text="copy"
                    onClick={() => {
                      handleClickToolbar("copy");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"accepted"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip "
                  id={`button-tooltip-${"accepted"}`}
                >
                  {trans("accepted")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {Import ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Import"]}
                    faIcon={[ICON_TYPE, "file-import"]}
                    text="accepted"
                    onClick={() => {
                      handleClickToolbar("import");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Import"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"Import"}`}>
                  {trans("importprocess")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {Importprocesses ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Import"]}
                    faIcon={[ICON_TYPE, "file-import"]}
                    text="import"
                    onClick={() => {
                      handleClickToolbar("import");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Delivery"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip "
                  id={`button-tooltip-${"Delivery"}`}
                >
                  {trans("Delivery")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {Delivery ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Delivery"]}
                    faIcon={[ICON_TYPE, "file-export"]}
                    text="Delivery"
                    onClick={() => {
                      handleClickToolbar("Delivery");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"export"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"export"}`}>
                  {trans("exportprocess")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {Export ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["export"]}
                    faIcon={[ICON_TYPE, "file-export"]}
                    text="export"
                    onClick={() => {
                      handleClickToolbar("export");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"print"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip " id={`button-tooltip-${"print"}`}>
                  {trans("print")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {!emptyList && Print ? (
                  <ButtonPrintToolbar
                    objectTag={objectTag}
                    Callback={(e) => {
                      handlePrint && handlePrint(e);
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"excel"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="toolbarTooltip"
                  id={`button-tooltip-${"excel"}`}
                >
                  {trans("excel")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {excel ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["excel"]}
                    faIcon={[ICON_TYPE, "table-cells"]}
                    text="Excel"
                    onClick={() => {
                      handleClickToolbar("excel");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"voiding"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="toolbarTooltip"
                  id={`button-tooltip-${"voiding"}`}
                >
                  {trans("void")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {voiding ? (
                  <ButtonToolbar
                    disable={emptyList}
                    hotKey={shortcutList["delete"]}
                    faIcon={[ICON_TYPE, "file-circle-xmark"]}
                    text="void"
                    onClick={() => {
                      handleClickToolbar("voiding");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"ConversionRatio"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="toolbarTooltip"
                  id={`button-tooltip-${"ConversionRatio"}`}
                >
                  {trans("currencyRate")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {ConversionRatio ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["currencyRate"]}
                    faIcon={[ICON_TYPE, "money-bill"]}
                    text="currencyRate"
                    onClick={() => {
                      handleClickToolbar("ConversionRatio");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"info"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"info"}`}>
                  {trans("info")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {info ? (
                  <ButtonToolbar
                    id={id}
                    key={"Ctrl+i"}
                    disable={emptyList}
                    faIcon={[ICON_TYPE, "info"]}
                    text="info"
                    hotKey={shortcutList["info"]}
                    onClick={() => {
                      handleClickToolbar("info");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"forceStop"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"forceStop"}`}
                >
                  {trans("forceStop")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {forceStop ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    key={"Ctrl+q"}
                    faIcon={[ICON_TYPE, "stop-circle"]}
                    text="stop"
                    hotKey={shortcutList["stop"]}
                    onClick={() => {
                      handleClickToolbar("forceStop");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"insertnote"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"insertnote"}`}
                >
                  {trans("insertnote")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {insertnote ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    key={"Ctrl+g"}
                    faIcon={[ICON_TYPE, "note-sticky"]}
                    text="insertnote"
                    hotKey={shortcutList["insertnote"]}
                    onClick={() => {
                      handleClickToolbar("insertnote");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"chat"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"chat"}`}>
                  {trans("chat")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {chat ? (
                  <ButtonToolbar
                    id={id}
                    key={"Ctrl+g"}
                    faIcon={[ICON_TYPE, "paper-plane"]}
                    text="chat"
                    hotKey={shortcutList["chat"]}
                    onClick={() => {
                      handleClickToolbar("chat");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"report"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"report"}`}>
                  {trans("report")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {report ? (
                  <ButtonToolbar
                    id={id}
                    key={"F9"}
                    faIcon={[ICON_TYPE, "bolt"]}
                    text="report"
                    hotKey={shortcutList["report"]}
                    onClick={() => {
                      handleClickToolbar("report");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"reminder"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"reminder"}`}
                >
                  {trans("reminder")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {reminder ? (
                  <ButtonToolbar
                    id={id}
                    key={"F10"}
                    faIcon={[ICON_TYPE, "bell"]}
                    text="Reminder"
                    hotKey={shortcutList["reminder"]}
                    onClick={() => {
                      handleClickToolbar("reminder");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Upload"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Upload"}`}>
                  {trans("Upload")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {upload ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Upload"]}
                    faIcon={[ICON_TYPE, "upload"]}
                    text="Upload"
                    onClick={() => {
                      handleClickToolbar("Upload");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"action"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"action"}`}>
                  {trans("action")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {action ? (
                  <ButtonToolbar
                    id={id}
                    key={"F9"}
                    faIcon={[ICON_TYPE, "bolt"]}
                    text="Action"
                    hotKey={shortcutList["action"]}
                    onClick={() => {
                      handleClickToolbar("action");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"download"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"download"}`}
                >
                  {trans("Download")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {download ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["Download"]}
                    faIcon={[ICON_TYPE, "download"]}
                    text="Download"
                    onClick={() => {
                      handleClickToolbar("Download");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"move"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"move"}`}>
                  {trans("transfer")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {move ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["move"]}
                    faIcon={[ICON_TYPE, "link"]}
                    text="transfer"
                    onClick={() => {
                      handleClickToolbar("move");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"members"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"members"}`}>
                  {trans("members")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {members ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["members"]}
                    faIcon={[ICON_TYPE, "users"]}
                    text="members"
                    onClick={() => {
                      handleClickToolbar("members");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"people"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"people"}`}>
                  {trans("people")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {people ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["persons"]}
                    faIcon={[ICON_TYPE, "users"]}
                    text="people"
                    onClick={() => {
                      handleClickToolbar("people");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"Rule"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Rule"}`}>
                  {trans("Role")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {assignRule ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Rule"]}
                    faIcon={[ICON_TYPE, "users"]}
                    text="Role"
                    onClick={() => {
                      handleClickToolbar("AssignRule");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"user"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"user"}`}>
                  {trans("createuserwithdetailcode")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {user ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["user"]}
                    faIcon={[ICON_TYPE, "user"]}
                    text="user"
                    onClick={() => {
                      handleClickToolbar("user");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"history"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"history"}`}>
                  {trans("history")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {history ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["history"]}
                    faIcon={[ICON_TYPE, "clipboard"]}
                    text="history"
                    onClick={() => {
                      handleClickToolbar("history");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"related"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"related"}`}>
                  {trans("related")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {related ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["related"]}
                    faIcon={[ICON_TYPE, "link"]}
                    text="related"
                    onClick={() => {
                      handleClickToolbar("related");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Tree"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Tree"}`}>
                  {trans("Tree")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {Tree ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Tree"]}
                    faIcon={[ICON_TYPE, "project-diagram"]}
                    text="Tree"
                    onClick={() => {
                      handleClickToolbar("Tree");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"App"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"App"}`}>
                  {trans("App")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {assignApp ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["App"]}
                    faIcon={[ICON_TYPE, "desktop-alt"]}
                    text="App"
                    onClick={() => {
                      handleClickToolbar("assignApp");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Icon"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Icon"}`}>
                  {trans("Icon")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {assignIcon ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["Icon"]}
                    faIcon={[ICON_TYPE, "icons"]}
                    text="Icon"
                    onClick={() => {
                      handleClickToolbar("assignicon");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"reply"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"reply"}`}>
                  {trans("reply")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {reply ? (
                  <ButtonToolbar
                    id={id}
                    key={shortcutList["Archive"]}
                    faIcon={[ICON_TYPE, "reply"]}
                    text="Reply"
                    hotKey={shortcutList["reply"]}
                    onClick={() => {
                      handleClickToolbar("reply");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            {attach ? (
              <OverlayTrigger
                key={"attach"}
                placement="top"
                delay={{ show: 600, hide: 0 }}
                overlay={
                  <Tooltip
                    className="tooltip"
                    id={`button-tooltip-${"attach"}`}
                  >
                    {trans(
                      window.location.href.includes("item")
                        ? "picture"
                        : "attach"
                    )}
                  </Tooltip>
                }
              >
                <div className="py-1 py-lg-0">
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["Attach"]}
                    faIcon={[ICON_TYPE, "paperclip"]}
                    text="Attach"
                    onClick={() => handleClickToolbar("attach")}
                  />
                </div>
              </OverlayTrigger>
            ) : null}

            <OverlayTrigger
              key={"finish"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"finish"}`}>
                  {trans("finish")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {finish ? (
                  <ButtonToolbar
                    id={id}
                    key={"F11"}
                    faIcon={[ICON_TYPE, "flag-checkered"]}
                    text="Finish"
                    hotKey={shortcutList["finish"]}
                    onClick={() => {
                      handleClickToolbar("finish");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"reverse"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"reverse"}`}>
                  {trans("reverse")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {reverse ? (
                  <ButtonToolbar
                    id={id}
                    key={shortcutList["Archive"]}
                    faIcon={[ICON_TYPE, "reply"]}
                    text="reverse"
                    hotKey={shortcutList["reverse"]}
                    onClick={() => {
                      handleClickToolbar("reverse");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"reference"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"reference"}`}
                >
                  {trans("reference")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {reference ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["reference"]}
                    key={"F8"}
                    faIcon={[ICON_TYPE, "share-square"]}
                    text="Reference"
                    onClick={() => {
                      handleClickToolbar("reference");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"specialreference"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"specialreference"}`}
                >
                  {trans("specialreference")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {specialreference ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["specialreference"]}
                    key={"F8"}
                    faIcon={[ICON_TYPE, "share-square"]}
                    text="specialreference"
                    onClick={() => {
                      handleClickToolbar("specialreference");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"Current"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Current"}`}>
                  {trans("settoinprogress")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {current ? (
                  <ButtonToolbar
                    id={id}
                    key={"F11 "}
                    alarme={alarme}
                    faIcon={[ICON_TYPE, "undo"]}
                    text="inprogress"
                    hotKey={shortcutList["current"]}
                    onClick={() => {
                      handleClickToolbar("current");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"Archive"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"Archive"}`}>
                  {trans("Archive")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {archive ? (
                  <ButtonToolbar
                    id={id}
                    key={shortcutList["Archive"]}
                    faIcon={[ICON_TYPE, "file-archive"]}
                    text="Archive"
                    hotKey={shortcutList["archive"]}
                    onClick={() => {
                      handleClickToolbar("archive");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"archive2"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"archive"}`}>
                  {trans("archive")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedArchive ? (
                  <ButtonToolbarArchiveFile
                    Callback={(e) => {
                      handleSelectedArchive && handleSelectedArchive(e);
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"tax"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"tax"}`}>
                  {trans("tax")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {tax ? (
                  <ButtonToolbar
                    disable={emptyList}
                    isFocos={isFocos}
                    id={id}
                    hotKey={shortcutList["tax"]}
                    faIcon={[ICON_TYPE, "percent"]}
                    text="tax"
                    onClick={() => {
                      handleClickToolbar("tax");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"barcode"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"barcode"}`}>
                  {trans("barcode")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {barcode ? (
                  <ButtonToolbar
                    disable={emptyList}
                    isFocos={isFocos}
                    id={id}
                    hotKey={shortcutList["barcode"]}
                    faIcon={[ICON_TYPE, "barcode"]}
                    text="barcode"
                    onClick={() => {
                      handleClickToolbar("barcode");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"properties"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"properties"}`}
                >
                  {trans("properties")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {properties ? (
                  <ButtonToolbar
                    disable={emptyList}
                    isFocos={isFocos}
                    id={id}
                    hotKey={shortcutList["properties"]}
                    faIcon={[ICON_TYPE, "list"]}
                    text="properties"
                    onClick={() => {
                      handleClickToolbar("properties");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"values"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"values"}`}
                >
                  {trans("values")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {values ? (
                  <ButtonToolbar
                    disable={emptyList}
                    isFocos={isFocos}
                    id={id}
                    hotKey={shortcutList["values"]}
                    faIcon={[ICON_TYPE, "list"]}
                    text="values"
                    onClick={() => {
                      handleClickToolbar("values");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"delete"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"delete"}`}>
                  {trans("delete")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {remove ? (
                  <ButtonToolbar
                    disable={emptyList}
                    isFocos={isFocos}
                    id={id}
                    hotKey={shortcutList["delete"]}
                    faIcon={[ICON_TYPE, "trash"]}
                    text="delete"
                    onClick={() => {
                      handleClickToolbar("remove");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"price"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"price"}`}>
                  {trans("price")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {price ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["price"]}
                    faIcon={[ICON_TYPE, "money-bill"]}
                    text="price"
                    onClick={() => {
                      handleClickToolbar("price");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"refresh"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"refresh"}`}>
                  {trans("refresh")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {refresh ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["refresh"]}
                    faIcon={[ICON_TYPE, "sync"]}
                    text="Refresh"
                    onClick={() => {
                      handleClickToolbar("refresh");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create1"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create1"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedStartingProcessToolbar ? (
                  <ButtonToolbarProcessStarting
                    key={"F4"}
                    Callback={handleSelectedLetterProcessStarting}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <></>
            <OverlayTrigger
              key={"create2"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create2"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedMyOwnRequestStartForm ? (
                  <ButtonToolbarMyOwnRequest
                    key={""}
                    Callback={(e) => {
                      if (handleSelectedMyOwnRequestStartForm) {
                        handleSelectedMyOwnRequestStartForm(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create7"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create2"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedStartProcess ? (
                  <ButtonToolbarStartProcess
                    key={""}
                    Callback={(e) => {
                      if (handleSelectedStartProcess) {
                        handleSelectedStartProcess(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <></>
            <OverlayTrigger
              key={"create3"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create3"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedHolidaysType ? (
                  <ButtonToolbarHolidaysType
                    key={"create"}
                    Callback={(e) => {
                      if (handleSelectedHolidaysType) {
                        handleSelectedHolidaysType(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <></>
            <OverlayTrigger
              key={"create4"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create4"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {create ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["create"]}
                    faIcon={[ICON_TYPE, "plus"]}
                    text="Create"
                    onClick={() => {
                      handleClickToolbar("create");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create5"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create5"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleSelectedcreateUser ? (
                  <ButtonToolbarCreateUser
                    key={"create"}
                    Callback={(e) => {
                      if (handleSelectedcreateUser) {
                        handleSelectedcreateUser(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create6"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create6"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleCreateArchive ? (
                  <ButtonToolbarCreateArchive
                    key={"create"}
                    Callback={(e) => {
                      if (handleCreateArchive) {
                        handleCreateArchive(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create9"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"create9"}`}>
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleCreateWarehouse && handleCreateWarehouse != undefined ? (
                  <ButtonToolbarCreateWarehouse
                    key={"create"}
                    Callback={(e) => {
                      if (handleCreateWarehouse) {
                        handleCreateWarehouse(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"create11"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"create11"}`}
                >
                  {trans("create")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {handleCreateReports && handleCreateReports != undefined ? (
                  <ButtonToolbarCreateReports
                    key={"create"}
                    Callback={(e) => {
                      if (handleCreateReports) {
                        handleCreateReports(e);
                      }
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            {handleCreateItem && (
              <>
                <OverlayTrigger
                  key={"create8"}
                  placement="top"
                  delay={{ show: 600, hide: 0 }}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      id={`button-tooltip-${"create8"}`}
                    >
                      {trans("create")}
                    </Tooltip>
                  }
                >
                  <div className="py-1 py-lg-0 ">
                    {handleCreateItem && handleCreateItem != undefined ? (
                      <ButtonToolbarCreateitem
                        type={1}
                        id={id}
                        key={"create"}
                        onClick={(e) => {
                          if (handleCreateItem) {
                            handleCreateItem(e);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  key={"create9"}
                  placement="top"
                  delay={{ show: 600, hide: 0 }}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      id={`button-tooltip-${"create9"}`}
                    >
                      {trans("create")}
                    </Tooltip>
                  }
                >
                  <div className="py-1 py-lg-0 ">
                    {handleCreateItem &&
                    window.location.href.includes("item-groups") &&
                    handleCreateItem != undefined ? (
                      <ButtonToolbarCreateitem
                        type={2}
                        id={id}
                        key={"create"}
                        onClick={(e) => {
                          if (handleCreateItem) {
                            handleCreateItem(e);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </OverlayTrigger>
              </>
            )}
            {handleCreateManual && (
              <>
                <OverlayTrigger
                  key={"create8"}
                  placement="top"
                  delay={{ show: 600, hide: 0 }}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      id={`button-tooltip-${"create8"}`}
                    >
                      {trans("create")}
                    </Tooltip>
                  }
                >
                  <div className="py-1 py-lg-0 ">
                    {handleCreateManual && handleCreateManual != undefined ? (
                      <ButtonToolbarCreateManual
                        id={id}
                        key={"create"}
                        Callback={(e) => {
                          if (handleCreateManual) {
                            handleCreateManual(e);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  key={"create9"}
                  placement="top"
                  delay={{ show: 600, hide: 0 }}
                  overlay={
                    <Tooltip
                      className="tooltip"
                      id={`button-tooltip-${"create9"}`}
                    >
                      {trans("create")}
                    </Tooltip>
                  }
                >
                  <div className="py-1 py-lg-0 ">
                    {handleCreateItem &&
                    window.location.href.includes("item-groups") &&
                    handleCreateItem != undefined ? (
                      <ButtonToolbarCreateitem
                        type={2}
                        id={id}
                        key={"create"}
                        onClick={(e) => {
                          if (handleCreateItem) {
                            handleCreateItem(e);
                          }
                        }}
                      />
                    ) : null}
                  </div>
                </OverlayTrigger>
              </>
            )}
            <OverlayTrigger
              key={"view"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"view"}`}>
                  {trans("view")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {view ? (
                  <ButtonToolbar
                    disable={emptyList}
                    id={id}
                    hotKey={shortcutList["view"]}
                    faIcon={[ICON_TYPE, "eye"]}
                    text="View"
                    onClick={() => {
                      handleClickToolbar("view");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              key={"edit"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"edit"}`}>
                  {trans("edit")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {edit ? (
                  <ButtonToolbar
                    disable={emptyList}
                    editInGrid={editInGrid}
                    createCallback={(e) => {
                      e == 1
                        ? handleClickToolbar("edit")
                        : e == 2
                        ? handleClickToolbar("editgrid")
                        : handleClickToolbar("groupedit");
                    }}
                    options={
                      editInGrid
                        ? [
                            { val: 1, text: trans("EditRow") },
                            { val: 2, text: trans("EditTable") },
                            { val: 3, text: trans("GroupEdit") },
                          ]
                        : null
                    }
                    id={id}
                    hotKey={shortcutList["edit"]}
                    faIcon={[ICON_TYPE, "edit"]}
                    text="Edit"
                    onClick={
                      editInGrid
                        ? () => {}
                        : () => {
                            handleClickToolbar("edit");
                          }
                    }
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              key={"edit2"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"edit"}`}>
                  {trans("edit")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {editSalesForms ? (
                  <ButtonToolbarEditSalesForms
                    formName={editSalesForms}
                    Callback={(e) => {
                      editSalesFormsCallBack && editSalesFormsCallBack(e);
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>










            <OverlayTrigger
              key={"edit3"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip className="tooltip" id={`button-tooltip-${"edit"}`}>
                  {trans("edit")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {createWarehouseIoForm ? (
                <ButtonToolbarCreateWarehouseIo
                key={"create"}
                Callback={(e) => {
                  if (handleCreateWarehouseIo) {
                    handleCreateWarehouseIo(e);
                  }
                }}
              />
                ) : null}
              </div>
            </OverlayTrigger>









            <OverlayTrigger
              key={"settingPattern"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"messagePatterns"}`}
                >
                  {trans("messagePatterns")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {settingPattern ? (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["messagePatterns"]}
                    faIcon={[ICON_TYPE, "paste"]}
                    text="messagePatterns"
                    onClick={() => {
                      handleClickToolbar("settingPattern");
                    }}
                  />
                ) : null}
              </div>
            </OverlayTrigger>

            {helpBtn && (
              <OverlayTrigger
                key={"help"}
                placement="top"
                delay={{ show: 600, hide: 0 }}
                overlay={
                  <Tooltip className="tooltip" id={`button-tooltip-${"help"}`}>
                    {trans("help")}
                  </Tooltip>
                }
              >
                <div className="py-1 py-lg-0">
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList["help"]}
                    faIcon={[ICON_TYPE, "question"]}
                    text="Help"
                    onClick={() => {
                      handleClickToolbar("help");
                    }}
                  />
                </div>
              </OverlayTrigger>
            )}

            <OverlayTrigger
              key={"recovery"}
              placement="top"
              delay={{ show: 600, hide: 0 }}
              overlay={
                <Tooltip
                  className="tooltip"
                  id={`button-tooltip-${"recovery"}`}
                >
                  {trans("recovery")}
                </Tooltip>
              }
            >
              <div className="py-1 py-lg-0">
                {recovery && (
                  <ButtonToolbar
                    id={id}
                    hotKey={shortcutList[""]}
                    faIcon={[ICON_TYPE, "rotate-left"]}
                    text="recovery"
                    onClick={() => {
                      handleClickToolbar("recovery");
                    }}
                  />
                )}
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </section>
  );
};
