import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { getParameterDictionary } from "./api";
interface CustomerPositionPropsType {
  label: string,
  hasBorder?: boolean,
  resetForm: boolean,
  required?: boolean,
  withoutLable?: boolean,
  value?: string,
  mode?: string,
  typeName?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
  isOptional?:boolean
}

export const ParameterDictionary = ({ label, hasBorder,typeName, isOptional=false,value, mode ,resetForm,required ,withoutLable, name="", Change ,onChange}: CustomerPositionPropsType) => {
  const [Parameter, setParameter] = useState<any>();
  const [valueName, setValueName] = useState<any>();
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const ParameterRef = useRef<any>(Parameter);
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)

  let optionList : any =[]
  async function getParameterDictionaryAsync( id?,search?) {
    try {
    const res = await getParameterDictionary(search,typeName);
    if(Object.keys(res.Data).length>0){
      if (id) {
        setValeu(res.Data[id] , id)
        setValueName(res.Data[id])
        if(res.Data[id]){
          onChange && onChange({Name:res.Data[id] , Id : id}) 
        }else{
          onChange && onChange(null) 
        }
      }

    }else{
      onChange && onChange(null)
    }
    setParameter(res.Data)     
    ParameterRef.current = res.Data
  } catch (err: any) {
    onChange && onChange(null)

    }

  }

  const Auto = (e) => {
    validRef.current = false
    setValidat(false)
    setShow(true)
    getParameterDictionaryAsync('',e.target.value)
  }

  function userSelecter(){
    setValeu(optionList[countRef.current],Object.keys(Parameter)[countRef.current])
    setShow(false)
  }

  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < optionList.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
      document.getElementById("ParameterDictionary"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = optionList.length-1;
      }
      document.getElementById("ParameterDictionary"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    }
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }
  const CheckParameter =async (e)=>{
    
    setTimeout(() => {
       
      if(!validRef.current){
        
        if(Parameter!=undefined &&  Object.values(Parameter).length == 1){
          setValeu(Object.values(Parameter)[0] , Object.keys(Parameter)[0])
          e.target.setCustomValidity("");
        }else{
          if(inputRef?.current){
          setValeu(null,null)
          }
          
          if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
            e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
            setValidat(true);
            e.target.reportValidity();
            inputIdRef.current.value = null
          }
          if(e.target.value.length == 0&& inputRef.current?.value?.length == 0 && e.target.value==""){
            e.target.setCustomValidity("");
            inputIdRef.current.value = null
          }
          setParameter(null);
          
        }
      }
    }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));




  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null && newValue){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id
      ParameterRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :id}):Change&&Change({[name]:null});
      if(newValue){
        onChange && onChange({Name:newValue , Id : id})
      }else{
        onChange && onChange(null)
      }
      setParameter(null)
      setParameter({[inputIdRef.current.value]:inputRef.current.value})
      setValidat(false)
    }
  }
  useEffect(() => {
    if(value){
      getParameterDictionaryAsync(value,null)
    }else if(value == null){
      setValueName(null)

     }
  }, [value,resetForm]);



  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,optionList])

  
  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }



  if(Parameter){
    optionList = Object.values(Parameter)
  }
  return (
    <>
    <div className="text-box">
        {!withoutLable&&
    <label htmlFor={`browser${name}`} className="text-box__label">{trans(label)}</label>
        }
    
    <input  required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
    <div className="d-flex">
    <input required={required} onBlur={CheckParameter}  defaultValue={valueName} autoComplete="off" onFocus={(e)=>{ e.target.select() ;setShow(true);Auto(e)}}  ref={inputRef} list="ParameterDictionary" name="browser" className="text-box__box form-control " type={'text'}    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view"  ? true : false} />
    {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
    </div>
    {
       show && 
        <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
          {optionList && optionList.map((item , index)=>{
                 return  <div id={"ParameterDictionary"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={()=>{setValeu(item,Object.keys(Parameter)[index])}} key={Object.keys(Parameter)[index]} >{item}</div>
           })}  
        </div>
     }  
      <div className="text-box__msg-wrapper">
       {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
       </div>    
    </div>
    </>
   )
}