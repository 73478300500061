import { Button } from "components/Button/Button";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { MapLocation } from "pages/OfficeAutomation/BaseInformation/PeoplePage/components/PersonContactList/component/PersonContactForm/component/MapLocation";
import { useEffect, useState } from "react";
interface PersonContactFormPropsType {
  personContact?: any;
  mode?: any;
  withMap?: any;
  hideDetail?: boolean;
  onChange?: (e) => void;
  required?:any
}
export const Address = ({
  onChange,
  personContact,
  hideDetail = false,
  mode,
  withMap = true,
  required
}: PersonContactFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [type, setType] = useState<any>(4);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [personLocation, setpersonLocation] = useState<any>();

  const sendLocationCallback = (e) => {
    setpersonLocation({
      address: e.address,
      Latitude: e.geom.coordinates[1],
      Longitude: e.geom.coordinates[0],
    });

    setShowMap(false);
  };

  return (
    <>
      {showMap && (
        <ModalForm
          checkClose={false}
          onHide={() => setShowMap(false)}
          show={showMap}
          title="PersonContact"
        >
          <MapLocation sendLocationCallback={sendLocationCallback} />
        </ModalForm>
      )}

      <>
        <div className="row">
          <div className={`${hideDetail ? "col-lg-12   " : "col-lg-7"} `}>
            <Textbox
            required={required}
              mode={mode}
              textboxType="text"
              textboxName="Address"
              labelText="Address"
              onChange={onChange}
              maxLength={900}
              resetForm={resetForm}
              defaultValue={
                hideDetail?
                personContact?
                personContact
                :''
                :''
              }
            />
          </div>
          {hideDetail ? (
            <></>
          ) : (
            <>
              <div className="col-lg-1 ">
                <Textbox
                  mode={mode}
                  textboxType="text"
                  textboxName="Plaque"
                  labelText="Plaque"
                  maxLength={900}
                  resetForm={resetForm}
                  defaultValue={
                    type == 4 && personContact ? personContact?.plaque : ""
                  }
                />
              </div>
              <div className="col-lg-1 ">
                <Textbox
                  mode={mode}
                  textboxType="text"
                  textboxName="Unit"
                  labelText="Unit"
                  maxLength={900}
                  resetForm={resetForm}
                  defaultValue={
                    type == 4 && personContact ? personContact?.unit : ""
                  }
                />
              </div>
              <div className="col-lg-3 ">
            <Textbox
              mode={mode}
              defaultValue={
                type == 4 && personContact ? personContact?.postalcode : ""
              }
              textboxType="PostalCode"
              textboxName="PostalCode"
              labelText="PostalCode"
              resetForm={resetForm}
              maxLength={10}
            />
          </div>
            </>
          )}


          {withMap && (
            <>
              <div className="col-lg-2 ">
                <Textbox
                  mode={mode}
                  textboxType="text"
                  textboxName="Latitude"
                  labelText="Latitude"
                  maxLength={900}
                  resetForm={resetForm}
                  readonly
                  defaultValue={
                    personLocation
                      ? personLocation.Latitude
                      : type == 4 && personContact
                      ? personContact?.Latitude
                      : ""
                  }
                />
              </div>
              <div className="col-lg-2 ">
                <Textbox
                  mode={mode}
                  textboxType="text"
                  textboxName="Longitude"
                  labelText="Longitude"
                  maxLength={900}
                  readonly
                  resetForm={resetForm}
                  defaultValue={
                    personLocation
                      ? personLocation.Longitude
                      : type == 4 && personContact
                      ? personContact?.Longitude
                      : ""
                  }
                />
              </div>
              <div className="col-lg-2  d-none">
                <Textbox
                  mode={mode}
                  textboxType="text"
                  textboxName="Altitude"
                  labelText="Altitude"
                  maxLength={900}
                  readonly
                  resetForm={resetForm}
                  defaultValue={
                    type == 4 && personContact ? personContact?.Altitude : ""
                  }
                />
              </div>
              <div className="col-2 mt-4">
                <Button
                  value="نقشه"
                  btnType="primary"
                  disabled={mode == "view"}
                  onClick={() => {
                    setShowMap(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
};
