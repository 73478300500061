import { useEffect, useState } from "react";

import { Button } from "components/Button/Button";
import { Databases } from "components/Databases/Databases";
import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { getReportView, getWithFeed } from "pages/SystemManagement/Reports/components/api";
import { trans } from "utils/helpers";
import { OperatorOptions } from "./OperatorOptions";
import { SelectBox } from "components/Select/Select";

interface FilterReportFormPropsType {
    Id: any,
    mode?: any,
    onHide: () => void,
}
export const FilterReportForm = ({mode="view" , Id=null,onHide }: FilterReportFormPropsType) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [resetForm, setResetForm] = useState(false);
    const [databasesList, setDatabasesList] = useState<any>([]);

    const [loading, setLoading] = useState(false);
    const [dateDefaultValue,setDateDefaultValue] = useState<any>({})
    const lang = localStorage.getItem("lang");

    const [feedType , setFeedType] = useState("Api")


 
    const submitHandler =(e)=>{
        e.preventDefault()
        let data:any ={}
        response.ReportParameters.map((item , index)=>{
            if((e.target["value"+item.ParameterNameEN]&&e.target["value"+item.ParameterNameEN]?.value )|| dateDefaultValue["value"+item.ParameterNameEN]||(e.target["value"+item.ParameterNameEN]!=undefined&&e.target["value"+item.ParameterNameEN][0]&&e.target["value"+item.ParameterNameEN][0]?.value) ){
                data[item.ParameterNameEN] ={
                    "OperatorType": +e.target["OperatorType"+item.ParameterNameEN].value,
                    "Values": [
                        ...(+e.target["OperatorType"+item.ParameterNameEN].value==13||+e.target["OperatorType"+item.ParameterNameEN].value==12 ?
                            [(item.ParameterDataType.includes(".Date")||item.ParameterDataType.includes(".Time") ) ?dateDefaultValue["value"+item.ParameterNameEN]?.[0]   : (item.ParameterDataType.includes("Boolean") ?  e.target["value"+item.ParameterNameEN][0].checked :  e.target["value"+item.ParameterNameEN][0].value),
                            (item.ParameterDataType.includes(".Date")||item.ParameterDataType.includes(".Time") ) ?dateDefaultValue["value"+item.ParameterNameEN]?.[1]    : (item.ParameterDataType.includes("Boolean") ?  e.target["value"+item.ParameterNameEN][1].checked :  e.target["value"+item.ParameterNameEN][1].value)
                        ]
                            :
                            [  ...(+e.target["OperatorType"+item.ParameterNameEN].value==11||+e.target["OperatorType"+item.ParameterNameEN].value==10 ?
    
                                [...dateDefaultValue["value"+item.ParameterNameEN]]    
                                   :
                                [(item.ParameterDataType.includes(".Date")||item.ParameterDataType.includes(".Time") ) ?dateDefaultValue["value"+item.ParameterNameEN]?.[0]   : (item.ParameterDataType.includes("Boolean") ?  e.target["value"+item.ParameterNameEN].checked :  e.target["value"+item.ParameterNameEN].value)]
    
                            )
                            ] 
                        ),
                    ]
                }
                if(data[item.ParameterNameEN].Values[0] == null || data[item.ParameterNameEN].Values[0] == undefined){
                    delete data[item.ParameterNameEN]
                }
            }
        })
        let databasesString =""
         databasesList.map(item=>{
            databasesString += ("&databases="+item)
         })
         if(databasesList.length==0){
            databasesString += ("&databases="+e.target.Databases?.value)
         }
         if(mode=="view"){
             window.open(`${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportViewer/View/"}${e.target.ReportTemplates.value}?paramsAsJson=${JSON.stringify(data)}${response?.IsMethod ? databasesString:""}&userDataId=${sessionStorage.getItem("accept-data")} `,"_blank" );  
         }else{
            window.open(`${process.env.REACT_APP_REPORTING_SERVICE_URL+ "/ReportDesigner/Index/"}${e.target.ReportTemplates.value }?paramsAsJson=${JSON.stringify(data)}${response?.IsMethod ? databasesString:""}&userDataId=${sessionStorage.getItem("accept-data")}`,"_blank" );  
         }
    }

    async function getAllFilterReportAsync() {
        setLoading(false)
        try {
            const res = await getReportView(Id); 
            setResponse(res.Data)
            setFeedType(res.Data.IsMethod ?"Method" :"Api"	)
        } catch (error) {
            // setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        getAllFilterReportAsync()
    }, [ Id]);

    return (
        <div className="person-contact-list">
        {loading?
            <form className="card" onSubmit={submitHandler }>
            <div className="card-body">
                <div className="row">
                    {response?.IsMethod&&
                     <div className="col-3">
                     <Databases 
                        label="Databases"
                        name={"Databases"}
                        mode={"create"}
                        type={"SelectBox"}   
                        DatabasesListCallback={(e)=>{setDatabasesList(e)}}
                        />
                    </div>
                    }
                 
                     <div className="col-3 mb-1">
                         <SelectBox
                           useTrans={true}
                           lable={"ReportTemplates"}
                           selectType={"select"}
                           options={Object.values(response.ReportTemplates)  }
                           val={Object.keys(response.ReportTemplates)}
                           name={"ReportTemplates"}
                           resetForm={resetForm}
                           mode={"create"}       
                         />
                    </div>
                    
                </div>



                {response.ReportParameters.map((item,index)=>(
                    <div className="row">
                        <div className="col-3 " >
                        {index==0 && <small className="d-block mb-1">{trans('parametername')}</small>}

                            <Textbox
                            withLabel={false}
                               defaultValue={lang==='fa'?item.ParameterName: item.ParameterNameEN}
                               textboxType="text"
                               textboxName="defaultValue"
                               labelText={''}
                               resetForm={resetForm}
                               mode={"view"}
                            />

                        </div>
               
                        <div className="col-6 " >
                            <OperatorOptions feedType={feedType} index={index} setDatesValue={(e)=>{setDateDefaultValue({...dateDefaultValue,...e})}} item={item}/>
                        </div>
                    </div>
                ))
                }
            </div>
            <div className="col-lg-12 mt-1 btns_form">
            <Button value="نمایش" btnType="submit" />
            </div>
            </form>        
        :<Loading/>
        }


        </div>
    );
}