import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Person } from "components/Person/Person";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";

import Datepicker from "components/DatePicker/DatePicker";
import { Position } from "components/Position/Position";
import { useEffect, useRef, useState } from "react";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import {  getSalesInvoiceById } from "./api";
import { CustomerSalesInvoiceDetailForm } from "./CustomerSalesInvoiceDetailForm";

interface SalesInvoiceFormProps {
  onHide?: (usenavigate?) => void;
  jobId?: any;
  mode: any;
  Id?:any
}

export const CustomerSalesInvoice = ({
  mode,
  onHide,
  jobId,
  Id
}: SalesInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [invoiceConstraint, setInvoiceConstraint] = useState<any>({});
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const invoiceRef = useRef<any>(null);
 
  async function getInvoiceAsync() {
    setLoading(false);
//   const constraint= await getSalesInvoiceConstraintAsync();
    try {
   
        let res= await getSalesInvoiceById(Id );        
        let count = 0;
        res.Data.CustomerSalesInvoiceDetailViewDTO.map((item) => {
          count += +item?.Qty;
        });
        setTotalRows({
          ItemCount: count,
          TotalTax:  res.Data.TotalTax,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });          
        setResponse(res.Data);

    } catch (error) {}
    setLoading(true);
  }






  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }



  useEffect(() => {
    if (mode != "create") {
      getInvoiceAsync();
    }
  }, []);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);



  return (
    <div className=" ">
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesInvoiceDetailForm"}
        >
           <CustomerSalesInvoiceDetailForm
            mode={"view"}
            onHide={() => {
              setShowForm(false);
            }}
        
            selectedObj={selectedObj}
          /> 
        </ModalForm>
      ) : (
        <></>
      )}



      {loading ? (
        <form ref={invoiceRef}>
          
         
          <FormWithTitle
            title={trans("SalesInvoice")}
            defualtClose={true}
            showFristRow={true}
            closeOption={true}
            dynamicTitle={{
              CustomerName: response?.CustomerName	,
              number: response?.SalesInvoiceNo,
            }}
          >
            <div className="card-body pt-0">
              <div className="row">
                  <div className="col-lg-1 col-11">
                         <Textbox
                           textboxType="text"
                           textboxName="salesInvoiceNo"
                           labelText="number"
                           defaultValue={response?.SalesInvoiceNo}
                           resetForm={resetForm}
                           mode={mode}
                          />
                  </div>
              
             <div className="col-lg-2 col-11">
                  <Datepicker
                    mode={"view"}
                    lable="Date"
                    setIsoTime={(isoTime: string) => {  }}
                    val={ response?.SalesInvoiceDate}
                    errorFlag={false}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Textbox
                    textboxType="text"
                    textboxName="CustomerEconNo"
                    labelText="CustomerEconNo"
                    defaultValue={response?.CustomerEconNo    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerPhone"
                      labelText="CustomerPhone"
                      defaultValue={
                        response?.CustomerPhone ? response?.CustomerPhone : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
          
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      textboxType="text"
                      textboxName="CustomerMobilePhone"
                      labelText="CustomerMobilePhone"
                      defaultValue={ response?.CustomerMobilePhone }
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
            
                <div className="col-lg-2 me-lg-4 col-11">
                  <Textbox
                    textboxName="CustomerNationalCode"
                    textboxType="nationalCode"
                    labelText="CustomerNationalCode"
                    defaultValue={  response?.CustomerNationalCode }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
   
           <div className="col-lg-3 col-11">
                  <Person
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    value={ response?.RecipientPersonId }
                    resetForm={resetForm}
                    mode={mode}
                    WithSearch={false}
                    returnObj={true}
                    name={"RecipientPersonDescription"}
                    label="RecipientPerson"
                  />
                </div>

                  <div className="col-lg-5 col-11 d-flex ">
                    <Textbox
                      textboxName="Address"
                      textboxType="text"
                      labelText="Address"
                      defaultValue={ response?.CustomerAddress    }
                      resetForm={resetForm}
                      mode={mode }
                    />
                  </div>

                <div className="col-lg-2 col-11 mr-2">
                  <Position
                    name="VisitorPositionId"
                    label="VisitorPosition"
                    resetForm={resetForm}
                    value={response?.VisitorPositionId  }
                    mode={mode}
                    returnObj={true}
                  />
                </div>
                <div className="col-lg-2 col-11">
                  <Position
                    name="DistributorPositionId"
                    label="DistributorPosition"
                    resetForm={resetForm}
                    value={response?.DistributorPositionId}
                    mode={mode }
                    returnObj={true}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Position
                    name="SalesManagerPositionId"
                    label="salesmanagerpositiondescription"
                    resetForm={resetForm}
                    value={response?.SalesManagerPositionId}
                    mode={mode}
                    returnObj={true}

                  />
                </div>

                <div className="col-lg-5 col-11">
                  <Textbox
                    textboxName="Description"
                    textboxType="textarea"
                    labelText="Description"
                    minHeight={1}
                    defaultValue={response?.Description}
                    resetForm={resetForm}
                    mode={mode}
                    maxLength={900}
                    letterCounter={true}
                  />
                </div>
              </div>
            </div>
          </FormWithTitle>
          <div className="mt-3">
            <FormWithTitle title={trans("Details")}>
              <div>
                <div className="row align-items-center justify-content-between">

                  <div className="col-10 row" style={{ justifyContent: "end" }}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className={`${mode!="view"? "col-7" : "col-12"}  row justify-content-end mb-1`}>
                        <Headerbar
                          withPading={false}
                          headerContents={{...totalRows}}
                          hasBorder={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
               
                  <>
                    {response?.CustomerSalesInvoiceDetailViewDTO?.length >= 0 ? (
                      <>
                        <DataGrid
                          convertDateColNames={["ExpireDate"]}
                          DoubleClick={(e) => {
                            setSelectedObj(e);
                            setShowForm(true);
                          }}
                          parentName={"SalesInvoiceDetailList"}
                          startIndex={7}
                          theadData={Object.keys(response?.CustomerSalesInvoiceDetailViewDTO[0])}
                          tbodyData={response?.CustomerSalesInvoiceDetailViewDTO}
                          totalRecords={
                            response?.CustomerSalesInvoiceDetailViewDTO?.length > 0 ? undefined : 0
                          }
                          scrollHeight={"67vh"}
                          minCellWidth={80}
                          selectedRowObjectCallback={selectedObjCallback}
                          rowSelected={selectedObj}
                          handleView={(e) => {
                            setSelectedObj(e);
                            setShowForm(true);
                          }}
                        />
                      </>
                    ) : (
                      <Message message={"NoDataForPreview"} type={"info"} />
                    )}
                  </>

              </div>
            </FormWithTitle>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="totalprice"
                    textboxType="text"
                    labelText="totalprice"
                
                    defaultValue={response?.TotalPrice   }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Addition"
                    textboxType="text"
                    labelText="Addition"
                    defaultValue={response?.Addition  }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Deduction"
                    textboxType="text"
                    labelText="Deduction"
                    defaultValue={response?.Deduction  }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="GrandTotalPrice"
             
                    textboxType="text"
                    labelText="GrandTotalPrice"
                    defaultValue={response?.GrandTotalPrice}
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-1">
                  <DictionarySelectBox
                    label="PaymentMethod"
                    name="PaymentMethodId"
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                        .PaymentMethod.dictionary
                    }
                    resetForm={resetForm}
                    value={response?.PaymentMethodId}
                    mode={mode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    textboxName="Prepayment"
                    textboxType="text"
                    labelText="Prepayment"
                    defaultValue={response?.Prepayment}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
{/*                 <div className="col-lg-2">
                  <Textbox
                    textboxName="DebitPayment"
                    textboxType="text"
                    labelText="DebitPayment"
                    defaultValue={response?.DebitPayment }
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="NonDebitPayment"
                    textboxType="text"
                    labelText="NonDebitPayment"
                    defaultValue={response?.NonDebitPayment}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="CreditPayment"
                    textboxType="text"
                    labelText="CreditPayment"
                    defaultValue={ response?.GrandTotalPrice - (+response?.NonDebitPayment + +response?.DebitPayment + +response?.Prepayment) +""}
                    resetForm={resetForm}
                    mode={"view"}
                    useGroupingPrice={true}
                  />
                </div> */}
                <div className="col-lg-4 d-flex justify-content-end">
                  
                  {mode != "view" ? (
                    <div className="col-lg-12 mt-4  btns_form">
                      <Button outline={false}  value="save" btnType="submit" />
                      {!jobId && (
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                        )}
                    </div>
                  ) : (
                    <div className="col-lg-12  mt-4  btns_form">
                      {!jobId && (
                        <Button
                          btnType="primary"
                          value="close"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
