import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Person } from "components/Person/Person";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import {
  ContactType,
  CustomerDictionaryShowMethod,
  MessageType,
} from "types/enum";
import { GUID_EMPTY, ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress, trans } from "utils/helpers";
import { CustomerTabform } from "../BaseDefinitionAndSetting/Customers/CustomerTabForm";
import { CurrencyDictionary } from "../PriceList/components/CurrencyDictionary/CurrencyDictionary";
import { PriceListDictionary } from "../PriceList/components/PriceListDictionary/PriceListDictionary";
import { AddressBox } from "../SalesInvoice/components/AddressBox/AddressBox";
import { CustomerDictionary } from "../SalesInvoice/components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "../SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "../SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import {
  CalculateSalesOrder,
  getDefaultPersonContact,
  getOrderConstraint,
  getPersonCustomer,
  getSalesOrderById,
  getSalesOrderByReferenceId,
  postSalesOrder,
  putSalesOrder,
  putSalesOrderWorkFlow,
} from "./api";
import { SalesOrderDetailForm } from "./SalesOrderDetailForm";

interface SalesOrderFormProps {
  onHide?: (usenavigate?) => void;
  relod: () => void;
  jobId?: any;
  processId?: any;
  referenceId?: any;
  mode: any;
  step?: any;
  SourceData?: any;
  sourceDataParam?: any;
  taskId?: any;
  Id?: any;
  rowObject?: any;
  handleShowReferenceStepper?: (referenceId: string) => void;
}

export const SalesOrderForm = ({
  mode,
  relod,
  onHide,
  rowObject,
  handleShowReferenceStepper,
  step,
  taskId,
  jobId,
  Id,
  sourceDataParam,
  SourceData,
  processId,
  referenceId,
}: SalesOrderFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [haveSource, setHaveSource] = useState(
    SourceData ? SourceData : sourceDataParam
  );
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [noAccess, setNoAccess] = useState<any>(false);
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [salesOrderDate, setSalesOrderDate] = useState<any>();
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
  const [showCustomerform, setShowCustomerform] = useState<boolean>(false);
  const [priceList, setPriceList] = useState<any>(null);
  const [currency, setCurrency] = useState<any>(null);
  const [salesOrderDetail, setSalesOrderDetail] = useState<any>([]);
  const [dataGridLoading, setDataGridLoading] = useState<any>(true);
  const [lastWarehouse, setLastWarehouse] = useState<any>(null);
  const [OrderConstraint, setOrderConstraint] = useState<any>({});
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const [payment, setPayment] = useState<any>({ totalprice: 0, Prepayment: 0 });
  const [receive, setReceive] = useState<any>({
    GrandTotalPrice: 0,
    Prepayment: 0,
  });
  const [todayDate, settodayDate] = useState<any>(new Date().toISOString());
  const [person, setPerson] = useState<any>(null);
  const [dataChange, setDataChange] = useState<any>(false);
  const [salesOrderNo, setSalesOrderNo] = useState<any>(false);
  const [showReferenceStepper, setShowReferenceStepper] =
    useState<boolean>(false);
  const [dateLoading, setDateLoading] = useState(true); //for calc fun is runing

  const [detailMode, setDetailMode] = useState<"create" | "edit" | "view">(
    mode
  );
  const OrderRef = useRef<any>(null);
  const [salesOrderStatusId, setSalesOrderStatusId] = useState<any>();
  const [changeAddress, setChangeAddress] = useState<any>();
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const [SendSMS, setSendSMS] = useState(false);
  const [selectedPhoneDetail, setSelectedPhoneDetail] = useState<any>({
    phone: "",
    mobile: "",
  });
  const [requiredFields, setRequiredFields] = useState<any>();
  function setSalesOrderDetailHandler(data) {
    setDataGridLoading(false);
    setSalesOrderDetail(data);
    setDataGridLoading(true);
  }
  const handleSalesOrderDetailFormSubmit = async (event) => {
    event.preventDefault();
    let SubTotalPrice: any = 0;
    let newsalesOrderDetail = JSON.parse(JSON.stringify(salesOrderDetail)).map(
      (e: any) => {
        SubTotalPrice = +e.TotalPrice + SubTotalPrice;
        delete e.Id;
        return e;
      }
    );
    let data: any = {
      RecipientPersonDescription:  person?.Name
        ?? null,
      RecipientPersonId:  person?.Id
        ?? null,
      CurrencyId: !OrderConstraint.IsFiscal ? undefined : currency?.Id,
      SalesOrderNo: event.target.salesOrderNo.value,
      SalesOrderDate: salesOrderDate,
      PriceListId: event.target.PriceList?.value,
      PaymentMethodId: !OrderConstraint.IsFiscal
        ? undefined
        : event.target.PaymentMethodId?.value,
      CustomerId: customerInfo.Id,
      CustomerName: customer.Name,
      CustomerPhone: customer.SundryCustomer
        ? event.target?.CustomerPhone?.value
        : customer?.CustomerPhone?.Name.split(":")[1]
        ? customer?.CustomerPhone?.Name.split(":")[1]
        : customer?.CustomerPhone?.Name ?? null,
      CustomerMobilePhone: customer.SundryCustomer
        ? event.target?.CustomerMobilePhone?.value
        : customer?.CustomerMobilePhone?.Name.split(":")[1]
        ? customer?.CustomerMobilePhone?.Name.split(":")[1]
        : customer?.CustomerMobilePhone?.Name ?? null,
      CustomerAddress: event.target.Address?.value ?? null,
      CustomerEconNo: event.target.CustomerEconNo?.value,
      CustomerNationalCode: event.target.CustomerNationalCode?.value,
      DeliveryAddress: event.target.DeliveryAddress?.value,
      Description: event.target.Description?.value,
      IsCurrency: !OrderConstraint.IsFiscal ? undefined : !currency.IsDefault,
      CurrencyRate: !OrderConstraint.IsFiscal
        ? undefined
        : currency.CurrencyRate,
      TotalDiscount: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows?.TotalDiscount,
      TotalPrice: !OrderConstraint.IsFiscal ? undefined : +payment.totalprice,
      TotalTax:
        !OrderConstraint.IsFiscal || !OrderConstraint.HasTax
          ? 0
          : +totalRows?.TotalTax,
      GrandTotalPrice: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows?.SubTotalPrice -
          +totalRows?.TotalDiscount +
          +totalRows?.TotalTax,
      Prepayment: !OrderConstraint.IsFiscal
        ? undefined
        : +event.target.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows?.SubTotalPrice,
      SalesOrderDetailDTOList: newsalesOrderDetail,
      IsFiscal: OrderConstraint.IsFiscal,
    };

    try {
      if (mode == "create") {
        const res = await postSalesOrder(data, processId, SendSMS);
        if (showReferenceStepper) {
          handleShowReferenceStepper &&
            handleShowReferenceStepper(
              !(res.MessageType == MessageType.success) ? false : res.Data
            );
        } else {
          if (res.MessageType == MessageType.success) {
            setResetForm(true);
            onHide && onHide(true);
            relod();
          }
        }
      } else {
        if (jobId) {
          data = {
            ...data,
            Id: response.Id,
            SalesOrderStatusId: salesOrderStatusId,
            JobId: jobId,
            JobNo: response?.JobNo,
          };
          await putSalesOrderWorkFlow(data, referenceId);
        } else {
          data = {
            ...data,
            Id: response.Id,
            SalesOrderStatusId: salesOrderStatusId,
          };
          await putSalesOrder(data);
        }
      }
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
      onHide && onHide();
    } catch (error: any) {
      if (error.response.data.MessageType == MessageType.confirm) {
        Toast(trans("میخواهید تغییر دهید؟"), "warning", "alert", () => {
          setResponse(error.response.data.Data);
          setSalesOrderDetail(
            error.response.data.Data?.SalesOrderDetailDTOList
          );
          let newItemCount = 0;
          error.response.data.Data?.SalesOrderDetailDTOList.map((item) => {
            newItemCount += item.Qty;
          });
          setTotalRows({
            ItemCount: newItemCount,
            TotalTax: OrderConstraint.HasTax
              ? error.response.data.Data?.TotalTax
              : 0,
            TotalDiscount: error.response.data.Data?.TotalDiscount,
            SubTotalPrice: error.response.data.Data?.SubTotalPrice,
          });
          setPayment({
            totalprice: error.response.data.Data?.totalprice,
            Prepayment: error.response.data.Data?.Prepayment,
          });
          setReceive({
            GrandTotalPrice: error.response.data.Data?.GrandTotalPrice,
            Prepayment: error.response.data.Data?.Prepayment,
          });
        });
      }
    }
  };
  const sendDataForCalcAsync = async (newPriceList) => {
    setDateLoading(false);
    let SubTotalPrice: any = 0;
    let newsalesOrderDetail = JSON.parse(JSON.stringify(salesOrderDetail)).map(
      (e: any) => {
        SubTotalPrice = +e.TotalPrice + SubTotalPrice;

        return e;
      }
    );

    let data = {
      RecipientPersonDescription: 
       person?.Name
        ?? null,
      RecipientPersonId:  person?.Id
        ?? null,
      CurrencyId: !OrderConstraint.IsFiscal
        ? undefined
        : newPriceList?.CurrencyId,
      SalesOrderNo: OrderRef.current.salesOrderNo.value,
      SalesOrderDate: salesOrderDate,
      PriceListId: newPriceList
        ? newPriceList.Id
        : OrderRef.current?.PriceList?.value,
      PaymentMethodId: !OrderConstraint.IsFiscal
        ? undefined
        : OrderRef.current.PaymentMethodId?.value,
      CustomerId: customerInfo?.Id,
      CustomerName: customer?.Name,
      CustomerPhone: customer?.CustomerPhone?.Name.split(":")[1]
        ? customer?.CustomerPhone?.Name.split(":")[1]
        : customer?.CustomerPhone?.Name ?? null,
      CustomerMobilePhone: customer?.CustomerMobilePhone?.Name.split(":")[1]
        ? customer?.CustomerMobilePhone?.Name.split(":")[1]
        : customer?.CustomerMobilePhone?.Name ?? null,
      CustomerAddress: OrderRef.current.Address?.value ?? null,
      CustomerEconNo: OrderRef.current.CustomerEconNo?.value,
      CustomerNationalCode: OrderRef.current.CustomerNationalCode?.value,
      DeliveryAddress: OrderRef.current.DeliveryAddress?.value,
      Description: OrderRef.current.Description?.value,
      IsCurrency: !OrderConstraint.IsFiscal ? undefined : !currency.IsDefault,
      CurrencyRate: !OrderConstraint.IsFiscal
        ? undefined
        : currency.CurrencyRate,
      TotalDiscount: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows.TotalDiscount,
      TotalPrice: !OrderConstraint.IsFiscal ? undefined : +payment.totalprice,
      TotalTax:
        !OrderConstraint.IsFiscal || !OrderConstraint.HasTax
          ? 0
          : +totalRows.TotalTax,
      GrandTotalPrice: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows?.SubTotalPrice -
          +totalRows?.TotalDiscount +
          +totalRows?.TotalTax,
      Prepayment: !OrderConstraint.IsFiscal
        ? undefined
        : +OrderRef.current.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: !OrderConstraint.IsFiscal
        ? undefined
        : +totalRows?.SubTotalPrice,
      SalesOrderDetailDTOList: newsalesOrderDetail,
      IsFiscal: OrderConstraint.IsFiscal,
      Id: response?.Id,
      SalesOrderStatusId: salesOrderStatusId,
      JobId: jobId,
      JobNo: response?.JobNo,
    };
    try {
      if (mode !== "view" && newPriceList.Id != priceList.Id) {
        const res = await CalculateSalesOrder(data);
        setSalesOrderNo(res.Data?.SalesOrderNo);
        setResponse(res.Data);
        setSalesOrderDetail(res.Data?.SalesOrderDetailDTOList);
        let newItemCount = 0;
        res.Data?.SalesOrderDetailDTOList.map((item) => {
          newItemCount += item.Qty;
        });
        setTotalRows({
          ItemCount: newItemCount,
          TotalTax: OrderConstraint.HasTax ? res.Data?.TotalTax : 0,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });
        setPayment({
          totalprice: res.Data?.totalprice,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
        });
      }
    } catch (error) {}
    setPriceList(newPriceList);
    setDateLoading(true);
  };

  async function getDefaultPersonContactAsync(personId) {
    try {
      const res2 = await getPersonCustomer(personId);
      setCustomer((pre) => {
        return {
          ...pre,
          Id: res2.Data.Customer.Id,
          Name: res2.Data.Customer.CustomerPersonDescription,
          SundryCustomer:
            res2.Data.Customer.Id == OrderConstraint?.SundryCustomerId,
        };
      });
      if (res2.Data.Customer.Id == OrderConstraint?.SundryCustomerId) {
        setCustomerInfo({ ...res2?.Data?.Customer });
      } else {
        if (mode != "view") {
          const res = await getDefaultPersonContact(
            res2?.Data?.Customer?.CustomerPersonId
          );
          if (res?.MessageType == MessageType.error && res2?.Data == null) {
          } else setCustomerInfo({ ...res.Data, ...res2?.Data?.Customer });
        } else setCustomerInfo({ ...res2?.Data?.Customer });
      }
    } catch (error) {}
  }

  async function getOrderConstraintAsync(withLoading = false) {
    if (withLoading) setLoading(false);
    try {
      const res = await getOrderConstraint();
      setSalesOrderNo(res.Data.DocNo);
      setOrderConstraint(res.Data);
      setRequiredFields(JSON.parse(res.Data.RequiredFields));
      if (withLoading) setLoading(true);
      return res.Data;
    } catch (error) {}
    if (withLoading) setLoading(true);
  }

  async function getOrderAsync() {
    setLoading(false);

    try {
      const constraint = await getOrderConstraintAsync();
      let res: any;
      if (referenceId) {
        res = await getSalesOrderByReferenceId(referenceId);
        // res = await getSalesOrderById(rowObject ? rowObject.Id : jobId);
      } else {
        res = await getSalesOrderById(rowObject ? rowObject.Id : jobId);
      }
      setSalesOrderNo(res.Data.SalesOrderNo);
      setResponse(res.Data);
      let count = 0;
      res.Data.SalesOrderDetailDTOList.map((item) => {
        count += +item.Qty;
      });
      setSalesOrderDetail(res.Data.SalesOrderDetailDTOList);
      setTotalRows({
        ItemCount: count,
        TotalTax: constraint.HasTax ? res.Data.TotalTax : 0,
        TotalDiscount: res.Data.TotalDiscount,
        SubTotalPrice: res.Data.SubTotalPrice,
      });
      setPayment({
        totalprice: res.Data.totalprice,
        Prepayment: res.Data.Prepayment,
      });
      setReceive({
        GrandTotalPrice: res.Data.GrandTotalPrice,
        Prepayment: res.Data.Prepayment,
      });
    } catch (error) {}
    setLoading(true);
  }

  const handlesetOrderDetail = (newObj) => {
    if (OrderConstraint.AllowDuplicateItems || detailMode == "edit") {
      setTotalRows({
        ItemCount:
          detailMode == "create"
            ? totalRows.ItemCount + +newObj.Qty
            : totalRows.ItemCount + (+newObj.Qty - +selectedObj.Qty),
        TotalTax:
          detailMode == "create"
            ? totalRows.TotalTax + +newObj.Tax
            : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
        TotalDiscount:
          detailMode == "create"
            ? totalRows.TotalDiscount + +newObj.Discount
            : totalRows.TotalDiscount +
              (+newObj.Discount - +selectedObj.Discount),
        SubTotalPrice:
          detailMode == "create"
            ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
            : totalRows.SubTotalPrice +
              (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
      });
      if (detailMode == "create") {
        setSalesOrderDetailHandler([...salesOrderDetail, newObj]);
      } else {
        let newItemes = salesOrderDetail.map((item: any) => {
          if (item.Id == newObj.Id) {
            return newObj;
          } else return item;
        });
        setSalesOrderDetailHandler(newItemes);
      }
      Toast("msg_saveddatasuccessfully", "success");
      setDataChange(true);
      return true;
    } else {
      let Dupli = salesOrderDetail.find((e: any) => e.ItemId == newObj.ItemId);
      if (!Dupli || salesOrderDetail.length == 0) {
        setTotalRows({
          ItemCount:
            detailMode == "create"
              ? totalRows.ItemCount + +newObj.Qty
              : totalRows.ItemCount + (+newObj.Qty - +selectedObj.Qty),
          TotalTax:
            detailMode == "create"
              ? totalRows.TotalTax + +newObj.Tax
              : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
          TotalDiscount:
            detailMode == "create"
              ? totalRows.TotalDiscount + +newObj.Discount
              : totalRows.TotalDiscount +
                (+newObj.Discount - +selectedObj.Discount),
          SubTotalPrice:
            detailMode == "create"
              ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
              : totalRows.SubTotalPrice +
                (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
        });
        if (detailMode == "create") {
          setSalesOrderDetailHandler([...salesOrderDetail, newObj]);
        } else {
          let newItemes = salesOrderDetail.map((item: any) => {
            if (item.Id == newObj.Id) {
              return newObj;
            } else return item;
          });
          setSalesOrderDetailHandler(newItemes);
        }
        Toast("msg_saveddatasuccessfully", "success");
        setDataChange(true);
        return true;
      } else {
        Toast("msg_duplicateitems", "error");
        return false;
      }
    }
  };
  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    let newPriceListDetail: any = [...salesOrderDetail];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setTotalRows({
      ItemCount: totalRows.ItemCount - +obj.Qty,
      TotalTax: totalRows.TotalTax - +obj.Tax,
      TotalDiscount: totalRows.TotalDiscount - +obj.Discount,
      SubTotalPrice: totalRows.SubTotalPrice - +obj.SubTotalPrice,
    });
    setSalesOrderDetailHandler(newPriceListDetail.filter((e) => e.Id != id));
  };

  const handleOpenDetailForm = () => {
    if (priceList || !OrderConstraint.IsFiscal) {
      if (customerInfo?.CustomerPersonId || customer?.SundryCustomer) {
        setDetailMode("create");
        setShowForm(true);
      } else {
        Toast("msg_customernotselected", "error");
      }
    } else {
      Toast("msg_choosepricelist", "error");
    }
  };

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (mode != "create") {
      getOrderAsync();
    } else {
      getOrderConstraintAsync(true);
    }
  }, []);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(() => {
    setPayment({
      ...payment,
      totalprice: +totalRows.SubTotalPrice - +totalRows.TotalDiscount,
    });
  }, [totalRows]);

  useEffect(() => {
    setReceive({
      ...receive,
      GrandTotalPrice: +payment.totalprice + +totalRows.TotalTax,
    });
  }, [payment]);

  const kydown = (event) => {
    if (event.key == "Insert") {
      event.stopPropagation();
      event.preventDefault();
      handleOpenDetailForm();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", kydown, true);
    return () => {
      document.removeEventListener("keydown", kydown, true);
    };
  }, [customer, OrderConstraint, priceList]);

  return (
    <div className=" ">
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesOrderDetailForm"}
        >
          <SalesOrderDetailForm
            salesWarehouse={OrderConstraint?.EcommerceWarehouseId}
            setLastWarehouse={setLastWarehouse}
            lastWarehouse={lastWarehouse}
            mode={detailMode}
            onHide={() => {
              setShowForm(false);
            }}
            priceList={OrderConstraint.IsFiscal ? priceList : null}
            OrderConstraint={OrderConstraint}
            selectedObj={selectedObj}
            SalesOrderDetailCallBack={(e) => handlesetOrderDetail(e)}
            personId={customerInfo?.CustomerPersonId}
            currency={currency}
            salesOrderId={response?.Id}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showCustomerPhoneForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            type={typePhoneContact}
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
            handlePhone={(e) => {
              typePhoneContact == ContactType.Phone
                ? setSelectedPhoneDetail({ ...selectedPhoneDetail, phone: e })
                : setSelectedPhoneDetail({ ...selectedPhoneDetail, mobile: e });
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showAddressBox ? (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            personId={
              response?.CustomerPersonId
                ? response.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setChangeAddress(JSON.stringify(e).toLowerCase());
              setCustomerInfo({
                ...customerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showCustomerform ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerform(false);
          }}
          show={showCustomerform}
          title={"Customer"}
        >
          <CustomerTabform
            setCustomerCallBack={(e) => {
              setResponse({ ...response, CustomerId: e });
            }}
            selectedObj={customer}
            mode={mode}
            onHide={() => {
              setShowCustomerform(false);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {loading ? (
        <form onSubmit={handleSalesOrderDetailFormSubmit} ref={OrderRef}>
          <div className="col-12 mb-3 mt-1">
            <div className="d-flex justify-content-end">
              {mode != "view" ? (
                <div className="col-lg-12  btns_form">
                  <Button
                    disabled={salesOrderDetail?.length == 0}
                    value="save"
                    btnType="submit"
                  />
                  {handleShowReferenceStepper &&
                    (step == 1 || mode == "create") && (
                      <Button
                        disabled={salesOrderDetail?.length == 0}
                        btnType="submit"
                        value="SaveAndReference"
                        onClick={() => setShowReferenceStepper(true)}
                      />
                    )}
                  {!jobId && (
                    <Button
                      btnType="cancel"
                      value="Cancel"
                      onClick={() => {
                        cancel();
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form">
                  {!jobId && (
                    <Button
                      btnType="primary"
                      value="close"
                      onClick={() => {
                        cancel();
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <FormWithTitle
            title={trans("SalesOrder")}
            defualtClose={true}
            showFristRow={true}
            closeOption={true}
            dynamicTitle={{
              CustomerName: customer?.Name,
              number: salesOrderNo,
            }}
          >
            <div className="card-body pt-0">
              <div className="row ">
                <div className="col-lg-1 col-11">
                  <Textbox
                    textboxType="text"
                    textboxName="salesOrderNo"
                    labelText="number"
                    defaultValue={
                      response ? response.SalesOrderNo : OrderConstraint.DocNo
                    }
                    resetForm={resetForm}
                    readonly={!OrderConstraint.IsDocNoEditable}
                    onChange={(e) => {
                      setSalesOrderNo(e.target.value);
                    }}
                    mode={mode}
                  />
                </div>
                {dateLoading && (
                  <div className="col-lg-1 col-11">
                    <Datepicker
                      mode={OrderConstraint.IsNewDocCurrentDate ? "view" : mode}
                      lable="Date"
                      setIsoTime={(isoTime: string) => {
                        setSalesOrderDate(isoTime);
                      }}
                      required={true}
                      val={
                        response
                          ? response.SalesOrderDate
                          : OrderConstraint.IsNewDocCurrentDate
                          ? todayDate
                          : null
                      }
                      errorFlag={false}
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                    />
                  </div>
                )}
                <div className="col-lg-2 row col-11">
                  <CustomerDictionary
                    checkHasAccess={() => setNoAccess(true)}
                    name="CustomerName"
                    label="CustomerName"
                    resetForm={resetForm}
                    value={response?.CustomerId}
                    ShowMethod={OrderConstraint?.ChangeDocCustomer}
                    CustomerName={response?.CustomerName}
                    mode={
                      OrderConstraint?.CustomerDictionaryShowMethod ==
                      CustomerDictionaryShowMethod.OnlyCustomer
                        ? "view"
                        : mode
                    }
                    returnObj={true}
                    required={true}
                    onChange={(e) => {
                      if (e.Id) {
                        setCustomer((prev) => {
                          return { ...e, ...prev };
                        });
                        getDefaultPersonContactAsync(e.Id);
                      } else {
                        setCustomerInfo(null);
                        setCustomer(null);
                      }
                    }}
                  />
                  {mode == "create" && !noAccess && (
                    <div
                      style={{
                        marginTop: "25px",
                        width: "1%",
                        marginRight: "-25px",
                      }}
                    >
                      <Button
                        Icon={true}
                        value={
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCustomerform(true);
                            }}
                            className="checkButton d-flex align-items-center justify-content-center"
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={[ICON_TYPE, "plus"]}
                              size="2x"
                            />
                          </button>
                        }
                        btnType="primary"
                      />
                    </div>
                  )}
                </div>
                {OrderConstraint.IsFiscal && (
                  <>
                    <div className="col-lg-2 col-11 me-lg-4">
                      <PriceListDictionary
                        name="PriceList"
                        label="PriceList"
                        resetForm={resetForm}
                        value={
                          OrderConstraint.UseCustomerPriceList &&
                          customerInfo?.PriceListId
                            ? customerInfo.PriceListId
                            : priceList == null ||
                              !priceList ||
                              priceList.Id == GUID_EMPTY
                            ? response?.PriceListId
                            : priceList.Id
                        }
                        mode={
                          OrderConstraint.UseCustomerPriceList &&
                          customerInfo?.PriceListId
                            ? "view"
                            : mode
                        }
                        onChange={(e) => {
                          !!salesOrderDetail.length &&
                          e.Id != GUID_EMPTY &&
                          response?.PriceListId != e.Id
                            ? sendDataForCalcAsync(e)
                            : setPriceList(e);
                        }}
                      />
                    </div>
                    <div className="col-lg-1 col-11 ">
                      <CurrencyDictionary
                        name="CurrencyId"
                        label="Currency"
                        onChange={(e) => {
                          setCurrency(e);
                        }}
                        resetForm={resetForm}
                        value={
                          priceList?.CurrencyId
                            ? priceList.CurrencyId
                            : response?.CurrencyId
                        }
                        mode={priceList?.CurrencyId ? "view" : mode}
                        required={true}
                      />
                    </div>
                  </>
                )}

                <div className="col-lg-2 col-11 me-lg-4">
                  <Textbox
                    required={requiredFields.CustomerEconNo}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CustomerEconNo"
                    labelText="CustomerEconNo"
                    defaultValue={
                      response?.CustomerEconNo
                        ? response.CustomerEconNo
                        : customerInfo?.EconNo
                    }
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>

                {/* {mode=='create' && <div className="col-lg-2 col-11 d-flex align-items-center mb-1 mb-lg-0">
                <CheckableButton
                                Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                defaultChecked={SendSMS}
                                defaultValue={SendSMS}
                                readOnly={mode==='view'}
                                checked={SendSMS}
                                onchange={setSendSMS}
                                labelText={"NotifyBySMS"}
                                inputName={"NotifyBySMS"}
                                type="checkbox"
                                mode={mode}
                                />
              </div>} */}

                {!customer?.SundryCustomer ? (
                  <div className="col-11 col-lg-2 d-flex">
                    <div className="flex-grow-1">
                      <CustomerPhoneDictionary
                        required={requiredFields.CustomerPhone}
                        type={ContactType.Phone}
                        name="CustomerPhone"
                        label="CustomerPhone"
                        resetForm={resetForm}
                        valueString={
                          response?.CustomerPhone || mode !== "create"
                            ? response?.CustomerPhone
                            : selectedPhoneDetail.phone.Value ??
                              customerInfo?.PhoneNumber
                        }
                        mode={mode}
                        returnObj={true}
                        personId={
                          response?.CustomerPersonId
                            ? response?.CustomerPersonId
                            : customerInfo?.CustomerPersonId
                        }
                        onChange={(e) => {
                          setCustomer((prev) => {
                            return { ...prev, CustomerPhone: e };
                          });
                        }}
                      />
                    </div>

                    {mode != "view" && (
                      <div
                        style={{
                          marginTop: "25px",
                          width: "1%",
                          // marginRight: "-25px",
                        }}
                        className="ms-4"
                      >
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowCustomerPhoneForm(true);
                                setTypePhonContact(ContactType.Phone);
                              }}
                              className="checkButton d-flex align-items-center justify-content-center"
                            >
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "plus"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerPhone"
                      labelText="CustomerPhone"
                      defaultValue={
                        response?.CustomerPhone ? response?.CustomerPhone : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={requiredFields.CustomerPhone}
                    />
                  </div>
                )}

                {!customer?.SundryCustomer ? (
                  <div className="col-11 col-lg-2 d-flex">
                    <div className="flex-grow-1">
                      <CustomerPhoneDictionary
                        required={requiredFields.CustomerMobilePhone}
                        type={ContactType.Mobile}
                        name="CustomerMobilePhone"
                        label="CustomerMobilePhone"
                        resetForm={resetForm}
                        valueString={
                          response?.CustomerMobilePhone || mode !== "create"
                            ? response.CustomerMobilePhone
                            : selectedPhoneDetail.mobile?.Value ??
                              customerInfo?.MobilePhone
                        }
                        mode={mode}
                        returnObj={true}
                        personId={
                          response?.CustomerPersonId
                            ? response.CustomerPersonId
                            : customerInfo?.CustomerPersonId
                        }
                        onChange={(e) => {
                          setCustomer((prev) => {
                            return { ...prev, CustomerMobilePhone: e };
                          });
                        }}
                        Change={() => {}}
                      />
                    </div>
                    {mode != "view" && (
                      <div
                        style={{
                          marginTop: "25px",
                          width: "1%",
                          // marginRight: "-25px",
                        }}
                        className="ms-4"
                      >
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowCustomerPhoneForm(true);
                                setTypePhonContact(ContactType.Mobile);
                              }}
                              className="checkButton d-flex align-items-center justify-content-center"
                            >
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "plus"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerMobilePhone"
                      labelText="CustomerMobilePhone"
                      defaultValue={
                        response?.CustomerMobilePhone
                          ? response.CustomerMobilePhone
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={requiredFields.CustomerMobilePhone}
                    />
                  </div>
                )}

                <div className="col-lg-3 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="CustomerNationalCode"
                    textboxType="nationalCode"
                    labelText="CustomerNationalCode"
                    defaultValue={
                      response?.CustomerNationalCode
                        ? response.CustomerNationalCode
                        : customerInfo?.NationalId?.trim()
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={requiredFields.CustomerNationalCode}
                  />
                </div>

          
                  <div className="col-lg-3 col-11">
                    <Person
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      value={
                        response?.RecipientPersonId
                          ? response.RecipientPersonId
                          : customerInfo?.RecipientPersonId
                      }
                      resetForm={resetForm}
                      mode={mode}
                      WithSearch={false}
                      setPerson={setPerson}
                      returnObj={true}
                      name={"RecipientPersonDescription"}
                      label="RecipientPerson"
                      required={requiredFields.RecipientPersonId}
                    />
                  </div>
            
                {mode == "edit" && (
                  <div className="col-3">
                    <DictionarySelectBox
                      Change={(e) => {
                        setSalesOrderStatusId(e.salesOrderStatus);
                      }}
                      onChange={(id, obj) => {
                        setSalesOrderStatusId(obj.Id);
                      }}
                      required={true}
                      mode={"edit"}
                      baseUrl={INVENTORYANDSALES_URL}
                      endPoint={
                        ENDPOINTSINVENTORYANDSALES.Sales
                          .BaseDefinitionAndSetting.SalesOrderStatus.dictionary
                      }
                      label="salesOrderStatus"
                      name="salesOrderStatus"
                      value={response?.SalesOrderStatusId}
                    />
                  </div>
                )}
                {!customer?.SundryCustomer ? (
                  <div className="col-lg-5 col-11 d-flex ">
                    <div className="flex-grow-1">
                      <Address
                        onChange={(e) => {
                          setShowAddressBox("selectMode");
                          return false;
                        }}
                        withMap={false}
                        hideDetail={true}
                        personContact={
                          changeAddress
                            ? convertAddress(changeAddress)
                            : response?.CustomerAddress
                            ? response?.CustomerAddress
                            : convertAddress(customerInfo?.Address)
                        }
                        mode={"view"}
                        required={requiredFields.CustomerAddress}
                      />
                    </div>

                    {mode != "view" && (
                      <>
                        <div
                          style={{
                            marginTop: "25px",
                            width: "1%",
                            // marginRight: "-25px",
                          }}
                          className="ms-4"
                        >
                          <Button
                            Icon={true}
                            value={
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowAddressBox("createMode");
                                }}
                                className="checkButton d-flex align-items-center justify-content-center"
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={[ICON_TYPE, "plus"]}
                                  size="2x"
                                />
                              </button>
                            }
                            btnType="primary"
                          />
                        </div>
                        {mode !== "view" && (
                          <div
                            style={{
                              marginTop: "25px",
                              width: "1%",
                              // marginRight: "5px",
                              marginLeft: "20px",
                            }}
                            className="ms-4"
                          >
                            <Button
                              Icon={true}
                              value={
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddressBox("selectMode");
                                  }}
                                  className="checkButton d-flex align-items-center justify-content-center"
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={[ICON_TYPE, "search"]}
                                    size="2x"
                                  />
                                </button>
                              }
                              btnType="primary"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="col-lg-5 col-11 d-flex ">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Address"
                      textboxType="text"
                      labelText="Address"
                      defaultValue={
                        response?.CustomerAddress
                          ? response.CustomerAddress
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={requiredFields.CustomerAddress}
                    />
                  </div>
                )}
                <div className="col-lg-3 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="DeliveryAddress"
                    textboxType="text"
                    labelText="DeliveryAddress"
                    defaultValue={
                      response?.DeliveryAddress ? response.DeliveryAddress : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={requiredFields.DeliveryAddress}
                  />
                </div>
                <div className="col-lg-5 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="Description"
                    textboxType="textarea"
                    labelText="Description"
                    minHeight={1}
                    defaultValue={response?.Description}
                    resetForm={resetForm}
                    mode={mode}
                    maxLength={900}
                    letterCounter={true}
                    required={requiredFields.Description}
                  />
                </div>
              </div>
            </div>
          </FormWithTitle>
          <div className="mt-3">
            <FormWithTitle title={trans("Details")}>
              <div>
                <div className="row justify-content-between">
                  {mode != "view" && (
                    <div
                      onClick={handleOpenDetailForm}
                      className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                      style={{
                        width: "70px",
                        maxHeight: "30px",
                        marginRight: "20px",
                        minWidth: "70px",
                      }}
                    >
                      <>
                        <span className=" font-size-md ms-2 ">
                          {trans("add")}
                        </span>
                        <FontAwesomeIcon
                          size="xs"
                          icon={["fas", "plus"]}
                          className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                          color="white"
                        />
                      </>
                    </div>
                  )}
                  <div
                    className={`${
                      OrderConstraint.IsFiscal
                        ? mode != "view"
                          ? "col-10"
                          : "col-12 "
                        : "col-3"
                    } row `}
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <div
                        className={`${
                          mode != "view" ? "col-7" : "col-12"
                        }  row justify-content-end mb-1`}
                      >
                        <Headerbar
                          withPading={false}
                          headerContents={
                            OrderConstraint.IsFiscal
                              ? totalRows
                              : { ItemCount: totalRows.ItemCount }
                          }
                          hasBorder={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {dataGridLoading ? (
                  <>
                    {(
                      mode == "view"
                        ? salesOrderDetail.length > 0
                        : OrderConstraint.DetailDTO
                    ) ? (
                      <>
                        <DataGrid
                          useLanguage={true}
                          convertDateColNames={["ExpireDate"]}
                          DoubleClick={(e) => {
                            setSelectedObj(e);
                            setShowForm(true);
                            setDetailMode("view");
                          }}
                          parentName={"SalesOrderDetailList"}
                          startIndex={13}
                          theadData={
                            mode !== "view"
                              ? Object.keys(OrderConstraint.DetailDTO)
                              : Object.keys(salesOrderDetail[0])
                          }
                          tbodyData={
                            salesOrderDetail?.length > 0
                              ? salesOrderDetail
                              : [OrderConstraint.DetailDTO]
                          }
                          totalRecords={
                            salesOrderDetail?.length > 0 ? undefined : 0
                          }
                          scrollHeight={"67vh"}
                          minCellWidth={80}
                          selectedRowObjectCallback={selectedObjCallback}
                          handleEdit={
                            mode != "view"
                              ? (e) => {
                                  setSelectedObj(e);
                                  setDetailMode("edit");
                                  setShowForm(true);
                                }
                              : undefined
                          }
                          rowSelected={selectedObj}
                          handleDelete={
                            mode != "view"
                              ? (e) => {
                                  Toast(
                                    trans("msg_deleteconfirm"),
                                    "warning",
                                    "alert",
                                    () => handleDeleteRow(e)
                                  );
                                }
                              : undefined
                          }
                          handleView={(e) => {
                            setSelectedObj(e);
                            setDetailMode("view");
                            setShowForm(true);
                          }}
                        />
                      </>
                    ) : (
                      <Message message={"NoDataForPreview"} type={"info"} />
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </FormWithTitle>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="row">
                {OrderConstraint.IsFiscal && (
                  <>
                    <div className="col-lg-2 ">
                      <Textbox
                        useGroupingPrice={true}
                        textboxName="totalprice"
                        textboxType="text"
                        labelText="totalprice"
                        decimals={currency?.PriceRoundingPlace}
                        round={OrderConstraint.FloorTaxDuty}
                        defaultValue={
                          payment.totalprice != 0
                            ? payment.totalprice + ""
                            : response?.TotalPrice
                            ? response?.TotalPrice + ""
                            : payment.totalprice
                        }
                        resetForm={resetForm}
                        mode={"view"}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Textbox
                        useGroupingPrice={true}
                        textboxName="GrandTotalPrice"
                        decimals={currency?.PriceRoundingPlace}
                        round={OrderConstraint.FloorTaxDuty}
                        textboxType="text"
                        labelText="GrandTotalPrice"
                        defaultValue={
                          receive?.GrandTotalPrice != 0
                            ? receive?.GrandTotalPrice + ""
                            : response?.GrandTotalPrice
                            ? response?.GrandTotalPrice + ""
                            : receive?.GrandTotalPrice
                        }
                        resetForm={resetForm}
                        mode={"view"}
                      />
                    </div>

                    <div className="col-lg-2">
                      <Textbox
                        textboxName="Prepayment"
                        textboxType="text"
                        labelText="Prepayment"
                        useGroupingPrice={true}
                        defaultValue={
                          receive?.Prepayment != 0
                            ? receive?.Prepayment + ""
                            : response?.Prepayment
                            ? response?.Prepayment + ""
                            : receive?.Prepayment
                        }
                        onChange={(e) => {
                          setReceive({
                            ...receive,
                            Prepayment: e.target.value.replaceAll(",", ""),
                          });
                        }}
                        resetForm={resetForm}
                        mode={mode}
                      />
                    </div>

                    <div
                      className={
                        OrderConstraint.IsFiscal ? "col-lg-1" : "col-lg-2"
                      }
                    >
                      <DictionarySelectBox
                        label="PaymentMethod"
                        name="PaymentMethodId"
                        baseUrl={INVENTORYANDSALES_URL}
                        endPoint={
                          ENDPOINTSINVENTORYANDSALES.Sales
                            .BaseDefinitionAndSetting.PaymentMethod.dictionary
                        }
                        resetForm={resetForm}
                        value={response?.PaymentMethodId}
                        mode={mode}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
