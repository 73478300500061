import { Loading } from "components/Loading/loading";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { trans } from "utils/helpers";
import { ItemMainPropertyVariation } from "../../../components/ItemMainPropertyVariation/ItemMainPropertyVariation";
import { Toast } from "components/ToastShow/ToastShow";


interface MainFormPropsType {
    id?: any,
    mode?: any,
    inTab?: any,
    formRef?: any,
    data?:any;
    checkData: any;

    submitMain?: (data) => void;

}

export const MainForm = ({
    id,
    mode,
    inTab,
    formRef,
    data,
    checkData,
    submitMain,
 
  
}: MainFormPropsType) => {


    const [resetForm, setResetForm] = useState(false);
    const [selectedItemMainPropertyValues, setSelectedItemMainPropertyValues] = useState<any>();
    const [descriptions, setDescriptions] = useState<any>({Description: checkData.Description , DescriptionEN:checkData.DescriptionEN});

    async function formSubmit(event) {
        event.preventDefault();    
             let data ={
                Code: (checkData.ItemMainPropertyId? checkData?.ParentCode :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","") ,
                 TechnicalCode : event.target.TechnicalCode.value,
                 "Description": checkData.Description ,
                 "DescriptionEN":checkData.DescriptionEN,
                 SummaryDescription :checkData.ItemMainPropertyId?"": event.target.SummaryDescription.value,
                 SummaryDescriptionEN :checkData.ItemMainPropertyId?"": event.target.SummaryDescriptionEN.value,
                 "SelectedItemMainProperty": checkData.ItemMainPropertyId,
                 "SelectedItemMainPropertyValues": selectedItemMainPropertyValues ? selectedItemMainPropertyValues.map(item=>(item.Id)):null

             }
             if(checkData.ItemMainPropertyId&& (!selectedItemMainPropertyValues ||selectedItemMainPropertyValues?.length==0)){
                Toast(trans(`msg_validation_valueisrequired`) , "error")
              }else{
                  submitMain&&  submitMain(data)
              }
    }

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }

    }, [resetForm]);

    return (

        <form className="Main-form py-2" id={"ItemForm"} name={"main"} ref={formRef} onSubmit={formSubmit}>
            <div className="card">
            { (mode != "create" && data)||(mode == "create") ?
                <div className="card-body">
                    <div className="row">
                        {(!checkData.ItemMainPropertyId||mode=="view")?
                        <>
                        <div className="col-2">
                            <Textbox
                                textboxType="number"
                                textboxName="Code"
                                resetForm={resetForm}
                                required={true}
                                UsepadWithZeros={true}
                                maxLength={+checkData.CodeLength}
                                fixedlabel={checkData ? checkData?.ParentCode+"":checkData?.ParentCode}
                                defaultValue={data?.Code ? data.Code.replace(checkData?.ParentCode,"") : checkData?.Code}
                                labelText={trans("itemcode") + ( mode !== "create" ? "" : `  (${"طول مجاز"} : ${checkData.CodeLength})`)}
                                mode={checkData.ItemMainPropertyId?"view" :  mode}
                                />
                        </div>                     
                        <div className="col-2">
                                <Textbox
                                    textboxType="text"
                                    textboxName="TechnicalCode"
                                    resetForm={resetForm}
                                    defaultValue={data?.TechnicalCode ? data.TechnicalCode : null}
                                    labelText="TechnicalCode"
                                    mode={mode}
                                    />
                            </div>
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,summaryDescription:e })}}                       
                                textboxType="text"
                                textboxName="SummaryDescription"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescription ? data.SummaryDescription : !checkData.ItemMainPropertyId ?checkData?.ItemMainPropertyDescription :null }
                                labelText="itemSummaryDescription"
                                required={!checkData.ItemMainPropertyId }
                                mode={mode}
                                />
                        </div>
{/*                         <div className="col-4">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,Description:e })}}
                                required={!checkData.ItemMainPropertyId }
                                textboxType="text"
                                textboxName="Description"
                                resetForm={resetForm}
                                defaultValue={ data?.Description  ? data?.Description : ((descriptions.Description ? descriptions.Description: descriptions.summaryDescription))}
                                labelText="itemDescription"
                                mode={mode}
                            />
                        </div> */}


{/*                         <div className="col-3">
                            <Textbox
                                 onBlurHandler={(e)=>{setDescriptions({...descriptions ,DescriptionEN:e })}}
                                textboxType="text"
                                textboxName="DescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.DescriptionEN ? data.DescriptionEN : null}
                                labelText="itemDescriptionEN"
                                mode={mode}
                                />
                        </div> */}
                        <div className="col-3">
                            <Textbox
                                onBlurHandler={(e)=>{setDescriptions({...descriptions ,SummaryDescriptionEN:e })}}
                                textboxType="text"
                                textboxName="SummaryDescriptionEN"
                                resetForm={resetForm}
                                defaultValue={data?.SummaryDescriptionEN ? data?.SummaryDescriptionEN :  (descriptions.SummaryDescriptionEN ? descriptions.SummaryDescriptionEN: descriptions.DescriptionEN)}
                                labelText="itemSummaryDescriptionEN"
                                mode={mode}
                                />
                        </div>
                        </>:<></>
                       }
                        </div>
                    
                    {checkData.ItemMainPropertyId ?
                        <ItemMainPropertyVariation SelectedItemMainPropertyValues={setSelectedItemMainPropertyValues} ItemMainPropertyId={checkData.ItemMainPropertyId}ItemMainPropertyDescription={checkData.ItemMainPropertyDescription}  />:<></>
                    }

                </div>
                :
                <Loading/>
                }
            </div>
            <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
 
          </button>
        </form>
    );
}