import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import { ContactType, MessageType } from "types/enum";
import { GUID_EMPTY, ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress, trans } from "utils/helpers";
import { CustomerTabform } from "../BaseDefinitionAndSetting/Customers/CustomerTabForm";
import { CurrencyDictionary } from "../PriceList/components/CurrencyDictionary/CurrencyDictionary";
import { PriceListDictionary } from "../PriceList/components/PriceListDictionary/PriceListDictionary";
import { AddressBox } from "../SalesInvoice/components/AddressBox/AddressBox";
import { CustomerDictionary } from "../SalesInvoice/components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "../SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "../SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import { DocsClassification } from "../SalesInvoice/components/DocsClassification/DocsClassification";
import { SalesReturnInvoiceDetailForm } from "./SalesReturnInvoiceDetailForm";
import { SalesReturnInvoiceDetailsList } from "./SalesReturnInvoiceDetailsList";
import {
  CalculateSalesInvoice,
  getDefaultPersonContact,
  getInvoiceReturnConstraint,
  getPersonCustomer,
  getSalesInvoiceOrGenerate,
  getSalesReturnInvoiceById,
  postSalesInvoiceWorkFlow,
  postSalesReturnInvoice,
  putSalesInvoice,
  putSalesInvoiceworkflow
} from "./api";
import { SalesReturnInvoiceSourceId } from "./components/SalesReturnInvoiceSourceId";
import { Person } from "components/Person/Person";

interface SalesReturnInvoiceForm {
  onHide?: () => void;
  relod: () => void;
  jobId?: any;
  processId?: any;
  referenceId?: any;
  mode: any;
  step?: any;
  rowObject?: any;
  taskId?: any;
  SourceData?: any;
  sourceDataParam?: any;
  sourceId?: any;
}

export const SalesReturnInvoiceForm = ({
  mode,
  relod,
  onHide,
  rowObject,
  referenceId,
  taskId,
  jobId,
  sourceDataParam,
  sourceId,
}: SalesReturnInvoiceForm) => {
  const [resetForm, setResetForm] = useState(false);
  const [haveSource, setHaveSource] = useState(null);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [listPosition, setListPosition] = useState<any>({});
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [salesReturnInvoiceDate, setReturnSalesInvoiceDate] = useState<any>();
  const [cottageDate, setCottageDate] = useState<any>();
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
  const [showCustomerform, setShowCustomerform] = useState<boolean>(false);
  const [priceList, setPriceList] = useState<any>(null);
  const [salesInvoiceDetails, setSalesInvoiceDetails] = useState<any>([]);
  const [dataGridLoading, setDataGridLoading] = useState<any>(true);
  const [lastWarehouse, setLastWarehouse] = useState<any>(null);
  const [returnConstraint, setReturnConstraint] = useState<any>({});
  const [docsClassification, setDocsClassification] = useState<any>(null);
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const [payment, setPayment] = useState<any>({
    totalprice: 0,
    Addition: 0,
    Deduction: 0,
    Prepayment: 0,
  });
  const [receive, setReceive] = useState<any>({
    GrandTotalPrice: 0,
    Prepayment: 0,
    DebitPayment: 0,
    NonDebitPayment: 0,
  });
  const [todayDate, settodayDate] = useState<string | null>(
    new Date().toISOString()
  );
  const [optionalDiscount, setOptionalDiscount] = useState<any>(null);
  const [dataChange, setDataChange] = useState<any>(false);
  const [detailMode, setDetailMode] = useState<"create" | "edit" | "view">(
    mode
  );

  const [increased, setIncreased] = useState<any>(false);
  const [currencyInfo, setCurrencyInfo] = useState<any>();
  const [changeAddress, setChangeAddress] = useState<any>();
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const [SendSMS, setSendSMS] = useState(false);
  const [selectedInvoiceDetails, setSelectedInvoiceDetails] = useState<any>();
  const [showEditForm, setShowEditForm] = useState<any>(false);
  const [salesReturnInvoice,setSalesReturnInvoice]=useState()
  const [person, setPerson] = useState<any>(null);
  const [originalQty, setOriginalQty] = useState({
    qty1: 0,
    qty2: 0,
  });
  const invoiceRef = useRef<any>(null);

  const lang = localStorage.getItem("lang");

  function setSalesInvoiceDetailHandler(data) {
    setSalesInvoiceDetails(data);
  }

  function selectedInvoiceDetailHandler(data) {
    setDataGridLoading(false);
    setSelectedInvoiceDetails(data);
    let newItemCount = 0;
    let newItemTax = 0;
    let newItemTotalDiscount = 0;
    let newItemTotalPrice = 0;

    data.map((item) => {
      newItemCount += +item.Qty1;
      newItemTax += +item.Tax;
      newItemTotalDiscount += +item.Discount;
      newItemTotalPrice += +item.SubTotalPrice;
    });
    setTotalRows({
      ItemCount: newItemCount,
      TotalTax: newItemTax,
      TotalDiscount: newItemTotalDiscount,
      SubTotalPrice: newItemTotalPrice,
    });
    setDataGridLoading(true);
  }

  const handleSalesInvoiceDetailFormSubmit = async (event) => {
    event.preventDefault();
    let SubTotalPrice: any = 0;
    let newsalesInvoiceDetail = JSON.parse(JSON.stringify(selectedInvoiceDetails))?.map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      delete e.Id;
      return e;
    });

    let data: any = {
      RecipientPersonDescription: person?.Name??null,
      RecipientPersonId:person?.Id??null,
      SalesInvoiceId: response?.SalesInvoiceId,
      CurrencyId: event.target.CurrencyId.value,
      SalesReturnInvoiceNo: event.target.SalesReturnInvoiceNo?.value,
      SalesReturnInvoiceDate: salesReturnInvoiceDate,
      DocsClassificationId: event.target.docsClassificationId.value,
      PriceListId: event.target.PriceList?.value,
      PaymentMethodId: event.target.PaymentMethodId?.value,
      CustomerId: customerInfo.Id,
      CustomerName: customer.Name,
      CustomerPhone: customer?.CustomerPhone?.Name.split(":")[1],
      CustomerMobilePhone: customer?.CustomerMobilePhone?.Name.split(":")[1],
      CustomerAddress: event.target.CustomerAddress?.value,
      CustomerEconNo: event.target.CustomerEconNo?.value,
      CustomerNationalCode: event.target.CustomerNationalCode?.value,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        event.target.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        event.target.VisitorPositionDescriptionEN?.value,
      DistributorPositionId: listPosition?.DistributorPosition?.Id,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        event.target.DistributorPositionDescriptionEN?.value,
      CottageDate: cottageDate,
      DeliveryAddress: event.target.DeliveryAddress?.value,
      CottageNo: event.target.CottageNo?.value,
      PaymentRemark: event.target.PaymentRemark?.value,
      IsCurrancyInvoice: event.target.IsCurrancyInvoice?.checked,
     // CurrencyPrepayment: +event.target.CurrencyPrepayment?.value,
      Description: event.target.Description?.value,
      SourceId: response?.SourceId,
      SourceData: response?.SourceData,
      IsCurrency: !currencyInfo?.IsDefault,
      CurrencyRate: currencyInfo?.CurrencyRate,
      Addition: +event.target.Addition?.value.replaceAll(",", ""),
      Deduction: +event.target.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: +totalRows?.TotalTax,
      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +event.target.Addition?.value.replaceAll(",", "") -
        +event.target.Deduction?.value.replaceAll(",", ""),
      DebitPayment: +event.target.DebitPayment?.value.replaceAll(",", ""),
      NonDebitPayment: +event.target.NonDebitPayment?.value.replaceAll(",", ""),
      CreditPayment: +event.target.CreditPayment?.value.replaceAll(",", ""),
      Prepayment: +event.target.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesReturnInvoiceDetailDTOList: newsalesInvoiceDetail,
    };
    try {
      if (mode == "create") {
        const res = await postSalesReturnInvoice(data, SendSMS);
        if (res.MessageType == MessageType.success) {
          onHide && onHide();
          relod();
        }
      } else {
        if (jobId) {
          if (response.Id == GUID_EMPTY) {
            const res = await postSalesInvoiceWorkFlow(
              {
                ...data,
                JobId: jobId,
                JobNo: response.JobNo,
                TAS_UID: taskId,
                Id: response.Id,
              },
              referenceId,
              increased,
              SendSMS
            );
            // getInvoiceAsync();
          } else {
            const res = await putSalesInvoiceworkflow(
              {
                ...data,
                JobId: jobId,
                JobNo: response.JobNo,
                TAS_UID: taskId,
                Id: response?.Id,
              },
              referenceId
            );
          }
        } else {
          const res = await putSalesInvoice({
            ...data,
            JobId: response.JobId,
            JobNo: response.JobNo,
            TAS_UID: taskId,
            Id: response?.Id,
          });
        }
        //  await   putSalesInvoiceDetail(data)
      }
    } catch (error) {}
    document.body.classList.remove("loading-indicator-" + lang);
  };

  const sendDataForCalcAsync = async (newPriceList) => {
    let SubTotalPrice: any = 0;
    let newsalesInvoiceDetail = JSON.parse(
      JSON.stringify(salesInvoiceDetails)
    ).map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      return e;
    });
    let data: any = {
      RecipientPersonDescription: person?.Name??null,
      RecipientPersonId: person?.Id??null,
      CurrencyId: invoiceRef.current.CurrencyId?.value,
      SalesInvoiceNo: invoiceRef.current.salesInvoiceNo?.value,
      SalesInvoiceDate: salesReturnInvoiceDate,
      DocsClassificationId: invoiceRef.current.docsClassificationId?.value,
      PriceListId: newPriceList ?newPriceList.Id  :invoiceRef.current?.PriceList?.value,
      PaymentMethodId: invoiceRef.current.PaymentMethodId?.value,
      CustomerId: customerInfo.Id,
      CustomerName: customer.Name,
      CustomerPhone: customer?.CustomerPhone?.Name.split(":")[1],
      CustomerMobilePhone: customer?.CustomerMobilePhone?.Name.split(":")[1],
      CustomerAddress: invoiceRef.current.CustomerAddress?.value,
      CustomerEconNo: invoiceRef.current.CustomerEconNo?.value,
      CustomerNationalCode: invoiceRef.current.CustomerNationalCode?.value,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        invoiceRef.current.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        invoiceRef.current.VisitorPositionDescriptionEN?.value,
      DistributorPositionId: listPosition?.DistributorPosition?.Id,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        invoiceRef.current.DistributorPositionDescriptionEN?.value,
      CottageDate: cottageDate,
      DeliveryAddress: invoiceRef.current.DeliveryAddress?.value,
      CottageNo: invoiceRef.current.CottageNo?.value,
      PaymentRemark: invoiceRef.current.PaymentRemark?.value,
      IsCurrancyInvoice: invoiceRef.current.IsCurrancyInvoice?.checked,
   //   CurrencyPrepayment: +invoiceRef.current.CurrencyPrepayment?.value,
      Description: invoiceRef.current.Description?.value,
      IsCurrency: false,
      CurrencyRate: 0,
      Addition: +invoiceRef.current.Addition?.value.replaceAll(",", ""),
      Deduction: +invoiceRef.current.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: +totalRows?.TotalTax,
      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +invoiceRef.current.Addition?.value.replaceAll(",", "") -
        +invoiceRef.current.Deduction?.value.replaceAll(",", ""),
      DebitPayment: +invoiceRef.current.DebitPayment?.value.replaceAll(",", ""),
      NonDebitPayment: +invoiceRef.current.NonDebitPayment?.value.replaceAll(
        ",",
        ""
      ),
      CreditPayment: +invoiceRef.current.CreditPayment?.value.replaceAll(
        ",",
        ""
      ),
      Prepayment: +invoiceRef.current.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesReturnInvoiceDetailDTOList: newsalesInvoiceDetail,
    };
    try {
      if (mode == "create") {
        const res = await CalculateSalesInvoice(data);
        setResponse(res.Data);

        setSalesInvoiceDetails(res.Data?.SalesReturnInvoiceDetailDTOList);
        let newItemCount = 0;

        setPayment({
          totalprice: res.Data?.totalprice,
          Addition: res.Data?.Addition,
          Deduction: res.Data?.Deduction,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
          DebitPayment: res.Data?.DebitPayment,
          NonDebitPayment: res.Data?.NonDebitPayment,
        });
      }
    } catch (error) {}
  };

  async function getDefaultPersonContactAsync(personId) {
    if (personId != customerInfo?.Id || customerInfo == null)
      try {
        const res2 = await getPersonCustomer(personId);
        const res = await getDefaultPersonContact(
          res2?.Data?.Customer?.CustomerPersonId
        );
        setCustomerInfo({ ...res.Data, ...res2?.Data?.Customer });
        setListPosition({
          DistributorPosition: {
            Id: res2?.Data?.Customer.DistributorPositionId,
            Name: res2?.Data?.Customer.DistributorPositionDescription,
          },
          VisitorPosition: {
            Id: res2?.Data?.Customer.VisitorPositionId,
            Name: res2?.Data?.Customer.VisitorPositionDescription,
          },
        });
      } catch (error) {}
  }
  async function getReturnInvoiceConstraintAsync(withLoading = false) {
    if (withLoading) setLoading(false);
    try {
      const res = await getInvoiceReturnConstraint();
      setReturnConstraint(res.Data);
      setSalesReturnInvoice(res.Data?.DocNo);

    } catch (error) {}
    if (withLoading) setLoading(true);
  }

  async function getInvoiceAsync() {
    setLoading(false);
    await getReturnInvoiceConstraintAsync();
    try {
      if (jobId) {
          const res = await getSalesInvoiceOrGenerate(
            sourceDataParam,
            sourceId,
            referenceId,
            jobId
          );
          setResponse(res.Data);
          let count = 0;
          res.Data.SalesReturnInvoiceDetailDTOList.map((item) => {
            count += +item?.Qty1;
          });
          setHaveSource(res.Data.sourceDataParam);
          // setSalesInvoiceDetails(res.Data.SalesInvoiceDetailDTOList);

          setPayment({
            totalprice: res.Data?.totalprice,
            Addition: res.Data?.Addition,
            Deduction: res.Data?.Deduction,
            Prepayment: res.Data?.Prepayment,
          });

          setReceive({
            GrandTotalPrice: res.Data?.GrandTotalPrice,
            Prepayment: res.Data?.Prepayment,
            DebitPayment: res.Data?.DebitPayment,
            NonDebitPayment: res.Data?.NonDebitPayment,
          });
        
      } else {
        const res = await getSalesReturnInvoiceById(rowObject.Id);
        setResponse(res.Data);

        let count = 0;
        res.Data.SalesReturnInvoiceDetailDTOList.map((item) => {
          count += +item?.Qty1;
        });
        setSelectedInvoiceDetails(res.Data.SalesReturnInvoiceDetailDTOList);
        //  setPayment({
        //   totalprice: response?.TotalPrice,
        //   Addition: response?.Addition,
        //   Deduction: response?.Deduction,
        //   Prepayment: response?.Prepayment,
        // });
        // setReceive({
        //   GrandTotalPrice: response?.GrandTotalPrice,
        //   Prepayment: response?.Prepayment,
        //   DebitPayment: response?.DebitPayment,
        //   NonDebitPayment: response?.NonDebitPayment,
        // });
      }
    } catch (error) {}
    setLoading(true);
  }

 

  const handlesetPriceListDetail = (newObj) => {
    setSelectedObj(newObj);
    setTotalRows({
      ItemCount: totalRows.ItemCount + (+newObj.Qty1 - +selectedObj.Qty1),
      TotalTax: totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
      TotalDiscount:
        totalRows.TotalDiscount + (+newObj.Discount - +selectedObj.Discount),
      SubTotalPrice:
        totalRows.SubTotalPrice +
        (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
    });

    let newItemes = selectedInvoiceDetails?.map((item: any) => {
      if (item.ItemId == newObj.ItemId) {
        return newObj;
      } else {
        return item;
      }
    });
    selectedInvoiceDetailHandler(newItemes);

    setDataChange(true);
  };

  const selectedObjCallback = (obj) => {
    const findItem = salesInvoiceDetails.find((item) => item.Id == obj.Id);
    if (findItem) {
      setOriginalQty({
        qty1: findItem.Qty1,
        qty2: findItem.Qty2,
      });
    } else {
      setOriginalQty({
        qty1: obj.Qty1,
        qty2: obj.Qty2,
      });
    }
    obj?.IsAbsoluteUnitConversionRatio
      ? setSelectedObj(obj)
      : setSelectedObj({
          ...obj,
          IsAbsoluteUnitConversionRatio:
            findItem?.IsAbsoluteUnitConversionRatio,
        });
  };



  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    let newPriceListDetail: any = [...selectedInvoiceDetails];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setSelectedInvoiceDetails(newPriceListDetail.filter((e) => e.Id != id));
  };

  const handleSourceData = (obj, type) => {
    setResponse(obj);
    setHaveSource(type);
    setPriceList({ ...priceList, Id: obj.PriceListId });
    setSalesInvoiceDetailHandler(obj.SalesReturnInvoiceDetailDTOList);

    setCustomer({ Id: obj?.CustomerId, Name: obj?.CustomerName });
  };
  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null)?.length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (mode != "create") {
      getInvoiceAsync();
    } else {
      getReturnInvoiceConstraintAsync(true);
    }
  }, []);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(() => {
    setPayment((prev) => {
      return {
        ...prev,
        totalprice: +totalRows?.SubTotalPrice - +totalRows?.TotalDiscount,
      };
    });
  }, [totalRows]);
  useEffect(() => {
    setReceive((prev) => {
      return {
        ...prev,
        GrandTotalPrice:
          +payment.totalprice +
          +payment?.Addition +
          +totalRows?.TotalTax -
          +payment?.Deduction,
      };
    });
  }, [payment]);


  useEffect(() => {
    if (response) {
      setPayment({
        totalprice: response?.TotalPrice,
        Addition: response?.Addition,
        Deduction: response?.Deduction,
        Prepayment: response?.Prepayment,
      });
      setReceive({
        GrandTotalPrice: response?.GrandTotalPrice,
        Prepayment: response?.Prepayment,
        DebitPayment: response?.DebitPayment,
        NonDebitPayment: response?.NonDebitPayment,
      });
    }
  }, [response]);

  // useEffect(() => {
  //   if (!!selectedInvoiceDetails?.length) {
  //     let newItemCount = 0;
  //     let newItemTax = 0;
  //     let newItemTotalDiscount = 0;
  //     let newItemTotalPrice = 0;
  //     selectedInvoiceDetails.map((item) => {

  //       newItemCount += +item.Qty1;
  //       newItemTax += +item.Tax;
  //       newItemTotalDiscount += +item.Discount;
  //       newItemTotalPrice += +item.SubTotalPrice;
  //     });
  //     setTotalRows((prev)=>{return{
  //       ItemCount: newItemCount,
  //       TotalTax: newItemTax,
  //       TotalDiscount: newItemTotalDiscount,
  //       SubTotalPrice: newItemTotalPrice,
  //     }});
  //   }
  // }, [selectedInvoiceDetails?.length,selectedInvoiceDetails]);

  return (
    <div className=" ">
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesReturnInvoiceDetailForm"}
        >
          <SalesReturnInvoiceDetailsList
            salesInvoiceDetails={salesInvoiceDetails}
            SalesInvoiceDetailCallBack={selectedInvoiceDetailHandler}
            invoiceConstraint={returnConstraint}
            mode={mode}
            setShowForm={setShowForm}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showEditForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowEditForm(false);
          }}
          show={showEditForm}
          title={"SalesReturnInvoiceDetailForm"}
        >
          <SalesReturnInvoiceDetailForm
            setLastWarehouse={setLastWarehouse}
            lastWarehouse={lastWarehouse}
            mode={detailMode}
            onHide={() => {
              setShowEditForm(false);
            }}
            priceList={priceList}
            invoiceConstraint={returnConstraint}
            selectedObj={selectedObj}
            SalesInvoiceDetailCallBack={(e) => handlesetPriceListDetail(e)}
            originalQty={originalQty}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showCustomerPhoneForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
            type={typePhoneContact}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showAddressBox ? (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setChangeAddress(JSON.stringify(e).toLowerCase());
              setCustomerInfo({
                ...customerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showCustomerform ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerform(false);
          }}
          show={showCustomerform}
          title={"Customer"}
        >
          <CustomerTabform
            selectedObj={customer}
            mode={mode}
            onHide={() => {
              setShowCustomerform(false);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {loading ? (
        <form onSubmit={handleSalesInvoiceDetailFormSubmit} ref={invoiceRef}>
          <FormWithTitle
            title={trans("SalesReturnInvoice")}
            defualtClose={true}
            showFristRow={true}
            closeOption={true}
            dynamicTitle={{
              CustomerName: customer?.Name,
              salespreinvoiceno: response?.SalesReturnInvoiceNo
                ? response?.SalesReturnInvoiceNo
                : salesReturnInvoice,
            }}
          >
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-lg-2 col-11">
                  <SalesReturnInvoiceSourceId
                    SalesInvoiceDetailCallBack={selectedInvoiceDetailHandler}
                    invoiceConstraint={returnConstraint}
                    setShowForm={setShowForm}
                    SourceObjCallBack={(e, type) => {
                      handleSourceData(e, type);
                    }}
                    value={haveSource ? haveSource : "undefined"}
                    resetForm={resetForm}
                    dataChange={dataChange}
                    mode={!!selectedInvoiceDetails?.length ? "view" : mode}
                  />
                </div>

                {!returnConstraint?.IsClassificationIgnored && (
                  <div className="col-lg-2 col-11 me-lg-3 mt-3 mt-lg-0">
                    <DocsClassification
                      label="Classification"
                      name="docsClassificationId"
                      baseUrl={INVENTORYANDSALES_URL}
                      endPoint={
                        ENDPOINTSINVENTORYANDSALES.Sales
                          .BaseDefinitionAndSetting.SalesDocsClassification
                          .dictionary
                      }
                      resetForm={resetForm}
                      value={response?.DocsClassificationId}
                      mode={'view'}
                      onChange={(e) => {
                        setDocsClassification(e);
                      }}
                    />
                  </div>
                )}
                {(!returnConstraint.IsClassificationIgnored ||
                  docsClassification) && (
                  <div className="col-lg-1 col-11">
                    {/* <SalesReturnInvoiceNo
                      docsClassificationId={docsClassification}
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      onChange={(e) => {
                        setSalesInvoiceNo(e);
                      }}
                      resetForm={resetForm}
                      disable={!invoiceConstraint?.IsDocNoEditable}
                      mode={mode}
                    /> */}
                      <Textbox
                                textboxType="text"
                                textboxName="SalesReturnInvoiceNo"
                                labelText="number"
                                defaultValue={
                                  // response?.Id != GUID_EMPTY
                                  //   ? response?.SalesReturnInvoiceNo
                                  //   : returnConstraint?.DocNo
                                  response?.SalesReturnInvoiceNo
                                }
                                resetForm={resetForm}
                                readonly={
                                  // !returnConstraint?.IsDocNoEditable ||
                                  // mode !== "create"
                                  //   ? true
                                  //   : false
                                  true
                                }
                                onChange={(e) => {
                                  setSalesReturnInvoice(e.target.value);
                                }}
                                mode={mode}
                          />
                  </div>
                )}
                <div className="col-lg-2 col-11">
                  <Datepicker
                    mode={
                      mode == "view" || returnConstraint?.IsNewDocCurrentDate
                        ? "view"
                        : mode
                    }
                    lable="Date"
                    required={true}
                    setIsoTime={(isoTime: string) => {
                      setReturnSalesInvoiceDate(isoTime);
                    }}
                    val={
                      response?.SalesInvoiceDate
                        ? response?.SalesInvoiceDate
                        : returnConstraint?.IsNewDocCurrentDate
                        ? todayDate
                        : null
                    } //resetForm ? new Date().toISOString() :
                    errorFlag={false}
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <CustomerDictionary
                    name="CustomerName"
                    label="CustomerName"
                    resetForm={resetForm}
                    value={response?.CustomerId}
                    CustomerName={response?.CustomerName	}
                    returnObj={true}
                    required={true}
                    onChange={(e) => {
                      setCustomer(e);
                      getDefaultPersonContactAsync(e.Id);
                    }}
                    mode={"view"}
                  />
                </div>

                <div
                  style={{
                    marginTop: "25px",
                    width: "1%",
                    marginRight: "-25px",
                  }}
                >
                  <Button
                    Icon={true}
                    value={
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCustomerform(true);
                        }}
                        className="checkButton d-flex align-items-center justify-content-center"
                      >
                        {" "}
                        <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                      </button>
                    }
                    btnType="primary"
                  />
                </div>

                <div className="col-lg-2 col-11 me-lg-4">
                  <PriceListDictionary
                    name="PriceList"
                    label="PriceList"
                    required={true}
                    resetForm={resetForm}
                    value={response?.PriceListId}
                    mode={"view"}
                    onChange={(e) => {
                      dataChange ? sendDataForCalcAsync(e) : setPriceList(e);
                    }}
                  />
                </div>
                <div className="col-lg-1 col-11">
                  <CurrencyDictionary
                    onChange={(obj, id) => {
                      setCurrencyInfo(obj);
                    }}
                    name="CurrencyId"
                    label="Currency"
                    resetForm={resetForm}
                    value={
                      priceList?.CurrencyId
                        ? priceList?.CurrencyId
                        : response?.CurrencyId
                    }
                    mode={priceList?.CurrencyId ? "view" : mode}
                    required={true}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CustomerEconNo"
                    labelText="CustomerEconNo"
                    defaultValue={
                      response?.CustomerEconNo
                        ? response?.CustomerEconNo
                        : customerInfo?.EconNo
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <CustomerPhoneDictionary
                  required
                    type={ContactType.Phone}
                    name="CustomerPhone"
                    label="CustomerPhone"
                    resetForm={resetForm}
                    valueString={
                      response?.CustomerPhone
                        ? response?.CustomerPhone
                        : customerInfo?.PhoneNumber
                    }
                    mode={"view"}
                    returnObj={true}
                    personId={
                      response?.CustomerPersonId
                        ? response?.CustomerPersonId
                        : customerInfo?.CustomerPersonId
                    }
                    onChange={(e) => {
                      setCustomer({ ...customer, CustomerPhone: e });
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "25px",
                    width: "17px",
                    marginRight: "-25px",
                  }}
                  className="ms-lg-4"
                >
                  <Button
                    Icon={true}
                    value={
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCustomerPhoneForm(true);
                          setTypePhonContact(ContactType.Phone);
                        }}
                        className="checkButton d-flex align-items-center justify-content-center"
                      >
                        {" "}
                        <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                      </button>
                    }
                    btnType="primary"
                  />
                </div>
                <div className="col-lg-2 col-11">
                  <CustomerPhoneDictionary
                  required
                    type={ContactType.Mobile}
                    name="CustomerMobilePhone"
                    label="CustomerMobilePhone"
                    resetForm={resetForm}
                    valueString={response?.CustomerMobilePhone}
                    mode={"view"}
                    returnObj={true}
                    personId={
                      response?.CustomerPersonId
                        ? response?.CustomerPersonId
                        : customerInfo?.CustomerPersonId
                    }
                    onChange={(e) => {
                      setCustomer({ ...customer, CustomerMobilePhone: e });
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "25px",
                    width: "17px",
                    marginRight: "-25px",
                  }}
                >
                  <Button
                    Icon={true}
                    value={
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCustomerPhoneForm(true);
                          setTypePhonContact(ContactType.Mobile);
                        }}
                        className="checkButton d-flex align-items-center justify-content-center"
                      >
                        {" "}
                        <FontAwesomeIcon icon={[ICON_TYPE, "plus"]} size="2x" />
                      </button>
                    }
                    btnType="primary"
                  />
                </div>
                <div className="col-lg-2 me-lg-4 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="CustomerNationalCode"
                    textboxType="nationalCode"
                    labelText="CustomerNationalCode"
                    defaultValue={
                      response?.CustomerNationalCode
                        ? response?.CustomerNationalCode
                        : customerInfo?.NationalId
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                       <div className="col-lg-3 col-11">
                                  <Person
                                    Change={(e) => {
                                      setUserChanges({ ...userChanges, ...e });
                                    }}
                                    value={
                                      response?.RecipientPersonId
                                        ? response?.RecipientPersonId
                                        : customerInfo?.RecipientPersonId
                                    }
                                    resetForm={resetForm}
                                    mode={mode}
                                    WithSearch={false}
                                    setPerson={setPerson}
                                    returnObj={true}
                                    name={"RecipientPersonDescription"}
                                    label="RecipientPerson"
                                  />
                                </div>
                <div className="col-lg-5 col-12" style={{ marginRight: "2px" }}>
                  <div className="row">
                    <div className="col-lg-9 col-10">
                      <Address
                        onChange={(e) => {
                          setShowAddressBox("selectMode");
                          return false;
                        }}
                        withMap={false}
                        hideDetail={true}
                        personContact={
                          changeAddress
                            ? convertAddress(changeAddress)
                            : response?.CustomerAddress
                            ? response?.CustomerAddress
                            : convertAddress(customerInfo?.Address)
                        }
                        mode={"view"}
                      />
                    </div>
                    <div className="col-auto mt-4 text-end p-0">
                      <Button
                        Icon={true}
                        value={
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAddressBox("createMode");
                            }}
                            className="checkButton d-flex align-items-center justify-content-center"
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={[ICON_TYPE, "plus"]}
                              size="2x"
                            />
                          </button>
                        }
                        btnType="primary"
                      />
                    </div>
                    <div className="col-auto mt-4 text-end p-0">
                      <Button
                        Icon={true}
                        value={
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAddressBox("selectMode");
                            }}
                            className="checkButton d-flex align-items-center justify-content-center"
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={[ICON_TYPE, "search"]}
                              size="2x"
                            />
                          </button>
                        }
                        btnType="primary"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-11 mr-2">
                  <Position
                    name="VisitorPositionId"
                    label="VisitorPosition"
                    resetForm={resetForm}
                    value={
                      customerInfo?.VisitorPositionId
                        ? customerInfo?.VisitorPositionId
                        : response?.VisitorPositionId
                    }
                    mode={"view"}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({ ...listPosition, VisitorPosition: e });
                    }}
                  />
                </div>
                <div className="col-lg-2 col-11">
                  <Position
                    name="DistributorPositionId"
                    label="DistributorPosition"
                    resetForm={resetForm}
                    value={
                      customerInfo?.DistributorPositionId
                        ? customerInfo?.DistributorPositionId
                        : response?.DistributorPositionId
                    }
                    mode={"view"}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({
                        ...listPosition,
                        DistributorPosition: e,
                      });
                    }}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Position
                    name="SalesManagerPositionId"
                    label="salesmanagerpositiondescription"
                    resetForm={resetForm}
                    value={
                      customerInfo?.SalesInvoiceStatusId
                        ? customerInfo?.SalesInvoiceStatusId
                        : response?.SalesManagerPositionId
                    }
                    mode={"view"}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({
                        ...listPosition,
                        SalesManagerPosition: e,
                      });
                    }}
                  />
                </div>

                <div className="col-lg-5 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="Description"
                    textboxType="textarea"
                    labelText="Description"
                    minHeight={1}
                    defaultValue={response?.Description}
                  
                    resetForm={resetForm}
                    mode={"view"}
                    maxLength={900}
                    letterCounter={true}
                  />
                </div>
              </div>
            </div>
          </FormWithTitle>
          <div className="mt-3">
            <FormWithTitle title={trans("Details")}>
              <div>
                <div className="row align-items-center justify-content-between">
                  {mode !== "view" && (
                    <div className="col-2 d-flex">
                      <div
                        onClick={() => {
                          if (!!salesInvoiceDetails.length) {
                            setShowForm(true);
                            setDetailMode("create");
                          } else {
                            Toast("Msg_SelectInvoice", "error");
                          }
                        }}
                        className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                        style={{
                          width: "70px",
                          maxHeight: "30px",
                          minWidth:'70px',
                          marginRight: "20px",
                        }}
                      >
                        <span className=" font-size-md ms-2 ">
                          {trans("add")}
                        </span>
                        <FontAwesomeIcon
                          size="xs"
                          icon={["fas", "plus"]}
                          className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                          color="white"
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (selectedObj && selectedObj?.Id) {
                            setShowEditForm(true);
                          } else {
                            Toast("Msg_SelectItem", "error");
                          }
                        }}
                        className="col-2 mb-1 btn btn-primary btn-sm d-flex align-items-center justify-content-between "
                        style={{
                          width: "80px",
                          maxHeight: "30px",
                          marginRight: "20px",
                          zIndex:'2'
                        }}
                      >
                        <span className=" font-size-md ms-2 ">
                          {trans("edit")}
                        </span>
                        <FontAwesomeIcon
                          size="xs"
                          icon={["fas", "edit"]}
                          className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                          color="white"
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-10 row" style={{ justifyContent: "end" }}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="d-flex align-items-center justify-content-center mb-1 flex-wrap"></div>
                      <div className={`${mode!="view"? "col-7" : "col-12"}  row justify-content-end mb-1`}>
                        <Headerbar
                          withPading={false}
                          headerContents={totalRows}
                          hasBorder={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {dataGridLoading ? (
                  <>
                    {selectedInvoiceDetails?.length >= 0 ? (
                      <>
                        <DataGrid
                          // DoubleClick={(e) => {
                          //   setSelectedObj(e);
                          //   setShowForm(true);
                          // }}

                          parentName={"SalesReturnInvoiceDetailList"}
                          startIndex={18}
                          theadData={Object.keys(returnConstraint?.DetailDTO)}
                          tbodyData={
                            selectedInvoiceDetails?.length > 0
                              ? selectedInvoiceDetails
                              : [returnConstraint?.DetailDTO]
                          }
                          scrollHeight={"67vh"}
                          minCellWidth={80}
                          selectedRowObjectCallback={selectedObjCallback}
                          rowSelected={selectedObj}
                          handleDelete={
                            mode != "view"
                              ? (e) => {
                                  Toast(
                                    trans("msg_deleteconfirm"),
                                    "warning",
                                    "alert",
                                    () => handleDeleteRow(e)
                                  );
                                }
                              : undefined
                          }
                        />
                      </>
                    ) : (
                      <Message message={"NoDataForPreview"} type={"info"} />
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </FormWithTitle>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="totalprice"
                    textboxType="text"
                    labelText="totalprice"
                    decimals={priceList?.CurrencyRoundingPlace}
                    round={returnConstraint.FloorTaxDuty}
                    defaultValue={
                      mode == "view"
                        ? response.TotalPrice
                        : totalRows.SubTotalPrice != 0
                        ? totalRows.SubTotalPrice - totalRows.TotalDiscount + ""
                        : ""
                    }
                    value={
                      totalRows.SubTotalPrice != 0
                        ? totalRows.SubTotalPrice - totalRows.TotalDiscount + ""
                        : ""
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Addition"
                    textboxType="text"
                    labelText="Addition"
                    defaultValue={
                      payment.Addition != 0
                        ? payment.Addition + ""
                        : response?.Addition != null
                        ? response?.Addition + ""
                        : payment.Addition
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Addition: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Deduction"
                    textboxType="text"
                    labelText="Deduction"
                    defaultValue={
                      payment.Deduction != 0
                        ? payment.Deduction + ""
                        : response?.Deduction != null
                        ? response?.Deduction + ""
                        : payment.Deduction
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Deduction: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="GrandTotalPrice"
                    decimals={priceList?.CurrencyRoundingPlace}
                    round={returnConstraint.FloorTaxDuty}
                    textboxType="text"
                    labelText="GrandTotalPrice"
                    defaultValue={
                      receive?.GrandTotalPrice != 0
                        ? receive?.GrandTotalPrice + ""
                        : response?.GrandTotalPrice
                        ? response?.GrandTotalPrice + ""
                        : receive?.GrandTotalPrice
                        ? receive?.GrandTotalPrice
                        : null
                    }
                    value={
                      receive?.GrandTotalPrice != 0
                        ? +receive?.GrandTotalPrice + ""
                        : +response?.GrandTotalPrice
                        ? +response?.GrandTotalPrice + ""
                        : +receive?.GrandTotalPrice
                        ? +receive?.GrandTotalPrice + ""
                        : null
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-1">
                  <DictionarySelectBox
                    label="PaymentMethod"
                    name="PaymentMethodId"
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                        .PaymentMethod.dictionary
                    }
                    resetForm={resetForm}
                    value={response?.PaymentMethodId}
                    mode={mode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    textboxName="Prepayment"
                    textboxType="number"
                    labelText="Prepayment"
                    defaultValue={
                      receive?.Prepayment != 0
                        ? receive?.Prepayment
                        : response?.Prepayment !== null
                        ? response?.Prepayment + ""
                        : receive?.Prepayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        Prepayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="DebitPayment"
                    textboxType="text"
                    labelText="DebitPayment"
                    defaultValue={
                      receive?.DebitPayment != 0
                        ? receive?.DebitPayment + ""
                        : response?.DebitPayment != null
                        ? response?.DebitPayment + ""
                        : receive?.DebitPayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        DebitPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="NonDebitPayment"
                    textboxType="text"
                    labelText="NonDebitPayment"
                    defaultValue={
                      receive?.NonDebitPayment != 0
                        ? receive?.NonDebitPayment + ""
                        : response?.NonDebitPayment != null
                        ? response?.NonDebitPayment + ""
                        : receive?.NonDebitPayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        NonDebitPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                    useGroupingPrice={true}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    textboxName="CreditPayment"
                    textboxType="text"
                    labelText="CreditPayment"
                    defaultValue={
                      receive?.GrandTotalPrice -
                      (+receive?.NonDebitPayment +
                        +receive?.DebitPayment +
                        +receive?.Prepayment) +
                      ""
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        CreditPayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={"view"}
                    useGroupingPrice={true}
                  />
                </div>

                <div className="col-12 mt-4">
                  {mode != "view" ? (
                    <div className="col-lg-12  btns_form">
                      <Button
                        disabled={!selectedInvoiceDetails?.length}
                        value="save"
                        btnType="submit"
                      />
                        {!jobId && (
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                        )}
                    </div>
                  ) : (
                    <div className="col-lg-12 mt-1 btns_form">
                      <Button
                        btnType="primary"
                        value="close"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
