import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Person } from "components/Person/Person";
import { Position } from "components/Position/Position";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { GUID_EMPTY, REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import {
  deleteParameter,
  GenerateParameterFromDTO,
  postParameter,
  putParameter,
} from "./api";
import { EnumsDictionary } from "./EnumsDictionary";
import { ParameterDataTypeDictionary } from "./ParameterDataTypeDictionary";
import { ParameterEnumDictionary } from "./ParameterEnumDictionary";
import { ParameterDictionary } from "./ParameterDictionaryValuse";
import { ParameterOperatorTypesSelect } from "./ParameterOperatorTypesSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";

interface FeedParametersProps {
  FeedParametersListCallback: (e) => void;
  onHide: () => void;
  obj?: any;
  feedType?: any;
  parentMode?: any;
  feedId?:string
  handleUserChange?:any
}
export const FeedParametersForm = ({
  feedType,
  parentMode,
  obj,
  FeedParametersListCallback,
  onHide,
  feedId,
  handleUserChange
}: FeedParametersProps) => {
  const [response, setResponse] = useState<Response | any>(obj ? obj : []);
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [mode, setMode] = useState("create");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [editForm, setEditForm] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [catchMessage, setCatchMessage] = useState<any>({
    type: "info",
    msg: "NoDataForPreview",
  });
  const [showForm, setShowForm] = useState(0);
  const [dataType, setDataType] = useState<any>("System.String");
  const [enumType, setEnumType] = useState<any>();
  const [ParameterOperatorTypes, setParameterOperatorTypes] = useState<any>();
  const [dateDefaultValue, setDateDefaultValue] = useState<string | null>(null);
  const [selectBoxGuid, setSelectBoxGuid] = useState("0");
  const handleCreateFeedParameter = () => {
    setDataType("System.String");
    setEditForm(null);
    setMode("create");
    setShowForm(1);
  };


  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    if (parentMode === "edit") {
      try {
        await deleteParameter(selectedObj.Id);
      } catch (error) {}
    }

    let newData = response.filter((e) => e.Id != id);
    setResponse(newData);
    setSelectedObj(null);
    setForceUpdate(!forceUpdate);
  };
  const handleViewRow = () => {
    setMode("view");
    setShowForm(1);
    setEditForm(selectedObj);
  };
  const handleEditRow = (e) => {
    setMode("edit");
    setShowForm(1);
    setEditForm(selectedObj);
  };
  function handleCreateManual(e) {
    setShowForm(e);
    setEditForm(null);
    setDataType("System.String");
    setMode("create");
  }

  const selectedIdCallback = (obj, index) => {
    if (showForm && mode != "create") {
      setEditForm(obj);
    }
    setSelectedObj(obj);
  };

  const submit = async (e) => {
    e.preventDefault();
    let ParameterOperatorTypesObj ={}
    const radioArray:any = Array.from(e.target.ParameterOperatorTypesSelect);
    radioArray.map(item=>{
      if(item.checked){
        ParameterOperatorTypesObj[item.value] = true
      }
    })

    let data = {
      Id: uuidv4(),
      ReportFeedId: undefined,
      ParameterOperatorTypes:ParameterOperatorTypesObj ,
      Sequence: e.target.Sequence?.value,
      ParameterName: e.target.ParameterName.value,
      ParameterNameEN: e.target.ParameterNameEN?.value,
      ParameterDataType:
        dataType.includes("Enumerations") || dataType.includes("Dictionaries")
          ? dataType + "." + enumType
          : dataType.includes("Guid")
          ? `${dataType}.${selectBoxGuid == "0" ? "Person" : "Position"}`
          : dataType,
      OperatorTypesCount: ParameterOperatorTypesObj && Object.keys(ParameterOperatorTypesObj)?.length ,
      IsOptional: e.target.IsOptional.checked,
      ParameterDefaultValue:
        dataType.includes("Date") || dataType.includes("Time")
          ? dateDefaultValue
          : (dataType.includes("Boolean")
              ? e.target.ParameterDefaultValue.checked
              : e.target.ParameterDefaultValue.value) + "",
    };
   if (mode == "edit") {
     try {
       let newResponse = response.filter((item) => item.Id !== selectedObj.Id);
       if (parentMode == "edit") {
         await putParameter({ ...data, Id: selectedObj.Id });
       }
       setResponse([...newResponse, { ...data, Id: selectedObj.Id }]);
       setShowForm(0);
       setEditForm(null);
       setSelectedObj(null);
     } catch (error) {}
   } else {
     try {
       if (parentMode == "edit" && (feedId!=GUID_EMPTY)) {
         await postParameter({ ...data,ReportFeedId:feedId});
       }
       setResponse([...response, data]);
       setShowForm(0);
       setEditForm(null);
       setSelectedObj(null);
       setResetForm(true);
     } catch (error) {}
   }
  };
  const submitGenerateParameter = async (e) => {
    e.preventDefault();
    setLoading(false)
    try{
    const ServiceValue =
      e.target.Service?.value == "Reporting" ? null : e.target.Service?.value;
      const res = await GenerateParameterFromDTO(
        ServiceValue,
        e.target.dtoFullName?.value
      );
      setResponse(res?.Data);
      setShowForm(0)
      handleUserChange()
    } catch (error) {}
    setLoading(true)

    setEditForm(null);
    setResetForm(true);
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setEditForm(null);
        setShowForm(0);
      });
    } else {
      setEditForm(null);
      setShowForm(0);
    }
  }

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  /* #region toolbarFeedParameters */
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "excel":
        downloadCSV(response, 1, trans("FeedParameters"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        handleCreateFeedParameter();
        break;
      case "view":
        if (selectedObj?.Id) handleViewRow();
        break;
      case "help":
        alert("call api help");
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(trans("msg_deleteconfirm"), "warning", "alert", () =>
            handleDeleteRow(selectedObj?.Id)
          );
        break;
      case "edit":
        if (selectedObj?.Id) {
          handleEditRow(selectedObj);
        }
        break;
    }
  };
 
  return (
    <>
      <div className="cartable-FeedParameter">
        <Toolbar
          remove={true}
          view={false}
          id={selectedObj?.Id}
          emptyList={response == null || response?.length == 0}
          handleCreateManual={handleCreateManual}
          edit={true}
          search={false}
          handleClickToolbar={clickToolbarCallback}
        />
        <div className="card">
          <div className="">
            <>
              {showForm == 1 && (
                <>
                  <div className="card mb-4">
                    <form className="TypeForm row card-body " onSubmit={submit}>
                      <div className="col-md-3 col-sm-12">
                        <Textbox
                          required={true}
                          textboxName={"ParameterName"}
                          textboxType="text"
                          resetForm={resetForm}
                          labelText={"ParameterName"}
                          mode={mode}
                          defaultValue={editForm?.ParameterName}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <Textbox
                          required={true}
                          textboxName={"ParameterNameEN"}
                          textboxType="text"
                          resetForm={resetForm}
                          labelText={"ParameterNameEN"}
                          mode={mode}
                          defaultValue={editForm?.ParameterNameEN}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <Textbox
                          textboxName={"Sequence"}
                          textboxType="number"
                          resetForm={resetForm}
                          labelText={"Sequence"}
                          mode={mode}
                          defaultValue={
                            mode == "create"
                              ? response.length + 1
                              : editForm?.Sequence + ""
                          }
                        />
                      </div>
                      {/* <div className="col-md-4 col-sm-12">
                             <Textbox  required={true} textboxName={"ParameterDataType"} textboxType="text" resetForm={resetForm} labelText={"ParameterDataType"} mode={mode}  defaultValue={editForm?.ParameterDataType} />
                           </div>   */}
                      <div className="col-md-3 col-sm-12">
                        <ParameterDataTypeDictionary
                          isMethod={feedType === "Method"}
                          onChange={(e) => {
                            setDataType(e);
                            if (e !== dataType)
                              setEditForm({
                                ...editForm,
                                ParameterDefaultValue: null,
                              });
                          }}
                          name="ParameterDataType"
                          mode={mode}
                          defaultValue={
                            editForm?.ParameterDataType
                              ? editForm?.ParameterDataType.includes(
                                  "Enumerations."
                                ) ||
                                editForm?.ParameterDataType.includes(
                                  "Dictionaries."
                                )
                                ? editForm?.ParameterDataType.slice(0, 12)
                                : editForm?.ParameterDataType.includes("Guid")
                                ? editForm?.ParameterDataType.slice(0, 11)
                                : editForm?.ParameterDataType
                              : dataType
                          }
                          value={
                            dataType.includes("Guid")
                              ? dataType.slice(0, 11)
                              : dataType
                          }
                        />
                      </div>
                      <div className="col-3 ">
                        {
                          {
                            "System.String": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="text"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.TimeOnly": (
                              <Datepicker
                                mode={mode}
                                resetForm={resetForm}
                                lable="defaultValue"
                                setIsoTime={(isoTime: string) => {
                                  setDateDefaultValue(isoTime);
                                }}
                                val={
                                  editForm?.ParameterDefaultValue
                                    ? editForm.ParameterDefaultValue
                                    : null
                                } //resetForm ? new Date().toISOString() :
                                withTime={true}
                                format={"HH:mm"}
                                disableDayPicker={true}
                                onOpenPickNewDate={true}
                              />
                            ),
                            "System.DateOnly": (
                              <Datepicker
                                mode={mode}
                                resetForm={resetForm}
                                lable="defaultValue"
                                setIsoTime={(isoTime: string) => {
                                  setDateDefaultValue(isoTime);
                                }}
                                val={
                                  editForm?.ParameterDefaultValue
                                    ? editForm.ParameterDefaultValue
                                    : null
                                } //resetForm ? new Date().toISOString() :
                                format={"YYYY/MM/DD"}
                                onOpenPickNewDate={true}
                              />
                            ),
                            "System.DateTime": (
                              <Datepicker
                                mode={mode}
                                resetForm={resetForm}
                                lable="defaultValue"
                                setIsoTime={(isoTime: string) => {
                                  setDateDefaultValue(isoTime);
                                }}
                                val={
                                  editForm?.ParameterDefaultValue
                                    ? editForm.ParameterDefaultValue
                                    : null
                                } //resetForm ? new Date().toISOString() :
                                withTime={true}
                                onOpenPickNewDate={true}
                              />
                            ),
                            "System.Char": (
                              <Textbox
                                textboxType="text"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                                defaultValue={editForm?.ParameterDefaultValue}
                              />
                            ),
                            "System.Double": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.UInt64": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.UInt32": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.UInt16": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.Decimal": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.Int64": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.Int32": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.Int16": (
                              <Textbox
                                defaultValue={editForm?.ParameterDefaultValue}
                                textboxType="number"
                                textboxName={"ParameterDefaultValue"}
                                labelText={"ParameterDefaultValue"}
                                resetForm={resetForm}
                                mode={mode}
                              />
                            ),
                            "System.Boolean": (
                              <>
                                <CheckableButton
                                  defaultChecked={editForm?.ParameterDefaultValue}
                                  defaultValue={editForm?.ParameterDefaultValue}
                                  checked={editForm?.ParameterDefaultValue}
                                  labelText={"ParameterDefaultValue"}
                                  inputName={"ParameterDefaultValue"}
                                  type="checkbox"
                                  mode={mode}
                                />
                              </>
                            ),
                          }[dataType]
                        }
                        {dataType.includes("Enumerations") ||
                        dataType.includes("Dictionaries") ? (
                          <EnumsDictionary
                            name="Parameter"
                            value={
                              (editForm?.ParameterDataType?.includes(
                                "Enumerations."
                              ) &&
                                dataType.includes("Enumerations")) ||
                              (editForm?.ParameterDataType?.includes(
                                "Dictionaries."
                              ) &&
                                dataType.includes("Dictionaries"))
                                ? editForm?.ParameterDataType.slice(13)
                                : ""
                            }
                            IsEnum={dataType?.includes("Enumerations")}
                            feedType={feedType}
                            onChange={(e) => {
                              setEnumType(e);
                            }}
                            mode={mode}
                          />
                        ) : (
                          <></>
                        )}

                        {dataType.includes("Guid") ? (
                          <SelectBox
                            name=""
                            onChange={(e) => {
                              setSelectBoxGuid(e.target.value);
                            }}
                            mode={mode}
                            lable="Guid"
                            selectType="select"
                            value={selectBoxGuid}
                            options={["person", "position"]}
                            val={["0", "1"]}
                          />
                        ) : (
                          <></>
                        )}
                      </div>

                        {selectBoxGuid == "0" && dataType.includes("Guid") && (
                           <div className="col-3 ">
                          <Person
                            Change={(e) => {}}
                            value={
                              editForm?.ParameterDefaultValue
                                ? editForm?.ParameterDefaultValue
                                : ""
                            }
                            WithSearch={false}
                            returnObj={true}
                            name={"ParameterDefaultValue"}
                            label="ParameterDefaultValue"
                          />
                          </div>
                        )}
                        {selectBoxGuid == "1" && dataType.includes("Guid") && (
                           <div className="col-3 ">
                          <Position
                            name="ParameterDefaultValue"
                            label="ParameterDefaultValue"
                            value={
                              editForm?.ParameterDefaultValue
                                ? editForm?.ParameterDefaultValue
                                : ""
                            }
                            resetForm={resetForm}
                          />
                          </div>
                        )}
                        {dataType.includes("Enumerations") && enumType ? (
                           <div className="col-3 ">
                          <ParameterEnumDictionary
                            name="ParameterDefaultValue"
                            feedType={feedType}
                            enumType={
                              enumType ? enumType : editForm?.ParameterDataType
                            }
                            mode={mode}
                            value={editForm?.ParameterDefaultValue}
                          />
                          </div>
                        ) : (
                          <></>
                        )}
                        {dataType.includes("Dictionaries") && enumType ? (
                           <div className="col-3 ">
                          <ParameterDictionary
                            value={editForm?.ParameterDefaultValue}
                            typeName={enumType.replace("Dictionaries.", "")}
                            mode={mode}
                            label="ParameterDefaultValue"
                            name={"ParameterDefaultValue"}
                            resetForm={false}
                            required={false}
                          />
                          </div>
                        ) : (
                          <></>
                        )}
                    
                      <div className="col-3 mt-4 mb-4">
                        <CheckableButton
                          defaultChecked={editForm?.IsOptional}
                          checked={editForm?.IsOptional}
                          defaultValue={editForm?.IsOptional}
                          labelText={"IsOptional"}
                          inputName={"IsOptional"}
                          type="checkbox"
                          mode={mode}
                        />
                      </div>
                      <FormWithTitle
                        showFristRow={false}
                        title={"operatortype"}
                        cardClass={"col-lg-12 "}
                        key={1}
                      >
                          <ParameterOperatorTypesSelect
                          ParameterOperatorTypesListCallback={setParameterOperatorTypes}
                          name="FeedApi"
                          valueList={editForm?.ParameterOperatorTypes} type={"SelectBox"}              
                          />
                      </FormWithTitle>
    
                      {mode != "view" ? (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button
                            btnType="primary"
                            value="close"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      )}
                    </form>
                  </div>
                </>
              )}
              {showForm == 2 && (
                <>
                  <div className="card mb-4">
                    <form
                      className="TypeForm row card-body "
                      onSubmit={submitGenerateParameter}
                    >
                      <div className="col-4">
                        <DictionarySelectBox
                          mode={mode}
                          revert={true}
                          name="Service"
                          label="Service"
                          baseUrl={REPORT_URL}
                          endPoint={ENDPOINTSREPORT.ReportFeed.service}
                          // addCustomValue={'reporting'}
                          params={{ includeReporting: true }}
                        />
                      </div>
                      <div className="col-4">
                        <Textbox
                          textboxType="text"
                          textboxName="dtoFullName"
                          resetForm={resetForm}
                          labelText="dtoFullName"
                          required={true}
                          mode={mode}
                        />
                      </div>
                      {loading&&<>
                      {mode != "view" ? (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button value="Save" btnType="submit" />
                          <Button
                            btnType="cancel"
                            value="Cancel"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 mt-1 btns_form">
                          <Button
                            btnType="primary"
                            value="close"
                            onClick={() => {
                              cancel();
                            }}
                          />
                        </div>
                      )}
                      </>}
                    </form>
                  </div>
                </>
              )}

              {loading ? (
                response?.length > 0 ? (
                  <>
                    <DataGrid
                      handleEdit={handleEditRow}
                      DoubleClick={handleEditRow}
                      parentName={"FeedParameters"}
                      startIndex={3}
                      theadData={Object.keys(response[0])}
                      rowSelected={selectedObj}
                      tbodyData={response}
                      scrollHeight={showForm ? "25vh" : "67vh"}
                      minCellWidth={80}
                      selectedRowObjectCallback={selectedIdCallback}
                      handleDelete={(e) => {
                        Toast(
                          trans("msg_deleteconfirm"),
                          "warning",
                          "alert",
                          () => handleDeleteRow(e)
                        );
                      }}
                    />
                  </>
                ) : (
                  <Message
                    message={catchMessage.msg}
                    type={catchMessage.type}
                  />
                )
              ) : (
                <Loading />
              )}
            </>
          </div>
          <div className="col-lg-12 mt-1 btns_form">
            <Button
              btnType="submit"
              value="save"
              onClick={() => {
                FeedParametersListCallback(response);
                onHide();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};



