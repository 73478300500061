import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { AddressBox } from "pages/InventoryAndSales/Sales/SalesInvoice/components/AddressBox/AddressBox";
import { CustomerDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "pages/InventoryAndSales/Sales/SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "pages/InventoryAndSales/Sales/SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import { getDefaultPersonContact, sendBasketAsync } from "./api";

interface CustomerSalesOrderProps {
    onHide:()=>void
    mode:string
    basketData:any
}

export const CustomerSalesOrder = ({basketData,onHide,mode}:CustomerSalesOrderProps) => {

    const [loading,setLoading] = useState<boolean>(true)
    const [resetForm,setResetForm] = useState <boolean>(false)
    const [response, setResponse] = useState<any>();
    const [todayDate, settodayDate] = useState<string | null>(new Date().toISOString());
    const [customer,setCustomer] = useState <any> (null)

    const [customerDefaultContact,setCustomerDefaultContact] = useState<any> (null)
    const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);

    const [showAddressBox, setShowAddressBox] = useState<"createMode"|"selectMode"|null>(null);
    const layoutContext = useContext(LayoutContext);

    async function getDefaultPersonContactAsync(){

        try {
           // const res2 = await getPersonCustomer(personId)
            const res = await getDefaultPersonContact(	)
            setCustomerDefaultContact({...res.Data  })

        } catch (error) {
            
        }

    }
    const navigate = useNavigate();
    const handleCustomerSalesOrderFormSubmit =async (event) => {
        event.preventDefault();
        let SalesOrderDetails:any = []
        basketData.map(items => {
            Object.values(items.order).map((orders:any) =>{
                SalesOrderDetails.push({
                    "ItemId": orders.OrderItemId.ItemId,
                    "Qty":orders.count,
                    "UnitId": orders.OrderItemId.UnitId,
                    "Description":items.ItemDescription
                })
            })
        })

            let data = {
                "CustomerName":  customer?.Name ? customer?.Name : layoutContext.currentUserPosition.Description.split("-")[0]  ,
                "CustomerPhone": customer?.CustomerPhone?.Name.split(":")[1],
                "CustomerAddress": event.target.CustomerAddress?.value,
                "CustomerEconNo": event.target.CustomerEconNo?.value,
                "CustomerNationalCode": event.target.CustomerNationalCode?.value,
                "SalesOrderDate": todayDate,
                "Description": event.target.Description.value,
                "RecipientDescription": event.target.RecipientDescription.value,
                "PaymentMethodId": event.target.PaymentMethodId?.value,
                "SalesOrderDetails": SalesOrderDetails
              }
        try {
    
  
        await sendBasketAsync(data,"post")
          onHide()
      localStorage.setItem("Basket","")
      navigate("/e-commerce/Items")
        } catch (error) {

        }
    }
    useEffect(() => {
             getDefaultPersonContactAsync()
      }, []);
    return ( 
        <>
             {showCustomerPhoneForm ?
                <ModalForm modalHeight={49} mode={mode} onHide={()=>{setShowCustomerPhoneForm(false)}} show={showCustomerPhoneForm} title={"CustomerPhoneForm" } >
                    <CustomerPhoneList personId={customer?.Id} onHide={()=>{setShowCustomerPhoneForm(false)}}/>
                </ModalForm>  : <></>}
            {showAddressBox ?
                <ModalForm modalHeight={49} onHide={()=>{setShowAddressBox(null)}} mode={mode} show={showAddressBox && true} title={"Address" } >
                    <AddressBox 
                        personId={customer?.Id} 
                        onHide={()=>{setShowAddressBox(null)}}
                        status={showAddressBox}
                        AddressHandeler={(e)=>{ setCustomerDefaultContact({...customerDefaultContact , Address: JSON.stringify(e).toLowerCase()});setShowAddressBox(null);}}
                     /> 
                </ModalForm>  : <></>}
            <div className="person-contact-form card">
            <div className="card-body">
              {loading ? 
                <div className="container-fluid">
                    <form onSubmit={handleCustomerSalesOrderFormSubmit}>
                       <div className="row ">
                        <div className="col-lg-2">
                        <Textbox
                             textboxType="text"
                             textboxName="CustomerName"
                             labelText="CustomerName"
                             defaultValue={response?.CustomerName ? response?.CustomerName :layoutContext.currentUserPosition.Description.split("-")[0] }
                             resetForm={resetForm}
                             mode={"view"}
                            />
                           </div>
                        <div className=" col-3 "style={{ marginRight: "2px"}}>
                            <div className="row">
                            <div className="col-lg-6 pr-5" >
                            <Textbox
                             textboxType="text"
                             textboxName="CustomerEconNo"
                             labelText="CustomerEconNo"
                             defaultValue={response?.CustomerEconNo ? response?.CustomerEconNo :customerDefaultContact?.EconNo }
                             resetForm={resetForm}
                             mode={mode}
                             required={true}
                            />
                            </div>
                            <div className="col-lg-6">
                            <Textbox
                             textboxName="CustomerNationalCode"
                             textboxType="nationalCode"
                             labelText="CustomerNationalCode"
                             defaultValue={response?.CustomerNationalCode? response?.CustomerNationalCode :customerDefaultContact?.NationalId}
                             resetForm={resetForm}
                             mode={mode == 'view'?"view": mode}
                            />
                            </div>
                            </div>
                        </div>
                            <div className="col-lg-2">
                            <DictionarySelectBox
                            label="PaymentMethod"
                            name="PaymentMethodId"
                            baseUrl={INVENTORYANDSALES_URL}
                            endPoint={ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.PaymentMethod.dictionary}
                            resetForm={resetForm}
                            value={response?.PaymentMethodId}
                            mode={mode}
                        
                            />
                        </div>
                           <div className="col-lg-7 row">
                           <Address onChange={(e)=>{setShowAddressBox('selectMode');return false}} withMap={false} hideDetail={customerDefaultContact == null && response?.CustomerAddress!=undefined} personContact={customerDefaultContact == null && response?.CustomerAddress? response?.CustomerAddress : customerDefaultContact?.Address ?  JSON.parse(customerDefaultContact?.Address):null} mode={"view"}/>   
                           </div>
                           <div  style={{marginTop:"25px" , width:"1%" , marginRight:"-25px"}}>
                                <Button Icon={true}  value={<button onClick={(e)=>{e.preventDefault() ;setShowAddressBox('createMode')}} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "plus"]}    size="2x"  /></button>} btnType="primary"  />
                             </div>
                             <div  style={{marginTop:"25px" , width:"1%" , marginRight:"5px" ,marginLeft:"20px"}}>
                                <Button Icon={true}  value={<button onClick={(e)=>{e.preventDefault() ;setShowAddressBox('selectMode')}} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "search"]}    size="2x"  /></button>} btnType="primary"  />
                             </div>
                            <div className="col-lg-2">
                                <CustomerPhoneDictionary
                                required
                                   name="CustomerPhone"
                                   label="CustomerPhone"
                                   resetForm={resetForm}
                                   valueString={response?.CustomerPhone}
                                   mode={mode == 'view'?"view": mode}
                                   returnObj={true}
                                   personId={response?.CustomerPersonId ? response?.CustomerPersonId : customerDefaultContact?.CustomerPersonId }
                                   onChange={(e)=>{setCustomer({...customer ,  CustomerPhone: e})}}
                                   />
                           </div>
                           <div  style={{marginTop:"25px" , width:"1%" , marginRight:"-25px"}}>
                                <Button Icon={true}  value={<button onClick={(e)=>{e.preventDefault() ;setShowCustomerPhoneForm(true)}} className="checkButton" > <FontAwesomeIcon icon={[ICON_TYPE, "plus"]}    size="2x"  /></button>} btnType="primary"  />
                             </div>

                        <div className="col-lg-6 ">
                            <Textbox
                             textboxType="textarea"
                             textboxName="Description"
                             labelText="Description"
                             maxLength={900}
                             resetForm={resetForm}
                            />
                        </div>
                        <div className="col-lg-6 ">
                            <Textbox
                             textboxType="textarea"
                             textboxName="RecipientDescription"
                             labelText="RecipientDescription"
                             maxLength={900}
                             resetForm={resetForm}
                            />
                        </div>
                     </div> 

                        <div className="col-lg-12 mt-1 btns_form">
                        <Button value="Save" btnType="submit" />
                        <Button btnType="cancel" value="Cancel" onClick={()=>{onHide()}} />
                        </div>                     
                    </form>
                </div>  
            : 
              <Loading/>
            }
            </div>
        </div> 
        </>
     );
}