import { Button } from "components/Button/Button";
import { TabBar } from "components/Tabbar/Tabbar";
import { useEffect, useState } from "react";
import { ItemGroupLeafForm } from "./ItemGroupLeafForm";
import { getItemGroupLeaf, sendItemGroupLeaf } from "./api";
import { ItemFormatsForm } from "./ItemFormatsForm";
import { Loading } from "components/Loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { padWithZeros, trans } from "utils/helpers";


interface ItemGroupLeafType {

    mode?: any,
    checkData?: any,
    showForm: (e:boolean) => void

    selectedObj:any;
}

export const ItemGroupLeaf = ({mode,selectedObj,checkData,showForm}:ItemGroupLeafType) => {


    const [ItemFormatsCodeFormat,setItemFormatsCodeFormat] = useState<any>({})
    const [curentTab, setCurentTab] = useState<any>("GroupLeaf")
    const [response,setResponse] = useState<any>()
    const [canPaste,setCanPaste] = useState<any>()

    const [loading,setLoading] = useState<boolean>(false)

    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
      } 

    async function getDataAsync() {
        setLoading(false)
        try {
            const res = await getItemGroupLeaf(selectedObj.Id);
            setResponse(res.Data);        

      
        } catch (error) {
        }
        setLoading(true)
    }

    const sendItemGroupAsync = async(method , data) => {
        try {
            const res = await sendItemGroupLeaf(data ,method )
            showForm(true)       
        } catch (error) {
            
        }  
    }

    async function formSubmit(event) {
        event.preventDefault();    
        let ItemLevelSpecificationList:any =[]
        Object.values(ItemFormatsCodeFormat).map((item:any, index)=>{
                if(index<+event.target?.ItemLayerCodeFormat?.value && index< +event.target.LayerCodeFormat.value){
                    ItemLevelSpecificationList.push({
                            CodeFormatLetter:item.CodeFormatLetter[0]  ,
                            ItemMainPropertyDescription: event.target[item.CodeFormatLetter+"KeyDescription"].value ,
                            ItemMainPropertyId:item.ItemMainPropertyId ,
                        })

                }

            
            })
        let ItemVariationLevelSpecificationList:any =[]
        Object.values(ItemFormatsCodeFormat).map((item:any, index)=>{
            if(index>= +event.target.ItemLayerCodeFormat.value && index< +event.target.LayerCodeFormat.value)
            ItemVariationLevelSpecificationList.push({
                        CodeFormatLetter:item.CodeFormatLetter[0]  ,
                        ItemMainPropertyDescription: event.target[item.CodeFormatLetter+"KeyDescription"].value ,
                        ItemMainPropertyId:item.ItemMainPropertyId ,
                    })

            
            })
            if(mode=="create"){
                let data ={
                  ParentId: selectedObj.Id,
                  ItemType : +event.target.ItemType.value,
                  ItemLayerLevelCount:+event.target.ItemLayerCodeFormat.value ,
                  Code: ((checkData?.ParentCode ? checkData?.ParentCode+ event.target?.Code?.value :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","")) ,
                  "Description": checkData.Description ,
                  "DescriptionEN":checkData.DescriptionEN,
                  SummaryDescription : event.target.SummaryDescription.value,
                  DescriptionByCodeFormat : event.target.DescriptionByCodeFormat.value,
                  SummaryDescriptionEN : event.target.SummaryDescriptionEN.value, 
                  LeafCodeFormat :event.target.ItemGroupFormat.value,
                  ItemLevelSpecificationList:ItemLevelSpecificationList,
                  ItemVariationLevelSpecificationList:ItemVariationLevelSpecificationList
                }
                sendItemGroupAsync("post" ,data)
            }else{
                let data ={
                    Id: selectedObj.Id,
                    ItemType : +event.target.ItemType.value,
                    ItemLayerLevelCount:+event.target.ItemLayerCodeFormat.value , 
                    ParentId: selectedObj.ParentId,
                    Code: ((checkData?.ParentCode ? checkData?.ParentCode+ event.target?.Code?.value :checkData?.ParentCode+ event.target?.Code?.value).replace("undefined","") ),
                    DescriptionByCodeFormat : event.target.DescriptionByCodeFormat.value,
                  // Code: checkData?.ParentCode+padWithZeros(+checkData.CodeLength  , event.target?.Code?.value)   ,
                  "Description": checkData.Description ,
                  "DescriptionEN":checkData.DescriptionEN,
                    SummaryDescription : event.target.SummaryDescription.value,
                    SummaryDescriptionEN : event.target.SummaryDescriptionEN.value, 
                    LeafCodeFormat :event.target.ItemGroupFormat.value,
                    ItemLevelSpecificationList:ItemLevelSpecificationList,
                    ItemVariationLevelSpecificationList:ItemVariationLevelSpecificationList,
                  }
                sendItemGroupAsync("put" ,data)
            }
    }   
    
    async function checkPaste() {
        try {
            
            let data =await navigator.clipboard.readText()
            if(data && JSON.parse(data)){
                setCanPaste(true)
            }
        } catch (error) {
            setCanPaste(false)

        }
    }
    const pasteHandler = async() => {
       const text =JSON.parse( await navigator.clipboard.readText());
          setResponse(text)
        try {
        } catch (error) {
            
        }  
    }

    useEffect(() => {
        if(selectedObj?.Id && mode !="create"){
            getDataAsync()
        }else{
            setLoading(true)
        }
        checkPaste()
    }, [selectedObj]);
    let content = [
        { title: "GroupLeaf", body: (<ItemGroupLeafForm checkData={checkData} ItemGroupLeafData={response}   mode={mode} selectedObj={selectedObj} />), },
        { title: "ItemCodeFormat", body: (<ItemFormatsForm id={mode!="create"? selectedObj.Id:null}ItemGroupLeafData={response} submitItemFormats={setItemFormatsCodeFormat} checkData={checkData}   inTab={curentTab} mode={mode}  />), },
 ];
    return (
        <>
        <form className="Main-form py-2" id={"ItemForm"} name={"main"} onSubmit={formSubmit}>
        {(canPaste&&mode!="view")&&
            <div onClick={()=>{pasteHandler()}} className="col-2  btn btn-primary btn-sm d-flex align-items-center justify-content-between " style={{width:'89px' , maxHeight:"30px", marginRight:"20px" , position:"absolute" , left:"5px"}}>
                <span className=" font-size-md ">{trans('paste')}</span>
                <FontAwesomeIcon size="xs" icon={['fas', 'paste']} className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"   color='white' />
            </div>                    
        }
        {loading ? 
        <TabBar  tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"main"} />
        : 
            <Loading />
        }
        <div className="d-flex justify-content-end mt-2">
            {mode !== "view" ?
                <>
                    <Button    value="Save" btnType="submit" />
                    <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => { showForm(true)}}
                    />
                </>
                :
                 <Button
                    btnType="primary"
                    value="Close"
                    onClick={() =>{ showForm(true)}}
                />
            } 
          </div>
         </form>
        </>
    );;
}
 