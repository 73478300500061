import { ModalForm } from "components/ModalForm/ModalForm";

import { InventoryRemittanceForm } from "./components/InventoryRemittance/InventoryRemittanceForm";
import { warehouseIOType } from "types/enum";



interface Props {
  mode: string;
  showForm: boolean;
  selectedObj: any;
  onHide: () => void;
  reload: () => void;
  warehouseIoType?: any;
  setForceUpdate?:any;
  setShowWarehouseIoForm?:any
  forceUpdate
}

export const WarehouseIOForm = ({
  mode,
  showForm,
  selectedObj,
  onHide,
  reload,
  warehouseIoType,
  setForceUpdate,
  setShowWarehouseIoForm,
  forceUpdate

}: Props) => {
 

  return (
    <div className="card">
   {
    warehouseIoType==warehouseIOType.InventoryRemittance &&
    <InventoryRemittanceForm
    selectedObj={selectedObj}
    mode={mode}
    showForm={showForm}
    reload={() => {
      setForceUpdate(!forceUpdate);
    }}
    onHide={() => {
      setShowWarehouseIoForm(false);
    }}
    warehouseIoType={warehouseIoType}
    setShowWarehouseIoForm={setShowWarehouseIoForm}
  />
   }
               
         
 </div>
  );
};
