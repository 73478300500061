import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext"
import { getWarehouseDictionaryWithAccessApi } from "pages/InventoryAndSales/Sales/SalesInvoice/components/WarehouseDictionary/api"
import { useContext, useEffect, useRef, useState } from "react"
import { ICON_TYPE } from "utils/constants"
import { trans } from "utils/helpers"
import "./style.scss"

interface ButtonToolbarProps {
    onClick?:()=>void,
    disable?:any,

}
export const ButtonToolbarWarehouse: React.FC<ButtonToolbarProps> = ({

    onClick,
    disable,

}) => {
    let profile = { isBeginner: true }; //let profile = localStorage.getItem("profile"); // type --> Profile
  const buttonToolbar = useRef(null);

    const [showInventoryForm, setShowInventoryForm] = useState< any>();
    const [options, setOptions] = useState<any>()
    const [selectoptions, setSelectOptions] = useState<any>()
    const showSubMenuRef = useRef<any>(false);
    const [inventory, setInventory] = useState< any>();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const layoutContext = useContext(LayoutContext);


/*     function Submit(e){
        e.preventDefault() 
        if(e.target.Warehouse.value){
          if(e.target.Warehouse.value !=undefined && inventory !=undefined){
              localStorage.setItem("Inventory" ,JSON.stringify({Id:inventory.Id , Name:inventory.Name.Description}) )
              onClick&& onClick()
          }
          setShowInventoryForm(false)
        }
    
      }
 */
    function showSubMenuFunction() {
        setShowSubMenu(true)
        showSubMenuRef.current = true
    }
    function hideSubMenuFunction() {
        setShowSubMenu(false)
        showSubMenuRef.current = true

    }
    function selectboxValSetter(event , obj) {
        setSelectOptions(event.currentTarget.getAttribute('data-value'))

          if( obj.Id !=undefined){
              localStorage.setItem("Inventory" ,JSON.stringify({Id:obj.Id , Name:obj.Name}) )
              onClick&& onClick()
          }   
          setShowSubMenu(false)
        if (buttonToolbar.current) (buttonToolbar.current as HTMLButtonElement).blur();
    }

    async function getDataAsync() {
        try {
            const res = await getWarehouseDictionaryWithAccessApi()
            setOptions(res.Data);
        } catch (error) {

            setOptions(null)
        }

    }
    useEffect(() => {
        getDataAsync();

    }, [layoutContext.currentUserPosition, layoutContext.currentApplication]);
    return (
        <>
{/*         {showInventoryForm&& 
        <ModalForm mode={"edit"}  title={"currentinventoryselection" } onHide={() => setShowInventoryForm(false)} show={showInventoryForm}>
          <div className="card">
            <div className="card-body">
              <form onSubmit={Submit}>
              <div className="row col-3">
                <WarehouseDictionaryWithAccess
                  name="Warehouse"
                  label="Warehouse"
                  value={JSON.parse(localStorage.getItem("Inventory")+"")?.Id}
                  onChange={(e ,obj)=>setInventory(obj)}                          
                  mode={"create"}
                  required={true}
                  />
              </div>

              <div className="col-lg-12 mt-1 btns_form">
                  <Button btnType="submit" value="Save"  />
              </div>
              </form>
            </div>
          </div>
        </ModalForm>   
      }
       */}

        {options && <div className={"toolbar-btn-wrapper  ButtonToolbarWarehouse"}>
          <button ref={buttonToolbar} onClick={() => { }} onFocus={showSubMenuFunction} onBlur={hideSubMenuFunction}   className={`btn ${disable ? 'btn-outline-secondary' : 'btn-outline-primary'}  toolbar-btn d-flex flex-column mx-1 justify-content-center align-items-center`}   role="button" aria-disabled={disable} disabled={disable}  style={{width:"100px"}}>
          {localStorage.getItem("Inventory")&&
                         <div style={{fontWeight:"600"}} className={`toolbar-btn__hotkey ${disable ? "text-secondary" : ""} ${profile == undefined ? "" : (profile.isBeginner ? "" : "professional-customer")} mb-0`}>انبارجاری</div>
                         }
                         <div className="d-flex justify-content-between align-items-center" style={{direction:'ltr'}}>
                           <small className="small">{JSON.parse(localStorage.getItem("Inventory")?localStorage.getItem("Inventory")+"":JSON.stringify({Name:trans("currentinventoryselection")}))?.Name}</small>
                           <div className="small" style={{marginLeft:'0'}}><FontAwesomeIcon icon={[ICON_TYPE , "angle-down"] }/></div>
                         </div>
                        <div className={`toolbar-btn-sub-menu mt-1 ${showSubMenu ? "" : "d-none"}`}>
                            {options && Object.keys(options).map((option, index) => (
                                selectoptions && option ==selectoptions  ?
                                <div key={index} className="toolbar-btn-sub-menu__option "  data-value={`${option}`} onClick={(e)=>{selectboxValSetter(e,{Id:option , Name:options[option].Description})}}>{options[option].Description	}</div>
                               :
                                <div key={index} className="toolbar-btn-sub-menu__option" data-value={`${option}`} onClick={(e)=>{selectboxValSetter(e,{Id:option , Name:options[option].Description})}}>{options[option].Description	}</div>
                            ))}
        
                        </div>
                    </button>
                </div>
                }
        </>
    )
}