import { Toast } from "components/ToastShow/ToastShow";

import { useEffect, useState } from "react";
import { trans, uuidv4 } from "utils/helpers";

import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Message } from "components/Message/Message";
import { Person } from "components/Person/Person";
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { CustomerDictionary } from "../../SalesInvoice/components/CustomerDictionary/CustomerDictionary";

interface RelatedCustomerFormPropsType {
  onHide: () => void;
  CustomerRelationsCallBack: (e) => void;
  mode: any;
  CustomerRelations: any;
  CustomerId?: string;
}
export const RelatedCustomerForm = ({
  onHide,
  CustomerRelationsCallBack,
  mode,
  CustomerRelations,
  CustomerId,
}: RelatedCustomerFormPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [userChanges, setUserChanges] = useState({});
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [editForm, setEditForm] = useState<any>({});
  const [indexEditForm, setIndexEditForm] = useState<number>(0);
  const [relatedMode, setRelatedMode] = useState<"create" | "edit">("create");
  const [relatedList, setRelatedList] = useState<any>(CustomerRelations);
  const [customer, setCustomer] = useState<any>();
  const [relationType, setRelationType] = useState<any>();

  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    const filteredData=relatedList.filter((item) => item.Id != id)
    setRelatedList(filteredData);
    CustomerRelationsCallBack(filteredData);
  };


  const handleEdit = (id) => {
    relatedList.map((item, index) => {
      if (item.Id == id) {
        setEditForm(item);
        setIndexEditForm(index);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data: any = {};
    data = {
      Id: relatedMode == "create" ? uuidv4() : editForm.Id,
      // CustomerId: CustomerId?CustomerId:null,
      CustomerRelatedId: event.target.CustomerRelatedId?.value,
      CustomerRelationTypeId: event.target.CustomerRelationTypeId?.value,
      CustomerRelated: customer.Name,
      CustomerRelationType: relationType.Name,
    };

    if (relatedMode == "create") {
      setRelatedList([...relatedList, data]);
      CustomerRelationsCallBack([...relatedList, data]);
    } else {
      let newRelated = JSON.parse(JSON.stringify(relatedList));
      newRelated.splice(indexEditForm, 1, data);
      CustomerRelationsCallBack(newRelated);
      setRelatedList(newRelated);
    }
    setResetForm(true);
    setResetForm(true);
  };


  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);



  useEffect(() => {
    if(CustomerRelations && CustomerRelations.length > 0){
        CustomerRelationsCallBack(CustomerRelations);
        setRelatedList(CustomerRelations);
    }
  },[CustomerRelations])

  return (
    <>
      {mode != "view" && (
        <div className="card">
          <form className="TypeSettingForm  card-body" onSubmit={handleSubmit}>
            <div className="row my-1">
              <div className="col-12 col-sm-3 ">
                <CustomerDictionary
                  value={editForm?.CustomerRelatedId}
                  name="CustomerRelatedId"
                  label="CustomerRelated"
                  resetForm={resetForm}
                  required={true}
                  returnObj
                  onChange={(e) => {
                    setCustomer(e);
                  }}
                />
              </div>
              <div className="col-12 col-sm-3 ">
                <DictionarySelectBox
                  value={editForm?.CustomerRelationTypeId??''}
                  required={true}
                  baseUrl={INVENTORYANDSALES_URL}
                  endPoint={
                    ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                      .CustomerRelationType.dictionary
                  }
                  label="CustomerRelationType"
                  name="CustomerRelationTypeId"
                  resetForm={resetForm}
                  onChange={(e, obj) => {
                    setRelationType(obj);
                  }}
                />
              </div>
            </div>
            {relatedMode == "create" ? (
              <Button value="Save" btnType="submit" />
            ) : (
              <>
                <Button value="Save" btnType="submit" />
                <Button
                  value="cancel"
                  btnType="cancel"
                  onClick={() => {
                    setEditForm(null);
                    setRelatedMode("create");
                    setResetForm(true)
                  }}
                />
              </>
            )}
          </form>
        </div>
      )}
      <div className="card card-body">
        {relatedList?.length > 0 ? (
          <>
            <DataGrid
              parentName={"RelatedCustomer"}
              theadData={Object.keys(relatedList[0])}
              tbodyData={relatedList}
              scrollHeight={"67vh"}
              startIndex={3}
              minCellWidth={80}
              selectedRowObjectCallback={setSelectedObj}
              handleDelete={(e) => {
                Toast(trans("msg_deleteconfirm"), "warning", "alert", () =>
                  handleDeleteRow(e)
                );
              }}
              handleEdit={(e) => {
                handleEdit(e);
                setRelatedMode("edit");
              }}
            />
          </>
        ) : (
          <Message message={"NoDataForPreview"} type={"info"} />
        )}
      </div>
    </>
  );
};
