
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toolbar } from "components/Toolbar/Toolbar";
import { addPositionRole, delPositionRole, getPositionRole } from "pages/SystemManagement/Settings/Users/api";
import { trans } from "utils/helpers";
import { PositionRoleForm } from "./PositionRoleForm";

interface propsOrganizationalStructure {
    position:any
    inTab:any
}
export const PositionTree = ({position , inTab }:propsOrganizationalStructure) => {
  const [data, setData] = useState<any>([])
  const [dataPo, setDataPo] = useState<any>([])
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedObj, setSelectedObj] = useState<any>()
  const [selectedList, setSelectedList] = useState<any>([])
  const [selectedIdList, setSelectedIdList] = useState<any>([])
  const [selectedRole, setSelectedRole] = useState<any>([])
  const [selectedListPo, setSelectedListPo] = useState<any>([])
  const [selectedIdListPo, setSelectedIdListPo] = useState<any>([])
  const [selectedRolePo, setSelectedRolePo] = useState<any>([])
  const [idCallback, setIdCallback] = useState<any>("2")
  const [messageType, setMessageType] = useState<any>("info")
  const [showAddForm, setShowAddForm] = useState(false)
  const [disabled, setDisabled] = useState<any>(true);
  const [stopEdit, setStopEdit] = useState<any>(false);
  let lastEditedList:any = []

  //   const [theadData , setTheadData] = useState<any>()

  let theadData: any = ["OrganizationRoles" ,"OrganizationRoles","OrganizationRoles","OrganizationRoles"]
  let theadDataPo: any = ["PositionRoles" , "PositionRoles", "PositionRoles", "PositionRoles"]
  let obj: any = {}
  let objPo: any = {}
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "print":
        alert("print ");
        break;
      case "edit":
        setDisabled(!disabled)
        break;
      case "remove":
        break;
      // case "excel":
      //   alert("excel ");
      //   break;
      case "refresh":
        setForceUpdate(!forceUpdate)
        break;
      case "create":
        setShowAddForm(!showAddForm)  
        break;
    }
  };

  function setPo(name, value) {
    var obj2Po = objPo;  
    var nameList = name.split('.');
    var len = nameList.length;
    for(var i = 0; i <len-1; i++) {
        var elem = nameList[i];
        if( !obj2Po[elem] ) obj2Po[elem] = {}
        obj2Po = obj2Po[elem];
    }
    obj2Po[nameList[len-1]] = value;
}
  function set(name, value ) {
    var obj2 = obj;  
    var nameList = name.split('.');
    var len = nameList.length;
    for(var i = 0; i <len-1; i++) {
        var elem = nameList[i];
        if( !obj2[elem] ) obj2[elem] = {}
        obj2 = obj2[elem];
    }
    obj2[nameList[len-1]] = value;
}


  function setTree(obj2 , parentId ,title) {
    let list:any = Object.keys(obj2)
    let tree:any = []
    if(typeof obj2 != "string"){
    list.forEach(item=>{
      tree.push({
        Id : parentId+item ,
        parentId : parentId ,
        Children : setTree(obj2[item] , parentId+item+"." ,title ) ,
        [title] : item
      })
    })
    }
  return tree
}
  function setTreePo(obj2Po , parentId ,titlePo) {
    let list:any = Object.keys(obj2Po)
    let tree:any = []
    if(typeof obj2Po != "string"){

    list.forEach(item=>{
      tree.push({
        Id : parentId+item ,
        parentId : parentId ,
        Children : setTreePo(obj2Po[item] , parentId+item+"." ,titlePo ) ,
        [titlePo] : item
      })
    })

    }
    console.log("tree ",tree)
  return tree
}


async function addNewPositionRoles(list) {
  setForceUpdate(!forceUpdate)
  setStopEdit(true)
// setLoading(false) ;
// [...selectedRolePo,...list].forEach(item => {
//   setSelectedListPo(Object.assign(selectedListPo, {[item.Name ]: item?.HasAccess}))
//   setSelectedIdListPo(Object.assign(selectedIdListPo, {[item.RoleId ]: item?.Name}))
//   setPo( item.Name,  item.RoleId);
// });
// let tabletrPo =  setTreePo(objPo , "" ,"PositionRoles")
// setDataPo(tabletrPo)
// setLoading(true) ;
}


async function editOrganizationRoles(list) {
 let addList:any = []
 let delList:any = []
  selectedRole.forEach(item=> {
    if( !list.includes(item.Name)){
      if(item?.HasAccess){
        delList.push(item.RoleId)
      }
    }else{
      if(!item?.HasAccess){
        addList.push(item.RoleId)
      }

    }
  });

  try {
      if(delList.length > 0){
        
        const delResponse =  delPositionRole(position.Id ,delList)
        
      }
      if(addList.length > 0){
          const addResponse =  addPositionRole(position.Id ,addList)
      }
    
    let newselectedRole = selectedRole
    newselectedRole.forEach(item=> {
      if( delList.includes(item.RoleId)){
          item.HasAccess = false
      }else if(addList.includes(item.RoleId)){
      
        item.HasAccess = true
  
      }
    });
      setSelectedRole(newselectedRole)
      
  } catch (err: any) {
      
  }
  
}
async function editPositionRoles(list) {
 let addList:any = []
 let delList:any = []
 if(!stopEdit){
   selectedRolePo.forEach(item=> {
     if( !list.includes(item.Name)){
       if(item?.HasAccess){
         delList.push(item.RoleId)
       }
     }else{
       if(!item?.HasAccess){
         addList.push(item.RoleId)
       }
 
     }
      });
 
   try {
       if(delList.length > 0&&JSON.stringify(lastEditedList)!= JSON.stringify(delList)){
         lastEditedList = delList
         const delResponse = await delPositionRole(position.Id ,delList)
       }
       if(addList.length > 0&&lastEditedList!= addList){
         lastEditedList =addList
 
         const addResponse =  addPositionRole(position.Id ,addList)
       }
     
       let newselectedRole = selectedRolePo
       newselectedRole.forEach(item=> {
       if( delList.includes(item.RoleId)){
           item.HasAccess = false
       }else if(addList.includes(item.RoleId)){
       
         item.HasAccess = true
   
       }
     });
       setSelectedRolePo(newselectedRole)
       
   } catch (err: any) {
       
   }

 }else{
  setStopEdit(false)
 }
  
}

async function getTreeRoleAsync() {
  setLoading(false)
    try {
      const response2:any = await getPositionRole(position.Id)
      setSelectedRolePo(response2.PositionRoles)
      response2.PositionRoles.forEach((item:any )=> {
        setSelectedListPo(Object.assign(selectedListPo, {[item.Name ]: item?.HasAccess}))
        setSelectedIdListPo(Object.assign(selectedIdListPo, {[item.RoleId ]: item?.Name}))  
        setPo( item.Name,  item.RoleId);
      });
      setSelectedRole(response2.OrganizationRoles)
      response2.OrganizationRoles.forEach(item => {
        if(item && item.Name){
          setSelectedList(Object.assign(selectedList, {[item.Name ]: item?.HasAccess}))
          setSelectedIdList(Object.assign(selectedIdList, {[item.RoleId ]: item?.Name}))   
          set( item.Name,  item.RoleId );
        }
      });
      let tabletrPo =  setTreePo(objPo , "" ,"PositionRoles")
      let tabletr =  setTree(obj , "" ,"OrganizationRoles")
      if(tabletr.length>0){
        setData(tabletr)
      }
      if(tabletrPo.length>0){
        setDataPo(tabletrPo)
      }

  } catch (err: any) {
   
  }
  setLoading(true)
}




  useEffect(() => {
        if(inTab && inTab==position.Title){
          setSelectedRole([])
          setSelectedIdList([])
          setSelectedList([])
          setSelectedRolePo([])
          setSelectedIdListPo([])
          setSelectedListPo([])
          getTreeRoleAsync();
        }else if(inTab == null ||inTab == undefined ){
          setSelectedRole([])
          setSelectedIdList([])
          setSelectedList([])
          setSelectedRolePo([])
          setSelectedIdListPo([])
          setSelectedListPo([])
          getTreeRoleAsync();
        }
    
}, [inTab,forceUpdate])

  if (data.length > 0) {
    theadData = Object.keys(data[0])
  }

  if (dataPo.length > 0) {
    theadDataPo = Object.keys(dataPo[0])
  }



  return (
    <div className="p-2">
      {showAddForm ?<ModalForm mode={"edit"} onHide={()=>{setShowAddForm(!showAddForm)}} show={showAddForm} modalProfileKey={"Users"} title={trans("Assignment")+" "+trans("Role") } ><PositionRoleForm selectedObj={position} addNewPositionRoles={addNewPositionRoles} reload={()=>{setForceUpdate(!forceUpdate)}  } onHide={setShowAddForm}  /> </ModalForm>  : null}
      <Toolbar excel={false} edit={true}  id={position} create={true} view={false} search={false} handleClickToolbar={clickToolbarCallback} />
      <div className="col-lg-12 mt-0">
        <div className="list-card card d-flex flex-row">
          <>
            {
              loading ? (

                
                <>
                {
                <>
                <div style={{maxWidth:"50%"}} className="card">
                    <TableTree
                      disabled={disabled}
                      selectedList={selectedList}
                      ListResponse={selectedIdList}
                      multiselect={true}
                      selectedRowObjectCallback={setSelectedObj}
                      selectedRowsCallback={editOrganizationRoles}
                      selectedRowObject={idCallback}
                      //getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                      Data={data}
                      theadData={theadData}
                      Titleparams={"OrganizationRoles"}
                      parentName={"Roles"}
                      startIndex={2}
                      defultShow={false}
    
                    />
                </div>
                </>
                }
                {
                dataPo ?
                <>
                <div style={{maxWidth:"80%",minWidth:"10%",width:"80%"}} className="card">
                     <TableTree
                       maxWidth =  {true}
                       disabled={disabled}
                       selectedList={selectedListPo}
                       ListResponse={selectedIdListPo}
                       multiselect={true}
                       selectedRowsCallback={editPositionRoles}
                       selectedRowObjectCallback={setSelectedObj}
                       selectedRowObject={idCallback}
                       //getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                       Data={dataPo}
                       theadData={theadDataPo}
                       Titleparams={"PositionRoles"}
                       parentName={"Roles"}
                       startIndex={2}
                       defultShow={false}

                     />
                </div>
                </>
                  : <Message message={"NoDataForPreview"} type={messageType} />
                   }
                  </>)
                : <Loading />
            }
          </>
        </div>

      </div>
    </div>
  )
}