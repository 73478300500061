import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { deleteDataCollectionProperty, getDataCollectionProperties,postPropertyDataCollection } from "./api";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";


interface DataCollectionPropertiesPropsType {
  onHide: (e: boolean) => void;
  selectedRow?:any
  mode: "create" | "view" | "edit";
}

export const DataCollectionPropertiesForm = ({
  onHide,
  selectedRow,
  mode,
}: DataCollectionPropertiesPropsType) => {
  const [userChanges, setUserChanges] = useState({});
  const [resetForm, setResetForm] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pages, setPages] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [response, setResponse] = useState<any>([]);
  const [selectedObj, setSelectedObj] = useState<string | any>(null);
  const [showPropertiesForm, setShowPropertiesForm] = useState(false);
  const [forceUpdate,setForceUpdate] = useState <boolean>(false)
  const [showForm, setShowForm] = useState(false);
  const [detailMode, setDetailMode] = useState<"create" | "view" | "edit">();




  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
        Code: event.target.Code?.value,
        Value: event.target.Value?.value,
      Description: event.target.Description?.value,
      DescriptionEN: event.target.DescriptionEN?.value,
      OwnerId:selectedRow.Id
    };
    try {
      if(detailMode==='create'){
        await postPropertyDataCollection(data,'post');
      }else{
        await postPropertyDataCollection({...data,Id:selectedObj.Id,ActivityStatus: +event.target.ActivityStatus?.value,
        },'put');

      }
      setForceUpdate(!forceUpdate)
    } catch (error) {}
    cancel()
  };




  const getDataCollectionPropertiesAsync = async (PageNum = pageNum) => {
    setLoading(false);
    let pageSizeChangedByUser = getPageSize("DataCollectionProperties");
    setPageSize(pageSizeChangedByUser);
    try {
      const res = await getDataCollectionProperties(PageNum, pageSizeChangedByUser,selectedRow.Id);
      if (res.Data.Data.length > 0) {
        setResponse(res.Data);

        setPages(
          res.Data.TotalRecords > pageSizeChangedByUser
            ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser)
            : 1
        );
      }
    } catch (error) {}
    setLoading(true);
  };


  const handleDeleteRow = async () => {
    try {
      const res = await deleteDataCollectionProperty(selectedObj.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };

  function cancel() {
    setShowForm(false);
  }


  const handleChangePageSize = (e: any) => {
    setPageNum(1);
    setPageSize(e.currentTarget.value);
    getPageSize("DataCollectionProperties", e.currentTarget.value);
    getDataCollectionPropertiesAsync(1);
  };

  const handleGoPage = (value) => {
    if (value) {
      if (value < 1 || value > pages) {
      } else setPageNum(+value);
      getDataCollectionPropertiesAsync(+value);
    } else {
    }
  };
  const handleNextPage = () => {
    if (pageNum < pages) {
      setPageNum(pageNum + 1);
      getDataCollectionPropertiesAsync(pageNum + 1);
    }
  };
  const handlePrevPage = () => {
    if (pageNum > 1) {
      setPageNum((pageNum) => {
        return +pageNum - 1;
      });
      getDataCollectionPropertiesAsync(pageNum - 1);
    }
  };

  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
}


const clickToolbarCallback = (clickedButtonName: string) => {
  switch (clickedButtonName.toLowerCase()) {
    case "excel":
      downloadCSV(response.Data, 1, trans("dataCollectionPrperties"));
      break;
    case "refresh":
      setForceUpdate(!forceUpdate);
      break;
    case "create":
      setShowForm(true);
      setDetailMode("create");
      break;
    case "remove":
      if (selectedObj?.Id)
        Toast(
          trans("msg_deleteconfirm"),
          "warning",
          "alert",
          handleDeleteRow
        );
      break;
    case "view":
      if (selectedObj?.Id) {
        setShowForm(true);
        setDetailMode("view");
      }
      break;
    case "properties":
      if (selectedObj?.Id) setShowPropertiesForm(true);
      break;
    case "edit":
      if (selectedObj?.Id) {
        setShowForm(true);

        setDetailMode("edit");
      }
      break;
  }
};


  useEffect(() => {
    getDataCollectionPropertiesAsync();
  }, [forceUpdate]);

  return (
    <>


<Toolbar
        helpBtn={false}
        Print={false}
       
        id={selectedObj?.Id}
        emptyList={response?.TotalRecords == 0 || response?.Data == null}
        remove={true}
        create={true}
        view={true}
        edit={true}
        search={true}
        handleClickToolbar={clickToolbarCallback}
      />
      { showForm &&
      <div className="card ">
      <form className="TypeSettingForm row  card-body " onSubmit={handleSubmit}>
      {mode != "view" ? (
          <div className="col-lg-12 mt-1 btns_form">
            <Button value="Save" btnType="submit" />
            <Button
              btnType="cancel"
              value="Cancel"
              onClick={() => {
                cancel();
              }}
            />
          </div>
        ) : (
          <div className="col-lg-12 mt-1 btns_form">
            <Button
              btnType="primary"
              value="close"
              onClick={() => {
                cancel();
              }}
            />
          </div>
        )}
        <div className="col-md-4 col-sm-12">
          <Textbox
            Change={(e) => {
              setUserChanges({ ...userChanges, ...e });
            }}
            required={true}
            textboxName={"Code"}
            textboxType="text"
            resetForm={resetForm}
            labelText={"Code"}
            mode={detailMode}
            defaultValue={detailMode!=='create'?selectedObj.Code:''}
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <Textbox
            Change={(e) => {
              setUserChanges({ ...userChanges, ...e });
            }}
            textboxName={"Value"}
            textboxType="text"
            resetForm={resetForm}
            labelText={"Value"}
            mode={detailMode}
            defaultValue={detailMode!=='create'?selectedObj.Value:''}

          />
        </div>
        {detailMode != "create" && (
          <div className="col-md-4 mt-1">
            <EnumerationTypeSelect
                             value={selectedObj?.ActivityStatus}
                             Change={(e) => {
                               setUserChanges({ ...userChanges, ...e });
                             }}
                             name="ActivityStatus"
                             enumTypeSelect="ActivityStatus"
                             mode={detailMode}
                             label="Status"
            />
          </div>
        )}
        <div className="col-md-4 col-sm-12">
          <Textbox
            Change={(e) => {
              setUserChanges({ ...userChanges, ...e });
            }}
            textboxName={"Description"}
            textboxType="textarea"
            resetForm={resetForm}
            labelText={"Description"}
            mode={detailMode}
            defaultValue={detailMode!=='create'?selectedObj.Description:''}

          />
        </div>
        <div className="col-md-4 col-sm-12">
          <Textbox
            Change={(e) => {
              setUserChanges({ ...userChanges, ...e });
            }}
            textboxName={"DescriptionEN"}
            textboxType="textarea"
            resetForm={resetForm}
            labelText={"DescriptionEN"}
            mode={detailMode}
            defaultValue={detailMode!=='create'?selectedObj.DescriptionEN:''}

          />
        </div>
      

      </form>

      </div>
      }

      {loading ? (
        <>
          {response?.Data?.length > 0 ? (
            <div className="card">
              <div className="card-body p-0">
                <>
                  <DataGrid
                    DoubleClick={(obj) => {
                      setSelectedObj(obj);
                      // clickToolbarCallback("view");
                    }}
                    View={true}
                    ViewParametr={"NotSeen"}
                    // handlefiltering={(str: any) => {
                    //   str.Filterlist != undefined
                    //     ? setFilterAndSort(str)
                    //     : setFilterAndSort({
                    //         ...filterAndSort,
                    //         Filterlist: str,
                    //       });
                    // }}
                    // filterStr={filterAndSort.Filterlist}
                    // orderFormatList={filterAndSort.OrderFormat}
                    // orderFormat={(order) => {
                    //   setFilterAndSort({
                    //     ...filterAndSort,
                    //     OrderFormat: order,
                    //   });
                    // }}
                    parentName={"SalesInvoiceList"}
                    theadData={Object.keys(response.Data[0])}
                    tbodyData={response.Data}
                    minCellWidth={80}
                    selectedRowObjectCallback={selectedObjCallback}
                    rowSelected={selectedObj}
                    startIndex={1}
                    // handleView={(e) => {
                    //   setSelectedObj({ Id: e });
                    //   setMode("view");
                    //   setShowSalesInvoiceForm(true);
                    // }}
                    totalRecords={response.TotalRecords}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    HandleNextPage={handleNextPage}
                    HandlePrevPage={handlePrevPage}
                    handlechangePageSize={handleChangePageSize}
                    // first={() => {
                    //   getSalesInvoicesAsync(pages);
                    //   setPageNum(pages);
                    // }}
                    // end={() => {
                    //   getSalesInvoicesAsync(1);
                    //   setPageNum(1);
                    // }}
                    handleGoPage={handleGoPage}
                  />
                </>
              </div>
            </div>
          ) : (
            <Message message={"NoDataForPreview"} type={messageType} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
