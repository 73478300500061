import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import { DynamicComponentHOC } from "components/DynamicComponentHOC/DynamicComponentHOC";
import { GroupPerson } from "components/GroupPerson/GroupPerson";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssignTypes, CartableType } from "types/enum";
import { DynamicForm } from "types/types";
import { DYNAMICFORMS } from "utils/dynamicForms";
import { ENDPOINTS } from "utils/endpoints";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { CancelFinishedReference, DeleteReference, FinishedReference, ReferReverse, RejectReference, UnderFollowup, acceptReference, getCartable, getJobReferrer, getJobRequester, getStartForm, getViewForm, ongoingReference, undoReference } from "./api/api";
import { ActionList } from "./components/ActionList/ActionList";
import { Actions } from "./components/ActionList/components/Actions/Actions";
import { Attachment } from "./components/ActionList/components/Attachment/Attachment";
import { ReferencesActions } from "./components/ActionList/components/RefrenceAndActionsForm/RefrenceAndActions";
import { ArchiveForm } from "./components/ArchiveForm/ArchiveForm";
import { getArchivedJob } from "./components/ArchiveForm/api";
import { JobSendForm } from "./components/JobSendForm/JobSendForm";
import { ReferenceStepper } from "./components/ReferenceStepper/ReferenceStepper";
import { TerminatedForm } from "./components/Terminated/Terminated";
import { Toolbar } from "./components/Toolbar/Toolbar";
import { GUID_EMPTY } from "utils/constants";
import { TabBar } from "components/Tabbar/Tabbar";
import { AcceptSelfService } from "./components/AcceptSelfService/AcceptSelfService";
import { ReferenceTree } from "./components/ActionList/components/ReferencesTree/ReferencesTree";



interface propsCartablePage {
    type?: any; // => type is CartableType
}
export const CartablePage = ({ type }: propsCartablePage) => {
    let keys, cartable = [];
    let Total_records, pages;
    let [dynamicForm, setDynamicForm] = useState<DynamicForm | any>("GeneralRequestForm");
    let [showDynamicForm, setShowDynamicForm] = useState<boolean>(false);
    const [showDynamicFormView, setShowDynamicFormView] = useState<boolean>   (false);
    const [response, setResponse] = useState<Response | any>(null);
    const [referenceId, setReferenceId] = useState<string | any>(null);
    const [rowObject, setRowObject] = useState<string | any>(null);
    const referenceIdRef = useRef(referenceId);
    const [selectedStartingProcessId, setSelectedStartingProcessId] = useState<string | any>(null);
    const [selectedProcessFilterId, setSelectedProcessFilterId] = useState<string | any>(null);
    const [showActionForm, setShowActionForm] = useState<boolean>(false);
    const [showTerminatedForm, setShowTerminatedForm] = useState<boolean>(false);
    const [showReminder, setShowReminder] = useState(false);
    const [reload, setReload] = useState(false); // integer state
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [mode, setMode] = useState<string>();
    const [showReference, setShowReference] = useState(false);
    const [currentModalWidth, setCurrentModalWidth] = useState(70);
    const [showAttachment, setShowAttachment] = useState(false);
    const [ShowReferDescriptionForm, setShowReferDescriptionForm] = useState(false);
    const [ShowReferenceAndActions, setShowReferenceAndActions] = useState(false);
    const [ShowReferenceTree, setShowReferenceTree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ShowDynamicFormViewLoading, setShowDynamicFormViewLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [endpoint, setEndpoint] = useState<any>();
    const [showArchiveForm, setShowArchiveForm] = useState<any>(0);
    const [showJobSendForm, setShowJobSendForm] = useState<any>(false);
    const [typeOfDataGrid, setTypeOfDataGrid] = useState<any>(type);
    const layoutContext = useContext(LayoutContext);
    const [search, setsearch] = useState(null);
    const [showActions, setShowActions] = useState(false);
    
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const [showstep, setShowstep] = useState<any>({});
    const [showAccept, setShowAccept] = useState<any>(true);
    const [Updated, setUpdated] = useState<any>(true);
    const [archivedList, setArchivedList] = useState<any>()
    const [curentTab, setCurentTab] = useState<any>("request")

    let customFuction: any = []
    const navigate = useNavigate();


    if(type ==CartableType.Current ||type === CartableType.Ongoing ) {
        customFuction.push([(e) => { setReferenceId(e.Id); setMode("view"); processViewCallback(e?.ProcessId);}, "eye", "view"])
        customFuction.push([(e) => { setReferenceId(e.Id);setMode("edit"); setShowReference(true);}, "share-from-square", "Reference"])
        customFuction.push([(e) => { setReferenceId(e.Id);setMode("edit"); setShowActionForm(true);}, "bolt", "action"])
        customFuction.push([(e) => { chatWithRequester(e.JobId) }, "paper-plane", "ChatWithRequester"])
        customFuction.push([(e) => { chatWithReferrer(e.Id) }, "paper-plane", "ChatWithReferrer"])
        customFuction.push([(e) => { setReferenceId(e.Id); setShowActions(true) }, "bolt", trans("submit")+" "+trans("report") ])
        customFuction.push([(e) => { setReferenceId(e.Id);setShowTerminatedForm(true)}, "flag-checkered", "finish"])
        customFuction.push([(e) => { setShowReferDescriptionForm(true) }, "reply", "reverse"])
        customFuction.push([(e) => { ongoingReferenceAsync(e.Id, true) }, "hourglass-half", "Ongoing"])
        customFuction.push([(e) => { acceptReferenceAsync(e.Id) }, "check-circle", "accept","AssignType" ,AssignTypes.SELF_SERVICE])
    }else if (type == CartableType.Referred){
        customFuction.push([(e) => { undoReferenceAsync(e.Id) }, "undo", "Undo"])
        customFuction.push([(e) => { UnderFollowupAsync(e.Id, true) }, "hourglass-half", "UnderFollowup"])
    }
    if( type !== CartableType.Draft){
        customFuction.push([(e) => { setReferenceId(e.Id); setShowReferenceAndActions(true) }, "clipboard", "referenceandactionshistory"])
        customFuction.push([(e) => { setReferenceId(e.Id); setShowReferenceTree(true) }, "clipboard", "referencestree"])
    }
    
    const tabChangeHandle = (tab) => {
        setCurentTab(tab)
    } 

    const Searchhandleer = (str) => {
        setPageNum(1);
        referenceIdRef.current = null
        setReferenceId(null);
        setRowObject(null);
        if (str === "") {
            setsearch(null)
            getCartableAsync(1, null )
    
        } else {
            setsearch(str)
            getCartableAsync(1 ,str)
        }
    }

    const handleAttachmentRow = (id) => {
        setEndpoint(ENDPOINTS.cartable.reference.attachments)
        setReferenceId(id)
        setShowAttachment(true)
    }
    const handlechangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("cartable" + type + `${selectedProcessFilterId}`, e.currentTarget.value)
        getCartableAsync(1)
    };

    const handleGoPage = (value) => {
        if (value) {
            if (value < 1 || value > pages) { }
            else { setPageNum(value); getCartableAsync(value); }
        } else {

        }
    };

    const HandleNextPage = () => {
        if (pageNum < pages) {
            setPageNum(+pageNum + 1);
            getCartableAsync(+pageNum + 1);
        }
    };
    const HandlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getCartableAsync(+pageNum - 1);
        }
    };
    /* pagination area */



    const showDynamicFormViewCallback = (ans: boolean) => {
        setShowDynamicFormView(ans);
    }
    async function getStartFormAsync(id: string) {
        const lang = localStorage.getItem("lang");

        document.body.classList.add("loading-indicator-"+lang);
        try {
            setMode("create");
            const res = await getStartForm(id, layoutContext.currentUserPosition.PositionId);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
                setShowDynamicForm(true);
            }
            else { setShowDynamicForm(false) }

        } catch (error) {
            setDynamicForm(undefined);
            setShowDynamicForm(false)

        }
        document.body.classList.remove("loading-indicator-"+lang);
    }
    async function getFormViewAsync(id: string) {
       
        
        setShowDynamicFormViewLoading(false)
        try {
   
            const res = await getViewForm(id, layoutContext.currentUserPosition.PositionId);
            if (res.Data)
                setDynamicForm(res.Data);
            else
                setDynamicForm(undefined);
            if (dynamicForm) {
                setShowDynamicFormView(true);
            }
            else { setShowDynamicFormView(false) }

        } catch (error) {
            setDynamicForm(undefined);
            setShowDynamicFormView(false)

        }
        setShowDynamicFormViewLoading(true)

    }
    async function chatWithRequester(e) {
            try {
                const res = await getJobRequester(e);
                if(res?.Data.Id && res?.Data.Id != GUID_EMPTY){

                    let item = {
                        Id:res?.Data.Id ,
                        name: res?.Data.Description
                    }
                    localStorage.setItem("JobRequester",JSON.stringify(item) );
                    window.open(`#/message`)
                }
            } catch (error) {

            }
            setShowReferDescriptionForm(false)
    }
    async function chatWithReferrer(e) {
            try {
                const res = await getJobReferrer(e);
                if(res?.Data.Id && res?.Data.Id != GUID_EMPTY){
                let item = {
                    Id:res?.Data.Id ,
                    name: res?.Data.Description
                }
                localStorage.setItem("JobRequester",JSON.stringify(item) );
                window.open(`#/message`)
              }
            } catch (error) {

            }
            setShowReferDescriptionForm(false)
    }
    async function ReferreverseAsync(e) {
        e.preventDefault()
        let ReverseDescription = e.target.ReverseDescription.value
        if (ReverseDescription.length > 0) {
            try {
                await ReferReverse(referenceId, ReverseDescription , e?.target?.sendMessageToPerformer.checked);
              //setReload(true)
              setShowReferDescriptionForm(false)
              getCartableAsync()
            } catch (error) {
            }
        }
    }

    async function ongoingReferenceAsync(referenceId, status) {
        try {
            await ongoingReference(referenceId, status);
        } catch (error) {

        }
        getCartableAsync()

    }
    async function UnderFollowupAsync(referenceId, status) {
        try {
            await UnderFollowup(referenceId, status);
        } catch (error) {

        }
        getCartableAsync()

    }
    async function acceptReferenceAsync(referenceId) {
        try {
            await acceptReference(referenceId);
            setShowAccept(false)
        } catch (error) {

        }
        getCartableAsync()

    }
    async function undoReferenceAsync(referenceId) {
        try {
            await undoReference(referenceId);
            getCartableAsync()
        } catch (error) {

        }
    }
    async function handleDeleteReference(id = referenceId) {
        try {
            await DeleteReference(id);
            getCartableAsync()
        } catch (error) {

        }
    }

    const selectedStartingProcessCallback = (id: string) => {
        setMode("create");
        setSelectedStartingProcessId(id);
        setReferenceId(undefined);
        getStartFormAsync(id);
    };
    
    const processViewCallback = async (id: string) => {
        try {
        setSelectedStartingProcessId(id);
        getFormViewAsync(id);
        if(rowObject &&rowObject?.Archive){
            const archive = await getArchivedJob(rowObject?.JobId)
            setArchivedList(archive.Data)          
        }
          } catch (error) {
            
          }

    };

    const selectedProcessFilterCallback = (id: string) => {
        if (id === "00000000000000000000000000000000") {
            setSelectedProcessFilterId(null);
        } else {
            setSelectedProcessFilterId(id);
        }
    };
    const selectedArchiveCallback = () => {
        setShowArchiveForm(1)
    };

    const selectedRowCallback = (obj, index) => {
      setRowObject(obj);
       referenceIdRef.current = obj?.Id
       setReferenceId(obj?.Id);
    };

    const showReferenceStepperCallback = (referenceId2: string|boolean) => {
        if(referenceId2!==false){
            if(window.location.href.includes("draft")){
                setRowObject({Id:referenceId2 });
            }
            setReferenceId(referenceId2);
            localStorage.setItem("ShowNewReferene", "null"); 
             sessionStorage.setItem("changingforms", "false")
            setShowReference(true);
            setShowDynamicForm(false);
            setShowDynamicFormView(false);
        }
    };
    const print = () => {
        var printdata = document.getElementsByClassName("printForm")[0] ;
        let newwin:any = window.open("");
        newwin.document.write(printdata.outerHTML);
        newwin.print();
        newwin.close();
            
    };

    const clickToolbarCallback = (clickedButtonName: string, id?) => {
        switch (clickedButtonName.toLowerCase()) {
            case "reference":
                if (referenceId || id) { setMode("edit"); setShowReference(true); } else { Toast(trans("Msg_SelectRow"), "warning") }
                break;
            case "action":
                if (referenceIdRef.current) {
                    setMode("edit")
                    setShowActionForm(true);
                } else { Toast(trans("Msg_SelectRow"), "warning") }
                break;
            case "reminder": if (referenceId) { setMode("edit"); setShowReminder(true); } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "archive": if (referenceId) { selectedArchiveCallback() } break;
            case "current": alert("call api current " + referenceId); break;
            case "view": if (referenceId) {setMode("view");  processViewCallback(rowObject?.ProcessId); } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "edit": if (referenceId) { setMode("edit");  processViewCallback(rowObject?.ProcessId);} else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "print": print(); break;
            case "excel": downloadCSV(response.Data ,( typeOfDataGrid === CartableType.Current|| typeOfDataGrid === CartableType.Ongoing) ?7 :6 ,trans("cartable")+" "+trans(CartableType[type])); break;
            case "remove": if (referenceId){Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,()=>{ type === CartableType.Ongoing ? ongoingReferenceAsync(referenceId, false) :   type === CartableType.Followup ?UnderFollowupAsync(referenceId, false)   : handleDeleteReference() }  )}else { Toast(trans("Msg_SelectRow"), "warning") }     break;
            case "finish": if (referenceId) { setShowTerminatedForm(true) } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "refresh": setReload(true); break;
            case "reverse": if (referenceId) { setMode("edit"); setShowReferDescriptionForm(true) } else { Toast(trans("Msg_SelectRow"), "warning") }; break;
            case "undo": if (referenceId) { setMode("edit"); undoReferenceAsync(referenceId) } else { Toast(trans("Msg_SelectRow"), "warning") }; break;
            case "send": if (referenceId) { setMode("create"); setShowJobSendForm(true) } else { Toast(trans("Msg_SelectRow"), "warning") }; break;
            case "report": if (referenceId)setShowActions(true); break;
            case "attach": if (referenceId) {
                setEndpoint(ENDPOINTS.cartable.attachment)
                setMode("edit")
                setShowAttachment(true)
            } else {
                Toast(trans("Msg_SelectRow"), "warning")
            } break; 
        }
    };
    useEffect(() => {
        setLoading(false)
        if(type != typeOfDataGrid){
            setResponse({ Data: [] })
        }
        setTypeOfDataGrid(type)
        let settings = getGridSetting("cartable" + type + `${selectedProcessFilterId}`)
        setFilterAndSort({Filterlist:settings[0],OrderFormat:settings[1] })
        referenceIdRef.current = null
        setReferenceId(null);
        setRowObject(null);
    }, [type ]);

    async function getCartableAsync(pagNum = pageNum ,searchSTR=search ) {
        setLoading(false)
        keys=[];
        cartable=[];
        setResponse({ Data: [] })
        let sizePage = getPageSize("cartable" + type + `${selectedProcessFilterId}`)
        setPageSize(sizePage)
        try {
            if(type==typeOfDataGrid){
                const res = await getCartable(pagNum, sizePage, type, selectedProcessFilterId, layoutContext.currentUserPosition.PositionId, filterAndSort?.OrderFormat?.join(","), searchSTR, filterAndSort?.Filterlist);
                setUpdated(!Updated)
                layoutContext.setNotificount( res?.data?.Data?.NewRecords )
                setResponse(res?.data?.Data);
            }
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }

    useEffect(() => {
        setResponse({ Data: [] })
        if(reload ){
            getCartableAsync();
            setReload(false)
        }
        
    }, [reload]);


    useEffect(() => {
        setResponse({ Data: [] })
        setRowObject(null);
        referenceIdRef.current = null
        setReferenceId(null);
        if(filterAndSort != null){
            getCartableAsync();  

        }
        let requesterStorage =localStorage.getItem("ShowNewReferene")
        let NewReferene:any  = {}
        if(requesterStorage == null) {
            NewReferene ={};
        } else {
            NewReferene = JSON.parse(requesterStorage)
        }
        if(NewReferene?.referenceId?.length > 0){
            setRowObject(NewReferene)
            setReferenceId(NewReferene.referenceId);
            setMode("view"); 
            processViewCallback(NewReferene.ProcessId); 
        }
    }, [filterAndSort,  selectedProcessFilterId, layoutContext.currentUserPosition ]);

 
    async function handleFinishedReference(type ,text="",sendMsg=false ) {
        try {
            if(type==="Terminated"){
                 await FinishedReference(referenceId,sendMsg ,text);        
            }else if(type==="CancelTerminate"){ 
                 await CancelFinishedReference(referenceId);
            }else{
                 await RejectReference(referenceId ,text,sendMsg);
            }
            setShowTerminatedForm(false)
            setShowActionForm(false)
            referenceIdRef.current = null
            setReferenceId(null);
            getCartableAsync();             
        } catch (error) {
            
        }
        
    }

    let tabContent = [

        { title: "request" ,body: (<>
                {!showstep[1] &&
                    <DynamicComponentHOC JobNo={rowObject?.JobNo} RequesterPositionId={rowObject?.RequesterPositionId}  SourceData={rowObject?.SourceData} onHide={() => {localStorage.setItem("ShowNewReferene", "null"); setShowDynamicFormView(false); setReload(true);sessionStorage.setItem("changingforms", "false"); setCurentTab("request") ;setLoading(false)  }} componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                        reload={setReload} Name={dynamicForm?.Name} modeParams={type === CartableType.Finished ?"view" :  mode} componentParams={dynamicForm.Parameters} processId={selectedStartingProcessId}
                        referenceId={referenceId} jobId={rowObject?.JobId} handleCancelForm={() => {localStorage.setItem("ShowNewReferene", "null");showDynamicFormViewCallback(false);sessionStorage.setItem("changingforms", "false");}} handleShowReferenceStepper={showReferenceStepperCallback} />
                }
                     {(archivedList&&archivedList.length >0 )&& 
                                 <div className="card legendCard mt-2">
                                    <div className="legend">{trans("ArchivedIn")}</div>
                                    <div className="card-body ">
                                    <div style={{display:"flex" , flexDirection:"column"  }} className="">
                                     {archivedList.map(item=>(
                                       <div>
                                         {item}
                                       </div>
                                     ))}
                                    </div>
                                     </div >
                                 </div>
                                 
                                 }

        </>), },
       type !=CartableType.Draft&&  { title: "referenceandactionshistory" ,body: ( <ReferencesActions rowObject={rowObject} inTab={curentTab}  cartableType={type} referenceId={referenceId}/>   ), },
       type !=CartableType.Draft&&{ title: "referencestree" ,body: ( <ReferenceTree intab={curentTab=="referencestree"} referenceId={referenceId}/>   ), },

    ];

    if (response &&
        response.Data && response.Data[0]) {
        keys = Object.keys(response.Data[0]);
    
        cartable = response.Data;
        Total_records = response.TotalRecords;
    } else if (response && response?.Data?.length === 0) {
        keys = [];
        cartable = [];
    }
    pages = Total_records > pageSize ? Math.ceil(Total_records / pageSize) : 1;
    /* #endregion */

    // modal width from modal form
    const getModalWidth = (modalWidth) => {
        setCurrentModalWidth(modalWidth)
    }

   

    return (
        <>
            <Toolbar
                handleSearch={Searchhandleer}
                handleClickToolbar={clickToolbarCallback}
                handleSelectedStartingProcessToolbar={selectedStartingProcessCallback}
                handleSelectedProcessFilterToolbar={selectedProcessFilterCallback}
                create={type === CartableType.Current|| type === CartableType.Ongoing || type === CartableType.Draft }
                edit={type === CartableType.Draft}
                remove={type === CartableType.Draft || type === CartableType.Followup ||type === CartableType.Ongoing }
                id={referenceId}
                reference={type === CartableType.Current || type === CartableType.Ongoing || type === CartableType.Draft}
                action={type === CartableType.Current||type === CartableType.Ongoing  || type === CartableType.Finished}
                report={type === CartableType.Current||type === CartableType.Ongoing  || type === CartableType.Finished}
                reminder={true}
                archive={ type === CartableType.Finished}
                personalArchive={type === CartableType.Current||type === CartableType.Ongoing  || type === CartableType.Finished}
                reverse={type === CartableType.Current||type === CartableType.Ongoing }
                undo={type === CartableType.Referred}
                print={false}
                excel={true}
                Updated={Updated}
                view={type != CartableType.Current}
                RefreshWithNotif={true}
                refresh={true}
                attach={true}
                attachHover={"request"}
                help={true}
                finish={type === CartableType.Current||type === CartableType.Ongoing }
                search={true} />
            <div style={{overflowY: "auto",
             height: "74vh",
            scrollbarWidth: "thin",}} className="col-lg-12 mt-0">
                <div className="list-card card">
                    <div className="list-card__body">
                        {loading ?
                            (cartable.length > 0 && keys ?
                                <DataGrid
                                    handlefiltering={(str:any) => {  str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                    orderFormat={(order)=>{setFilterAndSort({...filterAndSort, OrderFormat:order})  }}
                                    orderFormatList={filterAndSort.OrderFormat}
                                    filterStr={filterAndSort.Filterlist}
                                    DoubleClick={(obj) => { referenceIdRef.current = obj.Id; setReferenceId(obj.Id); if (type === CartableType.Current || type === CartableType.Finished ){ clickToolbarCallback("action", obj.Id)}else{clickToolbarCallback("view", obj.Id)} }}
                                    View={true}
                                    startIndex={( typeOfDataGrid === CartableType.Current|| typeOfDataGrid === CartableType.Ongoing) ?12 :11}
                                    customFuction={customFuction}
                                    handleAttachment={handleAttachmentRow}  
                                    handleAttachmentText={trans("attachment")+" "+trans("reference")}  
                                    selectItem={(e) => {   }}
                                    parentName={"cartable" + type + `${selectedProcessFilterId}`}
                                    theadData={keys}
                                    tbodyData={cartable}
                                    minCellWidth={80}
                                    selectedRowObjectCallback={selectedRowCallback}
                                    rowSelected={rowObject}
                                    totalRecords={Total_records}
                                    pageNum={pageNum}
                                    ViewParametr={"EyeIcon"}
                                    pageSize={pageSize}
                                    handleDelete={type === CartableType.Draft ? handleDeleteReference : type === CartableType.Ongoing ? (id) => { ongoingReferenceAsync(id, false) } : null}
                                    HandleNextPage={HandleNextPage}
                                    HandlePrevPage={HandlePrevPage}
                                    handlechangePageSize={handlechangePageSize}
                                    first={() => { getCartableAsync(pages); setPageNum(pages) }}
                                    end={() => { getCartableAsync(1); setPageNum(1) }}
                                    handleGoPage={handleGoPage}
                                />
                                : <Message message={"NoDataForPreview"} type={messageType} />)
                            : <Loading />
                        }
                    </div>
                </div>  

                {ShowReferDescriptionForm &&
                    <ModalForm mode={mode} title={"reverse"} onHide={() => setShowReferDescriptionForm(false)} show={ShowReferDescriptionForm}>
                        <form onSubmit={ReferreverseAsync}>
                            <div className="row mb-1">
                                <div className="col-12">
                                    <Textbox
                                        minHeight={3}
                                        required
                                        textboxType="textarea"
                                        textboxName="ReverseDescription"
                                        labelText="reverseDescription"
                                        mode={"create"}
                                    />
                                </div>
                                <div className="col-4 my-1 my-lg-0 col-lg-4 pt-4">
                                <CheckableButton
                                       defaultValue={false}
                                       labelText={"sendMessageToPerformer"}
                                       inputName={"sendMessageToPerformer"}
                                       type="checkbox"
                                       mode={"edit"}
                                    />    
                            </div>
                            </div>
                            <div className="col-lg-12 mt-1 btns_form">
                                <Button value="Send" btnType="submit" />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => { Toast(trans("modalclosemessage"), "warning", "alert", () => setShowReferDescriptionForm(false)) }}
                                />
                            </div>
                        </form>
                    </ModalForm>
                }
                 {showActions &&
                    <ModalForm mode={"create"} title="reports" onHide={() => setShowActions(false)} show={showActions}>
                        <Actions
                            rowObject={rowObject} 
                            handleShowReferenceStepper={showReferenceStepperCallback}
                            showForm={true}
                            Reload={()=>setReload(true)}
                            onHide={() => setShowActions(false)}
                            mode={"create"}
                            referenceId={referenceId}
                        />
                    </ModalForm>
                }

                {showActionForm &&
                    <ModalForm modalHeight={70} mode={"create"} modalProfileKey={"Actions"}  title={ `${trans("Actions")} [ ${trans("Process")} : ${rowObject?.IconHover} ]`}  onHide={() => { setShowActionForm(false); setReload(true) }} show={showActionForm} getModalWidth={getModalWidth}>
                        <ActionList cartableType={type} haveReverse={rowObject?.Reversed}  handleFinishedReference={handleFinishedReference} rowObject={rowObject} onHide={() => { setShowActionForm(false); setReload(true) }} referenceId={referenceId} jobId={rowObject?.JobId} handleShowReferenceStepper={showReferenceStepperCallback} styleCondition={currentModalWidth} />
                    </ModalForm>
                }
                {showTerminatedForm &&
                    <ModalForm mode={"create"} title={("Terminate")} onHide={() => { setShowTerminatedForm(false) }} show={showTerminatedForm} getModalWidth={getModalWidth}>
                        <TerminatedForm rowObject={rowObject} submit={handleFinishedReference}   onHide={() => setShowTerminatedForm(false)} />
                    </ModalForm>
                }   

                {ShowReferenceAndActions &&
                    <ModalForm mode={"create"} title={"referenceandactionshistory"} onHide={() => { setShowReferenceAndActions(false) }} show={ShowReferenceAndActions}>
                        <ReferencesActions
                            cartableType={type}
                            rowObject={rowObject}
                            referenceId={referenceId}
                        />
                    </ModalForm>
                }
                {ShowReferenceTree &&
                    <ModalForm mode={"create"} title={"referencestree"} onHide={() => { setShowReferenceTree(false) }} show={ShowReferenceTree}>
                       <ReferenceTree intab={true} referenceId={referenceId}/>  
                    </ModalForm>
                }

                {showAttachment &&
                    <ModalForm modalHeight={22}  mode={"create"} title={("Attachment")} onHide={() => { setShowAttachment(false) }} show={showAttachment}>
                        <Attachment haveVersion={true} showInGrid={true} onHide={() => { setShowAttachment(false); setReload(true) }} mood={mode} endpoint={ENDPOINTS.cartable.reference.attachments} getListEndpoint={endpoint === ENDPOINTS.cartable.attachment ? endpoint : null}getListOwnerId={referenceId}  ownerId={ referenceId} />
                    </ModalForm>
                }
                {showReminder &&
                    <ModalForm mode={"create"} title={("Reminder")} onHide={() => setShowReminder(false)} show={showReminder}>
                        <GroupPerson onHide={() => setShowReminder(false)} label={trans("person")} hasBorder={false} TextBoxName={"CartableReminder"} value={""} SourceId={referenceId} />
                    </ModalForm>
                }
                {showReference && 
                    <ModalForm modalHeight={74} mode={"create"} title={"ReferenceStepper"} onHide={() => { setShowReference(false) }} show={showReference} getModalWidth={getModalWidth}>
                        <ReferenceStepper rowObject={rowObject} hideReferenceAndFinish={()=>{setShowReference(false) ;setShowTerminatedForm(true) }}  reload={setReload} onHide={() => { setShowReference(false); setReload(true) }} referenceId={referenceId} styleCondition={currentModalWidth} />
                    </ModalForm>
                }
                {showJobSendForm &&
                    <ModalForm mode={mode} title={"JobSendForm"} onHide={() => setShowJobSendForm(false)} show={showJobSendForm}>
                        <JobSendForm step={1}  mode={mode} onHide={() => setShowJobSendForm(false)} />
                    </ModalForm>
                }
                {showArchiveForm>0 &&
                    <ModalForm mode={mode} title={("ArchiveForm")} onHide={() => setShowArchiveForm(0)} show={showArchiveForm>0}>
                        <ArchiveForm jobId={rowObject?.JobId} Id={rowObject?.Id}  type={showArchiveForm} onHide={() => setShowArchiveForm(0)} />
                    </ModalForm>
                }

                {showDynamicForm &&
                    <ModalForm mode={mode} title={(dynamicForm.Name)} onHide={() => { setShowDynamicForm(false);setReload(true); }} show={showDynamicForm}>
                        <DynamicComponentHOC JobNo={rowObject?.JobNo} RequesterPositionId={rowObject?.RequesterPositionId} SourceData={rowObject?.SourceData} onHide={(usenavigate=false) => {sessionStorage.setItem("changingforms", "false")  ;setLoading(false);setShowDynamicForm(false); if(usenavigate){ navigate("/cartable/draft")}; setReload(true);  }} componentName={DYNAMICFORMS[dynamicForm.Name] ?null:dynamicForm.Name} component={DYNAMICFORMS[dynamicForm.Name] ?DYNAMICFORMS[dynamicForm.Name].component:null}
                            reload={setReload}  Name={dynamicForm.Name} TaskId={dynamicForm.TaskId	} componentParams={dynamicForm.Parameters} processId={selectedStartingProcessId}
                            referenceId={referenceId}  handleCancelForm={() =>{sessionStorage.setItem("changingforms", "false");setShowDynamicForm(false); }} handleShowReferenceStepper={showReferenceStepperCallback} />
                   
                    </ModalForm>
                }



                {showDynamicFormView &&
                    <ModalForm mode={mode} title={ShowDynamicFormViewLoading ?(dynamicForm.Name) :"cartableView"} onHide={() => { setShowDynamicFormView(false);setReload(true);setCurentTab("request") }} show={showDynamicFormView}>                       
                        {ShowDynamicFormViewLoading ? 
                            <>
                       {((rowObject?.AssignType == AssignTypes.SELF_SERVICE&&rowObject?.AcceptTime==null)&&(rowObject?.referenceId!=undefined ?rowObject?.referenceId==referenceId  :  rowObject?.Id==referenceId) && showAccept)&&
                        <AcceptSelfService  handleAcceptReferenceAsync={()=>{acceptReferenceAsync(rowObject?.Id)}} onHide={(e)=>{e.stopPropagation();  setShowAccept(false)}}/>
                            
                             }
                                <TabBar  tabChange={tabChangeHandle} content={tabContent} curentTab={curentTab} defaultTab={"request"} />
                             </>
                          : <Loading/>
                        }
                    </ModalForm>  
                }
            </div>
        </>
    )
}

