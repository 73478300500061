import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { getParameterEnumApi, getParameterEnumMethod } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";

interface ParameterEnumProp extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: any;
  enumType?: any;
  feedType?: any;
  mode?: string;
  required?:boolean 
  withoutLable?:boolean 
  onChange?: (e,string?) => void,
}
export const ParameterEnumDictionary = ({ enumType,feedType,  name, value ,mode,withoutLable , required ,onChange }: ParameterEnumProp) => {
  const [optionsParameterEnum, setOptionsParameterEnum] = useState<any>();
  const [defaultValue, setDefaultValue] = useState<any>(value);
  const [errcode, setErrcode] = useState<any>();
  const getParameterEnumAsync = async ()=>{
    try {
    if(!enumType.includes("Dictionaries")){

      if(feedType=="Api"){
        const res = await getParameterEnumApi(enumType)
        setOptionsParameterEnum(res?.Data)
        
      }else{
        const res = await getParameterEnumMethod(enumType)
        setOptionsParameterEnum(res?.Data)
    
        onChange && onChange(Object.keys(res?.Data)[0],Object.values(res?.Data)[0])
      }
    }
    } catch (error:any) {
      setErrcode(error.response?.status)

    }
  }
  useEffect(() => {
    getParameterEnumAsync()
    return () => {
     
      setErrcode("")
    };
   
  }, [enumType]);


 return (
    <>
        {optionsParameterEnum ?
            <div style={{display:"flex" , }} >
            <div style={{width:"100%"}}>
            <SelectBox
              onChange={(e)=>{ onChange&& onChange(e.target.value,optionsParameterEnum[e.target.value]);setDefaultValue(e.target.value) }}
              required={required}
              mode={mode}
              lable={!withoutLable ?"ParameterDefaultValue":undefined}
              selectType="select"
              value={defaultValue?defaultValue:Object.keys(optionsParameterEnum)[0]}
              val={Object.keys(optionsParameterEnum)}
              options={Object.values(optionsParameterEnum)}
              name={name}
            ></SelectBox>
            </div>
          </div>
        :<InputLoad   msg={errcode} required={required}  labelText={!withoutLable ?"ParameterDefaultValue":undefined}  onClick={getParameterEnumAsync}/> 

      }
        <div className="text-box__msg-wrapper">
        </div>
    </>
  );
};
