import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { ModalForm, UserContext } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { useContext, useEffect, useState } from "react";
import { ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { PriceListDictionary } from "../../PriceList/components/PriceListDictionary/PriceListDictionary";
import { GeographicalClassification } from "../GeographicalClassification/GeographicalClassification";
import { getGeographicalClassification, getNextCustomerCode } from "./api";
import { PersonDictionaryWithAdd } from "./PersonDictionaryWithAdd";
import { PersonFormInTab } from "./PersonFormInTab";



interface CustomerFormPropsType {
    onHide: () => void
    submit: (e) => void
    mode: any,
    formRef?: any,
    Customer?: any,
    selectedObj: any,
    reload?:()=>void,
}
export const CustomerForm = ({ 
    onHide,
    submit,
    
    mode,
    formRef,
    Customer,
    selectedObj,
    reload
}: CustomerFormPropsType) => {

     
    const [resetForm, setResetForm] = useState(false);
    const [loading , setLoading] = useState(false)
    const [showTree,setShowTree] = useState<any>()

    const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
    const [userChanges, setUserChanges] = useState({});
    const [nextCode, setNextCode] = useState<any>();
    const [showPersonForm, setShowPersonForm] = useState<any>(false);
    const [person, setPerson] = useState<any>({});
    const [newPerson, setNewPerson] = useState<any>(null);
    const [geographical,setGeographical] = useState<any>(null)
    const [objDictionary,setObjDictionary] = useState<any>({VisitorPosition:{},DistributorPosition:{}})








    const getNextCustomerCodeAsync = async ()=>{
      setLoading(false)
      try{
      const res = await getNextCustomerCode(); 
      setNextCode(res.Data)  
             
     }catch(err:any){

    }
      setLoading(true)
    }
    const getGeographicalClassificationAsync = async (id)=>{
      try{
      const res = await getGeographicalClassification(id); 
      setGeographical(res.Data)  
             
     }catch(err:any){

    }
      
    }

    const itemCodeHandeler = (obj) => {
      setLoading(false)
      setGeographical(obj)
      setShowTree(false)
      setLoading(true)

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let data:any = {}
        if (Customer == null) {


            data = {
              CustomerOrganizationId: event.target.CustomerOrganizationId.value,
              CustomerPersonId: event.target.CustomerPersonId.value,
              CustomerPersonDescription: person?.Name,
              CreditValue: +event.target.CreditValue.value,
              CreditLetterValue: +event.target.CreditLetterValue.value,
              CustomerCode: event.target.CustomerCode.value,
              Description: event.target.Description.value,
              DescriptionEN: event.target.DescriptionEN.value,
              CustomerReferrer: event.target.CustomerReferrer.value,
              CustomerClassificationId: event.target.CustomerClassification.value,
              CustomerServicingStatus: +event.target.CustomerServicingStatus.value,
              GeographicalClassificationId: geographical?.Id,
              PriceListId: event.target.PriceList.value ? event.target.PriceList.value : null,
              VisitorPositionId: event.target.VisitorPosition.value ?event.target.VisitorPosition.value :null,
              VisitorPositionDescription: objDictionary?.VisitorPosition?.Name ? objDictionary?.VisitorPosition?.Name:null,
              VisitorPositionDescriptionEN: event.target.VisitorPositionDescriptionEN.value,

              DistributorPositionId: objDictionary.DistributorPosition?.Id??null,
              DistributorPositionDescription: objDictionary?.DistributorPosition?.Name ?objDictionary?.DistributorPosition?.Name:null ,
              DistributorPositionDescriptionEN: event.target.DistributorPositionDescriptionEN.value,
              YearlySaleTarget:+event.target.YearlySaleTarget.value,
              PriceListDiscountPercent:+event.target.PriceListDiscountPercent.value

              /*"AccDetailsId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            */
            }
            submit(data)
          } else {
            
            data = {
              Id: Customer?.Id,
              CustomerOrganizationId: event.target.CustomerOrganizationId.value,
              ActivityStatus:+event.target.CustomerStatus.value,
              CustomerPersonId: event.target.CustomerPersonId.value,
              CreditValue: +event.target.CreditValue.value,
              CreditLetterValue: +event.target.CreditLetterValue.value,
              CustomerCode: event.target.CustomerCode.value,
              Description: event.target.Description.value,
              CustomerPositionId	:Customer.CustomerPositionId,
              DescriptionEN: event.target.DescriptionEN.value,
              CustomerReferrer: event.target.CustomerReferrer.value,
              CustomerClassificationId: event.target.CustomerClassification.value,
              CustomerPersonDescription:person.Name,
              CustomerServicingStatus: +event.target.CustomerServicingStatus.value,
              GeographicalClassificationId: geographical?.Id,

              PriceListId: event.target.PriceList.value ?event.target.PriceList.value : null,
              VisitorPositionId: event.target.VisitorPosition.value ?event.target.VisitorPosition.value :null,
              VisitorPositionDescription: objDictionary?.VisitorPosition?.Name ? objDictionary?.VisitorPosition?.Name:null,
              VisitorPositionDescriptionEN: event.target.VisitorPositionDescriptionEN.value,

              DistributorPositionId: objDictionary.DistributorPosition?.Id??null,
              DistributorPositionDescription: objDictionary?.DistributorPosition?.Name ?objDictionary?.DistributorPosition?.Name:null ,
              DistributorPositionDescriptionEN: event.target.DistributorPositionDescriptionEN.value,
              YearlySaleTarget:+event.target.YearlySaleTarget.value,
              PriceListDiscountPercent:+event.target.PriceListDiscountPercent.value

              /*"AccDetailsId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            */
            }
            submit(data)  
      }
    }



  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };


useEffect(() => {
    if(Object.values(userChanges).filter((item)=>item!=null).length>0){

        sessionStorage.setItem("changingforms" , "true")
          window.addEventListener('beforeunload', handleBeforeUnload);
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
    }else{
        sessionStorage.setItem("changingforms" , "false")
        window.removeEventListener('beforeunload', handleBeforeUnload);

    }
}, [userChanges]);
  
    
     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);
     useEffect(() => {
          if (mode!="create"&& selectedObj?.Id&& Customer) {
            setLoading(true)
            if(Customer?.GeographicalClassificationId)
              getGeographicalClassificationAsync(Customer?.GeographicalClassificationId)
            setObjDictionary({VisitorPosition:Customer.VisitorPositionDescription,DistributorPosition:Customer.DistributorPositionDescription})
        }else{
          getNextCustomerCodeAsync()
          setLoading(true)
        }
      }, [Customer]);


    return (
        <>
       {showPersonForm && 
        <ModalForm mode={mode} onHide={()=>{setShowPersonForm(false)}} show={showPersonForm}     title={"person" } >
        <>
        <PersonFormInTab  setNewPerson={setNewPerson} setShowPersonForm={setShowPersonForm}setResetForm={setResetForm}mode={"create"} />        
        </>
        </ModalForm>
        
        }
            <>
            
          {showTree&& 
                <ModalForm mode={mode} title="GeographicalClassification"  onHide={() => setShowTree(false)} show={showTree}>
                    <GeographicalClassification itemCodeHandeler={itemCodeHandeler} onHide={()=>{setShowTree(false)}}/>
                </ModalForm>
            }
            
            {loading? 
            <>
              <div className="">
                <form ref={formRef}name="Customer" className="TypeSettingForm row  " onSubmit={handleSubmit}>
                <div className="row my-1">
                <div  className="col-md-3 col-sm-12" style={{display:"flex", alignItems:'center' , flexDirection:"row"}}  >
                       <PersonDictionaryWithAdd
                           setShowPersonForm={setShowPersonForm}
                           name={"CustomerPersonId"}
                           Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                           setPerson={setPerson}
                           resetForm={resetForm}
                           required={true}
                           value={newPerson ? newPerson : Customer?.CustomerPersonId}
                           mode={mode}
                         />                 
                        {mode!="view"&&
                        <div  style={{marginTop:"4px", width:"10%"}}>
                            <Button Icon={true} value={<button onClick={(e)=>setShowPersonForm(true)} type="button" className="checkButton d-flex justify-content-center align-items-center"  > <FontAwesomeIcon icon={[ICON_TYPE, "plus"]}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>setShowPersonForm(true)}   />
                        </div>             
                        }
                    </div> 

                  <div className="col-12 col-sm-3">
                  <DictionarySelectBox
                      name='CustomerOrganizationId'
                      label='CustomerOrganization'
                      endPoint={ENDPOINTS.BaseInformation.organization.byType}
                      mode={mode}
                  />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.CreditValue+""}
                          textboxType="number"
                          textboxName="CreditValue"
                          labelText="CreditValue"
                          min={0}
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.CreditLetterValue+""}
                          textboxType="number"
                          textboxName="CreditLetterValue"
                          labelText="CreditLetterValue"
                          min={0}
                          mode={mode}
                          resetForm={true}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={nextCode ? nextCode: Customer?.CustomerCode}
                          textboxType="text"
                          textboxName="CustomerCode"
                          labelText="CustomerCode"
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.CustomerReferrer}
                          textboxType="text"
                          textboxName="CustomerReferrer"
                          labelText="CustomerReferrer"
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3">
                     <PriceListDictionary
                       name="PriceList"
                       label="PriceList"
                       resetForm={resetForm}
                       value={Customer?.PriceListId}
                       mode={mode}
                     />
                   </div>
                   <div className="col-12 col-sm-3">
                     <EnumerationTypeSelect
                       name="CustomerServicingStatus"
                       label="CustomerServicingStatus"
                       value={Customer?.PriceListId}
                       mode={mode}
                       enumTypeSelect="CustomerServicingStatus"
                     />
                   </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.Description}
                          textboxType="text"
                          textboxName="Description"
                          labelText="Description"
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.DescriptionEN}
                          textboxType="text"
                          textboxName="DescriptionEN"
                          labelText="DescriptionEN"
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.PriceListDiscountPercent}
                          textboxType="number"
                          min={0}
                          max={100}
                          textboxName="PriceListDiscountPercent"
                          labelText="PriceListDiscountPercent"
                          mode={mode}
                      />
                  </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.YearlySaleTarget}
                          textboxType="text"

                          textboxName="YearlySaleTarget"
                          labelText="YearlySaleTarget"
                          mode={mode}
                          useGroupingPrice={true}
                      />
                  </div>

                  <div  className="col-md-3 col-sm-12" style={{display:"flex", alignItems:'center' , flexDirection:"row"}}  >
                        <Textbox  Change={(e)=>{setUserChanges({...userChanges , ...e})}}   textboxName={"GeographicalClassificationId"} textboxType="text" resetForm={resetForm} labelText={"GeographicalClassification"} mode={mode} value={geographical?.Description} defaultValue={geographical?.Description} />
                       {mode!="view"&&
                        <div  style={{marginTop:"4px", width:"10%"}}>
                            <Button Icon={true} value={<button onClick={(e)=>setShowTree(true)} type="button" className="checkButton d-flex justify-content-center align-items-center" > <FontAwesomeIcon icon={[ICON_TYPE, "diagram-project"]}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>setShowTree(true)}   />
                        </div>             
                        }
                    </div>  

                  <div className="col-12 col-sm-3">
                      <DictionarySelectBox
                       Change={setUserMadeChanges}
                       label="CustomerClassification"
                       name="CustomerClassification"
                       baseUrl={INVENTORYANDSALES_URL}
                       endPoint={ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.CustomerClassification.dictionary}
                       value={Customer?.CustomerClassificationId}
                       resetForm={resetForm}
                       mode={mode}
                       required={true}
                     />
                   </div>
                   <div className="col-12 col-sm-3">
                     <Position
                       name="DistributorPosition"
                       label="DistributorPositionDescription"
                       resetForm={resetForm}
                       value={Customer?.DistributorPositionId}
                       onChange={(e)=>{
                        setObjDictionary({...objDictionary,DistributorPosition:e})}}
                       returnObj={true}
                       mode={mode}
                     />
                   </div>
                   <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.DistributorPositionDescriptionEN??""}
                          textboxType="text"
                          textboxName="DistributorPositionDescriptionEN"
                          labelText="DistributorPositionDescriptionEN"
                          mode={mode}
                      />
                  </div>
                   <div className="col-12 col-sm-3">
                     <Position
                       name="VisitorPosition"
                       label="VisitorPositionDescription"
                       resetForm={resetForm}
                       value={Customer?.VisitorPositionId}
                       mode={mode}
                       returnObj={true}

                       onChange={(e)=>setObjDictionary({...objDictionary,VisitorPosition:e})}
                     />
                   </div>
                  <div className="col-12 col-sm-3 ">
                      <Textbox
                          defaultValue={Customer?.VisitorPositionDescriptionEN??""}
                          textboxType="text"
                          textboxName="VisitorPositionDescriptionEN"
                          labelText="VisitorPositionDescriptionEN"
                          mode={mode}
                      />
                  </div>

                 {mode != "create" &&          
                    <div className="col-lg-4 mt-1">
                      <EnumerationTypeSelect
                      Change={(e)=>{setUserChanges({...userChanges , ...e})}} 
                      value={Customer?.ActivityStatus ? Customer?.ActivityStatus  : null}  
                      name="CustomerStatus"  
                      mode={mode} 
                      label="Status"
                      enumTypeSelect="ActivityStatus" 
                      />
                    </div>                            
                    }
                 </div>    
                 <button  type="submit"  className="d-none" id="hidebutton" name="hidebutton" >
                </button>                  
                </form>
              </div >
               </>
             :<Loading />
            }
            </>
          </>
    );
}