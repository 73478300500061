import React, { useEffect, useState } from "react";
import Select from "react-select";
import { trans } from "utils/helpers";


import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPE } from "utils/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// extends React.HTMLAttributes<HTMLSelectElement | HTMLInputElement> 
interface Props {
  selectType: "select" | "selectImg" | "selectThin" | "selectIcon" | 'multiSelect';
  options?: any[];
  value?: any;
  lable?: string,
  mode?: string,
  required?: boolean;
  showOption?: boolean;
  resetForm?: boolean;
  // children?:any,
  name: string,
  val: any[],
  useTrans?:boolean,
  onChange?: (e) => void,
  onClick?: (e) => void,
  icons?: any;
  recordedData?:any;
  isDisabled?:any

}

export const SelectBox: React.FC<Props> = React.forwardRef(({ recordedData,useTrans=true, selectType,onClick, onChange,showOption=true , options, val, value, lable, required, name, mode ,resetForm, icons,isDisabled}, ref: any) => {
  const disabled = (mode == "view" || isDisabled ? true : false);
  const defaultvalue = value ? value : (val ? val[0] : undefined)
  const [selectedValue, setSelectedValue] = useState(defaultvalue);

  const currentValueIndex = val && val.findIndex(item => item === defaultvalue)
  const colourStyles = {
    menuPortal: base => ({ ...base, zIndex: 99999999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: (isSelected ||isFocused) ? 'blue' : 'white',
        // color: (isSelected ||isFocused) ? 'white' : 'black',
        cursor: 'pointer',

      }
    },
   
    control: (provided, state) => ({
      ...provided,
      minHeight: '30px',
      height: '30px',

    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
      cursor:'pointer'

     
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      
    }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '400px', // ارتفاع لیست کشویی داخلی
    overFlow:'auto'
  }),
  };


  useEffect(() => {
      setSelectedValue(value)
  }, [value]);

  return selectType == "selectImg" ? (
    <Select
      value={value ? value : options && options[0] }
      options={options}
      name={name}
      ref={ref}
      onChange={onChange}
      menuPortalTarget={document.body} 
      styles={colourStyles}
      isSearchable={false}
    />)
     : selectType == "selectIcon" ? (
        <>
          {lable ?
            <div className="d-flex align-items-start" style={{height:'22px'}}>
              <label className="select-box__label showDots h-100"  style={{maxWidth:'95%'}}>{trans(`${lable}`)}</label>
              {required ? <span className='text-danger me-1'>*</span> : ''}
            </div>
            :
             null}
          <FontAwesomeIcon
                icon={icons[currentValueIndex]}
                color={"#ced4da"}
                className={'select-box__icon'}
           />
          <select onClick={onClick} defaultValue={defaultvalue}  required={required} ref={ref} disabled={disabled} name={name}  className={`form-control-custom ${disabled ? " disabled" : ""} select-box__selectIcon-input`} onChange={onChange}   >
              {(showOption&&defaultvalue && options) && options.map((item, index) => {
                  if (val[index] == defaultvalue) {
                    return (<option key={index} value={val[index]} selected >{useTrans ? trans(`${item}`) : item}</option>)
                  } else{
                    return (<option key={index}  value={val[index]}>{useTrans ? trans(`${item}`) : item}</option>)
                  }
              })}

          </select>
        </>
       )
     : (
        <>
        <div className="d-flex justify-content-between">
          {lable ?
             <OverlayTrigger
             key={lable}
             placement="top"
             delay={{"show" : 600 , "hide":0}}
             overlay={
              // reserve or key ??
                <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                {trans(lable)}
                </Tooltip>
                }>

                 <div className="d-flex align-items-start" style={{height:'22px',width:'80%'}}>
                   <label className="select-box__label showDots"  style={{maxWidth:'95%'}}>{trans(`${lable}`)}</label>
                   {required ? <span className='text-danger me-1'>*</span> : ''}
                 </div>
          </OverlayTrigger> 
          :
           null}
          {/* Create Mode in SelectBox ?? */}
          {((mode=="edit" && recordedData!=undefined&& recordedData != selectedValue)||(mode=="create"&&recordedData != selectedValue&&selectedValue!=undefined&&selectedValue.length>0&& recordedData!=undefined )) &&
            <>
              <OverlayTrigger
                 key={"reserve"}
                 placement="top"
                 delay={{"show" : 600 , "hide":0}}
                 overlay={
                  // reserve or key ??
                    <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                    {trans("PreviousValue")+  " : " + useTrans ? trans(options?.[(val.indexOf(recordedData+""))]) : (options?.[(val.indexOf(recordedData+""))])}
                    </Tooltip>
                    }>
                  <span className="d-flex " style={{maxWidth:'18%'}}>
                    <FontAwesomeIcon style={{marginRight:"5px"}} icon={["fas", "circle-info"]} className="text-primary pr-2"/>
                    <FontAwesomeIcon onClick={()=>{setSelectedValue(recordedData); onChange && onChange({target:{value: recordedData}})}} style={{marginRight:"5px"}} icon={["fas", "undo"]} className="text-primary pr-2"/>
                  </span>
              </OverlayTrigger> 
            </> 
          }
          </div>
          <select onClick={onClick} value={selectedValue} defaultValue={(options?.length==1 && val[0] == "AddNewOption"  )? "null" :  defaultvalue} color="white" required={required} disabled={disabled} name={name} ref={ref} className={`${selectType == "selectThin" ? " select-box-thin " : ""}  form-control-custom ${disabled ? " disabled" : ""} `} onChange={(e) => {setSelectedValue(e.target.value); onChange&&onChange(e)}}>
              {(showOption&&defaultvalue && options) && options.map((item, index) => {
                  if (val[index] == defaultvalue) {
                    if(val[index] == "AddNewOption"){
                      return (<>
                        <option key={index} className="select-box__option" selected value={"null"}  >{}</option>
                        <option key={index} className="select-box__option" style={{fontSize:"13px" , color:"#0d6efd" }} value={val[index]}  >{useTrans ? trans(`${item}`) : item}</option>
                        </>) }else{
                          return (<option key={index} className="select-box__option" selected value={val[index]}  >{useTrans ? trans(`${item}`) : item}</option>)
                    }
                  }else{
                    if(val[index] == "AddNewOption"){
                      return (<option key={index} style={{fontSize:"13px" , color:"#0d6efd" }} className="select-box__option" value={val[index]} >{useTrans ? trans(`${item}`) : item}</option>)               
                    }else{
                      return (<option key={index} className="select-box__option" value={val[index]} >{useTrans ? trans(`${item}`) : item}</option>)               

                    }
                  
                    }
              })}
    
          </select>
        </>
    );
   
});

