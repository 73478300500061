

import { DataGrid } from "components/DataGrid/DataGrid";
import { useEffect, useState } from "react";

import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { FeedParametersForm } from "./FeedParametersForm";


interface FeedParametersProps {
    FeedParametersListCallback: (e) => void,
    feeds: any,
    feedType: any,
    parentMode?:any
    handleUserChange?:any
}
export const FeedParameters = ({feeds,feedType ,  handleUserChange,parentMode,FeedParametersListCallback}: FeedParametersProps) => {

  
    const [feedsList, setFeedsList] = useState<Response | any>(feeds);
    const [selectedObj, setSelectedObj] = useState< any>(null);
    const [mode, setMode] = useState("create");
    const [resetForm, setResetForm] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});
    const [showForm, setShowForm] = useState(false);


    const FeedsListHandlr = (list) => {
        let newlist =feedsList
        newlist.map(item=>{
            if((item.ReportFeedMethodId == selectedObj.ReportFeedMethodId&&selectedObj.ReportFeedMethodId!=null )|| (item.ReportFeedApiId ==selectedObj.ReportFeedApiId &&selectedObj.ReportFeedApiId!=null )){
                item.ParametersObj = list
                item.Parameters = JSON.stringify(list) 
            }
            return item
        })
        FeedParametersListCallback(newlist)
       // setFeedsList(newlist)
    }
    const selectedIdCallback = (obj, index) => {
        setSelectedObj(obj) 
    }

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    
    useEffect(() => {
        setFeedsList(feeds);
        
    }, [feeds]);

    return (
        <>
            <div className="cartable-FeedParameter">

            <div className="card">
                    <div className="">
                    <Message message={"Help_DoubleClickItemToEdit"} type={"instruction"} />
                        <>
                            {showForm&& 
                            <>
                                <ModalForm mode={mode} title="Parameters" onHide={() => setShowForm(false)} show={showForm} modalHeight={60}>
                                    <FeedParametersForm handleUserChange={handleUserChange} feedId={selectedObj.FeedId} parentMode={parentMode} feedType={feedType} obj={selectedObj?.ParametersObj} onHide={()=>{setShowForm(false)}} FeedParametersListCallback={FeedsListHandlr} />
                                </ModalForm>
                           </>
                            }
                            {
                                feedsList?.length > 0 ?
                                    <>
                                        <DataGrid
                                            DoubleClick={(e)=>{setSelectedObj(e)  ;setShowForm(true)}}
                                            parentName={"FeedParameters"}
                                            startIndex={4}
                                            theadData={Object.keys(feedsList[0])}
                                            tbodyData={feedsList}
                                            scrollHeight={showForm? "25vh": "67vh"}
                                            minCellWidth={80}
                                            selectedRowObjectCallback={selectedIdCallback}
                                            handleEdit={(e)=>{setSelectedObj(e)  ;setShowForm(true)}}
                                        />
                                    </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />
                            }
                            </>
                    </div>
                </div>
            </div>
        </>

    );
}
