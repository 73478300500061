import { trans } from "utils/helpers";
import "./style.scss";

function Toast(
  massage: any = "",
  type: "info" | "success" | "error" | "warning" | any,
  mode = "toast",
  func?: any,
  titl?,
  data?,
  nofunc?
) {
  const timeID = new Date().getTime() + "toast";
  let node;
  let showDataFlag = false;

  const handleClick = (event) => {
    if (
      type === "success" &&
      mode === "toast" &&
      event.target !== document.getElementById(timeID)
    ) {
      document.getElementById("backScreen")?.remove();
      document.getElementById(timeID)?.remove();
    }
    document.removeEventListener("click", handleClick);
  };
  document.addEventListener("click", handleClick);

  const yes = () => {
    if (mode === "massage") {
      const dom: any = document?.getElementById(timeID + "replyInput");
      document.getElementById(timeID)?.remove();
      func(dom.value);
    } else {
      document.getElementById("backScreen")?.remove();
      document.getElementById(timeID)?.remove();
      func();
    }
  };

  const no = () => {
    document.getElementById("backScreen")?.remove();
    document.getElementById(timeID)?.remove();
    if (nofunc) {
      nofunc();
    }
  };
  const showData = (e) => {
    e.stopPropagation();
    if (showDataFlag) {
      let e = document.getElementById(timeID + "UL");
      var child = e?.lastElementChild;
      while (child) {
        e?.removeChild(child);
        child = e?.lastElementChild;
      }
      showDataFlag = false;
    } else {
      let dataList2 = data.map((item) => {
        return `<div class='${item.MessageType}toast'>
                    ${trans(
                      item.Message.replaceAll("\\n", "<br/>").replaceAll(
                        "n\\",
                        "<br/>"
                      )
                    )}
                </div>`;
      });
      document
        .getElementById(timeID + "UL")
        ?.insertAdjacentHTML("beforeend", `${dataList2}`);
      showDataFlag = true;
    }
  };
  const tanslatedText = trans(
    massage === undefined ? "msg_serverexceptionerror" : massage
  );

  if (mode === "toast") {
    if (data) {
      node = `
        <div id=${timeID} class='${type}toast toastData'>
          <div class='headerToast'>
             <span>
                ${tanslatedText}
              </span>
              <span class='hidetoast'  id=${timeID + "close"}>&#10006;</span>
          </div>
          <div id=${timeID + "UL"} ></div>
          <div class='showtoast' id=${
            timeID + "show"
          }><div>جزئیات</div><div class="detailArrow">&#8250;</div>
          </div>
        </div>`;
    } else {
      node = `
      <div id=${timeID} class='${type}toast new defultPadding'>
      <div class='headerToast newHead'>
            <span class='hidetoast '   id=${timeID + "no"}>&#10006;</span>
      </div>
      <div class='toast-container newcontain'>
        <div class='toast-container__content'>
          <p style="word-wrap: break-word;">
          ${tanslatedText.replaceAll("\\n", "<br/>").replaceAll("\n", "<br/>")}
         
          </p>
        </div>
      </div> 
    </div> `;
    }
  } else if (mode === "massage") {
    node = `
  
  <div id=${timeID} class='${type}toast'>
    <div class='toastContainer'>
    <span>
    ${titl}
    </span>
      <div class='toastContainer__content'>
        <span>
        ${tanslatedText}
        </span>
      </div>
      <div class='toastContainer__footer'>
        <input value=${data ? data : ""} class='replyInput' id=${
      timeID + "replyInput"
    } />
        <button class='toastContainer__footer-btn-yes' id=${
          timeID + "yes"
        }>${trans("Send")}</button>
        <button class='toastContainer__footer-btn-no' id=${
          timeID + "no"
        }>${trans("Hide")}</button>
      </div>
    </div>   
  </div>`;
  } else {
    if (data) {
      node = `
  <div id=${timeID} class='${type}toast alerttoast new'>
    <div class='headerToast newHead'>
      ${
        mode != "alertWithoutClose"
          ? `<span class="hidetoast "   id=${timeID + "close"}>&#10006;</span>`
          : ""
      }
    </div>
    <div class='toastContainer'>
      <div class='toastContainer__content'>
        <div>
        ${tanslatedText}
        </div>
      </div>
                <div id=${timeID + "UL"} ></div>
          <div class='showtoast' id=${
            timeID + "show"
          }><div>جزئیات</div><div class="detailArrow">&#8250;</div>
      <div class='toastContainer__footer'>
        <button autofocus='true' class='toastContainer__footer-btn-yes' id=${
          timeID + "yes"
        }>${trans("yes")}</button>
        <button class='toastContainer__footer-btn-no' id=${
          timeID + "no"
        }>${trans("no")}</button>
      </div>
    </div>   
  </div>`;
    } else {
      node = `
    <div id=${timeID} class='${type}toast alerttoast new'>
      <div class='headerToast newHead'>
        ${
          mode != "alertWithoutClose"
            ? `<span class="hidetoast "   id=${
                timeID + "close"
              }>&#10006;</span>`
            : ""
        }
      </div>
      <div class='toastContainer'>
        <div class='toastContainer__content'>
          <div>
          ${tanslatedText}
          </div>
        </div>
        <div class='toastContainer__footer'>
          <button autofocus='true' class='toastContainer__footer-btn-yes' id=${
            timeID + "yes"
          }>${trans("yes")}</button>
          <button class='toastContainer__footer-btn-no' id=${
            timeID + "no"
          }>${trans("no")}</button>
        </div>
      </div>   
    </div>`;
    }
  }

  let toastEl = document.getElementById("tostErroreBox");
  let add = false;

  if (mode == "alert" || mode === "alertWithoutClose") {
    if (!document.getElementsByClassName("alerttoast").length) {
      toastEl?.insertAdjacentHTML("afterbegin", node);
      add = true;
    }
  } else {
    toastEl?.insertAdjacentHTML("afterbegin", node);
    add = true;
  }

  if (mode !== "toast") {
    if (mode === "massage") {
      document.getElementById(timeID + "yes")?.addEventListener("click", yes);
      document.getElementById(timeID + "no")?.addEventListener("click", no);
      document.getElementById(timeID + "close")?.addEventListener("click", no);
    } else {
      if (add) {
        document
          .getElementById("DashboardLayout")
          ?.insertAdjacentHTML(
            "afterbegin",
            '<div id="backScreen" class="fullScreen" ></div>'
          );
        document.getElementById(timeID + "yes")?.focus();
        document.getElementById(timeID + "yes")?.addEventListener("click", yes);
        document.getElementById(timeID + "no")?.addEventListener("click", no);
        document
          .getElementById(timeID + "close")
          ?.addEventListener("click", no);
        document
          .getElementById(timeID + "show")
          ?.addEventListener("click", showData);
      }
    }
  } else {
    if (data) {
      document
        .getElementById(timeID + "show")
        ?.addEventListener("click", showData);
      document.getElementById(timeID + "close")?.addEventListener("click", no);
    } else {
      document.getElementById(timeID + "no")?.addEventListener("click", no);
    }
  }
  if (mode === "toast") {
    if (data) {
      //  setTimeout(()=>{
      //  document.getElementById(timeID)?.remove()
      //
      //  }, 5000)
      //
    } else {
      setTimeout(() => {
       document.getElementById(timeID)?.remove();
      }, 6000);
    }
  }
  if (mode === "massage") {
  }

  /*   let list:any = document.getElementById("tostErroreBox")
  
    while (list && list.hasChildNodes()) {
      setInterval( list.removeChild(list.firstChild), 5000);
    }
    */
}

function ToastShow() {
  return <div id={"tostErroreBox"} className={"errorBox"}></div>;
}

export { ToastShow, Toast };
