import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getWarehouseDictionary } from "./api";
import './style.scss'
import { InputLoad } from "components/InputLoading/inputLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { ICON_TYPE } from "utils/constants";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
interface WarehouseDictionarySelectProp extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  value?: any;
  mode?: any;
  required?: boolean;
  Change?: (e) => void,
  setSelectedList?: (e) => void,
  selectedItemCallBack?: (e) => void,
}
export const WarehouseDictionarySelect = ({ setSelectedList,selectedItemCallBack, label, name, value, mode ,required,Change }: WarehouseDictionarySelectProp) => {
  const [optionsWarehouseDictionarySelect, setOptionsWarehouseDictionarySelect] = useState<any>({});
  const [errcode, setErrcode] = useState<any>();
  const [Warehouse, setWarehouse] = useState<any>();
  const [selectedItemList, setSelectedItemList] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>();


  const WarehouseDictionarySelectApiAsync = async ()=>{
    try {
      const res = await getWarehouseDictionary()
      setOptionsWarehouseDictionarySelect({'allWarehouses':{Description:'allWarehouses'},...res.Data})

    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }

  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Id = Warehouse?.target.value? Warehouse?.target?.value : Object.keys(optionsWarehouseDictionarySelect)[0]
    if(selectedItemList.includes('allWarehouses')  || Id == 'allWarehouses'){
      setSelectedItemList(['allWarehouses'])
      setSelectedItem({Id:'allWarehouses' , Name:'allWarehouses'})
    }else{
      if(!selectedItemList.includes(Id)){
          setSelectedItemList([...selectedItemList , Id])
          setSelectedItem({Id:Id , Name:optionsWarehouseDictionarySelect[Id].Description})
      }

    }
    }

  



  async function deleteaOption(Id) {
    let list:any = []
    selectedItemList.map(item=>{
        if(item!=Id){
            list.push(item)
        }
    })
    setSelectedItemList(list)
    if(Id== selectedItem.Id){
      setSelectedItem({Id:list[0]  , Name:  optionsWarehouseDictionarySelect[list[0]]?.Description} )
    }
}

useEffect(() => {
    WarehouseDictionarySelectApiAsync()
  }, []);

useEffect(() => {
    setSelectedList&& setSelectedList(selectedItemList)
  }, [selectedItemList]);

useEffect(() => {
  selectedItemCallBack&& selectedItemCallBack(selectedItem)
  }, [selectedItem]);


  
  

  return (
    <>
     {label ? <label className="WarehouseDictionarySelect__label">{trans(label)}</label> : null}
      {true ?
            <>
            <div style={{display:"flex" , alignItems:'center' ,flexDirection:"row",width:"100%"}}  >
              <div style={{width:"100%"}} >
              <SelectBox  
                onChange={(e) => { if(selectedItemList.length==0){setSelectedItem({Id:e.target.value , Name:optionsWarehouseDictionarySelect[e.target.value].Description})}      ;setWarehouse(e);e.target.value !=(value?value:Object.keys(optionsWarehouseDictionarySelect)[0]) ?Change&&Change({[name] :e.target.value}):Change&&Change({[name] :null}) }} 
                required={required}
                mode={selectedItemList.includes('allWarehouses') ? 'view':mode}
                lable="Warehouses"
                selectType="select"
                value={value}
                options={Object.values(optionsWarehouseDictionarySelect).map((item:any)=>{return item.Description})}
                val={Object.keys(optionsWarehouseDictionarySelect)}
                name={name}
                
              ></SelectBox>                  
              </div>
              <div  style={{marginTop:"20px" , width:"10%"}}>
             <Button Icon={true} disabled={selectedItemList.includes('allWarehouses')}  value={<button disabled={selectedItemList.includes('allWarehouses')} onClick={addOption} className={`checkButton ${selectedItemList.includes('allWarehouses') && 'btn btn-primary disabled'}`} > <FontAwesomeIcon icon={[ICON_TYPE, "check"]}    size="2x"  /></button>} btnType="submit"  />
              </div>
             </div>
              <div className="group-person__selected-options-wrWarehouseer pe-4 mt-2 row">
              { selectedItemList.map((item, index) => (
            <div style={{width:"35%"}} className={`group-person__selected-option   p-0 ms-1 form-control-custom ${item==selectedItem.Id && ('group-person__selected-options-wrWarehouseer-active')}  col-3 `}  key={item + index}>
                <div style={{marginRight:"13px",cursor:'pointer'}} className="d-flex align-items-center h-100" onClick={(e)=>{ setSelectedItem({Id:item , Name:optionsWarehouseDictionarySelect[item].Description})}}>
                 {trans(optionsWarehouseDictionarySelect[item].Description)  } 
                </div>
                <div className="group-person__delete-selected-option" data-key={item} onClick={()=>{deleteaOption(item)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
            </div>
             ))}
           </div>

            </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={WarehouseDictionarySelectApiAsync}/> 
      }
      <div className="text-box__msg-wrWarehouseer">

        
      </div>
    </>
  );
};
