import { useState } from "react";
import "./systemSettingMenu.scss";

import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { Price } from "components/Price/Price";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { SelectBoxHandle } from "./SelectBoxHandle";

import DatePicker from "components/DatePicker/DatePicker";
import { PriceListDictionary } from "pages/InventoryAndSales/Sales/PriceList/components/PriceListDictionary/PriceListDictionary";
import { ProcessDictionary } from "pages/OfficeAutomation/Settings/ProcessSetting/components/ProcessDictionary/ProcessDictinary";
import { PropertyType } from "types/enum";
import { WarehouseDictionary } from "../../../Sales/SalesInvoice/components/WarehouseDictionary/WarehouseDictionary";
import { CheckBoxList } from "components/CheckboxList/CheckBoxList";

export function SystemSettingForm({
  cards,
  setFromDate,
  userChanges,
  setUserChanges,
}) {
  const [selectedSelectBox, setSelectedSelectBox] = useState<any>({});

  return (
    <div>
      <div className="row align-items-center ">
        {/* specify number of card */}
        {cards.map((item, index) => {
          return (
            <>
              {item.PropertyType == PropertyType.Text && (
                <div className="col-sm-6 col-md-4">
                  <Textbox
                    required
                    textboxName={item.SystemKey}
                    textboxType={"text"}
                    labelText={item.Title}
                    Change={(e) => setUserChanges({ ...userChanges, ...e })}
                    defaultValue={
                      item.SelectedValue
                        ? item.SelectedValue
                        : userChanges[item.SystemKey]
                    }
                  />
                </div>
              )}
              {item.PropertyType == PropertyType.Number && (
                <div className="col-sm-6 col-md-4">
                  <Textbox
                    required
                    textboxName={item.SystemKey}
                    textboxType={"number"}
                    labelText={item.Title}
                    Change={(e) => setUserChanges({ ...userChanges, ...e })}
                    defaultValue={
                      item.SelectedValue
                        ? item.SelectedValue
                        : userChanges[item.SystemKey]
                    }
                  />
                </div>
              )}
              {item.PropertyType == PropertyType.List && (
                <div className="col-sm-6 col-md-4 ">
                  <SelectBoxHandle
                    selectedSelectBox={selectedSelectBox}
                    setSelectedSelectBox={setSelectedSelectBox}
                    options={item?.Values?.split(",")}
                    userChanges={userChanges}
                    setUserChanges={setUserChanges}
                    item={item}
                  >
                    <SelectBox
                      onChange={(e) => {
                        setSelectedSelectBox({
                          ...selectedSelectBox,
                          [item.SystemKey]: e.target.value,
                        });
                      }}
                      lable={item.Title}
                      selectType="select"
                      value={
                        item.SelectedValue
                          ? item?.Values.split(",").find(
                              (listItem, index) => index == item.SelectedValue
                            )
                          : "0"
                      }
                      options={item?.Values?.split(",")}
                      val={item?.Values?.split(",")}
                      name={item.SystemKey}
                    ></SelectBox>
                  </SelectBoxHandle>
                </div>
              )}
              {item.PropertyType == PropertyType.CheckBox && (
                <div className="my-2">
                  <CheckableButton
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    defaultChecked={
                      item.SelectedValue === "true" ? true : false
                    }
                    defaultValue={item.SelectedValue === "true" ? true : false}
                    labelText={item.Title}
                    inputName={item.SystemKey}
                    type="checkbox"
                  />
                </div>
              )}
              {item.PropertyType == PropertyType.Date && (
                <div className="col-sm-6 col-md-4 ">
                  <DatePicker
                    required
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    lable={item.Title}
                    setIsoTime={(isoTime: string) => {
                      setFromDate(isoTime);
                    }}
                    val={null}
                    name={item.SystemKey}
                    onOpenPickNewDate={true}
                  />
                </div>
              )}
              {item.PropertyType == PropertyType.Time && (
                <div className="col-sm-6 col-md-4 ">
                  <Time
                    required
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    name={item.SystemKey}
                    IdString={item.SystemKey}
                    defaultValue={item?.SelectedValue}
                    label={item.Title}
                  />
                </div>
              )}
              {item.PropertyType == PropertyType.Price && (
                <div className="col-sm-6 col-md-4 ">
                  <Price
                    name={item.SystemKey}
                    defaultValue={item.SelectedValue ? item.SelectedValue : 0}
                    label={item.Title}
                    onChange={(e) =>
                      setUserChanges({
                        ...userChanges,
                        [item.SystemKey]: e.target.value,
                      })
                    }
                  />
                </div>
              )}
      {item.PropertyType == PropertyType.CheckBoxList && (
                <div className="">
                  <CheckBoxList textboxName={item.SystemKey} title={item.Title} items={JSON.parse(item.SelectedValue)} onChange={(e)=>{
                    console.log({e});
                    
                  }} mode={""} />
                </div>
              )}

           {item.PropertyType == PropertyType.Dictanory &&
                (item.Values === "WarehouseComponent" ? (
                  <div className="col-sm-6 col-md-4">
                    <WarehouseDictionary
                      name={item.SystemKey}
                      label={item.Title}
                      value={
                        item?.SelectedValue ? item?.SelectedValue : undefined
                      }
                    />
                  </div>
                ) : item.Values === "PriceListComponent" ? (
                  <div className="col-sm-6 col-md-4">
                    <PriceListDictionary
                      name={item.SystemKey}
                      label={item.Title}
                      required={true}
                      value={
                        item?.SelectedValue ? item?.SelectedValue : undefined
                      }
                      resetForm={false}
                    />
                  </div>
                ) : (
                  <div className="col-sm-6 col-md-4">
                  <ProcessDictionary
                  name={item.SystemKey}
                  label={item.Title}
                  required={true}
                  value={
                    item?.SelectedValue ? item?.SelectedValue : undefined
                  }
                  /></div>
                ))}
            </>
          );
        })}
      </div>
    </div>
  );
}
