import { Button } from "components/Button/Button";
import { TabBar } from "components/Tabbar/Tabbar";
import { useEffect, useRef, useState } from "react";

//import { getCustomerForm, sendCustomer } from "./api";
import { Loading } from "components/Loading/loading";
import { CustomerBranchesForm } from "./CustomerBranchesForm";
import { CustomerForm } from "./CustomerForm";
import { CustomerTaxForm } from "./CustomerTaxForm";
import { RelatedCustomerForm } from "./RelatedCustomer";
import { getCustomer, postCustomer, putCustomer } from "./api";

interface Form {
  onHide: (e) => void;
  reload?: () => void;
  setCustomerCallBack?: (e) => void
  mode: any;
  selectedObj: any;
}
export const CustomerTabform = ({ onHide,selectedObj, mode, reload ,setCustomerCallBack  }: Form) => {
  const lang = localStorage.getItem("lang");

  const [curentTab, setCurentTab] = useState<any>("Customer")
  const taxData = useRef<any>();
  const CustomerData = useRef<any>();
  const BranchesData = useRef<any>();
  const CustomerRelations = useRef<any>();

  const formRefs = [useRef(), useRef()];
  const [Customer , setCustomer ] = useState< any>();
  const [loading , setLoading] = useState(false)
  let content = [
    { title: "Customer", body: (<CustomerForm Customer={Customer?.Customer} formRef={formRefs[0]} submit={(e)=>{CustomerData.current=e}} selectedObj={selectedObj} onHide={ ()=>{onHide(false)}} mode={mode} />), },
    { title: "Tax", body: (<CustomerTaxForm CustomerId={Customer?.Customer?.Id	} CustomerTax={Customer?.CustomerTax} formRef={formRefs[1]} submit={(e)=>{taxData.current=e}} onHide={ ()=>{onHide(false)}} mode={mode}  />)},
    { title: "Branches", body: (<CustomerBranchesForm CustomerId={Customer?.Customer?.Id	}  CustomerBranches={Customer?.CustomerBranches}  CustomerBranchesCallBack={(e)=>{BranchesData.current=e}} onHide={ ()=>{onHide(false)}} mode={mode}  />)},
    { title: "Relatedcustomers", body: (<RelatedCustomerForm CustomerId={Customer?.Customer?.Id	}  CustomerRelations={Customer?.CustomerRelations	}  CustomerRelationsCallBack={(e)=>{CustomerRelations.current=e}} onHide={ ()=>{onHide(false)}} mode={mode}  />)}

  ];



  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 
  async function formSubmit() { 
    document.body.classList.add("loading-indicator-"+lang);
    let list
    if(BranchesData.current){
      list = JSON.parse(JSON.stringify(BranchesData.current))
    }
    if(list){
      Object.values(list).map((e:any)=>{
        delete e.Id ;
      })
    }

    await delay(50)
    let formdata: any = {}
    let customerData = { ...CustomerData.current }
    formdata.CustomerOrganizationId = customerData['CustomerOrganizationId']
    delete customerData['CustomerOrganizationId']
    formdata.Customer = { ...customerData }
    formdata.CustomerTax = { ...taxData.current }
    formdata.CustomerRelations=CustomerRelations.current ? [...CustomerRelations.current] : []
    if(list){
      formdata.CustomerBranches =[...list]
    }else{
      formdata.CustomerBranches = []
    }
      try {
        if(mode=="create"){
         const res =  await postCustomer(formdata);
         setCustomerCallBack&& setCustomerCallBack(res.Data)
          reload && reload()
        }else  await putCustomer(formdata);
        onHide(false)
        reload && reload()
        sessionStorage.setItem("changingforms" , "false");
        } catch (error) {

        }
        document.body.classList.remove("loading-indicator-"+lang);
}


const delay = (duration) =>
new Promise(resolve => setTimeout(resolve, duration));
const handleSubmitAllForms = async(e) => {
   
  let flag=true ; 
  await Promise.all(
    formRefs.map(async (formRef:any) => {
      if(formRef.current.checkValidity()&&flag){
        formRef.current.hidebutton?.click();
      }else if(flag){
        flag = false
        setCurentTab(formRef.current.name)
        await delay(50)
        formRef.current.reportValidity()
      }
    })
  );
  await delay(50)
  if(CustomerData.current!=undefined && taxData.current!=undefined){
      formSubmit()
  }else  document.body.classList.remove("loading-indicator-"+lang);

};

const getNewCustomer = async ()=>{
  setLoading(false)
  try{
  const res = await getCustomer(selectedObj.Id); 
  setCustomer(res.Data)  
 }catch(err:any){

}
  setLoading(true)
}

useEffect(() => {
    if (mode!="create"&& selectedObj?.Id) {
        getNewCustomer();
    }else{
      setLoading(true)
    }
}, []);

  /* pagination area*/
  return (
    <>
    {loading ?
    <>
      <TabBar  tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"main"} />
      <div className="d-flex justify-content-end mt-2">
        { mode != "view"?
          
          <Button onClick={handleSubmitAllForms} btnType="submit"  value="save"  />
          :
          <Button onClick={()=>onHide(false)} btnType="primary" value="Close"  />
          
        }
      </div>
       </>
       : <Loading/>
        }
    </>
  )
}