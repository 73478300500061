import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { useState } from "react";

interface CheckBoxListProps {
  mode?: "create" | "view" | "edit";
  items?: any;
  onChange?: any;
  textboxName?: any;
}

export const CheckBoxList = ({ items, mode, onChange, title, textboxName }) => {
  const [listItems, setListItems] = useState(items);
  const handleChange = (changeItem) => {
    const newObj = {
      ...listItems,
      [changeItem]: !listItems[changeItem],
    };
    setListItems(newObj);
    onChange(newObj);
  };

  return (
    <div className="border border-1 rounded-1 p-2 my-1">
      <div className=" text-box__label text-primary mb-1">{title}:</div>
      <input
        type="text"
        className="d-none"
        name={textboxName}
        value={JSON.stringify(listItems)}
      />
      <div className="row">
        {Object.keys(listItems)?.map((item: string) => {
          return (
            <div className="col-5">
              <CheckableButton
                defaultChecked={items[item]}
                defaultValue={items[item]}
                labelText={
                  item.endsWith("Id") ? item.slice(0, item.length - 2) : item
                }
                inputName={item}
                type="checkbox"
                mode={mode}
                onchange={(e) => {
                  handleChange(item);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
