import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useRef, useState } from "react";
import { OrganizationType } from "types/enum";
import { trans } from "utils/helpers";
import { getProvinceDictionary  } from "../api";
interface CustomerPositionPropsType {
  label: string,
  hasBorder?: boolean,
  resetForm?: boolean,
  required?: boolean,
  value?: string,
  ProvinceName?: string,
  mode?: string,
  name: string,
  Change?: (e) => void,
  onChange?: (e) => void,
  countryId?: string,
}

export const ProvinceDictionary = ({ label, countryId,ProvinceName, hasBorder, value, mode ,resetForm,required , name="", Change ,onChange}: CustomerPositionPropsType) => {
  const [province, setProvince] = useState<any>();
  const [valueName, setValueName] = useState<any>(ProvinceName);
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [validat , setValidat] = useState(false);
  const [count,setCount] = useState<number>(0);
  const inputRef = useRef<any>();
  const inputIdRef = useRef<any>();
  const ProvinceRef = useRef<any>(province);
  const validRef = useRef<any>(false);
  const countRef = useRef<any>(0)

  let optionList : any =[]

  async function getProvinceDictionaryAsync(search?, id?) {
    
    try {
    const res = await getProvinceDictionary(search,countryId,id);
    if(Object.keys(res.Data).length>0){
      if (id) {
        setValeu(res.Data[id] , id)
        setValueName(res.Data[id])
        onChange && onChange({Name:res.Data[id] , Id : id})
      }

    }else{
      onChange && onChange(null)
    }
    setProvince(res.Data)     
    ProvinceRef.current = res.Data
    setFlag(!flag)
  } catch (err: any) {
    onChange && onChange(null)

    }

  }

  const Auto = (e) => {
    validRef.current = false
    setValidat(false)
    setShow(true)
    getProvinceDictionaryAsync(e.target.value,"")
    setFlag(!flag)

  }

  function userSelecter(){
    setValeu(optionList[countRef.current],Object.keys(province)[countRef.current])
    setShow(false)
  }

  const kydown = (event) => {    
    if((event.key === "ArrowDown")){
      if(countRef.current < optionList.length-1){
        countRef.current = countRef.current + 1;
      }else{
        countRef.current = 0;
      }
    }
    
    if(event.key === "ArrowUp"){
      if(countRef.current > 0){
        countRef.current = countRef.current -1;
      }else{
        countRef.current = optionList.length-1;
      }
    }
    document.getElementById("ProvinceDictionary"+countRef.current)?.scrollIntoView({behavior:"smooth"})
    setCount(countRef.current)

    if(event.key === "Enter"){
      userSelecter()
    }
  }
  const CheckProvince =async (e)=>{
    
    setTimeout(() => {
       
      if(!validRef.current){
        
        if(province!=undefined &&  Object.values(province).length == 1){
          setValeu(Object.values(province)[0] , Object.keys(province)[0])
          e.target.setCustomValidity("");
        }else{
          if(inputRef?.current){
          setValeu(null,null)
          }

          if(inputRef?.current?.value.length >0 && inputRef?.current?.value!=""){
            e.target.setCustomValidity(trans("Msg_Validation_ValueIsIncorrect"));
            setValidat(true);
            e.target.reportValidity();
          }
          setProvince(null);
          
        }
      }
    }, 200);
    await delay(200)
    setShow(false)
  }
  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));




  const setValeu = (newValue, id) => {
    if(inputIdRef != null && inputIdRef.current != null ){
      validRef.current = true
      inputRef.current.value = newValue
      inputIdRef.current.value = id
      ProvinceRef.current = null;
      ((value==null &&id)|| id != value) ? Change&&Change({[name] :id}):Change&&Change({[name]:null});
      onChange && onChange({Name:newValue , Id : id})
      setProvince(null)
      setFlag(!flag)
      setProvince({[inputIdRef.current.value]:inputRef.current.value})
      setValidat(false)
    }
  }
  useEffect(() => {
    if(value && mode!="view"){
      getProvinceDictionaryAsync(null,value)
     }else if(value == null){
      

     }
  }, [value,resetForm]);


  useEffect(() =>{
    if(show ){
      document.addEventListener('keydown', kydown);
      document.body.addEventListener("click", clickToclose)

    } 
     return () => { document.removeEventListener("keydown", kydown);document.body.removeEventListener('click', clickToclose) }

    
  },[show ,optionList])

  
  function clickToclose(e){
    let input = document.querySelector("#browser"+name);
    if (e.target.id !== input?.id) { 
      setShow(false);
    }
  }



  if(province){
    optionList = Object.values(province)
  }
  return (
    <>
    <div className="text-box">

    <label htmlFor={`browser${name}`} className="text-box__label">{trans(label)}</label>
    {required && <span className="text-box__required-sign-for-label text-danger">*</span>}
    <input required={required} defaultValue={value && value} name={name} className="d-none" ref={inputIdRef} />
    <input  required={required} onBlur={CheckProvince} defaultValue={valueName} autoComplete="off" onFocus={(e)=>{  setShow(true);Auto(e)}}  ref={inputRef} list="ProvinceDictionary" name="browser" className="text-box__box form-control " type={'text'}    id={"browser"+name} onMouseDown={(e)=>e.stopPropagation() } onChange={Auto} disabled={mode =="view" ? true : false} />
    {
       show && 
        <div tabIndex={-1} className={`text-box__sub-menu mt-1`} >
          {optionList && optionList.map((item , index)=>{
                 return  <div id={"ProvinceDictionary"+index} className={`${count == index ?"text-box__sub-menu-option-active" : "text-box__sub-menu-option"}`} onClick={()=>{setValeu(item,Object.keys(province)[index])}} key={Object.keys(province)[index]} >{item}</div>
           })}  
        </div>
     }  
      <div className="text-box__msg-wrapper">
       {required && <span className={`${validat ? "show-msg" : "hide-msg"} text-box__required-msg text-danger`}>{trans("Msg_Validation_ValueIsIncorrect")}</span>}
       </div>    
    </div>
    </>
   )
}