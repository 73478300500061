

import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";

import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";
import { deleteForm, getAllForms } from "../../api";
import { DynamicFormData } from "./DynamicFormData";
import { useLocation } from "react-router-dom";


interface Form {
    Title?: string,
    Status?: number
}
export const DynamicFormsPage = ({ }: Form) => {
    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [showEventForm , setShowEventForm] = useState(false)
  const location = useLocation() as any;
  const queryParams = new URLSearchParams(location.search);
  const systemKey = queryParams.get("systemKey");
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response?.Data ,1 ,trans("DynamicForms"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "remove": if (selectedObj?.Id) Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
        }
    };
    const handleCreateAction = () => {
        setMode("create")
        setShowEventForm(true)
    }
    const handleEditRow = () => {
        setMode("edit");
        setShowEventForm(true)
    }

    const handleViewRow = () => {
        setMode("view")
        setShowEventForm(true)
    }

    const handleDeleteRow =async () => {
        try {
            await deleteForm(selectedObj?.FormKey)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }

    }

    const cansel = ()=>{
        setShowEventForm(false)
        setForceUpdate(!forceUpdate)
    }

    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }

      async function getFormsAsync() {
        setLoading(false)
        setResponse(null)
        try {
            const res = await getAllForms();
            setResponse(res.Data)
            setMessageType("info")
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)
    }




    useEffect(() => {
    
         getFormsAsync();
        
    }, [forceUpdate]);

    useEffect(() => {
    
        if(systemKey){
            setSelectedObj({FormKey:systemKey})
            setShowEventForm(true)
        }
        
    }, [systemKey]);

    return (
        <>   
              {showEventForm ? <ModalForm modalHeight={50} mode={mode} onHide={()=>cansel()} show={showEventForm} title={selectedObj?.FormTitle} ><DynamicFormData formObj={selectedObj} /> </ModalForm> : null}
            <div className="cartable-action">
                <Toolbar emptyList={response?.length==0} remove={true} id={selectedObj?.Id} search={true} handleClickToolbar={clickToolbarCallback} />
                <div className="card" style={{overflowY: "auto",height: "74vh",scrollbarWidth: "thin",}}>
                    <div className="">
                        {loading ?
                            <>
                                {
                                    response?.length > 0 ?
                                        <>
                                            <DataGrid
                                                parentName={"FormsInformation"}
                                                startIndex={1}
                                                theadData={Object.keys(response[0])}
                                                tbodyData={response}
                                                minCellWidth={80}
                                                selectedRowObjectCallback={selectedObjCallback}
                                                handleView={handleViewRow}
                                                handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}

                                            />
                                        </>
                                        : <Message message={"NoDataForPreview"} type={messageType} />
                                }
                            </>
                            : <Loading />
                        }
                    </div>
                </div>
            </div>
        </>

    );
}