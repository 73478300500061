
import { DataGrid } from "components/DataGrid/DataGrid";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { downloadCSV, getGridSetting, getPageSize, trans } from "utils/helpers";
import { getSalesPreInvoice } from "./api";
import { ModalForm } from "components/ModalForm/ModalForm";
import { SalesPreInvoiceForm } from "./SalesPreInvoiceForm";



export const SalesPreInvoices = () => {
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState<any>("info");
    const [response, setResponse] = useState<any>([]);
    const [selectedObj, setSelectedObj] = useState<string | any>(null);
    const [mode, setMode] = useState<'create'|'edit'|'view'>("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showSalesPreInvoiceForm, setShowSalesPreInvoiceForm] = useState(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [search, setsearch] = useState(null);
    const [filterAndSort, setFilterAndSort] = useState<any>(null);
    const layoutContext = useContext(LayoutContext);
    const [pages, setPages] = useState<number>(0);

    const searchHandler = (str) => {
        setPageNum(1);
        setSelectedObj(null)
        if (str === "") {
            setsearch(null)
            getSalesPreInvoicesAsync(1, null);
        } else {
            setsearch(str)
            getSalesPreInvoicesAsync(1, str);
        }
    }
    const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(e.currentTarget.value);
        getPageSize("salesPreInvoiceList", e.currentTarget.value)
        getSalesPreInvoicesAsync(1);
    };
    const handleGoPage = (value) => {
        if (value) {
            setPageNum(+value);
            getSalesPreInvoicesAsync(+value);
        } else {
        }
    };
    const handleNextPage = () => {
        if (response.TotalRecords && ((response.TotalRecords / pageSize) > pageNum)) {
            setPageNum((pageNum) => { return +pageNum + 1 });
            getSalesPreInvoicesAsync(pageNum + 1);

        }
    };
    const handlePrevPage = () => {
        if (pageNum > 1) {
            setPageNum((pageNum) => { return +pageNum - 1 });
            getSalesPreInvoicesAsync(pageNum - 1);
        }
    };



    const selectedObjCallback = (obj, index) => {
        setSelectedObj(obj);
    }

    const handleCreateSalesPreInvoice = (id) => {
        setMode("create");
        setShowSalesPreInvoiceForm(true)
    }



    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel": downloadCSV(response?.Data ,6 ,trans("salesPreInvoiceList")); break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateSalesPreInvoice(""); break;
            case "view": if (selectedObj) {  setMode("view");setShowSalesPreInvoiceForm(true)  } else { Toast(trans("Msg_SelectRow"), "warning") } break;
            case "edit": if (selectedObj){setMode("edit");setShowSalesPreInvoiceForm(true) }; break;
            case "help": alert("call api help"); break;

        }
    };
  
    async function getSalesPreInvoicesAsync(pagNum = pageNum, searchSTR = search) {
        setLoading(false)
        try {
        let pageSizeChangedByUser = getPageSize("salesPreInvoiceList")
        setPageSize(pageSizeChangedByUser)
        if(layoutContext.currentApplication!=undefined){
            const res = await getSalesPreInvoice(pagNum, pageSizeChangedByUser, searchSTR, filterAndSort?.OrderFormat?.join(","), filterAndSort?.Filterlist)
            if (res.Data.Data.length > 0) {
                setResponse(res.Data)
                setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1) ;
            }
        }
        } catch (error) {
            setMessageType("error")
        }
        setLoading(true)

    }
    useEffect(() => {
        setSelectedObj(null)
        setResponse(null)
        if(filterAndSort!=null&&layoutContext.currentApplication!=undefined){
            getSalesPreInvoicesAsync();
        }
    }, [forceUpdate, filterAndSort ,layoutContext.currentApplication]);
    useEffect(() => {
        let settings = getGridSetting("salesPreInvoiceList")
        setFilterAndSort({ Filterlist: settings[0], OrderFormat: settings[1] })

    }, []);


    return (
        <>  

            {showSalesPreInvoiceForm ?
                <ModalForm  modalHeight={49} mode={mode} onHide={()=>{setShowSalesPreInvoiceForm(false)}} show={showSalesPreInvoiceForm} title={"salesPreInvoiceForm" } >
                    <SalesPreInvoiceForm 
                        step={1}
                        mode={mode} 
                        relod={()=>{setForceUpdate(!forceUpdate)}}  
                        rowObject={mode == "create" ? null : selectedObj}
                        onHide={()=>{setShowSalesPreInvoiceForm(false)}} /> 
                </ModalForm> 
                 : null}
            <Toolbar  view={true} handleSearch={searchHandler} id={selectedObj} emptyList={response?.TotalRecords == 0} search={true} handleClickToolbar={clickToolbarCallback} />
            <div style={{ overflowY: "auto", 
          height: "78vh",
           scrollbarWidth: "thin" }} className="col-lg-12 mt-0">
                {loading ? <>{
                    response?.Data?.length > 0 ?
                        <div className="card">
                            <div className="card-body p-0">
                                <>
                                    <DataGrid
                                        DoubleClick={(obj) => { setSelectedObj(obj); clickToolbarCallback("view") }}
                                        View={true}
                                        ViewParametr={"NotSeen"}
                                        handlefiltering={(str:any) => {str.Filterlist != undefined  ? setFilterAndSort(str) : setFilterAndSort({...filterAndSort, Filterlist:str})}}
                                        filterStr={filterAndSort.Filterlist}
                                        orderFormatList={filterAndSort.OrderFormat}
                                        orderFormat={(order) => { setFilterAndSort({ ...filterAndSort, OrderFormat: order }) }}
                                        parentName={"salesPreInvoiceList"}
                                        theadData={Object.keys(response.Data[0])}
                                        tbodyData={response.Data}
                                        minCellWidth={80}
                                        scrollHeight={true}
                                        selectedRowObjectCallback={selectedObjCallback}
                                        rowSelected={selectedObj}
                                        startIndex={2}
                                        handleView={(e)=>{  setSelectedObj({Id:e});setMode("view");setShowSalesPreInvoiceForm(true)}}
                                        totalRecords={response.TotalRecords}
                                        pageNum={pageNum}
                                        pageSize={pageSize}
                                        HandleNextPage={handleNextPage}
                                        HandlePrevPage={handlePrevPage}
                                        handlechangePageSize={handleChangePageSize}
                                        first={() => { getSalesPreInvoicesAsync(pages); setPageNum(pages) }}
                                        end={() => { getSalesPreInvoicesAsync(1); setPageNum(1) }}
                                        handleGoPage={handleGoPage}
                                    />
                                </>
                            </div>
                        </div>
                        :<Message message={"NoDataForPreview"} type={messageType} />
                        
                }</>
                    : <Loading />
                }
            </div>
        </>

    );
}