
import { useEffect, useState } from "react";

import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { TableTree } from "components/TableTree/TableTree";
import { addNewRolesAsync, getExceptOrganizationRolesList } from "../../api/api";
import { Toolbar } from "components/Toolbar/Toolbar";
import { Search } from "components/Search/Search";
import { trans } from "utils/helpers";

interface propsOrganizationalStructure {
  selectedObj:any;
  treeType:any;
  onHide: (mod) => void;
  reload: () => void;
}
export const AddRoleForm = ({selectedObj ,onHide,reload,treeType} :propsOrganizationalStructure )=> {
  const [data, setData] = useState<any>()
  const [ListResponse, setListResponse] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<any>()
  const [selectedList, setSelectedList] = useState<any>([])
  const [idCallback, setIdCallback] = useState<any>("2")
  const [messageType, setMessageType] = useState<any>("info")
  //   const [theadData , setTheadData] = useState<any>()
  const [search, setsearch] = useState(null);
  const [defultShow, setDefultShow] = useState(false);
  const [message, setMessage] = useState<any>("NoDataForPreview")


  let tbodyData: any = [];
  let theadData: any = []
  let obj: any = {}

  function set(name, value) {
    let obj2 = obj;  
    var nameList = name.split('.');
    var len = nameList.length;
    for(var i = 0; i <len-1; i++) {

        var elem = nameList[i];
        if( !obj2[elem] ) obj2[elem] = {}
        obj2 = obj2[elem];
    }
  obj2[nameList[len-1]] = value;
}




  function setTree(obj2 , parentId) {
    let list:any = Object.keys(obj2)
    let tree:any = []
    if(typeof obj2 != "string"){

    list.forEach(item=>{
      tree.push({
        Id :  parentId+item ,
        parentId : parentId ,
        Children : setTree(obj2[item] , parentId+item+"." ) ,
        Description : item
      })
    })

    }
  return tree
}

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};



async function getListAsync() {
  setLoading(false)
  try {
  const response = await getExceptOrganizationRolesList(selectedObj.Id, "")
  if(response){

    let newTypeObject:any = {}
    if(treeType){
      Object.keys(response.Data).forEach(item => {
        var nameList = response.Data[item].split('.');
        let ty = array_move(nameList, 3, 1)
        newTypeObject = Object.assign(newTypeObject,{[item] :ty.join(".") })
        set( ty.join("."),  item);
      });
      setListResponse(newTypeObject)
    }else{
      setListResponse(response.Data)
      Object.keys(response.Data).forEach(item => {
        set( response.Data[item],  item);
      });
    }
  
     let tabletr =  setTree(obj , "")
      if(tabletr.length >0){
        setData(tabletr)
      }
  }else setMessageType("info")
  } catch (err: any) {
    setMessageType("error")
    setMessage(err.response.data.Message)
  }
  setLoading(true)
}

async function addNewRoles() {
  let addList:any = []
  Object.values(ListResponse).forEach((item , index )=> {
    if(selectedList.includes(item) ){
      addList.push(Object.keys(ListResponse)[index])
    }
  });
    try {
        const response = await addNewRolesAsync(selectedObj.Id, addList)
        onHide(false)
        reload()   
    } catch (err: any) {
        
    }
}
const delay = (duration) =>
new Promise(resolve => setTimeout(resolve, duration));

const Searchhandleer =async (str) => {
  setLoading(false)
   obj = {}
  
   setData(null)
  if (str == ""||str==null) {
      setsearch(null)
      setDefultShow(false)
      getListAsync()
  }else{
    setDefultShow(true)
    setsearch(str)
  if(ListResponse && ListResponse != undefined){
    let newTypeObject:any = {}

    Object.keys(ListResponse).forEach(item => {
      if(str != ""&&str != null ){
        let flag = true
        ListResponse[item].split('.').forEach(word=>{
          if(flag ){
            if(str.split(" ").length > 1){
              str.split(" ").forEach(serchWord=>{
                if(trans(word).includes(serchWord)){
                  flag = false
                  return ;
                }
              })
            }else{
              if(trans(word).includes(str)){
                flag = false
                return ;
              }
            }
          }
        })
        if(!flag){
          newTypeObject = Object.assign(newTypeObject,{[item] :ListResponse[item] })
          set( ListResponse[item],  item);
        }
      }else{
        newTypeObject = Object.assign(newTypeObject,{[item] :ListResponse[item] })
        set( ListResponse[item],  item);
      }
    });

    setListResponse(newTypeObject)
    let tabletr =  setTree(obj , "")
    if(tabletr.length >0){
      await delay(5)
      setData(JSON.parse(JSON.stringify(tabletr)))
    }
    setLoading(true)

  }
  }
  
}


  useEffect(() => {
    getListAsync()
  }, [ treeType]);

  useEffect(() => {

  }, [ search]);

  if (data) {
    theadData = Object.keys(data[0])
    tbodyData = data;
  }



  
  return (
    <div className="p-2">
        <div className="col-lg-2 mb-3">
         <Search  onChange={Searchhandleer}/>
        </div>

      <div className="col-lg-12 mt-0">
        <div className="list-card card">
          <>
            {
              loading ? (
                data ?
                  <TableTree
                    multiselect={true}
                    selectedRowObjectCallback={setSelected}
                    selectedRowObject={idCallback}
                    //getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                    Data={data}
                    theadData={theadData}
                    parentName={"UsersAccess"}
                    startIndex={2}
                    openLevel={1}
                    search={search}
                    defultShow={defultShow}
                    selectedRowsCallback={setSelectedList}
                    ListResponse={ListResponse}

                  />
                  : <Message message={message} type={messageType} />
              )
                : <Loading />
            }
          </>
        </div>
        
        <div className="col-lg-12 mt-1 btns_form">
                      <Button value="Save" btnType="submit" onClick={addNewRoles} />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {onHide(false)}}
                      />

        </div>
      </div>
    </div>
  )
}