import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Person } from "components/Person/Person";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { DocsClassification } from "pages/InventoryAndSales/Sales/SalesInvoice/components/DocsClassification/DocsClassification";
import { WarehouseDictionaryWithAccess } from "pages/InventoryAndSales/Sales/SalesInvoice/components/WarehouseDictionary/WarehouseDictionaryWithAccess";
import React, { useEffect, useRef, useState } from "react";
import { warehouseIOType } from "types/enum";
import { GUID_EMPTY, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { trans } from "utils/helpers";
import {
  constraintWarehouseIo,
  getWarehouseIoId,
  getWarehouseIoNo,
  putWarehouseIO,
  sendWarehouseIO,
} from "./api";
import { InventoryRemittanceDetailForm } from "./InventoryRemittanceDetailForm";
import { WarehouseIODTO } from "./type";



interface Props {
  mode: string;
  showForm: boolean;
  selectedObj: any;
  onHide: () => void;
  reload: () => void;
  warehouseIoType?: any;
  setShowWarehouseIoForm
}

export const InventoryRemittanceForm = ({
  mode,
  showForm,
  selectedObj,
  onHide,
  reload,
  warehouseIoType,
  setShowWarehouseIoForm
}: Props) => {
  const [fromDate, setFromDate] = useState<string | null>(null);

  const [resetForm, setResetForm] = useState(false);
  const [recipientPerson, setRecipientPerson] = useState<any>({});
  const [accountPartyPerson, setAccountPartyPerson] = useState<any>({});
  const [deliverPerson, setDeliverPerson] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [applicantPosition, setApplicantPosition] = useState<any>({});
  const [response, setResponse] = useState<any>(null);
  const [userChanges, setUserChanges] = useState({});
  const [constraint, setConstraint] = useState<any>({});
  const [docsClassification, setDocsClassification] = useState<any>({});
  const myform = useRef<any>(null);
  const [warehouseIoDetail, setWarehouseIoDetail] = useState<any>([]);
  const [detailMode, setDetailMode] = useState<any>([]);

  const [totalRow, setTotalRow] = useState<any>({
    QtyOut1: 0,
  });
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>();
  const [time, setTime] = useState(new Date().toISOString());
  const [nextInvoiceNumber, setNextInvoiceNumber] = useState<any>();
  const [requiredFields,setRequiredFields]=useState<any>()


  async function sendDataAsync(data, method) {
    try {
      if (method == "post") {
        let res = await sendWarehouseIO(data);
        Toast(trans(res.Message), "success");
      } else {
        let res = await putWarehouseIO(data);
        Toast(trans(res.Message), "success");
      }
      sessionStorage.setItem("changingforms", "false");
      setUserChanges({});
      onHide();
      reload();
    } catch (err: any) {}
  }

  const getConstaintWarehouseIoAsync = async (withLoading = false) => {
    if (withLoading) {
      setResponse({});
      setLoading(false);
    }

    try {
      const res = await constraintWarehouseIo(warehouseIoType);
      setConstraint(res.Data);
      setRequiredFields(JSON.parse(res.Data.RequiredFields))
      if (res.Data.IsDocClassified && mode=='create') {
        getWarehouseIoNoAsync();
      }
    } catch (error) {}
    if (withLoading) setLoading(true);
  };

  async function getWarehouseIoByIdAsync() {
    setLoading(false);
    if (mode !== "view") {
      getConstaintWarehouseIoAsync();
    } else setConstraint({});

    try {
      const res = await getWarehouseIoId(selectedObj.Id);
      setResponse(res.Data);
      setWarehouseIoDetail(res.Data.WarehouseIODetailDTOList);
      setNextInvoiceNumber(res.Data.WarehouseIONo)
      if (!!res.Data.WarehouseIODetailDTOList.length) {
        let count = 0;
        res.Data.WarehouseIODetailDTOList.map((item) => {
          count += item.QtyOut1;
        });
        setTotalRow({
          QtyOut1: count,
        });
      }
    } catch (error) {}
    setLoading(true);
  }

  const getWarehouseIoNoAsync = async () => {
    try {
      const res = await getWarehouseIoNo(
        docsClassification?.Id != GUID_EMPTY ? docsClassification?.Id : null
      );
      setNextInvoiceNumber(res.Data);
    } catch (error: any) {}
  };




  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: WarehouseIODTO = {
      DocsClassificationId:
        event.currentTarget?.docsClassificationId &&
        event.currentTarget.docsClassificationId.value !== GUID_EMPTY
          ? event.currentTarget.docsClassificationId.value
          : null,
      WarehouseIOKindId: event.currentTarget.WarehouseIOKindId.value,
      WarehouseId: event.currentTarget.WarehouseId.value,
      WarehouseIOType: +warehouseIoType,
      WarehouseIONo: parseInt(event.currentTarget.WarehouseIONo.value, 10),
      WarehouseIODate: fromDate || "",
      BillOfLadingNo: event.currentTarget.BillOfLadingNo.value,
      CalculateQuantityFromAnalysis:
        event.currentTarget.CalculateQuantityFromAnalysis.checked,
      RecipientPersonId: recipientPerson.Id,
      RecipientPersonDescription: recipientPerson.Name,
      AccountPartyPersonId: accountPartyPerson.Id,
      AccountPartyPersonDescription: accountPartyPerson.Name,
      DeliverPersonId: deliverPerson.Id,
      DeliverPersonDescription: deliverPerson.Name,
      ApplicantPositionId: applicantPosition.Id,
      ApplicantPositionDescription: applicantPosition.Name,
      WarehouseIODetailDTOList: warehouseIoDetail, // برای مثال، اینجا یک آرایه خالی است
      // DestinationWarehouseId: event.currentTarget.DestinationWarehouseId.value,
      // parseInt(event.currentTarget.WarehouseIOType.value, 10),
      // IsRegisteredByQuantity:
      //   event.currentTarget.IsRegisteredByQuantity.checked,
      // IsRegisteredByPrice: event.currentTarget.IsRegisteredByPrice.checked,
      // ProductionType: 0,
      //  parseInt(event.currentTarget.ProductionType.value, 10),
      // ValuationType: 0,
      // parseInt(event.currentTarget.ValuationType.value, 10),
      // AutoGenerateTransferReceipt:
      //   event.currentTarget.AutoGenerateTransferReceipt.checked,
      // RecipientPersonDescriptionEN: event.currentTarget.RecipientPersonDescriptionEN.value,
      // AccountPartyPersonDescriptionEN: event.currentTarget.AccountPartyPersonDescriptionEN.value,
      // DeliverPersonDescriptionEN: event.currentTarget.DeliverPersonDescriptionEN.value,
      //  ApplicantPositionDescriptionEN: event.currentTarget.ApplicantPositionDescriptionEN.value,
    };
    if (mode === "create") {
      sendDataAsync(data, "post");
    } else {
      sendDataAsync({ ...data, Id: selectedObj.Id }, "put");
    }
  };

  const handleCallBackWarehouseIODetail = (newData) => {
    if (detailMode == "create") {
      setWarehouseIoDetail([...warehouseIoDetail, newData]);
    } else {
      let newItemes = warehouseIoDetail?.map((item: any) => {
        if (item.Id == newData.Id) {
          return newData;
        } else {
          return item;
        }
      });
      setWarehouseIoDetail(newItemes);
    }
    setTotalRow({
      QtyOut1: totalRow.QtyOut1 + newData.QtyOut1,
    });
  };
  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }

  const handleDeleteRow = async (id: string = selectedObj?.Id) => {
    let newPriceListDetail: any = [...warehouseIoDetail];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setTotalRow({
      QtyOut1: totalRow.QtyOut1 - obj.QtyOut1,
    });
    setWarehouseIoDetail(newPriceListDetail.filter((e) => e.Id != id));
  };

  const selectedObjCallback = (obj, index) => {
    setSelectedRow(obj);
  };

  useEffect(() => {
    if (mode !== "create") {
      getWarehouseIoByIdAsync();
    } else {
      getConstaintWarehouseIoAsync(true);
    }
  }, []);

  return (
    <div className="card">
      {showDetailForm && (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowDetailForm(false);
          }}
          show={showDetailForm}
          title={"warehouseIODetail"}
        >
          <InventoryRemittanceDetailForm
            warehouseIODetail={handleCallBackWarehouseIODetail}
            mode={detailMode}
            onHide={() => setShowDetailForm(false)}
            warehouseId={warehouseData?.WarehouseId}
            selectedObj={selectedRow}
          />
        </ModalForm>
      )}
      {loading ? (
        <>
          <form className={"card-body"} ref={myform} onSubmit={handleSubmit}>
            <div className="mb-2">
              {mode != "view" ? (
                <div className="col-lg-12  btns_form mb-3">
                  <Button
                    // disabled={!(salesOrderDetail.length > 0)}
                    value="save"
                    btnType="submit"
                  />

                  <Button
                    btnType="cancel"
                    value="Cancel"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              ) : (
                <div className="col-lg-12 mt-1 btns_form mb-3">
                  <Button
                    btnType="primary"
                    value="close"
                    onClick={() => {
                      cancel();
                    }}
                  />
                </div>
              )}
            </div>
            <FormWithTitle
              title={
                trans("warehouseIo") +
                " _ " +
                trans("WarehouseIONo") +
                " : " +
                (response.WarehouseIONo?.toString() ?? constraint.IsDocClassified?nextInvoiceNumber:constraint.DocNo
                  ? constraint.DocNo
                  : "")
              }
              defualtClose={true}
              showFristRow={true}
              closeOption={true}
            >
              <div className="row px-2 py-1">
                <div className="col-11 col-lg-3">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    labelText="WarehouseIONo"
                    textboxName="WarehouseIONo"
                    resetForm={resetForm}
                    readonly={!constraint.IsDocNoEditable}
                    defaultValue={
                      constraint.IsDocClassified
                        ? nextInvoiceNumber
                        :mode!=='create'?response.WarehouseIONo?.toString():constraint.DocNo
                    }
                    mode={constraint.IsDocNoEditable?mode:'view'}
                    required={requiredFields?.WarehouseIONo}
                  />
                </div>
              {constraint.IsDocClassified && (
                  <div className="col-lg-3 col-11 ">
                    <DocsClassification
                      label="Classification"
                      name="docsClassificationId"
                      baseUrl={INVENTORYANDSALES_URL}
                      endPoint={
                        ENDPOINTSINVENTORYANDSALES.Inventory
                          .BaseDefinitionAndSettingApi
                          .InventoryDocsClassification.dictionary
                      }
                      resetForm={resetForm}
                      value={response.DocsClassificationId}
                      mode={mode}
                      IsDocClassified={constraint.IsDocClassified}
                      onChange={(e, obj) => {
                        setDocsClassification(obj);
                        if((e!=docsClassification.Id && nextInvoiceNumber && !constraint.IsClassificationIgnored) || (mode=='edit' && (!response.DocsClassificationId	 &&!docsClassification.Id) && constraint.IsClassificationIgnored)  ) {
                          //توضیح کامل شرط:
                          //وقتی مد ایجاد هست شرط اول اجرا شده
                          //وقتی مد ویرایش هست و در ریسپانس مقدار داکس کلسیفیکیش داریم شرط اول اجرا میشه
                          //در مد ادیت اگر ریسپانس مقدار داکس کلسیفیکیشن نداشته باشد شرط دوم اجرا شده به این صورت که چون میخواد جدید داکس کلسیفیکیشن بگیره پس باید درخواست بزنه و شماره جدید بگیره و فقط یکبار باید این اتفاق بیوفته چون ایگنور داریم و دفعات بعدی اگر داکس تغییر کرد نباید شماره جدید بگیره پس باید بررسی کنیم داکس مقدار نداشته باشه و یا ریسپانس داکس مقدار نداشته باشه
                          getWarehouseIoNoAsync()
                        }
                        
                      }}
                    />
                  </div>
                )}

                <div className="col-11 col-lg-3">
                  <DictionarySelectBox
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Inventory.WarehouseIOApis
                        .warehouseIOKindDictionary
                    }
                    label="WarehouseIOKind"
                    name="WarehouseIOKindId"
                    params={{
                      warehouseIOType: warehouseIOType.InventoryRemittance,
                    }}
                    value={response.WarehouseIOKindId}
                    mode={mode}
                  />
                </div>


                <div className="col-11 col-lg-3">
                  <WarehouseDictionaryWithAccess
                    name="WarehouseId"
                    label="Warehouse"
                    onChange={(e, obj) => {
                      setWarehouseData({
                        WarehouseId: e,
                        WarehouseTitle: obj.Name.Description,
                        WarehouseDetailsCount: obj.Name.WarehouseDetailsCount,
                      });
                    }}
                    mode={mode}
                    value={response.WarehouseId}
                    required={requiredFields?.WarehouseId}
                  />
                </div>

        {  mode==='view'&&<div className="col-11 col-lg-3">
                  <Position
                    name="RetrievalUser"
                    label="RetrievalUser"
                    value={response.RetrievalUser}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>}
                {/* <div className="col-lg-3 col-11 mb-3 mb-lg-0">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    label="WarehouseIOType"
                    enumTypeSelect="WarehouseIOType"
                    name="WarehouseIOType"
                    mode={mode}
                    value={response.WarehouseIOType}
                  />
                </div> */}

                <div className="col-11 col-lg-3">
                  <Datepicker
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    mode={constraint.IsNewDocCurrentDate ? "view" : mode}
                    resetForm={resetForm}
                    lable="WarehouseIODate"
                    setIsoTime={(isoTime: string) => {
                      setFromDate(isoTime);
                    }}
                    val={response.WarehouseIODate ?? time}
                    required={requiredFields?.WarehouseIODate}
                  />
                </div>
                <div className="col-11 col-lg-3">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    labelText="BillOfLadingNo"
                    textboxName="BillOfLadingNo"
                    resetForm={resetForm}
                    defaultValue={response.BillOfLadingNo}
                    mode={mode}
                    required={requiredFields?.BillOfLadingNo}
                  />
                </div>
                {/* 
                <div className="col-lg-3 col-11">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    label="ProductionType"
                    enumTypeSelect="ProductionType"
                    name="ProductionType"
                    mode={mode}
                    value={response.ProductionType}
                  />
                </div>
                <div className="col-lg-3 col-11">
                  <EnumerationTypeSelect
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    label="ValuationType"
                    enumTypeSelect="ValuationType"
                    name="ValuationType"
                    mode={mode}
                    value={response.ValuationType}
                  />
                </div> */}

                <div className="col-lg-3 col-11">
                  <Person
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    value={response.RecipientPersonId}
                    resetForm={resetForm}
                    mode={mode}
                    WithSearch={false}
                    setPerson={setRecipientPerson}
                    returnObj={true}
                    name={"RecipientPerson"}
                    label="RecipientPerson"
                    required={requiredFields?.RecipientPersonId}
                  />
                </div>
                <div className="col-lg-3 col-11">
                  <Person
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    value={response.AccountPartyPersonId}
                    resetForm={resetForm}
                    mode={mode}
                    WithSearch={false}
                    setPerson={setAccountPartyPerson}
                    returnObj={true}
                    name={"AccountPartyPerson"}
                    label="AccountPartyPerson"
                    required={requiredFields?.AccountPartyPersonId}
                  />
                </div>
                <div className="col-lg-3 col-11">
                  <Person
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    value={response.DeliverPersonId}
                    resetForm={resetForm}
                    mode={mode}
                    WithSearch={false}
                    setPerson={setDeliverPerson}
                    returnObj={true}
                    name={"DeliverPerson"}
                    label="DeliverDescription"
                    required={requiredFields?.DeliverPersonId}
                  />
                </div>
                <div className="col-lg-3 col-11">
                  <Position
                    name="ApplicantPosition"
                    label="ApplicantPositionDescription"
                    resetForm={resetForm}
                    value={response.ApplicantPositionId}
                    mode={mode}
                    returnObj={true}
                    onChange={setApplicantPosition}
                    required={requiredFields?.ApplicantPositionId}
                  />
                </div>

                <div className="col-11 col-lg-3">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    labelText="AccountPartyPersonDescription"
                    textboxName="AccountPartyPersonDescription"
                    resetForm={resetForm}
                    defaultValue={response.AccountPartyPersonDescription}
                    mode={mode}
                  />
                </div>
                <div className="w-100"></div>
                {/* <div className="col-11 col-lg-6">
                  <CheckableButton
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    defaultChecked={
                      response ? response.AutoGenerateTransferReceipt : false
                    }
                    defaultValue={
                      response ? response.AutoGenerateTransferReceipt : false
                    }
                    labelText={"AutoGenerateTransferReceipt"}
                    inputName={"AutoGenerateTransferReceipt"}
                    type="checkbox"
                    mode={mode}
                  />
                </div> */}
                <div className="col-11 col-lg-6">
                  <CheckableButton
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    defaultChecked={response.CalculateQuantityFromAnalysis}
                    defaultValue={response.CalculateQuantityFromAnalysis}
                    labelText={"CalculateQuantityFromAnalysis"}
                    inputName={"CalculateQuantityFromAnalysis"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
                {/* <div className="col-12 col-lg-6">
                  <CheckableButton
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    defaultChecked={response.IsRegisteredByQuantity  }
                    defaultValue={ response.IsRegisteredByQuantity }
                    labelText={"IsRegisteredByQuantity"}
                    inputName={"IsRegisteredByQuantity"}
                    type="checkbox"
                    mode={mode}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CheckableButton
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    defaultChecked={ response.IsRegisteredByPrice 
                    }
                    defaultValue={ response.IsRegisteredByPrice 
                    }
                    labelText={"IsRegisteredByPrice"}
                    inputName={"IsRegisteredByPrice"}
                    type="checkbox"
                    mode={mode}
                  />
                </div> */}
              </div>
            </FormWithTitle>
          </form>

          <div className="card-body mt-3 mb-5">
            <div className="row mb-3">
              <div className="col-6 ">
                {mode != "view" && (
                  <div
                    onClick={() => {
                      if (warehouseData?.WarehouseId) {
                        setShowDetailForm(true);
                        setDetailMode("create");
                      } else {
                        Toast("msg-selectedwarehouse", "error");
                      }
                    }}
                    className="col-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                    style={{
                      width: "70px",
                      maxHeight: "30px",
                      marginRight: "20px",
                      minWidth: "70px",
                    }}
                  >
                    <>
                      <span className=" font-size-lg ms-2 ">
                        {trans("add")}
                      </span>
                      <FontAwesomeIcon
                        size="xs"
                        icon={["fas", "plus"]}
                        className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                        color="white"
                      />
                    </>
                  </div>
                )}
              </div>
              <div className="col-6 ">
                <Headerbar
                  withPading={false}
                  headerContents={totalRow}
                  hasBorder={true}
                />
              </div>
            </div>
            <div>
              {(
                mode == "view"
                  ? warehouseIoDetail?.length > 0
                  : constraint?.DetailDTO
              ) ? (
                <>
                  <DataGrid
                    convertDateColNames={["ExpireDate"]}
                    DoubleClick={(e) => {
                      setSelectedRow(e);
                      setDetailMode("edit");
                      setShowDetailForm(true);
                    }}
                    parentName={"warehouseIoDetail"}
                    theadData={
                      mode !== "view"
                        ? Object.keys(constraint?.DetailDTO)
                        : Object.keys(warehouseIoDetail?.[0])
                    }
                    tbodyData={warehouseIoDetail}
                    totalRecords={warehouseIoDetail.length > 0 ? undefined : 0}
                    scrollHeight={"67vh"}
                    minCellWidth={80}
                    startIndex={17}
                    selectedRowObjectCallback={selectedObjCallback}
                    handleEdit={
                      mode != "view"
                        ? (e) => {
                            setSelectedRow(e);
                            setDetailMode("edit");
                            setShowDetailForm(true);
                          }
                        : undefined
                    }
                    rowSelected={selectedRow}
                    handleDelete={
                      mode != "view"
                        ? (e) => {
                            Toast(
                              trans("msg_deleteconfirm"),
                              "warning",
                              "alert",
                              () => handleDeleteRow(e)
                            );
                          }
                        : undefined
                    }
                    handleView={(e) => {
                      setSelectedRow(e);
                      setDetailMode("view");
                      setShowDetailForm(true);
                    }}
                  />
                </>
              ) : (
                <Message message={"NoDataForPreview"} type={"info"} />
              )}
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
