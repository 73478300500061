import { Button } from "components/Button/Button";
import { Toast } from "components/ToastShow/ToastShow";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getUserInfoForm, postUserInfoForm } from "../api";
import { Loading } from "components/Loading/loading";

export const UserInfoForm = ({Id, hide, mode  }) => {
    //const [selectedUserId, setSelectedUserId] = useState<string>(userId);
    const [response, setResponse] = useState<Response | any>();
    const [dataChange, setDataChange] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [valid, setValid] = useState<any>(true);

    const sendDataAsync = async ( InfoData) => {
            try {
                const pass = await postUserInfoForm(InfoData)
                hide()
            } catch (error2) {

            }
    }

    const getDataAsync = async ( ) => {
        setLoading(false)
            try {
                const pass = await getUserInfoForm()
                setResponse(pass.Data)
            } catch (error2) {

            }
        setLoading(true)
    }


    const formSubmit = (event) => {
        event.preventDefault()
        const InfoData = {
            Id:response.Id,
            Email:response.Email,
            UserName: event.target.UserName.value
        }
        sendDataAsync(InfoData);
    }

    useEffect(() => {
        getDataAsync();
    }, [  ]);

    return (
        <div className="card p-3">
            <form onSubmit={formSubmit}>
            {loading ?
                <div className="container-fluid">
                    <div className="row my-1">
                        <div className="col-12 col-sm-6 my-1 ">
                            <Textbox
                                defaultValue={response.UserName}
                                setValid={setValid}
                                Change={setDataChange}
                                required={true}
                                textboxType="userName"
                                textboxName="UserName"
                                labelText="UserName"
                            />
                        </div>
{/*                         <div className="col-12 col-sm-6 my-1">
                            <Textbox
                                setValid={setValid}
                                defaultValue={response.Email}
                                Change={setDataChange}
                                textboxType="email"
                                textboxName="email"
                                labelText="email"
                            />
                        </div> */}
                    </div>
                
                    <div className="row my-1 d-flex justify-content-end">
                        {mode != "view" ?
                            <div className="col-4 d-flex justify-content-end">
                                <Button btnType="submit" value={'Save'} />
                                <Button
                                    btnType="cancel"
                                    value="Cancel"
                                    onClick={() => {
                                        dataChange ? Toast(trans("modalclosemessage"), "warning", "alert", hide)
                                            : hide()
                                    }}
                                />
                            </div>
                            :
                            <div className="col-4 d-flex justify-content-end">
                                <Button
                                    btnType="primary"
                                    value="Close"
                                    onClick={hide}
                                />
                            </div>
                        }
                    </div>
                </div>
                : <Loading/>}
            </form>
        </div>
    )
}

