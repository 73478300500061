import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { acceptReference, getReference, postReferences } from "../../api/api";
import { AssignTypes, ReferenceLocalDTO } from "../../types";
import { ReferenceContent } from "./components/ReferenceContent/ReferenceContent";
import './style.scss';
import { ReferenceForm } from "./components/ReferenceForm/ReferenceForm";
import { trans } from "utils/helpers";
import { Button } from "components/Button/Button";
import { AcceptSelfService } from "../AcceptSelfService/AcceptSelfService";
import { MessageType } from "types/enum";
import { Toast } from "components/ToastShow/ToastShow";

interface props {

    styleCondition: any
    Reference?: any
    rowObject?: any
    referenceId: string
    onHide: () => void
    reload?: (e) => void
    hideReferenceAndFinish?: () => void
}

export const ReferenceStepper: React.FC<props> = ({ styleCondition,Reference,rowObject, referenceId, onHide,hideReferenceAndFinish , reload }) => {

    let taskList: any[] = []
    let taskPositionList: ReferenceLocalDTO[] = []
    const [flag, setFlag] = useState<any>(true);
    const [data, setData] = useState<any>([]);
    const [response, setResponse] = useState<Response | any>(null);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [referenceList, setReferenceList] = useState<any>([]);
    const [passList, setPassList] = useState<any>([]);
    const [messageType, setMessageType] = useState<any>("info");
    const [message, setMessage] = useState<any>("NoDataForPreview");
    const [selectAtLeastOneTask, setSelectAtLeastOneTask] = useState<any>();
    const [showAccept, setShowAccept] = useState<any>(true);
    const [ParentReferenceId, setParentReferenceId] = useState<any>();

    const layoutContext = useContext(LayoutContext);
    const handleSubmitedData = async(data ,TAS_UID) => {
        if(!flag){
            if (!data) {   // add more rules later for form completion
             
            } else {
                let fillReferences:any = []
    
                    let obj:any={}
                    if(data.ReferenceSubjectId != ""){
                        obj.ParentReferenceId = data.ParentReferenceId
                        obj.JobId = data.JobId
                        obj.TAS_UID = data.TAS_UID
                        obj.PerformerPositionId = data.PerformerPositionId
                        obj.ReferrerPositionId = data.ReferrerPositionId
                        obj.ReferenceSubjectId = data.ReferenceSubjectId
                        obj.Description = data.Description
                        obj.Priority = data.Priority
                        obj.EstimateTime = data.EstimateTime
                        obj.DeadLineDate = data.DeadLineDate
                        obj.HasFollowUp = data.HasFollowUp
                        obj.SecretReference = data.SecretReference
                        obj.AssignType = data.AssignType
                        obj.EstimateTime = data.EstimateTime
    
                      fillReferences.push(obj)
                    }
                    
            
                try {
                    const res = await postReferences([{Reference:obj,SendMessageToPerformer:data.SendMessageToPerformer}]);
    
                    //reload && reload(true)
                    onHide()
                } catch (error) {
    
                }
    
    
    
            }

        }
    }
    const getDataFromContent = (fetchedData, currentPage, status) => {

        if (fetchedData !== undefined) {
            setReferenceList([]) 
            setPassList({ ...passList, [page]: status })
            setReferenceList(fetchedData)
        }
        setPage(currentPage)
    }

    const getReferenceAsync = async () => {
        setLoading(false)
        try {
            if(Reference){
                setResponse(Reference);
                setData(Reference.Tasks)
            }else{

                const res = await getReference(referenceId)
                if(res.Data.Tasks.length ==1 &&res.Data.Tasks[0].Positions.length ==1 ){
                    setFlag(false)
                }
                setSelectAtLeastOneTask(res.Data.SelectAtLeastOneTask)
                setResponse(res.Data);
                setData(res.Data.Tasks)
                setMessage(res?.Message)
                setMessageType({type: "info" , Message :res?.Message })
            }
        } catch (error:any) {
            if(error.response?.data.MessageType == MessageType.confirm){
                Toast(
                    trans(error.response?.data.Message),
                    "warning",
                    "alert",
                    () => {
                        hideReferenceAndFinish&&  hideReferenceAndFinish()
                    },
                  );
            }
            setMessageType("error")     
             setMessage(error?.response?.data?.Message)


        }


        setLoading(true)
    }
    const handlePass = (stepStatus, page) => {

    }
    async function acceptReferenceAsync() {
        try {
            await acceptReference(ParentReferenceId);
            setShowAccept(false)
            reload&&  reload(true)
            getReferenceAsync()
        } catch (error) {

        }
    }

    useEffect(() => {
        if (data.length > 1){
            if (styleCondition) {
                (document.querySelector(".ProgresBarTask-mb-scroll") as HTMLElement).scrollTop = 0;
            } else {
                (document.querySelector(".ProgresBarTask-scroll") as HTMLElement).scrollLeft = 0;
            }
        }
    }, [styleCondition])

    useEffect(() => {
        if (referenceId) {
            if((rowObject?.AssignType == AssignTypes.SELF_SERVICE&&rowObject?.AcceptTime==null) && showAccept&&rowObject.Id==referenceId){
            }else{
                setParentReferenceId(referenceId)
                getReferenceAsync()
            }
        }
    }, [])




    const fillAllTaskPositionList = () => {
        taskPositionList = []
        Object.keys(taskList).map((TaskIndex) => {
            if (!taskList[TaskIndex])
                return
            let taskPositions = taskList[TaskIndex].Positions
            Object.keys(taskPositions).map((index) => {
                let rowItem: ReferenceLocalDTO = {
                    AssignType  :AssignTypes[taskList[TaskIndex].AssignType],
                    ParentReferenceId: ParentReferenceId,
                    Id: taskPositions[index] ? taskPositions[index].Id : "",
                    TAS_UID: taskList[TaskIndex].TaskId,
                    JobId: response?.JobInfo?.Id,
                    PerformerPositionId: taskPositions[index] ? taskPositions[index].Id : "",
                    ReferrerPositionId: layoutContext.currentUserPosition.PositionId,
                    ReferenceSubjectId: "",
                    Position: taskPositions[index] ? taskPositions[index].Description : "",
                    Description: "",
                    ReferenceSubject: "",
                    Priority: 1,
                    EstimateTime: 0,
                    DeadLineDate: undefined,
                    HasFollowUp: false,
                    SecretReference: false,
                    SendMessageToPerformer: false,

                };
                taskPositionList.push(rowItem);
            })
        })
    }
    if (response) {
        taskList = response.Tasks
          fillAllTaskPositionList();  
    }

    return (
        <>
                    {((rowObject?.AssignType == AssignTypes.SELF_SERVICE&&rowObject?.AcceptTime==null) && showAccept&&rowObject.Id==ParentReferenceId  )&&
                        <AcceptSelfService  handleAcceptReferenceAsync={()=>{acceptReferenceAsync()}} onHide={(e)=>{e.stopPropagation();  setShowAccept(false)}}/>
                            
                 }  
            {loading ?
                (response ?
                    <>
                        <Headerbar hasBorder={true} headerContents={response.JobInfo} />
                        {flag ?
                        <ReferenceContent
                                selectAtLeastOneTask={selectAtLeastOneTask}
                                reload={reload}
                                handlePassStep={handlePass}
                                passList={passList}
                                referenceId={ParentReferenceId}
                                onHide={onHide}
                                pageIndex={page}
                                jobId={response?.JobInfo?.Id}
                                taskList={taskList}
                                taskPositionList={referenceList.lenght > 0 ? referenceList : taskPositionList}
                                sendDataToWizard={getDataFromContent}
                                styleCondition={styleCondition} />
                            :<>
                            <div className="my-1">
                               <Headerbar hasBorder={true} shakingKey={["taskTitle" ,"assignType" ]} headerContents={{ taskTitle:response.Tasks[0].TaskTitle, "assignType" :trans(response.Tasks[0].AssignType) }} />
                            </div>
                            {(response.Tasks[0].AssignType&&response.Tasks[0].Positions > 1)=="SELF_SERVICE"&&
                               <Message message={"Help_SelfServiceTaskMustBeReferToAll"} type={"instruction"} />
                            }
                             <ReferenceForm
                                   taskMode={!flag}
                                   PositionTitle={response.Tasks[0].Positions[0]?.Description}
                                   taskTitle={response.Tasks[0]?.TaskTitle}
                                   assignType={response.Tasks[0]?.AssignType}
                                   jobId={response.JobInfo.Id}
                                   TAS_UID={taskList[page].TaskId}
                                   Id={""}
                                   PerformerPositionId={response.Tasks[0].Positions[0].Id}
                                   ParentReferenceId={ParentReferenceId}
                                   //description={selectedRow?.Description}
                                   ReferrerPositionId={layoutContext.currentUserPosition.PositionId}
                                   handleSubmitedData={handleSubmitedData}
                                   handleSubmitedDataForAllForms={()=>{}}
                                   allFormData={referenceList.lenght > 0 ? referenceList : taskPositionList}
                                   setShowForm={()=>{}}
                                   showForm={true}
                                   isAllForms={false}
                                />
                            </>
                        }

                    </>
                    :
                    <Message message={message} type={messageType} />
                ) :
                (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Loading />
                </div>)
            }
        </>
    )
}