import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "components/Address/Address";
import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Headerbar } from "components/Headerbar/Headerbar";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Position } from "components/Position/Position";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { useEffect, useRef, useState } from "react";
import {
  ContactType,
  CustomerDictionaryShowMethod,
  MessageType,
} from "types/enum";
import { GUID_EMPTY, ICON_TYPE, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { convertAddress, GroupingPriceFunction, trans } from "utils/helpers";
import { CustomerTabform } from "../BaseDefinitionAndSetting/Customers/CustomerTabForm";
import { CurrencyDictionary } from "../PriceList/components/CurrencyDictionary/CurrencyDictionary";
import { PriceListDictionary } from "../PriceList/components/PriceListDictionary/PriceListDictionary";
import { AddressBox } from "../SalesInvoice/components/AddressBox/AddressBox";
import { CustomerDictionary } from "../SalesInvoice/components/CustomerDictionary/CustomerDictionary";
import { CustomerPhoneDictionary } from "../SalesInvoice/components/CustomerPhoneDictionary/CustomerPhoneDictionary";
import { CustomerPhoneList } from "../SalesInvoice/components/CustomerPhones/CustomerPhoneList";
import { WarehouseForDetail } from "../components/WarehouseForDetail";
import { SalesPreInvoiceDetailForm } from "./SalesPreInvoiceDetail";
import {
  CalculateSalesPreInvoice,
  getDefaultPersonContact,
  getPersonCustomer,
  getPreInvoiceById,
  getPreInvoiceByReferenceId,
  getPreInvoiceConstraint,
  getSalesPreInvoiceOrGenerate,
  optionalDiscountHandler,
  postSalesPreInvoice,
  postSalesPreInvoiceWorkFlow,
  putSalesPreInvoice,
  putSalesPreInvoiceWorkflow,
} from "./api";
import { SalesPreInvoiceSourceId } from "./components/SalesPreInvoiceSourceId";
import { Person } from "components/Person/Person";

interface SalesPreInvoiceFormProps {
  onHide?: (usenavigate?) => void;
  relod: () => void;
  jobId?: any;
  processId?: any;
  taskId?: any;
  SourceData?: any;
  sourceDataParam?: any;
  sourceId?: any;
  referenceId?;
  step?: any;
  JobNo?: any;
  useGenerate?: any;
  rowObject?: any;
  mode: any;
}

export const SalesPreInvoiceForm = ({
  mode,
  relod,
  onHide,
  rowObject,
  step,
  referenceId,
  taskId,
  jobId,
  JobNo,
  useGenerate = true,
  processId,
  sourceDataParam,
  SourceData,
  sourceId,
}: SalesPreInvoiceFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [haveSource, setHaveSource] = useState(
    SourceData ? SourceData : sourceDataParam
  );
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [userChanges, setUserChanges] = useState({});
  const [listPosition, setListPosition] = useState<any>({});
  const [customerInfo, setCustomerInfo] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [salesPreInvoiceDate, setSalesPreInvoiceDate] = useState<any>();
  const [selectedObj, setSelectedObj] = useState<any>(null);
  const [showForm, setShowForm] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState<
    "createMode" | "selectMode" | null
  >(null);
  const [showCustomerPhoneForm, setShowCustomerPhoneForm] = useState(false);
  const [showCustomerform, setShowCustomerform] = useState<boolean>(false);
  const [priceList, setPriceList] = useState<any>(null);
  const [salesPreInvoiceDetail, setSalesPreInvoiceDetail] = useState<any>([]);
  const [dataGridLoading, setDataGridLoading] = useState<any>(true);
  const [lastWarehouse, setLastWarehouse] = useState<any>(null);
  const [PreInvoiceConstraint, setPreInvoiceConstraint] = useState<any>({});
  const [docsClassification, setDocsClassification] = useState<any>(null);
  const [totalRows, setTotalRows] = useState<any>({
    ItemCount: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    SubTotalPrice: 0,
  });
  const [payment, setPayment] = useState<any>({
    totalprice: 0,
    Addition: 0,
    Deduction: 0,
    Prepayment: 0,
  });
  const [receive, setReceive] = useState<any>({
    GrandTotalPrice: 0,
    Prepayment: 0,
  });
  const [todayDate, settodayDate] = useState<string | null>(
    new Date().toISOString()
  );
  const [dateLoading, setDateLoading] = useState(true); //for calc fun is runing
  const [optionalDiscount, setOptionalDiscount] = useState<any>(null);
  const [detailMode, setDetailMode] = useState<"create" | "edit" | "view">(
    mode
  );
  const [dataChange, setDataChange] = useState<any>(false);
  const [increased, setIncreased] = useState<any>(false);
  const [showWarehouseForDetail, setShowWarehouseForDetail] =
    useState<any>(false);
  const [warehouseData, setWarehouseData] = useState<any>(lastWarehouse);
  const [noAccess, setNoAccess] = useState<any>(false);

  const [salespreinvoiceno, setSalespreinvoiceno] = useState<any>();
  const [salesPreInvoiceStatusId, setSalesPreInvoiceStatusId] = useState<any>();
  const [person, setPerson] = useState<any>(null);

  const [changeAddress, setChangeAddress] = useState<any>();
  const [typePhoneContact, setTypePhonContact] = useState<any>();
  const PreInvoiceRef = useRef<any>(null);
  const [ExpireDate, setExpireDate] = useState<any>();

  const [currencyInfo, setCurrencyInfo] = useState<any>();
  const [selectedPhoneDetail, setSelectedPhoneDetail] = useState<any>({
    phone: "",
    mobilePhone: "",
  });
  const [requiredFields, setRequiredFields] = useState<any>();

  function setSalesPreInvoiceDetailHandler(data) {
    setDataGridLoading(false);
    setSalesPreInvoiceDetail(data);
    setDataGridLoading(true);
  }

  const handleSalesPreInvoiceDetailFormSubmit = async (event) => {
    event.preventDefault();
    let SubTotalPrice: any = 0;
    let newsalesPreInvoiceDetail = JSON.parse(
      JSON.stringify(salesPreInvoiceDetail)
    )?.map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      if (response?.Id == GUID_EMPTY) {
        delete e.Id;
      } else {
      }
      return e;
    });

    let data: any = {
      RecipientPersonDescription: 
         person?.Name??
         null,
      RecipientPersonId:  person?.Id
        ?? null,
      CurrencyId: event.target.CurrencyId.value,
      SalesPreInvoiceNo: event.target.SalesPreInvoiceNo.value,
      SalesPreInvoiceDate: salesPreInvoiceDate,
      ExpireDate: ExpireDate,
      PriceListId: event.target.PriceList?.value,
      PaymentMethodId: event.target.PaymentMethodId?.value,
      CustomerId: customerInfo?.Id,
      CustomerName: customer.Name,
      CustomerPhone: customer.SundryCustomer
        ? event.target?.CustomerPhone?.value
        : customer?.CustomerPhone?.Name.split(":")[1]
        ? customer?.CustomerPhone?.Name.split(":")[1]
        : customer?.CustomerPhone?.Name ?? null,
      CustomerMobilePhone: customer.SundryCustomer
        ? event.target?.CustomerMobilePhone?.value
        : customer?.CustomerMobilePhone?.Name.split(":")[1]
        ? customer?.CustomerMobilePhone?.Name.split(":")[1]
        : customer?.CustomerMobilePhone?.Name ?? null,
      CustomerAddress: event.target.Address?.value ?? null,
      CustomerEconNo: event.target.CustomerEconNo?.value,
      CustomerNationalCode: event.target.CustomerNationalCode?.value,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        event.target.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        event.target.VisitorPositionDescriptionEN?.value,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        event.target.DistributorPositionDescriptionEN?.value,
      DeliveryAddress: event.target.DeliveryAddress?.value,
      IsCurrancyPreInvoice: event.target.IsCurrancyPreInvoice?.checked,
      //   CurrencyPrepayment: +event.target.CurrencyPrepayment?.value,
      Description: event.target.Description?.value,
      IsCurrency: false,
      SourceId: sourceId,
      SourceData: haveSource ? haveSource : response?.SourceData,
      Addition: +event.target.Addition?.value.replaceAll(",", ""),
      Deduction: +event.target.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: PreInvoiceConstraint ? +totalRows?.TotalTax : 0,
      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +event.target.Addition?.value.replaceAll(",", "") -
        +event.target.Deduction?.value.replaceAll(",", ""),
      CreditPayment: +event.target.CreditPayment?.value.replaceAll(",", ""),
      Prepayment: +event.target.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesPreInvoiceDetailDTOList: newsalesPreInvoiceDetail,
    };
    try {
      if (mode == "create") {
        const res = await postSalesPreInvoice(data);
        if (res.MessageType == MessageType.success) {
          onHide && onHide(true);
          relod();
        }
      } else {
        const exteraData = {
          JobId: response.JobId ? response.JobId : jobId,
          JobNo: response.JobNo ? response.JobNo : JobNo,
          TAS_UID: taskId,
        };
        if (jobId) {
          if (response?.Id == GUID_EMPTY) {
            const res = await postSalesPreInvoiceWorkFlow(
              { ...data, ...exteraData },
              referenceId,
              increased
            );
            getPreInvoiceAsync();
          } else {
            const res = await putSalesPreInvoiceWorkflow(
              {
                ...data,
                ...exteraData,
                Id: response?.Id,
                SalesPreInvoiceStatusId: salesPreInvoiceStatusId,
              },
              referenceId
            );
          }
        } else {
          const res = await putSalesPreInvoice({
            ...data,
            ...exteraData,
            Id: response?.Id,
            SalesPreInvoiceStatusId: salesPreInvoiceStatusId,
          });
          onHide && onHide();
          relod();
        }
      }
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    } catch (error: any) {
      if (error.response.data.MessageType == MessageType.confirm) {
        Toast(trans("میخواهید تغییر دهید؟"), "warning", "alert", () => {
          setResponse(error.response.data.Data);

          setSalesPreInvoiceDetail(
            error.response.data.Data?.SalesPreInvoiceDetailDTOList
          );
          let newItemCount = 0;
          error.response.data.Data?.SalesPreInvoiceDetailDTOList.map((item) => {
            newItemCount += item.Qty1;
          });
          setTotalRows({
            ItemCount: newItemCount,
            TotalTax: PreInvoiceConstraint.HasTax
              ? error.response.data.Data?.TotalTax
              : 0,
            TotalDiscount: error.response.data.Data?.TotalDiscount,
            SubTotalPrice: error.response.data.Data?.SubTotalPrice,
          });
          setPayment({
            totalprice: error.response.data.Data?.TotalPrice,
            Addition: error.response.data.Data?.Addition,
            Deduction: error.response.data.Data?.Deduction,
            Prepayment: error.response.data.Data?.Prepayment,
          });
          setReceive({
            GrandTotalPrice: error.response.data.Data?.GrandTotalPrice,
            Prepayment: error.response.data.Data?.Prepayment,
          });
        });
      }
    }
  };
  const sendDataForCalcAsync = async (newPriceList) => {
    setDateLoading(false);

    let SubTotalPrice: any = 0;

    let newsalesPreInvoiceDetail = JSON.parse(
      JSON.stringify(salesPreInvoiceDetail)
    ).map((e: any) => {
      SubTotalPrice = +e.TotalPrice + SubTotalPrice;
      //delete e.Id;
      return e;
    });

    let data: any = {
      CurrencyId: newPriceList?.CurrencyId,
      SalesPreInvoiceNo: PreInvoiceRef.current.SalesPreInvoiceNo?.value,
      SalesPreInvoiceDate: salesPreInvoiceDate,
      ExpireDate: ExpireDate,
      PriceListId: newPriceList
        ? newPriceList.Id
        : salesPreInvoiceDate.current?.PriceList?.value,
      PaymentMethodId: PreInvoiceRef.current.PaymentMethodId?.value,
      CustomerId: customerInfo?.Id,
      CustomerName: customer?.Name,
      CustomerPhone: customer?.SundryCustomer
        ? PreInvoiceRef.current?.CustomerPhone?.value
        : customer?.CustomerPhone?.Name.split(":")[1]
        ? customer?.CustomerPhone?.Name.split(":")[1]
        : customer?.CustomerPhone?.Name ?? null,
      CustomerMobilePhone: customer?.SundryCustomer
        ? PreInvoiceRef.current?.CustomerMobilePhone?.value
        : customer?.CustomerMobilePhone?.Name.split(":")[1]
        ? customer?.CustomerMobilePhone?.Name.split(":")[1]
        : customer?.CustomerMobilePhone?.Name ?? null,
      CustomerAddress: PreInvoiceRef.current.Address?.value ?? null,
      CustomerEconNo: PreInvoiceRef.current.CustomerEconNo?.value,
      CustomerNationalCode: PreInvoiceRef.current.CustomerNationalCode?.value,
      RecipientPersonDescription:  person?.Name
        ?? null,
      RecipientPersonId:  person?.Id
        ?? null,
      SalesManagerPositionId: listPosition?.SalesManagerPosition?.Id,
      SalesManagerPositionDescription: listPosition?.SalesManagerPosition?.Name,
      SalesManagerPositionDescriptionEN:
        PreInvoiceRef.current.SalesManagerPositionDescriptionEN?.value,
      VisitorPositionId: listPosition?.VisitorPosition?.Id,
      VisitorPositionDescription: listPosition?.VisitorPosition?.Name,
      VisitorPositionDescriptionEN:
        PreInvoiceRef.current.VisitorPositionDescriptionEN?.value,
      DistributorPositionDescription: listPosition?.DistributorPosition?.Name,
      DistributorPositionDescriptionEN:
        PreInvoiceRef.current.DistributorPositionDescriptionEN?.value,
      DeliveryAddress: PreInvoiceRef.current.DeliveryAddress?.value,
      IsCurrancyPreInvoice: PreInvoiceRef.current.IsCurrancyPreInvoice?.checked,
      //  CurrencyPrepayment: +PreInvoiceRef.current.CurrencyPrepayment?.value,
      Description: PreInvoiceRef.current.Description?.value,
      IsCurrency: false,
      SourceId: sourceId,
      SourceData: haveSource ? haveSource : response?.SourceData,
      Addition: +PreInvoiceRef.current.Addition?.value.replaceAll(",", ""),
      Deduction: +PreInvoiceRef.current.Deduction?.value.replaceAll(",", ""),
      TotalDiscount: +totalRows?.TotalDiscount,
      TotalPrice: +payment.totalprice,
      TotalTax: PreInvoiceConstraint ? +totalRows?.TotalTax : 0,
      GrandTotalPrice:
        +totalRows?.SubTotalPrice -
        +totalRows?.TotalDiscount +
        +totalRows?.TotalTax +
        +PreInvoiceRef.current.Addition?.value.replaceAll(",", "") -
        +PreInvoiceRef.current.Deduction?.value.replaceAll(",", ""),
      CreditPayment: +PreInvoiceRef.current.CreditPayment?.value.replaceAll(
        ",",
        ""
      ),
      Prepayment: +PreInvoiceRef.current.Prepayment?.value.replaceAll(",", ""),
      SubTotalPrice: +totalRows?.SubTotalPrice,
      SalesPreInvoiceDetailDTOList: newsalesPreInvoiceDetail,
      JobId: response.JobId ? response.JobId : jobId,
      JobNo: response.JobNo ? response.JobNo : JobNo,
      TAS_UID: taskId,
      Id: response.Id,
    };
    try {
      if ((mode !== "view" || jobId) && priceList.Id != newPriceList.Id) {
        const res = await CalculateSalesPreInvoice(data);
        setResponse(res.Data);

        setSalesPreInvoiceDetail(res.Data?.SalesPreInvoiceDetailDTOList);
        let newItemCount = 0;
        res.Data?.SalesPreInvoiceDetailDTOList.map((item) => {
          newItemCount += item.Qty1;
        });
        setTotalRows({
          ItemCount: newItemCount,
          TotalTax: PreInvoiceConstraint.HasTax ? res.Data?.TotalTax : 0,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });
        setPayment({
          totalprice: res.Data?.totalprice,
          Addition: res.Data?.Addition,
          Deduction: res.Data?.Deduction,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
        });
      }
    } catch (error) {}
    setPriceList(newPriceList);
    setDateLoading(true);
  };
  async function getDefaultPersonContactAsync(personId) {
    try {
      const res2 = await getPersonCustomer(personId);

      setCustomer((pre) => {
        return {
          ...pre,
          Id: res2.Data.Customer.Id,
          Name: res2.Data.Customer.CustomerPersonDescription,
          SundryCustomer:
            res2.Data.Customer.Id == PreInvoiceConstraint?.SundryCustomerId,
        };
      });
      if (res2.Data.Customer.Id == PreInvoiceConstraint?.SundryCustomerId) {
        setCustomerInfo({ ...res2?.Data?.Customer });
      } else {
        if (mode != "view") {
          const res = await getDefaultPersonContact(
            res2?.Data?.Customer?.CustomerPersonId
          );
          setCustomerInfo({ ...res.Data, ...res2?.Data?.Customer });
        } else setCustomerInfo({ ...res2?.Data?.Customer });
      }

      setListPosition({
        VisitorPosition: {
          Id: res2?.Data?.Customer.VisitorPositionId,
          Name: res2?.Data?.Customer.VisitorPositionDescription,
        },
      });
    } catch (error) {}
  }
  async function getPreInvoiceConstraintAsync(withLoading = false) {
    if (withLoading) setLoading(false);

    try {
      const res = await getPreInvoiceConstraint();
      setSalespreinvoiceno(res.Data?.DocNo);
      setPreInvoiceConstraint(res.Data);
      setRequiredFields(JSON.parse(res.Data.RequiredFields));
      if (withLoading) setLoading(true);
      return res.Data;
    } catch (error) {}
    if (withLoading) setLoading(true);
  }
  function addWarehouse(e) {
    e.stopPropagation();
    e.preventDefault();
    let newList: any = [];

    salesPreInvoiceDetail.map((item) => {
      newList.push({
        ...item,
        WarehouseId: warehouseData?.WarehouseId,
        WarehouseDetailId: warehouseData?.WarehouseDetailId,
        Warehouse: warehouseData.WarehouseTitle,
        WarehouseDetail: warehouseData?.WarehouseDetail,
      });
    });
    setSalesPreInvoiceDetail(newList);
  }

  async function getPreInvoiceAsync() {
    setLoading(false);
    const constraint = await getPreInvoiceConstraintAsync();
    if (jobId) {
      let res: any;
      if (useGenerate && !(!sourceDataParam && mode == "view")) {
        res = await getSalesPreInvoiceOrGenerate(
          sourceDataParam,
          sourceId,
          referenceId,
          jobId
        );
        setResponse(res.Data);
      } else {
        if (referenceId) {
          res = await getPreInvoiceByReferenceId(referenceId);
          //res = await getPreInvoiceById(jobId);
        } else {
          res = await getPreInvoiceById(jobId);
        }
        setResponse(res.Data);
      }

      if (res.Data != null) {
        let count = 0;
        res.Data.SalesPreInvoiceDetailDTOList.map((item) => {
          count += +item?.Qty1;
        });
        setHaveSource(res.Data.SourceData);
        setSalesPreInvoiceDetail(res.Data.SalesPreInvoiceDetailDTOList);
        setTotalRows({
          ItemCount: count,
          TotalTax: constraint?.HasTax ? res.Data?.TotalTax : 0,
          TotalDiscount: res.Data?.TotalDiscount,
          SubTotalPrice: res.Data?.SubTotalPrice,
        });
        setPayment({
          totalprice: res.Data?.totalprice,
          Addition: res.Data?.Addition,
          Deduction: res.Data?.Deduction,
          Prepayment: res.Data?.Prepayment,
        });
        setReceive({
          GrandTotalPrice: res.Data?.GrandTotalPrice,
          Prepayment: res.Data?.Prepayment,
        });
      }
    } else {
      const res = await getPreInvoiceById(rowObject.Id);
      setHaveSource(res.Data.SourceData);

      setResponse(res.Data);
      let count = 0;
      res.Data.SalesPreInvoiceDetailDTOList.map((item) => {
        count += +item?.Qty1;
      });
      setSalesPreInvoiceDetail(res.Data.SalesPreInvoiceDetailDTOList);
      setTotalRows({
        ItemCount: count,
        TotalTax: constraint.HasTax ? res.Data?.TotalTax : 0,
        TotalDiscount: res.Data?.TotalDiscount,
        SubTotalPrice: res.Data?.SubTotalPrice,
      });
      setPayment({
        totalprice: res.Data?.totalprice,
        Addition: res.Data?.Addition,
        Deduction: res.Data?.Deduction,
        Prepayment: res.Data?.Prepayment,
      });
      setReceive({
        GrandTotalPrice: res.Data?.GrandTotalPrice,
        Prepayment: res.Data?.Prepayment,
      });
      if (mode == "view") {
        setPreInvoiceConstraint({
          ...PreInvoiceConstraint,
          DetailDTO: res.Data.SalesPreInvoiceDetailDTOList[0],
        });
      }
    }

    try {
    } catch (error) {}
    setLoading(true);
  }
  async function optionalDiscountHandlerAsync() {
    let data = {
      PriceRoundingPlace: currencyInfo?.PriceRoundingPlace,
      OptionalDiscount: +optionalDiscount,
      Addition: +payment.Addition,
      Deduction: +payment.Deduction,
      DetailDTOList: salesPreInvoiceDetail,
    };

    try {
      const res = await optionalDiscountHandler(data);
      setTotalRows({
        ItemCount: res.Data.ItemCount,
        TotalTax: PreInvoiceConstraint.HasTax ? res.Data.TotalTax : 0,
        TotalDiscount: res.Data.TotalDiscount,
        SubTotalPrice: res.Data.SubTotalPrice,
      });
      setSalesPreInvoiceDetailHandler(res.Data.SalesPreInvoiceDetailDTOList);
    } catch (error) {}
  }

  const handlesetPreInvoiceDetail = (newObj) => {
    if (detailMode == "create") setIncreased(true);
    if (PreInvoiceConstraint.AllowDuplicateItems || detailMode == "edit") {
      setTotalRows({
        ItemCount:
          detailMode == "create"
            ? totalRows.ItemCount + +newObj.Qty1
            : totalRows.ItemCount + (+newObj.Qty1 - +selectedObj.Qty1),
        TotalTax:
          detailMode == "create"
            ? totalRows.TotalTax + +newObj.Tax
            : totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
        TotalDiscount:
          detailMode == "create"
            ? totalRows.TotalDiscount + +newObj.Discount
            : totalRows.TotalDiscount +
              (+newObj.Discount - +selectedObj.Discount),
        SubTotalPrice:
          detailMode == "create"
            ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
            : totalRows.SubTotalPrice +
              (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
      });
      if (detailMode == "create") {
        setSalesPreInvoiceDetailHandler([...salesPreInvoiceDetail, newObj]);
      } else {
        let newItemes = salesPreInvoiceDetail?.map((item: any) => {
          if (item.Id == newObj.Id) {
            return newObj;
          } else return item;
        });
        setSalesPreInvoiceDetailHandler(newItemes);
      }
      Toast("msg_saveddatasuccessfully", "success");
      setDataChange(true);
      return true;
    } else {
      let Dupli = salesPreInvoiceDetail?.find(
        (e: any) => e.ItemId == newObj.ItemId
      );
      if (!Dupli || salesPreInvoiceDetail.length == 0) {
        setTotalRows({
          ItemCount:
            detailMode == "create"
              ? totalRows.ItemCount + +newObj.Qty1
              : totalRows.ItemCount + (+newObj.Qty1 - +selectedObj.Qty1),
          TotalTax:
            detailMode == "create"
              ? +totalRows.TotalTax + +newObj.Tax
              : +totalRows.TotalTax + (+newObj.Tax - +selectedObj.Tax),
          TotalDiscount:
            detailMode == "create"
              ? totalRows.TotalDiscount + +newObj.Discount
              : totalRows.TotalDiscount +
                (+newObj.Discount - +selectedObj.Discount),
          SubTotalPrice:
            detailMode == "create"
              ? totalRows.SubTotalPrice + +newObj.SubTotalPrice
              : totalRows.SubTotalPrice +
                (+newObj.SubTotalPrice - +selectedObj.SubTotalPrice),
        });
        if (detailMode == "create") {
          setSalesPreInvoiceDetailHandler([...salesPreInvoiceDetail, newObj]);
        } else {
          let newItemes = salesPreInvoiceDetail?.map((item: any) => {
            if (item.Id == newObj.Id) {
              return newObj;
            } else return item;
          });
          setSalesPreInvoiceDetailHandler(newItemes);
        }
        Toast("msg_saveddatasuccessfully", "success");
        setDataChange(true);
        return true;
      } else {
        Toast("msg_duplicateitems", "error");
        return false;
      }
    }
  };
  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide && onHide();
      });
    } else {
      onHide && onHide();
    }
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleDeleteRow = async (id: string = selectedObj.Id) => {
    let newPriceListDetail: any = [...salesPreInvoiceDetail];
    let obj = newPriceListDetail.filter((e) => e.Id == id)[0];

    setTotalRows({
      ItemCount: totalRows.ItemCount - +obj.Qty1,
      TotalTax: totalRows.TotalTax - +obj.Tax,
      TotalDiscount: totalRows.TotalDiscount - +obj.Discount,
      SubTotalPrice: totalRows.SubTotalPrice - +obj.SubTotalPrice,
    });
    setSalesPreInvoiceDetailHandler(
      newPriceListDetail.filter((e) => e.Id != id)
    );
  };
  const handleEditDetails = (e) => {
    if (
      salesPreInvoiceDetail.length > 0 &&
      (!salesPreInvoiceDetail?.[0]?.WarehouseId ||
        salesPreInvoiceDetail[0].WarehouseId == GUID_EMPTY)
    ) {
      Toast("Msg_ChooseWarehouse", "error");
    } else {
      if (priceList == null || !priceList || priceList.Id == GUID_EMPTY) {
        Toast(trans("Msg_ChoosePriceList"), "error");
      } else {
        setSelectedObj(e);
        setDetailMode("edit");
        setShowForm(true);
      }
    }
  };

  const handleSourceData = (obj, type) => {
    setResponse(obj);
    setHaveSource(type);
    setPriceList({ ...priceList, Id: obj.PriceListId });
    setSalesPreInvoiceDetailHandler(obj.SalesPreInvoiceDetailDTOList);
    setTotalRows({
      ItemCount: obj?.ItemCount,
      TotalTax: obj?.TotalTax,
      TotalDiscount: obj?.TotalDiscount,
      SubTotalPrice: obj?.SubTotalPrice,
    });
    setCustomer((prev) => {
      return { ...prev, Id: obj?.CustomerId, Name: obj?.CustomerName };
    });
  };

  const handleOpenDetailForm = () => {
    if (
      priceList.Id != GUID_EMPTY &&
      (customerInfo?.CustomerPersonId || customer?.SundryCustomer)
    ) {
      if (
        salesPreInvoiceDetail.length > 0 &&
        (!salesPreInvoiceDetail?.[0]?.WarehouseId ||
          salesPreInvoiceDetail[0].WarehouseId == GUID_EMPTY)
      ) {
        Toast("Msg_ChooseWarehouse", "error");
      } else {
        setShowForm(true);
        setDetailMode("create");
      }
    } else {
      if (customer?.Id) {
        Toast("Msg_customer", "error");
      } else {
        Toast("msg_choosepricelist", "error");
      }
    }
  };

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (mode != "create") {
      getPreInvoiceAsync();
    } else {
      getPreInvoiceConstraintAsync(true);
    }
  }, []);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);
  useEffect(() => {
    setPayment((prev) => {
      return {
        ...prev,
        totalprice: +totalRows?.SubTotalPrice - +totalRows?.TotalDiscount,
      };
    });
  }, [totalRows]);
  useEffect(() => {
    setReceive((prev) => {
      return {
        ...prev,
        GrandTotalPrice:
          +payment.totalprice +
          +payment?.Addition +
          +totalRows?.TotalTax -
          +payment?.Deduction,
      };
    });
  }, [payment]);

  return (
    <div className=" ">
      {showForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowForm(false);
          }}
          show={showForm}
          title={"SalesPreInvoiceDetailForm"}
        >
          <SalesPreInvoiceDetailForm
            setLastWarehouse={setLastWarehouse}
            lastWarehouse={lastWarehouse ? lastWarehouse : warehouseData}
            mode={detailMode}
            onHide={() => {
              setShowForm(false);
            }}
            priceList={priceList}
            selectedObj={selectedObj}
            PreInvoiceConstraint={PreInvoiceConstraint}
            SalesPreInvoiceDetailCallBack={(e) => handlesetPreInvoiceDetail(e)}
            personId={customerInfo?.CustomerPersonId}
            SourceData={SourceData}
            currency={currencyInfo}
            salesPreInvoiceId={response.Id}
          />
        </ModalForm>
      ) : (
        <></>
      )}
      {showWarehouseForDetail && (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowWarehouseForDetail(false);
          }}
          show={showWarehouseForDetail}
          title={"Warehouse"}
        >
          <WarehouseForDetail
            Detailes={salesPreInvoiceDetail}
            setDetail={(e) => {
              setSelectedObj(null);
              setSalesPreInvoiceDetail(e);
              setShowWarehouseForDetail(false);
            }}
            selectedObj={selectedObj}
          />
        </ModalForm>
      )}
      {showCustomerPhoneForm ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerPhoneForm(false);
          }}
          show={showCustomerPhoneForm}
          title={"CustomerPhoneForm"}
        >
          <CustomerPhoneList
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowCustomerPhoneForm(false);
            }}
            type={typePhoneContact}
            handlePhone={(e) => {
              typePhoneContact == ContactType.Phone
                ? setSelectedPhoneDetail({ ...selectedPhoneDetail, phone: e })
                : setSelectedPhoneDetail({ ...selectedPhoneDetail, mobile: e });
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showAddressBox ? (
        <ModalForm
          modalHeight={49}
          onHide={() => {
            setShowAddressBox(null);
          }}
          mode={mode}
          show={showAddressBox && true}
          title={"Address"}
        >
          <AddressBox
            personId={
              response?.CustomerPersonId
                ? response?.CustomerPersonId
                : customerInfo?.CustomerPersonId
            }
            onHide={() => {
              setShowAddressBox(null);
            }}
            status={showAddressBox}
            AddressHandeler={(e) => {
              setChangeAddress(JSON.stringify(e).toLowerCase());
              setCustomerInfo({
                ...customerInfo,
                Address: JSON.stringify(e).toLowerCase(),
              });
              setShowAddressBox(null);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {showCustomerform ? (
        <ModalForm
          modalHeight={49}
          mode={mode}
          onHide={() => {
            setShowCustomerform(false);
          }}
          show={showCustomerform}
          title={"Customer"}
        >
          <CustomerTabform
            selectedObj={customer}
            mode={mode}
            onHide={() => {
              setShowCustomerform(false);
            }}
          />
        </ModalForm>
      ) : (
        <></>
      )}

      {loading ? (
        <form
          onSubmit={handleSalesPreInvoiceDetailFormSubmit}
          ref={PreInvoiceRef}
        >
          <FormWithTitle
            title={trans("SalesPreInvoice")}
            defualtClose={true}
            showFristRow={true}
            closeOption={true}
            dynamicTitle={{
              CustomerName: customer?.Name,
              salespreinvoiceno: response?.SalesPreInvoiceNo
                ? response?.SalesPreInvoiceNo
                : salespreinvoiceno,
            }}
          >
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-lg-1 col-11 ms-lg-1">
                  <SalesPreInvoiceSourceId
                    SourceObjCallBack={(e, type) => {
                      handleSourceData(e, type);
                    }}
                    value={haveSource ? haveSource : "undefined"}
                    resetForm={resetForm}
                    dataChange={dataChange}
                    mode={mode == "view" || jobId ? "view" : mode}
                    setHaveSource={setHaveSource}
                  />
                </div>
                {(!PreInvoiceConstraint.IsClassificationIgnored ||
                  docsClassification) && (
                  <div className="col-lg-1 col-11">
                    <Textbox
                      textboxType="text"
                      textboxName="SalesPreInvoiceNo"
                      labelText="number"
                      defaultValue={
                        response?.Id != GUID_EMPTY
                          ? response?.SalesPreInvoiceNo
                          : PreInvoiceConstraint?.DocNo
                      }
                      resetForm={resetForm}
                      readonly={
                        !PreInvoiceConstraint?.IsDocNoEditable ||
                        mode !== "create"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setSalespreinvoiceno(e.target.value);
                      }}
                      mode={mode}
                    />
                  </div>
                )}
                {dateLoading && (
                  <>
                    {" "}
                    <div className="col-lg-1 col-11">
                      <Datepicker
                        mode={
                          mode == "view" ||
                          PreInvoiceConstraint?.IsNewDocCurrentDate
                            ? "view"
                            : mode
                        }
                        lable="Date"
                        required={true}
                        setIsoTime={(isoTime: string) => {
                          setSalesPreInvoiceDate(isoTime);
                        }}
                        val={
                          response?.SalesPreInvoiceDate &&
                          response?.SalesPreInvoiceDate != "0001/01/01" &&
                          mode != "create"
                            ? response?.SalesPreInvoiceDate
                            : PreInvoiceConstraint?.IsNewDocCurrentDate
                            ? todayDate
                            : null
                        } //resetForm ? new Date().toISOString() :
                        errorFlag={false}
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-11">
                      <Datepicker
                        mode={mode}
                        lable="ExpireDate"
                        setIsoTime={(isoTime: string) => {
                          setExpireDate(isoTime);
                        }}
                        val={mode != "create" ? selectedObj?.ExpireDate : null} //resetForm ? new Date().toISOString() :
                        errorFlag={false}
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        resetForm={resetForm}
                      />
                    </div>
                  </>
                )}

                <div className="col-lg-2 col-11 d-flex">
                  <div className="flex-grow-1">
                    <CustomerDictionary
                      checkHasAccess={() => setNoAccess(true)}
                      name="CustomerName"
                      label="CustomerName"
                      resetForm={resetForm}
                      value={haveSource ? response?.CustomerId : ""}
                      ShowMethod={PreInvoiceConstraint?.ChangeDocCustomer}
                      CustomerName={response?.CustomerName}
                      mode={
                        haveSource &&
                        PreInvoiceConstraint?.CustomerDictionaryShowMethod ==
                          CustomerDictionaryShowMethod.OnlyCustomer
                          ? "view"
                          : mode
                      }
                      returnObj={true}
                      required={true}
                      onChange={(e) => {
                        if (e.Id) {
                          setCustomer((prev) => {
                            return { ...e, ...prev };
                          });
                          getDefaultPersonContactAsync(e.Id);
                        }
                      }}
                    />
                  </div>
                  {mode == "create" && !noAccess && (
                    <div style={{ marginTop: "25px", width: "3%" }}>
                      <Button
                        Icon={true}
                        value={
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCustomerform(true);
                            }}
                            className="checkButton"
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={[ICON_TYPE, "plus"]}
                              size="2x"
                            />
                          </button>
                        }
                        btnType="primary"
                      />
                    </div>
                  )}
                </div>

                <div className="col-lg-2 col-11 me-lg-4">
                  <PriceListDictionary
                    showOption={
                      !(
                        response?.PriceListId == GUID_EMPTY &&
                        salesPreInvoiceDetail.length > 0 &&
                        (!salesPreInvoiceDetail?.[0]?.WarehouseId ||
                          salesPreInvoiceDetail[0].WarehouseId == GUID_EMPTY)
                      )
                    }
                    name="PriceList"
                    label="PriceList"
                    resetForm={resetForm}
                    value={
                      PreInvoiceConstraint?.UseCustomerPriceList &&
                      customerInfo?.PriceListId
                        ? customerInfo?.PriceListId
                        : priceList == null ||
                          !priceList ||
                          priceList.Id == GUID_EMPTY
                        ? response?.PriceListId
                        : priceList.Id
                    }
                    mode={
                      (salesPreInvoiceDetail.length > 0 &&
                        (!salesPreInvoiceDetail?.[0]?.WarehouseId ||
                          salesPreInvoiceDetail[0].WarehouseId ==
                            GUID_EMPTY)) ||
                      (PreInvoiceConstraint?.UseCustomerPriceList &&
                        customerInfo?.PriceListId)
                        ? "view"
                        : mode
                    }
                    withNullOption={response && response?.PriceListId == null}
                    onChange={(e) => {
                      salesPreInvoiceDetail.length > 0 &&
                      e?.Id != GUID_EMPTY &&
                      (response?.PriceListId != e?.Id ||
                        (priceList && e?.Id != priceList?.Id))
                        ? sendDataForCalcAsync(e)
                        : setPriceList(e);
                    }}
                  />
                </div>
                <div className="col-lg-1  col-11">
                  <CurrencyDictionary
                    onChange={(obj, id) => {
                      setCurrencyInfo(obj);
                    }}
                    name="CurrencyId"
                    label="Currency"
                    resetForm={resetForm}
                    value={
                      priceList?.CurrencyId
                        ? priceList?.CurrencyId
                        : response?.CurrencyId
                    }
                    mode={priceList?.CurrencyId ? "view" : mode}
                    required={true}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="text"
                    textboxName="CustomerEconNo"
                    labelText="CustomerEconNo"
                    defaultValue={
                      response?.CustomerEconNo
                        ? response?.CustomerEconNo
                        : customerInfo?.EconNo
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={requiredFields.CustomerEconNo}
                  />
                </div>
                {!customer?.SundryCustomer ? (
                  <div className="col-lg-2 col-11 d-flex">
                    <div className="flex-grow-1">
                      <CustomerPhoneDictionary
                        required={requiredFields.CustomerPhone}
                        type={ContactType.Phone}
                        name="CustomerPhone"
                        label="CustomerPhone"
                        resetForm={resetForm}
                        valueString={
                          response?.CustomerPhone || mode !== "create"
                            ? response?.CustomerPhone
                            : selectedPhoneDetail.phone.Value ??
                              customerInfo?.PhoneNumber
                        }
                        mode={mode}
                        returnObj={true}
                        personId={
                          response?.CustomerPersonId
                            ? response?.CustomerPersonId
                            : customerInfo?.CustomerPersonId
                        }
                        onChange={(e) => {
                          setCustomer((prev) => {
                            return { ...prev, CustomerPhone: e };
                          });
                        }}
                      />
                    </div>
                    {mode !== "view" && (
                      <div
                        style={{ marginTop: "25px", width: "1%" }}
                        className={"ms-lg-4"}
                      >
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowCustomerPhoneForm(true);
                                setTypePhonContact(ContactType.Phone);
                              }}
                              className="checkButton"
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "plus"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      required={requiredFields.CustomerPhone}
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerPhone"
                      labelText="CustomerPhone"
                      defaultValue={
                        response?.CustomerPhone ? response?.CustomerPhone : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                    />
                  </div>
                )}
                {!customer?.SundryCustomer ? (
                  <div className="col-lg-2 col-11 d-flex">
                    <div className="flex-grow-1">
                      <CustomerPhoneDictionary
                        required={requiredFields.CustomerMobilePhone}
                        type={ContactType.Mobile}
                        name="CustomerMobilePhone"
                        label="CustomerMobilePhone"
                        resetForm={resetForm}
                        valueString={
                          response?.CustomerMobilePhone || mode !== "create"
                            ? response?.CustomerMobilePhone
                            : selectedPhoneDetail.mobile?.Value ??
                              customerInfo?.MobilePhone
                        }
                        mode={mode}
                        returnObj={true}
                        personId={
                          response?.CustomerPersonId
                            ? response?.CustomerPersonId
                            : customerInfo?.CustomerPersonId
                        }
                        onChange={(e) => {
                          setCustomer((prev) => {
                            return { ...prev, CustomerMobilePhone: e };
                          });
                        }}
                      />
                    </div>
                    {mode !== "view" && (
                      <div style={{ marginTop: "25px", width: "1%" }}>
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowCustomerPhoneForm(true);
                                setTypePhonContact(ContactType.Mobile);
                              }}
                              className="checkButton"
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "plus"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-11 col-lg-2 d-flex">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxType="text"
                      textboxName="CustomerMobilePhone"
                      labelText="CustomerMobilePhone"
                      defaultValue={
                        response?.CustomerMobilePhone
                          ? response?.CustomerMobilePhone
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={requiredFields.CustomerMobilePhone}
                    />
                  </div>
                )}

                <div className="col-lg-2 col-11 me-lg-4">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="CustomerNationalCode"
                    textboxType="nationalCode"
                    labelText="CustomerNationalCode"
                    defaultValue={
                      response?.CustomerNationalCode
                        ? response?.CustomerNationalCode
                        : customerInfo?.NationalId
                        ? customerInfo?.NationalId
                        : null
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={requiredFields.CustomerNationalCode}
                  />
                </div>

            
                  <div className="col-lg-3 col-11">
                    <Person
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      value={
                        response?.RecipientPersonId
                          ? response?.RecipientPersonId
                          : customerInfo?.RecipientPersonId
                      }
                      resetForm={resetForm}
                      mode={mode}
                      WithSearch={false}
                      setPerson={setPerson}
                      returnObj={true}
                      name={"RecipientPersonDescription"}
                      label="RecipientPerson"
                      required={requiredFields.RecipientPersonId}
                    />
                  </div>
            

                {/* <div className="col-lg-3 col-11">
                                {mode=='edit'   && 
                                    <DictionarySelectBox
                                    Change={(e)=>{
                                        setSalesPreInvoiceStatusId(e.SalesPreInvoiceStatus) }}  
                                    required={true}
                                    onChange={(id,obj)=>{
                                        setSalesPreInvoiceStatusId(obj?.Id)
                                      }} 
                                    mode={"edit"}
                                    baseUrl={INVENTORYANDSALES_URL} 
                                    endPoint={ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.SalesPreInvoiceStatus.dictionary}
                                    label="SalesPreInvoiceStatus"
                                    name="SalesPreInvoiceStatus" 
                                   value={response?.SalesPreInvoiceStatusId}
                                   
                                    />
                                    }
                            </div> */}

                {!customer?.SundryCustomer ? (
                  <div className="col-11 col-lg-5 d-flex ">
                    <div className="flex-grow-1">
                      <Address
                        onChange={(e) => {
                          setShowAddressBox("selectMode");
                          return false;
                        }}
                        withMap={false}
                        hideDetail={true}
                        personContact={
                          changeAddress
                            ? convertAddress(changeAddress)
                            : response?.CustomerAddress
                            ? response?.CustomerAddress
                            : convertAddress(customerInfo?.Address)
                        }
                        mode={mode}
                        required={requiredFields.CustomerAddress}
                      />
                    </div>
                    {mode !== "view" && (
                      <div className="col-auto mt-4 text-end p-0">
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowAddressBox("createMode");
                              }}
                              className="checkButton"
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "plus"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                    {mode !== "view" && (
                      <div className="col-auto mt-4 text-end p-0 ms-3">
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setShowAddressBox("selectMode");
                              }}
                              className="checkButton"
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "search"]}
                                size="2x"
                              />
                            </button>
                          }
                          btnType="primary"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-lg-5 col-11 d-flex ">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Address"
                      textboxType="text"
                      labelText="Address"
                      defaultValue={
                        response?.CustomerAddress
                          ? response?.CustomerAddress
                          : ""
                      }
                      resetForm={resetForm}
                      mode={mode}
                      required={requiredFields.CustomerAddress}
                    />
                  </div>
                )}

                {/*                         <div className="col-lg-2">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxName="Prepayment"
                             textboxType="number"
                             labelText="Prepayment"
                             defaultValue={response?.Prepayment}
                             resetForm={resetForm}
                             mode={mode == 'view'?"view": mode}
                             required={true}
                            />
                        </div> */}
                {/*                         <div className="col-lg-2">
                            <Textbox
                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                            textboxName="VisitorPositionDescriptionEN"
                            textboxType="text"
                            labelText="VisitorPositionDescriptionEN"
                            defaultValue={response?.VisitorPositionDescriptionEN}
                            resetForm={resetForm}
                            mode={mode == 'view'?"view": mode}
                            />
                            </div> */}
                {/*  <div className="col-lg-2">
                                 <Textbox
                                  Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                  textboxName="DistributorPositionDescriptionEN"
                                  textboxType="text"
                                  labelText="DistributorPositionDescriptionEN"
                                  defaultValue={response?.DistributorPositionDescriptionEN}
                                  resetForm={resetForm}
                                  mode={mode == 'view'?"view": mode}
                                 />
                                </div> */}
                {/*                         <div className="col-lg-2">
                            <Textbox
                             Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                             textboxName="SalesManagerPositionDescriptionEN"
                             textboxType="text"
                             labelText="SalesManagerPositionDescriptionEN"
                             defaultValue={response?.SalesManagerPositionDescriptionEN}
                             resetForm={resetForm}
                             mode={mode == 'view'?"view": mode}
                            />
                           </div> */}

                <div className="col-lg-2 col-11 ">
                  <Position
                    name="VisitorPositionId"
                    label="VisitorPosition"
                    resetForm={resetForm}
                    value={
                      customerInfo?.VisitorPositionId
                        ? customerInfo?.VisitorPositionId
                        : response?.VisitorPositionId
                    }
                    mode={mode}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({ ...listPosition, VisitorPosition: e });
                    }}
                  />
                </div>

                <div className="col-lg-2 col-11">
                  <Position
                    name="SalesManagerPositionId"
                    label="salesmanagerpositiondescription"
                    resetForm={resetForm}
                    value={
                      customerInfo?.SalesPreInvoiceStatusId
                        ? customerInfo?.SalesPreInvoiceStatusId
                        : response?.SalesManagerPositionId
                    }
                    mode={mode}
                    returnObj={true}
                    onChange={(e) => {
                      setListPosition({
                        ...listPosition,
                        SalesManagerPosition: e,
                      });
                    }}
                  />
                </div>
                <div className="col-lg-3 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="DeliveryAddress"
                    textboxType="text"
                    labelText="DeliveryAddress"
                    defaultValue={
                      response?.DeliveryAddress ? response.DeliveryAddress : ""
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={requiredFields.DeliveryAddress}
                  />
                </div>
                <div className="col-lg-5 col-11">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxName="Description"
                    textboxType="textarea"
                    labelText="Description"
                    minHeight={1}
                    defaultValue={response?.Description}
                    resetForm={resetForm}
                    mode={mode}
                    maxLength={900}
                    letterCounter={true}
                    required={requiredFields.Description}
                  />
                </div>
              </div>
            </div>
          </FormWithTitle>
          <div className="mt-3">
            <FormWithTitle title={trans("Details")}>
              <div>
                <div className="row justify-content-between align-items-center pe-2">
                  <div className="col-12 col-lg-3 d-flex">
                    <div>
                      {" "}
                      {mode != "view" && (
                        <div
                          onClick={handleOpenDetailForm}
                          className="col-12 col-md-2 mb-1 btn btn-success btn-sm d-flex align-items-center justify-content-between "
                          style={{
                            width: "70px",
                            maxHeight: "30px",
                            marginRight: "10px",
                            minWidth: "70px",
                          }}
                        >
                          <span className=" font-size-md ms-2 ">
                            {trans("add")}
                          </span>
                          <FontAwesomeIcon
                            size="xs"
                            icon={["fas", "plus"]}
                            className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 p-1"
                            color="white"
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {(response?.Id === GUID_EMPTY || !response?.Id) &&
                        haveSource &&
                        mode != "view" &&
                        salesPreInvoiceDetail?.length <=
                          response?.SalesPreInvoiceDetailDTOList?.length && (
                          <div
                            onClick={(e) => {
                              setShowWarehouseForDetail(true);
                            }}
                            className=" mb-1 btn btn-primary btn-sm d-flex align-items-center "
                            style={{
                              width: "100px",
                              maxHeight: "30px",
                              marginRight: "10px",
                            }}
                          >
                            <span className=" font-size-md ms-2 ">
                              {trans("choosewarehouse")}
                            </span>
                            <FontAwesomeIcon
                              size="xs"
                              icon={["fas", "warehouse"]}
                              className="color_text_light d-flex Requests__chatbox-reply-btn mx-1 "
                              color="white"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                  <div
                    className={`${
                      mode != "view" ? "col-11 " : "col-12"
                    } col-lg-9  row`}
                    style={{ justifyContent: "end" }}
                  >
                    <div className="d-flex justify-content-start justify-content-lg-end align-items-center">
                      <>
                        {!!salesPreInvoiceDetail?.length &&
                          ((jobId && mode == "edit") || haveSource) && (
                            <>
                              {/*                                         <div className="col-lg-1">
                                            <WarehouseDictionary
                                              name="Warehouse"
                                              label="Warehouse"
                                              resetForm={resetForm}
                                              value={warehouseData?.WarehouseId ? warehouseData?.WarehouseId : undefined}
                                              onChange={(e,obj)=>{ setWarehouseData({WarehouseId:e , WarehouseTitle:obj?.Name?.Description , WarehouseDetailsCount:obj?.Name?.WarehouseDetailsCount	})} }
                                              mode={mode}
                                              required={true}
                                              />
                                        </div>
                                        {warehouseData?.WarehouseDetailsCount >0 &&
                                        <div className="col-lg-1 mb-2">
                                              <DictionarySelectBox
                                               name="WarehouseDetail"
                                               label="WarehouseDetail"
                                               params={{warehouseId:warehouseData?.WarehouseId }}
                                               onChange={(e,obj)=>{setWarehouseData({...warehouseData ,WarehouseDetailId:e ,WarehouseDetail:obj?.Name })}}
                                               baseUrl={INVENTORYANDSALES_URL}
                                               endPoint={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.dictionary}
                                               value={warehouseData?.WarehouseDetailId}
                                               resetForm={resetForm}
                                               mode={mode}
                                               />
                                           </div>
                                        } */}
                            </>
                          )}
                      </>
                      {mode != "view" && (
                        <div className=" pe-lg-0 d-flex align-items-center justify-content-start mb-1 flex-wrap">
                          <input
                            style={{ width: "30%" }}
                            onChange={(e) => {
                              setOptionalDiscount(
                                e.target.value.replaceAll(",", "")
                              );
                            }}
                            value={
                              optionalDiscount > 0
                                ? GroupingPriceFunction(+optionalDiscount)
                                : null
                            }
                            className="text-box__box form-control col-2 me-2 "
                            placeholder={trans("Discount")}
                            disabled={
                              salesPreInvoiceDetail?.length == 0 ||
                              !priceList?.IsManual
                                ? true
                                : false
                            }
                          />
                          <div
                            onClick={(e) => {
                              if (
                                salesPreInvoiceDetail?.length > 0 &&
                                priceList?.IsManual
                              ) {
                                e.preventDefault();
                                e.stopPropagation();
                                optionalDiscountHandlerAsync();
                              }
                            }}
                            className={`btn   ${
                              salesPreInvoiceDetail?.length == 0 ||
                              !priceList?.IsManual
                                ? "btn-outline-secondary disabled"
                                : "btn-primary"
                            } btn-sm d-flex align-items-center justify-content-between  me-1 `}
                          >
                            <span className=" font-size-md ">
                              {trans("sharediscount")}
                            </span>
                            <FontAwesomeIcon
                              size="xs"
                              icon={[ICON_TYPE, "share"]}
                              className=" d-flex Requests__chatbox-reply-btn mx-1 p-1"
                              style={{
                                color:
                                  salesPreInvoiceDetail?.length == 0 ||
                                  !priceList?.IsManual
                                    ? "gray"
                                    : "white",
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={`${
                          mode != "view" ? "col-7" : "col-12"
                        }  row justify-content-end mb-1`}
                      >
                        <Headerbar
                          withPading={false}
                          headerContents={totalRows}
                          hasBorder={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {dataGridLoading ? (
                  <>
                    {(
                      mode == "view"
                        ? salesPreInvoiceDetail?.length > 0
                        : PreInvoiceConstraint?.DetailDTO
                    ) ? (
                      <>
                        <DataGrid
                          DoubleClick={(e) => {
                            setSelectedObj(e);
                            setShowForm(true);
                            setDetailMode("view");
                          }}
                          useLanguage={true}
                          parentName={"SalesPreInvoiceDetailList"}
                          startIndex={19}
                          theadData={
                            mode !== "view"
                              ? Object.keys(PreInvoiceConstraint?.DetailDTO)
                              : Object.keys(salesPreInvoiceDetail?.[0])
                          }
                          tbodyData={
                            salesPreInvoiceDetail?.length > 0
                              ? salesPreInvoiceDetail
                              : [PreInvoiceConstraint?.DetailDTO]
                          }
                          totalRecords={
                            salesPreInvoiceDetail?.length > 0 ? undefined : 0
                          }
                          scrollHeight={"67vh"}
                          minCellWidth={80}
                          selectedRowObjectCallback={selectedObjCallback}
                          rowSelected={selectedObj}
                          handleEdit={
                            mode !== "view" ? (e) => handleEditDetails(e) : null
                          }
                          handleDelete={
                            mode !== "view"
                              ? (e) => {
                                  mode !== "view" &&
                                    Toast(
                                      trans("msg_deleteconfirm"),
                                      "warning",
                                      "alert",
                                      () => handleDeleteRow(e)
                                    );
                                }
                              : null
                          }
                          handleView={(e) => {
                            if (
                              priceList == null ||
                              !priceList ||
                              priceList.Id == GUID_EMPTY
                            ) {
                              Toast(trans("Msg_ChoosePriceList"), "error");
                            } else {
                              setSelectedObj(e);
                              setDetailMode("view");
                              setShowForm(true);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <Message message={"NoDataForPreview"} type={"info"} />
                    )}
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </FormWithTitle>
          </div>

          <div className="card">
            <div className="card-body ">
              <div className="row">
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="totalprice"
                    textboxType="text"
                    labelText="totalprice"
                    defaultValue={
                      payment.totalprice != 0
                        ? payment.totalprice + ""
                        : response?.TotalPrice
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Addition"
                    textboxType="text"
                    labelText="Addition"
                    defaultValue={
                      payment.Addition != 0
                        ? payment.Addition + ""
                        : response?.Addition
                        ? response?.Addition + ""
                        : payment.Addition
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Addition: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Deduction"
                    textboxType="text"
                    labelText="Deduction"
                    defaultValue={
                      payment.Deduction != 0
                        ? payment.Deduction + ""
                        : response?.Deduction
                        ? response?.Deduction + ""
                        : payment.Deduction
                    }
                    onChange={(e) => {
                      setPayment({
                        ...payment,
                        Deduction: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="GrandTotalPrice"
                    textboxType="text"
                    labelText="GrandTotalPrice"
                    defaultValue={
                      receive?.GrandTotalPrice != 0
                        ? receive?.GrandTotalPrice + ""
                        : response?.GrandTotalPrice
                        ? response?.GrandTotalPrice + ""
                        : ""
                    }
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-2">
                  <DictionarySelectBox
                    label="PaymentMethod"
                    name="PaymentMethodId"
                    baseUrl={INVENTORYANDSALES_URL}
                    endPoint={
                      ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting
                        .PaymentMethod.dictionary
                    }
                    resetForm={resetForm}
                    value={response?.PaymentMethodId}
                    mode={mode}
                  />
                </div>
              </div>
              <div className=" row">
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="Prepayment"
                    textboxType="text"
                    labelText="Prepayment"
                    defaultValue={
                      receive?.Prepayment != 0
                        ? receive?.Prepayment + ""
                        : response?.Prepayment
                        ? response?.Prepayment + ""
                        : receive?.Prepayment
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        Prepayment: e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={mode}
                  />
                </div>
                <div className="col-lg-2">
                  <Textbox
                    useGroupingPrice={true}
                    textboxName="CreditPayment"
                    textboxType="text"
                    labelText="CreditPayment"
                    defaultValue={
                      +receive?.GrandTotalPrice - +receive?.Prepayment + ""
                    }
                    onChange={(e) => {
                      setReceive({
                        ...receive,
                        CreditPayment: +e.target.value.replaceAll(",", ""),
                      });
                    }}
                    resetForm={resetForm}
                    mode={"view"}
                  />
                </div>
                <div className="col-lg-8 d-flex justify-content-end">
                  {mode != "view" ? (
                    <div className="col-lg-12 mt-4  btns_form">
                      <Button
                        outline={false}
                        disabled={
                          salesPreInvoiceDetail?.length == 0 ||
                          !(salesPreInvoiceDetail?.length > 0) ||
                          !salesPreInvoiceDetail?.[0]?.WarehouseId ||
                          salesPreInvoiceDetail[0].WarehouseId == GUID_EMPTY
                        }
                        value="save"
                        btnType="submit"
                      />
                      {!jobId && (
                        <Button
                          btnType="cancel"
                          value="Cancel"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      )}{" "}
                    </div>
                  ) : (
                    <div className="col-lg-12 mt-4 btns_form">
                      {!jobId && (
                        <Button
                          btnType="primary"
                          value="close"
                          onClick={() => {
                            cancel();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </div>
  );
};
