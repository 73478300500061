import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { getDicMethod, getenumApi, getenumMethod } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";

interface enumProp extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: any;
 
  feedType?: any;
  mode?: string;
  required?:boolean 
  IsEnum?:boolean 
  withoutLable?:boolean 
  onChange?: (e) => void,
}
export const EnumsDictionary = ({feedType,  name, value ,mode,withoutLable,IsEnum=true , required ,onChange }: enumProp) => {
  const [optionsenum, setOptionsenum] = useState<any>();
  const [errcode, setErrcode] = useState<any>();
  const getenumAsync = async ()=>{
    if(feedType=="Api"){
      if(IsEnum){
        const res = await getenumApi()
        setOptionsenum(res?.Data)
        if(value) onChange&& onChange(value)
        else
          onChange&& onChange(Object.keys(res?.Data)[0])
        
      }
    }else{
      if(IsEnum){
        
      const res = await getenumMethod()
      setOptionsenum(res?.Data)
      if(value) onChange&& onChange(value)
        else
          onChange&& onChange(Object.keys(res?.Data)[0])}else{
        const res = await getDicMethod()
        setOptionsenum(res?.Data)
        if(value) onChange&& onChange(value)
          else
            onChange&& onChange(Object.keys(res?.Data)[0])      }
    }
    try {
    } catch (error:any) {
      setErrcode(error.response.status)
    }
  }
  useEffect(() => {
    getenumAsync()
    return () => {
      setErrcode("")
    };
   
  }, [IsEnum,feedType]);

 return (
    <>
        {optionsenum ?
            <div style={{display:"flex" , }} >
            <div style={{width:"100%"}}>
            <SelectBox
              onChange={(e)=>{onChange&& onChange(e.target.value) }}
              required={required}
              mode={mode}
              lable={!withoutLable ?(IsEnum ? "enumType" :"DictionaryType" ):undefined}
              selectType="select"
              value={value?value:Object.keys(optionsenum)[0]}
              val={Object.keys(optionsenum)}
              options={Object.values(optionsenum)}
              name={name}
            ></SelectBox>
            </div>
          </div>
        :<InputLoad   msg={errcode} required={required}  labelText={!withoutLable ?"enum":undefined}  onClick={getenumAsync}/> 

      }
        <div className="text-box__msg-wrapper">
        </div>
    </>
  );
};
