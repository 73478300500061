import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState} from "react";
import { Card, Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap"
import { trans } from "utils/helpers";
import "./style.scss";
import { ICON_TYPE } from "utils/constants";

interface Prop {
    img: string,
    text: string,
    handleClickSlider: (e) => void,
    handleVersionTrigger: (e) => void,
    handleDownloadTrigger:(e)=>void
    handleCheckbox: (e , item) => void,
    handleDeleteRow?: (e) => void,
    handleEdit?: (e) => void,
    handleSetAsDefault?:(e)=>void
    id: string,
    reset?: boolean,
    haveVersion?: boolean,
    fileName:string,
    asDefault?:any
}

export const CardBoxAttachment = ({haveVersion, img, text,asDefault, handleClickSlider,handleDeleteRow, id, handleDownloadTrigger,handleEdit , handleCheckbox ,handleVersionTrigger, fileName ,reset,handleSetAsDefault}: Prop) => {
    const [target, setTarget] = useState<any>(null);
    const [ShowTrigger, setShowTrigger] = useState(false);
    const [check, setCheck] = useState(false);
    const [CurrentOperation, setCurrentOperation] = useState("");


    function handleTrigger(e) {
        e.stopPropagation()
        setTarget(e.target);
        setCurrentOperation(id);
        if (id == CurrentOperation) {
            setShowTrigger(!ShowTrigger)
        } else {
            setShowTrigger(true)
        }
    }

    const current = useRef().current;

    useEffect(() => {
        setCheck(false)

    }, [reset])




    return (
        <Card className="CardBoxAttachment my-2" ref={current} id={id} >
            <div style={{display:"flex" , justifyContent:"space-between" , flexDirection:"row" , width:"93%" , margin:"0 auto"}}>
               <div className="CardBoxAttachment__checkbox" id={id}>
                <input className="CardBoxAttachment__checkboxInput" name="CardBoxAttachment" checked={check}onChange={(e)=>{}} type="checkbox" id={id} title={fileName} onClick={(e)=>{handleCheckbox(e,{Id:id , Name:fileName}) ; setCheck(!check)}} />
               </div>               
               <div className="CardBoxAttachment__operations">
                <button className="operations__trigger-btn" onFocus={handleTrigger} onBlur={handleTrigger}>
                    <FontAwesomeIcon icon={['fas', "ellipsis-v"]} />
                    <div className={` operations-icons h-auto  ${   ShowTrigger ? "" : " d-none"}`} >
                        <div className="CardBoxAttachment__triggerContainer  ">
                            <div className="CardBoxAttachment__trigger">
                                <div className="operations__operations-icon-wrapper" onClick={()=>{handleDeleteRow && handleDeleteRow(id)} }>
                                     <FontAwesomeIcon icon={[ICON_TYPE, "trash"]} className="text-primary" />
                                     <p>{trans("Delete")}</p>
                                </div>
                                <div id={id} title={fileName} className="operations__operations-icon-wrapper" onClick={(e)=>{e.stopPropagation(); handleDownloadTrigger([{id:id,title:fileName}])}}>
                                    <FontAwesomeIcon   icon={[ICON_TYPE, "download"]} className="text-primary" />
                                    <p >{trans("Download")}</p>
                                </div>
                                {haveVersion&&
                                <>
                                <div id={id} title={fileName} className="operations__operations-icon-wrapper" onClick={(e)=>{e.stopPropagation();handleEdit&& handleEdit([{id:id,title:fileName}])}}>
                                    <FontAwesomeIcon   icon={[ICON_TYPE, "edit"]} className="text-primary" />
                                    <p >{trans("edit")}</p>
                                </div>
                                <div id={id} title={fileName} className="operations__operations-icon-wrapper" onClick={(e)=>{e.stopPropagation();handleVersionTrigger&& handleVersionTrigger({id:id,title:fileName})}}>
                                    <FontAwesomeIcon   icon={[ICON_TYPE, "code-fork"]} className="text-primary" />
                                    <p >{trans("version")}</p>
                                </div></>
                                }
                               {asDefault&&
                                <div id={id} title={fileName} className="operations__operations-icon-wrapper" onClick={(e)=>{e.stopPropagation();handleSetAsDefault&& handleSetAsDefault(id)}}>
                                    <FontAwesomeIcon   icon={[ICON_TYPE, "map-pin"]} className="text-primary" />
                                    <p >{trans("SetAsDefault")}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </button>
               </div>
   

            </div>
            <OverlayTrigger
                key={text}
                placement="top"
                delay={{"show" : 600 , "hide":0}}
                overlay={
                    <Tooltip className="tooltip" id={`button-tooltip-${text}`}>
                        {text}
                    </Tooltip>
                }
            >
            <div className="mt-4">
            <Card.Img className="CardBoxAttachment__img " variant="top" src={img} onClick={handleClickSlider} id={id} title={fileName}/>
            <Card.Body>
                <Card.Text className="CardBoxAttachment__txt">
                    {text}
                </Card.Text>
            </Card.Body>
            </div>
            </OverlayTrigger>  



        </Card>
    )
}