import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DataGrid } from "components/DataGrid/DataGrid";
import DatePicker from "components/DatePicker/DatePicker";
import { Toast } from "components/ToastShow/ToastShow";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { Person } from "components/Person/Person";
import { Textbox } from "components/Textbox/Textbox";
import { Toolbar } from "components/Toolbar/Toolbar";
import {  useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import { AddNewPosition, deletePosition, editPosition, getPosition, getPositionOrganizationList } from "../../api/api";
import { GroupPositionDictionary } from "./GroupPositionDictionarySelect";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";


interface PositionFormPropsType {
    selectedObj;

}
export const PositionForm = ({ 
    selectedObj,

}: PositionFormPropsType) => {
    const [resetForm, setResetForm] = useState(false);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [untilDate, setUntilDate] = useState<string | null>(null);
    const [response , setResponse ] = useState< any>(null);
    const [position , setPosition ] = useState< any>();
    const [selectedPosition , setSelectedPosition ] = useState< any>();
    const [mode , setMode ] = useState< any>();
    const [showPositions , setShowPositions ] = useState< any>(false);
    const [loading , setLoading] = useState(false)
    const [loading2 , setLoading2] = useState(false)
    const [messageType , setMessageType] = useState< any>("info")
    const [orderBy, setOrderBy] = useState<any>([]);
    const [filterList, setFilterList] = useState<any>("");
    const [PageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [search, setSearch] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);
    const [pages,setPages] = useState(1);
    const [userChanges, setUserChanges] = useState({});
    const [groupPositionDictionary, setGroupPositionDictionary] = useState<any>([]);
    const [flag,setFlag] = useState(false);


    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
          case "reference":
            alert("call api reference with Id :");
            break;
          case "view":if(selectedPosition){
            defaultFormData();
            mode != "view" ?setShowPositions(true) : setShowPositions(!showPositions)
            setMode("view") ;
          }  break;
          case "edit":if(selectedPosition){
            defaultFormData();
            mode != "edit" ?setShowPositions(true) : setShowPositions(!showPositions)
            setMode("edit") ;
          }  break;
          case "print":
            alert("print ");
            break;
          case "remove":if(!showPositions){if (selectedPosition)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow);} break;
          case "excel":
            downloadCSV(response?.Data ,3 ,trans("PositionForm"));
            break;
          case "refresh":
            setResetForm(true);
            getPositionorganizationIdAsync(selectedObj.Id);
            break;
          case "create":
            setPosition(null);
            setResetForm(true);
            setLoading2(true);
            mode != "create" ?setShowPositions(true) : setShowPositions(!showPositions);
            setMode( "create");
            
            break;
        }
      };
    




      const getWithOrder = (order: string[]) => {
            setOrderBy(order)
      }
    

      const handleChangePageSize = (e: any) => {
        setPageNum(1);
        setPageSize(+e.currentTarget.value);
        getPageSize("PositionForm" ,+e.currentTarget.value);
        getPositionorganizationIdAsync(selectedObj.Id ,1);
      };
    
      const handleGoPage = (value) => {
        if (value) {
          if (value < 1 || value > pages) {}
          else {setPageNum(+value);getPositionorganizationIdAsync(selectedObj.Id ,value)};
        } else {
     
        }
      };
      const handleNextPage = () => {    

        if (PageNum < pages) {
          setPageNum(PageNum + 1);
          getPositionorganizationIdAsync(selectedObj.Id ,PageNum + 1);
      };
      };
      const handlePrevPage = () => {

        if (PageNum > 1) {
          setPageNum((pageNum) => { return +pageNum - 1 });
          getPositionorganizationIdAsync(selectedObj.Id ,PageNum - 1);
      };
      };
    


      const searchHandler = (str) => {
        setPageNum(1);
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }





      const handleDeleteRow = async() => {
        try {
            const res  = await deletePosition(selectedPosition.Id)
            getPositionorganizationIdAsync(selectedObj.Id)
            setResetForm(true)

        } catch (error) {
            
        }
    }
    function selectedPositionCallback(obj) {
      if(sessionStorage.getItem("changingforms")+""=="true"){
        Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{setSelectedPosition(obj);sessionStorage.setItem("changingforms" , "false"); setUserChanges({}) });
    }else{
      setSelectedPosition(obj)         
    };
    }
      

    async function sendNodeAsync(data) {
        try{
           const res = await AddNewPosition(data);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
           setResetForm(true)
           setShowPositions(false)
           getPositionorganizationIdAsync(selectedObj.Id)
        }catch(err:any){

        }

    }


    async function putNodeAsync(data) {
        try{
           const res = await editPosition(data);
           sessionStorage.setItem("changingforms" , "false");
           setUserChanges({});
           setResetForm(true)
           setShowPositions(false)
           getPositionorganizationIdAsync(selectedObj.Id)
        }catch(err:any){

        }
    }

    async function getPositionorganizationIdAsync(id,pageNum = PageNum) {      
      setLoading(false)
      setResponse(null)
      let pageSizeChangedByUser = getPageSize("PositionForm" )
      setPageSize(pageSizeChangedByUser)
        try{
           const res = await getPositionOrganizationList(id,pageNum, pageSizeChangedByUser , orderBy.join(",") , filterList ,search ,showDeleted);
           setResponse(res.Data)
           setPages(res.Data.TotalRecords > pageSizeChangedByUser ? Math.ceil(res.Data.TotalRecords / pageSizeChangedByUser) : 1)
           setMessageType("info")
          }catch(err:any){
            setMessageType("error")
          }
          setLoading(true)
    }



    const defaultFormData = async ()=>{      
      setLoading2(false)
 
      try{
      const res = await getPosition(selectedPosition.Id); 
      setPosition(res.Data.Position)      
     }catch(err:any){

       }
        setLoading2(true)
    }


    const getNewPosition = async ()=>{
      setLoading2(false)
      try{
      const res = await getPosition(selectedPosition.Id); 
      setPosition(res.Data.Position)   
     }catch(err:any){

       }
        setLoading2(true)
    }
    const selectedShowDeletedFilterCallback = async (e)=>{
        if(e==0){
          setShowDeleted(false)

        }else{
          setShowDeleted(true)
        }
    }



    function cancel() {
      if(sessionStorage.getItem("changingforms")+""=="true"){
          Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
              sessionStorage.setItem("changingforms" , "false");
              setUserChanges({});
              setShowPositions(false)
          });
      }else{
        setShowPositions(false)    
      };


  }

  
  const validationDates = () =>{
    if(fromDate && untilDate && fromDate?.toString().slice(0,10) > untilDate?.toString().slice(0,10)){
        setFlag(true)
    }else{
        setFlag(false)
    }
}


    const handlePersonContactFormSubmit = (event) => {
        event.preventDefault();
        if(flag){
          Toast(trans("InvalidEndDate"),"error");
      }else{
        if(mode=="edit"){
          let data={
            "Id" : selectedPosition.Id,
            "OrganizationId": selectedObj.Id,
            "IsDefault": event.target.checkbox.checked,
            "FromDate":fromDate? fromDate : null   ,
            "UntilDate": untilDate? untilDate : null ,
            "Title": event.target.Title.value ,
            "TitleEN": event.target.TitleEN.value ,
            "PersonId": event.target.PersonId.value,
            "PostLevelId" : event.target.PostLevel.value,
        }


        putNodeAsync(data)
        }else{

        let data={
            "OrganizationId": selectedObj.Id,
            "IsDefault": event.target.checkbox.checked,
            "FromDate":fromDate? fromDate : null   ,
            "UntilDate": untilDate? untilDate : null ,
            "PersonId": event.target.PersonId.value,
            "Title": event.target.Title.value ,
            "TitleEN": event.target.TitleEN.value ,
            "PostLevelId" : event.target.PostLevel.value,
        }
        sendNodeAsync({Position:data ,GroupPositionIds:groupPositionDictionary.map(item=>{return item.Id})})
        }
      }
    }

    
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };


  useEffect(() => {
      if(Object.values(userChanges).filter((item)=>item!=null).length>0){

          sessionStorage.setItem("changingforms" , "true")
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
            };
      }else{
          sessionStorage.setItem("changingforms" , "false")
          window.removeEventListener('beforeunload', handleBeforeUnload);

      }
  }, [userChanges]);

     useEffect(() => {
          if (resetForm) {
            setResetForm(false);
        }
      }, [resetForm]);

    
     useEffect(() => {
      setUntilDate(null)
      setFromDate(null)
      setPosition(null)

      }, [showPositions]);

     useEffect(() => {
        setPageNum(1)
        getPositionorganizationIdAsync(selectedObj.Id,1);
      }, [ selectedObj,search,orderBy,filterList,showDeleted]);

     useEffect(() => {
      if(mode != "create" &&showPositions ){

          getNewPosition()
        
      }
    }, [selectedPosition]);

    useEffect(()=>{
      validationDates()
    },[untilDate,fromDate])

    return (
        <div className="person-contact-form card">
            <div className="m-1">
            <Toolbar 
            handleShowDeletedFilterToolbar={selectedShowDeletedFilterCallback}
            handleSearch={searchHandler} emptyList={response?.TotalRecords == 0} id={selectedPosition+showPositions} create={true} remove={true} edit={true} search={true}   handleClickToolbar={clickToolbarCallback} />
                <div className="p-2">
                    { 
                        showPositions &&
                        <>
                        {loading2 ?
                    <form className="row " onSubmit={handlePersonContactFormSubmit}>
                        <div className="col-lg-6 mt-1">
                        <Person
                              name={"PersonId"}
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              required={true}
                              resetForm={resetForm}                             
                              value={position?.PersonId ? position?.PersonId  : null}
                              mode={mode}
                         />                         

                            <div >
                            <DatePicker
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              mode={mode}
                              resetForm={resetForm}
                              lable="fromDate"
                              setIsoTime={(isoTime: string) => { setFromDate(isoTime) }}
                              val={ position?.FromDate ? position?.FromDate  : null} //resetForm ? new Date().toISOString() : 
                              errorFlag={flag}
                            />
                            </div>
                            
                        <div className="">
                            <DictionarySelectBox 
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                              required={true}
                              label= "PostLevel"
                              name= "PostLevel"
                              endPoint={ENDPOINTS.BaseInformation.postLevel.dictionary}
                              resetForm={resetForm}
                              value={position?.PostLevelId ?position?.PostLevelId : null }
                              mode={mode}                            
                            />
                            </div>
                            
                            {
                              mode=="create"&&
                            <div className="">
                              <GroupPositionDictionary
                              mode={mode}
                              name={"GroupPositionDictionary"}
                              label={"groupPosition"}
                              selectedList={setGroupPositionDictionary}
                              />
                            </div>
                            }
                        </div>
                        <div className="col-lg-6 mt-1">
                          <div className="row">
                            <div className="col">
                             <Textbox
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               required={true}
                               defaultValue={position?.Title ? position?.Title  : selectedObj.Description}
                               textboxType="text"
                               textboxName="Title"
                               labelText="Title"
                               resetForm={resetForm}
                               mode={mode}
                            />
                            </div>
                            <div className="col">

                             <Textbox
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               defaultValue={position?.TitleEN ? position?.TitleEN  : null}
                               textboxType="text"
                               textboxName="TitleEN"
                               fixedLanguage={"en"}
                               labelText="TitleEN"
                               resetForm={resetForm}
                               mode={mode}
                            />
                            </div>

                          </div>
                            <div className="">
                            <DatePicker
                               Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                mode={mode}
                                resetForm={resetForm}
                                lable="untilDate"
                                setIsoTime={(isoTime: string) => { setUntilDate(isoTime) }}
                                val={position?.UntilDate ? position?.UntilDate : null} //resetForm ? new Date().toISOString() : 
                                errorFlag={flag}
                            />                                
                            </div>
                            <div className="mt-4" >
                            <CheckableButton
                              Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                               defaultChecked={mode == "create" ? true :  (position?.IsDefault)}
                               defaultValue={mode == "create" ? true :  (position?.IsDefault)}
                               labelText={"isdefault"}
                               inputName={"checkbox"}
                               type="checkbox"
                               mode={mode}
                            />                                
                        </div>
                        </div>
                        {  mode !=="view" ?
                            <div className="col-lg-12 mt-1 btns_form my-2">
                            <Button value="Save" btnType="submit" />
                            <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                        </div>
                        :
                        <div className="col-lg-12 mt-1 btns_form my-2">
                        <Button btnType="cancel" value="Cancel" onClick={()=>{cancel()}} />
                      </div>
                        }   
                    </form>
                     : <div className="row card"> <Loading/>  </div>  }
                    </>
                    }
                    {loading ?
                          (response?.Data?.length>0 ?
                          <div className="">
                         <DataGrid
                            orderFormat={getWithOrder}
                            orderFormatList={orderBy}
                            handlefiltering={(str)=>{setFilterList(str) ; }}
                            filterStr={filterList}
                            parentName={"PositionForm"}
                            multiSelect={false}
                            theadData={Object.keys(response?.Data[0])}
                            minCellWidth={80}
                            tbodyData={response?.Data}
                            selectedRowObjectCallback={selectedPositionCallback}   
                            startIndex={6}  
                            useLanguage={true} 
                            scrollHeight={"300px"}  
                            totalRecords={response?.TotalRecords}
                            pageNum={PageNum}
                            pageSize={pageSize}
                            HandleNextPage={handleNextPage}
                            HandlePrevPage={handlePrevPage}
                            handlechangePageSize={handleChangePageSize}
                            first={() => {getPositionorganizationIdAsync(selectedObj.Id ,pages);setPageNum(pages)}}
                            end={() => {getPositionorganizationIdAsync(selectedObj.Id,1);setPageNum(1)}}
                            handleGoPage={handleGoPage}              
                          /> </div> 
                        : <Message message={"NoDataForPreview"} type={messageType} />                          
                          )
                      : <Loading/>
                    }
                </div>
            </div>
        </div>
    );
}