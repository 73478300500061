import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";

interface ItemCardsProps {
  setfilterObj: (data: any) => void;
  filterData?: any;
  handleFilter?: any;
  handleRemoveFilter?: any;
  filterObj?: any;
}

export const FilterBox = ({
  setfilterObj,
  filterData,
  handleFilter,
  handleRemoveFilter,
  filterObj,
}: ItemCardsProps) => {
  const [showStep, setShowStep] = useState<any>({ 3: true });

  return (
    <div>
      <div className={`card mb-3 `}>
        <div
          style={{
            backgroundColor: "#f97316",
            color: "#fff",
            paddingRight: "20px",
            justifyContent: "space-between",
          }}
          className="d-flex  py-1 col-12 pr-4"
        >
          <div style={{ whiteSpace: "nowrap" }}>{filterData.title}</div>
          <div
            style={{ marginLeft: "18px" }}
            onClick={() => {
              showStep[1]
                ? setShowStep({ ...showStep, 1: false })
                : setShowStep({ ...showStep, 1: true });
            }}
          >
            {showStep[1] ? (
              <FontAwesomeIcon
                icon={[ICON_TYPE, "angle-down"]}
                size="lg"
                className="icon"
              />
            ) : (
              <FontAwesomeIcon
                icon={[ICON_TYPE, "angle-up"]}
                size="lg"
                className="icon"
              />
            )}
          </div>
        </div>
        <div
          style={{ display: !showStep[1] ? "block" : "none" }}
          className="card-body"
        >
          {
            {
              1: (
                <>
                  <Textbox
                    withLabel={false}
                    useGroupingPrice
                    textboxType="text"
                    textboxName="min"
                    placeholder={"از قیمت"}
                    onChange={(e) => {
                      setfilterObj({ min: e.target.value });
                    }}
                    mode={"edit"}
                    customClass="arrow-none"
                    defaultValue={filterObj?.min}
                    // value={filterObj?.min}
                  />
                  <Textbox
                    withLabel={false}
                    useGroupingPrice
                    textboxType="text"
                    textboxName="max"
                    placeholder={"تا قیمت"}
                    onChange={(e) => {
                      setfilterObj({ max: e.target.value });
                    }}
                    mode={"edit"}
                    customClass="arrow-none"
                    defaultValue={filterObj?.max}
                    // value={filterObj?.max}
                  />
                  <div className=" row gx-1 justify-content-between">
                    <div className="col-12  ps-0 mb-2 ">
                      <button
                        className="btn-customerPrimaryColor w-100  d-block border-0 btn-sm "
                        onClick={handleFilter}
                        style={{ fontSize: "12px" }}
                      >
                        <div className="row">
                          <div className="col-8 p-0">{trans("filter")}</div>
                          <div className="col-4 p-0">
                            <FontAwesomeIcon
                              icon={["fas", "filter"]}
                              className="text-white "
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className="col-12  ps-0">
                      <button
                        className="btn-customerPrimaryColor border-0 btn-sm w-100 d-block "
                        onClick={() => {
                          setfilterObj({ max: null, min: null });
                          handleRemoveFilter();
                        }}
                        style={{ fontSize: "12px" }}
                      >
                        <div className="row">
                          <div className="col-8 p-0">{trans("حذف فیلتر")}</div>
                          <div className="col-4 p-0">
                            <FontAwesomeIcon
                              icon={["fas", "filter"]}
                              className="text-white "
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              ),
              2: (
                <>
                  {filterData?.data?.map((item, index) => (
                    <>
                      <input
                        type="radio"
                        onClick={(e) => {
                          setfilterObj({ option: item });
                        }}
                        id={item + index}
                        name={filterData.title}
                        value={item}
                      />
                      <label className="mx-1" htmlFor={item}>
                        {item}
                      </label>
                      <br></br>
                    </>
                  ))}
                  <input
                    type="radio"
                    onClick={(e) => {
                      setfilterObj({ option: null });
                    }}
                    id="alll"
                    name={filterData.title}
                    value={"همه"}
                  />
                  <label className="mx-1" htmlFor={"alll"}>
                    همه
                  </label>
                  <br></br>
                </>
              ),
              3: (
                <>
                  {filterData?.data?.map((item, index) => (
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        id={item + index}
                        onClick={(e) => {
                          setfilterObj({ option2: item });
                        }}
                        name={filterData.title}
                        value={item}
                      />
                      <label className="mx-1" htmlFor={item}>
                        {item}
                      </label>
                      <br></br>
                    </div>
                  ))}
                </>
              ),
            }[filterData.type]
          }
        </div>
      </div>
    </div>
  );
};
