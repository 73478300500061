
import { DataGrid } from "components/DataGrid/DataGrid";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { deleteReferenceSubject, getListReferenceSubject, getReferenceSubject, postReferenceSubject, putReferenceSubject } from "./api/api";
import { Message } from "components/Message/Message";
import { Loading } from "components/Loading/loading";
import { TypeSettingForm } from "components/TypeSettingForm/TypeSettingForm";
import { Toast } from "components/ToastShow/ToastShow";
import { downloadCSV, trans } from "utils/helpers";




interface Form {
    Title: string,
    Status: number,
    SystemKey?:string
    IsDefault?:boolean
}
export const ReferenceSubjectSetting = ({ }: Form) => {

    const [response, setResponse] = useState<Response | any>(null);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [mode, setMode] = useState("create");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [editAllGrid, setEditAllGrid] = useState(false);
    const [flag, setFlag] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [editForm, setEditForm] = useState<Form | null>();
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState<any>([]);
    const [search, setSearch] = useState(null);
    const [changedDataObj, setChangedDataObj] = useState<any>(null);
    const [showForm, setShowForm] = useState(false);
    const [catchMessage, setCatchMessage] = useState<any>({type:"info",msg:"NoDataForPreview"});




    

    const changeingData = (data) => {
        if(Object.keys(data).length>0){
            setChangedDataObj(data)
        }else{
            setChangedDataObj(null)

        }
      }
    const saveChangeingData = () => {
        if(Object.keys(changedDataObj).length>0){
            let listData:any = []
            
            Object.keys(changedDataObj).map(item=>{
                listData.push(
                    {
                        Id:item,
                        ...changedDataObj[item]
                    }
                )
            })
    
            
        }
      }


    const searchHandler = (str) => {
        if(str =="" ){
            setSearch(null)
        }else{
            setSearch(str)
        }
      }

    const handleCreateAction = () => {
        setMode("create")
        setEditForm(null)
        setShowForm(true)
    }
    
    const handleEditRow = () => {
        setMode("edit");
        setShowForm(true)
    }

    const handleDeleteRow = async() => {
        try {
            await deleteReferenceSubject(selectedObj?.Id)
            setForceUpdate(!forceUpdate)
        } catch (error) {
            
        }
    }
    const handleViewRow = () => {
        setMode("view")
        setShowForm(true)
    }

    const selectedIdCallback = (obj, index) => {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{sessionStorage.setItem("changingforms" , "false");setSelectedObj(obj);setResetForm(true) });
        }else{
            setSelectedObj(obj)         
        };
    }

    const submit = async (e) => {
        e.preventDefault();        
        try {
            if (editForm == null) {
               await postReferenceSubject({ Title: e.target.ReferenceSubject.value ,SystemKey :e.target.systemKey.value ,IsDefault: e.target.checkbox.checked  }).then(() => {sessionStorage.setItem("changingforms" , "false"); getReferenceSubjectAsync(); setResetForm(true) });
            } else {
                // ??? systeam Reserve && Active false
               await putReferenceSubject({ Title: e.target.ReferenceSubject.value ,SystemKey :e.target.systemKey.value ,IsDefault: e.target.checkbox.checked , ActivityStatus: +e.target.ReferenceSubjectSelect.value , Id: selectedObj.Id }).then(() => { sessionStorage.setItem("changingforms" , "false");getReferenceSubjectAsync(); setResetForm(true); setEditForm(null) })
            }
            setShowForm(false)
            
        } catch (error) {
            
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                setEditForm(null);
                setShowForm(false)
            });
        }else{
            setEditForm(null);
            setShowForm(false)     
        };
    }
    async function getReferenceSubjectAsync() {
        setLoading(false)
        try {
            const res = await getListReferenceSubject(search ,orderBy.length == 0 ?null:  orderBy.join(","));
            setResponse(res.Data);
            setCatchMessage({type:"info",msg:"NoDataForPreview"})
        } catch (error:any) {
            setCatchMessage({type:"error" , msg:error?.response?.data?.Message})

        }
        setLoading(true)
    }
    async function getReferenceSubjectt() {
        const data = await getReferenceSubject(selectedObj?.Id);
        setEditForm({ Title: data.Data.Title, Status: data.Data.ActivityStatus ,SystemKey :data.Data.SystemKey ,IsDefault:data.Data.IsDefault});
    }
    useEffect(() => {
        setResponse(null)
        getReferenceSubjectAsync();
    }, [forceUpdate, search , orderBy ,flag ]);

    useEffect(() => {
        if (resetForm) {
            setResetForm(false);
        }
    }, [resetForm]);
    useEffect(() => {
        if (editAllGrid) {
            setEditAllGrid(false);
        }
    }, [editAllGrid]);


    useEffect(() => {
        if(showForm && selectedObj && mode != "create"){
        getReferenceSubjectt()
        }
    }, [selectedObj , showForm,mode])

 

    

    /* #endregion */

    /* #region toolbarActions */
    const clickToolbarCallback = (clickedButtonName: string) => {
        switch (clickedButtonName.toLowerCase()) {
            case "print": alert("print "); break;
            case "excel":downloadCSV(response ,1 ,trans("ReferenceSubjectSetting"));break;
            case "refresh": setForceUpdate(!forceUpdate); break;
            case "create": handleCreateAction(); break;
            case "view": if (selectedObj?.Id) handleViewRow(); break;
            case "edit": if (selectedObj?.Id) handleEditRow(); break;
            case "editgrid": setEditAllGrid(true); break;
            case "remove": if (selectedObj?.Id)Toast(trans("msg_deleteconfirm"), "warning" , "alert" , handleDeleteRow); break;
            case "help": alert("call api help"); break;
            case "save": saveChangeingData(); break;
        }
    };
    /* #endregion */


    return (
        <>

            <div className="cartable-action">
                <Toolbar handleSearch={ searchHandler}  search={changedDataObj!=null?false:true } save={changedDataObj!=null?true:false } emptyList={response == null || response?.length == 0} id={selectedObj?.Id} remove={true} create={true} edit={true}  handleClickToolbar={clickToolbarCallback} />
                <div className="card"> 
                    <div className="">
                        {showForm && 
                        <TypeSettingForm IsDefault={editForm?.IsDefault} SystemKey={true} SystemKeyValue={editForm?.SystemKey} mode={mode} cancel={cancel} handleSubmit={submit} TextboxValue={editForm?.Title} resetForm={resetForm}
                            SelectBoxValue={editForm?.Status} TextBoxName="ReferenceSubject" SelectBoxName="ReferenceSubjectSelect" />
                        }

                        {loading ?
                            response?.length > 0 ?
                                <>
                                    <DataGrid
                                        orderFormat={setOrderBy}
                                        orderFormatList={orderBy}   
                                        parentName={"ReferenceSubjectSetting"}                                 
                                        startIndex={1}
                                        theadData={Object.keys(response[0])}
                                        tbodyData={response}
                                        minCellWidth={80}
                                        scrollHeight={showForm? "25vh": "67vh"}
                                        selectedRowObjectCallback={selectedIdCallback}
                                        handleEdit={handleEditRow}
                                        handleDelete={(e)=>{Toast(trans("msg_deleteconfirm"), "warning" , "alert" ,handleDeleteRow)}}
                                        handleView={handleViewRow}
                                        //rowSelected={selectedObj}
                                    />
                                </>
                                : <Message message={catchMessage.msg} type={catchMessage.type} />


                            : <Loading />
                        }

                    </div>
                </div>
            </div>
        </>

    );
}