import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";




export const getWarehouseDictionaryWithAccessApi = (async ( ) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.dictionaryHasAccess,
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)

export const  getWarehouseDictionary =( async () =>  await http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.Warehouse.dictionary,
    method: 'get',

}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject( err);
})
)
