import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Loading } from "components/Loading/loading";
import { SelectBox } from "components/Select/Select";
import { Textbox } from "components/Textbox/Textbox";
import { useEffect, useRef, useState } from "react";
import { GUID_EMPTY, INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { getWarehouseDictionaryWithAccessApi } from "../SalesInvoice/components/WarehouseDictionary/api";

export function WarehouseForDetail({ Detailes ,setDetail,selectedObj}) {
    const [warehouseData,setWarehouseData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [warehousegroup, setwarehousegroup] = useState<boolean>(true);
    const [WarehouseDictionary, setWarehouseDictionary] = useState<any>({});
    const formRef = useRef<any>(null)




    function submit(){
        let newList:any = []
        
        Detailes.map((item)=>{
          
    
            newList.push({...item,
                WarehouseId:warehousegroup ?warehouseData?.WarehouseId   :  warehouseData?.["WarehouseId"+item?.ItemCode] ? warehouseData?.["WarehouseId"+item?.ItemCode]  :formRef.current?.["WarehouseId"+item?.ItemCode]?.value   ,
                WarehouseDetailId:warehousegroup ?warehouseData?.WarehouseDetailId   : warehouseData?.["WarehouseDetailId"+item?.ItemCode] ? warehouseData?.["WarehouseDetailId"+item?.ItemCode] :formRef.current?.["WarehouseDetailId"+item?.ItemCode]?.value  ,
                Warehouse: warehousegroup ?warehouseData?.WarehouseTitle   : warehouseData?.["WarehouseTitle"+item?.ItemCode] ?warehouseData?.["WarehouseTitle"+item?.ItemCode]  : WarehouseDictionary?.[formRef.current?.["WarehouseId"+item?.ItemCode]?.value]?.Description  ,
                WarehouseDetail:warehousegroup ?
                warehouseData?.WarehouseDetail   :
                warehouseData?.["WarehouseDetail"+item?.ItemCode]
                 ?warehouseData?.["WarehouseDetail"+item?.ItemCode] :
                WarehouseDictionary?.[formRef.current?.["WarehouseDetailId"+item?.ItemCode]?.value]?.Description   ,
             } )
        })
        setDetail(newList)
    }


    const getWarehouseDictionaryAsync = async ()=>{
        setLoading(false) 
        try {
        const res = await getWarehouseDictionaryWithAccessApi()
        if(res?.Data){
            setWarehouseDictionary(res?.Data)
            let Warehouse :any= Object.values(res?.Data)[0]
      if(selectedObj?.WarehouseId!=GUID_EMPTY){
        let WarehouseDetailsCount=0
        Object.keys(res?.Data).forEach((item)=>{
          if(item==selectedObj?.WarehouseId){
            WarehouseDetailsCount=res.Data[item].WarehouseDetailsCount
          }
 })
    setWarehouseData({
    WarehouseId:selectedObj?.WarehouseId,
      WarehouseTitle:selectedObj?.Warehouse,
      WarehouseDetailsCount:WarehouseDetailsCount  
        })
      }
  else{
        setWarehouseData({
          WarehouseId:Object.keys(res?.Data)[0],
          WarehouseTitle:Warehouse.Description	 ,
            WarehouseDetailsCount:Warehouse?.WarehouseDetailsCount	})
      }
  }
        } catch (error:any) {
            setWarehouseDictionary(null)
        }
        setLoading(true)
      }


    useEffect(() => {
        getWarehouseDictionaryAsync()

    }, []);



    const handleChangeMainWarehouse=(e,item)=>{
    
      
if(WarehouseDictionary[e.target.value]?.WarehouseDetailsCount>0){
  setWarehouseData((prev)=>{return{...prev,["WarehouseId"+item?.ItemCode]:e.target.value ,["WarehouseTitle"+item?.ItemCode] :WarehouseDictionary[e.target.value]?.Description ,["WarehouseDetailsCount"+item?.ItemCode] :WarehouseDictionary[e.target.value]?.WarehouseDetailsCount	}})

}else{
  setWarehouseData((prev)=>{return{...prev,["WarehouseId"+item?.ItemCode]:e.target.value ,["WarehouseTitle"+item?.ItemCode] :WarehouseDictionary[e.target.value]?.Description ,["WarehouseDetailsCount"+item?.ItemCode] :WarehouseDictionary[e.target.value]?.WarehouseDetailsCount,["WarehouseDetailId"+item?.ItemCode]:null,["WarehouseDetail"+item?.ItemCode]:null}})
}

    }

  return (
    <div className="card ">

      <div className="card">
        <div className="card-body">
            {loading ?
            
        
      <>
        <div className="row mt-2 justify-content-end"> 
            <div className="col row">
                <div className="col-3 mt-4">
                    <CheckableButton  
                        labelText={'warehousegroupselecttion'} 
                        defaultChecked={warehousegroup}
                        defaultValue={warehousegroup}
                        inputName={"warehousegroupselecttion"} 
                        checked={warehousegroup}
                        type={"checkbox"}
                        onchange={(e)=>{setwarehousegroup(!warehousegroup)}}
                    />
                </div>
                {warehousegroup&&<>
                        <div className="col-lg-3">
                           <SelectBox
                               onChange={(e)=>{setWarehouseData({
                                WarehouseId:e.target.value , WarehouseTitle:WarehouseDictionary[e.target.value].Description , WarehouseDetailsCount:WarehouseDictionary[e.target.value]?.WarehouseDetailsCount	})} }
                               lable={"Warehouse"}
                               selectType={'select'}
                               options={Object.values(WarehouseDictionary).map((item:any)=>{return item.Description})}
                               val={Object.keys(WarehouseDictionary) }
                               name={"Warehouse"}
                               value={warehouseData?.WarehouseId ? warehouseData?.WarehouseId : undefined}
                               mode={"edit"}
                           />    
                        </div>
                        {warehouseData?.WarehouseDetailsCount >0 &&
                           <div className="col-lg-3">
                             <DictionarySelectBox
                              name="WarehouseDetail"
                              label="WarehouseDetail"
                              params={{warehouseId:warehouseData?.WarehouseId }}
                              onChange={(e,obj)=>{setWarehouseData((prev)=>{return{...prev ,WarehouseDetailId:e ,WarehouseDetail:obj?.Name }})}}
                              baseUrl={INVENTORYANDSALES_URL}
                              endPoint={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.dictionary}
                              value={warehouseData?.WarehouseDetailId}
                              mode={"edit"}
                              />
                          </div>
                        }
                </>
                }
            </div>
        <div  style={{ width:"10%"}}>
            <Button  onClick={(e)=>{submit()}}  value={"save"} btnType="submit"  />
          </div>
        </div>
        <form ref={formRef}>
          {Detailes.map((item) => (
            <div className="row mt-2">
                       <div className="col-lg-3">
                            <Textbox
                            textboxName="ItemDescription"
                            textboxType="text"
                            labelText="Item"
                            value={item?.Item}
                            defaultValue={item?.Item}
                            mode={"view"}
                            />
                        </div>
                       <div className="col-lg-3">
                            <Textbox
                            textboxName="ItemCode"
                            textboxType="text"
                            labelText="ItemCode"
                            value={item?.ItemCode}
                            defaultValue={item?.ItemCode}
                            mode={"view"}
                            />
                        </div>
                        <div className="col-lg-3">
                        <SelectBox
                            onChange={(e)=>{
                              handleChangeMainWarehouse(e,item);
                              ; } }
                            lable={"Warehouse"}
                            selectType={'select'}
                            options={Object.values(WarehouseDictionary).map((item:any)=>{return item.Description})}
                            val={Object.keys(WarehouseDictionary) }
                            name={"WarehouseId"+item?.ItemCode}
                            value={ warehouseData?.["WarehouseId"+item?.ItemCode]? warehouseData?.["WarehouseId"+item?.ItemCode]: warehouseData?.WarehouseId?warehouseData?.WarehouseId:   ( item.WarehouseId )}
                            mode={warehousegroup?"view":"edit"}
                            />    

                       </div>
                       {((warehouseData?.["WarehouseDetailsCount"+item?.ItemCode]!=undefined) ?(warehouseData?.["WarehouseDetailsCount"+item?.ItemCode]>0):(warehouseData?.WarehouseDetailsCount>0) )  &&
                       <div className="col-lg-3">
                             <DictionarySelectBox
                              name={"WarehouseDetailId"+item?.ItemCode}
                              label="WarehouseDetail"
                              params={{warehouseId:warehouseData?.["WarehouseId"+item?.ItemCode] ?warehouseData?.["WarehouseId"+item?.ItemCode]  :warehouseData?.WarehouseId }}
                              onChange={(e,obj)=>{setWarehouseData((prev)=>{return{...prev ,["WarehouseDetailId"+item?.ItemCode] :e ,["WarehouseDetail"+item?.ItemCode]:obj?.Name }})

                            }
                    
                            
                            }
                              baseUrl={INVENTORYANDSALES_URL}
                              endPoint={ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseDetail.dictionary}
                              value={warehousegroup? warehouseData?.WarehouseDetailId :   item.WarehouseDetailId?item.WarehouseDetailId:  warehouseData?.["WarehouseDetailId"+item?.ItemCode] ?warehouseData?.["WarehouseDetailId"+item?.ItemCode]: ( warehouseData?.WarehouseDetailId  )}
                              mode={warehousegroup?"view":"edit"}
                            />
                          </div>
                       }
            </div>
          ))}
          
        </form>
          </>
          :<Loading/>
    }
        </div>
      </div>
    </div>
  );
}
