import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "components/ToastShow/ToastShow";
import React, { useState, useCallback, useEffect, useRef, createContext } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import "./style.scss";

interface ModalFormProp {
  title: string
  mode?: string;
  modalProfileKey?: string;
  onHide: () => void;
  show: boolean;
  children?: React.ReactNode | any;
  modalWidth?;
  modalHeight?;
  checkClose?;
  getModalWidth?: (param: any) => void;
}
export  const UserContext = createContext<any>([]);

export const ModalForm = ({
  title,
  mode,
  modalProfileKey,
  onHide,
  show,
  children,
  modalWidth,
  checkClose=true,
  modalHeight=40,
  getModalWidth,
}: ModalFormProp) => {
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [leftx, setLeftx] = useState<any>("5%")
  const [boxWidth, setBoxWidth] = useState<any>(70)
  const [boxHeight, setBoxHeight] = useState<any>(modalHeight)
  const [fullscreen, setfullscreen] = useState<boolean>(false);
  const [mouseleftx, setMouseleftx] = useState<any>()
  const [boxleftx, setBoxleftx] = useState<any>()
  const [mouseleftY, setMouseleftY] = useState<any>()
  const [boxleftY, setBoxleftY] = useState<any>()
  const [activeIndex, setActiveIndex] = useState<any>(null)
  const [topy, setTopy] = useState<any>("5%")
  const [touchX, setTouchX] = useState<any>()
  const [touchY, setTouchY] = useState<any>()
  const [touchIndex, setTouchIndex] = useState<any>(null)
  const [sendSize, setSendSize] = useState<any>(modalWidth < 50 || window.innerWidth < 900)
  const [getflag, setgetFlag] = useState<any>(true)
  const [userMadeChanges, setUserMadeChanges] = useState(false);
  const myStateRef = React.useRef(userMadeChanges);
  const boxWidthRef = React.useRef(boxWidth);
  const boxHeightRef = React.useRef(boxHeight);
  let disableDrag: boolean = false
  const lang = localStorage.getItem("lang")
  const el = document.getElementById('modalform')
  const boxRef = useRef<any>();
  const timeID = new Date().getTime() + "modalform"
  const timeIDRes = useRef<any>(timeID);

  window.onresize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }   
  


 function hide(){
  if(sessionStorage.getItem("changingforms")+""=="true"&&checkClose&&mode!="view"){
    Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
        sessionStorage.setItem("changingforms" , "false");
        onHide()
      });
    }else{
      onHide()
    };

}

useEffect(() => {

   return ()=>{
    let modalKey = modalProfileKey ? modalProfileKey : title
 let modalsSize = localStorage.getItem("modalsSize");
 let locallist;
 let localflag = true;
 if (modalsSize == null) {
   locallist = [];
 } else {
   locallist = JSON.parse(modalsSize)
 
 }
 locallist.forEach(item => {
   if (item[0] == modalKey) {
     localflag = false
     item[1] = boxWidthRef.current
     item[2] =  boxHeightRef.current
   }
 });
 if (localflag) {
   locallist.push([modalKey, boxWidthRef.current,  boxHeightRef.current])
 }
 localStorage.setItem("modalsSize", JSON.stringify(locallist));


 }
}, [])
const setModalFullscreen = () => {
  setfullscreen(!fullscreen)
  }

// on touch or mouse click

  const mouseDown = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;
    setMouseleftx(e.screenX)
    setBoxleftx(x)
    const y = boxRef?.current?.offsetTop;
    setMouseleftY(e.screenY)
    setBoxleftY(y)
    setActiveIndex(1);
  }

  const touchStart = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;
    setTouchX(e.touches[0].screenX)
    setBoxleftx(x)
    const y = boxRef?.current?.offsetTop;
    setTouchY(e.touches[0].screenY)
    setBoxleftY(y)
    setTouchIndex(1);    
  }

// resize UP & DOWN

  const resizeUp = (e) => {
    e.stopPropagation()
    const y = boxRef?.current?.offsetTop;
    setBoxleftY(y)
    setMouseleftY(e.screenY)
    setActiveIndex(4)
  }

  const resizeDown = (e) => {
    e.stopPropagation()

    const y = boxRef?.current?.offsetTop;
    setBoxleftY(y)
    setMouseleftY(e.screenY)
    setActiveIndex(5)
  }

// resize LEFT & RIGHT

  const resizeLeft = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;

    setBoxleftx(x)
    setMouseleftx(e.screenX)
    setActiveIndex(2);
  }

  const resizeRight = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;

    setBoxleftx(x)
    setMouseleftx(e.screenX)
    setActiveIndex(3);   
  }


  const resizeTouchRight = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;      

    setBoxleftx(x)
    setTouchX(e.touches[0].screenX)
    setTouchIndex(3);
  }

  const resizeTouchLeft = (e) => {
    e.stopPropagation()
    const x = boxRef?.current?.offsetLeft;
   
    setBoxleftx(x)
    setTouchX(e.touches[0].screenX)
    setTouchIndex(2);
  }

// if any text selected

  const textSelection = () => {    
    const selection = document.getSelection()
    const selectonRange: any = selection?.focusOffset
  
    if(selectonRange > 0){
      disableDrag = true 
    } else{
      disableDrag = false
    }
  }

// mouse and touch travel on screen

  const mouseMove = useCallback((e) => {    
    if(disableDrag){
      e.preventDefault()
      return
    } 
    else if(activeIndex == 1) {
      setLeftx((boxleftx - (mouseleftx - e.screenX)) + "px")
      if(e.clientY > 5){
        setTopy((boxleftY - (mouseleftY - e.screenY)) + "px")
      }
    } 
    else if (activeIndex == 2) {
      if(mouseleftx - e.screenX < 420){
        if(((mouseleftx - e.screenX)+((boxWidth* window.innerWidth)/100)) > 450){
        setLeftx((boxleftx - (mouseleftx - e.screenX)) + "px")
          setBoxWidth((((mouseleftx - e.screenX) / window.innerWidth) * 100) + boxWidth)
          boxWidthRef.current = (((mouseleftx - e.screenX) / window.innerWidth) * 100) + boxWidth
        }

      }
    } 
    else if (activeIndex == 3) {
      if(mouseleftx - e.screenX < 420){
        if(((boxWidth* window.innerWidth)/100)-(mouseleftx - e.screenX) > 450){
          setBoxWidth(boxWidth - (((mouseleftx - e.screenX) / window.innerWidth) * 100))
          boxWidthRef.current =(boxWidth - (((mouseleftx - e.screenX) / window.innerWidth) * 100))
        }
      }
    } 
    else if (activeIndex == 4) { 
      if(((boxHeight* window.innerHeight)/100)+(mouseleftY - e.screenY) > 300){
      setTopy((boxleftY - (mouseleftY - e.screenY)) + "px")
      setBoxHeight((((mouseleftY - e.screenY) / window.innerHeight) * 100) + boxHeight)
      boxHeightRef.current = ((((mouseleftY - e.screenY) / window.innerHeight) * 100) + boxHeight)
      }
    } 
    else if (activeIndex == 5) {

      if(((boxHeight* window.innerHeight)/100)-(mouseleftY - e.screenY)> 300){

      setBoxHeight(boxHeight -(((mouseleftY - e.screenY) / window.innerHeight) * 100))
      boxHeightRef.current = (boxHeight -(((mouseleftY - e.screenY) / window.innerHeight) * 100))}

    }
  }, [activeIndex]);

  const touchMove = useCallback((e) => {

    if(disableDrag){
      e.preventDefault()
      return
    }
    else if (touchIndex == 1) {
      setLeftx((boxleftx - (touchX - e.touches[0].screenX)) + "px")
      setTopy((boxleftY - (touchY - e.touches[0].screenY)) + "px")
    } 
    else if (touchIndex == 2) {
      setLeftx((boxleftx - (touchX - e.touches[0].screenX)) + "px")
      setBoxWidth((((touchX - e.touches[0].screenX) / window.innerWidth) * 100) + boxWidth)
      boxWidthRef.current = ((((touchX - e.touches[0].screenX) / window.innerWidth) * 100) + boxWidth)
    } 
    else if (touchIndex == 3) {
      setBoxWidth(boxWidth - (((touchX - e.touches[0].screenX) / window.innerWidth) * 100))
      boxWidthRef.current = (boxWidth - (((touchX - e.touches[0].screenX) / window.innerWidth) * 100))

    }
  }, [touchIndex]);


  // remove events

  const removeListeners = useCallback(() => {
    setActiveIndex(null)
    setBoxleftY(null)
    setMouseleftY(null)
    setBoxleftx(null)
    setMouseleftx(null)
    setTouchY(null)
    setTouchX(null)


    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners ,true);
    window.removeEventListener('mouseup', mouseUp );
  }, [mouseMove, touchMove]);


  const removeTouchListeners = useCallback(() => {
    setTouchIndex(null)
    setBoxleftY(null)
    setTouchY(null)
    setBoxleftx(null)
    setTouchX(null)
    setMouseleftY(null)
    setMouseleftx(null)


    el!.removeEventListener('touchmove', touchMove);
    el!.removeEventListener('touchend', removeTouchListeners);
  }, [touchMove, mouseMove]);


  // mouse and touch removed from screen

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);


  const touchEnd = useCallback(() => {
    setTouchIndex(null);
    removeTouchListeners();
  }, [setTouchIndex, removeTouchListeners]);


  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
  }, [mouseDown]);

  useEffect(() => {
    if (touchIndex !== null) {
      el?.addEventListener('touchmove', touchMove);
      el?.addEventListener('touchend', touchEnd);
    }  
  }, [touchMove]);

  useEffect(() => {
    if(getflag){
      getModalWidth && getModalWidth(sendSize) 
      let modalKey = modalProfileKey ? modalProfileKey : title

      let modalsSize = localStorage.getItem("modalsSize");
      let locallist;

      if (modalsSize == null) {
        locallist = [];
      } else {
        locallist = JSON.parse(modalsSize)
      }
      locallist.forEach(item => {
        if (item[0] == modalKey) {
          setBoxWidth(item[1])
          boxWidthRef.current = item[1]
          setBoxHeight(item[2])
          boxHeightRef.current = item[2]

        }
      });
    }
    setgetFlag(false)
  }, [children]);


  useEffect(() => {
      if(sendSize != (boxWidth < 50 || window.innerWidth < 900)){
        getModalWidth && getModalWidth(!sendSize)
        setSendSize(!sendSize) 
      }
  }, [boxWidth ,width])

  const kydown =(event)=>{


    
    event.stopPropagation();
    if(event.key == "Escape" &&  !document.activeElement?.className.includes("input")){    
      event.preventDefault()
      event.stopPropagation()
      
     let modalList =  document.getElementsByClassName("modal-form")
     if(modalList.length == 1){
       document.removeEventListener("keydown", kydown,true);
       hide()
      }else{
        if(modalList[modalList?.length-1]!=undefined &&  modalList[modalList?.length-1]?.id == timeIDRes.current){
          hide()
        }
     }
    }
}  

  // ** stop scrolling window when modal is opened
  useEffect(()=>{
    const body: HTMLBodyElement | null = document.querySelector('body');
    body!.style.overflow = 'hidden' 
    document.addEventListener('keydown',kydown,true  )
    if( window.innerWidth < 900){
      setfullscreen(true)
    }
    return () => {  document.removeEventListener("keydown", kydown,true); body!.style.overflow = 'auto' }
  },[])
  
  useEffect(()=>{
    try {
      const modalElement = boxRef.current;
      const firstElement = modalElement.querySelectorAll(".tabStart")[0]
      firstElement.focus()
      const handleTabKeyPress = (event) =>{
        if (event.key === "Tab") {
          
          if(document.activeElement?.className.includes("tab")){
            let lastElement = modalElement.querySelectorAll(".tabEnd ")[modalElement.querySelectorAll(".tabEnd").length-1]
            if (event.shiftKey && document.activeElement?.className.includes("tabStart") ) {
              event.preventDefault();
              lastElement?.focus();
            }else if (
              !event.shiftKey && ( lastElement == document.activeElement )
              ){
                event.preventDefault();
                firstElement?.focus();
              }
          }
        }
      };
      modalElement.addEventListener("keydown", handleTabKeyPress);
      return () => {
        modalElement.removeEventListener("keydown", handleTabKeyPress);
      };
    } catch (error) {
    }
  },[])


  return (
    <>
      {
        show &&
        <>
           <div className="fullscreen-bg-modal" />
            <div 
              ref={boxRef} 
              id={timeIDRes.current} 
              className={`modal-form  ${fullscreen ? "fullscreen-active" : ""}`} 
              style={{top: topy , left: leftx,maxHeight:"95%" , height:`${boxHeight}%`, width: `${width < 775 ? 99 : boxWidth}%`}} 
              role="document"
            >

              <div 
                className={`modal-form__resize-right ${fullscreen ? "d-none" : "d-block"}`} 
                onMouseDown={lang === "fa" ? resizeRight : resizeLeft} 
                onTouchStart={lang === "fa" ? resizeTouchRight : resizeTouchLeft}
              />
              <div 
                 className={`modal-form__resize-up ${fullscreen ? "d-none" : "d-block"}`}
                 onMouseDown={resizeUp}
              />
              <div className="modal-form__content modal-content" >
                <div  className="modal-form__content-header modal-header alert-primary" onMouseDown={mouseDown} onTouchStart={touchStart}>
                  <strong className="Modaltitle" id="modal-form__title" >
                    { trans(title) }
                  </strong>
                  <div>
                  {window.innerWidth < 500 ?
                   <></>
                   :
                    <FontAwesomeIcon icon={fullscreen ? [ICON_TYPE, "compress"] : [ICON_TYPE, "expand"]} onClick={setModalFullscreen} className='modal-form__content-header-fullscreen-btn mx-4 modalForm__fullscreen-btn'/>
                  }
                    <button onClick={(e)=>{e.stopPropagation(); hide()}} type="button" className=" modal-form__content-header-close-btn border-none alert-primary">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div className={`modal-form__content-body modal-body ${fullscreen ? "fullscreen-active" : ""} px-2 py-2`}>
                  <UserContext.Provider value={[userMadeChanges,(e)=>{   myStateRef.current = e ; setUserMadeChanges(e) } ]}>
                    {children}
                  </UserContext.Provider>
                </div>
              </div>

              {/* <div 
                 className={`modal-form__resize-north-west ${fullscreen ? "d-none" : "d-block"}`}
              />
              <div 
                 className={`modal-form__resize-south-west ${fullscreen ? "d-none" : "d-block"}`}
              /> */}
              <div 
                className={`modal-form__resize-down ${fullscreen ? "d-none" : "d-block"}`} 
                onMouseDown={resizeDown}
              />
              <div 
                className={`modal-form__resize-left ${fullscreen ? "d-none" : "d-block"}`} 
                onMouseDown={lang === "fa" ? resizeLeft : resizeRight} 
                onTouchStart={lang === "fa" ? resizeTouchLeft : resizeTouchRight}
              />

            </div>
        </>
      }


    </>
  );
};
