import  { useEffect, useRef, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import type { Value } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { trans } from "utils/helpers";
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { REGEX } from "utils/regex";

interface DatepickerProps {
  lable?: string,
  required?: boolean,
  setIsoTime: (isoTime: any) => void,
  Change?: (e) => void,
  val: string | Value,
  id?: any,
  resetForm?: boolean,
  withTime?: boolean,
  disableDayPicker?: boolean,
  fixMainPosition?: boolean,
  mode?: string,
  format?: string,
  onOpenPickNewDate?: boolean,
  portal?: boolean,
  name?: any,
  range?: any,
  className?: any,
  parentName?: any,
  type?: "date" | "time",
  recordedData?:any,
  min?:number,
  max?:number,
  errorFlag?:any,
  maxDate?:any,
  minDate?:any,
  useTrans?:boolean,
  calendarPosition?:string,
  placeholder?:string|null
}

export default function Datepicker({
  lable,
  required,
  resetForm,
  withTime,
  placeholder,
  setIsoTime,
  Change,
  val,
  id = undefined,
  type,
  disableDayPicker=false,
  onOpenPickNewDate=false,
  portal=true,
  range=false,
  fixMainPosition=false,
  mode,
  format,
  parentName="",
  name="DatePicker",
  className="",
  recordedData,
  min,
  max,
  maxDate,
  minDate,
  errorFlag=false,
  useTrans=true,
  calendarPosition="bottom-right",
  
  ...rest
}: DatepickerProps) {
  const timePickerRef = useRef<any>(null);
  const isOpenRef = useRef<any>(null);
  
  let editDate;
  let flag:any = null;
  const digits = persian_fa.digits;
  const disabled = (mode =="view" ? true : false);
  const [value, setValue] = useState<Value>(val);
  const RecordedData :any= withTime ? recordedData : (recordedData?  recordedData?.toString().slice(0,10):null)
  let lang = localStorage.getItem("lang");
  let date;
  const [newDate,setNewDate] = useState<any>();
  const ConverDate = new DateObject({
  date: new Date(RecordedData?.slice(0,4),RecordedData?.slice(5,7)-1,RecordedData?.slice(8,10)),
  calendar: persian
  })


const onChange =(e)=>{
  if (e != null) {
    if (type === "time") {
      setIsoTime(`${e}`);
    }else {
      if(range){
        
        let date1 = new Date((e[0]?.valueOf() as number));
        if(e[1]){
          let date2 = new Date((e[1]?.valueOf() as number ));
          setIsoTime([date1.toISOString() ,date2.toISOString() ]);  
        }else{
          setIsoTime([date1.toISOString()  ]);

        }
      }else{
        
        date = new Date(e.valueOf() as number);
        // به دلیل عقب افتادن استیت باید از متغییر استفاد کرد
        let NewDate =`${date.getFullYear()}/${+(date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1): +(date.getMonth()+1)}/${+(date.getDate()) < 10 ? '0'+(date.getDate()): +(date.getDate())}`;
        setNewDate (`${date.getFullYear()}/${+(date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1): +(date.getMonth()+1)}/${+(date.getDate()) < 10 ? '0'+(date.getDate()): +(date.getDate())}`);
        
        // به این دلیل که این متد در هنگام تبدیل به ساعت ایران ما را به یه روز قبل اانتقال میدهد toISOString از فرمت بالا بجای متد 
        if(withTime){
          NewDate != val?.toString().slice(0,10) || date.getHours().toString() != val?.toString().slice(11,13) || date.getMinutes().toString() != val?.toString().slice(14,16) ? Change&&Change({[name] :date.toISOString()}) : Change&&Change({[name]:null}) ;
        }else{
          NewDate!= val?.toString().slice(0,10) ? Change&&Change({[name] :date.toISOString()}):Change&&Change({[name]:null});
        }
        setIsoTime(date?.toISOString());
      }
    }
  }else{
    setNewDate(" ")
     setIsoTime(e);
  }
}



function handleTabKeyPresse(event){
  if (event.key === "Tab") {
    if (event.shiftKey) {

      if( isOpenRef.current&&(disableDayPicker ?  document.activeElement?.className.includes("rmdp-up") && document.activeElement?.nextElementSibling?.getAttribute("name") == 'hour' : document.activeElement?.className.includes("rmdp-left"))){
        timePickerRef.current?.firstChild?.focus()

       }
      flag = true;
    } else  {
        if( isOpenRef.current && document.activeElement?.className.includes("rmdp-down") && document.activeElement.previousElementSibling?.getAttribute("name") != 'hour' ){
         timePickerRef.current?.firstChild?.focus()
        }
          flag = false;
    }
  }
  
};
  


useEffect(() => {
  let input:any = document.querySelectorAll(".rmdp-input")
  let modalForm = document.querySelectorAll(".modal-content")
  let cardBody = document.querySelector<HTMLElement>(".card-body")
    Array.from(input).map((item:any,index)=>{
      item?.addEventListener("blur",(e)=>{
        if(flag!=null){
            const activeElement:any = document.activeElement as HTMLElement;
            const inputs =modalForm[0]? modalForm[0]?.querySelectorAll<HTMLInputElement>('.modal-body, .form-control-custom,.form-control,.rmdp-input,.checkable-btn__input'):document.querySelectorAll<HTMLInputElement>('.modal-body, .form-control-custom,.form-control,.rmdp-input,.checkable-btn__input,.btn');
            const currentIndex = Array.from(inputs).indexOf(item);
            const nextInput =!flag ? inputs[currentIndex + 1] : inputs[currentIndex - 1]
            if (!nextInput?.className?.includes("btn")) {
              nextInput.click();
              nextInput.focus();
            }else{
              cardBody?.click()
              nextInput.focus();
            }
            flag = null;
        }
    })
  
    })
    
  modalForm[0] ? document.addEventListener('keydown', handleTabKeyPresse):document.addEventListener('keydown', handleTabKeyPresse);
 return () => {
  modalForm[0] ? document.removeEventListener('keydown', handleTabKeyPresse):document.removeEventListener('keydown', handleTabKeyPresse);
 };
}, []);



const validationChecking = (date:any, { input, isTyping }:any) => {
  let value = input.value;

  for (let digit of digits) {
    value = value.replace(
      new RegExp(digit, "g"),
      digits.indexOf(digit)
    );
  }
  if(withTime){
    const strings = value.split(':')
    const numbers = strings.map(Number);
    const [hour,minutes] = numbers;
    if (input.value && numbers.some((number) => (isNaN(number)))) {
      //return false; //in case user enter something other than digits
    }
  
    if (hour < 0 || ( hour > 23)) return false;
    if (minutes < 0 || ( minutes > 59)) return false;
  }

  if (!isTyping){onChange(date); return setValue(date)}; // user selects the date from the calendar and no needs for validation.
  if(!disableDayPicker){


    const strings = value.split("/");
    const numbers = strings.map(Number);
    const [year, month, day] = numbers;

    if (input.value && numbers.some((number) => (isNaN(number)))) {
      return false; //in case user enter something other than digits
    }

    let valditaionRegex = new RegExp(REGEX.datePicker.regex);
    let validationRegexResult;
    validationRegexResult = valditaionRegex.test(year);

    if(( (year.toString().length == 4||value.includes("/")) && !validationRegexResult && (year < (min ? min :1399) || year >( max ? max : 1500))) || year.toString().length > 4)return false;
    
    
    if (month > 12 || month < 0) return false; //month < 0 in case user want to type 01
    if (day < 0 || (date && day > date.day)) return false;
    if (strings.some((val) => val.startsWith("00"))) return false;
    
    //onChange(date)
    setValue(date);
  }
  }


  
  useEffect(() => {
    if(value != null){
          if (type === "time") {
      editDate = new DateObject({ date: new Date(val as string) });
      setValue(editDate);
    } else {
      if(range){
         let editDate1 = new DateObject({ date: new Date(val?.[0] as string), format: "YYYY/MM/DD hh:mm:ss.SSS a" });
        let editDate2 = new DateObject({ date: new Date(val?.[1] as string), format: "YYYY/MM/DD hh:mm:ss.SSS a" });
         setIsoTime([editDate1.toDate()  , editDate2.toDate()])
         setValue(null);

      }else{

        editDate = new DateObject({ date: new Date(val as string), format: "YYYY/MM/DD hh:mm:ss.SSS a" });
        setIsoTime(editDate.toDate().toISOString())
        setValue(null);
      }
    }
  
    }

}, [resetForm])
const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

 async function selectValue(){
  await delay(20);isOpenRef.current = true;timePickerRef.current.children[0]?.select()
  }


  useEffect(() => {
    if(val != null){
    if (type === "time") {
      editDate = new DateObject({ date: new Date(val as string) });
    } else {
      if(range){
        editDate =[ new DateObject({ date: new Date(val[0] as string), format: "YYYY/MM/DD hh:mm:ss.SSS a" }) ,  new DateObject({ date: new Date(val[1] as string), format: "YYYY/MM/DD hh:mm:ss.SSS a" })] ;
      }
    }
    
    setValue(editDate);
  }
}, [id, val]); // آیا دیت پیکر با آیدی کار کند یا val ؟

  return (
    <div>
      <OverlayTrigger
        key={lable}
        placement="top"
        delay={{"show" : 300 , "hide":0}}
        overlay={
            <Tooltip className="tooltip" id={`button-tooltip-${"checkable"}`}>
                {trans(lable+"")}
            </Tooltip>
        }
        >
      <div>
        {lable ? (
          <div className="d-flex align-items-center" style={{height:'22px'}}>
            <label className="date-picker__label showDots">
             {useTrans ? trans(lable) : lable}
            </label>
             {required? <span className="text-danger">*</span> : ""}
          </div>
        ) : null}
      </div>
      </OverlayTrigger>

   
      

  {((mode=="edit"&& RecordedData != null && (RecordedData) != (newDate ? (newDate==" " ?null:newDate) : val?.toString().slice(0,10)))) && 
            <>
              <OverlayTrigger
                 key={"reserve"}
                 placement="top"
                 delay={{"show" : 600 , "hide":0}}
                 overlay={
                    <Tooltip className="tooltip " id={`button-tooltip-${"reserve"}`}>
                    {trans("PreviousValue")+  " : "+  ConverDate?.format()}
                    </Tooltip>
                    }>
             <span>
              <FontAwesomeIcon style={{marginRight:"5px"}} icon={["fas", "circle-info"]} className="text-primary pr-2"/>
             </span>
             </OverlayTrigger> 

            </> 
            }
    

      {
        {
          "fa": (
            type === "date" || type === undefined ?
              <div className="d-flex"   style={{ direction: "rtl" }}  onMouseDown={(e) => {e.stopPropagation()
              }} >
                <DatePicker
                  range={range}
                  portal={portal}
                  ref={timePickerRef}
                  onOpenPickNewDate={onOpenPickNewDate}
                  required={required}
                  inputClass={`rmdp-input${ disabled ? " disabled " : "" } ${className ?className:" form-control-custom" }   `}
                  calendar={persian}
                  minDate={minDate}
                  maxDate={maxDate}
                  id={id}
                  locale={persian_fa}
                  calendarPosition={calendarPosition}
                  value={value}
                  disableDayPicker={disableDayPicker}
                  format={format ?format : (withTime ?"YYYY/MM/DD HH:mm" :"YYYY/MM/DD")}
                  onChange={(date,{isTyping,input})=>{onChange(date); return validationChecking(date,{isTyping,input})}}      
                  onOpen={()=>{selectValue()}}
                  onClose={()=>{isOpenRef.current = false}}
                  name={name}           
                  plugins={withTime ? [<TimePicker   hideSeconds />] : []}
                  disabled={disabled}
                  fixMainPosition={fixMainPosition}
                  fixRelativePosition={fixMainPosition}
                  placeholder={placeholder ? placeholder : ""}
                />
                {(!lable && required) && <span className="text-danger">*</span>}
              </div> :
              <div className="d-flex" onMouseDown={(e) => {e.stopPropagation();
              }} >
                <DatePicker
                  portal={portal}
                  range={range}
                  onOpenPickNewDate={onOpenPickNewDate}
                  required={required}
                  onOpen={()=>{selectValue()}}
                  minDate={minDate}
                  maxDate={maxDate}
                  inputClass={`rmdp-input ${className ?className:" form-control-custom" }  ${disabled ? "disabled" : ""} `}
                  disableDayPicker
                  format={format? format :"HH:mm"}
                  onChange={(date,{isTyping,input})=>{onChange(date); return validationChecking(date,{isTyping,input})}}      

                  value={value}
                  ref={timePickerRef}
                  name={name}            
                  id={id}
                  // onChange={validationChecking}             
                  onClose={() => {isOpenRef.current = false; timePickerRef.current && setValue((timePickerRef.current as any).getElementsByTagName('input')[0].value) }}
                  plugins={[<TimePicker  hideSeconds />]}
                  calendar={persian}
                  locale={persian_fa}
                  disabled={disabled}
                  calendarPosition="bottom-right"
                  fixMainPosition={fixMainPosition}
                  fixRelativePosition={fixMainPosition}
                  placeholder={placeholder ? placeholder : ""}

                />
                {(!lable && required) && <span className="text-danger">*</span>}

              </div>
          ),
          "en": (
            type === "date" || type === undefined ?
              <div className="d-flex" onMouseDown={(e) =>{ e.stopPropagation()}
              } >
                <DatePicker
                  portal={portal}
                  range={range}
                  minDate={minDate}
                  maxDate={maxDate}
                  onOpenPickNewDate={onOpenPickNewDate}                
                  required={required}
                  disableDayPicker={disableDayPicker}
                  format={format ?format : (withTime ?"YYYY/MM/DD HH:mm" :"YYYY/MM/DD")}
                  inputClass={`rmdp-input ${className ?className:" form-control-custom" }  ${disabled ? "disabled" : ""} `}
                  value={value}
                  plugins={withTime ? [<TimePicker hideSeconds />] : []}
                  onChange={(e)=>{setValue(e);onChange(e) }}
                  name={name}     
                  id={id}
                  onOpen={()=>{selectValue()}}
                  onClose={()=>{isOpenRef.current = false}}
                  disabled={disabled}
                  fixMainPosition={fixMainPosition}
                  fixRelativePosition={fixMainPosition}
                  placeholder={placeholder ? placeholder : ""}

                />
                {(!lable && required) && <span className="text-danger">*</span>}

              </div> :
              <div className="d-flex" onMouseDown={(e) =>{ e.stopPropagation()}} >
                <DatePicker
                  portal={portal}
                  range={range}
                  minDate={minDate}
                  maxDate={maxDate}
                  onOpenPickNewDate={onOpenPickNewDate}                
                  required={required}
                  inputClass={`rmdp-input ${className ?className:" form-control-custom" }  ${disabled ? "disabled" : ""} `}
                  disableDayPicker
                  format="HH:mm"
                  name={name}            
                  id={id}
                  value={value}
                  onOpen={()=>{selectValue()}}
                  onClose={() => {isOpenRef.current = false;timePickerRef.current && setValue((timePickerRef.current as any).getElementsByTagName('input')[0].value) }}
                  onChange={(e)=>{setValue(e);onChange(e) }}
                  plugins={[<TimePicker hideSeconds />]}
                  disabled={disabled}
                  fixMainPosition={fixMainPosition}
                  fixRelativePosition={fixMainPosition}
                  placeholder={placeholder ? placeholder : ""}

                />
                {(!lable && required) && <span className="text-danger">*</span>}

              </div>
          ),
          "ar": (
            <div className="d-flex">
              <DatePicker
              portal={portal}
              range={range}
                onOpenPickNewDate={onOpenPickNewDate}            
                required={required}
                inputClass={`rmdp-input ${className ?className:" form-control-custom" }  ${disabled ? "disabled" : ""} `}
                calendar={arabic}
                locale={arabic_ar}
                calendarPosition="bottom-right"
                value={value}
                id={id}
                onChange={(e)=>{setValue(e);onChange(e)}}
                name={name}            
                disabled={disabled}
                fixMainPosition={fixMainPosition}
                fixRelativePosition={fixMainPosition}
                placeholder={placeholder ? placeholder : ""}
  
              />
                {(!lable && required) && <span className="text-danger">*</span>}
            </div>
            
          ),
        }[lang as string]
      }
      <span className={`${"show-msg" } text-box__regex-msg text-danger`}>{ errorFlag? trans("InvalidEndDate"):""}</span>
    </div>
  );
}
