import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { Items } from "pages/InventoryAndSales/Inventory/WarehouseItem/ItemTreeForm";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";

import { uuidv4 } from "utils/helpers";

interface SalesInvoiceFormProps {
  onHide: () => void;
  warehouseIODetail?: any;
  mode: any;
  warehouseId: any;
  selectedObj?: any;
}

export const InventoryRemittanceDetailForm = ({
  mode,
  onHide,
  warehouseIODetail,
  warehouseId,
  selectedObj,
}: SalesInvoiceFormProps) => {
  const [showTree, setShowTree] = useState<any>();
  const [userChanges, setUserChanges] = useState({});
  const [loading, setLoading] = useState<any>(true);


  const [itemData, setItemData] = useState<any>();
  const [dictionaryNameUnit, setDictionaryNameUnit] = useState<any>({});
  const [detailWarehouseData, setDetailWarehouseData] = useState<any>({
    QtyOut2:0,
    QtyOut1:0,
    ItemValue:0,
    ItemValueOut:0,
    WageCost:0
  });
  const [resetForm, setResetForm] = useState(false);


  const itemCodeHandeler = (obj) => {
    setLoading(false);
    setItemData({ Code: obj.ItemCode, Id: obj.Id,Description:obj.ItemDescription,TechnicalCode:obj.ItemTechnicalCode });
    setDetailWarehouseData({...obj,UnitOut1Id:obj.Unit1Id,UnitOut2Id:obj.Unit2Id,UnitOut2:obj.Unit2,UnitOut1:obj.Unit1,QtyOut1:obj.Qty1,QtyOut2:obj.Qty2,
      ItemValue:0,
      ItemValueOut:0,
      WageCost:0
    })
    setResetForm(true)
    setDictionaryNameUnit({UnitOut2:obj.Unit2,UnitOut1:obj.Unit1})
    setShowTree(false);
    setLoading(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      Id:mode!='create'?warehouseIODetail.Id: uuidv4(),
      ItemId: itemData?.Id,
      ItemCode:itemData?.Code,
      Item:itemData?.Description,
      ItemTechnicalCode:itemData?.ItemTechnicalCode,
      UnitOut1Id: event.target.UnitOut1Id?.value,
      UnitOut1:dictionaryNameUnit.UnitOut1,
      UnitOut2Id: event.target.UnitOut2Id?.value,
      UnitOut2:dictionaryNameUnit.UnitOut2,
      QtyOut1: +event.target.QtyOut1?.value,
      QtyOut2: +event.target.QtyOut2?.value,
      ItemValue: +event.target.ItemValue?.value,
      ItemValueOut: +event.target.ItemValueOut?.value,
      WageCost: +event.target.WageCost?.value,
      Description: event.target.Description?.value,
      DescriptionEN: event.target.DescriptionEN?.value,
      QCNo: event.target.QCNo?.value,
       QtySource: 0,
       DeliveryCost:0,
       OtherCost: 0,
       OverloadCost: 0
      // UnitIn1Id: event.target.UnitIn1Id?.value,
      // UnitIn1:dictionaryNameUnit.UnitIn1,
      // UnitIn2Id: event.target.UnitIn2Id?.value,
      // UnitIn2:dictionaryNameUnit.UnitIn2,
      // Calculated: event.target.Calculated?.checked,
      // CalculatedBySystem: event.target.CalculatedBySystem?.checked,
      // CalculatedByAlteration: event.target.CalculatedByAlteration?.checked,
      // ManualRegistered: event.target.ManualRegistered?.checked,
      // QtyIn1: +event.target.QtyIn1?.value,
      // QtyIn2: +event.target.QtyIn2?.value,
      // ItemValueIn: +event.target.ItemValueIn?.value,
    };
    warehouseIODetail?.(data);
    onHide && onHide();
  };

  const initializeFormWithSelectedObject = () => {
    setDetailWarehouseData({ ...selectedObj });
    setItemData({ Code: selectedObj.ItemCode, Id: selectedObj.ItemId,Description:selectedObj.Item,TechnicalCode:selectedObj.ItemTechnicalCode });

  };



  useEffect(() => {
    if (mode !== "create" ) {
      console.log('a');
      
      initializeFormWithSelectedObject();
    }
  }, []);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  return (
    <div className="card " style={{ flexDirection: "row" }}>
      {showTree && (
        <ModalForm
          mode={mode}
          title="ItemTree"
          onHide={() => setShowTree(false)}
          show={showTree}
        >
          <Items
            onHide={() => {
              setShowTree(false);
            }}
            itemCodeHandeler={itemCodeHandeler}
            warehouseId={warehouseId}
          />
        </ModalForm>
      )}
      <div style={{ flexWrap: "nowrap" }} className="card-body row">
        <form action="" onSubmit={handleSubmit}>
          <div className="row">
            <div
              className="col-lg-3 col-sm-12"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Textbox
                required
                onChange={(e) => {
                  if (e?.target.value === null || e?.target.value === "") {
                    setDetailWarehouseData({});
                    setItemData({});
                  }
                }}
                textboxName={"itemCode"}
                textboxType="text"
                labelText={"itemCode"}
                mode={mode}
                value={ itemData?.Code}
                defaultValue={ itemData?.Code}
              />
              {mode != "view" && (
                <div style={{ marginTop: "4px", width: "10%" }}>
                  <Button
                    Icon={true}
                    value={
                      <button
                        onClick={(e) => setShowTree(true)}
                        type="button"
                        className="checkButton d-flex align-items-center justify-content-center"
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={[ICON_TYPE, "diagram-project"]}
                          size="1x"
                        />
                      </button>
                    }
                    btnType="primary"
                    onClick={(e) => setShowTree(true)}
                  />
                </div>
              )}
            </div>
            {/* <div className="col-lg-3">
              <UnitDictionary
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                value={detailWarehouseData?.UnitIn1Id}
                label="UnitIn1Id"
                name="UnitIn1Id"
                required={false}
                // resetForm={resetForm}
                setUnitDictionary={(e) => {
                  setDictionaryName({ ...dictionaryName, UnitIn1: e.Value });
                }}
              />
            </div> */}
            {/* <div className="col-lg-3">
              <UnitDictionary
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                value={detailWarehouseData?.UnitIn2Id}
                label="UnitIn2Id"
                name="UnitIn2Id"
                required={false}
                // resetForm={resetForm}
                setUnitDictionary={(e) => {
                  setDictionaryName({ ...dictionaryName, UnitIn2: e.Value });
                }}
              />
            </div> */}
            <div className="col-lg-3">
              <UnitDictionary
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                value={detailWarehouseData?.UnitOut1Id}
                label="UnitOut1"
                name="UnitOut1Id"
                required={false}
                resetForm={resetForm}
                setUnitDictionary={(e) => {       
                  setDictionaryNameUnit({ ...dictionaryNameUnit, UnitOut1: e?.Value });
                }}
                mode={mode}

              />
            </div>
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QtyOut1"
                textboxType="text"
                labelText="QtyOut1"
                value={detailWarehouseData?.QtyOut1}
                defaultValue={detailWarehouseData?.QtyOut1+''}
                mode={mode}

              />
            </div>
        
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QtyIn1"
                textboxType="text"
                labelText="QtyIn1"
                value={detailWarehouseData?.QtyIn1}
                defaultValue={detailWarehouseData?.QtyIn1}
              />
            </div> */}
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QtyIn2"
                textboxType="text"
                labelText="QtyIn2"
                value={detailWarehouseData?.QtyIn2}
                defaultValue={detailWarehouseData?.QtyIn2}
                mode={mode}

              />
            </div> */}
        {detailWarehouseData?.UnitOut2Id &&
        <>
            <div className="col-lg-3">
              <UnitDictionary
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                value={detailWarehouseData?.UnitOut2Id}
                label="UnitOut2"
                name="UnitOut2Id"
                required={false}
                 resetForm={resetForm}
                setUnitDictionary={(e) => {
                  setDictionaryNameUnit({ ...dictionaryNameUnit, UnitOut2: e?.Value });
                }}
                mode={mode}

              />
            </div>
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QtyOut2"
                textboxType="text"
                labelText="QtyOut2"
                value={detailWarehouseData?.QtyOut2}
                defaultValue={detailWarehouseData?.QtyOut2+''}
                mode={mode}

              />
            </div>
            </>
            }
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QtySource"
                textboxType="text"
                labelText="QtySource"
                value={detailWarehouseData?.QtySource}
                defaultValue={detailWarehouseData?.QtySource}
              />
            </div> */}
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="ItemValue"
                textboxType="text"
                labelText="ItemValue"
                onChange={(e) => {
                  setDetailWarehouseData({...detailWarehouseData,ItemValue:+e.target.value,ItemValueOut:
                    +e.target.value+detailWarehouseData.WageCost
                  })
                }}
                value={detailWarehouseData?.ItemValue}
                defaultValue={detailWarehouseData?.ItemValue}
                mode={mode}

              />
            </div>
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="ItemValueIn"
                textboxType="text"
                labelText="ItemValueIn"
                mode={"view"}
                value={detailWarehouseData?.ItemValueIn ?? totalValueIn}
                defaultValue={detailWarehouseData?.ItemValueIn ?? totalValueIn + ""}
              />
            </div> */}
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="ItemValueOut"
                textboxType="text"
                labelText="ItemValueOut"
                value={detailWarehouseData?.ItemValueOut}
                defaultValue={detailWarehouseData?.ItemValueOut}
                mode={'view'}
                onChange={(e)=> setDetailWarehouseData({...detailWarehouseData,ItemValue:+e.target.value,ItemValueOut:
                  +e.target.value
                })}
                

              />
            </div>
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="DeliveryCost"
                textboxType="text"
                labelText="DeliveryCost"
                onChange={(e) => {
                  setTotalValueIn(totalValueIn + +e.target.value);
                }}
                value={detailWarehouseData?.DeliveryCost}
                defaultValue={detailWarehouseData?.DeliveryCost}
              />
            </div> */}
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="OtherCost"
                textboxType="text"
                labelText="OtherCost"
                onChange={(e) => {
                  setTotalValueIn(totalValueIn + +e.target.value);
                }}
                value={detailWarehouseData?.OtherCost}
                defaultValue={detailWarehouseData?.OtherCost}
              />
            </div> */}
            {/* <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="OverloadCost"
                textboxType="text"
                labelText="OverloadCost"
                onChange={(e) => {
                  setTotalValueIn(totalValueIn + +e.target.value);
                }}
                value={detailWarehouseData?.OverloadCost}
                defaultValue={detailWarehouseData?.OverloadCost}
              />
            </div> */}
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="WageCost"
                textboxType="text"
                labelText="WageCost"
                onChange={(e) => {
                  setDetailWarehouseData({...detailWarehouseData,WageCost:+e.target.value,ItemValueOut:
                    +e.target.value+detailWarehouseData.ItemValue
                  })
                }}
                value={detailWarehouseData?.WageCost}
                defaultValue={detailWarehouseData?.WageCost}
                mode={mode}

              />
            </div>

            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="Description"
                textboxType="text"
                labelText="Description"
                value={detailWarehouseData?.Description}
                defaultValue={detailWarehouseData?.Description}
                mode={mode}

              />
            </div>
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="DescriptionEN"
                textboxType="text"
                labelText="DescriptionEN"
                value={detailWarehouseData?.DescriptionEN}
                defaultValue={detailWarehouseData?.DescriptionEN}
                mode={mode}

              />
            </div>
            <div className="col-lg-3">
              <Textbox
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                textboxName="QCNo"
                textboxType="text"
                labelText="QCNo"
                value={detailWarehouseData?.QCNo}
                defaultValue={detailWarehouseData?.QCNo}
                mode={mode}

              />
            </div>
            <div className="w-100"></div>
            {/* <div className="col-12 col-lg-3">
              <CheckableButton
                defaultChecked={detailWarehouseData?.Calculated}
                defaultValue={detailWarehouseData?.Calculated}
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                labelText={"Calculated"}
                inputName={"Calculated"}
                type="checkbox"
                mode={mode}
              />
            </div>
            <div className="col-12 col-lg-3">
              <CheckableButton
                defaultChecked={detailWarehouseData?.CalculatedBySystem}
                defaultValue={detailWarehouseData?.CalculatedBySystem}
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                labelText={"CalculatedBySystem"}
                inputName={"CalculatedBySystem"}
                type="checkbox"
                mode={mode}
              />
            </div> */}
            {/* <div className="col-12 col-lg-3">
              <CheckableButton
                defaultChecked={detailWarehouseData?.CalculatedByAlteration}
                defaultValue={detailWarehouseData?.CalculatedByAlteration}
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                labelText={"CalculatedByAlteration"}
                inputName={"CalculatedByAlteration"}
                type="checkbox"
                mode={mode}
              />
            </div> */}
            {/* <div className="col-12 col-lg-3">
              <CheckableButton
                defaultChecked={detailWarehouseData?.ManualRegistered}
                defaultValue={detailWarehouseData?.ManualRegistered}
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                labelText={"ManualRegistered"}
                inputName={"ManualRegistered"}
                type="checkbox"
                mode={mode}
              />
            </div> */}
          </div>
          <>
            {mode != "view" ? (
              <div className="col-lg-12 mt-1 btns_form">
                <Button value="save" btnType="submit" />
                <Button btnType="cancel" value="Cancel" onClick={() => {onHide()}} />
              </div>
            ) : (
              <div className="col-lg-12 mt-1 btns_form">
                <Button btnType="primary" value="close" onClick={() =>onHide()} />
              </div>
            )}
          </>
        </form>
      </div>
    </div>
  );
};
