export const ENDPOINTS = {
  application: {
    browse: "Application",
    dictionary: "Application/Dictionary",
  },
  menu: "Menu",
  dataSource: "DataSource",
  SharingCenterArticleUserApi: {
    browse: "SharingCenterArticleUser/ArticleList",
    load: "SharingCenterArticleUser",
    attachment: "SharingCenterArticleUser/Attachment",
  },
  BaseDefinitionAndSetting: {
    Country: {
      browse: "BaseDefinitionAndSettingApi/Country",
      load: "BaseDefinitionAndSettingApi/Country/id",
      dictionary: "BaseDefinitionAndSettingApi/Country/Dictionary",
      dictionaryId: "BaseDefinitionAndSettingApi/Country/Dictionary/Id",
    },
    Province: {
      browse: "BaseDefinitionAndSettingApi/Province",
      load: "BaseDefinitionAndSettingApi/Province/id",
      dictionary: "BaseDefinitionAndSettingApi/Province/Dictionary",
      dictionaryId: "BaseDefinitionAndSettingApi/Province/Dictionary/id",
    },
    City: {
      browse: "BaseDefinitionAndSettingApi/City",
      load: "BaseDefinitionAndSettingApi/City/id",
      dictionary: "BaseDefinitionAndSettingApi/City/Dictionary",
    },
  },
  SharingCenterApi: {
    SharingCenterArticleClassification: {
      browse: "SharingCenterApi/SharingCenterArticleClassification",
      load: "SharingCenterApi/SharingCenterArticleClassification/id",
      dictionary:
        "SharingCenterApi/SharingCenterArticleClassification/Dictionary",
    },
    SharingCenter: {
      browse: "SharingCenterApi/SharingCenterArticle",
      load: "SharingCenterApi/SharingCenterArticle/List",
      endDate: "SharingCenterApi/SharingCenterArticle/EndDate",
      id: "SharingCenterApi/SharingCenterArticle/id",
      file: "SharingCenterApi/SharingCenterArticle/Attachment/File",
      attachment: "SharingCenterApi/SharingCenterArticle/Attachment",
      access: "SharingCenterApi/SharingCenterArticle/Access",
    },
  },

  afterSalesApi: {
    browse: "AfterSalesApi/AfterSale/CustomerRequests",
    history: "AfterSalesApi/AfterSale/CustomerRequests/History",
    transfer: "AfterSalesApi/AfterSale/CustomerRequests/Transfer",
    stop: "AfterSalesApi/AfterSale/ForceStop",
    insertNote: "AfterSalesApi/AfterSale/InsertNote",
    userId: "AfterSalesApi/AfterSale/Requester/User",
    customerInfo: "AfterSalesApi/AfterSale/CustomerInfo",
    customerInProgressRequests:
      "AfterSalesApi/AfterSale/CustomerInProgressRequests",
    CurrentReferencePositions:
      "AfterSalesApi/AfterSale/Dictionary/CurrentReferencePositions",
    postRefrence: "AfterSalesApi/AfterSale/Reference/Special",
    postReferenceList: "AfterSalesApi/AfterSale/Reference/List/Special",
    customerList: "AfterSalesApi/AfterSale/Customers",
    detailCode: "AfterSalesApi/AfterSale/Customers/GetDetailCode",
    addUserWithDetailCode:
      "AfterSalesApi/AfterSale/Customers/AddUserWithDetailCode",

    deviceProperty: {
      browse: "AfterSalesApi/DeviceProperty",
      list: "AfterSalesApi/DeviceProperty/List",
      load: "AfterSalesApi/DeviceProperty/id",
      dictionary: "AfterSalesApi/DeviceProperty/GetDictionaryList",
    },
    DeviceDelivery: {
      browse: "AfterSalesApi/DeviceDelivery",
      list: "AfterSalesApi/DeviceDelivery/GetList",
      load: "AfterSalesApi/DeviceDelivery/id",
      dictionary: "AfterSalesApi/DeviceDelivery/GetDictionaryList",
    },
    customerServiceRequestClassification: {
      browse: "AfterSalesApi/CustomerServiceRequestClassification",
      load: "AfterSalesApi/CustomerServiceRequestClassification/id",
      dictionary:
        "AfterSalesApi/CustomerServiceRequestClassification/Dictionary",
    },
    PaymentCoverageType: {
      browse: "AfterSalesApi/PaymentCoverageType",
      load: "AfterSalesApi/PaymentCoverageType/id",
      dictionary: "AfterSalesApi/PaymentCoverageType/Dictionary",
    },
    CustomerGeneralRequestClassification: {
      browse: "AfterSalesApi/CustomerGeneralRequestClassification",
      load: "AfterSalesApi/CustomerGeneralRequestClassification/id",
      dictionary:
        "AfterSalesApi/CustomerGeneralRequestClassification/Dictionary",
    },
  },
  databases: {
    browse: "Databases",
    load: "Databases/id ",
  },
  report: {
    browse: "Report",
    load: "Report/id ",
    groupedReports: "Report/GetGroupedReports ",
    reportFeed: "Report/UpdateReportFeed ",
  },

  bpmnApi: {
    process: {
      getAll: "BPMNApi/Process/CurrentPosition/Dictionary/All",
      getStarting: "BPMNApi/Process/CurrentPosition/StartUpProcess",
      getStartForm: "BPMNApi/Process/CurrentPosition/StartForm",
      getViewForm: "BPMNApi/Process/CurrentPosition/StartForm",
      getProcessTasks: "BPMNApi/Process/ProjectsAndTasks", // Projects => ~ Process
      exportProcessMakerFile: "BPMNApi/Process/ExportProcessMakerFile",
      postProcessesFiles: "BPMNApi/Process/ImportProcessMakerFiles",
      getProcessId: "BPMNApi/Process/ProcessId",
      getProcessDictionaryAll: "BPMNApi/Process/Dictionary/All",
    },
    TaskUser: {
      browse: "BPMNApi/Bpmn_Task_User",
      dictionary: "BPMNApi/Bpmn_Task_User/Dictionary",
    },
    Task: {
      browse: "BPMNApi/Bpmn_Task",
      dictionary: "BPMNApi/Bpmn_Task/Dictionary",
    },
    bpmnProcessIcon: {
      browse: "BPMNApi/BpmnProcessSetting",
      load: "BPMNApi/BpmnProcessSetting/AssignIconToProcess",
    },
    bpmnProcessApplication: {
      delete: "BPMNApi/BpmnProcessApplication",
      load: "BPMNApi/BpmnProcessApplication/AssignApplicationToProcess",
      browse: "BPMNApi/BpmnProcessApplication/Dictionary",
    },
  },

  messageHub: {
    browse: "/ChatFile/GetFile",
    tokenFile: "/ChatFile/GetFileToken",
    load: "/ChatFile/Send ",
  },
  chatWeb: {
    appSettings: {
      load: "/api/AppSettings ",
      resetCache: "/api/AppSettings/ResetCache ",
    },
  },
  Chatuser: {
    userInfo: "/UserInfo/username",
  },

  Widget: {
    bords: "Widget/Widgets/BulletinBoard",
    widgetListName: "Widget/Widgets",
    bord: "Widget/Widgets/BulletinBoard/Id",
    dayMessage: "Widget/Widgets/DayMessage",
    dayEvents: "Widget/Widgets/DayEvents",
    weekEvents: "Widget/Widgets/WeekEvents",
    personalTask: "Widget/Widgets/PersonalTasks",
    dayInfo: "Widget/Widgets/DayInfo",
    bordAttachment: "Widget/Widgets/BulletinBoard/Attachment",
    personDayEvents: "Widget/PersonDayEvents",
  },
  bulletinBoard: {
    browse: "BulletinBoardApi/BulletinBoard",
    load: "BulletinBoardApi/BulletinBoard/List",
    endDate: "BulletinBoardApi/BulletinBoard/EndDate",
    id: "BulletinBoardApi/BulletinBoard/id",
    file: "BulletinBoardApi/BulletinBoard/Attachment/File",
    fileUrl: "BulletinBoardApi/BulletinBoard/Attachment/Url",
    attachment: "BulletinBoardApi/BulletinBoard/Attachment",
    access: "BulletinBoardApi/BulletinBoard/Access",
  },
  personalPlanningApi: {
    personalAction: {
      browse: "PersonalPlanningApi/PersonalAction",
      load: "PersonalPlanningApi/PersonalAction/id",
      Attachment: "PersonalPlanningApi/PersonalAction/Attachment",
    },
    PersonalTask: {
      browse: "PersonalPlanningApi/PersonalTask",
      load: "PersonalPlanningApi/PersonalTask/id",
      Attachment: "PersonalPlanningApi/PersonalTask/Attachment",
      status: "PersonalPlanningApi/PersonalTask/status",
    },
  },

  icon: "Icon",
  tadbirAuthorizeApi: {
    dictionary: "TadbirAuthorizeApi/Role/Dictionary",
    organizationRole: {
      browse: "TadbirAuthorizeApi/OrganizationRole/UnAssignedRoles/Dictionary",
      load: "TadbirAuthorizeApi/OrganizationRole/List",
      dictionary:
        "TadbirAuthorizeApi/OrganizationRole/AssignedRoles/Dictionary",
    },
    positionRole: {
      browse: "TadbirAuthorizeApi/PositionRole/AssignedRoles",
      load: "TadbirAuthorizeApi/PositionRole/id",
      dictionary: "TadbirAuthorizeApi/PositionRole/UnAssignedRoles/Dictionary",
    },
  },
  resources: "Resource",
  enumeration: "EnumerationApi/Enumeration",
  enumTypes: "EnumerationApi/Enumeration/EnumTypes",

  archive: {
    personalArchive: {
      getId: "ArchiveApi/PersonalArchive/id",
      browse: "ArchiveApi/PersonalArchive",
      dictionary: "ArchiveApi/PersonalArchive/Dictionary",
      archives: "ArchiveApi/PersonalArchive/PersonalArchiveWithDetailById",
    },
    organizationalArchiveAccess: {
      browse: "ArchiveApi/OrganizationalArchiveAccess",
    },
    JobOrganizationalArchiveDetail: {
      browse: "ArchiveApi/JobOrganizationalArchiveDetail",
      search: "ArchiveApi/JobOrganizationalArchiveDetail/Search",
      load: "ArchiveApi/JobOrganizationalArchiveDetail/id",
      attachment: "ArchiveApi/JobOrganizationalArchiveDetail/Attachment",
      fileList: "ArchiveApi/JobOrganizationalArchiveDetail/Attachment/List",
      attachmentText:
        "ArchiveApi/JobOrganizationalArchiveDetail/Attachment/Text",
      help: "ArchiveApi/JobOrganizationalArchiveDetail/Attachment/OCR/Help",
      file: "ArchiveApi/JobOrganizationalArchiveDetail/Attachment/File",
      removeArchivedJob: "ArchiveApi/JobOrganizationalArchiveDetail/jobId",
    },
    JobPersonalArchiveDetail: {
      browse: "ArchiveApi/JobPersonalArchiveDetail",
      load: "ArchiveApi/JobPersonalArchiveDetail/id",
    },
    organizationalArchive: {
      browse: "ArchiveApi/OrganizationalArchive",
      Tree: "ArchiveApi/OrganizationalArchive/Tree",
      load: "ArchiveApi/OrganizationalArchive/id",
      dictionary: "ArchiveApi/OrganizationalArchive/Dictionary",
      archives:
        "ArchiveApi/OrganizationalArchive/OrganizationalArchiveWithDetailById",
    },
    organizationalArchiveDetail: {
      browse: "ArchiveApi/OrganizationalArchiveDetail",
      load: "ArchiveApi/OrganizationalArchiveDetail/id",
      savedArchive: "ArchiveApi/OrganizationalArchiveDetail/jobId",
      dictionary: "ArchiveApi/OrganizationalArchiveDetail/Dictionary",
    },
    personalArchiveDetail: {
      browse: "ArchiveApi/personalArchiveDetail",
      load: "ArchiveApi/personalArchiveDetail/id",
      dictionary: "ArchiveApi/personalArchiveDetail/Dictionary",
    },
    documentArchive: {
      browse: "ArchiveApi/DocumentArchiveRequest",
      load: "ArchiveApi/DocumentArchiveRequest/id",
      referenceId: "ArchiveApi/DocumentArchiveRequest/referenceId",
      attachment: "ArchiveApi/DocumentArchiveRequest/Attachment",
      attachmentText: "ArchiveApi/DocumentArchiveRequest/Attachment/Text",
    },
    documentArchiveRequestClassification: {
      browse: "ArchiveApi/DocumentArchiveRequestClassification",
      load: "ArchiveApi/DocumentArchiveRequestClassification/id",
      attachment: "ArchiveApi/DocumentArchiveRequestClassification/Attachment",
      dictionary: "ArchiveApi/DocumentArchiveRequestClassification/dictionary",
    },
  },
  userApi: {
    userId: "UserApi/UserManagement",
    personUser: "BaseInformationApi/Person/PersonUser",
    userList: "UserApi/UserManagement",
    users: "UserApi/UserManagement/Users",

    reset: "UserApi/UserManagement/ResetPassword",
    lockout: "UserApi/UserManagement/ResetLockout",
  },
  UserAuthorize: {
    assignmentRole: "UserApi/UserAuthorize/AssignmentRole",
  },
  profileApi: {
    profile: "ProfileApi/Profile/Profile",
    ChangePassword: "ProfileApi/Profile/Profile/ChangePassword",
    userInfo: "ProfileApi/Profile/Profile/UserInfo",
  },
  BaseInformation: {
    person: {
      browse: "BaseInformationApi/Person",
      all: "BaseInformationApi/Person/All",

      load: "BaseInformationApi/Person/id",
      email: "BaseInformationApi/Person/DefaultEmail/Id",

      dictionary: "BaseInformationApi/Person/DictionaryByType",
      dictionaryId: "BaseInformationApi/Person/Dictionary",
      DictionaryOrganization:
        "BaseInformationApi/Person/DictionaryByOrganizationType",
      chatUserList: "BaseInformationApi/Person/Chat/Users",
      updatePicture: "BaseInformationApi/Person/Picture",
      searchByNumber: "BaseInformationApi/Person/SearchByPhoneOrMobile",
    },
    PersonContact: {
      Load: "BaseInformationApi/PersonContact",
      default: "BaseInformationApi/CustomerPosition/SummaryInfo",
      landLine: "BaseInformationApi/CustomerPosition/LandlineDictionary",
      mobile: "BaseInformationApi/CustomerPosition/MobileDictionary",
      addresses: "BaseInformationApi/PersonContact/Addresses",
      dictionary: "BaseInformationApi/PersonContact/Dictionary",
      phones: "BaseInformationApi/PersonContact/PhonesDictionary",
      brows: "BaseInformationApi/CustomerPosition/Contact",
      nationalEcoCode: "BaseInformationApi/CustomerPosition/NationalEcoCode",
    },
    CurrentPersonContact: {
      Load: "BaseInformationApi/CurrentPersonContact",
      default: "BaseInformationApi/CurrentPersonContact/Default",
      addresses: "BaseInformationApi/CurrentPersonContact/Addresses",
      dictionary: "BaseInformationApi/CurrentPersonContact/Dictionary",
      phones: "BaseInformationApi/CurrentPersonContact/PhonesDictionary",
    },

    PersonRealTitle: {
      browse: "BaseDefinitionAndSettingApi/PersonRealTitle",
      load: "BaseDefinitionAndSettingApi/PersonRealTitle/id",
      dictionary: "BaseDefinitionAndSettingApi/PersonRealTitle/dictionary",
    },
    PersonSignature: {
      browse: "BaseInformationApi/PersonSignature",
      load: "BaseInformationApi/PersonSignature/Default",
      list: "BaseInformationApi/PersonSignature/List",
      default: "BaseInformationApi/PersonSignature/SetDefault",
      active: "BaseInformationApi/PersonSignature/SetActive",
      inactive: "BaseInformationApi/PersonSignature/SetInactive",
      delete: "BaseInformationApi/PersonSignature",
    },
    PersonLegalTitle: {
      browse: "BaseDefinitionAndSettingApi/PersonLegalTitle",
      load: "BaseDefinitionAndSettingApi/PersonLegalTitle/id",
      dictionary: "BaseDefinitionAndSettingApi/PersonLegalTitle/dictionary",
    },
    postLevel: {
      browse: "BaseDefinitionAndSettingApi/PostLevel",
      load: "BaseDefinitionAndSettingApi/PostLevel/id",
      dictionary: "BaseDefinitionAndSettingApi/PostLevel/dictionary",
    },
    position: {
      load: "BaseInformationApi/Position/organizationId",
      getId: "BaseInformationApi/Position/Id",
      getPosition: "BaseInformationApi/Position/personId",
      browse: "BaseInformationApi/Position",

      dictionaryList: "BaseInformationApi/Position/Dictionary",
      dictionaryWithAcc: "BaseInformationApi/Position/DictionaryWithAccDetails",
      dictionary: "BaseInformationApi/Position/Dictionary/Id",
      substitute: "BaseInformationApi/Position/Substitute",
      substituteId: "BaseInformationApi/Position/Substitute/id",
      userId: "BaseInformationApi/Position/User",
    },
    groupPosition: {
      browse: "BaseInformationApi/GroupPosition",
      browsedefaults: "BaseInformationApi/GroupPosition/DefaultGroups",

      load: "BaseInformationApi/GroupPosition/id",
      details: "BaseInformationApi/GroupPosition/Details",
      defaultGroupsdetails: "BaseInformationApi/GroupPosition/Details",
      dictionary: "BaseInformationApi/GroupPosition/Dictionary",
    },
    organization: {
      browse: "BaseInformationApi/Organization/GetFullyLoadTree",
      canAdd: "BaseInformationApi/Organization/canAddChildTo",
      getId: "BaseInformationApi/Organization/id",
      byType: "BaseInformationApi/Organization/OrganizationsByType",
      load: "BaseInformationApi/Organization/AddNodeToParent",
      edit: "BaseInformationApi/Organization/UpdateNode",
      getChildren: "BaseInformationApi/Organization/GetChildren/",
      getNode: "BaseInformationApi/Organization/GetNodeByParentId/",
      deleteNode: "BaseInformationApi/Organization/DeleteNode",
      moveNode: "BaseInformationApi/Organization/move",
    },
  },
  cartable: {
    base: "CartableApi",
    browse: "CartableApi/Cartable",
    load: "CartableApi/Cartable/id",
    delete: "CartableApi/Cartable/ReferenceId",
    getDynamicForms: "CartableApi/Cartable/DynamicFormsByReferenceId",
    undo: "CartableApi/Cartable/ReferenceUndo",
    accept: "CartableApi/Cartable/ReferenceAccept",
    terminated: "CartableApi/Cartable/ReferenceTerminated",
    cancelTerminated: "CartableApi/Cartable/ReferenceTerminatedCancel",
    reject: "CartableApi/Cartable/JobReject",
    requester: "CartableApi/Cartable/Requester/User",
    JobReferrer: "CartableApi/Cartable/Referrer/User",
    ReferencesActions: "CartableApi/Cartable/ReferencesActions",
    ReferencesTree: "CartableApi/Cartable/ReferencesTree",
    attachment: "CartableApi/Cartable/Attachment",
    attachmentFile: "CartableApi/Cartable/Attachment/File",
    DeleteFile: "CartableApi/Cartable/Attachment_DeleteFile",
    ongoing: "CartableApi/Cartable/ReferenceOngoing",
    hasFollowUp: "CartableApi/Cartable/ReferenceHasFollowUp",
    setJobInProgress: "CartableApi/Cartable/SetJobInProgress",
    myRequests: "CartableApi/Cartable/MyRequests",
    notifications: "CartableApi/Cartable/CurrentNotSeenCount",
    notificationsDictionary:
      "CartableApi/Cartable/CurrentNotSeenCount/Dictionary",
    refer: {
      load: "CartableApi/Cartable/Refer",
      reverse: "CartableApi/Cartable/ReferenceReverse",
      reverseList: "CartableApi/Cartable/ReferenceReverse/List",
    },
    jobDelivery: {
      load: "CartableApi/JobDelivery",
    },
    action: {
      browse: "CartableApi/Action",
      load: "CartableApi/Action/id",
      jobActions: "CartableApi/Action/JobActionList",
      attachments: "CartableApi/Action/Attachment",
      loadAttachment: "CartableApi/Action/FileAttachment",
      DeleteFile: "CartableApi/Action/Attachment_DeleteFile",
    },
    signature: {
      browse: "CartableApi/Signature",
      load: "CartableApi/Signature/id",
    },
    sendMethod: {
      browse: "BaseDefinitionAndSettingApi/SendMethod",
      load: "BaseDefinitionAndSettingApi/SendMethod/id",
      dictionary: "BaseDefinitionAndSettingApi/SendMethod/dictionary",
    },
    JobSend: {
      browse: "CartableApi/JobSentHistory",
      load: "CartableApi/JobSentHistory/id",
    },
    job: {
      browse: "CartableApi/Job",
      jobId: "CartableApi/Job/id",
      load: "CartableApi/Job/referenceId",
      jobList: "CartableApi/Job/list",
      stop: "CartableApi/Job/ForceStop",
      DictionaryReference: "CartableApi/Job/Dictionary/Reference",

      // load: "CartableApi/Job/id"
    },
    GeneralRequest: {
      browse: "CartableApi/GeneralRequest",
      GeneralRequestId: "CartableApi/GeneralRequest/id",
      load: "CartableApi/GeneralRequest/referenceId",
      attachment: "CartableApi/JoGeneralRequestb/Attachment",
      DeleteFile: "CartableApi/GeneralRequest/Attachment_DeleteFile",
      GeneralRequestList: "CartableApi/GeneralRequest/list",
    },
    GeneralRequestClassification: {
      browse: "BaseDefinitionAndSettingApi/GeneralRequestClassification",
      load: "BaseDefinitionAndSettingApi/GeneralRequestClassification/id",
      dictionary:
        "BaseDefinitionAndSettingApi/GeneralRequestClassification/Dictionary",
    },

    jobInvoice: {
      browse: "CartableApi/JobInvoice",
      load: "CartableApi/JobInvoice/id",
      dictionary: "CartableApi/JobInvoice/Dictionary",
    },
    reference: {
      browse: "CartableApi/Reference/list",
      postReference: "CartableApi/Reference",
      attachments: "CartableApi/Reference/Attachment",
      DeleteFile: "CartableApi/Reference/Attachment_DeleteFile",
      actions: "CartableApi/Reference/ReferenceAndActionsById",
    },
    referenceSubject: {
      browse: "BaseDefinitionAndSettingApi/ReferenceSubject",
      load: "BaseDefinitionAndSettingApi/ReferenceSubject/id",
      dictionary: "BaseDefinitionAndSettingApi/ReferenceSubject/dictionary",
    },
    DailyLeaveRequestClassification: {
      browse: "BaseDefinitionAndSettingApi/DailyLeaveRequestClassification",
      load: "BaseDefinitionAndSettingApi/DailyLeaveRequestClassification/id",
      dictionary:
        "BaseDefinitionAndSettingApi/DailyLeaveRequestClassification/dictionary",
    },
    dailyLeave: {
      browse: "CartableApi/DailyLeaveRequest",
      load: "CartableApi/DailyLeaveRequest/id",
      PrintData: "CartableApi/DailyLeaveRequest/PrintData",
      leaveDaysRemains: "CartableApi/DailyLeaveRequest/LeaveDaysRemains",
      referenceId: "CartableApi/DailyLeaveRequest/referenceId",
    },
    HourlyLeave: {
      browse: "CartableApi/HourlyLeaveRequest",
      load: "CartableApi/HourlyLeaveRequest/id",
      PrintData: "CartableApi/HourlyLeaveRequest/PrintData",
      referenceId: "CartableApi/HourlyLeaveRequest/referenceId",
    },
    PersonnelCartable: {
      browse: "PersonnelApi/PersonnelCartable",
      dictionary: "PersonnelApi/PersonnelCartable/Subordinates/Dictionary",
      attachmentDetail: "PersonnelApi/PersonnelCartable/Attachment/List/Detail",
      attachment: "PersonnelApi/PersonnelCartable/Attachment",
      JobAndReference: "PersonnelApi/PersonnelCartable/JobAndReference",
    },
  },
  clientPortal: {
    startUpProcess: "ClientPortalApi/ClientPortal/StartUpProcess", //dictionary
    startForm: "ClientPortalApi/ClientPortal/StartForm", //dictionary
    chat: "ClientPortalApi/ClientPortal/Chat/CommunicationWithCustomersMembers", //dictionary
    Responsible: "ClientPortalApi/ClientPortal/FollowupResponsible/User",
    feedBack: "ClientPortalApi/ClientPortal/CustomerFeedBack",

    customerServiceRequest: {
      browse: "ClientPortalApi/customerServiceRequest",
      load: "ClientPortalApi/customerServiceRequest/id",
      reference: "ClientPortalApi/customerServiceRequest/referenceId",
      list: "ClientPortalApi/customerServiceRequest/list",
      detail: "ClientPortalApi/customerServiceRequest/Detail/List",
      attachment: "ClientPortalApi/customerServiceRequest/Attachment",
      DeleteFile:
        "ClientPortalApi/customerServiceRequest/Attachment_DeleteFile",
      reply: "ClientPortalApi/customerServiceRequest/Reply",
      cancel: "ClientPortalApi/customerServiceRequest/Cancel",
      serials: "ClientPortalApi/customerServiceRequest/CustomerSerials",
      items: "ClientPortalApi/customerServiceRequest/CustomerItems",
    },
    clientPortal: {
      browse: "ClientPortalApi/ClientPortal",
      load: "ClientPortalApi/ClientPortal/id",
      reference: "ClientPortalApi/ClientPortal/referenceId",
      list: "ClientPortalApi/ClientPortal/list",
      detail: "ClientPortalApi/ClientPortal/Detail/List",
      attachment: "ClientPortalApi/ClientPortal/Attachment",
      DeleteFile: "ClientPortalApi/ClientPortal/Attachment_DeleteFile",
      reply: "ClientPortalApi/ClientPortal/Reply",
      resend: "ClientPortalApi/ClientPortal/Resend",
      cancel: "ClientPortalApi/ClientPortal/Cancel",
      feedBack: "ClientPortalApi/ClientPortal/CustomerFeedBack",
      serials: "ClientPortalApi/ClientPortal/CustomerSerials",
      items: "ClientPortalApi/ClientPortal/CustomerItems",
      validate: "ClientPortalApi/ClientPortal/Validate/RequesterPosition",
      CustomerPositionList:
        "ClientPortalApi/ClientPortal/CustomerPosition/Dictionary",
      CustomerPositionId:
        "ClientPortalApi/ClientPortal/CustomerPosition/Dictionary/PositionId",
      followupResponsible:
        "ClientPortalApi/ClientPortal/FollowupResponsible/Dictionary",
    },
    CustomerGeneralRequest: {
      browse: "ClientPortalApi/CustomerGeneralRequest",
      load: "ClientPortalApi/CustomerGeneralRequest/id",
      reference: "ClientPortalApi/CustomerGeneralRequest/referenceId",
      list: "ClientPortalApi/CustomerGeneralRequest/list",
      detail: "ClientPortalApi/CustomerGeneralRequest/Detail/List",
      attachment: "ClientPortalApi/CustomerGeneralRequest/Attachment",
      DeleteFile:
        "ClientPortalApi/CustomerGeneralRequest/Attachment_DeleteFile",
      reply: "ClientPortalApi/CustomerGeneralRequest/Reply",
      cancel: "ClientPortalApi/CustomerGeneralRequest/Cancel",
      feedBack: "ClientPortalApi/CustomerGeneralRequest/CustomerFeedBack",
      serials: "ClientPortalApi/CustomerGeneralRequest/CustomerSerials",
      items: "ClientPortalApi/CustomerGeneralRequest/CustomerItems",
    },
  },
  secretariat: {
    indicatorPatterns: {
      browse: "SecretariatApi/IndicatorPatterns",
      dictionary: "SecretariatApi/IndicatorPatterns/Dictionary",
      load: "SecretariatApi/IndicatorPatterns/id",
    },
    SecretariatIndicatorPattern: {
      browse: "SecretariatApi/SecretariatIndicatorPattern",
      dictionary: "SecretariatApi/SecretariatIndicatorPattern/Dictionary",
      load: "SecretariatApi/SecretariatIndicatorPattern/id",
    },
    secretariat: {
      browse: "SecretariatApi/Secretariat",
      dictionary: "SecretariatApi/Secretariat/Dictionary",
      dictionaryAll: "SecretariatApi/Secretariat/Dictionary/All",
      load: "SecretariatApi/Secretariat/id",
      allowUpdate: "SecretariatApi/Secretariat/AllowUpdate",
    },
    secretariatAccess: {
      browse: "SecretariatApi/SecretariatAccess",
      dictionary: "SecretariatApi/SecretariatAccess/Dictionary",
      load: "SecretariatApi/SecretariatAccess/id",
    },
    letterSubject: {
      browse: "SecretariatApi/LetterSubject",
      load: "SecretariatApi/LetterSubject/id",
      dictionary: "SecretariatApi/LetterSubject/Dictionary",
    },
    letterReceiver: {
      browse: "SecretariatApi/LetterReceiver",
      load: "SecretariatApi/LetterReceiver/id",
      dictionary: "SecretariatApi/LetterReceiver/Dictionary",
      dictionaryId: "SecretariatApi/LetterReceiver/Dictionary/id",
      receiverDictionary: "SecretariatApi/LetterReceiver/Dictionary/JobId",
    },
    letterSignature: {
      browse: "SecretariatApi/LetterSignature",
      load: "SecretariatApi/LetterSignature/id",
    },
    letter: {
      browse: "SecretariatApi/Letter/list",
      chooseRelated: "SecretariatApi/Letter/list/chooseRelated",
      reference: "SecretariatApi/Letter/referenceId",
      letter: "SecretariatApi/Letter",
      defaultAttachment: "SecretariatApi/Letter/DefaultAttachment",
      defaultFileAttachment: "SecretariatApi/Letter/DefaultFileAttachment",
      attachment: "SecretariatApi/Letter/Attachment",
      DeleteFile: "SecretariatApi/Letter/Attachment_DeleteFile",
    },
    indicator: {
      browse: "SecretariatApi/Indicator/list",
      reserved: "SecretariatApi/Indicator/Reserved",
      loadLetter: "SecretariatApi/Indicator/LetterId",
      dictionaryReserved: "SecretariatApi/Indicator/Dictionary/Reserved",
      chooseRelated: "SecretariatApi/Indicator/list/chooseRelated",
      load: "SecretariatApi/Indicator/id",
      reference: "SecretariatApi/Indicator/referenceId",
      indicator: "SecretariatApi/Indicator",
      related: "SecretariatApi/Indicator/Relatedlist",
      secretariatNo: "SecretariatApi/Indicator/NewIndicator/secretariatId",
      defaultAttachment: "SecretariatApi/Indicator/DefaultAttachment",
      defaultFileAttachment: "SecretariatApi/Indicator/DefaultFileAttachment",
      attachmentFile: "SecretariatApi/Indicator/Attachment/File",
      attachment: "SecretariatApi/Indicator/Attachment",
      DeleteFile: "SecretariatApi/Indicator/Attachment_DeleteFile",
      PrintData: "SecretariatApi/Indicator/PrintData",
    },
    letterSent: {
      browse: "SecretariatApi/LetterSentHistory",
      load: "SecretariatApi/LetterSentHistory/id",
      list: "SecretariatApi/LetterSentHistory/List",
    },
  },

  calendar: {
    calendar: {
      browse: "CalendarApi/Calendar",
      load: "CalendarApi/Calendar/Day",
      year: "CalendarApi/Calendar/CurrentYear",
    },
    reminder: {
      browse: "CalendarApi/Reminder",
      load: "CalendarApi/Reminder/Dictionary",
    },
    event: {
      browse: "CalendarApi/CalendarEvent",
      load: "CalendarApi/CalendarEvent/id",
    },
    OrganizationEvents: {
      browse: "CalendarApi/CalendarOrganizationalEvent",
      load: "CalendarApi/CalendarOrganizationalEvent/id",
      Calendar: "CalendarApi/CalendarOrganizationalEvent/Calendar",
      WeekendOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/WeekendOrganizationEvents",
      GregorianOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/GregorianOrganizationEvents",
      LunarOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/LunarOrganizationEvents",
      SolarOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/SolarOrganizationEvents",
      CalendarEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/CalendarEvents",
      NationalWeekendOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/NationalWeekendOrganizationEvents",
      OrganizationalWeekendOrganizationEvents:
        "CalendarApi/CalendarOrganizationalEvent/Transfer/OrganizationalWeekendOrganizationEvents",
      Transfer:
        "CalendarApi/CalendarOrganizationalEvent/CalendarEvent/Transfer",
      Count: "CalendarApi/CalendarOrganizationalEvent/CalendarEvent/Count",
      list: "CalendarApi/CalendarOrganizationalEvent/List",
    },
    PersonalEvent: {
      browse: "CalendarApi/CalendarPersonalEvent",
      load: "CalendarApi/CalendarPersonalEvent/id",
    },
  },

  Management: {
    period: {
      browse: "ManagementApi/Period",
      load: "ManagementApi/Period/id",
      dictionary: "ManagementApi/Period/dictionary",
    },
    Branch: {
      browse: "ManagementApi/Branch",
      load: "ManagementApi/Branch/id",
      dictionary: "ManagementApi/Branch/dictionary",
    },
    JobManagement: {
      browse: "ManagementApi/JobManagement",
      jobList: "ManagementApi/JobManagement/list",
      JobEntireTree: "ManagementApi/JobManagement/JobEntireTree",
      CurrentReferencePositions:
        "ManagementApi/JobManagement/Dictionary/CurrentReferencePositions",
      postRefrence: "ManagementApi/JobManagement/Reference/Special",
      postReferenceList: "ManagementApi/JobManagement/Reference/List/Special",
      attachment: "ManagementApi/JobManagement/Attachment",
      stop: "ManagementApi/JobManagement/ForceStop",
    },
  },
  reportST: {
    designer: {
      saveData: "/ReportDesigner/SaveFeedData",
      getReport: "/ReportDesigner/GetReportByJsonData",
    },
    viewer: {
      saveData: "/ReportViewer/SaveFeedData",
      getReport: "/ReportViewer/GetReportWithJsonData",
    },
    reportGroup: {
      dictionary: "/api/ReportGroup/ReportGroupDictionary",
    },
    report: {
      brows: "/Report",
      browsTemplate: "/Report/AddTemplate",
      setefault: "/Report/Default",
      dictionary: "/Report/TemplateDictionary",
    },
  },
};

export const ENDPOINTSINVENTORYANDSALES = {
  Widget: {
    SaleWidget: "Widget/CustomerSaleWidget",
  },
  Sales: {
    PaymentMethod: {
      dictionary: "Sales/BaseDefinitionAndSettingApi/PaymentMethod/Dictionary",
    },
    CustomerSalesInvoiceApis: {
      CustomerSalesInvoice: {
        browse: "Sales/CustomerSalesInvoice",
        load:'Sales/CustomerSalesInvoice/Id',
      },
    },
    CustomersStoreApi: {
      CustomersStore: {
        browse: "Sales/CustomersStoreApi/CustomersStore/Items",
        load: "Sales/CustomersStoreApi/CustomersStore/ItemDetail",
        groupLeaves: "Sales/CustomersStoreApi/CustomersStore/ItemGroupLeaves",
      },
    },
    SalesOrderApis: {
      SalesOrder: {
        browse: "Sales/SalesOrder",
        addFirst: "Sales/SalesOrder/Workflow/AddFirst",
        load: "Sales/SalesOrder/id",
        referenceId: "Sales/SalesOrder/ReferenceId",
        Calculate: "Sales/SalesOrder/Calculate",
        nextOrderNo: "Sales/SalesOrder/NextOrderNo",
        constraint: "Sales/SalesOrder/Constraint",
        list: "Sales/SalesOrder/List",
        FromSource: "Sales/SalesOrder/FromSource",
        Details: "Sales/SalesOrder/Details",
        resendJob: "Sales/SalesOrder/Job/OrderId/Resend",
        workFlow: "Sales/SalesOrder/Workflow",
      },
    },
    CustomerSalesOrderApis: {
      CustomerSalesOrder: {
        browse: "Sales/CustomerSalesOrder",
        load: "Sales/CustomerSalesOrder/Id",
        constraint:"Sales/CustomerSalesOrder/Constraint"
      },
    },
    BaseInformationApi: {
      Currency: {
        browse: "Sales/BaseInformationApi/Currency",
        load: "Sales/BaseInformationApi/Currency/id",
        dictionary: "Sales/BaseInformationApi/Currency/Dictionary/Detail",
        conversionRatio: "Sales/BaseInformationApi/Currency/ConversionRatio",
      },
      PriceListApis: {
        browse: "Sales/BaseInformationApi/PriceList",
        constraint: "Sales/BaseInformationApi/PriceList/Constraint",
        list: "Sales/BaseInformationApi/PriceList",
        listDictionary: "Sales/BaseInformationApi/PriceList/Dictionary/Detail",
        id: "Sales/BaseInformationApi/PriceList/id",
        clone: "Sales/BaseInformationApi/PriceList/Clone",
        file: "Sales/BaseInformationApi/PriceList/Attachment/File",
        attachment: "Sales/BaseInformationApi/PriceList/Attachment",
        access: "Sales/BaseInformationApi/PriceList/Access",
        ecommercePriceListlistDictionary:'Sales/BaseInformationApi/PriceList/Dictionary/NoManual'
      },
      PriceListDetailApis: {
        browse: "Sales/BaseInformationApi/PriceListDetail",
        browseList: "Sales/BaseInformationApi/PriceListDetail",
        bulk: "Sales/BaseInformationApi/PriceListDetail/BatchAdd",
        batch: "Sales/BaseInformationApi/PriceListDetail/BatchEdit",
        editable:
          "Sales/BaseInformationApi/PriceListDetail/EditableColumnInDataGrid",
        id: "Sales/BaseInformationApi/PriceListDetail/id",
        file: "Sales/BaseInformationApi/PriceListDetail/Attachment/File",
        attachment: "Sales/BaseInformationApi/PriceListDetail/Attachment",
        access: "Sales/BaseInformationApi/PriceListDetail/Access",
        itemsTree: "Sales/BaseInformationApi/PriceListDetail/SelectableItemsTree",
        itemsPaging: "Sales/BaseInformationApi/PriceListDetail/SelectableItemsList",
      },
      Customer: {
        browse: "Sales/BaseInformationApi/Customer",
        Import: "Sales/BaseInformationApi/Customer/Import",
        load: "Sales/BaseInformationApi/Customer/id",
        nextCode: "Sales/BaseInformationApi/Customer/NextCustomerCode",
        dictionary: "Sales/BaseInformationApi/Customer/Dictionary",
        dictionaryId: "Sales/BaseInformationApi/Customer/Dictionary/Id",
        personCustomer: "Sales/BaseInformationApi/Customer/CustomerPersonId",
        credit: "Sales/BaseInformationApi/Customer/Credit",
        getEditableColumns:
          "Sales/BaseInformationApi/Customer/EditableColumnInDataGrid",
        putEditableColumns: "Sales/BaseInformationApi/Customer/ListEdit",
      },
    },
    BaseDefinitionAndSetting: {
      PaymentMethod: {
        browse: "Sales/BaseDefinitionAndSettingApi/PaymentMethod",
        load: "Sales/BaseDefinitionAndSettingApi/PaymentMethod/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/PaymentMethod/Dictionary",
      },
      SalesOrderStatus: {
        browse: "Sales/BaseDefinitionAndSettingApi/SalesOrderStatus",
        load: "Sales/BaseDefinitionAndSettingApi/SalesOrderStatus/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/SalesOrderStatus/Dictionary",
      },
      SalesInvoiceStatus: {
        browse: "Sales/BaseDefinitionAndSettingApi/SalesInvoiceStatus",
        load: "Sales/BaseDefinitionAndSettingApi/SalesInvoiceStatus/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/SalesInvoiceStatus/Dictionary",
      },
      SalesPreInvoiceStatus: {
        browse: "Sales/BaseDefinitionAndSettingApi/SalesPreInvoiceStatus",
        load: "Sales/BaseDefinitionAndSettingApi/SalesPreInvoiceStatus/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/SalesPreInvoiceStatus/Dictionary",
      },
      CustomerClassification: {
        browse: "Sales/BaseDefinitionAndSettingApi/CustomerClassification",
        load: "Sales/BaseDefinitionAndSettingApi/CustomerClassification/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/CustomerClassification/Dictionary",
      },
      SalesDocsClassification: {
        browse: "Sales/BaseDefinitionAndSettingApi/SalesDocsClassification",
        load: "Sales/BaseDefinitionAndSettingApi/SalesDocsClassification/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/SalesDocsClassification/Dictionary/Detail",
      },
      BusinessActivityType: {
        browse: "Sales/BaseDefinitionAndSettingApi/BusinessActivityType",
        load: "Sales/BaseDefinitionAndSettingApi/BusinessActivityType/id",
        dictionary:
          "Sales/BaseDefinitionAndSettingApi/BusinessActivityType/Dictionary",
      },

      GeographicalClassification: {
        browse:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/GetFullyLoadTree",
        canAdd:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/canAddChildTo",
        getId:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/id",
        load: "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/AddNodeToParent",
        edit: "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/UpdateNode",
        getChildren:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/GetChildren/",
        getNode:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/GetNodeByParentId",
        deleteNode:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/DeleteNode",
        moveNode:
          "Sales/BaseDefinitionAndSettingApi/GeographicalClassification/move",
      },
      SalesSystemSetting: {
        browse: "Sales/BaseDefinitionAndSettingApi/SalesSystemSetting",
        groupTitle:
          "Sales/BaseDefinitionAndSettingApi/SalesSystemSetting/GroupTitle",
      },
      CustomerRelationType:{
        dictionary:'Sales/BaseDefinitionAndSettingApi/CustomerRelationType/Dictionary'
      }
    },
    SalesInvoice: {
      load: "Sales/SalesInvoice",
      middle: "Sales/SalesInvoice/Workflow/AddMiddle",
      Calculate: "Sales/SalesInvoice/Calculate",
      browse: "Sales/SalesInvoice/id",
      referenceId: "Sales/SalesInvoice/ReferenceId",
      constraint: "Sales/SalesInvoice/Constraint",
      nextInvoiceNo: "Sales/SalesInvoice/NextInvoiceNo",
      getOrGenerate: "Sales/SalesInvoice/Workflow/GetOrGenerate",
      generate: "Sales/SalesInvoice/GenerateFromSource",
      workflow: "Sales/SalesInvoice/Workflow",
      optionalDiscount: "Sales/SalesInvoice/ShareDiscount",
      FromSourceWithIncrease:
        "Sales/SalesInvoice/Workflow/FromSourceWithIncrease",
      FromSource: "Sales/SalesInvoice/Workflow/FromSource",
      void: "Sales/SalesInvoice/Void",
      invoiceCustomer: "Sales/SalesInvoice/InvoiceCustomerInformation",
      invoiceDate: "Sales/SalesInvoice/InvoiceDate",
      invoiceNo: "Sales/SalesInvoice/InvoiceNo",
      EditInvoiceStatus: "Sales/SalesInvoice/InvoiceStatus",
      invoiceCottage: "Sales/SalesInvoice/InvoiceCottage",
      customerInfo: "Sales/SalesInvoice/CustomerInfo",
    },
    SalesReturnInvoice: {
      load: "Sales/SalesReturnInvoice",
      middle: "Sales/SalesInvoice/Workflow/AddMiddle",
      Calculate: "Sales/SalesInvoice/Calculate",
      browse: "Sales/SalesReturnInvoice/id",
      constraint: "Sales/SalesReturnInvoice/Constraint",
      nextInvoiceNo: "Sales/SalesReturnInvoice/Constraint",
      getOrGenerate: "Sales/SalesInvoice/Workflow/GetOrGenerate",
      generate: "Sales/SalesReturnInvoice/GenerateFromSource",
      workflow: "Sales/SalesInvoice/Workflow",
      optionalDiscount: "Sales/SalesInvoice/ShareDiscount",
      FromSourceWithIncrease: "Sales/SalesInvoice/FromSourceWithIncrease",
      FromSource: "Sales/SalesInvoice/FromSource",
      void: "Sales/SalesInvoice/Void",
    },
    SalesPreInvoice: {
      load: "Sales/SalesPreInvoice",
      WithDetails: "Sales/SalesPreInvoice/WithDetails",
      Calculat: "Sales/SalesPreInvoice/Calculate",
      referenceId: "Sales/SalesPreInvoice/ReferenceId",
      workflowFromSource: "Sales/SalesPreInvoice/Workflow/FromSource",
      getOrGenerate: "Sales/SalesPreInvoice/Workflow/GetOrGenerate",
      constraint: "Sales/SalesPreInvoice/Constraint",
      generate: "Sales/SalesPreInvoice/GenerateFromSource",
      workflow: "Sales/SalesPreInvoice/Workflow",
      nextInvoiceNo: "Sales/SalesPreInvoice/NextPreInvoiceNo",
      optionalDiscount: "Sales/SalesPreInvoice/ShareDiscount",
      FromSourceWithIncrease:
        "Sales/SalesPreInvoice/Workflow/FromSourceWithIncrease",
      getId: "Sales/SalesPreInvoice/id",
    },
  },
  Inventory: {
    WarehouseIOApis: {
      InventoryReceipt: "Inventory/WarehouseIO/InventoryRemittance",
      browse: "Inventory/WarehouseIO",
      warehouseIOKindDictionary: "Inventory/WarehouseIOKind/Dictionary",
      load: "Inventory/WarehouseIO/id",
      constraint: "Inventory/WarehouseIO/Constraint",
      nextNo:'Inventory/WarehouseIO/NextWarehouseIONo'
    },
    ItemOtherPropertyValue: {
      browse: "Inventory/ItemApi/ItemOtherPropertyValue",
    },
    ItemPriceApis: {
      ItemPrice: "Inventory/ItemPriceApi/ItemPrice",
      SellableItemsTree: "Inventory/ItemPriceApi/ItemPrice/SellableItemsTree",
      SellableItemsList: "Inventory/ItemPriceApi/ItemPrice/SellableItemsList",
      ItemsTree: "Inventory/ItemPriceApi/ItemPrice/ItemsTree",
      ItemsList: "Inventory/ItemPriceApi/ItemPrice/ItemsList",
    },
    ItemBarcode: {
      browse: "Inventory/ItemApi/ItemBarcode",
    },
    ItemTax: {
      browse: "Inventory/ItemApi/ItemTax",
    },
    ItemOtherProperty: {
      browse: "Inventory/ItemOtherPropertyApi/ItemOtherProperty",
      Properties: "Inventory/ItemOtherPropertyApi/ItemOtherProperty/Properties",
    },
    ItemItemOtherProperty: {
      browse: "Inventory/ItemApi/ItemItemOtherProperty",
      Properties: "Inventory/ItemApi/ItemItemOtherProperty",
      PropertiesEdit: "Inventory/ItemApi/ItemItemOtherProperty/EditGrid",
    },
    ItemApis: {
      /* Item: {
        browse: "Inventory/ItemApi/Item",
        store: "Inventory/ItemApi/Item/ItemStoreList",
        details: "Inventory/ItemApi/Item/Details",
        subItemGroup: "Inventory/ItemApi/Item/SubItemGroup",
        itemGroup: "Inventory/ItemApi/Item/ItemGroup",
        itemGroupRoot: "Inventory/ItemApi/Item/ItemGroupRoot",
        itemGroupDictionary: "Inventory/ItemApi/Item/ItemGroupDictionary",
        Properties: "Inventory/ItemApi/Item/ItemProperties",
        ItemGroupList: "Inventory/ItemApi/Item/ItemGroupList",
        getFull: "Inventory/ItemApi/Item/GetFullyLoadTree",
        canAdd: "Inventory/ItemApi/Item/canAddChildTo",
        getId: "Inventory/ItemApi/Item/id",
        load: "Inventory/ItemApi/Item/AddNodeToParent",
        edit: "Inventory/ItemApi/Item/UpdateNode",
        getChildren: "Inventory/ItemApi/Item/GetChildren/",
        getNode: "Inventory/ItemApi/Item/GetNodeByParentId/",
        checkLevel: "Inventory/ItemApi/Item/NewItemConstraints",
        checkCode: "Inventory/ItemApi/Item/EditConstraints",
        getRootes: "Inventory/ItemApi/Item/GetRootes",
        deleteNode: "Inventory/ItemApi/Item/DeleteNode",
        moveNode: "Inventory/ItemApi/Item/move",
        attach: "Inventory/ItemApi/Item/Attachment/File",
        attachThumbnail: "Inventory/ItemApi/Item/Attachment/Thumbnail",
        attachment: "Inventory/ItemApi/Item/Attachment",
      }, */
      ItemTree: {
        browse: "Inventory/ItemTreeApi/ItemTree",
        ItemGroupRootsFormat:
          "Inventory/ItemTreeApi/ItemTree/ItemGroupRootsFormat",
        store: "Inventory/ItemTreeApi/ItemTree/ItemStoreList",
        details: "Inventory/ItemTreeApi/ItemTree/Details",
        subItemGroup: "Inventory/ItemTreeApi/ItemTree/SubItemGroup",
        itemGroup: "Inventory/ItemTreeApi/ItemTree/ItemGroup",
        itemGroupRoot: "Inventory/ItemTreeApi/ItemTree/ItemGroupRoot",
        itemGroupLeaf: "Inventory/ItemTreeApi/ItemTree/ItemGroupLeaf",
        itemGroupBranch: "Inventory/ItemTreeApi/ItemTree/ItemGroupBranch",
        itemBranch: "Inventory/ItemTreeApi/ItemTree/ItemBranch",
        itemLeaf: "Inventory/ItemTreeApi/ItemTree/ItemLeaf",
        newItemVariation: "Inventory/ItemTreeApi/ItemTree/NewItemVariation",
        itemVariationBranch:
          "Inventory/ItemTreeApi/ItemTree/ItemVariationBranch",
        itemVariationLeaf: "Inventory/ItemTreeApi/ItemTree/ItemVariationLeaf",
        itemGroupDictionary:
          "Inventory/ItemTreeApi/ItemTree/ItemGroupDictionary",
        Properties: "Inventory/ItemTreeApi/ItemTree/ItemProperties",
        ItemGroupList: "Inventory/ItemTreeApi/ItemTree/ItemGroupList",
        getFull: "Inventory/ItemTreeApi/ItemTree/GetFullyLoadTree",
        canAdd: "Inventory/ItemTreeApi/ItemTree/canAddChildTo",
        getId: "Inventory/ItemTreeApi/ItemTree/id",
        load: "Inventory/ItemTreeApi/pi/ItemTree/AddNodeToParent",
        edit: "Inventory/ItemTreeApi/ItemTree/UpdateNode",
        getChildren: "Inventory/ItemTreeApi/ItemTree/GetWithChildren/",
        getNode: "Inventory/ItemTreeApi//ItemTree/GetNodeByParentId/",
        checkLevel: "Inventory/ItemTreeApi/ItemTree/NewItemConstraints",
        checkCode: "Inventory/ItemTreeApi/ItemTree/EditConstraints",
        getRootes: "Inventory/ItemTreeApi/ItemTree/GetRoots",
        getLeafList: "Inventory/ItemTreeApi/ItemTree/GetLeafList",
        deleteNode: "Inventory/ItemTreeApi/ItemTree/DeleteNode",
        moveNode: "Inventory/ItemTreeApi/ItemTree/move",
        attach: "Inventory/ItemTreeApi/ItemTree/Attachment/File",
        attachThumbnail: "Inventory/ItemTreeApi/ItemTree/Attachment/Thumbnail",

        attachment: "Inventory/ItemTreeApi/ItemTree/Attachment",
      },
    },
    BaseInformationApi: {
      WarehouseItem: {
        browse: "Inventory/BaseInformationApi/WarehouseItem",
        editList: "Inventory/BaseInformationApi/WarehouseItem/List",
        bulk: "Inventory/BaseInformationApi/WarehouseItem/Bulk",
        editable:
          "Inventory/BaseInformationApi/WarehouseItem/EditableColumnsInDataGrid",
        getRootes:
          "Inventory/BaseInformationApi/WarehouseItem/AvailableToAdd/Tree",
        tree: "Inventory/BaseInformationApi/WarehouseItem/Tree",
        load: "Inventory/BaseInformationApi/WarehouseItem/id",
        file: "Inventory/BaseInformationApi/WarehouseItem/Attachment/File",
        attachment: "Inventory/BaseInformationApi/WarehouseItem/Attachment",
        access: "Inventory/BaseInformationApi/WarehouseItem/Access",
        ItemsList:
          "Inventory/BaseInformationApi/WarehouseItem/SelectableItemsList",
        ItemsTree:
          "Inventory/BaseInformationApi/WarehouseItem/SelectableItemsTree",
        itemDetail:'Inventory/BaseInformationApi/WarehouseItem/ItemDetail'
      },

      warhouseItemGroup: {
        browse: "Inventory/BaseInformationApi/WarehouseItemGroup",
      },
      Warehouse: {
        browse: "Inventory/BaseInformationApi/Warehouse",
        load: "Inventory/BaseInformationApi/Warehouse/id",
        dictionary: "Inventory/BaseInformationApi/Warehouse/Dictionary",
        dictionaryHasAccess:
          "Inventory/BaseInformationApi/Warehouse/DictionaryHasAccess",
        getNode: "Inventory/BaseInformationApi/Warehouse/Detail",
        access: "Inventory/BaseInformationApi/Warehouse/Access",
      },
      WarehouseDetail: {
        browse: "Inventory/BaseInformationApi/WarehouseDetail",
        load: "Inventory/BaseInformationApi/WarehouseDetail/id",
        savedArchive: "Inventory/BaseInformationApi/WarehouseDetail/jobId",
        dictionary: "Inventory/BaseInformationApi/WarehouseDetail/Dictionary",
      },

      Unit: {
        browse: "Inventory/BaseInformationApi/Unit",
        load: "Inventory/BaseInformationApi/Unit/id",
        dictionary: "Inventory/BaseInformationApi/Unit/Dictionary",
        taxdictionary: "Inventory/BaseInformationApi/Unit/Dictionary/TaxUnit",
        dictionaryId: "Inventory/BaseInformationApi/Unit/Dictionary",
        defaultUnit: "Inventory/BaseInformationApi/Unit/DefaultUnit",
      },
      WarehouseItemGroup: {
        browse: "Inventory/BaseInformationApi/WarehouseItemGroup",
        load: "Inventory/BaseInformationApi/WarehouseItemGroup/id",
        hasItemGroup:
          "Inventory/BaseInformationApi/WarehouseItemGroup/HasItemGroup",
      },
    },
    BaseDefinitionAndSettingApi: {
      Barcode: {
        browse: "Inventory/BaseDefinitionAndSettingApi/Barcode",
        load: "Inventory/BaseDefinitionAndSettingApi/Barcode/id",
        dictionary: "Inventory/BaseDefinitionAndSettingApi/Barcode/Dictionary",
      },
      ItemMainProperty: {
        browse: "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Keys",
        //  load ?
        load: "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Keys/id",
        dictionary:
          "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Keys/Dictionary",
      },
      ItemMainPropertyValues: {
        browse: "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Values",
        list: "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Values/List",
        //  load ?
        load: "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Values/id",
        dictionary:
          "Inventory/BaseDefinitionAndSettingApi/ItemMainProperty/Values/Dictionary",
      },
      physicalQuantity: {
        dictionary:
          "/Inventory/BaseDefinitionAndSettingApi/PhysicalQuantity/Dictionary",
      },
      inventorySystemSetting: {
        browse: "Inventory/BaseDefinitionAndSettingApi/InventorySystemSetting",
        groupTitle:
          "Inventory/BaseDefinitionAndSettingApi/InventorySystemSetting/GroupTitle",
      },
      InventoryDocsClassification: {
        browse:
          "Inventory/BaseDefinitionAndSettingApi/InventoryDocsClassification",
        load: "Inventory/BaseDefinitionAndSettingApi/InventoryDocsClassification/id",
        dictionary:
          "Inventory/BaseDefinitionAndSettingApi/InventoryDocsClassification/Dictionary/Detail",
      },
    },
  },
};
export const ENDPOINTSNOTIFYING = {
  messageSettingsApis: {
    messageSetting: {
      browse: "MessageSettingApi/MessageSetting",
      load: "MessageSettingApi/MessageSetting/id",
      dictionary: "MessageSettingApi/MessageSetting/Dictionary",
    },
  },
  MessagePatternsApis: {
    MessagePattern: {
      browse: "MessagePatternApi/MessagePattern",
      load: "MessagePatternApi/MessagePattern/id",
      dictionary: "MessagePatternApi/MessagePattern/Dictionary",
    },
  },
};

export const ENDPOINTSREPORT = {
  Report: {
    tree: "Report/ReportTree",
    report: "Report",
    withFeed: "Report/WithFeed",
    reportView: "Report/View",
    feedApi: "Report/WithFeedApi",
    feedMethod: "Report/WithFeedMethod",
    dictionary: "MessageSettingApi/MessageSetting/Dictionary",
  },

  Databases: {
    dictionary: "Databases/Dictionary",
  },
  ReportFeed: {
    loadApi: "ReportFeed/Api/List",
    loadMethod: "ReportFeed/Method/List",
    browseApi: "ReportFeed/Api",
    browseMethod: "ReportFeed/Method",
    operatorTypes: "ReportFeed/OperatorTypes",
    parameterTypes: "ReportFeed/ParameterTypes",
    parameterEnums: "ReportFeed/EnumValues",
    methodEnumTypes: "ReportFeed/MethodEnumTypes",
    GenericDictionary: "ReportFeed/GenericDictionary/Types",
    GenericDictionaryValues: "ReportFeed/GenericDictionary/Values",
    gnerate: "ReportFeed/GenerateParametersFromDTO",
    service: "ReportFeed/Service/List",
    parameters:'ReportFeed/Parameter',
    templates:'Report/ReportTemplate',
    templatesList:'Report/ReportTemplates'
  },
};
export const ENDPOINTSDYNAMICFORMS = {
  DynamicFormApis: {
    id: "DynamicFormsManagement/DynamicFormApis/DynamicForm/Data/Id",
    JobId: "DynamicFormsManagement/DynamicFormApis/DynamicForm/JobId",
    allForms: "DynamicFormsManagement/DynamicFormApis/DynamicForm/AllForms",
    list: "DynamicFormsManagement/DynamicFormApis/DynamicForm/Data",
    data: "DynamicFormsManagement/DynamicFormApis/DynamicForm/Data",
    formKey: "DynamicFormsManagement/DynamicFormApis/DynamicForm/FormKey",
    checkEmpty: "DynamicFormsManagement/DynamicFormApis/DynamicForm/CheckEmpty",
    dynamicForm: "DynamicFormsManagement/DynamicFormApis/DynamicForm",
    CheckExist: "DynamicFormsManagement/DynamicFormApis/DynamicForm/CheckExist",
    dataCollection:
      "DynamicFormsManagement/DynamicFormApis/DynamicFormProperty/Keys",
    dataCollectionProperties:
      "DynamicFormsManagement/DynamicFormApis/DynamicFormProperty/Values",
    dataCollectionPropertiesDictionary:
      "DynamicFormsManagement/DynamicFormApis/DynamicFormProperty/Values/Dictionary",
  },
};

export const ENDPOINTSMEETINGMANAGEMENT = {
  CalenderApis: {
    browse: "MeetingApi/MeetingCalendar",
  },
  MeetingApis: {
    Meeting: {
      browse: "MeetingApi/Meeting",
      load: "MeetingApi/Meeting/id",
      accept: "MeetingApi/Meeting/Accept",
      Invitation: "MeetingApi/Meeting/Invitation/Id",
      InvitedId: "MeetingApi/Meeting/InvitedPeople/Id",
      PresenceType: "MeetingApi/Meeting/PresenceType",
      AttendanceId: "MeetingApi/Meeting/Attendees/List",
      approvalForSign:
        "MeetingApi/Meeting/MeetingMinuteAndApprovalForSign/Reference",
      putMinute: "MeetingApi/Meeting/MeetingMinutes",
      finishMeeting: "MeetingApi/Meeting/FinishMeetingAndStartApprovals",
      list: "MeetingApi/Meeting/List",
      conflict: "MeetingApi/Meeting/Place/Conflict",
      Signature: "MeetingApi/Meeting/Signature",
    },
    MeetingApproval: {
      browse: "MeetingApi/MeetingApproval",
      List: "MeetingApi/MeetingApproval/List",
      MeetingId: "MeetingApi/MeetingApproval/MeetingId",
      load: "MeetingApi/MeetingApproval/id",
    },
    BaseDefinitionAndSettingsApi: {
      MeetingClassification: {
        browse: "BaseDefinitionAndSettingsApi/MeetingClassification",
        load: "BaseDefinitionAndSettingsApi/MeetingClassification/id",
        list: "BaseDefinitionAndSettingsApi/MeetingClassification/List",
        dictionary:
          "BaseDefinitionAndSettingsApi/MeetingClassification/dictionary",
      },
      MeetingPlace: {
        browse: "BaseDefinitionAndSettingsApi/MeetingPlace",
        load: "BaseDefinitionAndSettingsApi/MeetingPlace/id",
        list: "BaseDefinitionAndSettingsApi/MeetingPlace/List",
        dictionary: "BaseDefinitionAndSettingsApi/MeetingPlace/dictionary",
      },
    },
  },
};
export const ENDPOINTSTREASURYANDACCOUNTING = {
  Treasury: {
    BaseInformationApi: {
      Bank: {
        brows: "Treasury/BaseInformationApi/Bank",
        load: "Treasury/BaseInformationApi/Bank/id",
        dictionary: "Treasury/BaseInformationApi/Bank/Dictionary",
      },
      BankAccounts: {
        brows: "Treasury/BaseInformationApi/BankAccount",
        load: "Treasury/BaseInformationApi/BankAccount/id",
        dictionary: "Treasury/BaseInformationApi/BankAccount/Dictionary",
      },
      TreasurySubject: {
        brows: "Treasury/BaseInformationApi/TreasurySubject",
        load: "Treasury/BaseInformationApi/TreasurySubject/id",
        dictionary: "Treasury/BaseInformationApi/TreasurySubject/Dictionary",
      },
      CashAccount: {
        access: "Treasury/BaseInformationApi/CashAccount/Access",
        brows: "Treasury/BaseInformationApi/CashAccount",
        load: "Treasury/BaseInformationApi/CashAccount/id",
        dictionary: "/Treasury/BaseInformationApi/CashAccount/Dictionary",
      },
    },
    BaseDefinitionAndSettingApi: {
      BankAccountType: {
        dictionary:
          "Treasury/BaseDefinitionAndSettingApi/BankAccountType/Dictionary",
        brows: "Treasury/BaseDefinitionAndSettingApi/BankAccountType",
        load: "Treasury/BaseDefinitionAndSettingApi/BankAccountType/id",
      },
      DocsClassification: {
        browse: "Treasury/BaseDefinitionAndSettingApi/DocClassification",
        load: "Treasury/BaseDefinitionAndSettingApi/DocClassification/id",
        dictionary:
          "Treasury/BaseDefinitionAndSettingApi/DocClassification/Dictionary",
      },
      BankPOS: {
        brows: "Treasury/BaseInformationApi/BankPOS",
        load: "Treasury/BaseInformationApi/BankPOS/id",
        dictionary: "Treasury/BaseInformationApi/BankPOS/Dictionary",
      },
      PaymentServiceProvider: {
        dictionary:
          "Treasury/BaseDefinitionAndSettingApi/PaymentServiceProvider/Dictionary",
      },
    },
    Receipt: {
      brows: "Treasury/Receipt",
      load: "Treasury/Receipt/id",
      dictionary: "Treasury/Receipt/Dictionary",
      attachment: "Treasury/Receipt/Attachment",
    },
  },
  Accounting: {
    browse: "MeetingApi/MeetingCalendar",
  },
};
