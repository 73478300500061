
import { http } from "utils/http";

import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";






export const getPriceListDetail= (priceListId,pageNumber , pageSize,orderBy  ,searchString?: any ,filterlist?) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.browseList,
    method: "get",
    params:{
        priceListId:priceListId,
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchString:searchString,
        OrderByString:orderBy,
        FilterString:filterlist

    }

}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const removePriceListDetail= (removelist) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.browse,
    method: "delete",
    data:removelist
}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })



export const getEditablecolumns= () => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.editable,
    method: "get",


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const postPriceListDetail= (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.browse,
    method: "post",
    data:data


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const putPriceListDetail= (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.browse,
    method: "put",
    data:data


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const postPriceListDetailBulkAddApi= (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.bulk,
    method: "post",
    data:data


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })
export const putPriceListDetailBatchApi= (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.PriceListDetailApis.batch,
    method: "put",
    data:data


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })