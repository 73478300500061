import { Button } from "components/Button/Button";
import { ButtonArrow } from "components/ButtonArrow/ButtonArrow";
import { Loading } from "components/Loading/loading";
import { UserContext } from "components/ModalForm/ModalForm";
import React, { useContext, useEffect, useState } from "react";
import { getGroupPositionDetail, getGroupPositionId, getPositionList, setGroupPositionList } from "../OtherGroups/components/api/api";
import { AssignedPositionList } from "./components/AssignedPositionList/AssignedPositionList";
import { PositionList } from "./components/PositionList/PositionList";



interface PositionAssigningToGroup {
  PositionId,
  mode,
  ShowDefaultGroups?,
  showModal?: (e) => void;
  setUpdate?: (e) => void;
}


export const PositionAssigningToGroup : React.FC<PositionAssigningToGroup> = ({
  PositionId,
  mode,
  ShowDefaultGroups=false,
  showModal,
  setUpdate
}) => {

  const [selectList , setSelectList] = useState<any>()
  const [AssignSelectList , setAssignSelectList] = useState<any>()
  const [reset, setReset] = useState<any>(false)
  const [loading, setLoading] = useState<any>(false)
  const [responsePosition, setResponsePosition] = useState<Response | any>();
  const [assignPositionListData ,setAssignPositionListData] = useState<any>()
  const [dataForSend ,setDataForSend] = useState<any>()
  const [userMadeChanges, setUserMadeChanges] = useContext(UserContext);
  function backward(){
    setUserMadeChanges(true)
    if(selectList[1] && selectList[1].length>0){
    let newList:any =[ ]
    responsePosition.forEach(item1 => {
      let flag = true
      selectList[1].forEach(item2 => {
        if(item1.Id==item2.Id){
          flag = false
        }
      });

      if(flag){
        newList.push(item1)
      }
      
    });

    setResponsePosition(newList)
    setAssignPositionListData(assignPositionListData.concat((selectList[1])))
    setSelectList([])
    setReset(!reset)
    }
    setAssignSelectList([])
    setSelectList([])
  }
  function forward(){
    setUserMadeChanges(true)
    if(AssignSelectList[1] && AssignSelectList[1].length>0){
    let newList:any =[ ]
    let newList2:any =[ ]
    AssignSelectList[1].forEach(item=>{
      newList2.push({Id:item.PositionId ?item.PositionId :item.Id ,Description:item.Description})

    })
     setResponsePosition(responsePosition.concat(newList2))
     assignPositionListData.forEach(item1 => {
      let flag = true
      AssignSelectList[1].forEach(item2 => {
        if(item1.Id==item2.Id){
          flag = false
        }
      });
      if(flag){
        newList.push(item1)
      }
    });
    setAssignPositionListData(newList)
    setSelectList([])
    setReset(!reset)
    }

    setAssignSelectList([])
    setSelectList([])
  }
  const showActionFormCallback =(e)=>{
    showModal && showModal(false)

  }
  const  sendData =()=>{
    let GroupPositionDetail=assignPositionListData.map(item=>{
        return({
          "GroupPositionId": PositionId.Id,
          "PositionId": item.PositionId ?item.PositionId :item.Id ,
  
        })

  
    })
    setGroupPositionListAsync(GroupPositionDetail)
  }



  async function getDataAsync() { 
    setLoading(true)
    let GroupPositionDetailResponse:any={Data :[]}
    try {
        GroupPositionDetailResponse = await getGroupPositionDetail(PositionId.Id );
    } catch (error) {
        setAssignPositionListData(true)
    }       
    const PositionListResponse = await getPositionList(); 
    let PositionListResponseFiltered:any = []
    let AssignPositionList:any = []
    PositionListResponse.Data.forEach(item1=>{
      let flag = true
      GroupPositionDetailResponse.Data?.Data.forEach(item2 => {
        if(item1.Id==item2.PositionId){
          flag = false
        }
      });
      if(flag){
        PositionListResponseFiltered.push(item1)
      }else{
        AssignPositionList.push(item1)
        
      }
    })
        setAssignPositionListData(AssignPositionList);
        setResponsePosition(PositionListResponseFiltered);
        try {
      } catch (error) {
        setResponsePosition(true)
      }

    try {
       const GroupPositionIdResponse = await getGroupPositionId(PositionId.Id)
       setDataForSend(GroupPositionIdResponse.Data)
      } catch (error) {
    }  
    setLoading(false)
 }


  async function setGroupPositionListAsync(data) { //TODO
      try {
        const response = await setGroupPositionList(data);
        setUpdate && setUpdate(false) 
        showModal && showModal(false)     
      } catch (error) {
        getDataAsync()
      }
  }

  useEffect(() => {
    getDataAsync()
  },[PositionId]);

  return (

    <>
    {loading ?
       <Loading />
      : 
     <div className="card" >
     <div className="card-body" >
        {
         mode !="view"   ?
         <>
      <div className="row mb-1 mt-4 d-flex justify-content-between">
        <div className="col-lg-5 col-md-5 mt-0">
          <PositionList reset={reset} selectList={setSelectList} data={responsePosition} />
        </div>
        
        <div className="col-lg-1 col-md-1 d-flex  flex-lg-column flex-md-column justify-content-center align-items-center">
          <ButtonArrow type="backward" onClick={()=>{backward()}}/>
          <ButtonArrow type="forward" onClick={()=>{forward()}}/>
        </div>
        <div className="col-lg-5 col-md-5 mt-0">
          <AssignedPositionList hasMultiSelect={true} reset={reset} data={assignPositionListData} selectList={setAssignSelectList} PositionId={PositionId} />
        </div>
      </div>
    
      <div className="d-flex justify-content-end mt-4">
        <Button value="Save" btnType={"submit"} onClick={() => { sendData() }} />
        <Button
            btnType="cancel"
            value="Cancel"
            onClick={() => { showActionFormCallback(false) }}
        />
      </div>
       </>
     :     <div style={{margin: "10px auto" , width:"600px"}} className="col-lg-5 col-md-5 mt-3  align-items-center">
               <AssignedPositionList hasMultiSelect={false} reset={reset} data={assignPositionListData} selectList={setAssignSelectList} PositionId={PositionId} />
           </div>
       }
       </div>
       </div>
       }
    </>
  );
};
