import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getSalesInvoiceById = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.CustomerSalesInvoiceApis.CustomerSalesInvoice.load,
    headers: { "Accept-Language": "en" },
    method: 'get',
    params: {
   
        Id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});









export const getDefaultPersonContact = (Id ) => http({

    url: ENDPOINTS.BaseInformation.PersonContact.default,
    method: 'get',
    params:{
        personId:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getPersonCustomer = (Id ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.load,
    method: 'get',
    params:{
        id:Id
    }
    
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const getSources = (type,pageNumber=1,pageSize=10 ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: type==1? ENDPOINTSINVENTORYANDSALES.Sales.SalesOrderApis.SalesOrder.browse:type==2?ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load: ENDPOINTSINVENTORYANDSALES.Sales.SalesPreInvoice.load ,
    method: 'get',
params:{
    PageNumber: pageNumber,
    PageSize: pageSize,
}

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const GenerateFromSource = (SourceId, SourceData  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.generate,
    method: 'get',
    params:{
        SourceData ,
        SourceId
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const optionalDiscountHandler = (data  ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.optionalDiscount,
    method: 'post',
    data:data

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});
export const salesInvoiceViod = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Sales.SalesInvoice.void,
    method: 'delete',
    params:{
        Id:id
    }

}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err);
});