import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";

export const getSellableItemsTree = (id: any ,warehouseDetailId ,priceListId=null,isFiscal=true) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.ItemPriceApis[isFiscal?'ItemsTree':'SellableItemsTree'],
    method: "get",
    params: {
        
        ParentId: null ,
        warehouseId: id=="allWarehouses"? null: id ,
        priceListId:priceListId
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
export const getSellableItemsList = (id: any ,warehouseDetailId ,priceListId=null ,pageNumber,pageSize,orderByString,searchString,filterString,isFiscal=true) => http({
    baseURL:INVENTORYANDSALES_URL,
    url:
     ENDPOINTSINVENTORYANDSALES.Inventory.ItemPriceApis[isFiscal?'ItemsList':'SellableItemsList'],
    method: "get",
    params: {
        ParentId: null ,
        warehouseId: id? id : null,
        priceListId:priceListId,
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});