import { SelectBox } from "components/Select/Select";
import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { trans } from "utils/helpers";
import { getParameterOperatorTypesDictionaryApi } from "./api";

import { InputLoad } from "components/InputLoading/inputLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { ICON_TYPE } from "utils/constants";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
interface ParameterOperatorTypesProp extends InputHTMLAttributes<HTMLInputElement> {
  type: "SelectBox" | "Radio" | "CheckBox";
  label?: string;
  PRJ_UID?: string;
  name: string;
  value?: any;
  mode?: any;
  valueList?: any;
  required?: boolean;
  Change?: (e) => void,
  ParameterOperatorTypesListCallback?: (e) => void,
  returnObj?:any
}
export const ParameterOperatorTypesSelect = ({ type,returnObj=false, label,PRJ_UID, name, value,valueList={} ,  mode ,required,Change,ParameterOperatorTypesListCallback }: ParameterOperatorTypesProp) => {
  const [optionsParameterOperatorTypes, setOptionsParameterOperatorTypes] = useState<any>({});
  const [errcode, setErrcode] = useState<any>();
  const [app, setApp] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>([]);
  let Default:any = value

  const ParameterOperatorTypesApiAsync = async ()=>{
   
      const res = await getParameterOperatorTypesDictionaryApi()

      setOptionsParameterOperatorTypes(res.Data)
     
  if(valueList ) {
        let Apps:any= []
        
        Object.keys(res.Data).map(item=>{
          console.log("Object.keys(valueList).includes(item)&&valueList[item]" ,Object.keys(valueList).includes(item),valueList[item] ,res.Data[item])
          if(Object.keys(valueList).includes(res.Data[item])&&valueList[item]){
              Apps.push({Id:item , Name:res.Data[item]})
          }
        })
        setSelectedItem(Apps) 
      }else{
        setSelectedItem([])               

      }
 try {
    } catch (error:any) {
      setErrcode(error.response.status)

    }
  }


  function addOption(e) {
    e.stopPropagation()
    e.preventDefault()
    let Name 

    if(app || Object.keys(optionsParameterOperatorTypes)[0]){
     let Id = app?.target?.value ? app?.target?.value :Object.keys(optionsParameterOperatorTypes)[0]
     let query =  {
      ParameterOperatorTypesId:Id ,
      PRJ_UID : PRJ_UID 
    }
     
    Object.keys(optionsParameterOperatorTypes).forEach(item => {
      if(item == Id){
        Name = optionsParameterOperatorTypes[item]
      }
    });

    let obj={}
    if(ParameterOperatorTypesListCallback){
      let selectList = JSON.parse(JSON.stringify(selectedItem))
      let list:any = []
      let listId = selectList.map(item=>{
        list.push(item.Id)
        return  returnObj?
        obj={...obj,
          [item.Id]:item.Name
        }
        :  item.Id
      })


      if(!list.includes(Id)){

        ParameterOperatorTypesListCallback(returnObj? [{...obj, [Id]:Name}]  :[...listId, Id])
        setSelectedItem([...selectList , {Id:Id , Name:Name}] )
      }

    }
    }

  }



  async function deleteaOption(obj) {
    if(ParameterOperatorTypesListCallback){
     
    let selectList:any  = [ ]
      selectedItem.map((e)=>{
        if(e.Id != obj.Id){
          selectList.push(e)
        }
      })
      setSelectedItem(selectList)
    }
}


  useEffect(() => {
    console.log("valueList" ,valueList)
  ParameterOperatorTypesApiAsync()
  }, [ ]);


  

  return (
    <>
      {true ?
            <>
            <div style={{display:"flex" , alignItems:'center' ,flexDirection:"row",width:"100%",flexWrap: "wrap"}}  >
              {Object.keys(optionsParameterOperatorTypes).map(item=>(
                <div className="my-2 col-3">
                <CheckableButton
                  defaultChecked={valueList?.[item] }
                  defaultValue={valueList?.[item] }
                  value={item}
                  labelText={item}
                  inputName={"ParameterOperatorTypesSelect"}
                  type="checkbox"
                />
              </div>
              ))}


             </div>
            </>
        :<InputLoad  msg={errcode}  required={required}  labelText={label}  onClick={ParameterOperatorTypesApiAsync}/> 
      }

    </>
  );
};


