import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import { FormWithTitle } from "components/FormWithTitle/FormWithTitle";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { WarehouseDictionarySelect } from "pages/InventoryAndSales/Inventory/Warehouse/WarehouseStructure/components/WarehouseDictionarySelect/WarehouseDictionarySelect";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { PriceInput } from "./PriceInput";
import { postPriceListDetailBulkAddApi } from "./api";
import { ItemsForPriceList } from "../ItemsForPriceList/ItemsForPriceList";

interface Prop {
  resetForm?: boolean;
  onHide: () => void;
  PriceListObj?: any;
  haveDetail?: any;
  saveWarehouseInPriceList?: any;
  mode: string;
  reload: () => void;
}

export const PriceListDetailBulkAddForm = ({
  reload,
  onHide,
  saveWarehouseInPriceList,
  haveDetail,
  mode,
  PriceListObj,
}: Prop) => {
  const [resetForm, setResetForm] = useState(false);
  const [userChanges, setUserChanges] = useState<any>({});
  const [loading, setLoading] = useState<any>(true);
  const [all, setAll] = useState<any>();
  const [fixedPrice, setFixedPrice] = useState<any>(true);
  const [Price, setPrice] = useState<any>(false);
  const [showTree, setShowTree] = useState<any>();
  const [treeType, setTreeType] = useState<any>();
  const [werhoseList, setWerhoseList] = useState<any>([]);
  const [selectedWerhose, setSelectedWerhose] = useState<any>([]);
  const [itemCodeData, setItemCodeData] = useState<any>({});
  const [needToCall, setNeedToCall] = useState<any>(true);
  const [unitPriceValue, setUnitPriceValue] = useState(0);

  const itemCodeHandeler = (obj) => {
    setLoading(false);

    setItemCodeData({
      ...itemCodeData,
      [selectedWerhose?.Id]: {
        ...itemCodeData[selectedWerhose?.Id],
        [treeType]: obj.Code,
      },
    });
    setShowTree(false);
    setLoading(true);
  };
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  console.log("PriceListObj ",PriceListObj)
  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  const submit = async (event) => {
    event.preventDefault();
    let warehouseCodes: any = [];
    if (werhoseList.length > 0) {
      werhoseList.map((item) => {
        if (all?.[item]) {
          let data = {
            WarehouseId: item,
            From: itemCodeData?.[item]?.From,
            To: itemCodeData?.[item]?.To,
          };
          warehouseCodes.push(data);
        } else {
          let data = {
            WarehouseId: item,
            From: null,
            To: null,
          };
          warehouseCodes.push(data);
        }
      });
    } else {
      if (event.target?.WarehouseDictionary?.value != "allWarehouses") {
        warehouseCodes = [
          {
            WarehouseId: event.target?.WarehouseDictionary?.value,
            From: null,
            To: null,
          },
        ];
      }
      if (event.target?.from?.value || event.target?.To?.value) {
        warehouseCodes = [
          {
            WarehouseId: warehouseCodes?.[0]?.WarehouseId
              ? warehouseCodes?.[0]?.WarehouseId
              : null,
            From: event.target?.from?.value,
            To: event.target?.To?.value,
          },
        ];
      }
    }
    if (fixedPrice) {
      let data: any = {
        TargetPriceListId: PriceListObj.Id,
        warehouseCodes: warehouseCodes,
        MinOrderQty:PriceListObj?.HasDetailRange? +event.target.MinOrderQty?.value:0,
        saveWarehouseInPriceList: saveWarehouseInPriceList,
        MaxOrderQty: (!event.target.MaxOrderQty?.value|| !PriceListObj?.HasDetailRange)
          ? null
          : +event.target.MaxOrderQty.value,
        UnitPrice: +unitPriceValue,
        DiscountPercent:
          event.target?.DiscountPercent?.value == ""
            ? null
            : +event.target?.DiscountPercent?.value,
        //    RoundingPlace: +event.target?.RoundingPlace?.value,
      };
      //   if(needToCall) delete data?.RoundingPlace
      if (werhoseList.length == 1 && werhoseList.includes("allWarehouses")) {
        if (!all?.allWarehouses || warehouseCodes.length == 0) {
          delete data.warehouseCodes;
        } else {
          data.warehouseCodes = {
            WarehouseId: null,
            From: itemCodeData?.allWarehouses?.From,
            To: itemCodeData?.allWarehouses?.To,
          };
        }
      }
      try {
        await postPriceListDetailBulkAddApi(data);
        reload();
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
      } catch (error) {}
    } else {
      let data: any = {
        TargetPriceListId: PriceListObj.Id,
        SourcePriceListId: event.target?.PriceList.value,
        [Price ? "UnitPrice" : "Percent"]: Price
          ? needToCall
            ? -1
            : +unitPriceValue
          : event.target.Percent.value,
        DiscountPercent:
          event.target?.DiscountPercent?.value == ""
            ? null
            : +event.target?.DiscountPercent?.value,
        //  "RoundingPlace": +event.target?.RoundingPlace?.value,
        warehouseCodes: warehouseCodes,
        saveWarehouseInPriceList: saveWarehouseInPriceList,
        MinOrderQty: PriceListObj?.HasDetailRange ?+event.target.MinOrderQty.value:0,
        MaxOrderQty: (!event.target.MaxOrderQty?.value||!PriceListObj?.HasDetailRange)
          ? null
          : +event.target.MaxOrderQty.value,
      };
      //   if(needToCall) delete data.RoundingPlace
      if (werhoseList.length == 1 && werhoseList.includes("allWarehouses")) {
        if (!all?.allWarehouses || warehouseCodes.length == 0) {
          delete data.warehouseCodes;
        } else {
          data.warehouseCodes = {
            WarehouseId: null,
            From: itemCodeData?.allWarehouses?.From,
            To: itemCodeData?.allWarehouses?.To,
          };
        }
      }
      try {
        await postPriceListDetailBulkAddApi(data);
        reload();
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
      } catch (error) {}
    }
  };

  function addOption(e) {
    setTreeType(e);
    setShowTree(true);
  }

  useEffect(() => {
    if (resetForm) {
      setUserChanges({});
    }
  }, [resetForm]);
  return (
    <>
      {showTree && (
        <ModalForm
          mode={mode}
          title="ItemTree"
          onHide={() => setShowTree(false)}
          show={showTree}
        >
          <ItemsForPriceList
            isWarehouseItem={!werhoseList.includes("allWarehouses")}
            warehouseId={selectedWerhose?.Id}
            priceListId={PriceListObj?.Id}
            onHide={() => {
              setShowTree(false);
            }}
            itemCodeHandeler={itemCodeHandeler}
          />
        </ModalForm>
      )}

      {loading ? (
        <>
          <form className=" " onSubmit={submit}>
            {/*            <div className="" >
                    <FormWithTitle title="basePrice">
                        <div className="card-body">
                        <div className="row">
                            <div className="col-4 mt-0">
                            <CheckableButton
                                onchange={setFixedPrice}
                                type='checkradio'
                                labelText={trans("fixedPrice")}
                                labelFalseText={trans("PriceList")}
                                defaultChecked={fixedPrice }
                                checked={fixedPrice }
                                inputName='basePrice'
                                mode={mode}
                                />
                            </div>
                            {!fixedPrice&&
                                <div  className="col-md-4 col-sm-12" style={{display:"flex" , flexDirection:"row"}}  >
                                    <PriceListDictionary
                                      name="PriceList"
                                      label="PriceList"
                                      resetForm={resetForm}
                                      value={""}
                                      mode={mode}
                                    />         
                                </div>  
                            }
                        </div>      
                    </div>
                    </FormWithTitle>      
                </div> */}
            <div className="">
              <FormWithTitle title="filter">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-md-6 col-sm-12 row"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <WarehouseDictionarySelect
                        name="WarehouseDictionary"
                        setSelectedList={setWerhoseList}
                        selectedItemCallBack={setSelectedWerhose}
                        value={""}
                        mode={mode}
                      />
                    </div>
                    {!fixedPrice && (
                      <div
                        className="col-md-2 col-sm-12"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Textbox
                          Change={(e) => {
                            setUserChanges({ ...userChanges, ...e });
                          }}
                          textboxName={"MinOrderQty"}
                          textboxType="number"
                          resetForm={resetForm}
                          labelText={"MinOrderQty"}
                          mode={mode}
                          defaultValue={
                            itemCodeData?.[selectedWerhose?.Id]?.MinOrderQty
                          }
                        />
                      </div>
                    )}
                    {/*                         <div className="col mt-4">
                            <CheckableButton
                               defaultChecked={false}
                               defaultValue={ false}
                               labelText={"saveWarehouseInPriceList"}
                               inputName={"saveWarehouseInPriceList"}
                               type="checkbox"
                               mode={haveDetail? "view" : "create"}
                            />   
                        </div> */}
                  </div>
                  <div className="row">
                    <div className="col-4 mt-0">
                      <CheckableButton
                        onchange={(e) => {
                          setAll({ ...all, [selectedWerhose?.Id]: !e });
                        }}
                        type="checkradio"
                        labelText={trans("AllItems")}
                        labelFalseText={trans("limitedItems")}
                        defaultChecked={!all?.[selectedWerhose?.Id]}
                        checked={!all?.[selectedWerhose?.Id]}
                        inputName="itemCode"
                        mode={mode}
                      />
                    </div>
                    <div className="row">
                      {all?.[selectedWerhose?.Id] && (
                        <>
                          <div
                            className="col-md-4 col-sm-12 "
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Textbox
                              Change={(e) => {
                                setUserChanges({ ...userChanges, ...e });
                              }}
                              onChange={(e) => {
                                setItemCodeData({
                                  ...itemCodeData,
                                  [selectedWerhose?.Id]: {
                                    ...itemCodeData[selectedWerhose?.Id],
                                    From: e.target.value,
                                  },
                                });
                              }}
                              textboxName={"from"}
                              textboxType="text"
                              resetForm={resetForm}
                              labelText={"from"}
                              mode={mode}
                              defaultValue={
                                itemCodeData?.[selectedWerhose?.Id]?.From
                              }
                            />
                            <div style={{ marginTop: "4px", width: "10%" }}>
                              <Button
                                Icon={true}
                                value={
                                  <button
                                    onClick={(e) => addOption("From")}
                                    type="button"
                                    className="checkButton"
                                  >
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={[ICON_TYPE, "diagram-project"]}
                                      size="1x"
                                    />
                                  </button>
                                }
                                btnType="primary"
                                onClick={(e) => addOption("From")}
                              />
                            </div>
                          </div>
                          <div
                            className="col-md-4 col-sm-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Textbox
                              Change={(e) => {
                                setUserChanges({ ...userChanges, ...e });
                              }}
                              onChange={(e) => {
                                setItemCodeData({
                                  ...itemCodeData,
                                  [selectedWerhose?.Id]: {
                                    ...itemCodeData[selectedWerhose?.Id],
                                    To: e.target.value,
                                  },
                                });
                              }}
                              textboxName={"To"}
                              textboxType="text"
                              resetForm={resetForm}
                              labelText={"To"}
                              mode={mode}
                              defaultValue={
                                itemCodeData?.[selectedWerhose?.Id]?.To
                              }
                            />
                            <div style={{ marginTop: "4px", width: "10%" }}>
                              <Button
                                Icon={true}
                                value={
                                  <button
                                    onClick={(e) => addOption("To")}
                                    type="button"
                                    className="checkButton"
                                  >
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={[ICON_TYPE, "diagram-project"]}
                                      size="1x"
                                    />
                                  </button>
                                }
                                btnType="primary"
                                onClick={(e) => addOption("To")}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </FormWithTitle>
            </div>
            <div className="">
              <FormWithTitle title="price">
                <div className="card-body">
                  {!fixedPrice && (
                    <div className="col-4 mt-0">
                      <CheckableButton
                        onchange={setPrice}
                        type={"checkradio"}
                        labelText={trans("Price")}
                        labelFalseText={trans("Percent")}
                        defaultChecked={Price}
                        checked={Price}
                        inputName="Discount"
                        mode={mode}
                      />
                    </div>
                  )}
                  <div className="row">
                    <Message
                      message={trans("Help_PriceListUnit")}
                      type={"info"}
                    />

                    {Price || fixedPrice ? (
                      <>
                        <div
                          className="col-md-3 col-sm-12"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <PriceInput
                            onChange={(e) => {
                              if (e?.Id == -1) {
                                setNeedToCall(true);
                                setUnitPriceValue(-1);
                              } else {
                                setNeedToCall(false);
                                setUnitPriceValue(e);
                              }
                            }}
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            name={"UnitPrice"}
                            resetForm={resetForm}
                            label={"UnitPrice"}
                            mode={mode}
                            value={"-1"}
                          />
                        </div>
                        {!needToCall && (
                          <>
                            <div
                              className="col-md-3 col-sm-12"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Textbox
                                onChange={(e) => {
                                  // if(e.target.value.startsWith('-')){
                                  //   setUserChanges({...userChanges,DiscountPercent:'0'})
                                  //       } // prevent to enter negetiv number
                                  if (+e.target.value > 100) {
                                    setUserChanges({
                                      ...userChanges,
                                      DiscountPercent: "100",
                                    });
                                  }
                                }}
                                Change={(e) => {
                                  setUserChanges({ ...userChanges, ...e });
                                }}
                                textboxName={"DiscountPercent"}
                                textboxType="number"
                                max={100}
                                resetForm={resetForm}
                                labelText={"DiscountPercent"}
                                mode={mode}
                                value={userChanges?.DiscountPercent}
                                defaultValue={
                                  userChanges?.DiscountPercent
                                    ? userChanges?.DiscountPercent
                                    : itemCodeData?.[selectedWerhose?.Id]
                                        ?.DiscountPercent
                                }
                              />
                            </div>
                            {/*                         <div className="col-lg-2 col-xxl-2 col-sm-3 ">
                            <EnumerationTypeSelect
                               mode={mode} 
                               type="SelectBox" 
                               label="PriceRoundingPlace"
                               name="RoundingPlace"
                               enumTypeSelect="PriceRoundingPlace"
                               value={itemCodeData?.status} />
                        </div> */}
                          </>
                        )}

                        {(fixedPrice&&PriceListObj?.HasDetailRange) && (
                          <>
                            <div
                              className={
                                needToCall
                                  ? "col-md-3"
                                  : "col-md-2 ?  : mr-2 col-sm-12"
                              }
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Textbox
                                onChange={(e) => {
                                  if (e.target.value.startsWith("-")) {
                                    setUserChanges({
                                      ...userChanges,
                                      MinOrderQty: "0",
                                    });
                                  }
                                }}
                                Change={(e) => {
                                  setUserChanges({ ...userChanges, ...e });
                                }}
                                value={userChanges?.MinOrderQty}
                                textboxName={"MinOrderQty"}
                                textboxType="number"
                                resetForm={resetForm}
                                labelText={"MinOrderQty"}
                                min={0}
                                mode={mode}
                                defaultValue={
                                  userChanges?.MinOrderQty
                                    ? userChanges?.MinOrderQty
                                    : itemCodeData?.MinOrderQty
                                    ? itemCodeData?.MinOrderQty
                                    : "0"
                                }
                              />
                            </div>
                            <div
                              className={
                                needToCall ? "col-md-3" : "col-md-2 col-sm-12"
                              }
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Textbox
                                onChange={(e) => {
                                  if (e.target.value.startsWith("-")) {
                                    setUserChanges({
                                      ...userChanges,
                                      MaxOrderQty: "0",
                                    });
                                  }
                                }}
                                Change={(e) => {
                                  setUserChanges({ ...userChanges, ...e });
                                }}
                                value={userChanges?.MaxOrderQty}
                                textboxName={"MaxOrderQty"}
                                textboxType="number"
                                resetForm={resetForm}
                                labelText={"MaxOrderQty"}
                                min={0}
                                mode={mode}
                                defaultValue={
                                  userChanges?.MaxOrderQty
                                    ? userChanges?.MaxOrderQty
                                    : itemCodeData?.MaxOrderQty
                                    ? itemCodeData?.MaxOrderQty
                                    : null
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="col-md-3 col-sm-12"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            textboxName={"Percent"}
                            textboxType="number"
                            resetForm={resetForm}
                            labelText={"Percent"}
                            mode={mode}
                            defaultValue={itemCodeData?.DiscountPercent}
                          />
                        </div>
                        <div
                          className="col-md-3 col-sm-12"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Textbox
                            Change={(e) => {
                              setUserChanges({ ...userChanges, ...e });
                            }}
                            textboxName={"DiscountPercent"}
                            textboxType="number"
                            resetForm={resetForm}
                            labelText={"DiscountPercent"}
                            mode={mode}
                            defaultValue={itemCodeData?.DiscountPercent}
                          />
                        </div>

                        {/*                     <div className="col-lg-2 col-xxl-2 col-sm-3 mt-1">
                        <EnumerationTypeSelect
                           mode={mode} 
                           type="SelectBox" 
                           label="PriceRoundingPlace"
                           name="RoundingPlace"
                           enumTypeSelect="PriceRoundingPlace"
                           value={itemCodeData?.status} />
                    </div> */}
                      </>
                    )}
                  </div>
                </div>
              </FormWithTitle>
            </div>

            {mode != "view" ? (
              <div className="col-lg-12 mt-1 btns_form">
                <Button value="Save" btnType="submit" />
                <Button btnType="cancel" value="Cancel" onClick={onHide} />
              </div>
            ) : (
              <div className="col-lg-12 mt-1 btns_form">
                <Button btnType="primary" value="Close" onClick={onHide} />
              </div>
            )}
          </form>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
