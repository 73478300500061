
import { useEffect, useState } from "react";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { TableTree } from "components/TableTree/TableTree";
import { Toolbar } from "components/Toolbar/Toolbar";
import { trans } from "utils/helpers";
import { addNewRolesAsync, deletRolesAsync, getExceptOrganizationRolesList, getOrganizationRolesList } from "../../api/api";
import { AddRoleForm } from "./AddRoleForm";
import { Toast } from "components/ToastShow/ToastShow";

interface propsOrganizationalStructure {
  selectedObj:any
}
export const OrganizationRoles = ({selectedObj}:propsOrganizationalStructure) => {
  const [data, setData] = useState<any>()
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAddForm, setShowAddForm] = useState(false)
  const [selected, setSelected] = useState<any>()
  const [idCallback, setIdCallback] = useState<any>("2")
  const [messageType, setMessageType] = useState<any>("info")
  const [selectedList, setSelectedList] = useState<any>([])
  const [treeType, setTreeType] = useState<any>(false)
  const [ListResponse, setListResponse] = useState<any>()
  const [exceptListResponse, setExceptListResponse] = useState<any>()
  const [search, setsearch] = useState(null);
  const [defultShow, setDefultShow] = useState(false);


  let theadData: any = []
  let obj: any = {}
  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "tree":setTreeType(!treeType);break;
      case "print":alert("print ");break;
      case "remove":deletListAsync();break;
      case "copy":copyList();break;
      case "paste":pasteList();break;
      // case "excel":alert("excel ");break;
      case "create":setShowAddForm(!showAddForm);break;
      case "refresh":setForceUpdate(!forceUpdate);break;

    }
  };



  async function addNewRoles(List) {
 
    let addList:any = []
    if(ListResponse){

      Object.keys(List).forEach((item , index )=> {
        if(!Object.keys(ListResponse).includes(item) ){
          addList.push(item)
        }
    
      });
    }else{
      addList = Object.keys(List)
    }
  
    
      try {
          
          const response = await addNewRolesAsync(selectedObj.Id,addList )
          setForceUpdate(!forceUpdate)

      } catch (err: any) {
          
      }
      
  }




  function pasteList() {
   const List:any = localStorage.getItem("storageCopy" )
   addNewRoles(JSON.parse(List) )

}
  function copyList() {
 
    Toast("TextCopiedToClipboard" , "success")
    localStorage.setItem("storageCopy" ,JSON.stringify(ListResponse) )

}
  function isIn(name) {
    let flag = false
    exceptListResponse.forEach(item => {
      if(item.includes(name+".")){
        flag = true
        return true
      }
    });
    return flag
}


  function set(name, value) {
    var obj2 = obj;  
    var nameList = name.split('.');
    let inList =false
    var len = nameList.length;
    let elem = nameList[0];
    for(var i = 0; i <len; i++) {
      if(!isIn(elem)||inList){
        if(i <len-1){
          inList = true
          if( !obj2[elem] ){
  
            obj2[elem] = {}
          } 
          obj2 = obj2[elem];

            elem = nameList[i+1];
          }

        }else{
          if(i <len){
          elem += "."+nameList[i+1];
          }
        }
    }
    if(inList){
      obj2[nameList[len-1]] = value;
      
    }else{
      
      obj2[elem] = value;
    }
}

function setTree(obj2 , parentId) {
  let list:any = Object.keys(obj2)
  let tree:any = []
  
  if(typeof obj2 != "string"){
  list.forEach(item=>{
    tree.push({
      Id :  parentId+item ,
      parentId : parentId ,
      Children : setTree(obj2[item] , parentId+item+"." ) ,
      Description : item
    })
  })

  }
  return tree
}

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};


async function getListAsync() {
  setLoading(false)
  let newTypeObject:any = {}
  try {
  const response = await getOrganizationRolesList(selectedObj.Id , "")
  if(response.Data){
    if(treeType){
        Object.keys(response.Data).forEach(item => {
          var nameList = response.Data[item].split('.');
          let ty = array_move(nameList, 3, 1)
          newTypeObject = Object.assign(newTypeObject,{[item] :ty.join(".") })
          set( ty.join("."),  item);
        });
        setListResponse(newTypeObject)
      }else{
        setListResponse(response.Data)
        if(response.Data){
          Object.keys(response.Data).forEach(item => {
            set( response.Data[item],  item);
          });
  
        }
      }
      let tabletr =  setTree(obj , "")
      if(tabletr.length >0){
        setData(tabletr)
      }else{
        
      }

  }else{
    setData(null)
  }
    } catch (err: any) {
        setMessageType("error")
  }
  setLoading(true)
}


async function getExceptListAsync() {
  setLoading(false)
 try {
  const response = await getExceptOrganizationRolesList(selectedObj.Id, "")
  let newTypeObject:any = {}
  setExceptListResponse(response.Data ?Object.values(response.Data) :[])
  } catch (err: any) {
   
  }
  setLoading(true)
}


async function deletListAsync(){

  let newList:any = []
  selectedList.forEach((item , index)=>{
  Object.values(ListResponse)?.forEach((item2:any,index2) => {
    if((item.split(".").length - 1 ==3 &&item2.includes(item) )|| item2.includes(item+".")){

      newList.push(Object.keys(ListResponse)[index2])

    }

    
  });
  
  })
  try {
    if(newList.length > 0){
    Toast(trans(`msg_deleteitemsconfirm`) , "warning" , "alert" , async()=>{await  deletRolesAsync(selectedObj.Id, newList);  setForceUpdate(!forceUpdate)} )
  }else{
    Toast(trans("Msg_SelectRow"), "warning")
  }
  } catch (err: any) {
   
  }

}

const Searchhandleer = (str) => {
  if (str === "") {
      setsearch(null)
      setDefultShow(false)

  } 
      setsearch(str)
      setDefultShow(true)
      if(ListResponse && ListResponse !== undefined){
        setLoading(false)
         obj = {}
         setData(null)
        Object.keys(ListResponse).forEach(item => {
          if(str!= ""){
            let flag = true
            ListResponse[item].split('.').forEach(word=>{
              if(flag && trans(word).includes(str)){
                flag = false
                return ;
              }
            })
            if(!flag){
              set( ListResponse[item],  item);
    
            }
    
          }else{
            set( ListResponse[item],  item);
    
          }
        });
  
        let tabletr =  setTree(obj , "")
        if(tabletr.length >0){
    
          setData(tabletr)
        }
        setLoading(true)
  }
}
  useEffect(() => {
    setData(null)
    setListResponse(null)
    setExceptListResponse(null)
  getExceptListAsync()
    
  }, [forceUpdate , treeType]);

  useEffect(() => {
    if(exceptListResponse){

      getListAsync()
    }else{
  //    setLoading(true)
    }
    
  }, [exceptListResponse]);
  


  if (data) {
    theadData = Object.keys(data[0])
  }



  return (
    <>
    {showAddForm ?<ModalForm mode={"edit"} onHide={()=>{setShowAddForm(!showAddForm)}} show={showAddForm} modalProfileKey={"Users"} title={trans("AvailableAccessRoles")} ><AddRoleForm selectedObj={selectedObj} treeType={treeType} reload={()=>{setForceUpdate(!forceUpdate)}  } onHide={setShowAddForm}  /> </ModalForm>  : null}
    <div className="p-2">
      <Toolbar excel={false} emptyList={data?.length==0} handleSearch={Searchhandleer} copy={true} paste={true} Tree={true} id={selected} create={true} remove={true} view={false} search={true} handleClickToolbar={clickToolbarCallback} />
      <div style={{overflowY: "auto", 
          height: "50vh",
           scrollbarWidth: "thin"}}  className=" col-lg-12 mt-0">
        <div className="list-card card">
          <>
            {
              loading ? (
                data ?
                  <TableTree
                    search={search}
                    defultShow={false}
                    ListResponse={ListResponse}
                    multiselect={true}
                    selectedRowObjectCallback={setSelected}
                    selectedRowObject={idCallback}
                    //getChildrenUrl={ENDPOINTS.BaseInformation.organization.getNode}
                    Data={data}
                    theadData={theadData}
                    parentName={"UsersAccess"}
                    startIndex={2}
                    selectedRowsCallback={setSelectedList}

                  />
                  : <Message message={"NoDataForPreview"} type={messageType} />
              )
                : <Loading />
            }
          </>
        </div>
      </div>
    </div>
    </>
  )
}