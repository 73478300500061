import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { CustomerPositionDictionary } from "components/CustomerPosition/CustomerPosition";
import DatePicker from "components/DatePicker/DatePicker";
import { FollowupPositionId } from "components/FollowupPositionId/FollowupPositionId";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Price } from "components/Price/Price";
import { Textbox } from "components/Textbox/Textbox";
import { Time } from "components/Time/Time";
import { LayoutContext } from "layouts/DashboardLayout/context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import { OrganizationType } from "types/enum";
import { ICON_TYPE } from "utils/constants";
import { trans } from "utils/helpers";
import { checkValidate, getRequest, getRequestById, postServiceRequest } from "./api";
import {  ItemsDictionary } from "./components/ItemsDictionary/ItemsDictionary";
import { Serials } from "./components/Serials/Serials";
import { Toast } from "components/ToastShow/ToastShow";
import { FileInput } from "components/FileInput/FileInput";
import { CustomerInfo } from "pages/AfterSales/component/CustomerInfo/CustomerInfo";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { ENDPOINTS } from "utils/endpoints";


interface props {
    mode?: any,
    onHide?: (usenavigate?) => void,
    reload?: () => void,
    processId?: any,
    jobId?: any,
    Id?: any,
    referenceId?: any,
    step?: any,
    showInDetail?: any,
    JobNo?: any,
    inDynamicForms?: any;

}

export const ServiceRequestForm = ({ inDynamicForms=false , showInDetail = false, JobNo, step = 1, mode = "create", onHide, reload, processId, referenceId, jobId ,Id }: props) => {
   
    const layoutContext = useContext(LayoutContext);
    const [RequestDate, setServiceRequestDate] = useState<any>();
    const [serviceRequest, setServiceRequest] = useState<any>();
    const [showStep, setShowStep] = useState<any>({3:true});
    const [serialId, setSerialId] = useState<any>();
    const [serialName, setSerialName] = useState<any>();
    const [correctedSerialId, setCorrectedSerialId] = useState<any>();
    const [position, setPosition] = useState<any>(null);
    const [itemsId, setItemsId] = useState<any>("");
    const [correctedItemDescription, setCorrectedItemDescription] = useState<any>("");
    const [validatePosition, setValidatePosition] = useState<any>(false);
    const [CustomerPosition, setCustomerPosition] = useState<any>(null);
    const [itemsValid, setItemsValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<any>("invalid position");
    const [messageType, setMessageType] = useState<any>(null);
    const [userChanges, setUserChanges] = useState({});
    const [selectedImage, setSelectedImage] = useState<any>();
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);

    const changeHandlePic = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files);
        }
    };

    const handleSubmit = async(event) => {  
        event.preventDefault()
        if (itemsId == undefined || itemsId == " " ) {
            setItemsValid(true)
        } else {
            setShowStep({1:false,2:false,3:false})
            if (mode === "edit") {
                let data = {
                    "Id": serviceRequest.Id,
                    "RequesterPositionId": serviceRequest.RequesterPositionId,
                    "Applicant": serviceRequest.Applicant,
                    "RequesterMobileNumber": serviceRequest.RequesterMobileNumber,
                    "WorkHours": serviceRequest.WorkHours,
                    "ItemDescription": serviceRequest.ItemDescription,
                    "Serial": serviceRequest.Serial,
                    "Description": serviceRequest.Description,
                    "ExtraDescription": serviceRequest.ExtraDescription,
                    "InstallationLocation": serviceRequest.InstallationLocation,
                    "FollowupResponsiblePositionId": event.target.FollowupResponsiblePositionId.value.length > 0 ? event.target.FollowupResponsiblePositionId.value : null,
                    "CustomerServiceRequestClassificationId": event.target.CustomerServiceRequestClassificationId.value,
                    "PaymentCoverageTypeId": event.target.PaymentCoverageTypeId.value,
                    "EstimateTime": +event?.target.EstimateTime[0].value,
                    "EstimatePayment": +event?.target.EstimatePayment.value.replaceAll(",", ""),
                    "EstimateDescription": event?.target.EstimateDescription.value,

                    "CorrectedItemDescription": correctedItemDescription!=serviceRequest.ItemDescription ? correctedItemDescription :null,
                    "CorrectedSerial":correctedSerialId!= serviceRequest.Serial ? correctedSerialId :null  ,
                    "CorrectedDescription": (correctedItemDescription==serviceRequest.ItemDescription&&correctedSerialId== serviceRequest.Serial)? null: event.target?.CorrectedDescription?.value,
                }
                sendDataAsync(data, "put");
            } else {
                let data = {
                    "PerformerPositionId":(position && position?.Id)? position?.Id   : layoutContext.currentUserPosition.PositionId,
                    "CustomerServiceRequest": {
                        "RequesterPositionId": position?.Id ,
                        "Applicant": (serialName &&position&& serialName.includes(position?.Name.split("-")[0].trim())) ? event.target.applicant.value : event.target.applicant.value + '_' + serialName,
                        "RequesterMobileNumber": event.target.RequesterMobileNumber.value ,
                        "WorkHours": event.target.WorkHours.value ,
                        "RequestDate": RequestDate,
                        "ItemDescription": itemsId ? itemsId : event?.target?.ItemId?.value,
                        "Serial": serialId ? serialId : event?.target?.Serials?.value,
                        "ItemId": null,
                        "Description": event.target.Description.value,
                        "InstallationLocation": event.target.InstallationLocation.value,
                    }
                }
                let _objData = new FormData
                _objData.append("CustomerServiceRequestDTO", JSON.stringify(data));
                if (selectedImage) {
                    const ListSelected:any = [...selectedImage]
                    ListSelected.map(item=>{
                        _objData.append("Files", item);
                    })
                }
                sendDataAsync(_objData, "post");
            }
        }
    }
    async function sendDataAsync(data, method) {
        try {
            await postServiceRequest(data, method, processId);
            onHide && onHide(true);
            reload && reload()
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
        } catch (err: any) {
            sessionStorage.setItem("changingforms" , "false");
            setUserChanges({});
        }
    }

    function cancel() {
        if(sessionStorage.getItem("changingforms")+""=="true"){
            Toast(trans("modalclosemessage"),"warning","alertWithoutClose", ()=>{
                sessionStorage.setItem("changingforms" , "false");
                onHide && onHide();
            });
        }else{
            onHide && onHide();
        };


    }

    async function getRequestAsync() {
        setMessageType(null)
        setLoading(false)
        try {
            if(Id){
                const res = await getRequestById(Id);
                setServiceRequest(res.Data)
                setCorrectedSerialId(res.Data.CorrectedSerial)
                setCorrectedItemDescription(res.Data.CorrectedItemDescription)
            }else if (referenceId) {
                const res = await getRequest(referenceId ? referenceId : jobId);
                setServiceRequest(res.Data)
                setCorrectedSerialId(res.Data.CorrectedSerial)
                setCorrectedItemDescription(res.Data.CorrectedItemDescription)
            }
        } catch (err: any) {
            setMessage(err?.data?.Message)
            setMessageType("error")
        }
        setLoading(true)
    }
    async function checkValidateAsync() {
        setLoading(false)
        try {
            const res = await checkValidate();
            if(res.Data == null){
                setValidatePosition(true)
            }else{
                setCustomerPosition(res.Data)
            }
        } catch (err: any) {
            setValidatePosition(null)
        }
        setLoading(true)
    }

    const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = '';
      };


    useEffect(() => {
        if(Object.values(userChanges).filter((item)=>item!=null).length>0){
            sessionStorage.setItem("changingforms" , "true")
              window.addEventListener('beforeunload', handleBeforeUnload);
              return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
              };
        }else{
            sessionStorage.setItem("changingforms" , "false")
            window.removeEventListener('beforeunload', handleBeforeUnload);

        }
    }, [userChanges]);

    useEffect(() => {

        if(mode == "create" ){
            checkValidateAsync()
        }else{
            setCustomerPosition("")
        }
        if (Id || referenceId) {
            getRequestAsync();
        }
    }, [Id, referenceId]);

    return (
        <>
             {showCustomerInfo &&
                <ModalForm checkClose={false} mode={"view"} title={("CustomerInfo")} onHide={() => { setShowCustomerInfo(false) }} show={showCustomerInfo}>
                    <CustomerInfo   customerPositionId={serviceRequest?.RequesterPositionId}  />
                </ModalForm>
            }
        {loading ?  
            <>{(validatePosition !=null && messageType ==null) ?
                <form onSubmit={handleSubmit} className={"printForm "+mode}>
                    <div className="m-2">
                        {(serviceRequest?.ExtraDescription&&layoutContext.currentUserPosition.OrganizationType != OrganizationType.Customers)&&
                            <Message title="insertnote" message={serviceRequest?.ExtraDescription} type={"info"} />

                        }
                <div className={`${(step > 1 || showInDetail) && "card"} card mb-3 `}>
                    {(step > 1 || showInDetail) &&
                        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                            {showInDetail ?
                                <div>{trans("ServiceRequest")} - {JobNo}</div>
                                :
                                <div>{trans("step")}-1 </div>
                            }
                            <div style={{ marginLeft: "18px" }} onClick={() => { showStep[1] ? setShowStep({ ...showStep, 1: false }) : setShowStep({ ...showStep, 1: true }) }} >{showStep[1] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                        </div>
                    }
                  
                        <div style={{display: !showStep[1] ?"block" :"none"}} className="card-body" >
                            <div className="row">
                                {mode != "create" &&
                                    <div className="col-12 col-sm-2 mb-2 mb-sm-0">
                                        <DatePicker
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            required={true}
                                            lable="RequestDate"
                                            setIsoTime={(isoTime: string) => { setServiceRequestDate(isoTime); }}
                                            val={ serviceRequest?.RequestDate ? serviceRequest?.RequestDate  : null} //resetForm ? new Date().toISOString() : 
                                            mode={"view"}
                                        />
                                    </div>
                                }
                                {
                                <div style={{display:"flex"}} className="col-12 col-sm-3 mb-2 mb-sm-0">
                                    <div className={(jobId&&mode!="create")?"col-10":"col-12"}>
                                    <CustomerPositionDictionary
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        onChange={setPosition}
                                        name="RequesterPositionId"
                                        required={true}
                                        label="asker"
                                        mode={layoutContext.currentUserPosition.OrganizationType == OrganizationType.Customers ? "view" :   (step > 1 ? "view" :  mode)}
                                        value={mode == "create" ?( layoutContext.currentUserPosition.PositionId ): serviceRequest?.RequesterPositionId}
                                    />
                                    </div>
                                    {(jobId&&mode!="create")&&
                                        <Button Icon={true} value={<button type="button" style={{marginTop:"21px"}} onClick={(e)=>{setShowCustomerInfo(true)}} className="checkButton  d-flex align-items-center justify-content-center" > <FontAwesomeIcon icon={[ICON_TYPE, "info"]} style={{fontSize:"15px"}}  size="1x"  /></button>}  btnType="primary"onClick={(e)=>{}}   />
                                    }
                                </div>

                                }
                                <div className="col-12 col-sm-3 mb-2 mb-sm-0">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxType="text"
                                        textboxName="applicant"
                                        required={true}
                                        labelText="applicant"
                                         
                                        defaultValue={serviceRequest?.Applicant}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 mb-2 mb-sm-0">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxType="mobile"
                                        required={true}
                                        textboxName="RequesterMobileNumber"
                                        labelText="RequesterMobileNumber"
                                         
                                        defaultValue={serviceRequest?.RequesterMobileNumber}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 mb-2 mb-sm-0">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxType="text"
                                        textboxName="WorkHours"
                                        labelText="WorkHours"
                                         
                                        defaultValue={serviceRequest?.WorkHours}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {
                                    ((position?.Id?.length > 0 ||CustomerPosition != null) && step <= 1 && mode !== "edit") &&
                                <div className="col-12 col-sm-4">
                                    <Serials
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        customerPositionId={position?.Id}
                                        setSerialName={setSerialName}
                                        setSerialId={setSerialId}
                                        type="SelectBox"
                                        name="Serials"
                                        CorrectedSerial={step >1 ? null:serviceRequest?.CorrectedSerial}
                                        value={serviceRequest?.Serial}
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                                }
                                {(  step <= 1 && mode == "create") &&
                                    <div className="col-12 col-sm-4">
                                        {serialId ?
                                        <ItemsDictionary
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            customerPositionId={position?.Id}
                                            itemsValid={itemsValid}
                                            setItemsId={(e) => { setItemsId(e); setItemsValid(false) }}
                                            serialId={serialId}
                                            type="SelectBox"
                                            name="ItemId"
                                            value={serviceRequest?.ItemId}
                                            mode={step > 1 ? "view" : mode}
                                            required={true}
                                        />
                                        :
                                        <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        textboxName="ItemId"
                                        labelText="Items"
                                        textboxType="text"
                                        required={true}
                                        defaultValue={serviceRequest?.ItemId }
                                         
                                        mode={step > 1 ? "view" : mode}
                                    />
                                    }
                                    </div>
                                }
                                {(step > 1 || mode != "create") &&
                                    <div className="col-12 col-sm-4">
                                        <Textbox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            textboxName="ItemDescription"
                                            labelText="ItemDescription"
                                            textboxType="text"
                                            ExtraDescription={(step <=1&&serviceRequest?.CorrectedItemDescription?.length>1) ?true:false}
                                            recordedData={(step <=1&&serviceRequest?.CorrectedItemDescription?.length>1) ? serviceRequest?.ItemDescription:null}
                                            defaultValue={(step <=1&&serviceRequest?.CorrectedItemDescription?.length>1) ? serviceRequest?.CorrectedItemDescription:serviceRequest?.ItemDescription }
                                            minHeight={mode === "view" ? 2 : 3}
                                             
                                            mode={step > 1 ? "view" : mode}
                                        />
                                    </div>
                                }
                                {(step <= 1&& serviceRequest?.CorrectedDescription)&&
                                <div className="col-12 col-sm-4">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        defaultValue={serviceRequest?.CorrectedDescription}
                                        textboxName="CorrectedDescription"
                                        labelText="CorrectedDescription"
                                        textboxType="text"
                                        minHeight={mode === "view" ? 2 : 3}
                                         
                                        mode={step > 1 ? "view" : mode}

                                    />
                                </div>
                                }
                                <div className="col-12 col-sm-4">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        defaultValue={serviceRequest?.InstallationLocation}
                                        textboxName="InstallationLocation"
                                        labelText="InstallationLocation"
                                        textboxType="text"
                                        minHeight={mode === "view" ? 2 : 3}
                                         
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <Textbox
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        required={true}
                                        defaultValue={serviceRequest?.Description}
                                        textboxName="Description"
                                        labelText="Description"
                                        textboxType="textarea"
                                        minHeight={mode == "view" ? 2 : 3}
                                         
                                        mode={step > 1 ? "view" : mode}
                                    />
                                </div>
                            </div>
                            <div className="row mb-1">
                        {(mode=="create") &&
                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <FileInput
                                hideSrc={selectedImage ?false :true}
                                multiple={true}
                                mode={ mode}
                                lable="picture"
                                name="picture"
                                onChange={changeHandlePic}
                                src={selectedImage  ? (URL.createObjectURL(selectedImage[0] )) :""}
                                />
                            </div>
                        }
                    </div>
                        </div>
                    
                </div>
                {(step == 2 && !showInDetail) &&
                    <div className={`card   `}>
                        <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                            <div>{trans("step")}-2 </div>
                            <div style={{ marginLeft: "18px" }} onClick={() => { showStep[2] ? setShowStep({ ...showStep, 2: false }) : setShowStep({ ...showStep, 2: true }) }}>{showStep[2] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                        </div>
                        
                        <div style={{display: !showStep[2] ?"block" :"none"}} className="card-body" >
                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <DictionarySelectBox
                                            label="requestclassification"
                                            endPoint={ENDPOINTS.afterSalesApi.customerServiceRequestClassification.dictionary}
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            name="CustomerServiceRequestClassificationId"
                                            value={serviceRequest?.CustomerServiceRequestClassificationId}
                                            mode={mode}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                        <FollowupPositionId
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            name="FollowupResponsiblePositionId"
                                            label="FollowupResponsiblePosition"
                                            mode={mode}
                                            value={serviceRequest?.FollowupResponsiblePositionId}
                                             

                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <DictionarySelectBox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            name="PaymentCoverageTypeId"
                                            label="PaymentCoverageType"
                                            endPoint={ENDPOINTS.afterSalesApi.PaymentCoverageType.dictionary}
                                            value={serviceRequest?.PaymentCoverageTypeId}
                                            mode={mode}
                                        />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-4 col-lg-4 mt-2 mt-lg-0">
                                        <Price
                                            mode={mode}
                                            name="EstimatePayment"
                                             
                                            defaultValue={serviceRequest?.EstimatePayment}
                                            label="EstimatePayment"
                                        />
                                    </div>
                                    <div className="col-4 col-lg-4 mt-2 mt-lg-0">
                                        <Time
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            mode={mode}
                                            name="EstimateTime"
                                            IdString="EstimateTime"
                                             
                                            defaultValue={serviceRequest?.EstimateTime}
                                            label="EstimateTime"
                                        />
                                    </div>
                                    <div className="col-4 col-sm-4">
                                        <Textbox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            defaultValue={serviceRequest?.EstimateDescription}
                                            textboxName="EstimateDescription"
                                            labelText="EstimateDescription"
                                            textboxType="textarea"
                                            minHeight={mode == "view" ? 2 : 3}
                                             
                                            mode={mode}
                                        />
                                    </div>
                             </div>
                             {((mode=="view"&&((serviceRequest?.CorrectedItemDescription!=undefined &&serviceRequest?.CorrectedItemDescription!= serviceRequest.ItemDescription)||(serviceRequest?.CorrectedSerial!=undefined &&serviceRequest?.CorrectedSerial!= serviceRequest.Serial)))||(mode!="view"))&&
                            <div className="card">
                                <div style={{ backgroundColor: "#cfe2ff", paddingRight: "20px", justifyContent: "space-between" }} className="d-flex  py-1 col-12 pr-4">
                                    <div>{trans("CorrectSelectedItem")}</div>
                                    <div style={{ marginLeft: "18px" }} onClick={() => { showStep[3] ? setShowStep({ ...showStep, 3: false }) : setShowStep({ ...showStep, 3: true }) }}>{showStep[3] ? <FontAwesomeIcon icon={[ICON_TYPE, "angle-down"]} size="lg" className="icon" /> : <FontAwesomeIcon icon={[ICON_TYPE, "angle-up"]} size="lg" className="icon" />}</div>
                                </div>
                                <div style={{display: !showStep[3] ?"block" :"none"}} className="card-body" >
                                <div className="row">
                                <div className="col-12 col-sm-4">
                                    <Serials
                                        Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                        customerPositionId={serviceRequest?.RequesterPositionId}
                                        setSerialId={setCorrectedSerialId}
                                        label="CorrectedSerial"
                                        type="SelectBox"
                                        name="CorrectedSerial"
                                        value={serviceRequest?.CorrectedSerial ? serviceRequest?.CorrectedSerial : serviceRequest?.Serial }
                                        mode={mode}
                                    />
                                </div>
                                {(correctedSerialId||correctedItemDescription) &&
                                    <div className="col-12 col-sm-4">
                                        <ItemsDictionary
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            customerPositionId={serviceRequest?.RequesterPositionId}
                                            itemsValid={itemsValid}
                                            setItemsId={(e) => { setCorrectedItemDescription(e); setItemsValid(false) }}
                                            serialId={correctedSerialId}
                                            type="SelectBox"
                                            name="ItemId"
                                            label="CorrectedItemDescription"
                                            value={serviceRequest?.CorrectedItemDescription? serviceRequest?.CorrectedItemDescription : serviceRequest?.ItemDescription}
                                            mode={mode}
                                        />
                                    </div>
                                }
                                {(correctedSerialId||correctedItemDescription) &&
                                    <div className="col-12 col-sm-4">
                                        <Textbox
                                            Change={(e)=>{setUserChanges({...userChanges , ...e})}}
                                            defaultValue={serviceRequest?.CorrectedDescription}
                                            textboxName="CorrectedDescription"
                                            labelText="CorrectedDescription"
                                            textboxType="textarea"
                                            minHeight={mode == "view" ? 2 : 3}
                                             
                                            mode={mode}
                                        />
                                    </div>
                                }
                                </div>
                            </div>
                            </div>
                             }
                        </div>
                        
                    </div>


                }



                    </div>
                    <div className="row">
                {!showInDetail &&
                    <div className="d-flex justify-content-end">
                        {mode != "view" ? (
                            <>

                                <Button btnType="submit" value={step > 1 ? "save" : "send"} />
                                {!inDynamicForms&&
                                
                                <Button
                                btnType="cancel"
                                value="cancel"
                                onClick={() => { cancel() }}
                                />
                            }
                            </>

                        ) : (<></>)
                        }
                    </div>
                }
                    </div>
                </form>
                :
                <Message message={trans(message)} type={"error"} />

                }
                </>
        :<Loading/>}
        </>
    )
}