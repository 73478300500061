
import { INVENTORYANDSALES_URL } from "utils/constants";
import { ENDPOINTS, ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
import { http } from "utils/http";


export const getCustomers = (async(pageNumber,pageSize,searchString,orderByString,filterString) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.browse,
    method: 'get',
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) =>{

    return res.data
}).catch((err) =>{
    return Promise.reject(err);
})

)


export const getCustomer = (async (id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.load,
    method: 'get',
    params: { Id: id }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)
export const getNextCustomerCode = (async () => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.nextCode,
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)
export const getGeographicalClassification = (async (id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseDefinitionAndSetting.GeographicalClassification.getId,
    params:{
        id
    },
    method: 'get',
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

)


export const postCustomer = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.browse,
        method: 'post',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})



export const putCustomer = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.browse,
        method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})
export const importCustomer = (data,organizationId
) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.Import,
    method: 'post',
    data: data,
    params:{
        organizationId

    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const deleteCustomer = (id) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.browse,
        method: 'DELETE',
    params: {
        id :id
    }
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})

export const getPersonApi = (async (search, id) => await http({

    url: id.length >1 ?  ENDPOINTS.BaseInformation.person.dictionaryId :  ENDPOINTS.BaseInformation.person.dictionary,
    method: 'get',
    params: {
        SearchString: search,
        Id: id
    }
})  
    .then((respons) => (respons.data.Data))
)
export const getEditablecolumns = (async () => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.getEditableColumns,
    method: 'get',

})  
    .then((respons) => (respons.data.Data))
)
export const putEditableList = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Sales.BaseInformationApi.Customer.putEditableColumns,
        method: 'put',
    data: data
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
})
