    import { INVENTORYANDSALES_URL } from "utils/constants";
    import { ENDPOINTSINVENTORYANDSALES } from "utils/endpoints";
    import { http } from "utils/http";

export const getListWarehouseItem = (async(pageNumber,pageSize,orderByString,searchString,filterString ,warehouseId) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.browse,
    method: 'get',
    params:{
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
        warehouseId:warehouseId,
    }
}).then((res) =>{

    return res.data
}).catch((err) =>{
    return Promise.reject(err);
})

)

export const getWarehouseItem = (async (Id) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.load,
    method: 'get',
    params: {Id : Id}
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})

)


export const postWarehouseItemApi = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.bulk,
    method: "post",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
    return Promise.reject( err);
})

export const putWarehouseItemApi = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.editList,
    method: "put",
    data:data,
}).then((res) => {

    return res.data
}).catch((err) => {
 
    return Promise.reject( err);
})


export const getWarehouseItemDictionary = (async (id,provinceId,search) => await http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.load,
    method: 'get',
    params: {
        ProvinceId:provinceId,
        Id:id,
        searchString:search,
    }
})
    .then((response) => (response.data))

)
export const getWarehouseItemTree = (id: any,warehouseDetailId ) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.ItemsTree,
    method: "get",
    params: {
        ParentId: null ,
        warehouseId:id=="allWarehouses"? null: id ,
        
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});
export const getWarehouseItems = (id: any ,warehouseDetailId  ,pageNumber,pageSize,orderByString,searchString,filterString) => http({
    baseURL:INVENTORYANDSALES_URL,
    url:ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.ItemsList,
    method: "get",
    params: {
        ParentId: null ,
        warehouseId: id,
        PageNumber:pageNumber,
        PageSize:pageSize,
        OrderByString:orderByString,
        SearchString:searchString,
        FilterString:filterString,
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const checkHasItemGroup = (id: any) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItemGroup.hasItemGroup,
    method: "get",
    params: {
        warehouseId:id
    }
}).then((res) => {
    return res.data
}).catch((err) => {
    return Promise.reject(err.response  );
});

export const deleteItemGroupTree = (data) => http({
    baseURL:INVENTORYANDSALES_URL,
    url : ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.browse,
    method: 'delete',
    data: data
    
}).then((res) => {

    return res.data
}).catch((err) => {

    return Promise.reject(err);
});


export const getEditablecolumns= () => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.editable,
    method: "get",


}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })

 export const getWarehouseItemDetail= (itemId) => http({
    baseURL:INVENTORYANDSALES_URL,
    url: ENDPOINTSINVENTORYANDSALES.Inventory.BaseInformationApi.WarehouseItem.itemDetail,
    method: "get",
    params:{
    itemId
    }
}).then(response => response.data)
 .catch(err => {
     return Promise.reject(err);
 })



