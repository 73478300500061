import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button/Button";
import { Loading } from "components/Loading/loading";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { UnitDictionary } from "pages/InventoryAndSales/Inventory/BaseDefinitionAndSetting/Unit/components/UnitDictionary";
import { useEffect, useState } from "react";
import { ICON_TYPE } from "utils/constants";
import { roundOrTruncate, trans, uuidv4 } from "utils/helpers";

import { getPriceListDetail } from "pages/InventoryAndSales/Sales/SalesOrder/api";
import { ItemsForSell } from "pages/InventoryAndSales/Sales/components/ItemsForSell/ItemsForSell";
import { PriceDetails } from "pages/InventoryAndSales/Sales/components/PriceDetails";

interface CustomerSalesOrderFormProps {
  onHide: () => void;
  CustomerSalesOrderDetailCallBack?: (e: any) => void;
  selectedObj?: any;
  mode: any;
  priceList?: any;
  lastWarehouse?: any;
  OrderConstraint?: any;
  setLastWarehouse: (e: any) => void;
  CustomerSalesWarehouse: any;
  personId?: any;
}

export const CustomerSalesOrderDetailForm = ({
  lastWarehouse = null,
  OrderConstraint,
  setLastWarehouse,
  priceList,
  selectedObj,
  mode,
  onHide,
  CustomerSalesOrderDetailCallBack,
  CustomerSalesWarehouse,
  personId,
}: CustomerSalesOrderFormProps) => {
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState<any>(true);
  const [userChanges, setUserChanges] = useState({});
  const [showTree, setShowTree] = useState<any>();
  const [priceListDetail, setPriceListDetail] = useState<any>([]);
  const [detailPriceData, setDetailPriceData] = useState<any>({
    Price: 0,
    Discount: 0,
    SubTotalPrice: 0,
    TaxRate: 0,
    Qty: mode != "create" ? selectedObj?.Qty : null,
  });
  const [itemData, setItemData] = useState<any>(null);
  const [rate, setRate] = useState<any>(1);
  const [dictionaryName, setDictionaryName] = useState<any>({});

  const [save_Exit, setSave_Exit] = useState<boolean>(false);

  const handleCustomerSalesOrderFormSubmit = async (event) => {
    event.preventDefault();
    let data = {
      Id: mode != "create" ? selectedObj?.Id : uuidv4(),
      CustomerSalesOrderId: selectedObj?.CustomerSalesOrderId,
      ItemId: itemData?.ItemId,
      UnitId:
        event.target.UnitId?.value == "" ? null : event.target.UnitId?.value,
      SourceId: selectedObj?.SourceId,
      SourceData: selectedObj?.SourceData,
      Item: itemData.ItemDescription,
      ItemCode: itemData?.Code,
      Qty: +event.target.Qty?.value,
      UnitPrice: +event.target.UnitPrice?.value.replaceAll(",", ""),
      Unit: dictionaryName?.Unit ? dictionaryName?.Unit : null,
      Price:OrderConstraint.IsFiscal? +event.target.Price?.value.replaceAll(",", ""):0, //تخفیف کم میشود
      Discount: OrderConstraint.IsFiscal?+event.target.Discount?.value.replaceAll(",", ""):0,
      SubTotalPrice:OrderConstraint.IsFiscal? +event.target.SubTotalPrice?.value.replaceAll(",", ""):0,
      Description: event.target.Description?.value,
      //      DescriptionEN: event.target.DescriptionEN.value,
      Tax: !OrderConstraint?.HasTax || !OrderConstraint.IsFiscal
        ? undefined
        : parseFloat(event.target.Tax?.value.replace(/,/g, "")),
      TaxRate: !OrderConstraint.HasTax || !OrderConstraint.IsFiscal ? undefined : detailPriceData.TaxRate,
      DiscountPercent:OrderConstraint.IsFiscal? +event.target.DiscountPercent?.value.replaceAll(",", ""):0,
      ItemTechnicalCode: event.target.ItemTechnicalCode?.value,
    };
    CustomerSalesOrderDetailCallBack && CustomerSalesOrderDetailCallBack(data);
    setItemData(null);
    setResetForm(true);
    setUserChanges({});
    setDetailPriceData({
      Price: 0,
      Discount: 0,
      SubTotalPrice: 0,
      Qty: mode != "create" ? selectedObj?.Qty : 1,
    });
    sessionStorage.setItem("changingforms", "false");
    Toast("msg_saveddatasuccessfully", "success");

    try {
    } catch (error) {}
    if (save_Exit) {
      onHide();
    }
  };

  const itemCodeHandeler = (obj, bool) => {
    if (obj.IsLeaf || bool) {
      setItemData(obj);
      getPriceListDetailAsync(obj.Id);
      setDetailPriceData({
        Price: 0,
        Discount: 0,
        SubTotalPrice: 0,
        Qty1: 1,
        Qty2: 1,
        TaxRate: 0,
      });
      setShowTree(false);
    } else {
      Toast(trans("msg_itemleafisnotselected"), "error");
    }
  };

  function cancel() {
    if (sessionStorage.getItem("changingforms") + "" == "true") {
      Toast(trans("modalclosemessage"), "warning", "alertWithoutClose", () => {
        sessionStorage.setItem("changingforms", "false");
        setUserChanges({});
        onHide();
      });
    } else {
      onHide();
    }
  }

  const getPriceListDetailAsync = async (Id = null, Code = null) => {
    // setLoading(false)
    try {
      const res = await getPriceListDetail(
        Id,
        priceList,
        Code,
        CustomerSalesWarehouse,
        personId
      );
      if (res.Data && res.Data[0]) {
        setRate(res.Data[0]?.ConversionRatio);
      }

      setPriceListDetail(res.Data);
      setItemData({
        ...itemData,
        ItemDescription: res.Data[0].ItemDescription,
        ItemId: res.Data[0].ItemId,
        Code: res.Data[0].ItemCode,
      });
      if (!OrderConstraint?.IsFiscal)
        setDetailPriceData((pre) => {
          return { ...pre, UnitId: res.Data[0].UnitId };
        });
      // changeQty(res.Data[0].SalesUnitMinOrderQty, res.Data, totalTaxRate);
    } catch {}
  };

  const changeQty = (newQty: any, PriceList = [], tax: any = null) => {
    let newDetailPriceData: any = null;

    if (!priceListDetail.length && mode === "edit") {
      getPriceListDetailAsync(detailPriceData.ItemId);
      return null; // to not go continue function beacuse this function set priceListDetail to causes to run useEffect and run changeQty
    }

    if (!OrderConstraint?.PriceListIsManual && OrderConstraint?.IsFiscal) {
      priceListDetail.reverse().map((e) => {
        if (
          (+e.SalesUnitMaxOrderQty >= newQty || e.MaxOrderQty == null) &&
          +e.SalesUnitMinOrderQty <= newQty
        ) {
          newDetailPriceData = e;
        }
      });
    } else {
      newDetailPriceData =
        priceList?.length > 0 ? PriceList[0] : priceListDetail[0];
      newDetailPriceData = {
        ...detailPriceData,
        ...newDetailPriceData,
        SalesUnitPrice: detailPriceData.UnitPrice,
        DiscountPercent: detailPriceData?.DiscountPercent,
      };
    }

    const totalTaxRate = TaxHandler(priceListDetail?.[0]?.ItemTaxList);
    setDetailPriceData((pre) => {
      return {
        Price: roundOrTruncate(
          (+newQty *
            (+newDetailPriceData?.SalesUnitPrice *
              (100 - +newDetailPriceData?.DiscountPercent))) /
            100,

          OrderConstraint?.PriceRoundingPlace,
          false
        ),

        Discount: roundOrTruncate(
          (+newQty *
            +newDetailPriceData?.SalesUnitPrice *
            newDetailPriceData?.DiscountPercent) /
            100,
          OrderConstraint?.PriceRoundingPlace,
          false
        ),
        SubTotalPrice: +newQty * +newDetailPriceData?.SalesUnitPrice,
        Qty:
          (+newDetailPriceData?.SalesUnitMaxOrderQty >= newQty &&
            +newDetailPriceData?.SalesUnitMinOrderQty <= newQty) ||
          (priceList && priceList.IsManual)
            ? newQty
            : pre.Qty,
        ItemTechnicalCode: newDetailPriceData?.ItemTechnicalCode,
        MaxOrderQty: newDetailPriceData?.SalesUnitMaxOrderQty,
        MinOrderQty: newDetailPriceData?.SalesUnitMinOrderQty,
        QtyMainUnit: newDetailPriceData?.QtyMainUnit,
        QtyStatisticalUnit: newDetailPriceData?.QtyStatisticalUnit,
        IsAbsoluteUnitConversionRatio:
          newDetailPriceData?.IsAbsoluteUnitConversionRatio,
        PriceListId: newDetailPriceData?.PriceListId,
        UnitDiscount: newDetailPriceData?.SalesUnitDiscount,
        UnitId:
          newDetailPriceData?.SalesUnitId == newDetailPriceData?.Unit1Id
            ? newDetailPriceData?.Unit1Id
            : newDetailPriceData?.Unit2Id,
        UnitPrice: newDetailPriceData?.SalesUnitPrice,
        TaxRate: OrderConstraint?.HasTax ? totalTaxRate : 0,
        DiscountPercent: newDetailPriceData?.DiscountPercent,
      };
    });
  };

  const TaxHandler = (list) => {
    let totalTaxRate = 0;
    list?.map((item) => {
      totalTaxRate += item.TaxRate;
    });
    return totalTaxRate;
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };
  const initializeFormWithSelectedObject = () => {
    setItemData({
      ...selectedObj,
      Code: selectedObj.ItemCode,
      ItemDescription: selectedObj.Item,
    });
    setDetailPriceData({
      ...selectedObj,
      TaxRate: OrderConstraint.HasTax ? selectedObj.TaxRate : 0,
    });
  };

  useEffect(() => {
    if (selectedObj?.Id && mode !== "create") {
      initializeFormWithSelectedObject();
    }
  }, []);

  useEffect(() => {
    if (Object.values(userChanges).filter((item) => item != null).length > 0) {
      sessionStorage.setItem("changingforms", "true");
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    } else {
      sessionStorage.setItem("changingforms", "false");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userChanges]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);


  useEffect(() => {
    if (mode == "edit" && !!priceListDetail.length) {
      changeQty(
        selectedObj.ItemId == itemData.ItemId
          ? detailPriceData.Qty
          : priceListDetail[0]?.SalesUnitMinOrderQty,
        priceListDetail
      );
    }
    if (mode == "create" && !!priceListDetail.length) {
      changeQty(
        priceListDetail[0]?.SalesUnitMinOrderQty
          ? priceListDetail[0]?.SalesUnitMinOrderQty
          : 1,
        priceListDetail
      );
    }
  }, [priceListDetail]);

  return (
    <div className="card " style={{ flexDirection: "row" }}>
      {showTree && (
        <ModalForm
          mode={mode}
          title="ItemTree"
          onHide={() => setShowTree(false)}
          show={showTree}
        >
          <ItemsForSell
            priceListId={priceList}
            warehouseId={CustomerSalesWarehouse}
            warehouseDetailId={null}
            onHide={() => {
              setShowTree(false);
            }}
            itemCodeHandeler={itemCodeHandeler}
            isFiscal={OrderConstraint.IsFiscal}
          />
        </ModalForm>
      )}

      <div style={{ flexWrap: "nowrap" }} className="card-body row">
        <div
          className={`card-body ${
            !!priceListDetail.length
              ? "col-12 col-md-7 col-lg-8 col-xl-9"
              : "col-12"
          }`}
        >
          {loading ? (
            <div className="container-fluid">
              <form onSubmit={handleCustomerSalesOrderFormSubmit}>
                <div className="row">
                  <div
                    className="col-md-3 col-sm-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Textbox
                      required
                      onBlurHandler={(e) => {
                        if (e && e != itemData?.Code) {
                          getPriceListDetailAsync(null, e);
                        }
                      }}
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      onChange={(e) => {
                        if (
                          e?.target.value === null ||
                          e?.target.value === ""
                        ) {
                          setDetailPriceData({
                            Price: 0,
                            Discount: 0,
                            SubTotalPrice: 0,
                            Qty: mode != "create" ? selectedObj?.Qty1 : 1,
                          });
                          setItemData({});
                          setRate(null);
                        }
                      }}
                      textboxName={"itemCode"}
                      textboxType="text"
                      resetForm={resetForm}
                      labelText={"itemCode"}
                      mode={mode}
                      value={itemData?.Code}
                      defaultValue={itemData?.Code}
                    />
                    {mode != "view" && (
                      <div style={{ marginTop: "4px", width: "10%" }}>
                        <Button
                          Icon={true}
                          value={
                            <button
                              onClick={(e) => setShowTree(true)}
                              type="button"
                              className="checkButton"
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={[ICON_TYPE, "diagram-project"]}
                                size="1x"
                              />
                            </button>
                          }
                          btnType="primary"
                          onClick={(e) => setShowTree(true)}
                        />
                      </div>
                    )}
                  </div>

                  {itemData && itemData?.ItemDescription && (
                    <div className="col-md-4">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="ItemDescription"
                        textboxType="text"
                        labelText="ItemReview"
                        value={itemData?.ItemDescription}
                        defaultValue={itemData?.ItemDescription}
                        resetForm={resetForm}
                        mode={"view"}
                        required={false}
                      />
                    </div>
                  )}

                  <div className="col-md-2">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="ItemTechnicalCode"
                      textboxType="text"
                      labelText="TechnicalCode"
                      value={detailPriceData.ItemTechnicalCode + ""}
                      defaultValue={
                        detailPriceData?.ItemTechnicalCode
                          ? detailPriceData?.ItemTechnicalCode
                          : null
                      }
                      resetForm={resetForm}
                      mode="view"
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="Qty"
                      textboxType="number"
                      labelText="Qty"
                      value={
                        detailPriceData?.Qty
                          ? detailPriceData.Qty
                          : !!priceListDetail.length &&
                            priceListDetail[0]?.SalesUnitMinOrderQty
                          ? priceListDetail[0]?.SalesUnitMinOrderQty
                          : 1 + ""
                      }
                      defaultValue={
                        detailPriceData?.Qty
                          ? detailPriceData.Qty
                          : !!priceListDetail.length &&
                            priceListDetail[0]?.SalesUnitMinOrderQty
                          ? priceListDetail[0]?.SalesUnitMinOrderQty
                          : 1 + ""
                      }
                      usePrevDefaultValue={
                        OrderConstraint.PriceListHasDetailRange &&
                        priceListDetail[priceListDetail.length - 1]
                          ?.SalesUnitMaxOrderQty === detailPriceData.Qty
                      }
                      min={
                        priceListDetail.length > 0
                          ? priceListDetail[0]?.SalesUnitMinOrderQty
                            ? priceListDetail[0]?.SalesUnitMinOrderQty
                            : 1
                          : 1
                      }
                      max={
                        priceListDetail.length > 0
                          ? priceListDetail[priceListDetail.length - 1]
                              ?.SalesUnitMaxOrderQty
                          : undefined
                      }
                      decimals={priceListDetail[0]?.Unit1RoundingPlace}
                      resetForm={resetForm}
                      mode={mode}
                      required={true}
                      onChange={(e) => {
                        changeQty(+e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <UnitDictionary
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      value={detailPriceData?.UnitId}
                      label="Unit"
                      name="UnitId"
                      required={false}
                      mode={"view"}
                      resetForm={resetForm}
                      setUnitDictionary={(e) => {
                        setDictionaryName({ ...dictionaryName, Unit: e.Value });
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="UnitPrice"
                      textboxType="text"
                      labelText="UnitPrice"
                      value={detailPriceData?.UnitPrice}
                      round={false}
                      defaultValue={
                        (detailPriceData?.UnitPrice
                          ? detailPriceData?.UnitPrice
                          : 0) + ""
                      }
                      resetForm={resetForm}
                      mode={"view"}
                      useGroupingPrice={true}
                      onChange={(e) =>
                        setDetailPriceData({
                          ...detailPriceData,
                          UnitPrice: +e.target.value.replaceAll(",", ""),
                          SubTotalPrice:
                            +e.target.value.replaceAll(",", "") *
                            detailPriceData.Qty,
                        })
                      }
                      required={false}
                    />
                  </div>
                {OrderConstraint?.IsFiscal &&   <div className="col-md-3">
                    <Textbox
                      Change={(e) => {
                        setUserChanges({ ...userChanges, ...e });
                      }}
                      textboxName="SubTotalPrice"
                      textboxType="text"
                      labelText="مبلغ"
                      value={detailPriceData?.SubTotalPrice}
                      round={false}
                      defaultValue={
                        detailPriceData?.SubTotalPrice
                          ? detailPriceData?.SubTotalPrice + ""
                          : ""
                      }
                      resetForm={resetForm}
                      mode={"view"}
                      useGroupingPrice={true}
                      required={false}
                    />
                  </div>}
                </div>
                {OrderConstraint?.IsFiscal && 
                <>
                  <div className="row justify-content-end">
                    <div className="col-md-2">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="DiscountPercent"
                        textboxType="text"
                        labelText="DiscountPercent"
                        value={detailPriceData?.DiscountPercent + ""}
                        onChange={(e) =>
                          setDetailPriceData({
                            ...detailPriceData,
                            DiscountPercent: +e.target.value,
                            Discount:
                              (+e.target.value *
                                detailPriceData?.SubTotalPrice) /
                              100,
                            Price:
                              detailPriceData?.SubTotalPrice -
                              (+e.target.value *
                                detailPriceData?.SubTotalPrice) /
                                100,
                          })
                        }
                        defaultValue={
                          detailPriceData?.DiscountPercent > 0
                            ? detailPriceData?.DiscountPercent
                            : detailPriceData?.DiscountPercent == 0
                            ? 0
                            : null
                        }
                        max={100}
                        resetForm={resetForm}
                        mode={"view"}
                        useGroupingPrice={true}
                        required={false}
                      />
                    </div>
                    <div className="col-md-3">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="Discount"
                        textboxType="text"
                        labelText="Discount"
                        value={detailPriceData?.Discount * rate}
                        defaultValue={
                          (detailPriceData?.Discount
                            ? detailPriceData?.Discount
                            : null) *
                            rate +
                          ""
                        }
                        resetForm={resetForm}
                        round={false}
                        onChange={(e) => {
                          setDetailPriceData({
                            ...detailPriceData,
                            Price:
                              detailPriceData?.SubTotalPrice -
                              +e.target.value.replaceAll(",", ""),
                            UnitDiscount: +e.target.value.replaceAll(",", ""),
                            DiscountPercent:
                              (+e.target.value.replaceAll(",", "") /
                                detailPriceData?.SubTotalPrice) *
                              100,
                          });
                        }}
                        mode={"view"}
                        useGroupingPrice={true}
                        required={false}
                      />
                    </div>
                    <div className="col-md-3">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="Price"
                        textboxType="text"
                        labelText="totalprice"
                        value={detailPriceData.Price + ""}
                        defaultValue={detailPriceData?.Price}
                        resetForm={resetForm}
                        decimals={OrderConstraint?.PriceRoundingPlace}
                        round={false}
                        mode={
                          priceList.IsManual && mode != "view"
                            ? "create"
                            : "view"
                        }
                        useGroupingPrice={true}
                        required={false}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-md-3">
                      <Textbox
                        useGroupingPrice
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="Tax"
                        textboxType="text"
                        labelText="Tax"
                        decimals={OrderConstraint?.PriceRoundingPlace}
                        round={OrderConstraint.FloorTaxDuty}
                        defaultValue={
                          detailPriceData?.TaxRate
                            ? roundOrTruncate(
                                (+detailPriceData.TaxRate *
                                  +detailPriceData.Price) /
                                  100,
                                OrderConstraint.PriceRoundingPlace,
                                OrderConstraint?.FloorTaxDuty
                              ) + ""
                            : "0"
                        }
                        value={
                          detailPriceData?.TaxRate
                            ? roundOrTruncate(
                                (+detailPriceData.TaxRate *
                                  +detailPriceData.Price) /
                                  100,
                                OrderConstraint.PriceRoundingPlace,
                                OrderConstraint?.FloorTaxDuty
                              ) + ""
                            : "0"
                        }
                        resetForm={resetForm}
                        mode={
                          priceList?.IsManual &&
                          OrderConstraint?.HasTax &&
                          mode !== "view"
                            ? "create"
                            : "view"
                        }
                        required={false}
                        onChange={(e) => {
                          if (priceList.IsManual) {
                            setDetailPriceData({
                              ...detailPriceData,
                              TaxRate:
                                (+e.target.value.replaceAll(",", "") * 100) /
                                detailPriceData.Price,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-md-3">
                      <Textbox
                        Change={(e) => {
                          setUserChanges({ ...userChanges, ...e });
                        }}
                        textboxName="GrandTotalPrice"
                        textboxType="text"
                        labelText="payableamount"
                        value={
                          detailPriceData?.TaxRate
                            ? +detailPriceData.Price +
                              (+detailPriceData?.TaxRate *
                                +detailPriceData?.Price) /
                                100 +
                              ""
                            : detailPriceData?.Price
                            ? detailPriceData?.Price + ""
                            : ""
                        }
                        defaultValue={
                          detailPriceData?.TaxRate
                            ? +detailPriceData.Price +
                              (+detailPriceData?.TaxRate *
                                +detailPriceData?.Price) /
                                100 +
                              ""
                            : detailPriceData?.Price
                            ? detailPriceData?.Price + ""
                            : ""
                        }
                        resetForm={resetForm}
                        decimals={OrderConstraint?.PriceRoundingPlace}
                        round={false}
                        mode={
                          priceList.IsManual && mode != "view"
                            ? "create"
                            : "view"
                        }
                        useGroupingPrice={true}
                        required={false}
                      />
                    </div>
                  </div>
                </>}
                <div className="row justify-content-end"></div>

                <div className="col-md-12">
                  <Textbox
                    Change={(e) => {
                      setUserChanges({ ...userChanges, ...e });
                    }}
                    textboxType="textarea"
                    textboxName="Description"
                    labelText="Description"
                    defaultValue={
                      mode != "create" ? selectedObj?.Description : null
                    }
                    resetForm={resetForm}
                    mode={mode}
                    required={false}
                  />
                </div>

                <>
                  {mode != "view" ? (
                    <div className="col-md-12 mt-1 btns_form">
                      <Button value="save" btnType="submit" />
                      <Button
                        value="save&close"
                        btnType="submit"
                        onClick={() => {
                          setSave_Exit(true);
                        }}
                      />
                      <Button
                        btnType="cancel"
                        value="Cancel"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-md-12 mt-1 btns_form">
                      <Button
                        btnType="primary"
                        value="close"
                        onClick={() => {
                          cancel();
                        }}
                      />
                    </div>
                  )}
                </>
              </form>
            </div>
          ) : (
            <Loading />
          )}
        </div>
        {!!priceListDetail.length &&
          OrderConstraint?.PriceListHasDetailRange &&
          mode == "create" && (
            <PriceDetails priceListDetail={priceListDetail} />
          )}
      </div>
    </div>
  );
};
