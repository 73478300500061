import axios from "axios";
import { Toast } from "components/ToastShow/ToastShow";
import { MessageType } from "types/enum";
import { auth } from "./auth";
import { APP_URL } from "./constants";
import { trans } from "./helpers";


let forbiddenCount =0
export let UserTabs: any = [];
const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

function clearSessionStorage() {
  sessionStorage.clear();
  localStorage.setItem('clearSessionStorage', Date.now()+"");
}


async function handleExit() {
  const Idtoken = localStorage.getItem("id_token")
  sessionStorage.removeItem("accept-data");
  localStorage.removeItem("token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_expire");
  localStorage.removeItem("CurrentApplication");
  localStorage.removeItem("tabs");

  try {
    const openedWindow = window.open(`${process.env.REACT_APP_AUTH_URL}/endsession?id_token_hint=${Idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`);
    await delay(300)
    openedWindow?.close();
    clearSessionStorage()
  window.location.href = "/"
  } catch (error) {

  }
}

export const http = axios.create({
  baseURL: APP_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  timeout: 40000,
});

http.interceptors.request.use(
async function (config: any) {
    const lang = localStorage.getItem("lang");
    const token = localStorage.getItem("token");
    const ex = localStorage.getItem("token_expire");
    let res = true;
    if (token) {
      res =await auth.checkTokenExpire();
    } else {
      auth.attempt();
    }

    if(!localStorage.getItem("token")){
      await delay(300)
      if (localStorage.getItem("token")) {
        res =await auth.checkTokenExpire();
      } else {
        auth.attempt();
      }
    }

    if (
      config.method == "post" ||
      config.method == "put" ||
      config.method == "delete"
    ) {
      document.body.classList.add("loading-indicator-"+lang);
    }
    if (sessionStorage.getItem("accept-data")) {
      config.headers["Accept-Data"] = sessionStorage.getItem("accept-data");
    }else{
      if(!config.url.includes("Profile")&&!config.url.includes("Resource")&&!config.url.includes("UserInfo")){
        console.log("ddd" ,config.url)
       handleExit() 
      }
    }
    if (res) {
      if (sessionStorage.getItem("accept-data")) {
        config.headers["Accept-Data"] = sessionStorage.getItem("accept-data");
      }
      if (
        config.headers["Accept-Language"] == undefined ||
        config.headers["Accept-Language"] == ""
      )
        config.headers["Accept-Language"] = lang;
      config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
      return config;
    } else {
      auth.attempt();
      if (
        config.headers["Accept-Language"] == undefined ||
        config.headers["Accept-Language"] == ""
      )
        config.headers["Accept-Language"] = lang;
      config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
      return config;
    }
  },
  function (error) {

  }
);


http.interceptors.response.use(
  async (res) => {
    const lang = localStorage.getItem("lang");
    try {

      if (res.config.method == "get") {
        document.body.classList.remove("loading-indicator-"+lang);
        return res;
      } else {
        if (res.data.MessageType == MessageType["confirm"]) {
          res.config.params.Confirm = true;
          const confirm = await new Promise((resolve) => {
            let re: any;
            Toast(
              res.data.Message,
              "warning",
              "alert",
              () => {
                re = http(res.config);
                resolve(re);
              },
              "",
              res?.data?.Data?.[0]?.MessageType ?res?.data?.Data  :  "",
              () => {
                document.body.classList.remove("loading-indicator-"+lang);
              }
            );
          });
          document.body.classList.remove("loading-indicator-"+lang);
          return confirm;
        } else {
          if (res?.data?.Data && res.data.Data[0]?.MessageType) {
            Toast(
              res?.data.Message,
              MessageType[res.data.MessageType],
              "toast",
              () => {},
              "",
              res?.data?.Data
            );
            return res;
          } else {
            if(res.data.MessageType && res.data.MessageType!=MessageType["noDialog"] ){
              Toast(res.data.Message, MessageType[res.data.MessageType]);
            }
            document.body.classList.remove("loading-indicator-"+lang);
            return res;
          }
        }
      }
    } catch (error) {
    }
    document.body.classList.remove("loading-indicator-"+lang);
    document.body.classList.remove("loading-indicator-"+lang);
  },

  async (err) => {

    
    const lang = localStorage.getItem("lang");

    document.body.classList.remove("loading-indicator-"+lang);
    const statusCode = err?.response?.status;
    if(forbiddenCount==20){
      handleExit()
    }
    if (statusCode == 401) {
      forbiddenCount =0
      try {
 /*      const Idtoken = localStorage.getItem("id_token");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("token_expire");
      localStorage.removeItem("CurrentApplication");

        if (UserTabs.length == 0) {
          const openedlogoutWindow = window.open(
            `${process.env.REACT_APP_AUTH_URL}/endsession?id_token_hint=${Idtoken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`
          );

          UserTabs = [...UserTabs, openedlogoutWindow];
        }

        UserTabs?.forEach((item) => {
          item?.close();
        });
        UserTabs = [];

        window.location.href = "/";
 */

        await auth.checkTokenExpire();
        const token = localStorage.getItem("token");
        err.response.config.headers["Authorization"] = "Bearer " + token
        await new Promise((resolve) => {
          let re = http(err?.response.config);
          resolve(re);
        });







      } catch (error) {}
    }  else if (statusCode == 403) {
      forbiddenCount +=1
      if(err.config.method != "get"){
        Toast("noaccess" ,"error")
      }
      err.response.data ={
        data:null ,
        MessageType : MessageType["error"],
        Message:"noaccess"
      }
      return Promise.reject(err);

    }else if (!err?.message.includes("timeout") &&err?.response?.data?.MessageType != MessageType["confirm"]) { //confirm with error managed in api functions
      forbiddenCount =0
      
        if (err?.response?.data?.Data && err?.response?.data?.Data[0]?.MessageType) {
          Toast(
            err?.response?.data.Message,
            "error",
            "toast",
            () => {},
            "",
            err?.response?.data?.Data
          );
        } else if (!err?.response?.data?.Message) {
          if(err?.response?.status != 400){
            Toast( trans("msg_serverexceptionerror") +(err?.response?.status?  " ( "+err?.response?.status+" )" :""), "error");
          }
        } else {
        
        if(err?.response?.data?.MessageType!=MessageType["noDialog"] )
          Toast(err.response.data.Message, "error");
         return Promise.reject(err);

        }
        document.body.classList.remove("loading-indicator-"+lang);
      
    } else if (err?.message.includes("timeout ")) {
      forbiddenCount =0
      Toast(trans("Msg_ServerTimeOutError"), "error");
    }
    document.body.classList.remove("loading-indicator-"+lang);
    return Promise.reject(err);
  }
);





























