import { useEffect, useRef, useState } from "react";
import { CheckableButton } from "components/CheckableButton/CheckableButton";
import Datepicker from "components/DatePicker/DatePicker";
import { DictionarySelectBox } from "components/DictionaryTypeSelect/DictionaryTypeSelect";
import { Textbox } from "components/Textbox/Textbox";
import { ICON_TYPE, REPORT_URL } from "utils/constants";
import { ENDPOINTSREPORT } from "utils/endpoints";
import { Button } from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "utils/helpers";
import { ParameterEnumDictionary } from "pages/SystemManagement/Reports/components/ParameterEnumDictionary";
import { Position } from "components/Position/Position";
import { Person } from "components/Person/Person";
import { ParameterDictionary } from "pages/SystemManagement/Reports/components/ParameterDictionaryValuse";
import { SelectBox } from "components/Select/Select";

interface OperatorOptionsPropsType {
  item: any;
  feedType?: any;
  setDatesValue: (e) => void;
  index?: number;
}
export const OperatorOptions = ({
  item,
  feedType,
  setDatesValue,
  index,
}: OperatorOptionsPropsType) => {
  const [resetForm, setResetForm] = useState(false);
  const [operatorType, setOperatorType] = useState<any>(1);
  const [dateDefaultValue, setDateDefaultValue] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [optionKeys, setOptionKeys] = useState<any>();
  const [dateForm,setDateForm]=useState(new Date().toISOString())

  const [value, setValue] = useState<any>();  
  const btn = useRef<any>();
  const optionBox = useRef<any>();

  function addOption(e) {
    e.stopPropagation();
    e.preventDefault();
    let selectList = JSON.parse(JSON.stringify(selectedItem));
    let selectListKeys = JSON.parse(JSON.stringify(selectedKeys));
    if (
      item.ParameterDataType.includes(".Time") ||
      item.ParameterDataType.includes(".Date")
    ) {
      setSelectedItem(
        selectList.concat(dateDefaultValue["value" + item.ParameterNameEN][0])
      );
      setDatesValue({
        ["value" + item.ParameterNameEN]: selectList.concat(
          dateDefaultValue["value" + item.ParameterNameEN][0]
        ),
      });
      setDateDefaultValue({});
    } else {
      if (value) {
        setSelectedItem(selectList.concat(value));
        setSelectedKeys(optionKeys!=undefined ? selectListKeys.concat(optionKeys) :selectedKeys.concat(value));
        setDatesValue({
          ["value" + item.ParameterNameEN]:optionKeys!=undefined ?  selectedKeys.concat(optionKeys):selectedKeys.concat(value) 
        });
        setValue(null);
        setOptionKeys(null);
      }
    }
    setResetForm(true);
  }
  
  function deleteOption(obj) {


    let selectList:any  = [ ]
    let selectKeys:any  = [ ]
    selectedItem.map((e)=>{
      if(e != obj){
        selectKeys.push(e)
        selectList.push(e)
      }
    })

    setSelectedItem(selectList)
    setSelectedKeys(selectKeys)
    if (
      item.ParameterDataType.includes("Time") ||
      item.ParameterDataType.includes("Date")
    ) {

      setDatesValue({
        ["value" + item.ParameterNameEN]:selectList,
      });
      setDateDefaultValue({});
    } else {
      
        setDatesValue({
          ["value" + item.ParameterNameEN]:selectKeys
        });
        setValue(null);
        setOptionKeys(null);
      
    }
  }


  function DatepickerDataHandler(isoTime) {
    if (isoTime) {
      if (item.ParameterDataType == "System.TimeOnly") {
        setDateDefaultValue({
          ["value" + item.ParameterNameEN]: [
            isoTime.split("T")[1].replaceAll("Z", ""),
          ],
        });
      } else if (item.ParameterDataType == "System.DateOnly") {
        setDateDefaultValue({
          ["value" + item.ParameterNameEN]: [
            isoTime.split("T")[0].replaceAll("Z", ""),
          ],
        });
      } else {
        setDateDefaultValue({ ["value" + item.ParameterNameEN]: [isoTime] });
      }
    } else {
      setDateDefaultValue({ ["value" + item.ParameterNameEN]: [] });
    }
  }
  function DatepickerDataHandler2(isoTime) {
    if (item.ParameterDataType == "System.TimeOnly") {
      setDateDefaultValue({
        ["value" + item.ParameterNameEN]: [
          dateDefaultValue["value" + item.ParameterNameEN]?.[0],
          isoTime.split("T")[1].replaceAll("Z", ""),
        ],
      });
    } else if (item.ParameterDataType == "System.DateOnly") {
      setDateDefaultValue({
        ["value" + item.ParameterNameEN]: [
          dateDefaultValue["value" + item.ParameterNameEN]?.[0],
          isoTime.split("T")[0].replaceAll("Z", ""),
        ],
      });
    } else {
      setDateDefaultValue({
        ["value" + item.ParameterNameEN]: [
          dateDefaultValue["value" + item.ParameterNameEN]?.[0],
          isoTime,
        ],
      });
    }
  }
  useEffect(() => {
    setDatesValue(dateDefaultValue);
  }, [dateDefaultValue]);
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);


  return (
    <div
      className="row"
      style={{
        height: `${((operatorType == 10 || operatorType == 11) &&!!selectedItem.length) ?optionBox?.current?.getBoundingClientRect()?.height + 44:0}px`,
      }}
    >
      <div
        className="col-4 "
        style={{
          height: `${optionBox?.current?.getBoundingClientRect()?.height}px`,
        }}
      >
        {index == 0 && (
          <small className="d-block mb-1">{trans("operatortype")}</small>
        )}

        <SelectBox
          onChange={(e)=>{setOperatorType(e.target.value);
            if(!(e.target.value == 10 || e.target.value == 11)){
              setSelectedItem([]);setSelectedKeys([])
            }
          }}
            useTrans={true}
            lable={""}
            selectType={"select"}
            options={Object.values(JSON.parse(item.OperatorTypes))  }
            val={Object.keys(JSON.parse(item.OperatorTypes))}
            name={"OperatorType" + item.ParameterNameEN}
            resetForm={resetForm}
            mode={"create"}       
          />

      </div>
      <div
        style={{ height: "35px" }}
        className={
          operatorType == 12 || operatorType == 13 ? "col-3 " : "col-6"
        }
      >
        {index == 0 && (
          <small
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            className="d-block mb-1"
          >
            {trans("parameterdefaultvalue")}
          </small>
        )}

        {
          {
            "System.String": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                onChange={(e) => {
                  if (operatorType == 10 || operatorType == 11) {
                    setValue(e.target.value);
                  }
                }}
                textboxType="text"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.TimeOnly": (
              <Datepicker
                mode={"create"}
                resetForm={resetForm}
                lable={""}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                setIsoTime={(isoTime: string) => {
                  DatepickerDataHandler(isoTime);
                }}
                val={item.ParameterDefaultValue} //resetForm ? new Date().toISOString() :
                withTime={true}
                format={"HH:mm"}
                disableDayPicker={true}
                onOpenPickNewDate={true}
              />
            ),
            "System.DateOnly": (
              <Datepicker
                mode={"create"}
                resetForm={resetForm}
                lable={""}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                setIsoTime={(isoTime: string) => {
                  DatepickerDataHandler(isoTime);
                }}
                val={item.ParameterDefaultValue?item.ParameterDefaultValue:null} //resetForm ? new Date().toISOString() :
                format={"YYYY/MM/DD"}
                onOpenPickNewDate={true}
                required={!item.IsOptional}
              />
            ),
            "System.DateTime": (
              <Datepicker
                mode={"create"}
                resetForm={resetForm}
                lable={""}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                setIsoTime={(isoTime: string) => {
                  DatepickerDataHandler(isoTime);
                }}
                val={item.ParameterDefaultValue?item.ParameterDefaultValue:null} //resetForm ? new Date().toISOString() :
                withTime={true}
                onOpenPickNewDate={true}
              />
            ),
            "System.Char": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="text"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Double": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.UInt64": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.UInt32": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.UInt16": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Decimal": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="text"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Int64": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Int32": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Int16": (
              <Textbox
                withLabel={false}
                placeholder={
                  operatorType == 12 || operatorType == 13 ? trans("from") : ""
                }
                defaultValue={item.ParameterDefaultValue}
                textboxType="number"
                textboxName={"value" + item.ParameterNameEN}
                labelText={""}
                resetForm={resetForm}
                mode={"create"}
              />
            ),
            "System.Boolean": (
              <>
                <CheckableButton
                  defaultChecked={item.ParameterDefaultValue==='true'?true:false}
                  defaultValue={item.ParameterDefaultValue==='true'?true:false}
                  labelText={""}
                  inputName={"value" + item.ParameterNameEN}
                  type="checkbox"
                  mode={"create"}
                />
              </>
            ),
            "System.Guid.Position": (
              <>
              <Position
   
               name={"value" + item.ParameterNameEN}
               label=""
               value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
              resetForm={resetForm}
                                    />
              </>
            ),
            "Guid.Person": (
              <>
            <Person

                                   value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
                                   WithSearch={false}
                                   returnObj={true}
                                   name={"value" + item.ParameterNameEN}
                                   label=""
                                 />
              </>
            ),
          }[item.ParameterDataType]
        }

        {item.ParameterDataType.includes("Enumerations") ? (
          <div
            style={{ position: "relative" }}
            className={
              (operatorType == 10 || operatorType == 11) && value ? "mb-4" : ""
            }
          >
            <ParameterEnumDictionary
              withoutLable={true}
              name={"value" + item.ParameterNameEN}
              feedType={feedType}
              enumType={item?.ParameterDataType.split("numerations.")[1]}
              mode={"create"}
              value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
              onChange={(e, str) => {
                if (!selectedItem.includes(str)) {
                  setValue(str);
                  setOptionKeys(e);
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {item.ParameterDataType.includes("Dictionaries") ? (
          <div
            style={{ position: "relative" }}
            className={
              (operatorType == 10 || operatorType == 11) && value ? "mb-4" : ""
            }
          >
            <ParameterDictionary
              name={"value" + item.ParameterNameEN}
              typeName={item?.ParameterDataType.replaceAll("Dictionaries.", "")}
              withoutLable={true}
              mode={"create"}
              value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
              required={!item.IsOptional}
              onChange={(e) => {
                if (!selectedItem.includes(e?.Id) ) {
                  if(e?.Name){
                    setValue(e.Name);
                    setOptionKeys(e.Id);
                  }
                }
              } } 
              label={""}
               resetForm={false}            />
          </div>
        ) : (
          <></>
        )}


      </div>

      {(operatorType == 10 || operatorType == 11) && (
        <div
          ref={btn}
          style={{
            height: "35px",
            marginTop: `${index == 0 ? "24.5px" : "3px"}`,
            width: "10%",
            marginRight: "-27px",
            position: "relative",
          }}
        >
          {(operatorType == 10 || operatorType == 11) && (
            <div
              ref={optionBox}
              className="group-person__selected-options-wrapper d-flex flex-wrap "
              style={{
                top: `23px`,
                width: "250px",
                position: "absolute",
                right: "unset",
                direction: "rtl",
                left: `0px`,
              }}
            >
              {selectedItem.map((item, index) => (
                <div
                  className="w-auto group-person__selected-option form-control-custom p-relative"
                  key={item + index}
                >
                  <div
                    className=" w-auto group-person__defult-selected-option row"
                    data-key={item}
                  ></div>
                  <div className="showDots ms-2">{item}</div>
                  <div className="group-person__delete-selected-option me-2 " data-key={item.Id} onClick={()=>{deleteOption(item)}}>
                    <FontAwesomeIcon icon={[ICON_TYPE, "times"]} />
                </div>
                </div>
              ))}
            </div>
          )}

          <Button
            Icon={true}
            value={
              <button onClick={addOption} className="checkButton">
                {" "}
                <FontAwesomeIcon icon={[ICON_TYPE, "check"]} size="2x" />
              </button>
            }
            btnType="submit"
          />
        </div>
      )}


      {operatorType && (operatorType == 12 || operatorType == 13) && (
        <div className="col-3 " style={{ height: "35px" }}>
          {index == 0 && (
          <small
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height:'18px'
            }}
            className="d-block mb-1 w-100"
          >
           
          </small>
        )}
          {
            {
              "System.String": (
                <Textbox
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="text"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.TimeOnly": (
                <Datepicker
                  mode={"create"}
                  resetForm={resetForm}
                  lable={""}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  setIsoTime={(isoTime: string) => {
                    DatepickerDataHandler2(isoTime);
                  }}
                  val={item.ParameterDefaultValue?item.ParameterDefaultValue:null} //resetForm ? new Date().toISOString() :
                  withTime={true}
                  format={"HH:mm"}
                  disableDayPicker={true}
                  onOpenPickNewDate={true}
                  required={!item.IsOptional}
                />
              ),
              "System.DateOnly": (
                <Datepicker
                  mode={"create"}
                  resetForm={resetForm}
                  lable={""}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  setIsoTime={(isoTime: string) => {
                    DatepickerDataHandler2(isoTime);
                  }}
                  val={item.ParameterDefaultValue?item.ParameterDefaultValue:null} //resetForm ? new Date().toISOString() :
                  format={"YYYY/MM/DD"}
                  onOpenPickNewDate={true}
              required={!item.IsOptional}
                />
              ),
              "System.DateTime": (
                <Datepicker
                  mode={"create"}
                  resetForm={resetForm}
                  lable={""}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  setIsoTime={(isoTime: string) => {
                    DatepickerDataHandler2(isoTime);
                  }}
                  val={item.ParameterDefaultValue?item.ParameterDefaultValue:null} //resetForm ? new Date().toISOString() :
                  withTime={true}
                  onOpenPickNewDate={true}
                required={!item.IsOptional}
                />
              ),
              "System.Char": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="text"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Double": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.UInt64": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.UInt32": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.UInt16": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={"to"}
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Decimal": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="text"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Int64": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Int32": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  labelText={""}
                  withLabel={false}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Int16": (
                <Textbox
                  defaultValue={item.ParameterDefaultValue}
                  textboxType="number"
                  textboxName={"value" + item.ParameterNameEN}
                  withLabel={false}
                  labelText={""}
                  placeholder={
                    operatorType == 12 || operatorType == 13 ? trans("to") : ""
                  }
                  resetForm={resetForm}
                  mode={"create"}
                />
              ),
              "System.Boolean": (
                <>
                  <CheckableButton
                    defaultChecked={item.ParameterDefaultValue==='true'?true:false}
                    defaultValue={item.ParameterDefaultValue==='true'?true:false}
                    labelText={""}
                    inputName={"value" + item.ParameterNameEN}
                    type="checkbox"
                    mode={"create"}
                  />
                </>
              ),
              "System.Guid.Position": (
                <>
                <Position
     
                 name={"value" + item.ParameterNameEN}
                 label=""
                 value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
                resetForm={resetForm}
                                      />
                </>
              ),
              "Guid.Person": (
                <>
              <Person
                value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
                WithSearch={false}
                returnObj={true}
                name={"value" + item.ParameterNameEN}
                label=""
              />
                </>
              ),
            }[item.ParameterDataType]
          }
         {item.ParameterDataType.includes("Enumerations") ? (
          <div
            style={{ position: "relative" }}
            className={
              (operatorType == 10 || operatorType == 11) && value ? "mb-4" : ""
            }
          >
            <ParameterEnumDictionary
              withoutLable={true}
              name={"value" + item.ParameterNameEN}
              feedType={feedType}
              enumType={item?.ParameterDataType.split("numerations.")[1]}
              mode={"create"}
              value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
              onChange={(e, str) => {
                if (!selectedItem.includes(str)) {
                  setValue(str);
                  setOptionKeys(e);
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {item.ParameterDataType.includes("Dictionaries") ? (
          <div
            style={{ position: "relative" }}
            className={
              (operatorType == 10 || operatorType == 11) && value ? "mb-4" : ""
            }
          >
            <ParameterDictionary
              name={"value" + item.ParameterNameEN}
              typeName={item?.ParameterDataType.replaceAll("Dictionaries.", "")}
              withoutLable={true}
              mode={"create"}
              value={item?.ParameterDefaultValue?item?.ParameterDefaultValue:''}
              required={!item.IsOptional}
              onChange={(e) => {
                if (!selectedItem.includes(e?.Id) ) {
                  if(e?.Name){
                    setValue(e.Name);
                    setOptionKeys(e.Id);
                  }
                }
              } } 
              label={""}
               resetForm={false}            />
          </div>
        ) : (
          <></>
        )}
        </div>
      )}
    </div>
  );
};
