import { Button } from "components/Button/Button";
import { TabBar } from "components/Tabbar/Tabbar";
import { useEffect, useRef, useState } from "react";
import { MainForm } from "./components/Main";
import { UnitForm } from "./components/Unit";
import { PackingForm } from "./components/Packing";
import { putItemLeaf, sendItemLeaf } from "./api";
import { TaxForm } from "./components/Tax";
import { getItemLeaf } from "./api"; 
import { Loading } from "components/Loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "utils/helpers";

   
interface Form { 
  showForm: (e) => void;
  reload?: (flag) => void;
  Id?: any;
  mode: any;
  selectedObj: any;
  checkData: any

}
export const ItemLeafForm = ({ showForm, Id,selectedObj,mode,checkData, reload   }: Form) => {

  const [curentTab, setCurentTab] = useState<any>("main")
  const [response,setResponse] = useState<any>()
  const [loading,setLoading] = useState<any>(false)
  const mainData = useRef<any>()
  const [canPaste,setCanPaste] = useState<any>()
  const lang = localStorage.getItem("lang");

  const UnitData = useRef<any>()
  const packingData = useRef<any>()
  const taxData = useRef<any>()

  const formRefs = [useRef(), useRef(),useRef(),useRef()];



  let content = [
    { title: "main", body: (<MainForm checkData={checkData} submitMain={(e)=>{mainData.current=e}} formRef={formRefs[0]} inTab={curentTab} mode={mode} data={response} />), },
    { title: "Unit", body: (<UnitForm  checkData={checkData} formRef={formRefs[1]} submitUnit={(e)=>{UnitData.current=e}} inTab={curentTab} mode={mode} data={response} />), },
    { title: "Packing", body: (<PackingForm formRef={formRefs[2]} submitPacking={(e)=>{packingData.current=e}} inTab={curentTab} mode={mode} data={response} />), },
    { title: "Tax", body: (<TaxForm formRef={formRefs[3]} submitTax={(e)=>{taxData.current=e}} inTab={curentTab} mode={mode}  data={response} />), },
  ];

  const tabChangeHandle = (tab) => {
    setCurentTab(tab)
  } 
  async function formSubmit() {
        if(mode=="create"){
          try {
            await delay(50)
            let data = {
            ParentId: selectedObj.Id,
            "GroupLeafId": checkData.GroupLeafId,
            "ParentLayerLeafId": checkData.ParentLayerLeafId ?checkData.ParentLayerLeafId :response?.ParentLayerLeafId  ,
            "ItemLayerType": checkData.ItemLayerType,
            ...{...mainData.current ,...UnitData.current,...packingData.current,...taxData.current},               
            
            }
            await sendItemLeaf(data )
            showForm(true)

          } catch (error) {
  
          }
        }else{
          try {
          await delay(50)
          let formdata: any = {}
          formdata = {Id:selectedObj.Id ,
            ParentId: selectedObj.ParentId,
            "GroupLeafId": checkData?.GroupLeafId,
            IsLeaf:response.IsLeaf,
            "ParentLayerLeafId": checkData.ParentLayerLeafId ?checkData.ParentLayerLeafId :response?.ParentLayerLeafId,
            "ItemLayerType": checkData.ItemLayerType,
            ...mainData.current ,...UnitData.current,...packingData.current,...taxData.current }

              await putItemLeaf(formdata )
              showForm(true)
          } catch (error) {
      
          }
        }
    
}

const getItemLeafAsync =async () => {
  setLoading(false)
  try {
    const res = await getItemLeaf(selectedObj.Id)
    setResponse(res?.Data)
  } catch (error) {
    
  }
  setLoading(true)
}
async function checkPaste() {
  try {
      
      let data =await navigator.clipboard.readText()
      if(data && JSON.parse(data)){
          setCanPaste(true)
      }
  } catch (error) {
      setCanPaste(false)

  }
}
const pasteHandler = async() => {
 const text =JSON.parse( await navigator.clipboard.readText());
    setResponse(text)
  try {
  } catch (error) {
      
  }  
}
useEffect(() => {
  if(selectedObj?.Id && mode !="create"){
    getItemLeafAsync()
  }else{
      setLoading(true)
  }
  checkPaste()
}, [selectedObj]);



  const delay = (duration) =>
  new Promise(resolve => setTimeout(resolve, duration));

  const handleSubmitAllForms = async(e) => {
    document.body.classList.add("loading-indicator-"+lang);
  let flag=true ; 
  await Promise.all(
    formRefs.map(async (formRef:any, index) => {        
      if(formRef.current.checkValidity()&&flag){    
        formRef.current.hidebutton?.click();                   
      }else if(flag){                   
        flag = false  
        setCurentTab(formRef.current.name)                   
        await delay(50)                   
        formRef.current.reportValidity()                   
      }                   
    })                   
  );                
    
  await delay(50)                   
  if(mainData.current!=undefined && taxData.current!=undefined&&flag){   
                    
      formSubmit()                   
  }else{
    document.body.classList.remove("loading-indicator-"+lang);
  }                     
                   
  };                   
  return (
    <>
    {loading ? 
      <>
        {(canPaste&&mode!="view")&&
            <div onClick={()=>{pasteHandler()}} className="col-2  btn btn-primary btn-sm d-flex align-items-center justify-content-between " style={{zIndex:"1",  width:'89px' , maxHeight:"30px", marginRight:"20px" , position:"absolute" , left:"5px"}}>
                <span className=" font-size-md ">{trans('paste')}</span>
                <FontAwesomeIcon size="xs" icon={['fas', 'paste']} className="color_text_light d-flex Requests__chatbox-reply-btn mx-1"   color='white' />
            </div>                    
         }
        <TabBar  tabChange={tabChangeHandle} content={content} curentTab={curentTab} defaultTab={"main"} />
        <div className="d-flex justify-content-end mt-2">
          { mode != "view"?
          <Button onClick={handleSubmitAllForms} btnType="submit" value="save"  />
          :
          <Button onClick={()=>showForm(false)} btnType="primary" value="Close"  />                                                                                                                                                              
          }
        </div>
      </>
    :
      <Loading />
  }
    </>
  )
}