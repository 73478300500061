import { Button } from "components/Button/Button";
import { DataGrid } from "components/DataGrid/DataGrid";
import { EnumerationTypeSelect } from "components/EnumerationTypeSelect/EnumerationTypeSelect";
import { Loading } from "components/Loading/loading";
import { Message } from "components/Message/Message";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Textbox } from "components/Textbox/Textbox";
import { Toast } from "components/ToastShow/ToastShow";
import { Toolbar } from "components/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { downloadCSV, getPageSize, trans } from "utils/helpers";
import {
  deleteDataCollection,
  getDataCollection,
  postDataCollection
} from "./api";
import { DataCollectionPropertiesForm } from "./DataCollectionPropertiesForm";


interface DataCollectionPropsType {
  onHide: (e: boolean) => void;

  mode: "create" | "view" | "edit";
}

export const DataCollectionForm = ({
  onHide,

  mode,
}: DataCollectionPropsType) => {
  const [userChanges, setUserChanges] = useState({});
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState<any>("info");
  const [response, setResponse] = useState<any>([]);
  const [selectedObj, setSelectedObj] = useState<string | any>(null);
  const [showPropertiesForm, setShowPropertiesForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [detailMode, setDetailMode] = useState<"create" | "view" | "edit">();
  const [search,setsearch]=useState<any>('')

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
      Title: event.target.Title?.value,
      TitleEN: event.target.TitleEN?.value,
      SystemKey: event.target.SystemKey?.value,
      Description: event.target.Description?.value,
      DescriptionEN: event.target.DescriptionEN?.value,
    };
    try {
      if (detailMode === "create") {
        await postDataCollection(data, "post");
      } else {
 

        await postDataCollection(
          {
            ...data,
            ActivityStatus: +event.target.ActivityStatus?.value,
            Id: selectedObj.Id,
          },
          "put"
        );
      }
      setForceUpdate(!forceUpdate);
      setShowForm(false);
    } catch (error) {}
  };

  const getDataCollectionAsync = async (SearchString=search) => {
    setLoading(false);

    try {
      const res = await getDataCollection(SearchString);
      if (res.Data.length > 0) {
        setResponse(res);

      }
    } catch (error) {}
    setLoading(true);
  };


  const searchHandler = (str) => {
    setSelectedObj(null)
    if (str === "") {
        setsearch(null)
        getDataCollectionAsync( null);
    } else {
        setsearch(str)
        getDataCollectionAsync( str);
    }
}

  const handleDeleteRow = async () => {
    try {
      const res = await deleteDataCollection(selectedObj.Id);
      setForceUpdate(!forceUpdate);
    } catch (error) {}
  };

  function cancel() {
    setShowForm(false);
  }


  const selectedObjCallback = (obj, index) => {
    setSelectedObj(obj);
  };

  const clickToolbarCallback = (clickedButtonName: string) => {
    switch (clickedButtonName.toLowerCase()) {
      case "excel":
        downloadCSV(response.Data, 1, trans("dataCollection"));
        break;
      case "refresh":
        setForceUpdate(!forceUpdate);
        break;
      case "create":
        setShowForm(true);
        setDetailMode("create");
        break;
      case "remove":
        if (selectedObj?.Id)
          Toast(
            trans("msg_deleteconfirm"),
            "warning",
            "alert",
            handleDeleteRow
          );
        break;
      case "view":
        if (selectedObj?.Id) {
          setShowForm(true);
          setDetailMode("view");
        }
        break;
      case "properties":
        if (selectedObj?.Id) setShowPropertiesForm(true);
        break;
      case "edit":
        if (selectedObj?.Id) {
          setShowForm(true);

          setDetailMode("edit");
        }
        break;
    }
  };

  useEffect(() => {
    getDataCollectionAsync();
  }, [forceUpdate]);

  return (
    <>
      {showPropertiesForm && (
        <ModalForm
          title={trans("viewForm")}
          show={showPropertiesForm}
          onHide={() => {
            setShowPropertiesForm(false);
          }}
        >
          <DataCollectionPropertiesForm
            selectedRow={selectedObj}
            mode="create"
            onHide={onHide}
          />
        </ModalForm>
      )}
      <Toolbar
        helpBtn={false}
        Print={false}
        properties={true}
        id={selectedObj?.Id}
        emptyList={response?.TotalRecords == 0 || response?.Data == null}
        remove={true}
        create={true}
        view={true}
        edit={true}
        search={true}
        handleClickToolbar={clickToolbarCallback}
        handleSearch={searchHandler}
      />

      {showForm && (
        <form
          className="TypeSettingForm row card-body "
          onSubmit={handleSubmit}
        >

          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"Title"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"Title"}
              mode={detailMode}
              defaultValue={detailMode !== "create" ? selectedObj.Title : ""}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              textboxName={"TitleEN"}
              textboxType="text"
              resetForm={resetForm}
              labelText={"TitleEN"}
              mode={detailMode}
              defaultValue={detailMode !== "create" ? selectedObj.TitleEN : ""}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              required={true}
              textboxName={"SystemKey"}
              textboxType="systemKey"
              resetForm={resetForm}
              labelText={"SystemKey"}
              mode={detailMode}
              defaultValue={
                detailMode !== "create" ? selectedObj.SystemKey : ""
              }
            />
          </div>
          {detailMode != "create" && (
            <div className="col-md-4 mt-1">
              <EnumerationTypeSelect
                value={selectedObj?.ActivityStatus}
                Change={(e) => {
                  setUserChanges({ ...userChanges, ...e });
                }}
                name="ActivityStatus"
                enumTypeSelect="ActivityStatus"
                mode={detailMode}
                label="Status"
              />
            </div>
          )}
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              textboxName={"Description"}
              textboxType="textarea"
              resetForm={resetForm}
              labelText={"Description"}
              mode={detailMode}
              defaultValue={
                detailMode !== "create" ? selectedObj.Description : ""
              }
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <Textbox
              Change={(e) => {
                setUserChanges({ ...userChanges, ...e });
              }}
              textboxName={"DescriptionEN"}
              textboxType="textarea"
              resetForm={resetForm}
              labelText={"DescriptionEN"}
              mode={detailMode}
              defaultValue={
                detailMode !== "create" ? selectedObj.DescriptionEN : ""
              }
            />
          </div>
          {mode != "view" ? (
            <div className="col-lg-12 mt-1 btns_form">
              <Button value="Save" btnType="submit" />
              <Button
                btnType="cancel"
                value="Cancel"
                onClick={() => {
                  cancel();
                }}
              />
            </div>
          ) : (
            <div className="col-lg-12 mt-1 btns_form">
              <Button
                btnType="primary"
                value="close"
                onClick={() => {
                  cancel();
                }}
              />
            </div>
          )}
        </form>
      )}

      {loading ? (
        <>
          {response?.Data?.length > 0 ? (
            <div className="card">
              <div className="card-body p-0">
                <>
                  <DataGrid
                    DoubleClick={(obj) => {
                      setSelectedObj(obj);
                      // clickToolbarCallback("view");
                    }}
                    View={true}
                    ViewParametr={"NotSeen"}
                    // handlefiltering={(str: any) => {
                    //   str.Filterlist != undefined
                    //     ? setFilterAndSort(str)
                    //     : setFilterAndSort({
                    //         ...filterAndSort,
                    //         Filterlist: str,
                    //       });
                    // }}
                    // filterStr={filterAndSort.Filterlist}
                    // orderFormatList={filterAndSort.OrderFormat}
                    // orderFormat={(order) => {
                    //   setFilterAndSort({
                    //     ...filterAndSort,
                    //     OrderFormat: order,
                    //   });
                    // }}
                    handleDelete={handleDeleteRow}
                    parentName={"SalesInvoiceList"}
                    theadData={Object.keys(response.Data[0])}
                    tbodyData={response.Data}
                    minCellWidth={80}
                    selectedRowObjectCallback={selectedObjCallback}
                    rowSelected={selectedObj}
                    startIndex={5}
                    // handleView={(e) => {
                    //   setSelectedObj({ Id: e });
                    //   setMode("view");
                    //   setShowSalesInvoiceForm(true);
                    // }}
                  />
                </>
              </div>
            </div>
          ) : (
            <Message message={"NoDataForPreview"} type={messageType} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
